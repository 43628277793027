import Swal from 'sweetalert2';
import { cargarUsuario, cerrarModalPassword } from './slicePreferencias';
import GLOBALS from 'src/features/App/globals'
import "../chat/swalestilo.css"

const API = { sliceName: 'preferencias' }

export const retornaUsuario = (cedula) => {
    return async (dispatch) => {

        const resp = await fetchUsuario(cedula);

        if (resp.status === 200) {
            const body = await resp.json();
            dispatch(cargarUsuario(body))
        } else {
            console.log("error al cargar el usuario en preferencias")
        }
    }
}

export const guardarNuevoPassword = (valores) => {
    return async (dispatch) => {
        const resp = await fetchSetPassword(valores.usuarioId, valores.pass1);
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Password cambiado correctamente',
                showConfirmButton: false,
                timer: 2000,
                customClass: {
                    container: 'my-swal'
                }
            })
            dispatch(cerrarModalPassword());
        } else {
            console.log("error al cambiar el password")
            Swal.fire({
                position: "center",
                icon: 'error',
                title: "Error",
                html: "Error al cambiar el password",
                showConfirmButton: true,
                customClass: {
                    container: 'my-swal'
                }
            })
        }
    }
}

export const guardarUsuario = (valores, telefono, cargo) => {
    return async (dispatch) => {
        const resp = await saveInfoUsuario(valores, telefono, cargo);
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Usuario actualizado correctamente',
                showConfirmButton: false,
                timer: 2000,
                customClass: {
                    container: 'my-swal'
                }
            })
            dispatch(retornaUsuario(valores.cedulaUsuario))
        } else {
            console.log("error al actualizar la información del usuario")
            Swal.fire({
                position: "center",
                icon: 'error',
                title: "Error",
                html: "Error al actualizar la información del usuario",
                showConfirmButton: true,
                customClass: {
                    container: 'my-swal'
                }
            })
        }
    }
}

export const guardarImagen = (data) => {
    return async (dispatch) => {
        const resp = await saveImagenUsuario(data);
        const estado = resp.status

            if (estado === 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Imagen guardada correctamente',
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: {
                        container: 'my-swal'
                    }
                })
            } else {
                Swal.fire({
                    position: "center",
                    icon: 'error',
                    title: "Error",
                    html: "Error al guardar la imagen del usuario",
                    showConfirmButton: true,
                    customClass: {
                        container: 'my-swal'
                    }
                })
            }
    }
}

const saveInfoUsuario = (valores, telefono, cargo, method = 'POST') => {

    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/update`

    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            "id": `${valores.id}`,
            "nombreUsuario": `${valores.nombreUsuario}`,
            "cedulaUsuario": `${valores.cedulaUsuario}`,
            "pasaporteUsuario": null,
            "usernameUsuario": `${valores.usernameUsuario}`,
            "telefonoUsuario": `${telefono}`,
            "tipo": `${valores.tipo}`,
            "cargoUsuario": `${cargo}`
        })
    })
}

const saveImagenUsuario = (data, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/imagen`
    return fetch(url, {
        method,
        body: data
    })
}

const fetchSetPassword = (id, password, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/setpsw?empId=${id}&passwd1=${password}&passwd2=${password}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        },
    })
}

const fetchUsuario = (cedula, method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/getemp?cedulaOcorreo=${cedula}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        },
    })
}

export const fetchImagen = (id) => {
    const url = `${GLOBALS.mainUrl}/imagen/descargar?idUsuario=${id}`
    return fetch(url)
        .then((response) => {
            return response.blob();
        })
        .then((blob) => {
            return URL.createObjectURL(blob);
        });
}
