import React, {useEffect, useState} from 'react'
import {f} from 'src/commons'
import API from 'src/features/App/API'
import {FormControlLabel, Grid, Radio, RadioGroup} from '@mui/material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyTextField} from 'src/components/MyTextField'
import {MyAreaTextField} from 'src/components/MyAreaTextField'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import FormControl from "@mui/material/FormControl";
import {MySwitch} from "../../../components/MySwitch";
import {MyUpload} from "../../../components/MyUpload";
import Typography from "@mui/material/Typography";

export const Propuesta = ({section, formValues, setFormValues, canEdit, formErrors}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})

  const sectionErrors = (sectionValues.nombre === '') ?'NOMBRE NO VÁLIDO;':'' ||
    (sectionValues.resumen === '') ?'RESUMEN NO VÁLIDO;':'' ||
    (sectionValues.plazo <1 ||  sectionValues.plazo > 44) ?'PLAZO DEBE SER UN NUMERO ENTRE 1 y 44 mesesw;':'' ||
    (sectionValues.modeloDeNegocios === '') ?'MODELO DE NEGOCIOS VACÍA;':'' ||
    (sectionValues.adjunto === '') ?'ADJUNTO NO HA SIDO SUBIDO;':''

  useEffect(() => {
    if (canEdit && f.isValid(setFormValues)) {
      setFormValues({
          ...formValues,
          [section]: {...sectionValues},
        },
      )
    }
  }, [sectionValues])
  // const sectionErrors = (f.isValid(formErrors) && f.isValid(formErrors[section])) ? formErrors[section] : null
  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Propuesta de acceso con fines comerciales'}/>
      </Grid>
      <Grid item xs={12}>
        <MyAreaTextField id='nombre'
                         label={'Nombre *'}
                         formValues={sectionValues}
                         icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                         error={formErrors['nombre']}
                         canEdit={canEdit}
                         minHeight={'4rem'}
                         handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <MyAreaTextField id='resumen'
                         label={'Resumen de la investigación *'}
                         formValues={sectionValues}
                         icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                         error={formErrors['nombre']}
                         canEdit={canEdit}
                         minHeight={'4rem'}
                         handleChange={handleChange} />
      </Grid>
      <Grid container>
        <Grid item xs={3}>
          <MyTextField id='plazo'
                       label={'Plazo de ejecución *'}
                       formValues={sectionValues}
                       icon={<AccessTimeIcon sx={dialog.textFieldIcon}/>}
                       error={formErrors['plazo']}
                       canEdit={canEdit}
                       isNumber={true}
                       handleChange={(e) => {if(e.target.value === '' || !isNaN(e.target.value)) handleChange(e)}} />
        </Grid>
        <Grid item xs={9}>
          <FormControl sx={{p:'1.5rem 0 0 0 '}}>
            <RadioGroup row
                        aria-labelledby="recoleccion-recurso"
                        name="tipo-plazo"
                        id='tipo'
                        value={sectionValues.tipo}
                        onChange={(e, v) => setSectionValues({...sectionValues,tipo:v})}>
              <FormControlLabel value="meses" control={<Radio />} label="meses" />
              <FormControlLabel value="años" control={<Radio />} label="años" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container sx={{p:'0 4rem 0 0'}}>
          <Grid item xs={12}>
            <MySwitch id={'beneficioXInnovacion'}
                      label={'Beneficios por innovación'}
                      formValues={sectionValues}
                      setFormValues={setSectionValues}
                      handleChange={handleChange}
                      canEdit={canEdit}/>
          </Grid>
          <Grid item xs={12}>
            <MySwitch id={'beneficioXIndustrial'}
                      label={'Beneficios por aplicación industrial'}
                      formValues={sectionValues}
                      setFormValues={setSectionValues}
                      handleChange={handleChange}
                      canEdit={canEdit}/>
          </Grid>
          <Grid item xs={12}>
            <MySwitch id={'beneficioXPrecomercial'}
                      label={'Beneficios por pre-comercialización'}
                      formValues={sectionValues}
                      setFormValues={setSectionValues}
                      handleChange={handleChange}
                      canEdit={canEdit}/>
          </Grid>
          <Grid item xs={12}>
            <MySwitch id={'beneficioXComercializacion'}
                      label={'Beneficios monetarios por comercialización'}
                      formValues={sectionValues}
                      setFormValues={setSectionValues}
                      handleChange={handleChange}
                      canEdit={canEdit}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container sx={{p:'0 0 0 4rem'}}>
          <Grid item xs={12} sx={{alignItems:'right'}}>
            <MyUpload id={'modeloDeNegocios'}
                      dir={formValues.INFO.solicitudId}
                      label={'Modelo de negocios y beneficiarios'}
                      formValues={sectionValues}
                      setFormValues={setSectionValues}
                      canEdit={canEdit}
                      info={'info...'} />
          </Grid>
          <Grid item xs={12} sx={{alignItems:'right'}}>
            <MyUpload id={'adjunto'}
                      dir={formValues.INFO.solicitudId}
                      label={'Adjunto'}
                      formValues={sectionValues}
                      setFormValues={setSectionValues}
                      canEdit={canEdit}
                      info={'info...'} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {
          (sectionErrors !== '') ? <Typography sx={{color:'red'}}>{sectionErrors}</Typography>:false
        }
      </Grid>
    </Grid>

  )
}
