import React, {useState} from 'react'
import {DataGrid, gridClasses} from '@mui/x-data-grid'
import {Box, InputLabel, Select, Stack} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import YardIcon from '@mui/icons-material/Yard'
import {SelectTaxonomiaR1} from 'src/features/App/subcomponents/TaxonomiaR1'
import {SelectTaxonomiaR2} from 'src/features/App/subcomponents/TaxonomiaR2'
import RobotoCondensedRegular from "../styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf";
import {MyButtonBacan2} from "./MyButtonBacan2";
// import {f} from "../commons";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import {f} from "../commons";
// import Button from "@mui/material/Button";
// import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
// import GLOBALS from "../features/App/globals";

export const MyTableRecursos = ({
                                  id,
                                  formValues,
                                  setFormValues,
                                  canEdit=false,
                                  addItems=null,
                                  addItems2=null,
                                  setRecursoSelected=null,
                                  muestras,
                                  mode='in-situ',
                                  mode2='',
                                  oldRecursos=[]
                               })  => {
  const muestrasSet = [...(new Set(muestras.map(it => it.dato).sort((t1, t2) => (t1 > t2)?+1:-1)))]
  const columns = {
    'in-situ' : columnsInSitu,
    'ex-situ' : columnsExSitu,
  }[mode] ?? columnsInSitu
  const rows = formValues[id]
  const [selectionModel, setSelectionModel] = useState([])
  const [showTaxonomia, setShowTaxonomia] = useState(false)

  const processRowUpdate = (newRow) => {
    let fixed = {
      ...newRow,
      cantidadAutorizada: Number(newRow.cantidadAutorizada) < 0 ? 0 : Number(newRow.cantidadAutorizada),
      cantidadSolicitada: Number(newRow.cantidadSolicitada) < 0 ? 0 : Number(newRow.cantidadSolicitada),
      saldoATM: Number(newRow.saldoATM) < 0 ? 0 : Number(newRow.saldoATM),
      saldoDRM: Number(newRow.saldoDRM) < 0 ? 0 : Number(newRow.saldoDRM),
    }
    const nId = fixed.id
    let nRow = {...fixed}
    if(f.isValid(nRow.cantidadAutorizada) && (nRow.cantidadAutorizada > nRow.cantidadSolicitada)) {
      nRow.cantidadAutorizada = nRow.cantidadSolicitada
    }
    if(f.isValid(nRow.cantidadAutorizada) && (nRow.cantidadAutorizada < 0)) {
      nRow.cantidadAutorizada = 0
    }
    const nRows = [...rows]
    const index = nRows.findIndex(it => it.id === nId)
    if(index >= 0) {
      nRows[index] = {...nRows[index], ...nRow}
      setFormValues({...formValues, [id]:[...nRows]})
    }
    return newRow
  }

  const processRowUpdateAUTORIZAR = (newRow) => {
    const cantidadSolicitada = Number(newRow.cantidadSolicitada) < 0 ? 0 : Number(newRow.cantidadSolicitada)
    const cantidadAutorizada = Number(newRow.cantidadAutorizada) < 0 ? 0 : (Number(newRow.cantidadAutorizada) > cantidadSolicitada)?cantidadSolicitada:Number(newRow.cantidadAutorizada)
    let fixed = {
      ...newRow,
      cantidadAutorizada,
      cantidadSolicitada,
      saldoATM: cantidadAutorizada,
      saldoDRM: cantidadAutorizada,
    }
    const nId = fixed.id
    let nRow = {...fixed}
    const nRows = [...rows]
    const index = nRows.findIndex(it => it.id === nId)
    if(index >= 0) {
      nRows[index] = {...nRows[index], ...nRow}
      setFormValues({...formValues, [id]:[...nRows]})
    }
    return newRow
  }

  const processRowUpdateSOLICITUD = (newRow) => {
    let minV = 0
    if(oldRecursos.filter(it => it.taxonid === newRow.taxonid).length > 0) {
      const old = oldRecursos.filter(it => it.taxonid === newRow.taxonid)?.at(0)
      minV = old.cantidadAutorizada
    }
    const cantidadAutorizada = Number(newRow.cantidadAutorizada) < minV ? minV : Number(newRow.cantidadAutorizada)
    const cantidadSolicitada = Number(newRow.cantidadSolicitada) < minV ? minV : Number(newRow.cantidadSolicitada)
    let fixed = {
      ...newRow,
      cantidadAutorizada,
      cantidadSolicitada,
      saldoATM: 0,
      saldoDRM: 0,
    }
    const nId = fixed.id
    let nRow = {...fixed}
    const nRows = [...rows]
    const index = nRows.findIndex(it => it.id === nId)
    if(index >= 0) {
      nRows[index] = {...nRows[index], ...nRow}
      setFormValues({...formValues, [id]:[...nRows]})
    }
    return newRow
  }

  let taxonid = null
  let muestraslength = 0
  if(selectionModel.length > 0) {
    taxonid = selectionModel[0]
    muestraslength = muestras?.filter(it => it.taxonid === taxonid)?.length
  }
  const [agregar, setAgregar] = React.useState('orden')
  const handleChange = (e) => { setAgregar(e.target.value) }
  const pRowUpdate = {
    "solicitud": processRowUpdateSOLICITUD,
    "autorizar": processRowUpdateAUTORIZAR,
  }[mode2]
  return (
    <Box sx={{ pb: '24px', width: '100%',}}>
      <Stack direction="column" spacing={1}>
        {canEdit &&
          <Stack direction="row" spacing={1} justifyContent={'flex-end'} alignItems={'center'} sx={{p:'1rem 0 1rem 0'}}>
            <Stack direction="row"  justifyContent={'flex-end'} alignItems={'center'} spacing={3}>
              <FormControl sx={{width:'14rem'}}>
                <InputLabel variant='standard' id='agregar-label'>
                  Método para agregar especies
                </InputLabel>
                <Select variant="standard"  labelId='agregar-label'
                        id="agregar-select"
                        value={agregar}
                        label="Taxonomía"
                        onChange={handleChange}>
                  <MenuItem value={'rank'}>Por rango taxonómico</MenuItem>
                  <MenuItem value={'orden'}>Por jerarquía</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{pt:'0.9rem'}}>
                <MyButtonBacan2 icon={YardIcon} label={'agregar especies'} onClick={()=>setShowTaxonomia(true)} />
              </Box>
            </Stack>
            {
              (selectionModel.length === 1) && (!oldRecursos.includes(selectionModel[0])) &&
              <Box sx={{pt:'0.9rem'}}>
                <MyButtonBacan2 onClick={() => {
                                  if(muestraslength > 0) {
                                    alert('NO SE PUIEDEN BORRAR ESPECIES MIENTRAS TENGAN MUESTRAS Y SUBMUESTRAS DEPENDIENTES')
                                  } else {
                                    const selectedIDs = new Set(selectionModel)
                                    const nRows = [...rows].filter(it => !selectedIDs.has(it.id))
                                    setFormValues({...formValues, [id]:[...nRows]})
                                  }
                                }}
                                color0={'darkred'}
                                icon={DeleteIcon}
                                label={'Eliminar'} />
              </Box>
            }
          </Stack>
        }
        <DataGrid experimentalFeatures={{ newEditingApi: true }}
                  disableSelectionOnClick={false}
                  getRowHeight={() => 'auto'}
                  rows={rows}
                  columns={columns(canEdit, muestrasSet, mode2)}
                  processRowUpdate={pRowUpdate}
                  autoHeight={true}
                  rowHeight={32}
                  pageSize={8}
                  selectionModel={selectionModel}
                  rowsPerPageOptions={[8]}
                  onSelectionModelChange={(selection) => {
                    const selectionSet = new Set(selectionModel);
                    const result = selection.filter((s) => !selectionSet.has(s))
                    setSelectionModel(result)
                    if(result.length > 0) {
                      const taxonIdSel = rows.filter(it => it.id === result[0])[0].taxonid
                      setRecursoSelected({...rows.filter(it => it.id === result[0])[0], old:oldRecursos.includes(taxonIdSel)})
                    }
                    else
                      setRecursoSelected({}, null)
                  }}
                  headerHeight={40}
                  sx={{
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: "rgba(244,244,244,0.4)",
                      color: "#aeaeae",
                      fontSize: '0.8rem',
                      fontfamily: RobotoCondensedRegular,
                      fontweight: 'lighter',
                    },
                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                      '& .MuiDataGrid-row': {
                        backgroundColor: 'rgba(255, 255, 255, .7)',
                        fontSize: '0.8rem',
                        fontfamily: RobotoCondensedRegular,
                        fontweight: 'lighter',
                        color: "#888888",
                      },
                      '& .Mui-selected': {
                        color: "#000000",
                      },
                      [`& .${gridClasses.cell}`]: {
                        py: 1,
                      },
                    },
                  }} />
      </Stack>
      {(showTaxonomia && agregar==='rank') && <SelectTaxonomiaR1 showTaxonomia={showTaxonomia}
                                                                 onCancel={()=>setShowTaxonomia(false)}
                                                                 onSelectItems={(items)=> {
                                                                                 addItems(items)
                                                                                 setShowTaxonomia(false)
                                                                               }} /> }
      {(showTaxonomia && agregar==='orden') && <SelectTaxonomiaR2 showTaxonomia={showTaxonomia}
                                                                  onCancel={()=>setShowTaxonomia(false)}
                                                                  onSelectItems={(items)=> {
                                                                                  addItems2(items)
                                                                                  setShowTaxonomia(false)
                                                                                }} /> }
    </Box>
  )
}

const columnsInSitu = () => [
  {
    field: 'id',
    headerName: 'id',
    type: 'number',
    editable: false,
    hide: true
  },
  {
    field: 'taxonid',
    headerName: 'taxonid',
    type: 'number',
    editable: false,
    hide: true,
  },
  {
    field: 'scientificname',
    headerName: 'Nombre Científico',
    width: 240,
    editable: false,
    valueGetter: (params) => params?.row?.scientificname,
  },
  {
    field: 'taxonomicHierarchy',
    headerName: 'Jerarquía | nivel',
    width: 300,
    editable: false
  },
  {
    field: 'cites',
    headerName: 'Cites',
    width: 100,
    editable: false,
  },
  {
    field: 'redList',
    headerName: 'Lista roja',
    width: 160,
    editable: false
  },
  {
    field: 'categoriaEspecial',
    headerName: 'Categoría Especial',
    width: 200,
    editable: false,
  },
  {
    field: 'artificialGroup',
    headerName: 'Grupo artificial',
    width: 200,
    editable: false,
  },
]

const columnsExSitu = (canEdit, muestras, mode2) => ([
  {
    field: 'id',
    headerName: 'id',
    type: 'number',
    editable: false,
    hide: true
  },
  {
    field: 'taxonid',
    headerName: 'taxonid',
    type: 'number',
    editable: false,
    hide: true,
  },
  {
    field: 'clasificacion',
    headerName: 'Clasificación',
    width: 120,
    editable: false
  },
  {
    field: 'taxonrank',
    headerName: 'Rango taxonómico',
    width: 140,
    editable: false,
  },
  {
    field: 'scientificname',
    headerName: 'Nombre científico',
    width: 240,
    editable: false,
    valueGetter: (params) => params?.row?.scientificname,
  },
  {
    field: 'taxonomicHierarchy',
    headerName: 'Jerarquía | nivel',
    width: 500,
    editable: false,
    hide: true,
  },
  {
    field: 'cites',
    headerName: 'Cites',
    width: 100,
    editable: false,
    hide: true
  },
  {
    field: 'tipoMuestra',
    headerName: 'Submuestra',
    width: 200,
    editable: canEdit && mode2 !== 'autorizar',
    type: 'singleSelect',
    valueOptions: muestras,
  },
  {
    field: 'especimenTipo',
    headerName: 'Especimen tipo',
    width: 160,
    editable: canEdit && mode2 !== 'autorizar',
  },
  {
    field: 'cantidadSolicitada',
    headerName: 'Cantidad solicitada',
    width: 200,
    type: 'number',
    editable: canEdit && !['autorizar'].includes(mode2),
    // hide: ['autorizar-ver'].includes(mode2)
  },
  {
    field: 'cantidadAutorizada',
    headerName: 'Cantidad autorizada',
    width: 200,
    type: 'number',
    editable: mode2 === 'autorizar',
    // hide: mode2 === 'solicitud',
  },
  {
    field: 'detalle',
    headerName: 'Detalle cantidad',
    width: 200,
    editable: canEdit && mode2 !== 'autorizar',
  },

  {
    field: 'saldoATM',
    headerName: 'Saldo ATM1',
    width: 200,
    editable: false,
    hide: mode2 === 'solicitud',
  },

  {
    field: 'saldoDRM',
    headerName: 'Saldo DRM',
    width: 200,
    editable: false,
    hide: mode2 === 'solicitud',
  },
])
