import React from 'react'
import { Administracion } from './Administracion'

export const Widget = () => {
  return (
    <>
      <Administracion />
    </>
  )
}
