import React, {useEffect, useState} from 'react'
import {Box} from '@mui/system'
import {f} from 'src/commons'
import API from 'src/features/App/API'
import {Grid} from '@mui/material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MySwitch} from 'src/components/MySwitch'
import {MySelect} from 'src/components/MySelect'
import {paises} from 'src/features/P01Solicitud/CONF'
import {Email, LocationOn, PhoneInTalk, Smartphone} from '@mui/icons-material'
import {MyTextField} from 'src/components/MyTextField'
import {MyButtonBacan3} from 'src/components/MyButtonBacan3'
import SearchIcon from '@mui/icons-material/Search'
import {MyMaskedTextField} from 'src/components/MyMaskedTextField'
import {MyUpload} from "../../../components/MyUpload";
import Typography from "@mui/material/Typography";

export const EmpresaSolicitante = ({section, formValues, setFormValues, canEdit, formErrors, payload}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})

  // const sectionErrors = (f.isValid(formErrors) && f.isValid(formErrors[section])) ? formErrors[section] : null
  const sectionErrors = (sectionValues.esExtranjera=== false && sectionValues.esRuc === '') ? 'RUC NO VÁLIDO;':'' ||
                                   (sectionValues.esExtranjera=== true && sectionValues.esRuc !== '') ? 'RUC NO VÁLIDO;':'' ||
                                   (sectionValues.esRazonSocial === '') ?'RAZON NO VÁLIDO;':'' ||
                                   (sectionValues.esDomicilioLegal === '') ?'DIRECCIÓN NO VÁLIDO;':'' ||
                                   (sectionValues.esRepresentanteLegal === '') ?'RERESENTANTE LEGAL NO VÁLIDO;':'' ||
                                   (sectionValues.esTelefonoTrabajo === '') ?'TELEFONO NO VÁLIDO;':'' ||
                                   (sectionValues.esCelular === '') ?'CELULAR NO VÁLIDO;':'' ||
                                   (sectionValues.esCorreoElectronico === '') ?'CORREO NO VÁLIDO;':'' ||
                                   (sectionValues.esEmpresaSolicitanteLegal === '') ?'Nombramiento del represente legal no ha sido cargado;':''

  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  const handleChangePhone = (e) => {
    if(e.target.value === '' || f.isPhone(e.target.value))
      API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  }
  useEffect(() => {
    if(canEdit && f.isValid(setFormValues)) {
      setFormValues({
        ...formValues,
        [section]: {...sectionValues},

      },
    )}
  }, [sectionValues])
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Empresa solicitante'} />
      </Grid>
      <Grid item xs={3} >
        <Box sx={{p:'1rem 1.5rem 0 0'}}>
          <MySwitch id={'esExtranjera'}
                    label={'Es empresa extranjera'}
                    formValues={(sectionValues)}
                    setFormValues={setSectionValues}
                    handleChange={handleChange}
                    canEdit={canEdit}/>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <MySelect id='esPais'
                  label={'Nacionalidad | País *'}
                  canEdit={canEdit}
                  formValues={sectionValues}
                  setFormValues={setSectionValues}
                  data={paises} />
      </Grid>
      {sectionValues.esExtranjera ?
        <Grid item xs={5}>
          <MyTextField id={'esRuc'}
                       label={'NUMERO *'}
                       formValues={sectionValues}
                       setFormValues={setSectionValues}
                       icon={<LocationOn sx={{fontSize: '14px', color: 'silver'}}/>}
                       error={f.isValid(sectionErrors) && sectionErrors['direccion']}
                       canEdit={canEdit}
                       handleChange={handleChange}/>
        </Grid> :
        <>
          <Grid item xs={3}>
              <MyTextField id={'esRuc'}
                           label={'RUC *'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<LocationOn sx={{fontSize: '14px', color: 'silver'}}/>}
                           error={f.isValid(sectionErrors) && sectionErrors['direccion']}
                           canEdit={canEdit}
                           handleChange={handleChange}/>
          </Grid>
          <Grid item xs={2} >
            <Box sx={{p:'1.9rem 0 0 0', width:'100%'}} display={'flex'} justifyContent={'center'}>
              <MyButtonBacan3 label={'BUSCAR RUC'}
                              onClick={() => {
                                API.buscarRuc(sectionValues.esRuc).then((res) => {
                                  if(f.isValid(res.id)) {
                                    setSectionValues({
                                      ...sectionValues,
                                      esRuc:                    res.id,
                                      esRazonSocial:            res.razonSocial ?? '',
                                      esDomicilioLegal:         res.direccionRuc ?? '',
                                      esRepresentanteLegal:     res.representanteLegal ?? '',
                                      esTelefonoTrabajo:        res.telefonoRuc ?? '',
                                      esCelular:                res.celularRepresentante ?? '',
                                      esCorreoElectronico:      res.correoRuc ?? '',
                                      rucInfo:res,
                                    })
                                  }
                                })
                              }}
                              icon={SearchIcon} />
            </Box>
          </Grid>
        </>
      }
      <Grid item xs={12}>
        <MyTextField id={'esRazonSocial'}
                     label={'Razón Social'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={f.isValid(sectionErrors) && sectionErrors['esRazonSocial']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'esDomicilioLegal'}
                     label={'Domicilio legal'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={f.isValid(sectionErrors) && sectionErrors['esDomicilioLegal']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'esRepresentanteLegal'}
                     label={'Representante legal'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={f.isValid(sectionErrors) && sectionErrors['esRepresentanteLegal']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'esTelefonoTrabajo'}
                           label={'Teléfono del trabajo'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>}
                           error={f.isValid(sectionErrors) && sectionErrors['esTelefonoTrabajo']}
                           canEdit={canEdit}
                           type={'CellPhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'esCelular'}
                           label={'Celular'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>}
                           error={f.isValid(sectionErrors) && sectionErrors['esCelular']}
                           canEdit={canEdit}
                           type={'HomePhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyTextField id={'esCorreoElectronico'}
                     label={'Correo Electrónico'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>}
                     error={f.isValid(sectionErrors) && sectionErrors['esCorreoElectronico']}
                     canEdit={canEdit}
                     handleChange={handleChange}/>
      </Grid>
      <Grid item xs={12}>
        <MyUpload id={'esEmpresaSolicitanteLegal'}
                  label={'Nombramiento Representante Legal'}
                  dir={formValues.INFO.solicitudId}
                  setFormValues={setSectionValues}
                  formValues={sectionValues}
                  canEdit={canEdit} />
      </Grid>
      {
        (sectionErrors !== '') ? <Typography sx={{color:'red'}}>{sectionErrors}</Typography>:false
      }
    </Grid>
  )
}
