import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom"
import {useDispatch} from "react-redux"
import {useIdleTimer} from "react-idle-timer"
import {logout} from 'src/features/App/sliceApp'
import {MyConfirm} from "./MyConfirm";

// https://codesandbox.io/p/sandbox/confirm-prompt-y8ew9s?file=%2Fsrc%2FApp.tsx%3A51%2C3-52%2C60&from-embed=

const MyuTimeout = ({
               userDefined,                         // true || false
               timeout = 900_000,           // miliseconds
               promptBeforeIdle = 3_000,    // miliseconds
               intervl = 5_000
}) => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [dialogData, setDialogData] = useState({
    title: 'ATENCIÓN',
    message: 'Lleva 15 minutos sin actividad !',
    openDialog: false,
    okAction: () => {},
    cancelAction: () => alert('[cancelAction]'),
    messsageAfterOk: 'Lleva mas 15 minutos sin actividad !',
    mode: 'NONE'
  })

  const [state, setState] = useState('Active')
  const [remaining, setRemaining] = useState(timeout)
  // const [open, setOpen] = useState(false)

  const onIdle = () => {
    setState('Idle')
    setDialogData({...dialogData, openDialog: false})
    navigate('/')
    dispatch(logout())
  }

  const onActive = () => {
    setState('Active')
    setDialogData({...dialogData, openDialog: false})
  }

  const onPrompt = () => {
    if(userDefined) {
      setDialogData({...dialogData, openDialog: true})
    }
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 1000
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, intervl)

    return () => {
      clearInterval(interval)
    }
  })

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

  return (<MyConfirm dialogData={dialogData} setDialogData={setDialogData} />)
}

export default MyuTimeout
