import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  AppBar,
  Box,
  CssBaseline,
  Grid, InputLabel, Select, Stack, Tab, TextField, Typography,
} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import API from 'src/features/App/API'
import Toolbar from '@mui/material/Toolbar'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {MySelect} from "../../components/MySelect";
import {MyTextField} from "../../components/MyTextField";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {paises} from 'src/features/P01Solicitud/CONF'
import {MyDatePicker} from "../../components/MyDatePicker";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ScienceIcon from '@mui/icons-material/Science';
import {MySwitch} from "../../components/MySwitch";
import {MyUpload} from "../../components/MyUpload";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P10ATMRO} from "../P10ATM/P10ATMRO";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import {MyButtonBacan2} from "../../components/MyButtonBacan2";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default ({
                  instanciaTarea,
                  perfilUsuario,
                  instanciaProceso,
                  solicitud,
                  solicitudesAprobadas,
                  canEdit=true,
                  // atms=[],
                }) => {
  const dispatch = useDispatch()
  const section = 'Solicitud'
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const ATM = slct.payload.Solicitud.solicitudAprobada.father.payloadSolicitud
  let rss = ATM.Solicitud.recursos
  const mp = slct.payload.Solicitud.solicitudAprobada
  const payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:                       today,
    ubicacionPais:               '',
    ubicacionInstitucion:        '',
    recursos:                    [],
    responsableEnvio:            '',
    fechaRetorno:                today,
    justificacion:               '',
    integridadHolotipo:          false,
    certificadoDeposito:         '',
    texto:
      'Yo, ' + mp?.Solicitante?.nombresCompletos + ', portador del documento de identidad ' +mp?.Solicitante?.cedula +
      ', en calidad de solicitante, declaro bajo ' +
      'juramento que la información constante en la presente solicitud es verdadera y de mi absoluta ' +
      'responsabilidad. En caso de omitir información, así como en caso de forzar, falsificar, modificar, alterar o ' +
      'introducir cualquier información falsa o corregir el presente documento, asumo toda la responsabilidad ' +
      'administrativa, civil o penal conforme lo establecido por ley.\n\n' +
      'Atención: Por favor revise la información del registro de la solicitud, si está seguro que los datos son ' +
      'correctos acepte y declare la veracidad de toda la información detallada en la presente solicitud y envíe ' +
      'la misma; caso contrario, cierre esta ventana y realice los cambios a través del botón guardar.',
    si: false,
  }
  const [formValues, setFormValues] = useState({...emptyPayload,...payload[section]})
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
  },[formValues])
  const [recursoSelected, setRecursoSelected] = useState({})
  const defaultHandleChange4Switch = (
    event,
    value,
    canEdit,
    setFormValues,
    formValues
  ) => {
    if(canEdit) {
      setFormValues({...formValues, [event.target.id]: value})
    }
  }
  const handleChange = (e) => API.handleChange2(e, canEdit, setFormValues, formValues)
  const [myTab, setMytab] = useState('1')
  const [rowSelected, setRowSelected] = useState('')
  const [evCantidades, setEvCantidades] = useState(true)
  const [dataLine, setDataLine] = React.useState('')
  const handleChangeDataLine = (event) => { setDataLine(event.target.value) }
  useEffect(() => {
    let cantidades = formValues.recursos.filter(it4=> it4.cantidadSolicitada === 0)
    setEvCantidades(cantidades.length === 0)
  }, [formValues.recursos])
  // console.log('::: fechas :: ', formValues.fecha,  formValues.fechaRetorno)
  if(f.isValid(solicitudesAprobadas)) {
    return (
      <Box sx={accordeonBox.container}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Solicitud de salida de holotipos'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
            <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="ATM" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Identificador'}
                                     icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={solicitud.numeroSolicitud} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Fecha'}
                                     icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={formValues['fecha']} />
              </Grid>
              <Solicitante solicitud={{solicitud: {payload: mp}}} displayContact={false}/>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Destino'} />
              </Grid>
              <Grid item xs={6}>
                <MySelect id={'ubicacionPais'}
                          width={'40rem'}
                          label={'Pais *'}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          canEdit={canEdit}
                          data={paises} />
              </Grid>
              <Grid item xs={6}>
                <MyTextField id={'ubicacionInstitucion'}
                             label={'Institución *'}
                             formValues={formValues}
                             setFormValues={setFormValues}
                             icon={<ScienceIcon sx={{fontSize: '14px', color:'silver'}}/>}
                             canEdit={canEdit}
                             handleChange={handleChange} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Recursos'}/>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant='standard' fullWidth>
                  <InputLabel id="demo-simple-select-label">Recurso</InputLabel>
                  <Select labelId="demo-simple-select-label"
                          id="dataLine-simple-select"
                          value={dataLine}
                          onChange={handleChangeDataLine} >
                    { rss?.filter(it => it.cantidadAutorizada > 0).map((it, idx) => <MenuItem key={idx} value={it.mid}>{it.scientificname}</MenuItem>) }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} >
                <Box sx={{p:'0.8rem 0 0 0'}}>
                  <Stack direction={'row'} justifyContent='space-between' alignItems="center" spacing={2}>
                    <MyButtonBacan2 onClick={() => {
                                      if(dataLine !== '') {
                                        const filtro = rss.filter(it => it.mid === dataLine)
                                        if(filtro.length === 1) {
                                          let line = rss.filter(it => it.mid === dataLine)[0]
                                          const found = formValues.recursos.filter(it =>  it.mid === line.mid).length > 0
                                          if(!found) {
                                            let lineas = [...formValues.recursos]
                                            lineas.push(line)
                                            setFormValues({...formValues, recursos: lineas})
                                          }
                                        }
                                      }
                                    }}
                                    icon={AddIcon}
                                    label={'Agregar recurso'} />
                                    {(rowSelected !== '') &&
                                      <MyButtonBacan2 onClick={() => {
                                                                if(rowSelected !== '') {
                                                                  const filtro = formValues.recursos.filter(it => it.mid !== rowSelected)
                                                                  setFormValues({...formValues, recursos: filtro})
                                                                }
                                                              }}
                                      icon={RemoveIcon}
                                      label={'Eliminar recurso'} />
                    }
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <MyTableRecursos2 id={'recursos'}
                                  canEdit={true}
                                  formValues={formValues}
                                  setFormValues={setFormValues}
                                  setRowSelected={setRowSelected}
                                  mode={'ATM-ES2'} />
                { !evCantidades &&
                  <Typography>Hay items con cantidades 0</Typography>
                }
                { formValues.recursos.length === 0 &&
                  <Typography>No hay items</Typography>
                }
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Justificación de la salida del holotipo'} />
              </Grid>
              <Grid item xs={8} >
                <MySelect id={'responsableEnvio'}
                          label={'Responsable del envío *'}
                          data={mp?.Personal?.personal?.map(it => it.nombre)}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                          canEdit={canEdit} />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2} >
                <Box sx={{p:'0.9rem 1rem 0 0'}}>
                  <MyDatePicker id={'fechaRetorno'}
                                setFormValues={setFormValues}
                                formValues={formValues}
                                canEdit={canEdit}
                                label={'Fecha de retorno'} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <MyAreaTextField id='justificacion'
                                 label={'Justificación de la salida *'}
                                 formValues={formValues}
                                 icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                                 canEdit={canEdit}
                                 handleChange={(e) => {
                                   if(canEdit) {
                                     setFormValues({...formValues, justificacion: e.target.value})
                                   }
                                 }} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 0'}}>
                  <MyUpload id={'certificadoDeposito'}
                            label={'Certificado de depósito'}
                            dir={instanciaProceso?.solicitud?.id}
                            canEdit={canEdit}
                            formValues={formValues}
                            setFormValues={setFormValues} />
                  <Box sx={{p:'3rem 0 0 0'}}>
                    <MySwitch id={'integridadHolotipo'}
                              label={'¿Se garantiza la integridad del holotipo en cuestión y su retorno en óptimas condiciones después del tiempo de salida establecido?'}
                              formValues={formValues}
                              setFormValues={setFormValues}
                              canEdit={canEdit}
                              handleChange={handleChange} />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Declaración de veracidad de la información'}/>
              </Grid>
              <Grid item xs={12}>
                <TextField id='texto'
                           multiline
                           rows={10}
                           value={formValues.texto}
                           fullWidth
                           variant='standard'
                           aria-readonly={true}
                           sx={dialog.textTypography}
                           InputProps={{
                             disableUnderline: true,
                             sx: {
                               fontSize: '12px',
                               backgroundColor: 'transparent',
                             }
                           }}
                           InputLabelProps={{
                             sx: {
                               fontSize: '14px',
                             }
                           }} />
              </Grid>
              <Grid item xs={12} sx={{pr:'2rem'}}>
                <MySwitch id={'si'}
                          label={'¿Aceptar y enviar?'}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          canEdit={canEdit}
                          fullWidth={false}
                          handleChange={handleChange} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P10ATMRO payload={ATM} />
          </TabPanel>
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  {
                    (canEdit)?
                      <>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => {
                                         if(counter <= 2) {
                                           dispatch(handleClear())
                                         } else {
                                           alert('Debe GUARDAR los cambios realizados')
                                         }
                                       }} />
                        <MyButtonBacan label={'Guardar'} onClick={() => {
                                         const newPayload= {...payload ,[section]: {...formValues}}
                                         dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
                                         setCounter(0)
                                       }}
                                       disabled={counter <= 0}
                                       icon={SaveOutlinedIcon} />
                        <MySendButton disabled={
                                        counter > 0 ||
                                        formValues.ubicacionPais === '' ||
                                        formValues.ubicacionInstitucion === '' ||
                                        formValues.certificadoDeposito === '' ||
                                        formValues.responsableEnvio === '' ||
                                        formValues.justificacion === '' ||
                                        formValues.si === false ||
                                        f.parseDate(formValues.fechaRetorno) < f.parseDate(formValues.fecha) ||
                                        !evCantidades
                                      }
                                      label={'enviar'}
                                      onSend={ () => {
                                        const metadata = JSON.stringify({"solicitudId": `${instanciaProceso?.solicitud?.id}`,})
                                        dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                      }} />
                      </>
                      :
                      <Grid item xs={4} style={{padding:'0 24px 0 0'}}>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => dispatch(handleClear())} />
                      </Grid>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
  else return null
}
