import React, { useEffect } from 'react'
import { Alert, AlertTitle } from '@mui/material';
import { ChatController, MuiChat } from 'chat-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { guardarChat, retornaChatsxRoom } from './API';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import OutputIcon from '@mui/icons-material/Output';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ScrollToBottom from 'react-scroll-to-bottom';
import { css } from '@emotion/css';
import "./swalestilo.css"

export const ChatForm = () => {

  const dispatch = useDispatch();
  const { room, chats } = useSelector(state => state.chat)
  const { id } = useSelector(state => state.app.perfilUsuario)
  const [chatCtl] = React.useState(new ChatController())

  const ROOT_CSS = css({
    height: 600,
    width: 800
  })

  // chatCtl.clearMessages()

  React.useEffect(() => {
    chatCtl.clearMessages();
    room && chats.map((chat) => (     
      chatCtl.addMessage({
        type: 'text',
        content: `${chat?.messageText  + '\n' + (chat?.dateCreated || '')}`,
        self: id === chat?.participantId ? true : false,
      })
    ))
  }, [chats])
  
  React.useEffect(() => {
    chatCtl.clearMessages();
    let interval = setInterval(() => {
        room && dispatch(retornaChatsxRoom(room[0].id));
        chatCtl.clearMessages();
        chats.map((chat) => (
          chatCtl.addMessage({
            type: 'text',
            content: `${chat?.messageText + '\n' + (chat?.dateCreated || '')}`,
            self: id === chat?.participantId ? true : false,
          })
        ))
    }, 15000);

    return () => clearInterval(interval);
  }, [chats])
  
  chatCtl.setActionRequest({ type: 'text', always: true, sendButtonText: 'Enviar', }, (response) => {
    const valores = { room: room[0]?.id, usuario: id, texto: response.value }
    dispatch(guardarChat(valores));
  });
 
  return (
    <>
      {
        room ?
          <div >
            <Alert severity="success">
              <AlertTitle>
                <strong><StickyNote2Icon /> Tarea:</strong> {room[0]?.roomName || ''}
                <br></br>
                <strong><OutputIcon /> Funcionario responde: </strong> {room[0]?.nombrePersona || ''}
                <br></br>
                <strong><ManageAccountsIcon /> Cargo: </strong> {room[0]?.cargoPersona || ''}                
              </AlertTitle>
            </Alert>
            <ScrollToBottom className={ROOT_CSS}>
              <div>                
                <MuiChat chatController={chatCtl} />
              </div>
            </ScrollToBottom>
          </div>

          :
          <div>
            <Alert severity="info">Seleccione un chat</Alert>
          </div>
      }
    </>
  )
}
