import React, {useState} from 'react'
import Box from '@mui/material/Box'
import {
  Grid,
  Typography,
} from '@mui/material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import {MyTableRecursos} from "../../../components/MyTableRecursos";
import {f} from "../../../commons";
import {MyTableMuestras} from "../../../components/MyTableMuestras";
import {muestras} from "../CONF";

export const RecursosInSituRO = ({payload}) => {
  const section = 'RecursosInSitu'
  const recursos = payload[section]
  const recursosMAATE = f.isValid(payload['InformeTecnicoMaate'])?payload['InformeTecnicoMaate']:recursos
  const canEdit = false
  const [recursoSelected, setRecursoSelected] = useState({})

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Recursos'} />
        </Grid>
        <Grid item xs={12}>
          <MyTableRecursos id={'recursos'}
                           formValues={recursos}
                           muestras={recursos.muestras}
                           columnName={'Recursos'}
                           canEdit={canEdit}
                           setRecursoSelected={setRecursoSelected}
                           canDeleteRow={canEdit} />
        </Grid>
        {
          f.isValid(recursoSelected.scientificname) &&
            <>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Muestras y submuestras'} />
              </Grid>
              <Grid item xs={12} >
                {recursoSelected.scientificname}
              </Grid>
              <Grid item xs={12} >
                <MyTableMuestras id={'muestras'}
                                 selected={recursoSelected}
                                 formValues={recursosMAATE}
                                 canEdit={canEdit}
                                 mode={'maatero'}
                                 muestras={muestras} />
              </Grid>
            </>
        }
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Ubicación Geográfica--'} />
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{fontSize:'0.9rem', fontFamily:RobotoCondensedRegular}}>Provincias</Typography>
          {recursos.provincias.map((it, idx) => <Typography key={idx} sx={{fontSize:'0.8rem', fontFamily:RobotoCondensedRegular, m:'1rem'}}>{it}</Typography>)}
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{fontSize:'0.9rem', fontFamily:RobotoCondensedRegular}}>Áreas protegidas</Typography>
          {recursos.areasProtegidas.map((it, idx) => <Typography key={idx} sx={{fontSize:'0.8rem', fontFamily:RobotoCondensedRegular, m:'1rem'}}>{it}</Typography>)}
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{fontSize:'0.9rem', fontFamily:RobotoCondensedRegular}}>Bosques Protectores</Typography>
          {recursos.bosquesProtectores.map((it, idx) => <Typography key={idx} sx={{fontSize:'0.8rem', fontFamily:RobotoCondensedRegular, m:'1rem'}}>{it}</Typography>)}
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Destino de los recursos'} />
        </Grid>
        <Grid item xs={12} >
          <Typography sx={{fontSize:'0.9rem', fontFamily:RobotoCondensedRegular}}>
            Instituciones en la que se desarrollará la fase de laboratorio
          </Typography>
          {recursos.laboratorios.map((it, idx) => <Typography key={idx} sx={{fontSize:'0.8rem', fontFamily:RobotoCondensedRegular, m:'1rem'}}>{it}</Typography>)}
        </Grid>

      </Grid>
    </Box>
  )
}
