import { Avatar } from '@mui/material';
import React from 'react'
import { fetchImagenAvatar } from './API';

function stringAvatar(name) {
    let nombre
    if (name?.split(' ')[1]) {
        nombre = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    } else {
        nombre = `${name.split(' ')[0][0]}`
    }
    return {
        sx: {
            bgcolor: '#9A9A9A',
        },
        children: nombre,
    };
}

export const AvatarResponde = (id, nombre) => {

    const [imagenDisplay, setImagenDisplay] = React.useState()
    const [errorIma, setErrorIma] = React.useState('')

    async function mostrarImagen(id) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = await fetchImagenAvatar(id).catch(error => setErrorIma(error));        
        setImagenDisplay(a.href)
    }

    React.useEffect(() => {
        if (id?.id) {
            mostrarImagen(id.id)
        } else {
            setErrorIma("sin id")
        }
    }, [])

    return (
        <div>
            {
                errorIma === '' ? <Avatar alt="responde" src={imagenDisplay} />
                    :
                    <Avatar key={`A_${id}`}
                        {...stringAvatar(id?.nombre || 'No')}
                    />
            }
        </div>
    )
}
