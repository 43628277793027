import React, {useEffect} from 'react'
import {Box} from "@mui/material"
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {f} from 'src/commons/f'
import P01SolicitudAsignacionCaso from 'src/features/P01Solicitud/P01SolicitudAsignacionCaso'
import P14NotificarRespuesta from "./P14NotificarRespuesta";
import P14InformarResultados from "./P14InformarResultados";
import P14JustificaciNAmpliacionPlazo from "./P14JustificaciónAmpliacionPlazo";
import P14RevisarResultado from "../P01Solicitud/P14RevisarResultado";
import P14RevisarSolicitud from "./P14RevisarSolicitud";

export const Widget = () => {
  const navigate = useNavigate()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = instanciaProceso?.solicitud
  const solicitudesAprobadas = useSelector(state => state.app.solicitudesAprobadas)
  const atms = useSelector(state => state.app.atms)

  useEffect(()=> {
    if(!f.isValid(instanciaTarea.id)) {navigate('/')}
  }, [instanciaTarea, navigate])

  useEffect(() => {window.scrollTo(0, 0)}, [])

  if(f.isValid(instanciaTarea.id) && f.isValid(instanciaProceso.id) ) {
    const formulario = {
      '55000020_Activity_NotificarRespuesta_WF14': () => <P14NotificarRespuesta id={'55000020_Activity_NotificarRespuesta_WF14'}
                                                                                instanciaTarea={instanciaTarea}
                                                                                instanciaProceso={instanciaProceso}
                                                                                solicitud={solicitud}
                                                                                perfilUsuario={perfilUsuario}
                                                                                solicitudesAprobadas={solicitudesAprobadas}
                                                                                atms={atms} />,
      '55000020_Activity_InformarResultados_WF14': () => <P14InformarResultados id={'55000020_Activity_InformarResultadosWF14'}
                                                                               instanciaTarea={instanciaTarea}
                                                                               instanciaProceso={instanciaProceso}
                                                                               solicitud={solicitud}
                                                                               perfilUsuario={perfilUsuario}
                                                                               solicitudesAprobadas={solicitudesAprobadas}
                                                                               atms={atms} />,
      '55000021_Activity_AsignarCasoInformeResultados_WF14': () => <P01SolicitudAsignacionCaso id='55000021_Activity_AsignarCasoInformeResultados_WF14' />,
      '55000020_Activity_SolicitarAmpliacionPlazo_WF14': () => <P14JustificaciNAmpliacionPlazo id={'55000020_Activity_InformarResultadosWF14'}
                                                                                               instanciaTarea={instanciaTarea}
                                                                                               instanciaProceso={instanciaProceso}
                                                                                               solicitud={solicitud}
                                                                                               perfilUsuario={perfilUsuario}
                                                                                               solicitudesAprobadas={solicitudesAprobadas}
                                                                                               atms={atms} />,
      '55000021_Activity_RevisarResultados_WF14': () => <P14RevisarResultado id={'55000020_Activity_InformarResultadosWF14'}
                                                                             instanciaTarea={instanciaTarea}
                                                                             instanciaProceso={instanciaProceso}
                                                                             solicitud={solicitud}
                                                                             perfilUsuario={perfilUsuario}
                                                                             solicitudesAprobadas={solicitudesAprobadas}
                                                                             atms={atms} />,
      '55000021_Activity_AsignarCasoAmpliacionPlazo_WF14': () => <P01SolicitudAsignacionCaso id='55000021_Activity_AsignarCasoAmpliacionPlazo_WF14' />,
      '55000021_Activity_RevisarSolicitud_WF14': () => <P14RevisarSolicitud id={'55000020_Activity_InformarResultadosWF14'}
                                                                            instanciaTarea={instanciaTarea}
                                                                            instanciaProceso={instanciaProceso}
                                                                            solicitud={solicitud}
                                                                            perfilUsuario={perfilUsuario}
                                                                            solicitudesAprobadas={solicitudesAprobadas}
                                                                            atms={atms} />,
    }[instanciaTarea?.tareaCodigoTarea]
    if(f.isValid(formulario)) {
      return formulario()
    } else {
      return <Box sx={{m:'80px 0 0 0'}}>{`ERROR, EN FORMULARIO PARA TAREA ${instanciaTarea?.nombreTarea} .- (${instanciaTarea?.tareaCodigoTarea})`}</Box>
    }
  }
  else return (
    <Box sx={{m:'80px 0 0 0', height:'80px'}}>
      {'loading'}
    </Box>
  )
}
