import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { Usuarios } from './Usuarios';
import { cerrarModalUsuario } from './sliceUsuarios'

const botonAzul = {
  mt: 1, ml: 2, maxWidth: 200,
  textTransform: 'none',
  borderRadius: '0.2rem',
  color: 'rgba(255, 255, 255, 0.9)',
  backgroundColor: 'rgba(54, 160, 184, 1)',
  "&:hover": {
    backgroundColor: 'rgba(54, 138, 184, 1)',
  }
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const Widget = (estado) => {

  const dispatch = useDispatch()

  const { modalUsuarioOpen, tipoSeleccionado } = useSelector(state => state.usuarios)

  const handleClose = () => {
    dispatch(cerrarModalUsuario());
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalUsuarioOpen}
        maxWidth={tipoSeleccionado === 'INVESTIGADOR' ?  'md' : (tipoSeleccionado === 'FUNCIONARIO' ? 'sm' : 'xs') }
        fullWidth
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {'Creación de  cuenta'}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Usuarios />
        </DialogContent>
        <DialogActions>
          <Button autoFocus
            onClick={handleClose} variant="contained" sx={botonAzul}
            startIcon={<CancelIcon />}>
            {'Cancelar'}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
