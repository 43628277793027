import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, Button, ButtonGroup, CssBaseline, Grid, Stack, Tab,} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'

import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
// import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import API from "src/features/App/API";
// import {MyTextField} from "src/components/MyTextField";
// import {MyAreaTextField} from "src/components/MyAreaTextField";
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {MySendButton} from 'src/components/MySendButton'
// import {MyReabOnlyTableRecursos} from 'src/components/MyReadOnlyTableRecursos'
import {MySubtitle} from 'src/components/MySubtitle'
import {Solicitud2} from 'src/features/P01Solicitud/subcomponents/Solicitud2'
import {f} from 'src/commons/f'
import GLOBALS from 'src/features/App/globals'
import {SectionTitle} from "../../components/SectionTitle";
import Toolbar from "@mui/material/Toolbar";
import {MyButtonBacan} from "../../components/MyButtonBacan";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import {MyTableMuestras} from "../../components/MyTableMuestras";
import {AccountCircle, Badge} from "@mui/icons-material";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
// import UploadIcon from "@mui/icons-material/Upload"
// import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "./P0102PayloadRO";
// import {MySwitch} from "../../components/MySwitch";
// import {MyDatePicker} from "../../components/MyDatePicker";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {P0102Expediente} from "./P0102Expediente";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({id}) => {
  // console.log('::::: id ', id)
  const section = 'ElaborarPermiso'
  const dispatch = useDispatch()
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  // const dirName = instanciaTarea.tareaCodigoTarea==='permiso'
  // const titulo = instanciaTarea.tareaCodigoTarea==='55000015_Activity_SuscribirPermisoWF0102'?'Suscribir Permiso':'Elaborar Permiso'
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  // const funcionarios = useSelector(state => state.app.funcionarios)
  const inputRef = useRef()
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:          today,
    identificador:  solicitud.numeroSolicitud,
    tiempoVigencia: '' + payload.Propuesta.plazo + ' meses',
    contenido:      '',
    pdf:            '',
    asunto:         '',
    detalle:        '',

    // firma electronica ---\\
    pdfSigned:       '',
    firmando:        false,
    firmaTareaId:    0,
    firmado:         false,
    // firma electronica ---//

  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const [recursoSelected, setRecursoSelected] = useState({})
  const plantillaId = '56000023-1'
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab);
  }
  const docx = (payload.ElaborarPermiso.docxLded === "")?payload.ElaborarPermiso.docx:payload.ElaborarPermiso.docxLded
  const pdf = (payload.ElaborarPermiso.pdfLded === "")?payload.ElaborarPermiso.pdf:payload.ElaborarPermiso.pdfLded
  const labelTab4 = ['55000002_Activity_SuscribirPermiso'].includes(id)?'Solicitud':'Solicitud Modificatoria'
  const labelTab2 = ['55000002_Activity_SuscribirPermiso'].includes(id)?'Expediente':'Expediente Modificatoria'

  const getOnSave = (setCounter, fv=null) => {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(instanciaProceso?.id, payload, () => setCounter(0)))
    };
  }

  // firma electronica ---\\
  useEffect(() => {
    if(formValues.firmando && !formValues.firmado) {
      API.testFirmarPdf(instanciaTarea.id).then((res) => {
        if(res?.resultado) {
          setFormValues({...formValues, firmando: false, firmado: true})
        }
      })
    }
  },[])
  useEffect(() => {
    if(formValues.firmando) {
      getOnSave(setCounter)()
    }
  },[formValues.firmando])
  // firma electronica ---//
  console.log(':: firma :: ', JSON.stringify({id:instanciaTarea.id, firmaTareaId:formValues.firmaTareaId, pdfSigned: formValues.pdfSigned, firmando: formValues.firmando, firmado: formValues.firmado}))

  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Suscribir permiso'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label={labelTab2} value="2" />
              <Tab label={labelTab4} value="4" />
              {(f.isValid(payload?.solicitudOriginal))?<Tab label="Solicitud original" value="3" />:false}
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Expediente original`} value="6"/> : null
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={accordeonBox.container2}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Solicitud2 solicitud={solicitud} />
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Resolución'} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row"
                       justifyContent="space-between"
                       alignItems="center"
                       spacing={2} >
                  <MyReadOnlyTextField id='identificador'
                                       label={'Identificador'}
                                       value={payload.Resolucion?.identificador}
                                       icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>} />
                  <MyReadOnlyTextField id='fecha'
                                       label={'Fecha'}
                                       value={payload.Resolucion?.fecha}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>} />
                  <MyButtonBacan label={'VER PDF'}
                                 onClick={() => {
                                   const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${solicitud?.id}/resolucion-${nombreOrganizacion}/resolucion-${formValues.identificador}-${nombreOrganizacion}-firmado.pdf`
                                   fetch(url)
                                     .then((res) => { return res.blob(); })
                                     .then((data) => {
                                       const dataPdf = new Blob([data], { type: 'application/pdf' })
                                       const a = document.createElement("a")
                                       a.href = window.URL.createObjectURL(dataPdf)
                                       a.target="_blank"
                                       a.click()
                                     })
                                 }}
                                 myTip={'Ver resolución'}
                                 icon={FileDownloadOutlinedIcon} />
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Permiso de Investigación'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0'}}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                  <MyReadOnlyTextField id={'fecha'}
                                       label={'Fecha'}
                                       value={formValues['fecha']}
                                       icon={<CalendarMonthIcon  sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
                  <MyReadOnlyTextField id={'tiempoVigencia'}
                                       label={'Tiempo de Vigencia'}
                                       value={formValues['tiempoVigencia']}
                                       icon={<Badge sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}}/>} />
                  <MyButtonBacan label={'VER PDF'}
                                 onClick={() => {
                                   const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${solicitud?.id}/permiso-${nombreOrganizacion}/${pdf}`
                                   fetch(url)
                                     .then((res) => { return res.blob(); })
                                     .then((data) => {
                                       const dataPdf = new Blob([data], { type: 'application/pdf' })
                                       const a = document.createElement("a")
                                       a.href = window.URL.createObjectURL(dataPdf)
                                       a.target="_blank"
                                       a.click()
                                     })
                                 }}
                                 myTip={'Ver permiso/autorización'}
                                 icon={FileDownloadOutlinedIcon} />
                </Stack>
              </Grid>
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 0 0 8rem'}}>
                  <ButtonGroup variant={'contained'}>
                    {
                      formValues.pdfSigned !== '' ?
                        <MyButtonBacan3 label={''}
                                        width={'3rem'}
                                        onClick={() => {
                                          setFormValues({
                                            ...formValues,
                                            // firma -- \\
                                            firmaTareaId: 0,
                                            pdfSigned:    '',
                                            firmando:     false,
                                            firmado:      false,
                                            // firma -- //
                                          })
                                        }}
                                        icon={RestartAltIcon} /> : false
                    }
                    <MyButtonBacan3 label={'DOCX'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadDOCX({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder: `permiso-${nombreOrganizacion}`,
                                      // filename: `permiso-${formValues.identificador}-${nombreOrganizacion}.docx`,
                                      filename: docx,
                                    })}
                                    width={'5rem'} />
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder: `permiso-${nombreOrganizacion}`,
                                      // filename: `permiso-${formValues.identificador}-${nombreOrganizacion}.pdf`
                                      filename: pdf
                                    })}
                                    width={'5rem'} />
                  </ButtonGroup>
                  <ButtonGroup variant={'contained'}>
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'PDF (firmado)'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={`permiso-${nombreOrganizacion}`}
                                        fileName={`permiso-${formValues.identificador}-${nombreOrganizacion}-firmado.pdf`}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: `permiso-${formValues.identificador}-${nombreOrganizacion}-firmado.pdf`, firmado: true, firmando: false})}
                                        // afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                        toolTip={'Subir documento firmado en formato pdf'}
                                        width={'9rem'}
                                        disabled={formValues.firmando || formValues.firmado}/>
                    <MyButtonBacan3 label={'FIRMAR'}
                                    icon={FileDownloadIcon}
                                    onClick={()=> {
                                      API.firmarPdf(instanciaTarea.id, `permiso-${nombreOrganizacion}`, pdf).then((res)=> {
                                        if(res?.length > 0) {
                                          const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                          const firmaTareaId = res[0].firmaTareaId
                                          setFormValues({...formValues, firmando:true, firmado:false, pdfSigned, firmaTareaId})
                                        }
                                      })
                                    }}
                                    disabled={formValues.firmado}
                                    toolTip={'firmar electronicamente el documento'}
                                    width={'9rem'} />
                    {
                      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                     icon={FileDownloadIcon}
                                                                     onClick={() => API.fetchDownloadPDF({
                                                                       solicitudId: instanciaProceso?.solicitud?.id,
                                                                       subfolder:`permiso-${nombreOrganizacion}`,
                                                                       filename: `permiso-${formValues.identificador}-${nombreOrganizacion}-firmado.pdf`,
                                                                     })}
                                                                     width={'3rem'}/>
                    }
                  </ButtonGroup>
                </Stack>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={payload}/>
          </TabPanel>
          <TabPanel value="4">
            <P0102Expediente payload={payload}/>
          </TabPanel>
          {(f.isValid(payload?.solicitudOriginal)) ?
            <TabPanel value="3">
              <P0102PayloadRO payload={payload?.solicitudOriginal?.payloadSolicitud}/>
            </TabPanel> : false
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="6">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container >
              <Grid item xs={12}>
                <Stack direction="row"
                       justifyContent="space-between"
                       alignItems="center"
                       spacing={2}
                       sx={{p:0, m:'-1rem 4rem 0 4rem'}}>
                  <MyGobackButton onGoback={() => dispatch(handleClear())} />
                  <MySaveButton onSave={() => {
                                  payload[section]=formValues
                                  dispatch(handleSaveSolicitud(
                                    instanciaProceso?.id,
                                    payload,
                                    () => setCounter(0)
                                  ))
                                }}
                                disabled={counter <= 0 } />
                  <MySendButton onSend={() => {
                                  if(instanciaTarea.tareaCodigoTarea !== '55000015_Activity_SuscribirPermisoWF0102') {
                                    API.fetchSuspenderSolicitud(payload.solicitudOriginal.idSolicitud, true).then(res => {
                                      const metadata = JSON.stringify({
                                        "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                        "adjunto": `permiso-${formValues.identificador}-${nombreOrganizacion}-firmado.pdf`,
                                        "carpeta": `permiso-${nombreOrganizacion}`,
                                      })
                                      dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                    })
                                  } else {
                                    const metadata = JSON.stringify({
                                      "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                      "adjunto": `permiso-${formValues.identificador}-${nombreOrganizacion}-firmado.pdf`,
                                      "carpeta": `permiso-${nombreOrganizacion}`,
                                    })
                                    dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                  }
                                }}
                                label={'enviar'}
                                disabled={counter > 0 || formValues.pdfSigned === '' || formValues.pdfSigned === '' || !formValues.firmado} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}
