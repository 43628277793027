import React, {useEffect, useRef, useState} from 'react'
import {Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import {
  handleSaveSolicitud,
  handleClear,
  handCompletarTareaCoordinador
} from 'src/features/App/sliceApp'
import {MySendButton} from 'src/components/MySendButton'
import {SectionTitle} from 'src/components/SectionTitle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import {f} from 'src/commons/f'
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MySubtitle} from "../../components/MySubtitle";
import {Email} from "@mui/icons-material";
import API from 'src/features/App/API'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {MySwitch} from "../../components/MySwitch";
import GLOBALS from "../App/globals";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "../P01Solicitud/P0102PayloadRO";
import {MyTable as MyTableObservaciones} from "../../components/MyTable";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {MyTableCheck} from "../../components/MyTableCheck";
import {MyButtonAlert} from "../../components/MyButtonAlert";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch()
  const section = 'InformeTecnicoMaate'
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:          today,
    identificador:  solicitud.numeroSolicitud,
    observaciones:  [{subsanada:false, item:'', ritem:''}],
    observacionesReporte:  [''],
    serial:         '',
    docx:           '',
    pdf:            '',
    subsanacion:    0,
    seAprueba:      false,
  }
  const canEdit = instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, 'entrada', setFormValues, formValues)
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => {setMytab(newTab)}
  const inputRef = useRef()
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `informe-tecnico-${nombreOrganizacion}`
  const filename = `informe-tecnico-${formValues.identificador}-${nombreOrganizacion}`
  const codigoAutorizador = {
    'Maate':    1143,
    'Senadi':   1148,
    'Senescyt': 1139,
  }[nombreOrganizacion]
  const codigoCoordinador = {
    'Maate':    1144,
    'Senadi':   1148,
    'Senescyt': 164,
  }[nombreOrganizacion]
  const funcionarios = useSelector(state => state.app.funcionarios)
  const autorizador = funcionarios.filter(it => it.idPerfil === codigoAutorizador)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === codigoCoordinador)[0]
  // const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
          instanciaProceso?.id,
          payload,
          () => setCounter(0)
        )
      )
    }
  }
  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
      <AppBar position='fixed' color='primary' elevation={0} sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Pronunciamiento Maate'} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
          <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{p:'0 2rem 0 2rem'}}>
            <Grid container >
              {f.isValid(payload.T55000023_Activity_AsignarCasoMaate_WF15?.observaciones) && payload.T55000023_Activity_AsignarCasoMaate_WF15?.observaciones !== '' &&
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}
                         style={{padding: '1rem 24px 1rem 0'}}>
                    <MyButtonAlert label={'Directriz del caso'}
                                   color0={'#888888'}
                                   color1={'#888888'}
                                   messageTitle='Directriz del caso'
                                   messageContent={payload.T55000023_Activity_AsignarCasoMaate_WF15?.observaciones}/>
                  </Stack>
                </Grid>
              }
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Reporte de cumplimiento'} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'1rem 1rem 1rem 0'}}>
                  <MyReadOnlyTextField id={'solicitudNumero'}
                                       value={solicitud?.numeroSolicitud}
                                       label={'Identificador'}
                                       icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                  <MyReadOnlyTextField id={'fecha'}
                                       value={payload.InformeSeguimiento?.fecha}
                                       label={'Fecha'}
                                       icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                  <MyButtonBacan3 label={'Informe'}
                                  icon={FileDownloadIcon}
                                  onClick={() => API.fetchDownloadPDF({
                                    solicitudId: instanciaProceso?.solicitud?.id,
                                    subfolder:'informe-cumplimiento-investigador',
                                    filename: payload.InformeSeguimiento.pdf
                                  })}
                                  toolTip={'Descargar documento en formato pdf'}
                                  width={'7rem'} />
                  {(payload.InformeSeguimiento?.anexo !== '') ?
                    <MyButtonBacan label={'Anexo'}
                                   onClick={() => {
                                     const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/anexo/${payload.InformeSeguimiento?.anexo}`
                                     fetch(url)
                                       .then((res) => {
                                         return res.blob();
                                       })
                                       .then((data) => {
                                         const dataPdf = new Blob([data], {type: 'application/pdf'})
                                         const a = document.createElement("a")
                                         a.href = window.URL.createObjectURL(dataPdf)
                                         a.target = "_blank"
                                         a.click()
                                       })
                                   }}
                                   icon={FileDownloadOutlinedIcon}/> : null
                  }
                </Stack>
              </Grid>
              <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
                <MySubtitle subtitle={'Observaciones para subsanación'} />
              </Grid>
              <Grid item xs={12}>
                <MyTableCheck id={'observaciones'}
                              formValues={formValues}
                              setFormValues={setFormValues}
                              columnName={'Observaciones *'}
                              canEdit={canEdit}
                              addItem={() => {
                                  const field = 'observaciones'
                                  const newSet = [ ...formValues[field], {subsanada:false, item:'', ritem:''} ]
                                  const newFormValues = {...formValues, [field]:newSet}
                                  setFormValues(newFormValues)
                              }} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={1} style={{padding:'1rem'}}>
                  {(formValues.subsanacion + 1 <= 3) ?
                    <MySendButton onSend={() => {
                                    payload[section] = {...formValues, subsanacion: formValues.subsanacion + 1}
                                    payload['InformeSeguimiento'].docx = ''
                                    payload['InformeSeguimiento'].pdf = ''
                                    // console.log('Payload ::: ', payload)
                                    setCounter(0)
                                    dispatch(handleSaveSolicitud(instanciaProceso?.id, payload))
                                    const metadata = JSON.stringify({
                                      "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                      "perfilUsuarioSolicitud": Number(payload?.Solicitud?.solicitudAprobada?.idPerfilUsuario),
                                      "informeCompletoMaate": false,
                                      "subsanacionMaate": true,
                                    })
                                    dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                  }}
                                  label={`Subsanar ${formValues.subsanacion + 1}`}
                                  disabled={!canEdit || counter > 0 || formValues?.observaciones?.filter(it => it.item !== '')?.length === 0}
                                  icon={SaveOutlinedIcon}/> : null
                  }
                </Stack>
              </Grid>
              <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
                <MySubtitle subtitle={'Observaciones para el informe'} />
              </Grid>
              <Grid item xs={12}>
                <MyTableObservaciones id={'observacionesReporte'}
                                      formValues={formValues}
                                      setFormValues={setFormValues}
                                      columnName={'Observaciones *'}
                                      canEdit={canEdit}
                                      addItem={() => {
                                        if(formValues['observacionesReporte'].filter(it => it === '').length === 0) {
                                          const field = 'observacionesReporte'
                                          const newSet = [ ...formValues[field], '']
                                          const newFormValues = {...formValues, [field]:newSet}
                                          setFormValues(newFormValues)
                                        }
                                      }} />
              </Grid>
              <Grid item xs={9}></Grid>
              <Grid item xs={3}>
                <MySwitch id='seAprueba'
                          label={'¿Informe completo?'}
                          formValues={formValues}
                          fullWidth={false}
                          canEdit={canEdit}
                          handleChange={handleChange}/>
              </Grid>
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1} style={{padding:'0 0 0 1rem'}}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = 'ITMAT'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave({...formValues, serial:serial})()
                                            setFormValues({...formValues, serial:serial})
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar Informe'}
                                      width={'11rem'}
                                      onClick={() => {
                                        const doc = MyTagged2Docx(plantilla(payload), nombreOrganizacion, P0102API.mapping({
                                          solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                          payload,
                                          autorizador,
                                          coordinador,
                                          perfilUsuario,
                                          nombreOrganizacion,
                                          tipo: instanciaProceso.tipoInstanciaProceso?.toLowerCase(),
                                          section: payload[section]
                                        }))
                                        API.genDocxAnPdf({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          document: doc,
                                          formValues,
                                          setFormValues,
                                          subfolder,
                                          filename: filenameDOCX,
                                          nombreOrganizacion,
                                        })
                                      }}
                                      toolTip={'Generar'}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: formValues.docx,
                                      })}
                                      toolTip={'Descargar documento en formato docx'}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: formValues.pdf
                                      })}
                                      toolTip={'Descargar documento en formato pdf'}
                                      width={'5rem'} />
                    </>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton inputRef={inputRef}
                                          label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={() => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docx: filenameDOCX, pdf: arr2[arr2.length-1],})
                                              } else {
                                                setFormValues({...formValues, docx: filenameDOCX, pdf: '',})
                                              }
                                            })
                                          }}
                                          toolTip={'Subir documento modificado en formato docx'}
                                          width={'5rem'} />
                    </ButtonGroup>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <P0102PayloadRO payload={payload.Solicitud.solicitudAprobada.payloadSolicitud} />
        </TabPanel>
      </TabContext>
      <CssBaseline />
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'0 4rem 0 4rem'}}>
                <MyButtonBacan label={'Regresar'}
                               myTip={'Regresar a las tareas'}
                               icon={ArrowBackIcon}
                               onClick={() => {dispatch(handleClear())}} />
                <MyButtonBacan label={'Guardar'}
                               myTip={'Guarda el formulario, y permite continuar editando'}
                               onClick={() => {
                                 payload[section]=formValues
                                 if(f.isValid(payload?.InformeSeguimiento?.obsMaate)) {
                                   payload.InformeSeguimiento.obsMaate = formValues.observaciones
                                 }
                                 setCounter(0)
                                 dispatch(handleSaveSolicitud(instanciaProceso?.id,payload))
                               }}
                               disabled={!canEdit || counter <= 0}
                               icon={SaveOutlinedIcon} />
                <MySendButton onSend={ () => {
                                const metadata = JSON.stringify({
                                  "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                  "informeCompletoMaate":true,
                                  "subsanacionMaate":false,
                                })
                                dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'Enviar'}
                              disabled={!canEdit || counter > 0 || formValues.asunto === '' || formValues.detalle === '' || formValues.docx === ''} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

const plantilla = (payload) => {
  const observacionesReporte = payload?.InformeTecnicoMaate?.observacionesReporte?.join('{:}')
  return (""
    + '${TITULO1}INFORME TÉCNICO DE SEGUIMIENTO'
    + '${SALTO}${CENTRAR}No. ' + payload?.InformeTecnicoMaate?.serial
    + '${SALTO}${VAR}Fecha${:}' + payload?.InformeTecnicoMaate?.fecha
    + '${SALTO}'
    + '${SALTO}${TITULO2}1.  ANTECEDENTES'
    + '${SALTO}${TITULO3}1.1  Datos Generales de la autorización'
    + '${SALTO}${VAR}Solicitud:${:}' + payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Resolucion?.identificador
    + '${SALTO}${VAR}Solicitante: ${:}' + payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Solicitante?.nombresCompletos
    + '${SALTO}${VAR}Proyecto:${:}' + payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Propuesta?.nombre
    + '${SALTO}'
    + '${SALTO}${TITULO3}1.2  Datos Generales del informe'
    + '${SALTO}${VAR}Tipo:${:}'  + payload.InformeSeguimiento.tipo
    + '${SALTO}${VAR}Número:${:}' + payload.InformeSeguimiento.numero
    + '${SALTO}${VAR}Número:${:}' + payload.InformeSeguimiento.fecha
    + '${SALTO}'
    + '${SALTO}${TITULO3}Código Orgánico del Ambiente:'
    + '${SALTO}${PARRAFO}....'
    + '${SALTO}'
    + '${SALTO}${TITULO2}2.  DESARROLLO'
    + '${SALTO}'
    + '${SALTO}${TITULO3}2.1  Objetivo'
    + '${SALTO}${PARRAFO}Emitir el informe técnico de seguimiento del permiso/autorización otorgado(a) para el proyecto: ' + `${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Propuesta?.nombre}.`
    + '${SALTO}${TITULO3}2.2  Análisis'
    + '${SALTO}${PARRAFO}Se realizó la revisión técnica al informe de resultados alcanzados hasta la presente fecha presentado, de acuerdo al siguiente detalle: '
    + '${SALTO}'
    + '${SALTO}${TITULO3}2.3  Resultado:'
    + (payload.InformeTecnicoMaate.seAprueba?
      ('${SALTO}${PARRAFO}Caso aprobado: ${SALTO}${PARRAFO}El informe técnico de resultados y avance presentado está dentro de los parámetros establecidos.'):
      ('${SALTO}${PARRAFO}Reporte observado: ${SALTO}${LISTA.SIMPLE}' + `${payload.InformeTecnicoMaate?.observacionesReporte?.join('{:}')}`))
    + '${SALTO}'
    + (f.isValidNotEmpty(observacionesReporte)?'${SALTO}${TITULO3}2.4  Observaciones:':'')
    + (f.isValidNotEmpty(observacionesReporte)?('${SALTO}${LISTA.SIMPLE}' + payload?.InformeTecnicoMaate?.observacionesReporte?.join('{:}')):'')
    + '${SALTO}'
    + '${SALTO}${TITULO2}3. CONCLUSIONES Y RECOMENDACIONES'
    + (payload.InformeTecnicoMaate.seAprueba?
      ('${SALTO}${PARRAFO}' + `Del análisis realizado se evidenció que el informe técnico ${payload.InformeSeguimiento.tipo} con número ${payload.InformeSeguimiento.numero} presentado por ${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Solicitante?.nombresCompletos} para el proyecto de investigación titulado “${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Propuesta?.nombre}”, es pertinente y adecuado conforme el criterio de este Ministerio.`
        + '${SALTO}${PARRAFO}' + `Por lo expuesto, se recomienda la APROBACIÓN del informe del proyecto “${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Propuesta?.nombre}”.`)
      :
      ('${SALTO}${PARRAFO}Del análisis realizado se evidenció que el informe técnico ' + payload.InformeSeguimiento.tipo + ' con número ' + payload.InformeSeguimiento.numero + ' presentado por ' + payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Solicitante?.nombresCompletos + ' para el proyecto de investigación titulado ' + `"${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Propuesta?.nombre}".` + ' no cumple con los parámetros de seguimiento establecidos.'
        + '${SALTO}${PARRAFO}Por lo expuesto, se recomienda RECHAZAR el informe del proyecto ' + `"${payload?.Solicitud?.solicitudAprobada?.payloadSolicitud?.Propuesta?.nombre}".`))
    + '${SALTO}${VAR}Fecha:${:}'+payload?.InformeTecnicoMaate?.fecha
    + '${SALTO3}'
    + '${SALTO}${CENTRARBOLD}Revisado y aprobado por: '
    + '${SALTO}${CENTRAR}<<sistema.nombreAutorizadorSectorialMaate>>'
    + '${SALTO}${CENTRAR}<<sistema.rolAutorizadorSectorialMaate>>'
    + '${SALTO}${CENTRAR}<<usuario.cargoAutorizadorSectorialMaate>>'
    + '${SALTO3}'
    + '${SALTO}${CENTRARBOLD}Elaborado por: '
    + '${SALTO}${CENTRAR}<<usuario.nombre>>'
    + '${SALTO}${CENTRAR}<<usuario.rol>>'
    + '${SALTO}${CENTRAR}<<usuario.cargo>>')
}
