import React from 'react'
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";

export const MyMessageIfTrue = ({varBoolean, gridWidth= 12, message='Message here !', sx={}}) => {
  if(varBoolean === true) {
    return (
      <Grid container>
        <Grid item xs={gridWidth}>
          <Typography sx={{...sx, color:'red'}}>{message}</Typography>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}

export const MyMessageIfFalse = ({varBoolean, gridWidth= 12, message='Message here !', sx={}}) => {
  if(varBoolean === false) {
    return (
      <Grid container>
        <Grid item xs={gridWidth}>
          <Typography sx={{...sx, color:'red'}}>{message}</Typography>
        </Grid>
      </Grid>
    )
  } else {
    return null
  }
}
