import React, { useState } from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarModalPlantilla } from './sliceAdministrador';
import { guardarPlantilla } from './API'

const botonAzul = {
    mt: 1, ml: 2, maxWidth: 200,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
        backgroundColor: 'rgba(54, 138, 184, 1)',
    }
}


export const FormPlantilla = () => {

    const { seleccionadoPlantilla } = useSelector(state => state.administracion)

    const dispatch = useDispatch()
    const [cntd, setCntd] = useState(seleccionadoPlantilla?.contenidoPlantilla)

    const handleSubmit = () => {
        dispatch(guardarPlantilla(seleccionadoPlantilla?.codigoPlantilla, cntd));
    }

    const handleCerrar = () => {
        dispatch(cerrarModalPlantilla());
    }

    const handleChange = (e) => {
        setCntd(e.target.value)
    }

    return (
        <div>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: 490
                    }}
                >
                    <Box component="form" sx={{ mt: 0, width: 800 }}>
                        <Grid container spacing={1}>

                            <Grid item xs={10}>
                                <Typography variant="body2" gutterBottom >
                                    ID:
                                </Typography>
                                <Typography variant="body2" gutterBottom color="text.secondary">
                                    {seleccionadoPlantilla?.id}
                                </Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <Typography variant="body2" gutterBottom >
                                    Código:
                                </Typography>
                                <Typography variant="body2" gutterBottom color="text.secondary">
                                    {seleccionadoPlantilla?.codigoPlantilla}
                                </Typography>
                            </Grid>

                            <Grid item xs>
                                <Typography variant="body2" gutterBottom >
                                    Nombre:
                                </Typography>
                                <Typography variant="body2" gutterBottom color="text.secondary">
                                    {seleccionadoPlantilla?.nombrePlantilla}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    id="contenidoPlantilla"
                                    name="contenidoPlantilla"
                                    label="Contenido"
                                    value={cntd}
                                    onChange={e => handleChange(e)}
                                    autoComplete="off"
                                    fullWidth
                                    multiline
                                    rows={12}
                                />
                            </Grid>

                            <Grid container spacing={1} justifyContent='right' sx={{ mt: 2 }}>
                                <Button onClick={handleSubmit} color='success' variant="contained"
                                    sx={botonAzul}
                                    startIcon={<SaveIcon />}
                                >
                                    {'Guardar'}
                                </Button>
                                <Button color='success' variant="contained"
                                    sx={botonAzul}
                                    startIcon={<HighlightOffIcon />}
                                    onClick={handleCerrar}
                                >
                                    {'Cerrar'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}