import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { retornaRoles, cargarOrganizacion } from './API';
import { useFormikContext } from "formik";


export const InstitucionesSelect = ({ ...props }) => {

    const dispatch = useDispatch();
    const { orgs } = useSelector(state => state.app)
    const [age, setAge] = React.useState('');
    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
        if (props.tipo === 1) {
            setAge(510001);
            dispatch(cargarOrganizacion(510001));
            setTimeout(() => {
                dispatch(retornaRoles(510001));
            }, 500);
        } else {
            setAge(510004);
            dispatch(cargarOrganizacion(510004));
            setTimeout(() => {
                dispatch(retornaRoles(510004));
            }, 500);
        }
    }, [])

    const handleChange = (event) => {
        dispatch(cargarOrganizacion(event.target.value));
        dispatch(retornaRoles(event.target.value));
        setAge(event.target.value);
        setFieldValue("institucion", event.target.value)       
    };

    return (
        <div>
            <FormControl sx={{ width: 330 }}>
                <InputLabel id="institucion-label">Institución</InputLabel>
                <Select
                    labelId="institucion-label"
                    id="institucion"
                    value={age}
                    onChange={handleChange}
                    autoWidth
                    required
                    label="Instituciones"
                >
                    {
                        props.tipo === 1 ?

                            orgs.map((institucion) => (
                                institucion.id === 510001 &&
                                <MenuItem key={institucion.id} value={institucion.id}>{institucion.nombre}</MenuItem>
                            ))
                            :
                            orgs.map((institucion) => (
                                institucion.id !== 510001 &&
                                <MenuItem key={institucion.id} value={institucion.id}>{institucion.nombre}</MenuItem>
                            ))
                    }

                </Select>
            </FormControl>
        </div>
    );
}
