import API from "./API";
import { createSlice } from '@reduxjs/toolkit'


const state0 = {
  errorMsg: null,
  modalUsuarioOpen: false,
  tipoSeleccionado: null,
  investigador: null,
  institucion: null,
  roles: [],
  modalRestaurar: false,
  indentificador: null
}

export const sliceUsuarios = createSlice({
  name: API.sliceName,
  initialState: { ...state0 },
  reducers: {
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload
    },
    modalUsuario: (state, action) => {
      state.modalUsuarioOpen = true
    },
    closeModal: (state, action) => {
      state.modalUsuarioOpen = false
      state.tipoSeleccionado = null
      state.investigador = null
      state.institucion = null
      state.roles = []
      state.indentificador = null
    },
    tipoSeleccionado: (state, action) => {
      state.tipoSeleccionado = action.payload
      state.investigador = null
      state.institucion = null
      state.roles = []
    },
    setInvestigador: (state, action) => {
      state.investigador = action.payload
    },
    getRoles: (state, action) => {
      state.roles = action.payload
    },
    setInstitucion: (state, action) => {
      state.institucion = action.payload
    },
    openModalRestaurar: (state,action) => {
      state.modalRestaurar = action.payload
    },
    identificadorSeleccionado: (state, action) => {
      state.indentificador = action.payload
    },
  }
})

export const {
  setErrorMsg,
  modalUsuario,
  closeModal,
  tipoSeleccionado,
  setInvestigador,
  getRoles,
  setInstitucion,
  openModalRestaurar,
  identificadorSeleccionado
} = sliceUsuarios.actions

// THUNKS !!

export const nuevoUsuario = () => {
  return (dispatch) => {
    dispatch(modalUsuario())
  }
}

export const cerrarModalUsuario = () => {
  return (dispatch) => {
    dispatch(closeModal());
  }
}

export const seleccionarTipo = (tipo) => {
  return (dispatch) => {
    dispatch(tipoSeleccionado(tipo));
  }
}

export const cargarInvestigador = (investigador) => {
  return (dispatch) => {
    dispatch(setInvestigador(investigador));
  }
}

export const cargarRoles = (roles) => {
  return (dispatch) => {
    dispatch(getRoles(roles));
  }
} 

export const seleccionarInstitucion = (institucion) => {
  return (dispatch) => {
    dispatch(setInstitucion(institucion));
  }
}

export const abrirModalRestaurar = (estado) => {
  return (dispatch) => {
    dispatch(openModalRestaurar(estado))
  }
}

export const seleccionarIdentificador = (identificador) => {
  return (dispatch) => {
    dispatch(identificadorSeleccionado(identificador));
  }
}

