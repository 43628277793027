import React, {useEffect, useState} from 'react'
import {AppBar, Box, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, common, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {MyTextField} from "src/components/MyTextField";
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {MySwitch} from "../../components/MySwitch";
import {MySubtitle} from "../../components/MySubtitle";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import Toolbar from "@mui/material/Toolbar";
// import {MyAreaTextField} from "../../components/MyAreaTextField";
import {TabContext, TabList, TabPanel} from "@mui/lab";
// import {MyButtonAlert} from "../../components/MyButtonAlert";
// import {f} from "../../commons";
import {WF03SolicitudRO} from "./P03SolicitudRO";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MyDatePicker} from "../../components/MyDatePicker";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {MyUpload} from "../../components/MyUpload";
import {WF03Expediente} from "./subcomponents/P03Expediente";
import {MyMessageIfTrue} from "../../components/MyMessageIfTrue";

// eslint-disable-next-line import/no-anonymous-default-export
export const P03RegistrarResolucion = ({id, instanciaTarea, instanciaProceso, solicitud, perfilUsuario}) => {
  const section = 'WF03-002-RegistrarResolucion'
  const dispatch = useDispatch()
  const bandeja = useSelector(state => state.app.bandeja)
  let payload = !!solicitud.payload?JSON.parse(solicitud.payload):{[section]:{}}
  payload.solicitudId = solicitud.id
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha: today,
    codigo: payload.INFO.solicitudId,
    resolucionDeInicio : '',
    oposicion: false,
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada' && instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => { setCounter(counter + 1) }, [formValues])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }
  // console.log(':::: >> ', payload.INFO.solicitudId)
  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Registrar resolucion de inicio'}/>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
            <Tab label="Expediente" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Resolución'} />
            </Grid>
            <Grid item xs={4} >
              <Box sx={{width:'25%'}} >
                <MyDatePicker id={'fecha'}
                              canEdit={true}
                              label={'Fecha'}
                              formValues={formValues}
                              setFormValues={setFormValues} />
              </Box>
            </Grid>
            <Grid item xs={4} >
              <Box sx={{width:'80%'}}>
                <MyTextField id={'codigo'}
                             label={'Código'}
                             canEdit={canEdit}
                             setFormValues={setFormValues}
                             formValues={formValues}
                             formErrors={null}
                             error={''}
                             // handleChange={handleChange}
                             icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <MySwitch id={'oposicion'}
                        label={'¿Oposición presentada?'}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        fullWidth={false}
                        canEdit={canEdit}
                        handleChange={handleChange} />
            </Grid>
            <MyMessageIfTrue varBoolean={formValues.oposicion} message={'NOTA: SI HAY OPOSICIÓN el proceso termina inmediatamente'} sx={{ml:'24px'}}/>
            <Grid item xs={12} >
              <MyUpload id={'resolucionDeInicio'}
                        dir={payload.INFO.solicitudId}
                        label={'Resolución de inicio'}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        canEdit={canEdit}
                        info={'info...'} />
            </Grid>
            {(formValues.oposicion === true) ?
              <Grid item xs={12}>
                <Typography>{`La instania de proceso para la atención de la solicitud con identificador ${formValues.codigo} ha sido devuelta por existir oposición`} </Typography>
              </Grid>: false
            }
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <WF03SolicitudRO payload={payload}/>
        </TabPanel>
        <TabPanel value="3">
          <WF03Expediente payload={payload} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton  onSave={() => {
                                    payload[section]= {...formValues, codigo: payload.INFO.solicitudId}
                                    payload.INFO.docs = {
                                      ...payload.INFO.docs,
                                      'd02Resolucion': [
                                        {
                                          'idx':payload.INFO?.docs?Object.keys(payload.INFO.docs).length:0,
                                          'archivo':formValues.resolucionDeInicio,
                                          'solicitudId': payload.INFO.solicitudId,
                                          'desc':`Resolución de oposición`,
                                          'subFolder': 'resolucionDeInicio'
                                        }
                                      ]
                                    }
                                    payload[section]=formValues
                                    dispatch(handleSaveSolicitud(
                                    instanciaProceso?.id,
                                    payload,
                                    () => setCounter(0)
                                  ))
                               }}
                               disabled={counter <= 0} />
                  <MySendButton onSend={() => {
                                  const metadata = JSON.stringify({
                                      "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                      "oposicionPresentada": formValues.oposicion,
                                    }
                                  )
                                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                label={'ENVIAR'}
                                myTip={''}
                                disabled={formValues.resolucionDeInicio === '' || counter > 0}/>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}
