import React, {useEffect, useState} from 'react'
import {Box} from '@mui/system'
import {f} from 'src/commons'
import API from 'src/features/App/API'
import {Grid} from '@mui/material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyTextField} from "src/components/MyTextField";
import {LocationOn} from "@mui/icons-material";
import {MyButtonBacan3} from "src/components/MyButtonBacan3";
import SearchIcon from "@mui/icons-material/Search";
import {MySelect} from "src/components/MySelect";
import {MyTablePublicaciones} from "../../../components/MyTablePublicaciones";
import {MyTableActividadesAcceso} from "../../../components/MyTableActividadesAcceso";
import Typography from "@mui/material/Typography";

// ResponsableTecnico: {
//   rtNumeroRegistro:         '',
//     rtCedula:                 '',
//     rtNombresApellidos:       '',
//     rtEstudiosSuperiores:     '',
//     rtNacionalidad:           '',
//     rtPublicaciones:          [],
// },
export const ResponsableTecnico = ({section, formValues, setFormValues, canEdit, formErrors}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})
  const sectionErrors = (sectionValues.rtNumeroRegistro === '')?'NUMERO DE REGISTRO INVALIDO':'' ||
    (sectionValues.rtCedula === '')?'NUMERO DE CEDULA INVALIDO':'' ||
    (sectionValues.rtNombresApellidos === '')?'NOMBRE INVALIDO':'' ||
    (sectionValues.rtEstudiosSuperiores === '')?'ESTUDIOS INVALIDO':'' ||
    (sectionValues.rtNacionalidad === '')?'NACIONALIDAD INVALIDA':'' ||
    (sectionValues.rtPublicaciones?.length === 0)?'FALTAN PUBLICACIONES':''||
    (sectionValues.rtActividades?.length === 0)?'FALTAN ACTIVIDADES':'' ||
    (sectionValues.rtTipoDocumento === '')?'TIPO DOCUMENTO INVALIDO':''

  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  // const handleChangePhone = (e) => {
  //   if(e.target.value === '' || f.isPhone(e.target.value))
  //     API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  // }
  useEffect(() => {
    if(canEdit && f.isValid(setFormValues)) {
      setFormValues({...formValues,[section]: {...sectionValues}})
    }
  }, [sectionValues])
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Responsable técnico'} />
      </Grid>
      <Grid item xs={3}>
        <MyTextField id={'rtNumeroRegistro'}
                     label={'Número de registro *'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color: 'silver'}}/>}
                     error={f.isValid(sectionErrors) && sectionErrors['rtNumeroRegistro']}
                     canEdit={canEdit}
                     handleChange={handleChange}/>
      </Grid>
      <Grid item xs={2} >
        <Box sx={{p:'1.9rem 0 0 0', width:'100%'}} display={'flex'} justifyContent={'center'}>
          {canEdit?
            <MyButtonBacan3 label={'BUSCAR'}
                            onClick={() => {
                              API.fetchUsuarioSenescytXREGISTRO(sectionValues.rtNumeroRegistro).then((res) =>{
                                setSectionValues({
                                  ...sectionValues,
                                  rtCedula:                 f.StringOrEmpty(res.cedula),
                                  rtNombresApellidos:       f.StringOrEmpty(res.nombresCompletos),
                                  rtNacionalidad:           f.StringOrEmpty(res.nacionalidad),
                                  rtTipoDocumento:          f.StringOrEmpty(res.tipoDocumento),
                                  registroInfo:res,
                                })
                              })
                            }}
                            icon={SearchIcon} /> : null
          }
        </Box>
      </Grid>
      <Grid item xs={5} ></Grid>
      <Grid item xs={2} >
        <MyTextField id={'rtCedula'}
                     label={(sectionValues.rtTipoDocumento === '')?'Cédula | Pasaporte':sectionValues.rtTipoDocumento}
                     formValues={sectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color: 'silver'}}/>}
                     canEdit={false} />
      </Grid>
      <Grid item xs={12} >
        <MyTextField id={'rtNombresApellidos'}
                     label={'Nombres y apellidos'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color: 'silver'}}/>}
                     canEdit={false} />
      </Grid>
      <Grid item xs={6} >
        <MySelect id={'rtEstudiosSuperiores'}
                  label={'Estudios superiores'}
                  data={API.gradosAcademicos}
                  handleChange={handleChange}
                  setFormValues={setSectionValues}
                  formValues={sectionValues}
                  canEdit={canEdit}/>
      </Grid>
      <Grid item xs={6} >
        <MyTextField id={'rtNacionalidad'}
                     label={'Nacionalidad'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color: 'silver'}}/>}
                     canEdit={false} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Publicaciones Importantes'} />
      </Grid>
      <Grid item xs={12}>
        <MyTablePublicaciones  id={'rtPublicaciones'}
                               formValues={sectionValues}
                               setFormValues={setSectionValues}
                               addItem={() => {
                                 setSectionValues({...sectionValues, rtPublicaciones: [...sectionValues.rtPublicaciones, {nombre:'', enlace:''}]})
                               }}
                               canEdit={canEdit} />
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Actividades de acceso a los recursos genéticos'} />
      </Grid>
      <Grid item xs={12}>
        <MyTableActividadesAcceso id={'rtActividades'}
                                  formValues={sectionValues}
                                  setFormValues={setSectionValues}
                                  canEdit={canEdit}
                                  addItem={() => {
                                    setSectionValues({...sectionValues, rtActividades: [...sectionValues.rtActividades, {actividad:'', inicio:null, fin:null, pais:''}]})
                                  }}/>
      </Grid>
      <Grid item xs={12}>
        {
          (sectionErrors !== '') ? <Typography sx={{color:'red'}}>{sectionErrors}</Typography> : false
        }
      </Grid>
    </Grid>
  )
}
