
import { withFormik } from "formik";
import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save';
import { validacionPassword } from "./validacionPassword";
import { guardarNuevoPassword } from "./API"
import { cerrarModalPassword } from "./slicePreferencias";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const form = props => {

    const botonAzul = {
        ml: 2, mt:2,
        textTransform: 'none',
        borderRadius: '0.2rem',
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgba(54, 160, 184, 1)',
        "&:hover": {
            backgroundColor: 'rgba(54, 138, 184, 1)',
        }
    }

    const theme = createTheme();

    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
    } = props;

    return (
        <div >
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="md">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0 }}>
                            <Grid container spacing={1}>

                                <Grid item xs={12}>
                                    <TextField
                                        id="pass1"
                                        name="pass1"
                                        label="Nuevo Password"
                                        value={values.pass1}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.pass1 ? errors.pass1 : ""}
                                        error={touched.pass1 && Boolean(errors.pass1)}
                                        autoComplete="off"
                                        margin="dense"
                                        variant="outlined"
                                        type="password"
                                        fullWidth
                                        required
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        id="pass2"
                                        label="Repita el nuevo password"
                                        value={values.pass2}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.pass2 ? errors.pass2 : ""}
                                        error={touched.pass2 && Boolean(errors.pass2)}
                                        autoComplete="off"
                                        margin="dense"
                                        variant="outlined"
                                        type="password"
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid container spacing={1} justifyContent='right'>
                                    <Button type="submit" variant="contained"
                                        sx={botonAzul}
                                        startIcon={<SaveIcon />}
                                    >
                                        {'Guardar'}
                                    </Button>
                                    <Button color='success' variant="contained"
                                        sx={botonAzul}
                                        startIcon={<HighlightOffIcon />}
                                        onClick={props.handleCerrarModalPassword}
                                    >
                                        {'Cerrar'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </div >
    );
};

const PasswordForm = withFormik({

    enableReinitialize: true,

    mapPropsToValues: (props) => ({
        usuarioId: props.usuario.id,
        pass1: "",
        pass2: "",
    }),

    validationSchema: validacionPassword,

    handleSubmit: (values, { props, setSubmitting }) => {
        props.registroDePassword(values)
        setSubmitting(false);
    }
})(form);

const mapStateToProps = (
    state
) => ({
    usuario: state.app.usuario,
})

const mapDispatchToProps = (dispatch) => ({
    registroDePassword: (values) => {
        dispatch(guardarNuevoPassword(values))
    },
    handleCerrarModalPassword: () => {
        dispatch(cerrarModalPassword());
    }
})

export const FormPassword = connect(mapStateToProps, mapDispatchToProps)(PasswordForm)