
import * as Yup from 'yup';

export const validacionFuncionario = Yup.object().shape({
    nombre: Yup.string()
    .min(3, 'El nombre debe tener al menos 3 caracteres')
    .required('El nombre es obligatorio'),
    textoInicial: Yup.string()
    // .email('Ingrese un correo válido')
    .min(3, 'El correo debe tener al menos 3 caracteres')
    .required('El correo es obligatorio'),
    cedula: Yup.string()
      .matches('^[0-9]*$', "Ingrese solo números")
      .min(10, 'La cédula debe tener al menos 10 caracteres')
      .max(10, 'La cédula no debe tener mas de 10 caracteres')
      .required('Cédula es obligatoria'),
});