import React, { useState } from 'react'
import {Button, Divider, ListItem, ListItemIcon, ListItemText, Popover} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { handleCrearProceso, handleRefreshTareas } from 'src/features/App/sliceApp'
import List from "@mui/material/List"
import { MyConfirm } from 'src/components/MyConfirm'
import { ModalPreferencias } from 'src/features/Preferencias/ModalPreferencias'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ArticleIcon from '@mui/icons-material/Article'

export const MenuInvestigador = () => {
  const dispatch = useDispatch()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (e) => setAnchorEl(e.currentTarget)
  const handleClose = () => { setAnchorEl(null) }
  const open = Boolean(anchorEl)
  const mid = open ? 'simple-popover-3' : undefined

  const [dialogData, setDialogData] = useState({
    title: 'ATENCIÓN',
    message: '[message]',
    openDialog: false,
    okAction: () => {},
    cancelAction: () => alert('[cancelAction]'),
    messsageAfterOk: 'Se ejecutó',
    mode: 'OK_CANCEL_AFTEROK'
  })

  return (
    <>
      <Button variant="contained" aria-describedby={mid} onClick={handleClick} endIcon={<ExpandMoreIcon />}>
        SOLICITUD
      </Button>
      <Popover id={mid}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               transformOrigin={{
                 vertical: 'top',
                 horizontal: 'right',
               }}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'right',
               }} >
        <Box sx={{ p: '0.8rem' }}>
          <List>
            <ListItem key={'WF01'}
                      onClick={() => {
                        setDialogData({
                          ...dialogData,
                          message: 'Va a crear una nueva solicitud de nuevo permiso de investigacion',
                          messsageAfterOk: 'Se ha creado una nueva solicitud de permiso de investigación',
                          openDialog: true,
                          okAction: () => {
                            dispatch(handleCrearProceso(55000014, perfilUsuario?.id, perfilUsuario?.id, {}, 'Permiso'))
                            setTimeout(function () {
                              dispatch(handleRefreshTareas(perfilUsuario.id))
                            }, 1500)
                          }
                        })
                      }}
                      sx={{ p: '0.2rem 0.5rem 0.2rem 0', cursor: 'pointer'  }}>
              <ListItemIcon sx={{ minWidth: '1rem',  m: '0 0.6rem 0 0' }}>
                <ArticleIcon edge="start" checked={false} tabIndex={-1} />
              </ListItemIcon>
              <ListItemText>{'Permiso de investigación científica'}</ListItemText>
            </ListItem>
            <ListItem key={'WF02'}
                      onClick={() => {
                        setDialogData({
                          ...dialogData,
                          message: 'Va a crear una nueva solicitud de nuevo contrato de acceso con potencial uso comercial',
                          messsageAfterOk: 'Se ha una nueva solicitud de contrato de acceso con potencial uso comercial',
                          openDialog: true,
                          okAction: () => {
                            // console.log(55000014, perfilUsuario?.id, perfilUsuario?.id, {}, 'Contrato')
                            dispatch(handleCrearProceso(55000014, perfilUsuario?.id, perfilUsuario?.id, {}, 'Contrato'))
                            setTimeout(function () {
                              dispatch(handleRefreshTareas(perfilUsuario.id))
                            }, 1500)
                          }
                        })
                      }}
                      sx={{ p: '0.2rem 0.5rem 0.2rem 0', cursor: 'pointer'  }}>
              <ListItemIcon sx={{ minWidth: '1rem', m: '0 0.6rem 0 0' }}>
                <ArticleIcon edge="start" checked={false} tabIndex={-1} />
              </ListItemIcon>
              <ListItemText>{'Contrato de acceso con potencial uso comercial'}</ListItemText>
            </ListItem>
            <Divider />
            <ListItem key={'WF03'}
                      onClick={() => {
                        setDialogData({
                          ...dialogData,
                          message: 'Va a crear una nueva solicitud de nuevo contrato de acceso con fines comerciales',
                          messsageAfterOk: 'Se ha creado una nueva solicitud de contrato de acceso con fines comerciales',
                          openDialog: true,
                          okAction: () => {
                            dispatch(handleCrearProceso(55000030, perfilUsuario?.id, perfilUsuario?.id, {}, 'Comercial'))
                            setTimeout(function () {
                              dispatch(handleRefreshTareas(perfilUsuario.id))
                            }, 1500)
                          }
                        })
                      }}
                      sx={{ p: '0.2rem 0.5rem 0.2rem 0', cursor: 'pointer'  }}>
              <ListItemIcon sx={{ minWidth: '1rem', m: '0 0.6rem 0 0' }}>
                <ArticleIcon edge="start" checked={false} tabIndex={-1} />
              </ListItemIcon>
              <ListItemText>{'Contrato de acceso con fines comerciales'}</ListItemText>
            </ListItem>
          </List>
        </Box>
      </Popover>
      <MyConfirm dialogData={dialogData} setDialogData={setDialogData} />
      <ModalPreferencias />
    </>
  )
}
