import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {MyTextField} from "src/components/MyTextField";
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {MySwitch} from "../../components/MySwitch";
import {MySubtitle} from "../../components/MySubtitle";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {WF03SolicitudRO} from "./P03SolicitudRO";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MyDatePicker} from "../../components/MyDatePicker";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {Email} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import {PageOrientation} from "docx";
import GLOBALS from "../App/globals";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {WF03Expediente} from "./subcomponents/P03Expediente";

const range = (start, end) => {
  let ans = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
}
const range20 = range(1,20).map(it => <MenuItem key={it} value={it}>{it}</MenuItem>)
const range5 = range(1,5).map(it => <MenuItem key={it} value={it}>{it}</MenuItem>)

// eslint-disable-next-line import/no-anonymous-default-export
export const P03InformeTecnicoSENESCYT = (props) => {
  const dispatch = useDispatch()
  const inputRef = useRef()
  const section = 'WF03-004-InformeTecnicoSenescyt'
  const {instanciaTarea, instanciaProceso, solicitud, perfilUsuario, id} = props
  const bandeja = useSelector(state => state.app.bandeja)
  let payload = !!solicitud.payload?JSON.parse(solicitud.payload):{[section]:{}}
  const funcionarios = useSelector(state => state.app.funcionarios)
  payload.solicitudId = solicitud.id
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:              today,
    serial:             '',
    identificador:      solicitud.numeroSolicitud,
    resolucionDeInicio: '',
    ampliacionInformacionSenescyt: false,
    criterioTecnicoSenescyt:       false,
    informeCompletoSenescyt:       true,
    docxGenerado:       false,
    docx:               '',
    pdf:                '',
    docxLded:           '',
    pdfLded:            '',

    // firma electronica ---\\
    pdfSigned:       '',
    firmando:        false,
    firmaTareaId:    0,
    firmado:         false,
    // firma electronica ---//
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada' && instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => { setCounter(counter + 1) }, [formValues])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }
  const getOnSave = (setCounter, fv=null) => {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(instanciaProceso?.id, payload, () => setCounter(0)))
    };
  }

  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `informe-tecnico-${nombreOrganizacion}`
  const filename = `informe-tecnico-${formValues.identificador}-${nombreOrganizacion}`
  const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  const filenamePDFuploaded = `${filename}-upld.pdf`
  const filenamePDFsigned = `${filename}-firmado.pdf`

  const informeTecnico = '56015020-1'  // '56015003-1'
  const autorizador = 1139
  const coordinador = 164

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Informe técnico SENESCYT'} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
            <Tab label="Expediente" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Acto de simple administración'} />
            </Grid>

            <Grid item xs={6} >
              <Box sx={{width:'40%'}} >
                <MyDatePicker id={'fecha'}
                              canEdit={true}
                              label={'Fecha'}
                              formValues={formValues}
                              setFormValues={setFormValues} />
              </Box>
            </Grid>
            <Grid container xs={6}>
              {/*<Grid item xs={12}>*/}
              {/*  <MySwitch id={'ampliacionInformacionSenescyt'}*/}
              {/*            label={'¿Amplieación información?'}*/}
              {/*            formValues={formValues}*/}
              {/*            setFormValues={setFormValues}*/}
              {/*            fullWidth={false}*/}
              {/*            canEdit={canEdit}*/}
              {/*            handleChange={handleChange} />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <MySwitch id={'criterioTecnicoSenescyt'}*/}
              {/*            label={'¿Criterio técnico?'}*/}
              {/*            formValues={formValues}*/}
              {/*            setFormValues={setFormValues}*/}
              {/*            fullWidth={false}*/}
              {/*            canEdit={canEdit}*/}
              {/*            handleChange={handleChange} />*/}
              {/*</Grid>*/}
              <Grid item xs={12}>
                <MySwitch id={'informeCompletoSenescyt'}
                          label={'¿Informe completo?'}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          fullWidth={false}
                          canEdit={canEdit}
                          handleChange={handleChange} />
              </Grid>
            </Grid>
            <Grid item xs={12} >
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                {
                  formValues.serial === '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'GENERAR SERIAL'}
                                    width={'11rem'}
                                    bgColor1={'rgb(148 193 32)'}
                                    onClick={() => {
                                      // const tipo = instanciaProceso.tipoInstanciaProceso === 'Contrato'?'CAPC':'PIC'
                                      const tipo = 'ITSYT'
                                      API.secuenciaSet(tipo).then((ser) => {
                                        if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                          const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                          getOnSave(setCounter, {...formValues, serial})()
                                          setFormValues({...formValues, serial})
                                          setCounter(0)
                                        }
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={''}
                                    width={'3rem'}
                                    onClick={() => {
                                      setFormValues({
                                        ...formValues,
                                        docx:     '',
                                        pdf:      '',
                                        docxLded: '',
                                        pdfLded:  '',

                                        // firma -- \\
                                        firmaTareaId: 0,
                                        pdfSigned:    '',
                                        firmando:     false,
                                        firmado:      false,
                                        // firma -- //
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx === '' && formValues.serial !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'Generar Informe'}
                                    width={'11rem'}
                                    onClick={() => {
                                      // API.fetchPlantila(informeTecnico).then(taggeDoc => {
                                      //   const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, nombreOrganizacion, P0102API.mapping({
                                      const doc = MyTagged2Docx(plantilla, nombreOrganizacion, P0102API.mapping({
                                          solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                          payload,
                                          autorizador,
                                          coordinador,
                                          perfilUsuario,
                                          nombreOrganizacion,
                                          section: payload[section]
                                        }), PageOrientation.PORTRAIT, instanciaProceso?.solicitud?.nombreProyecto)
                                        API.genDocxAnPdf({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          document: doc,
                                          formValues,
                                          setFormValues,
                                          subfolder,
                                          filename: filenameDOCX,
                                          nombreOrganizacion,
                                        })
                                      // })
                                    }}
                                    icon={MiscellaneousServicesIcon} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'DOCX'}
                                    icon={FileDownloadIcon}
                                    onClick={() => {
                                      API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                      })
                                    }}
                                    toolTip={'Descargar informe técnico en formato docx'}
                                    width={'5rem'} />
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                    })}
                                    toolTip={'Descargar informe técnico en formato pdf'}
                                    width={'5rem'} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyFileUploadButton label={'DOCX'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenameDOCXuploaded}
                                        afterUpload={() => {
                                          API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                            if(f.isValid(result2.rutaDocumento)) {
                                              const arr2 = result2.rutaDocumento.split('/')
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                            } else {
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                            }
                                          })
                                        }}
                                        toolTip={'Subir docx actualizado del informe técnico '}
                                        width={'5rem'} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <>
                    <MyFileUploadButton label={'PDF (FIRMADO)'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenamePDFsigned}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                        toolTip={'Subir documento firmado en formato pdf'}
                                        width={'12rem'}
                                        disabled={formValues.firmando || formValues.firmado}/>
                    <MyButtonBacan3 label={'FIRMAR'}
                                    icon={FileDownloadIcon}
                                    onClick={()=> {
                                      API.firmarPdf(instanciaTarea.id, subfolder, filenamePDF).then((res)=> {
                                        if(res?.length > 0) {
                                          const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                          const firmaTareaId = res[0].firmaTareaId
                                          setFormValues({...formValues, firmando:true, firmado:false, pdfSigned, firmaTareaId})
                                        }
                                      })
                                    }}
                                    disabled={formValues.firmado}
                                    toolTip={'firmar electronicamente el documento'}
                                    width={'12rem'} />
                    {
                      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                     icon={FileDownloadIcon}
                                                                     onClick={() => API.fetchDownloadPDF({
                                                                       solicitudId: instanciaProceso?.solicitud?.id,
                                                                       subfolder,
                                                                       filename: filenamePDFsigned,
                                                                     })}
                                                                     toolTip={'Descarga informe técnico firmado'}
                                                                     width={'3rem'} />
                    }
                  </>
                }
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <WF03SolicitudRO payload={payload}/>
        </TabPanel>
        <TabPanel value="3">
          <WF03Expediente payload={payload} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={() => {
                                const target = 'SENESCYT'
                                let docs = {...payload.INFO.docs}
                                let IDXloc = docs.d04InformeTecnico?.findIndex(x => x.idx === target)
                                if(IDXloc >= 0) {
                                  payload.INFO.docs.d04InformeTecnico?.splice(IDXloc, 1)
                                  docs = {
                                    ...payload.INFO.docs,
                                    'd04InformeTecnico': payload.INFO.docs.d04InformeTecnico
                                  }
                                } else {
                                  if(!f.isValid(payload.INFO.docs.d04InformeTecnico))
                                    docs = {
                                      ...payload.INFO.docs,
                                      'd04InformeTecnico': []
                                    }
                                }
                                // docs = {
                                //   ...payload.INFO.docs,
                                //   'd04InformeTecnico': [
                                //     ...docs.d04InformeTecnico,
                                //     {
                                //       'idx': target,
                                //       'archivo': (formValues.pdfSigned !== '') ? formValues.pdfSigned : formValues.pdf,
                                //       'solicitudId': payload.INFO.solicitudId,
                                //       'desc': 'Informe técnico SENESCYT',
                                //       'subFolder': subfolder
                                //     }
                                //   ]
                                // }
                                // payload.INFO.docs = docs
                                payload[section]=formValues
                                dispatch(handleSaveSolicitud(instanciaProceso?.id, payload, () => setCounter(0) ))
                              }}
                              disabled={counter <= 0} />
                <MySendButton onSend={() => {
                                const metadata = JSON.stringify({
                                    "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    "criterioTecnicoSenescyt": false,
                                    "informeCompletoSenescyt": formValues.informeCompletoSenescyt,
                                    "ampliacionInformacionSenescyt": false,
                                  }
                                )
                                dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'ENVIAR'}
                              myTip={''}
                              disabled={counter > 0 || formValues.pdfSigned === ''} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

const plantilla = '${TITULO1}INFORME TÉCNICO' +
  '${SALTO}${CENTRAR}No. <<serial>> ' +
  '${SALTO}${CENTRAR}<<sistema.fecha>>' +
  '${SALTO}' +
  '${SALTO}${TÍTULO3}I.  DATOS GENERALES' +
  '${SALTO}${VAR}Solicitud:${:}<<solicitud.identificador>>' +
  '${SALTO}${VAR}Solicitante: ${:}<<solicitante.nombre>>' +
  '${SALTO}${VAR}Proyecto:${:}<<solicitud.nombreProyecto>>' +
  '${SALTO}' +
  '${SALTO}${TÍTULO3}II. ANTECEDENTES' +
  '${SALTO}${PARRAFO}(LOS ANTECEDENTES DEBERÁN SER DEFINIDOS POR LAS INSTITUCIONES)' +
  '${SALTO}' +
  '${SALTO}${TÍTULO3}III. OBJETIVO' +
  '${SALTO}${PARRAFO}Realizar la evaluación técnica a la solicitud No. <<solicitud.identificador>>, para la autorización del proyecto <<solicitud.nombreProyecto>>' +
  '${SALTO}' +
  '${SALTO}${TÍTULO3}IV. BASE LEGAL' +
  '${SALTO}${PARRAFO}(LA DEFINIDA POR LAS INSTITUCIONES)' +
  '${SALTO}${PARRAFO}Constitución de la República de Ecuador:' +
  '${SALTO}${PARRAFO}En el artículo XX establece que …….' +
  '${SALTO}${PARRAFO}Código Orgánico del Ambiente:' +
  '${SALTO}${PARRAFO}Demás Códigos Orgánicos y sus reglamentos' +
  '${SALTO}${PARRAFO}Estatuto Orgánico de Gestión Organizacional por Procesos:' +
  '${SALTO}' +
  '${SALTO}${TÍTULO3}V. ANALISIS' +
  '${SALTO}${PARRAFO}(El análisis será establecido por cada una de las instituciones, ya sea con una plantilla base o como un campo a completar para cada solicitud. En este espacio se describiría la información que requiera ser importada de la solicitud para ser analizada y que sustente la conclusión).' +
  '${SALTO}' +
  '${SALTO}${TÍTULO3}VI. CONCLUSIONES Y RECOMENDACIONES' +
  '${SALTO}${PARRAFO}Del análisis realizado se evidenció que la solicitud No.<<solicitud.identificador>> presentada por <<solicitante.nombre>> para desarrollar el proyecto <<solicitud.nombreProyecto>> por el plazo de <<solicitud.plazoProyecto>>, es pertinente, viable y factible conforme el criterio de este Ministerio y, que la misma promueve la conservación y uso sostenible de la biodiversidad; además, que su desarrollo permitirá  la generación de nuevo conocimiento sobre la biodiversidad y productos que potencialmente podrán tener usos comerciales. Asimismo, se evidenció la presente solicitud no prevé el uso del recurso con fin comercial en su propuesta y desarrollo.' +
  '${SALTO}${PARRAFO}Por lo expuesto, se recomienda la autorización de acceso solicitada para el desarrollo del proyecto <<solicitud.nombreProyecto>> por el plazo de <<solicitud.plazoProyecto>>, siempre y cuando no estén en contradicción con la normativa legal vigente.' +
  '${SALTO}' +
  '${SALTO}${VAR}Fecha:${:} <<sistema.fecha>>' +
  '       ${SALTO3}' +
  '${SALTO}${CENTRAR}<<usuario.cargo>>' +
  '${SALTO}${CENTRARBOLD}SERVICIO NACIONAL DE DERECHOS INTELECTUALES' +
  '${SALTO3}' +
  '${SALTO}${CENTRAR}Desarrollo del documento: ' +
  '${SALTO}${CENTRAR}<<usuario.nombre>>' +
  '${SALTO}${CENTRAR}<<usuario.rol>>' +
  '${SALTO}${CENTRAR}<<usuario.cargo>>' +
  '${SALTO}${CENTRAR}<<usuario.correoElectronico>>'
