// Notificación de respuesta
import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  AppBar,
  Box,
  CssBaseline,
  Grid, Stack, Tab,
} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import API from 'src/features/App/API'
import Toolbar from '@mui/material/Toolbar'
import {MyTextField} from "../../components/MyTextField";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {MyAreaTextField} from "../../components/MyAreaTextField";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {MySwitch} from "../../components/MySwitch";
import {MyUpload} from "../../components/MyUpload";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P10ATMRO} from "../P10ATM/P10ATMRO";
import {MyTable} from "../../components/MyTable";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {MyDatePicker} from "../../components/MyDatePicker";

export default ({
                  instanciaTarea,
                  perfilUsuario,
                  instanciaProceso,
                  solicitud,
                  canEdit=true,
                  // atms=[],
                }) => {
  const dispatch = useDispatch()
  const section = 'JustificacionAmplieacionPlazo'
  const slct = {...solicitud, payload : JSON.parse(solicitud?.payload ?? '{}')}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const ATM = slct.payload.Solicitud.solicitudAprobada.father.payloadSolicitud
  let rss = ATM.Solicitud.recursos
  const mp = slct.payload.Solicitud.solicitudAprobada
  const payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})

  const emptyPayload = {
    fecha:                       today,
    fechaEstimadaReporte:        today,
  }
  const [formValues, setFormValues] = useState({...emptyPayload,...payload[section]})
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
  },[formValues])
  const [recursoSelected, setRecursoSelected] = useState({})
  const defaultHandleChange4Switch = (
    event,
    value,
    canEdit,
    setFormValues,
    formValues
  ) => {
    if(canEdit) {
      setFormValues({...formValues, [event.target.id]: value})
    }
  }
  const handleChange = (e) => API.handleChange2(e, canEdit, setFormValues, formValues)
  const [myTab, setMytab] = useState('1')
  // console.log('::: py : ', payload?.Solicitud?.fechaEstimadaReporte)
  return (
    <Box sx={accordeonBox.container}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Justificación ampliación de plazo'} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
          <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="ATM" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Documento habilitante'} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'0 0 0 0'}}>
                <MyTextField id={'documentoIdentificador'}
                             label={'Número de permiso'}
                             formValues={payload?.Solicitud}
                             icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                             canEdit={false} />
                <MyTextField id={'documentoFecha'}
                             label={'Fecha'}
                             formValues={payload?.Solicitud}
                             setFormValues={setFormValues}
                             icon={<LocalShippingIcon sx={{fontSize: '14px', color:'silver'}}/>}
                             canEdit={false} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <MyAreaTextField id='documentoObjetivo'
                               label={'Objetivo de la exportación *'}
                               formValues={payload?.Solicitud}
                               icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                               canEdit={false} />
            </Grid>
            <Grid item xs={8}>
              <MyUpload id={'permisoExportacion'}
                        label={'Permiso de exportación:'}
                        dir={instanciaProceso?.numeroSolicitudPadre}
                        canEdit={false}
                        formValues={payload?.Solicitud} />
            </Grid>
            <Grid item xs={4}>
              <MySwitch id='procesoExteriorConcluido'
                        label={'¿Proceso en el exterior concluido?'}
                        formValues={payload.NotificarRespuesta}
                        canEdit={false} />
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Justificación de apliación de plazo'} />
            </Grid>
            <Grid item xs={12}>
              <MyAreaTextField id={'justificacion'}
                               label={'Justificación *'}
                               formValues={formValues}
                               setFormValues={setFormValues}
                               handleChange={canEdit?handleChange:null} />
            </Grid>
            <Grid item xs={12} >
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={2}>
                <Box sx={{width:'22rem'}}>
                  <MyReadOnlyTextField label={'Fecha presentación resultados'} value={payload?.Solicitud?.fechaEstimadaReporte}/>
                </Box>
                <Box sx={{width:'12rem'}}>
                  <MyDatePicker id={'fechaEstimadaReporte'}
                                label={'Nueva fecha'}
                                formValues={formValues}
                                setFormValues={setFormValues} />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <P10ATMRO payload={ATM} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} >
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                {
                  (canEdit)?
                    <>
                      <MyButtonBacan label={'Regresar'}
                                     icon={ArrowBackIcon}
                                     onClick={() => {
                                       if(counter <= 2) {
                                         dispatch(handleClear())
                                       } else {
                                         alert('Debe GUARDAR los cambios realizados')
                                       }
                                     }} />
                      <MyButtonBacan label={'Guardar'} onClick={() => {
                                       const newPayload= {...payload ,[section]: {...formValues}}
                                       dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
                                       setCounter(0)
                                     }}
                                     disabled={counter <= 0}
                                     icon={SaveOutlinedIcon} />
                      <MySendButton disabled={ counter > 0 }
                                    label={'enviar'}
                                    onSend={ () => {
                                      const metadata = JSON.stringify({"solicitudId": `${instanciaProceso?.solicitud?.id}`, "ampliacionAceptada": true})
                                      dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                    }} />
                    </>
                    :
                    <Grid item xs={4} style={{padding:'0 24px 0 0'}}>
                      <MyButtonBacan label={'Regresar'}
                                     icon={ArrowBackIcon}
                                     onClick={() => dispatch(handleClear())} />
                    </Grid>
                }
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
