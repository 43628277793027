import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {MyTextField} from "src/components/MyTextField";
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {MySwitch} from "../../components/MySwitch";
import {MySubtitle} from "../../components/MySubtitle";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {WF03SolicitudRO} from "./P03SolicitudRO";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MyDatePicker} from "../../components/MyDatePicker";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {WF03Expediente} from "./subcomponents/P03Expediente";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import GLOBALS from "../App/globals";
import {MyTable as MyTableObservaciones} from "../../components/MyTable";

// eslint-disable-next-line import/no-anonymous-default-export
export const P03SugerirMejorasMaate = ({instanciaTarea, instanciaProceso, solicitud, perfilUsuario, id}) => {
  // console.log(':::: sid : ', solicitud.numeroSolicitud)
  const inputRef = useRef()
  const section = 'P03ESugerirMejorasMaate'
  const dispatch = useDispatch()
  const bandeja = useSelector(state => state.app.bandeja)
  let payload = !!solicitud.payload?JSON.parse(solicitud.payload):{[section]:{}}
  payload.solicitudId = solicitud.id
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    identificador: solicitud.numeroSolicitud,
    fecha:                today,
    observacionesReporte: payload.P03ElaborarInfomeMejorasMaate.observacionesReporte,
    resolucionDeInicio :  payload.P03ElaborarInfomeMejorasMaate.resolucionDeInicio,
    serial:               payload.P03ElaborarInfomeMejorasMaate.serial,
    docx:                 payload.P03ElaborarInfomeMejorasMaate.docx,
    pdf:                  payload.P03ElaborarInfomeMejorasMaate.pdf,
    docxLded:             payload.P03ElaborarInfomeMejorasMaate.docxLded,
    pdfLded:              payload.P03ElaborarInfomeMejorasMaate.pdfLded,
    pdfSigned:            '',
  }

  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada' && instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => { setCounter(counter + 1) }, [formValues])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `mejoras-${nombreOrganizacion}`

  const filename = `mejoras-${solicitud.numeroSolicitud}-${nombreOrganizacion}`

  const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  const filenamePDFuploaded = `${filename}-upld.pdf`
  const filenamePDFsigned = `${filename}-firmado.pdf`

  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    }
  }

  const senescyt05 = payload?.INFO?.docs?.d05DictamenTecnico.findIndex(x => x.idx === 'SENESCYT')
  const maate05 = payload?.INFO?.docs?.d05DictamenTecnico.findIndex(x => x.idx === 'MAATE')
  const senadi05 = payload?.INFO?.docs?.d05DictamenTecnico.findIndex(x => x.idx === 'SENADI')

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Sugerir mejoras - MAATE'}/>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
            <Tab label="Expediente" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>


            <Grid item xs={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'INABIO'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: instanciaProceso?.solicitud?.id,
                        subfolder:payload?.INFO?.docs?.d03Factivilidad[0]?.subFolder, // 'd03Factivilidad',
                        filename: payload.INFO.docs?.d03Factivilidad[0]?.archivo,
                      })}
                      label={'Factibilidad'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'SENESCYT'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: instanciaProceso?.solicitud?.id,
                        subfolder: payload?.INFO?.docs?.d05DictamenTecnico[senescyt05]?.subFolder,
                        filename: payload.INFO.docs?.d05DictamenTecnico[senescyt05]?.archivo,
                      })}
                      label={'Dictamen'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'MAATE'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: payload?.INFO?.docs?.d05DictamenTecnico[maate05]?.solicitudId,
                        subfolder: payload?.INFO?.docs?.d05DictamenTecnico[maate05]?.subFolder,
                        filename: payload.INFO.docs?.d05DictamenTecnico[maate05]?.archivo,
                      })}
                      label={'Dictamen'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'SENADI'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: payload?.INFO?.docs?.d05DictamenTecnico[senadi05]?.solicitudId,
                        subfolder: payload?.INFO?.docs?.d05DictamenTecnico[senadi05]?.subFolder,
                        filename: payload.INFO.docs?.d05DictamenTecnico[senadi05]?.archivo,
                      })}
                      label={'Dictamen'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Observaciones'} />
            </Grid>
            <Grid item xs={12}>
              <MyTableObservaciones id={'observacionesReporte'}
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    columnName={'Observaciones *'}
                                    canEdit={canEdit}
                                    addItem={() => {
                                      if(formValues['observacionesReporte'].filter(it => it === '').length === 0) {
                                        const field = 'observacionesReporte'
                                        const newSet = [ ...formValues[field], '']
                                        const newFormValues = {...formValues, [field]:newSet}
                                        setFormValues(newFormValues)
                                      }
                                    }} />
            </Grid>

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Propuesta'} />
            </Grid>

            <Grid item xs={12} >
              <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                {
                  formValues.serial === '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'GENERAR SERIAL'}
                                    width={'11rem'}
                                    bgColor1={'rgb(148 193 32)'}
                                    onClick={() => {
                                      API.secuenciaSet('AUTIC').then((ser) => {
                                        if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                          const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                          getOnSave({...formValues, serial:serial})()
                                          setFormValues({...formValues, serial:serial})
                                        }
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <>
                    <MyButtonBacan3 label={'DOCX'}
                                    icon={FileDownloadIcon}
                                    onClick={() => console.log(':: > ',instanciaProceso?.solicitud?.id, subfolder, (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx) ||
                                      API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                      })
                                    }
                                    toolTip={'Descagar documento en formato docx'}
                                    width={'5rem'} />
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                    })}
                                    toolTip={'Descagar documento en formato pdf'}
                                    width={'5rem'} />
                  </>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'DOCX'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenameDOCXuploaded}
                                        afterUpload={() => {
                                          API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                            if(f.isValid(result2.rutaDocumento)) {
                                              const arr2 = result2.rutaDocumento.split('/')
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                            } else {
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                            }
                                          })
                                        }}
                                        toolTip={'Subir documento modificado en formato docx'}
                                        width={'5rem'} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'PDF (firmado)'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenamePDFsigned}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                        toolTip={'Subir documento firmado electrónicamente'}
                                        width={'9rem'} />
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'Firmar'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenamePDFsigned}
                                        toolTip={'Firmar electrónicamente'}
                                        disabled={true}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                        width={'9rem'} />
                    {
                      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                     icon={FileDownloadIcon}
                                                                     onClick={() => API.fetchDownloadPDF({
                                                                       solicitudId: instanciaProceso?.solicitud?.id,
                                                                       subfolder,
                                                                       filename: filenamePDFsigned,
                                                                     })}
                                                                     toolTip={'Descargar documento firmado'}
                                                                     width={'3rem'}/>
                    }
                  </ButtonGroup>
                }
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <WF03SolicitudRO payload={payload}/>
        </TabPanel>
        <TabPanel value="3">
          <WF03Expediente payload={payload} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={() => {
                                const target = 'MAATE'
                                let docs = {...payload.INFO.docs}
                                let IDXloc = docs.d08Mejoras?.findIndex(x => x.idx === target)
                                if(IDXloc >= 0) {
                                  payload.INFO.docs.d08Mejoras?.splice(IDXloc, 1)
                                  docs = {
                                    ...payload.INFO.docs,
                                    'd08Mejoras': payload.INFO.docs.d08Mejoras
                                  }
                                } else {
                                  if(!f.isValid(payload.INFO.docs.d08Mejoras))
                                    docs = {
                                      ...payload.INFO.docs,
                                      'd08Mejoras': []
                                    }
                                }
                                docs = {
                                  ...payload.INFO.docs,
                                  'd08Mejoras': [
                                    ...docs.d08Mejoras ,
                                    {
                                      'idx': target,
                                      'archivo': (formValues.pdfSigned !== '') ? formValues.pdfSigned : formValues.pdf,
                                      'solicitudId': payload.INFO.solicitudId,
                                      'desc': 'MAATE',
                                      'subFolder': subfolder
                                    }
                                  ]
                                }
                                payload.INFO.docs = docs
                                getOnSave()()
                              }}
                              disabled={counter <= 0} />
                <MySendButton onSend={() => {
                                const metadata = JSON.stringify({ "solicitudId": `${instanciaProceso?.solicitud?.id}` })
                                dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'ENVIAR'}
                              myTip={''}
                              disabled={counter > 0 || formValues.pdfSigned === ''}/>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}
