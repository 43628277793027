import React from 'react'
import {Popover} from '@mui/material'
import Box from "@mui/material/Box";
import { Chat } from './Chat';
import ChatIcon from '@mui/icons-material/Chat';

export const Widget = () => {
  const [anchorEl1, setAnchorEl1] = React.useState(null)
  // const handleClick = (e) => setAnchorEl1(e.currentTarget)
  const handleClose = () => {setAnchorEl1(null)}
  const open = Boolean(anchorEl1)
  const mid = open ? 'simple-popover' : undefined

  const handleClick = () => {
    setAnchorEl1(true);
  }

  return (
    <>
      <Box aria-describedby={mid} onClick={handleClick} sx={{height:'100%'}}>
        <ChatIcon sx={{color:'white', height:'100%', fontSize:'3rem', fontWeight:'lighter'}} />
      </Box>
      <Popover id={mid}
               open={open}
               anchorEl={anchorEl1}
               onClose={handleClose}
               transformOrigin={{
                 vertical: 'top',
                 horizontal: 'right',
               }}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'right',
               }} >
        <Box sx={{p:'0.8rem', maxHeight: 600, backgroundColor: '#F4FFF4'}}
          >
          <Chat />
        </Box>
      </Popover>
    </>
  )
}
