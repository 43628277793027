import React, {useEffect, useRef, useState} from 'react'
import {TabContext, TabList, TabPanel} from "@mui/lab"
import {useDispatch} from 'react-redux'
import {
  AppBar,
  Box, ButtonGroup,
  CssBaseline,
  Grid, Stack, Tab,
} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import Toolbar from '@mui/material/Toolbar'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Autorizacion from "src/features/P01Solicitud/subcomponents/Autorizacion";
import GLOBALS from "../App/globals";
import {MyTextField} from "../../components/MyTextField";
import API from "../App/API";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import {P10ATMRO} from "../P10ATM/P10ATMRO";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";

export default ({
                  instanciaTarea,
                  perfilUsuario,
                  instanciaProceso,
                  solicitud,
                  solicitudesAprobadas,
                  canEdit=true,
                }) => {
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const ATM = slct.payload.Solicitud.solicitudAprobada.father.payloadSolicitud
  const mp = slct.payload.Solicitud.solicitudAprobada
  const dispatch = useDispatch()
  const section = 'Autorizacion'
  const payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:         today,
    asunto:        '',
    detalle:       '',
    pdfSigned:     '',
  }
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const [formValues, setFormValues] = useState({...emptyPayload,...payload[section]})
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  const [recursoSelected, setRecursoSelected] = useState({})
  useEffect(() => {
    if(slct?.payload?.Validar?.cumpleRequisitos) {
      setFormValues({
        ...formValues,
        asunto:  'Solicitud de salida de holotipos ha sido APROBADA',
        detalle: `Estimado/a ${mp.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la ` +
          `Salida de Holotipos con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} ha sido APROBADA.\n\n` // +
          // 'Saludos cordiales,\n\n' +
          // `${perfilUsuario.usuario.nombreUsuario}`
      })
    }
    else {
      setFormValues({
        ...formValues,
        asunto:  'Solicitud de salida de holotipos no ha sido APROBADA',
        detalle: `Estimado/a ${mp.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la ` +
          `Salida de Holotipos con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} no ha sido APROBADA.\n\n` // +
          // 'Saludos cordiales,\n\n' +
          // `${perfilUsuario.usuario.nombreUsuario}`
      })
    }
  }, [slct?.payload?.Validar?.cumpleRequisitos])
  const handleChange = (e) => API.handleChange(e, 'entrada', setFormValues, formValues)
  const [myTab, setMytab] = useState('1')
  const subfolder = `holotipos-${nombreOrganizacion}`
  const filename = `holotipos-${formValues.identificador}-${nombreOrganizacion}`
  const filenamePDFsigned = `${filename}-firmado.pdf`
  const inputRef = useRef()
  const elPdf = f.isValidNotEmpty(slct.payload.Elaboracion.pdfLded)?slct.payload.Elaboracion.pdfLded:slct.payload.Elaboracion.pdf
  if(f.isValid(solicitudesAprobadas)) {
    return (
      <Box sx={accordeonBox.container}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          {
            (instanciaTarea.tareaCodigoTarea === "55000010_Activity_AprobarAutorizacionWF09")?
              <SectionTitle title={'Autorización de salida de holotipos'} />:
              <SectionTitle title={'Negación de salida de holotipos'} />
          }
        </AppBar>
        <TabContext value={myTab} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
            <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="ATM" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Identificador'}
                                     icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={solicitud.numeroSolicitud} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Fecha'}
                                     icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={slct.payload.Solicitud['fecha']} />
              </Grid>
              <Solicitante solicitud={{solicitud: {payload: mp}}} displayContact={false}/>
              <Grid item xs={12} sx={dialog.titleContainer}>
                {
                  (instanciaTarea.tareaCodigoTarea === "55000010_Activity_AprobarAutorizacionWF09")?
                    <MySubtitle subtitle={'Autorización'} />:
                    <MySubtitle subtitle={'Negación'} />
                }
              </Grid>
              {/*<Grid item xs={4} style={{padding:'0.5rem 0.6rem 0.5rem 0'}}>*/}
              {/*  <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems='center' >*/}
              {/*    <MySwitch id={'cumpleRequisitos'}*/}
              {/*              label={'¿Se aprueba la autorización?'}*/}
              {/*              fullWidth={false}*/}
              {/*              formValues={slct?.payload?.Validar}*/}
              {/*              canEdit={false} />*/}
              {/*  </Stack>*/}
              {/*</Grid>*/}
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent="flex-end" alignItems='center' sx={{p:'0 2rem 0 0'}}>
                  <MyButtonBacan label={'Ver PDF'}
                                 onClick={() => {
                                   const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/elaborar-autorizacion-Senescyt/${elPdf}`
                                   fetch(url)
                                     .then((res) => { return res.blob(); })
                                     .then((data) => {
                                       const dataPdf = new Blob([data], { type: 'application/pdf' })
                                       const a = document.createElement("a")
                                       a.href = window.URL.createObjectURL(dataPdf)
                                       a.target="_blank"
                                       a.click()
                                     })
                                 }} />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent="flex-end" alignItems='center' sx={{p:'0 1rem 0 0'}}>
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder:`elaborar-autorizacion-${nombreOrganizacion}`,
                                      filename: elPdf,
                                    })}
                                    width={'5rem'} />
                    <MyFileUploadButton label={'PDF firmado'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenamePDFsigned}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                        width={'9rem'} />
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'Firmar PDF'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        disabled={true}
                                        fileName={filenamePDFsigned}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                        toolTip={'Firmar el documento electrónicamete'}
                                        width={'9rem'} />
                    {
                      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                     icon={FileDownloadIcon}
                                                                     onClick={() => API.fetchDownloadPDF({
                                                                       solicitudId: instanciaProceso?.solicitud?.id,
                                                                       subfolder,
                                                                       filename: filenamePDFsigned,
                                                                     })}
                                                                     width={'3rem'} />
                    }
                  </ButtonGroup>
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Notificación'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 1rem 0 0.5rem'}}>
                <MyTextField id={'asunto'}
                             label={'Asunto *'}
                             formValues={formValues}
                             handleChange={handleChange}
                             canEdit false/>
              </Grid>
              <Grid item xs={12} style={{padding:'0 1rem 0 0.5rem'}}>
                <MyAreaTextField id={'detalle'}
                                 label={'Contenido *'}
                                 formValues={formValues}
                                 handleChange={handleChange}
                                 canEdit={false} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P10ATMRO payload={ATM} />
          </TabPanel>
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  {
                    (canEdit)?
                      <>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => {
                                         if(counter <= 2) {
                                           dispatch(handleClear())
                                         } else {
                                           alert('Debe GUARDAR los cambios realizados')
                                         }
                                       }} />
                        <MyButtonBacan label={'Guardar'} onClick={() => {
                                         const newPayload= {...payload ,[section]: {...formValues}}
                                         dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
                                         setCounter(0)
                                       }}
                                       disabled={counter <= 0}
                                       icon={SaveOutlinedIcon} />
                        <MySendButton disabled={counter > 0 || formValues.asunto === '' || formValues.detalle === '' || formValues.pdfSigned === ''}
                                      label={(instanciaTarea.tareaCodigoTarea === "55000010_Activity_AprobarAutorizacionWF09")?'aprobar':'negar'}
                                      onSend={() => {
                                        const metadata = JSON.stringify({
                                          "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                          // "informeFavorable": formValues.informeFavorable,
                                          "asunto": formValues.asunto,
                                          "detalle": formValues.detalle,
                                          "adjunto": slct.payload.Elaboracion.pdf,
                                          "carpeta": 'EL-Senescyt',
                                        })
                                        dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                      }} />
                      </>
                      :
                      <Grid item xs={4} style={{padding:'0 24px 0 0'}}>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => dispatch(handleClear())} />
                      </Grid>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
  else return null
}
