import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Box} from '@mui/system'
import {accordeonBox} from '../../styles/styles'
import AppBar from '@mui/material/AppBar'
import {SectionTitle} from 'src/components/SectionTitle'
import {
  API
} from 'src/features/P03'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import {
  CssBaseline,
  Grid,
  Stack
} from '@mui/material'
import {f} from 'src/commons'
import Toolbar from '@mui/material/Toolbar'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from 'src/features/App/sliceApp'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
const modificar = '55000028_Activity_ElaborarSolicitud_WF06'

export const WF03Solicitud = ({instanciaTarea, instanciaProceso, solicitud, perfilUsuario,}) => {
  const dispatch = useDispatch()
  let payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const [counter, setCounter] = useState(-1)
  const bandeja = useSelector(state => state.app.bandeja)
  const canEdit = bandeja === 'entrada' &&  ["En Progreso", "Iniciada"].includes(instanciaTarea.estadoInstanciaProceso)
  const incrementCounter = () => setCounter(counter + 1)
  const usuarioSenescyt = useSelector(state => state.app.usuarioSenescyt)

  const sections = API.sections
  const [expanded, setExpanded] = React.useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const emptyFormValues = {
    INFO: {solicitudId: solicitud.id, numeroSolicitud: solicitud.numeroSolicitud, nombre:''},
    Propuesta: {...API.esqueletos.Propuesta},
    Solicitante: {...API.esqueletos.Solicitante},
    EmpresaSolicitante: {...API.esqueletos.EmpresaSolicitante},
    ResponsableSolicitud: {...API.esqueletos.ResponsableSolicitud},
    ResponsableTecnico: {...API.esqueletos.ResponsableTecnico},
    ProveedorRecursos:  {...API.esqueletos.ProveedorRecursos},
    DeApoyo:  {...API.esqueletos.DeApoyo},
    RecursosInSitu: {...API.esqueletos.RecursosInSitu},
    RecursosExSitu: {...API.esqueletos.RecursosExSitu},
    AccesoConocimiento: {...API.esqueletos.AccesoConocimiento},
    Personal: {...API.esqueletos.Personal},
    Declaracion: {...API.esqueletos.Declaracion}
  }
  const [formValues, setFormValues] = useState({
    ...emptyFormValues,
    Propuesta:{...emptyFormValues.Propuesta, ...payload?.Propuesta},
    Solicitante:{...emptyFormValues.Solicitante, ...payload?.Solicitante},
    EmpresaSolicitante:{...emptyFormValues.EmpresaSolicitante, ...payload?.EmpresaSolicitante},
    ResponsableSolicitud:{...emptyFormValues.ResponsableSolicitud, ...payload?.ResponsableSolicitud},
    ResponsableTecnico: {...emptyFormValues.ResponsableTecnico, ...payload?.ResponsableTecnico},
    ProveedorRecursos: {...emptyFormValues.esqueletos?.ProveedorRecursos, ...payload?.ProveedorRecursos},
    DeApoyo:  {...API.esqueletos.DeApoyo, ...payload?.DeApoyo},
    RecursosInSitu: {...API.esqueletos.RecursosInSitu, ...payload?.RecursosInSitu},
    RecursosExSitu: {...API.esqueletos.RecursosExSitu, ...payload?.RecursosExSitu},
    AccesoConocimiento: {...API.esqueletos.AccesoConocimiento, ...payload?.AccesoConocimiento},
    Personal: {...API.esqueletos.Personal, ...payload?.Personal},
    Declaracion: {...API.esqueletos.Declaracion, ...payload?.Declaracion}
  })
  const [formErrors, setFormErrors] =
    useState({
      Propuesta: {...API.esqueletos.Propuesta},
      Solicitante: {...API.esqueletos.Solicitante},
      EmpresaSolicitante: {...API.esqueletos.EmpresaSolicitante},
      ResponsableSolicitud: {...API.esqueletos.ResponsableSolicitud},
      ResponsableTecnico: {...API.esqueletos.ResponsableTecnico},
      ProveedorRecursos:  {...API.esqueletos.ProveedorRecursos},
      DeApoyo:  {...API.esqueletos.DeApoyo},
      RecursosInSitu: {...API.esqueletos.RecursosInSitu},
      RecursosExSitu: {...API.esqueletos.RecursosExSitu},
      AccesoConocimiento: {...API.esqueletos.AccesoConocimiento},
      Personal: {...API.esqueletos.Personal},
      Declaracion: {...API.esqueletos.Declaracion},
    })

  useEffect(() => {
    if(!f.isValidNotEmpty(formValues.Solicitante?.nombresCompletos)) {
      // console.log('::: formvalues ', formValues.Solicitante, usuarioSenescyt)
      // payload.Solicitante = usuarioSenescyt
      // dispatch(handleSaveSolicitud(instanciaProceso.id, payload))
      const Solicitante = {
        cedula: usuarioSenescyt.cedula,
        celular: usuarioSenescyt.celular,
        direccion: usuarioSenescyt.direccion,
        email: usuarioSenescyt.email,
        etnia: usuarioSenescyt.etnia,
        genero: usuarioSenescyt.genero,
        nacionalidad: usuarioSenescyt.nacionalidad,
        nombresCompletos: usuarioSenescyt.nombresCompletos,
        registro: usuarioSenescyt.registro,
        telefono: usuarioSenescyt.telefono,
      }
      // setFormValues({...formValues, Solicitante})
      const newPayload = {...payload ,...formValues, Solicitante}
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        newPayload,
        () => setCounter(0))
      )
      setFormValues({...newPayload})
    }
  }, [usuarioSenescyt])

  useEffect(() => {
    if(canEdit && f.isValid(setFormValues)) {
      incrementCounter()
    }
  }, [formValues])

  if(f.isValid(formValues)) {
    return (
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
        <AppBar position='fixed' color='primary' elevation={0} sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Solicitud de contrato de acceso con fines comerciales'} />
        </AppBar>
        {
          sections.map((section, idx) => (
            <Accordion disableGutters
                       elevation={0}
                       key={`panel${idx+1}`}
                       expanded={expanded === `panel${idx+1}`}
                       onChange={handleChange(`panel${idx+1}`)}
                       sx={{backgroundColor:'#F4FFF4'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${idx + 1}bh-content`}
                                id={`panel${idx + 1}bh-header`}
                                sx={accordeonBox.titleBox2}>
                <Typography sx={accordeonBox.titleTypography2}>
                  {section.sectionTitle}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{pl:'52px'}}>{section.f(payload,formValues,setFormValues,canEdit,formErrors)}</AccordionDetails>
            </Accordion>
          ))
        }
        <CssBaseline />
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            { (bandeja === 'entrada')?
              <Grid container sx={{p:0, mt:'-1rem'}}>
                <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
                  <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                    <MyButtonBacan label={'Regresar'}
                                   // myTip={'Regresar a las tareas'}
                                   icon={ArrowBackIcon}
                                   onClick={() => {
                                     if(counter <= 2) {
                                       dispatch(handleClear())
                                     } else
                                       alert('Debe GUARDAR los cambios realizados')
                                   }} />
                    <MyButtonBacan label={'Guardar'}
                                   // myTip={'Guarda el formulario, y permite continuar editando'}
                                   onClick={() => {
                                     const newPayload= {
                                       ...payload ,
                                       ...formValues,
                                     }

                                     const cdocs = newPayload?.RecursosExSitu?.centrosDocumentacion
                                       .map((it, id) => (
                                           { 'idx':id+5,
                                             'archivo':it.adjunto,
                                             'solicitudId': newPayload.INFO.solicitudId,
                                             'desc':`Centro de documentación - Adjunto ${it.acronimo}`,
                                             'subFolder': `adjunto.${it.acronimo}`
                                           }
                                         )
                                       )

                                     const personal = newPayload.Personal.personal

                                     const pasaportes = personal.filter(it => it.pasaporte !== '')
                                       .map((it, id) => (
                                         {
                                           'idx':id+5 + cdocs.length,
                                           'archivo':it.pasaporte,
                                           'solicitudId': newPayload.INFO.solicitudId,
                                           'desc':`Pasaporte ${it.nombre}, ID: ${it.cedula} `,
                                           'subFolder': `${it.cedula}`
                                         }
                                       ))

                                     const docs = {
                                       'd01Solicitud':[   // EmpresaSolicitante
                                         {idx:0, 'archivo':newPayload.EmpresaSolicitante.esEmpresaSolicitanteLegal, solicitudId: newPayload.INFO.solicitudId, subFolder:'esEmpresaSolicitanteLegal', desc:'Empresa solicitante - Nombramiento Representante Legal'},
                                         {idx:1, 'archivo':newPayload.Propuesta.modeloDeNegocios, solicitudId: newPayload.INFO.solicitudId, subFolder: 'modeloDeNegocios', desc:'Propuesta - Modelo de negocios y beneficiarios'},
                                         {idx:2, 'archivo':newPayload.Propuesta.adjunto, solicitudId: newPayload.INFO.solicitudId, subFolder: 'adjunto', desc:'Propuesta - Adjunto'},
                                         {idx:3, 'archivo':newPayload.ProveedorRecursos.prProveedorRecursosLegal, solicitudId: newPayload.INFO.solicitudId, subFolder:'prProveedorRecursosLegal', desc:'Proveedor de Recursos - Nombramiento Representante Legal'},
                                         {idx:4, 'archivo':newPayload.DeApoyo.deApoyoLegal, solicitudId: newPayload.INFO.solicitudId, subFolder:'deApoyoLegal', desc:'Proveedor de Recursos - Nombramiento Representante Legal'},
                                         ...cdocs,
                                         ...pasaportes,
                                       ]
                                     }

                                     newPayload.INFO.docs = docs

                                     dispatch(handleSaveSolicitud(
                                       instanciaProceso?.id,
                                       newPayload,
                                       () => setCounter(0))
                                     )
                                   }}
                                   disabled={counter <= 0}
                                   icon={SaveOutlinedIcon} />
                    <MySendButton disabled={counter > 0 || !f.isValid(payload.Declaracion?.si) || (f.isValid(payload.Declaracion?.si) && payload.Declaracion?.si === false)}
                                  label={'Enviar'}
                                  onSend={ () => {
                                    const myPayload = JSON.parse(instanciaProceso.solicitud?.payload)
                                    const metadata = JSON.stringify(
                                      {
                                        "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                        "nombreProyecto": myPayload?.Propuesta?.nombre
                                      }
                                    )
                                    dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                  }} />
                  </Stack>
                </Grid>
              </Grid>
              :
              <Grid container sx={{p:0, mt:'-1rem'}}>
                <MyButtonBacan label={'Regresar'}
                               // myTip={'Regresa a la lista de tareas'}
                               icon={ArrowBackIcon}
                               onClick={() => dispatch(handleClear())} />
              </Grid>
            }
          </Toolbar>
        </AppBar>
      </Box>
    )
  } else {
    return null
  }
}
