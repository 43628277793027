import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';
import { useFormikContext } from "formik";
import { TextField } from '@mui/material';

export const SelectCorreo = ({ ...props }) => {

    const correos = [{
        510004: "@senadi.gob.ec",
        510005: "@inabio.gob.ec",
        510002: "@senescyt.gob.ec",
        510003: "@ambiente.gob.ec"
    }]

    const { institucion } = useSelector(state => state.usuarios)
    const [iniciales, setIniciales] = React.useState('')

    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
        setTimeout(() => {
            institucion && setIniciales(correos[0][institucion.id]);
            institucion && setFieldValue("dominio", correos[0][institucion.id]);
        }, 500);
    }, [institucion]);

return (
    <div>
        <FormControl sx={{ mt: 1 }}>
            <TextField
                id="dominio"
                name="dominio"
                value={iniciales}
                InputProps={{
                    readOnly: true,
                }}
            />
        </FormControl>
    </div>
);
}
