import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {add, format, parse} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import API from "src/features/App/API";
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from "src/features/App/sliceApp";
import {MySendButton} from 'src/components/MySendButton'
import {MySubtitle} from 'src/components/MySubtitle'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import GLOBALS from 'src/features/App/globals'
import {SectionTitle} from "../../components/SectionTitle";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {AccountCircle} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MyButtonBacan} from "../../components/MyButtonBacan";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Toolbar from "@mui/material/Toolbar";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import {MySwitch} from "../../components/MySwitch";
import {MyTextField} from "../../components/MyTextField";
import {MyDatePicker} from "../../components/MyDatePicker";
import {TabContext, TabList, TabPanel} from "@mui/lab"
import {P0102PayloadRO} from "./P0102PayloadRO";
import {P0102Expediente} from "./P0102Expediente";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {f} from "../../commons";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({id}) => {
  const section = 'RegistrarContrato'
  const dirName = 'registro'
  const inputRef = useRef()
  const dispatch = useDispatch()
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:           today,
    identificador:   solicitud.numeroSolicitud,
    cuentaConIRCC:   false,
    idRegistro:      '',
    pdfSigned:       ''
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const [recursoSelected, setRecursoSelected] = useState({})
  const dResolucion = parse(slct.payload.Resolucion.fecha,'dd-MMMM-yyyy', new Date(), {locale: es})
  const plazo = Number(slct.payload.Propuesta.plazo)
  const dPlazo = add(dResolucion, {months: plazo})
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {setCounter(counter + 1)}, [formValues])
  const nombreOrganizacion = 'Senescyt'
  const resolucionSubfolder = `resolucion-${nombreOrganizacion}`
  const resolucionFilename = `resolucion-${formValues.identificador}-${nombreOrganizacion}`
  const contratoFilename = `contrato-${formValues.identificador}-${nombreOrganizacion}`
  const contratoFilenamePDF = `${contratoFilename}.pdf`
  const filenamePDFuploaded = `${contratoFilename}-upld.pdf`
  const resolucionFilenamePDFsigned = `${resolucionFilename}-firmado.pdf`
  const contratoSubfolder = `contrato-${nombreOrganizacion}`
  const contratoFilenamePDFsigned = `${contratoFilename}-firmado.pdf`
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab);
  }
  const contratoPdf = (payload?.ElaborarContrato?.pdfLded !== '')?payload?.ElaborarContrato?.pdfLded:payload?.ElaborarContrato?.pdf

  const labelTab2 = ['55000015_Activity_RegistrarContratoWF0102'].includes(id)?'Solicitud':'Solicitud Modificatoria'
  const labelTab3 = ['55000015_Activity_RegistrarContratoWF0102'].includes(id)?'Expediente':'Expediente Modificatoria'

  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <Grid sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
          <AppBar position='fixed'
                  color='primary'
                  elevation={0}
                  sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
            <SectionTitle title={(instanciaTarea.tareaCodigoTarea ==='55000017_Activity_RegistrarContratoWF0405')?
              'Registro de contrato modificatorio de acceso con potencial uso comercial':
              'Registro de contrato de acceso con potencial uso comercial'} />
          </AppBar>
          <TabContext value={myTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab}
                       aria-label="lab API tabs example">
                <Tab label="Formulario" value="1" />
                <Tab label={labelTab2} value="2" />
                <Tab label={labelTab3} value="3" />
                {
                  f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                    <Tab label={`Solicitud original`} value="4"/> : null
                }
                {
                  f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                    <Tab label={'Expediente original'} value="5"/> : null
                }
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid container spacing={1} sx={accordeonBox.container2}>
                <Solicitante solicitud={{solicitud: {payload}}} displayContact={false} />
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Resolución'} />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{p:'0 1rem 0 0'}} >
                    <MyReadOnlyTextField id='identificador'
                                         label={'Identificador'}
                                         value={solicitud?.numeroSolicitud}
                                         icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                    <MyReadOnlyTextField id='fecha'
                                         label={'Fecha'}
                                         value={slct?.payload?.Resolucion?.fecha}
                                         icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                    <MyButtonBacan label={'VER PDF'}
                                   onClick={() => {
                                     const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${solicitud?.id}/${resolucionSubfolder}/${resolucionFilenamePDFsigned}`
                                     fetch(url)
                                       .then((res) => { return res.blob(); })
                                       .then((data) => {
                                         const dataPdf = new Blob([data], { type: 'application/pdf' })
                                         const a = document.createElement("a")
                                         a.href = window.URL.createObjectURL(dataPdf)
                                         a.target="_blank"
                                         a.click()
                                       })
                                   }}
                                   myTip={'Ver resolución'}
                                   icon={FileDownloadOutlinedIcon} />
                  </Stack>
                </Grid>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Contrato'} />
                </Grid>
                <Grid item xs={3} style={{padding:'0 24px 0 0.5rem'}} >
                  <MyReadOnlyTextField id='identificador'
                                       label={'Identificador'}
                                       value={solicitud?.numeroSolicitud}
                                       icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                </Grid>
                <Grid item xs={3} style={{padding:'0 24px 0 0.5rem'}} >
                  <MyReadOnlyTextField id='fecha'
                                       label={'Fecha de emisión'}
                                       value={format(dResolucion, 'dd-MMM-yyyy',{locale: es})}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                </Grid>
                <Grid item xs={3} style={{padding:'0 24px 0 0.5rem'}} >
                  <MyReadOnlyTextField id='plazo'
                                       label={'Vigencia'}
                                       value={`${plazo} meses`}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                </Grid>
                <Grid item xs={3} style={{padding:'0 24px 0 0.5rem'}} >
                  <MyReadOnlyTextField id='fechaCaducidad'
                                       label={'Fecha de caducidad'}
                                       value={format(dPlazo, 'dd-MMM-yyyy', {locale: es})}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}} />} />
                </Grid>
                <Grid item xs={3} style={{padding:'0 24px 0 0.5rem'}} >
                  <MyTextField id={'idRegistro'}
                               label={'Número de contrato *'}
                               formValues={formValues}
                               setFormValues={setFormValues}
                               handleChange={handleChange}
                               canEdit={true} />
                </Grid>
                <Grid item xs={3} style={{padding:'1rem 24px 0 0.5rem'}} >
                  <MyDatePicker id={'fecha'}
                                canEdit={true}
                                label={'Fecha de firma del contrato'}
                                formValues={formValues}
                                setFormValues={setFormValues} />
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={3} style={{padding:'2rem 1rem 0 0.5rem'}} >
                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} >
                    <MyButtonBacan label={'VER PDF'}
                                   onClick={() => {
                                     const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/${contratoSubfolder}/${contratoPdf}`
                                     fetch(url)
                                       .then((res) => { return res.blob(); })
                                       .then((data) => {
                                         const dataPdf = new Blob([data], { type: 'application/pdf' })
                                         const a = document.createElement("a")
                                         a.href = window.URL.createObjectURL(dataPdf)
                                         a.target="_blank"
                                         a.click()
                                       })
                                   }}
                                   myTip={'Ver resolución'}
                                   icon={FileDownloadOutlinedIcon} />
                  </Stack>
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1} style={{padding:'0.4rem 0 0 0rem'}}>
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton inputRef={inputRef}
                                          label={'PDF (firmado)'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={'contrato-Senescyt'}
                                          fileName={filenamePDFuploaded}
                                          afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFuploaded})}
                                          toolTip={'Subir contrato firmado'}
                                          width={'9rem'} />
                      {
                        formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                       icon={FileDownloadIcon}
                                                                       onClick={() => API.fetchDownloadPDF({
                                                                         solicitudId: instanciaProceso?.solicitud?.id,
                                                                         subfolder: 'contrato-Senescyt',
                                                                         filename: filenamePDFuploaded,
                                                                       })}
                                                                       toolTip={'Descargar el contrato firmado'}
                                                                       width={'3rem'}/>
                      }
                    </ButtonGroup>
                  </Stack>
                </Grid>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Certificado internacional de investigación'} />
                </Grid>
                <Grid item xs={4} style={{padding:'0.4rem 24px 0 0.5rem'}} >
                  <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1} style={{padding:'0.4rem 0 0 0rem'}}>
                    <MySwitch id={'cuentaConIRCC'}
                              canEdit={true}
                              label={'¿Este contrato cuenta con IRCC? *'}
                              formValues={formValues}
                              fullWidth={false}
                              handleChange={handleChange} />
                  </Stack>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <P0102PayloadRO payload={payload}/>
            </TabPanel>
            <TabPanel value="3">
              <P0102Expediente payload={payload}/>
            </TabPanel>
            {
              f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                <TabPanel value="4">
                  <P0102PayloadRO payload={payload.solicitudOriginal.payloadSolicitud}/>
                </TabPanel> : null
            }
            {
              f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                <TabPanel value="5">
                  <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
                </TabPanel> : null
            }
          </TabContext>
        </Grid>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                  <MyGobackButton onGoback={() => dispatch(handleClear())} />
                  <MySaveButton onSave={() => {
                                  payload[section]=formValues
                                  dispatch(handleSaveSolicitud(
                                    instanciaProceso?.id,
                                    payload,
                                    () => setCounter(0)
                                  ))
                                }}
                                disabled={counter <= 0 } />
                  <MySendButton onSend={() => {
                                    const metadata = JSON.stringify({
                                    "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    "icrr": formValues.cuentaConIRCC,
                                  })
                                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                  if(instanciaTarea.tareaCodigoTarea ==='55000017_Activity_RegistrarContratoWF0405') {
                                    API.fetchSuspenderSolicitud(payload?.solicitudOriginal?.idSolicitud, true).then(res => {
                                      console.log('02 :: >> ', res, )
                                    })
                                  }
                                }}
                                disabled={counter > 0 || formValues.idRegistro === '' || formValues.pdfSigned === '' }
                                label={'enviar'} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}
