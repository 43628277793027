import {
  AlignmentType,
  HeadingLevel,
  Paragraph,
  // TabStopType,
  TextRun,
  Table,
  TableCell,
  TableRow,
  WidthType,
  BorderStyle,
  ImageRun,
  VerticalAlign, PageNumber,
} from "docx";
import {f} from "../commons";
import {MyTableCell} from "./MyTableCell";

// const tabStops2C = [
//   {
//     type: TabStopType.RIGHT,
//     position: 2268,
//   },
// ]

const dafaultfontSize = 20

const escudo = fetch('https://cdn.jsdelivr.net/gh/vuvib/logos@master/republica-ecuador-escudo.png').then((r) => r.blob())

const senescyt = fetch('https://cdn.jsdelivr.net/gh/vuvib/logos@master/logo-senescyt.jpeg').then((r) => r.blob())

const externa = fetch('https://cdn.jsdelivr.net/gh/vuvib/logos@master/logo-senescyt.jpeg').then((r) => r.blob())

const maate = fetch('https://cdn.jsdelivr.net/gh/vuvib/logos@master/logo-maate.png').then((r) => r.blob())

const senadi = fetch('https://cdn.jsdelivr.net/gh/vuvib/logos@master/logo-senadi.png').then((r) => r.blob())

const inabio = fetch('https://cdn.jsdelivr.net/gh/vuvib/logos@master/logo-inabio.png').then((r) => r.blob())

const direcciones = {
  senescyt : {
    dir0: 'Dirección: Edificio Matriz: Alpallana E7-183 entre Av. Diego de Almagro y Whymper',
    dir1: 'Código Postal: 170518 / Quito - Ecuador',
    dir2: 'Teléfono: 593-2 3934-300'
  },
  externa : {
    dir0: '',
    dir1: '',
    dir2 : ''
  },
  maate : {
    dir0: 'Calle Madrid 1159 y Andalucía',
    dir1: 'Código Postal: 170525 / Quito - Ecuador',
    dir2 : 'Teléfono: 593-2 398-7600'
  },
  senadi : {
    dir0: 'Av. República E7-197 y Diego de Almagro – Edificio FORUM 300 Planta Baja, Mezzanine, Piso 1, Piso 3 y Piso 5',
    dir1: 'Código Postal: 170518 / Quito - Ecuador',
    dir2: 'PBX (593) 3-940000 / 3940001 / 3940002 3,4,5,6,7,8,9 / 3940010'
  },
  inabio: {
    dir0: 'Dirección. Pje. Rumipamba N. 341 y Av. de los Shyris (Parque La Carolina)',
    dir1: 'Quito - Ecuador',
    dir2: 'Teléfono. (+593)02 2449-824',
  }
}

const gobiernodetodos = fetch('https://cdn.jsdelivr.net/gh/vuvib/logos@master/gobierno-de-todos.png').then((r) => r.blob())

export const createLogoHeader = (org='senescyt') => {

  let rows = [
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new ImageRun({
                  data: escudo,
                  transformation: {
                    width:  141,
                    height: 65,
                  },
                }),
              ],
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [
            new Paragraph({
              alignment: AlignmentType.END,
              children: [
                new ImageRun({
                  data: {maate, senescyt, senadi, inabio, externa}[org],
                  transformation: {
                    width: {'externa':300, 'senescyt':272, 'maate':644*0.7,'senadi':463*0.8, 'inabio':463*0.8}[org] ?? 300,
                    height: {'externa':32, 'senescyt':34, 'maate':122*0.7,'senadi':109*0.8,'inabio':109*0.8}[org] ?? 32,
                  },
                }),
              ],
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
        }),
      ],
    }),
  ]

  return new Table({
    rows,
    width: {size: 100, type: WidthType.PERCENTAGE,},
    borders: {
      top: { style: BorderStyle.NONE,},
      bottom: { style: BorderStyle.NONE, },
      left: { style: BorderStyle.NONE, },
      right: {style: BorderStyle.NONE,},
      insideVertical: {style: BorderStyle.NONE,}
    },
  })
}

export const createLogoFooterSenescyt = (org='senescyt') => {
  const direccion = direcciones[org]
  return new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children:[
                  new TextRun({
                    text: direccion.dir0,
                    font: "Arial",
                    size: 12,
                  }),
                ],
              }),
              new Paragraph({
                children:[
                  new TextRun({
                    text: direccion.dir1,
                    font: "Arial",
                    size: 12
                  }),
                ]
              }),
              new Paragraph({
                children:[
                  new TextRun({
                    text: direccion.dir2,
                    font: "Arial",
                    size: 12
                  }),
                ]
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.END,
                children: [
                  new ImageRun({
                    data: gobiernodetodos,
                    transformation: {
                      width: 227,
                      height: 53,
                    },
                  }),
                ],
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      }),
    ],
    width: { size: 100, type: WidthType.PERCENTAGE, },
    borders: {
      top: {
        style: BorderStyle.NONE,
      },
      bottom: {
        style: BorderStyle.NONE,
      },
      left: {
        style: BorderStyle.NONE,
      },
      right: {
        style: BorderStyle.NONE,
      },
      insideVertical: {
        style: BorderStyle.NONE,
      }
    },
  });
}

export const dxC1C2 = ({text1, text2, marginLeft=0, c1width=24}) => {
  const noBorder = {style: BorderStyle.NONE, size: 0, color: "#ffffff"}
  return new Table({
    width: {size: 100, type: WidthType.PERCENTAGE,},
    margins: {
      top: 0,
      right: 0,
      bottom: 0,
      left: marginLeft,
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: { top: noBorder, bottom: noBorder, left: noBorder, right: noBorder },
            width: {size: c1width, type: WidthType.PERCENTAGE},
            children: [
              new Paragraph({
                spacing: {before: 50, after:50,},
                children: [dxTextRun({text:`${text1}`, bold:true}),]
              })
            ],
          }),
          new TableCell({
            borders: { top: noBorder, bottom: noBorder, left: noBorder, right: noBorder },
            width: {size: 75, type: WidthType.PERCENTAGE},
            children: [
              new Paragraph({
                spacing: {before: 100, after:100, line:280},
                children: [dxTextRun({text:`${text2}`})]
              })
            ],
          }),
        ],
      }),
    ]
  })
}

export const dxC1C2line = ({text1, text2, marginLeft=0, c1width=24}) => {
  const border = {style: BorderStyle.SINGLE, size: 0, color: "#888888"}
  return new Table({
    width: {size: 100, type: WidthType.PERCENTAGE,},
    margins: {
      top: 0,
      right: 0,
      bottom: 0,
      left: marginLeft,
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: { top: border, bottom: border, left: border, right: border },
            width: {size: c1width, type: WidthType.PERCENTAGE},
            children: [
              new Paragraph({
                spacing: {before: 100, after:100, line:280},
                children: [dxTextCellRun({text:`${text1}`, bold:true}),]
              })
            ],
          }),
          new TableCell({
            borders: { top: border, bottom: border, left: border, right: border },
            width: {size: 75, type: WidthType.PERCENTAGE},
            children: [
              new Paragraph({
                spacing: {before: 100, after:100, line:280},
                children: [dxTextRun({text:`${text2}`})]
              })
            ],
          }),
        ],
      }),
    ]
  })
}

export const dxTitle = ({text, level='TITLE', alignment='CENTER', font='Arial',after=null, color='000000'}) => {
  const size = {
    'TITLE': 20,
    'TITLE2': 18,
    'HEADING_2': 24,
    'HEADING_3': 20,
    'HEADING_4': 18,
  }[level] ?? 28

  const bold = {
    'TITLE': true,
    'TITLE2': false,
    'HEADING_2': true,
    'HEADING_3': true,
    'HEADING_4': false,
  }[level] ?? false

  const before = {
    'TITLE': 300,
    'TITLE2': 40,
    'HEADING_2': 500,
    'HEADING_3': 30,
    'HEADING_4': 30,
  }[level] ?? 500

  return new Paragraph({
    spacing: {after: after ?? 200, before},
    alignment: AlignmentType[alignment],
    children: [
      dxTextRun({text, font, size, heading: HeadingLevel[level], bold, color})
    ],
  })
}

export const dxSubTitleBlue = ({text}) => dxTitle({level: 'HEADING_2', text, alignment: 'LEFT', color:'0000DD', after:50})

export const dxSubTitleGray = ({text}) => dxTitle({level: 'HEADING_3', text, alignment: 'LEFT', color:'888888', after:10})

export const dxParagraph = (dxTexts, after=200, before=100, line=280) => {
  return new Paragraph({
      spacing: {after, before, line},
      alignment: AlignmentType.JUSTIFIED,
      children: dxTexts,
    },
  )
}

export const dxBulletParagraph = (dxTexts) => {
  return new Paragraph({
      spacing: {after: 200, before:100, line:280},
      alignment: AlignmentType.JUSTIFIED,
      children: dxTexts,
      bullet: { level: 0 },
    },
  )
}

export const dxQr = (qr) => {
  return new Paragraph({
    alignment: AlignmentType.CENTER,
    children: [
      new ImageRun({
        data:qr,
        transformation: {
          width: 150,
          height: 150,
        },
      }),
    ],
  });
}

export const dxTextRun = ({text, font='Arial', size=dafaultfontSize, heading=null, bold=false, color='000000'}) => {
  let opts = {text, font, size, bold, color}
  opts = f.isValid(heading)?{...opts, heading}:opts
  return new TextRun({...opts})
}

export const dxTextCellRun = ({text, font='Arial', size=dafaultfontSize, heading=null, bold=false, color='000000'}) => {
  let opts = {text, font, size, bold, color}
  opts = f.isValid(heading)?{...opts, heading}:opts
  return new TextRun({...opts})
}
