import React, {useEffect, useRef, useState} from 'react'
import {Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import {
  handleSaveSolicitud,
  handleClear,
} from 'src/features/App/sliceApp'
import {MySendButton} from 'src/components/MySendButton'
import {SectionTitle} from 'src/components/SectionTitle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import {f} from 'src/commons/f'
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MySubtitle} from "../../components/MySubtitle";
import {MyTextField} from "../../components/MyTextField";
import API from 'src/features/App/API'
import {Solicitante} from "../P01Solicitud/subcomponents/Solicitante";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MySwitch} from "../../components/MySwitch";
import GLOBALS from "../App/globals";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {handCompletarTareaCoordinador} from 'src/features/App/sliceApp'
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "../P01Solicitud/P0102PayloadRO";
import {P16SolicitudRO} from "./P16SolicitudRO";
import {MyButtonAlert} from "../../components/MyButtonAlert";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import DoneAllIcon from "@mui/icons-material/DoneAll";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({instanciaTarea, instanciaProceso, solicitud, perfilUsuario, solicitudesAprobadas}) => {
  const dispatch = useDispatch()
  const section = 'Verificar'
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const mp = slct.payload.Solicitud.solicitudAprobada
  const payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const sap = JSON.parse(payload?.Solicitud?.solicitudAprobada)
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:         today,
    requisito01:   false,
    requisito02:   false,
    requisito03:   false,
    requisito04:   false,
    requisito05:   false,
    requisito06:   false,
    requisito07:   false,
    pronunciamientoMaate: true,
    pronunciamientoSenadi: false,
    criterioTecnicoSenescyt: false,
    ampliacionInformacionSenescyt: false,
    cumpleRequisitos: false,
    asunto:        '',
    detalle:       '',
    serial:        '',
    docx:          '',
    pdf:           '',
    docxLded:      '',
    pdfLded:       '',
    pdfSigned:     '',
  }

  const canEdit = instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, 'entrada', setFormValues, formValues)
  const [counter, setCounter] = useState(-1)  // updating counter

  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])

  useEffect(() => {
    if(formValues.cumpleRequisitos) {
      setFormValues({
        ...formValues,
        asunto: 'SOLICITUD DE CIERRE DE AUTORIZACIÓN CUMPLE REQUISITOS',
        detalle: '' +
          `Estimado/a ${mp?.Solicitante?.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para el cierre de autorización registrada con el Nro. ${solicitud.numeroSolicitud} ` +
          `correspondiente al proyecto titulado ${mp?.Propuesta?.nombre}, cumple con los requisitos. \n` +
          '\n\nSaludos cordiales\n\n' +
          `${perfilUsuario.usuario.nombreUsuario}`
      })
    } else {
      setFormValues({
        ...formValues,
        asunto: 'SOLICITUD DE PERMISO DE INVESTIGACIÓN NO CUMPLE CON REQUISITOS',
        detalle: `Estimado/a ${mp?.Solicitante?.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para el cierre de autorización registrada con el Nro. ${solicitud.numeroSolicitud} ` +
          `correspondiente al proyecto titulado ${mp?.Propuesta?.nombre}, no cumple con los requisitos. \n` +
          '\n\nSaludos cordiales\n\n' + `${perfilUsuario.usuario.nombreUsuario}`
      })
    }
  }, [formValues['cumpleRequisitos']])

  useEffect(() => {
    if(canEdit) {
      setFormValues({...formValues, cumpleRequisitos: formValues.requisito01 &&
                                                            formValues.requisito02 &&
                                                            formValues.requisito03 &&
                                                            formValues.requisito04 &&
                                                            formValues.requisito05 &&
                                                            formValues.requisito06 &&
                                                            formValues.requisito07
      })
    }
  }, [
    formValues.requisito01,
    formValues.requisito02,
    formValues.requisito03,
    formValues.requisito04,
    formValues.requisito05,
    formValues.requisito06,
    formValues.requisito07,
  ]);

  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => { setMytab(newTab) }
  useEffect(() => {
    setFormValues({...formValues, pronunciamientoSenadi: sap?.AccesoConocimiento?.accesoConocimiento})
  }, [])
  const inputRef = useRef()
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `informe-tecnico-${nombreOrganizacion}`
  const filename = `informe-tecnico-${formValues.identificador}-${nombreOrganizacion}`
  const filenamePDFsigned = `${filename}-firmado.pdf`
  const codigoAutorizador = {
    'Maate':    1143,
    'Senadi':   1148,
    'Senescyt': 1139,
  }[nombreOrganizacion]
  const codigoCoordinador = {
    'Maate':    1144, // m_vasquez2008@hotmail.es
    'Senadi':   1148,
    'Senescyt': 164,
  }[nombreOrganizacion]
  const funcionarios = useSelector(state => state.app.funcionarios)
  const autorizador = funcionarios.filter(it => it.idPerfil === codigoAutorizador)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === codigoCoordinador)[0]
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
          instanciaProceso?.id,
          payload,
          () => setCounter(0)
        )
      )
    }
  }
  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Verificar requisitos'} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud de cierre" value="2" />
            <Tab label="Solicitud" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            {f.isValid(payload.T55000025_Activity_AsignarCasoSenescyt_WF16?.observaciones) && payload.T55000025_Activity_AsignarCasoSenescyt_WF16?.observaciones !== '' &&
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}
                       style={{padding: '1rem 24px 1rem 0'}}>
                  <MyButtonAlert label={'Directriz del caso'}
                                 color0={'#888888'}
                                 color1={'#888888'}
                                 messageTitle='Directriz del caso'
                                 messageContent={payload.T55000025_Activity_AsignarCasoSenescyt_WF16?.observaciones}/>
                </Stack>
              </Grid>
            }
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Declaración'} />
            </Grid>
            <Grid item xs={6} >
              <MyReadOnlyTextField label={'Identificador'}
                                   icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                   value={solicitud.numeroSolicitud} />
            </Grid>
            <Grid item xs={6} >
              <MyReadOnlyTextField label={'Fecha'}
                                   icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                   value={formValues['fecha']} />
            </Grid>

            <Solicitante solicitud={{solicitud: {payload: mp}}} displayContact={false}/>

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Requisitos'} />
            </Grid>
            <Grid item xs={6} style={{padding:'0.1rem 2rem 0.1rem 2rem'}}>
              <MySwitch id={'requisito01'}
                        label={'¿Resumen ejecutivo en español?'}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        handleChange={handleChange}
                        canEdit={canEdit} />
            </Grid>
            <Grid item xs={6} style={{padding:'0.1rem 2rem 0.1rem 2rem'}}>
              <MySwitch id={'requisito02'}
                        label={'¿Especies colectadas o número de las muestras?'}
                        formValues={formValues}
                        handleChange={handleChange}
                        canEdit />
            </Grid>
            <Grid item xs={6} style={{padding:'0.1rem 2rem 0.1rem 2rem'}}>
              <MySwitch id={'requisito03'}
                        label={'¿Área de estudio?'}
                        formValues={formValues}
                        handleChange={handleChange}
                        canEdit />
            </Grid>
            <Grid item xs={6} style={{padding:'0.1rem 2rem 0.1rem 2rem'}}>
              <MySwitch id={'requisito04'}
                        label={'¿Lugar de depósito de las muestras?'}
                        formValues={formValues}
                        handleChange={handleChange}
                        canEdit />
            </Grid>
            <Grid item xs={6} style={{padding:'0.1rem 2rem 0.1rem 2rem'}}>
              <MySwitch id={'requisito05'}
                        label={'¿Resultados obtenidos a partir de la autorización?'}
                        formValues={formValues}
                        handleChange={handleChange}
                        canEdit />
            </Grid>
            <Grid item xs={6} style={{padding:'0.1rem 2rem 0.1rem 2rem'}}>
              <MySwitch id={'requisito06'}
                        label={'¿Conclusiones y recomendaciones?'}
                        formValues={formValues}
                        handleChange={handleChange}
                        canEdit />
            </Grid>
            <Grid item xs={6} style={{padding:'0.1rem 2rem 0.5rem 2rem'}}>
              <MySwitch id={'requisito07'}
                        label={'Publicaciones derivadas de las autorizaciones'}
                        formValues={formValues}
                        handleChange={handleChange}
                        canEdit />
            </Grid>
            <Grid item xs={6} style={{padding:'0.1rem 2rem 0.5rem 2rem'}}>
              <MySwitch id={'cumpleRequisitos'}
                        label={'¿Cumple requisitos?'}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        canEdit={false}
                        fullWidth={false}
                        handleChange={handleChange} />
            </Grid>
            <Grid container>
              <Grid item xs={6} sx={{pr:'2.2rem'}}>
                <Stack direction={'column'}>
                  <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
                    <MySubtitle subtitle={'Pronunciamiento MAATE'} />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction={'column'} >
                      <MySwitch id='pronunciamientoMaate'
                                label={'¿Se requiere pronunciamiento?'}
                                formValues={formValues}
                                fullWidth={false}
                                canEdit={false}
                                handleChange={handleChange}/>
                      {f.isValidNotEmpty(payload?.EmitirInformeTecnicoMaate?.pdfSigned) ?
                          <MyButtonBacan label={'DICTAMEN'}
                                         onClick={() => {
                                           const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/informe-tecnico-Maate/${payload?.EmitirInformeTecnicoMaate?.pdfSigned}`
                                           fetch(url)
                                             .then((res) => { return res.blob(); })
                                             .then((data) => {
                                               const dataPdf = new Blob([data], { type: 'application/pdf' })
                                               const a = document.createElement("a")
                                               a.href = window.URL.createObjectURL(dataPdf)
                                               a.target = "_blank"
                                               a.click()
                                             })
                                         }}
                                         icon={FileDownloadOutlinedIcon} /> : false
                      }
                    </Stack>
                  </Grid>
                </Stack>
              </Grid>

              <Grid item xs={6} sx={{pr:'2.5rem'}}>
                <Stack direction={'column'}>
                  <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
                    <MySubtitle subtitle={'Pronunciamiento SENADI'} />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction={'column'} >
                    <MySwitch id='pronunciamientoSenadi'
                              label={'¿Se requiere pronunciamiento?'}
                              formValues={formValues}
                              fullWidth={false}
                              canEdit={sap?.AccesoConocimiento?.accesoConocimiento}
                              handleChange={handleChange}/>
                      {f.isValidNotEmpty(payload?.EmitirInformeTecnicoSenadi?.pdfSigned) ?
                          <MyButtonBacan label={'DICTAMEN'}
                                         onClick={() => {
                                           const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/informe-tecnico-Senadi/${payload?.EmitirInformeTecnicoSenadi?.pdfSigned}`
                                           fetch(url)
                                             .then((res) => { return res.blob(); })
                                             .then((data) => {
                                               const dataPdf = new Blob([data], { type: 'application/pdf' })
                                               const a = document.createElement("a")
                                               a.href = window.URL.createObjectURL(dataPdf)
                                               a.target = "_blank"
                                               a.click()
                                             })
                                         }}
                                         icon={FileDownloadOutlinedIcon} /> : null
                      }
                    </Stack>
                  </Grid>

                </Stack>
              </Grid>

              <Grid item xs={12}  sx={{p:'2rem 2rem 0 0'}}>
                <Stack direction={'row'}
                       justifyContent="flex-end"
                       alignItems="center"
                       spacing={2}>
                  <MySendButton onSend={ () => {
                                  const metadata = JSON.stringify({
                                    "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                    pronunciamientoSenadi:   formValues.pronunciamientoSenadi,
                                    pronunciamientoMaate:    formValues.pronunciamientoMaate,
                                    requisitosVerificados:   false,
                                    masConsultas:            true,
                                    masInformacion:          false,
                                    cumpleRequisitos:        true,
                                    criterioTecnicoSenescyt: false
                                  })
                                  dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                label={'Solicitar'}
                                myTip={'Solicitar pronunciuamiento a MAATE y/o SENADI'}
                                disabled={counter > 0 || f.isValidNotEmpty(payload?.EmitirInformeTecnicoMaate?.pdfSigned) || f.isValidNotEmpty(payload?.EmitirInformeTecnicoSenadi?.pdfSigned)} />
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Informe técnico de cierre'} />
              </Grid>
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} style={{padding:'0 0 0 1rem'}}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = 'REPSEG'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave({...formValues, serial:serial})()
                                            setFormValues({...formValues, serial:serial})
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',
                                          pdfSigned: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar Informe'}
                                      width={'11rem'}
                                      onClick={() => {
                                        const doc = MyTagged2Docx(plantilla(payload), nombreOrganizacion, P0102API.mapping({
                                          solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                          payload,
                                          autorizador,
                                          coordinador,
                                          perfilUsuario,
                                          nombreOrganizacion,
                                          tipo: instanciaProceso.tipoInstanciaProceso?.toLowerCase(),
                                          section: payload[section]
                                        }))
                                        API.genDocxAnPdf({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          document: doc,
                                          formValues,
                                          setFormValues,
                                          subfolder,
                                          filename: filenameDOCX,
                                          nombreOrganizacion,
                                        })
                                      }}
                                      toolTip={'Generar'}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                      })}
                                      toolTip={'Descargar documento en formato docx'}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })}
                                      toolTip={'Descargar documento en formato pdf'}
                                      width={'5rem'} />
                    </>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton inputRef={inputRef}
                                          label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={() => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                              } else {
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                              }
                                            })
                                          }}
                                          toolTip={'Subir documento modificado en formato docx'}
                                          width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <>
                      <MyFileUploadButton inputRef={inputRef}
                                          label={'PDF (firmado)'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenamePDFsigned}
                                          afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                          toolTip={'Subir documento firmado o para firmar en formato pdf'}
                                          width={'11rem'} />
                      <MyFileUploadButton inputRef={inputRef}
                                          label={'firmar pdf'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenamePDFsigned}
                                          afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                          disabled={true}
                                          icon={() => <DoneAllIcon height={'1rem'} fill={'rgba(255, 255, 255, 0.6)'} />}
                                          toolTip={'Firmar documento electrónicamente'}
                                          width={'11rem'} />
                      {
                        formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                       icon={FileDownloadIcon}
                                                                       onClick={() => API.fetchDownloadPDF({
                                                                         solicitudId: instanciaProceso?.solicitud?.id,
                                                                         subfolder,
                                                                         filename: filenamePDFsigned,
                                                                       })}
                                                                       toolTip={'Descargar dictamen tecnico'}
                                                                       width={'2rem'}/>
                      }
                    </>
                  }
                </Stack>
              </Grid>

              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Notificacion'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 1rem 0 0'}}>
                <MyTextField id={'asunto'}
                             label={'Asunto *'}
                             formValues={formValues}
                             handleChange={handleChange}
                             canEdit false/>
              </Grid>
              <Grid item xs={12} style={{padding:'0 1rem 0 0'}}>
                <MyAreaTextField id={'detalle'}
                                 label={'Contenido *'}
                                 formValues={formValues}
                                 handleChange={handleChange}
                                 canEdit={false} />
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <P16SolicitudRO payload={payload} />
        </TabPanel>
        <TabPanel value="3">
          <P0102PayloadRO payload={sap} />
        </TabPanel>
      </TabContext>

      <CssBaseline />
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'0 4rem 0 4rem'}}>
                <MyButtonBacan label={'Regresar'}
                               myTip={'Regresar a las tareas'}
                               icon={ArrowBackIcon}
                               onClick={() => { dispatch(handleClear()) }} />
                <MyButtonBacan label={'Guardar'}
                               myTip={'Guarda el formulario, y permite continuar editando'}
                               onClick={() => {
                                 payload[section]=formValues
                                 setCounter(0)
                                 dispatch(handleSaveSolicitud(instanciaProceso?.id,payload))
                               }}
                               disabled={!canEdit || counter <= 0}
                               icon={SaveOutlinedIcon} />
                <MySendButton onSend={ () => {
                                const metadata = JSON.stringify({
                                  "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                  "pronunciamientoMaate": false,
                                  "pronunciamientoSenadi": false,
                                  "requisitosVerificados": formValues.cumpleRequisitos,
                                  "criterioTecnicoSenescyt": false,
                                  "ampliacionInformacionSenescyt": false,
                                  "asunto": formValues.asunto,
                                  "detalle": formValues.detalle,
                                })
                                dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'Enviar'}
                              disabled={
                                formValues.identificador === '' ||
                                formValues.resultados === '' ||
                                formValues.pdf === '' ||
                                formValues.asunto === '' ||
                                formValues.detalle === '' ||
                                counter > 0
                              } />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

const plantilla = (payload) => {
  const observacionesReporte = payload?.ElaborarReporteSeguimiento?.observacionesReporte?.join('{:}')
  return (""
    + '${TITULO1}INFORME TÉCNICO'
    + '${SALTO}${CENTRAR}No. <<serial>>'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}<<sistema.fecha>>'
    + '${SALTO}${ENCABEZADO.REPORTE}<<solicitud.nombreProyecto>>'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${ENCABEZADO.DATOS}Datos generales{:}<<solicitud.identificador>>'
    + '${SALTO}'
    + '${SALTO}${ENCABEZADO.DETALLE}Funcionario responsable de informe{:}Nombre{:}Contacto{:}Teléfono{:}Correo electrónico{:}Cargo{:}<<usuario.nombre>>{:}<<usuario.rol>>{:}<<usuario.cargo>>{:}<<usuario.telefono>>{:}<<usuario.correoElectronico>>'
    + '${SALTO}${ENCABEZADO.DETALLE}Funcionario responsable de informe{:}Nombre{:}Contacto{:}Teléfono{:}Correo electrónico{:}Cargo{:}<<sistema.nombreAutorizadorPrincipalSenescyt>>{:}<<sistema.rolAutorizadorPrincipalSenescyt>>{:}<<sistema.cargoAutorizadorPrincipalSenescyt>>{:}<<sistema.telefonoAutorizadorPrincipalSenescyt>>{:}<<sistema.coreoElectronicoAutorizadorPrincipalSenescyt>>'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}1.  ANTECEDENTES'
    + '${SALTO}${PARRAFO}(ANTECEDENTES DEFINIDO POR LA INSTITUCIÓN)'
    + '${SALTO}'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}2.  ALCANCE'
    + '${SALTO}${PARRAFO}DEFINIDO POR LA INSTITUCIÓN'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}3.  BASE LEGAL   '
    + '${SALTO}${PARRAFO}'
    + '${SALTO}${PARRAFO}(DEFINIDO POR LA INSTITUCIÓN)'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}4. OBJETIVOS  '
    + '${SALTO}${PARRAFO}DEFINIDO POR LA INSTITUCIÓN'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}5. ANÁLISIS DE LA  PERTINENCIA, VIABILIDAD Y FACTIBILIDAD DE LA INVESTIGACIÓN  '
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${PARRAFO}(El análisis será establecido por cada una de las instituciones, ya sea con una plantilla base o como un campo a completar para cada solicitud).'
    + '${SALTO}${PARRAFO.VACIO}'
    + '${SALTO}${TITULO3}6. CONCLUSIONES Y RECOMENDACIONES'
    + '${SALTO}${PARRAFO}(DEFINIDO POR LA INSTITUCIÓN)'
    + '${SALTO}${FIRMA}Desarrollo del documento{:}<<usuario.nombre>>{:}<<sistema.fecha>>'
    + '${SALTO}${FIRMA}Revisión del documento{:}<<sistema.nombreCoordinadorCasosSenescyt>>{:}<<sistema.fecha>>'
    + '${SALTO}${FIRMA}Aprobación del documento{:}María José Ramirez{:}<<sistema.fecha>>')
}
