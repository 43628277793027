import React, {useState} from 'react'
import {DataGrid, gridClasses} from '@mui/x-data-grid'
import {Box, Stack} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import {MyButtonBacan2} from 'src/components/MyButtonBacan2'
import {f} from 'src/commons/f'
import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {paises} from 'src/features/P01Solicitud/CONF'

export const MyTableActividadesAcceso = ({id, formValues, setFormValues, canEdit=false, addItem=null})  => {
  const rows = formValues[id].map((item, idx) => ({...item, id:idx, }))
  const [rowSelected, setRowSelected] = useState({})

  const processRowUpdate = (newRow) => {
    const nId = newRow.id
    const nRow = {...newRow}
    const nRows = [...rows]
    const index = nRows.findIndex(it => it.id === nId)
    if(index >= 0) {
      nRows[index] = {...nRows[index], ...nRow}
      setFormValues({...formValues, [id]:[...nRows]})
    }
    return newRow
  }

  return (
    <Box sx={{ pb: '24px', width: '100%',}}>
      <Stack direction="column" spacing={2}>
        {canEdit ?
          <Stack direction='row'
                 spacing={3}
                 justifyContent='flex-end'
                 alignItems='center'>
            <Stack direction='row' spacing={3}>
              <MyButtonBacan2 onClick={addItem}
                              icon={AddIcon}
                              label={'Agregar'} />
            </Stack>
            {
              (f.isValid(rowSelected.id)) &&
              <MyButtonBacan2 onClick={() => {
                                const nRows = [...rows].filter(it => it.id !== rowSelected.id)
                                setFormValues({...formValues, [id]:[...nRows]})
                              }}
                              icon={DeleteIcon}
                              label={'Eliminar'} />
            }
          </Stack>: null
        }
        <DataGrid experimentalFeatures={{ newEditingApi: true }}
                  rows={rows}
                  columns={columns(canEdit)}
                  processRowUpdate={processRowUpdate}
                  autoHeight={true}
                  rowHeight={32}
                  pageSize={8}
                  rowsPerPageOptions={[8]}
                  headerHeight={40}
                  getRowHeight={() => 'auto'}
                  sx={{
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: "rgba(244,244,244,0.4)",
                      color: "#aeaeae",
                      fontSize: '0.8rem',
                      fontfamily: RobotoCondensedRegular,
                      fontweight: 'lighter',
                    },
                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                      '& .MuiDataGrid-row': {
                        backgroundColor: 'rgba(255, 255, 255, .7)',
                        fontSize: '0.8rem',
                        fontfamily: RobotoCondensedRegular,
                        fontweight: 'lighter',
                        color: "#888888",
                      },
                      '& .Mui-selected': {
                        color: "#000000",
                      },
                      [`& .${gridClasses.cell}`]: {
                        py: 1,
                      },
                    },
                  }}
                  onCellClick={(e) => {
                    setRowSelected(e.row)
                  }} />
      </Stack>
    </Box>
  );
}

const columns = (canEdit) => [
  {
    field: 'id',
    headerName: 'id',
    type: 'number',
    editable: false,
    hide: true
  },
  {
    field: 'actividad',
    headerName: 'Actividad',
    width: 360,
    editable: canEdit
  },
  {
    field: 'inicio',
    headerName: 'Inicio',
    width: 160,
    editable: canEdit,
    sortable: false,
    type: 'date',
    valueGetter: (params) => {
      if(f.isValidNotEmpty(params.value)) {
        return format(new Date(params.value), 'dd-MMMM-yyyy', {locale: es})
      } else {
        return ''
      }
    }
  },
  {
    field: 'fin',
    headerName: 'fin',
    width: 160,
    editable: canEdit,
    type: 'date',
    valueGetter: (params) => {
      if(f.isValidNotEmpty(params.value)) {
        return format(new Date(params.value), 'dd-MMMM-yyyy', {locale: es})
      } else {
        return ''
      }
    }
  },
  {
    field: 'pais',
    headerName: 'País',
    width: 280,
    editable: canEdit,
    type: 'singleSelect',
    valueOptions: [...paises, '',]
  },
]
