import GLOBALS from 'src/features/App/globals'
import { cargarChatRooms, cargarChats, cargarListaUsuariosChat, cerrarModalChat, limpiarChats, seleccionaRoom } from './sliceChat'
import Swal from 'sweetalert2'

const API = { sliceName: 'char' }

API.fetchOrgs = async () => {
  const url = `${GLOBALS.mainUrl}/api/v1/usuarios/getorgs`
  const res = await fetch(url)
  return res.json()
}

// otros API's

export const retornaChatRooms = (usuario) => {

  return async (dispatch) => {

    const resp = await fetchRoomsUsuario(usuario);
    const body = await resp.json();

    if (Object.keys(body).length !== 0) {
      dispatch(cargarChatRooms(body));
    } else {
      console.log("Investigador no tiene chats-rooms")
    }
  }
}

export const retornaChatRoomsxSolicitud = (solicitud) => {

  return async (dispatch) => {

    const resp = await fetchRoomsXSolicitud(solicitud);
    const body = await resp.json();

    if (Object.keys(body).length !== 0) {
      dispatch(cargarChatRooms(body));
    } else {
      console.log("Funcionario no tiene chats-rooms")
    }
  }
}

export const retornaChatsxRoom = (room) => {
  return async (dispatch) => {
    try {
      const resp = await fetchChatsxRoom(room);
      const body = await resp.json();

      if (Object.keys(body).length !== 0) {
        dispatch(cargarChats(body))
      } else {
        console.log("Room no contiene chats")
      }
    } catch (error) {
      console.log("error al retonar los chats", error)
    }
  }
}

export const guardarRooms = (solicitud, tarea, usuario, persona) => {
  return async (dispatch) => {

    const resp = await fetchSetRoom(solicitud, tarea, usuario, persona);
    const body = await resp.json();

    if (Object.keys(body).length !== 0) {
      dispatch(limpiarChats());
      dispatch(cerrarModalChat());
      dispatch(retornaChatRoomsxSolicitud(solicitud));
      setTimeout(() => {
        dispatch(seleccionaRoom(body.id));
      }, 500);
      dispatch(retornaChatsxRoom(body.id))

    } else {
      Swal.fire({
        position: "center",
        icon: 'error',
        title: "Error",
        html: "Error al crear el chat room",
        showConfirmButton: true,
        customClass: {
          container: 'my-swal'
        }
      })
    }
  }
}

export const guardarChat = (valores) => {

  return async (dispatch) => {

    try {
      const resp = await fetchSetChat(valores);
      const body = await resp.json();

      if (Object.keys(body).length !== 0) {
        console.log("Mensaje enviado")
        dispatch(retornaChatsxRoom(valores.room))
      } else {
        Swal.fire({
          position: "center",
          icon: 'error',
          title: "Error",
          html: "Ocurrió un error al enviar el mensaje",
          showConfirmButton: true,
          customClass: {
            container: 'my-swal'
          }
        })
      }

    } catch (error) {
      console.log("Error al guardar el chat", error);
    }
  }
}

export const retornaListaUsuariosChat = (solicitud) => {
  return async (dispatch) => {
    const resp = await fetchUsuarios(solicitud);
    const body = await resp.json();

    if (Object.keys(body).length !== 0) {
      dispatch(cargarListaUsuariosChat(body));
    } else {
      console.log("error al cargar los usuarios de la lista del chat")
    }
  }
}

const fetchUsuarios = (solicitud, method = 'GET') => {
  const url = `${GLOBALS.mainUrl}/v1/api/perfilusuario/chatlist?idSolicitud=${solicitud}`
  return fetch(url, {
    method,
    headers: {
      'Content-type': 'application/json'
    }
  })
}

const fetchChatsxRoom = (room, method = 'GET') => {
  const url = `${GLOBALS.mainUrl}/api/chat/message/list?idRoom=${room}`
  return fetch(url, {
    method,
    headers: {
      'Content-type': 'application/json'
    }
  })
}

export const fetchRoomsUsuario = (usuario, method = 'GET') => {
  const url = `${GLOBALS.mainUrl}/api/chat/room/list?idPerfilUsuario=${usuario}`

  return fetch(url, {
    method,
    headers: {
      'Content-type': 'application/json'
    }
  })
}

const fetchSetRoom = (solicitud, tarea, usuario, persona, method = 'POST') => {
  const url = `${GLOBALS.mainUrl}/api/chat/room/set?idSolicitud=${solicitud}&idInstanciaTarea=${tarea}&idPerfilUsuario=${usuario}&idPerfilPersona=${persona}`
  return fetch(url, {
    method,
    headers: {
      'Content-type': 'application/json'
    }
  })
}

const fetchSetChat = (valores, method = 'POST') => {
  const url = `${GLOBALS.mainUrl}/api/chat/message/set?idRoom=${valores.room}&idPerfilUsuario=${valores.usuario}&mensaje=${valores.texto}&estado=${'R'}`
  return fetch(url, {
    method,
    headers: {
      'Content-type': 'application/json'
    }
  })
}

const fetchRoomsXSolicitud = (solicitud, method = 'GET') => {
  const url = `${GLOBALS.mainUrl}/api/chat/room/solicitud?idSolicitud=${solicitud}`
  return fetch(url, {
    method,
    headers: {
      'Content-type': 'application/json'
    }
  })
}

export const fetchImagenAvatar = (id) => {
  const url = `${GLOBALS.mainUrl}/imagen/descargar?idUsuario=${id}`
  return fetch(url)
      .then((response) => {
          return response.blob();
      })
      .then((blob) => {
          return URL.createObjectURL(blob);
      });
}

export default API