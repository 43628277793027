import React, {useEffect, useRef, useState} from 'react'
import RobotoCondensedRegular from '../styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import GLOBALS from 'src/features/App/globals'
import Button from '@mui/material/Button'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import {f} from "../commons";
import {Tooltip} from "@mui/material";

export const MyFileUploadButton = ({
                                     label,
                                     solicitudId,
                                     subfolder,
                                     fileName,
                                     afterUpload,
                                     disabled=false,
                                     toolTip='',
                                     icon=() => <FileUploadIcon height={'1rem'} fill={'rgba(255, 255, 255, 0.6)'}/>,
                                     width='5rem'}) => {
  const [upldFile, setUpldFile] = useState(null)
  const ir = useRef()
  useEffect(() => {
    if(f.isValid(upldFile)) {
      const formData = new FormData()
      formData.append('idSolicitud', solicitudId)
      formData.append('subFolder', subfolder)
      formData.append('fileName', fileName)
      formData.append('archivo', upldFile)
      const requestOptions = {
        method: 'POST',
        body: formData,
        redirect: 'follow'
      }
      fetch(`${GLOBALS.mainUrl}/documentos/cargarnombre`, requestOptions)
        .then(response => response.text())
        .then(result => {
          afterUpload()
        })
    }
  }, [upldFile])
  return (
    <Tooltip title={toolTip}>
      <Button variant="contained"
              sx={{
                backgroundColor: 'rgba(54, 160, 184, 1)',
                fontSize: '0.8rem',
                fontfamily: RobotoCondensedRegular,
                fontWeight: 'normal',
                width,
                height: '36px',
              }}
              disabled={disabled}
              component='label'
              startIcon={icon()}
              size={'small'}>
        {label}
        <input ref={ir}
               accept="application"
               onChange={(e) => {
                 setUpldFile(e.target.files[0])
               }}
               onClick={(event)=> {
                 event.target.value = null
               }}
               hidden
               type='file' />
      </Button>
    </Tooltip>
  )
}
