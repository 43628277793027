import React, {useEffect, useState} from 'react'
import { useSelector} from 'react-redux'
import {
  Box,
  Grid,
} from '@mui/material'
import {dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyMultipleSelect} from 'src/components/MyMultipleSelect'
import {MyTableRecursos} from 'src/components/MyTableRecursos'
import {f} from 'src/commons/f'
import {MyTableMuestras} from 'src/components/MyTableMuestras'
import {muestras} from 'src/features/P01Solicitud/CONF'
import Typography from "@mui/material/Typography";

export const RecursosInSitu = ({section, formValues, setFormValues, canEdit, formErrors}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})
  const sectionErrors =  (sectionValues.recursos?.length === 0)?'NO SE HAN DEFINIDO RECURSOS':'' ||
    (sectionValues.muestras?.length === 0)?'NO SE HAN DEFINIDO MUESTRAS':'' ||
    (sectionValues.muestras?.filter(it => it.cantidadSolicitada === 0)?.length > 0)?'MUIESTRAS CON CANTIDADES 0':'' ||
    (sectionValues.provincias?.length === 0)?'NO SE HAN DEFINIDO PROVINCIAS':'' ||
    (sectionValues.areasProtegidas?.length === 0)?'NO SE HAN DEFINIDO AREAS PROTEGIDAS':'' ||
    (sectionValues.bosquesProtectores?.length === 0)?'NO SE HAN DEFINIDO BOSQUES PROTECTORES':'' ||
    (sectionValues.laboratorios?.length === 0)?'NO SE HAN DEFINIDO LABORATORIOS':''

  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  const provincias = useSelector(state => state.app.provincias)
  const bosquesProtectores = useSelector(state => state.app.bosques)
  const areasProtegidas  = useSelector(state => state.app.areasProtegidas)
  const laboratorios = useSelector(state => state.app.laboratorios)
  useEffect(() => {
    if(canEdit && f.isValid(setFormValues)) {
      setFormValues({...formValues,[section]: {...sectionValues}})
    }
  }, [sectionValues])
  const [recursoSelected, setRecursoSelected] = useState({})
  const [areasProtegidasData, setAreasProtegidasData] = useState([])
  const [bosquesProtectoresData, setBosquesProtectoresData] = useState([])
  useEffect(() => {
    setAreasProtegidasData(areasProtegidas.filter(it => sectionValues.provincias.includes(it.provincia)).map(it => it.nombre))
    setBosquesProtectoresData(bosquesProtectores.filter(it => sectionValues.provincias.includes(it.provincia)).map(it => it.nombre))
  }, [sectionValues.provincias])
  return(
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Recursos a ser recolectados (in situ)'} bold={true}/>
        </Grid>
        <Grid item xs={12}>
          <MyTableRecursos id={'recursos'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           muestras={sectionValues.muestras}
                           columnName={'Recursos'}
                           canEdit={canEdit}
                           mode={'in-situ'}
                           addItems={(items) => {
                             const field = 'recursos'
                             items.forEach(it => {
                               it['id'] = it.taxonid
                               it['scientificname'] = f.isValid(it.scientificname)?it.scientificname:''
                               it['redList'] = f.isValid(it.nationalredlist)?it.nationalredlist:''
                               it['taxonomicHierarchy'] = f.isValid(it.taxonomicHierarchy)?it.taxonomicHierarchy:''
                               it['categoriaEspecial'] = f.isValid(it.descripcion)?it.descripcion:''
                               it['artificialGroup'] = f.isValid(it.artificialgroup)?it.artificialgroup:''
                               it['cites'] = f.isValid(it.cites)?it.cites:''
                             })
                             const newSet = [ ...sectionValues[field]]
                             items.forEach(it => {
                               const idx = newSet.findIndex(ns => ns.taxonid === it.taxonid)
                               if(idx < 0) {
                                 newSet.push(it)
                               }
                             })
                             setSectionValues({...sectionValues, [field]:newSet})
                           }}
                           addItems2={(items) => {
                             const field = 'recursos'
                             items.forEach(it => {
                               it['id'] = it.taxonid
                               it['redList'] = f.isValid(it.nationalredlist)?it.nationalredlist:''
                               it['cites'] = f.isValid(it.cites)?it.cites:''
                               it['scientificname'] = f.isValid(it.scientificname)?it.scientificname:''
                               it['taxonomicHierarchy'] = f.isValid(it.taxonomicHierarchy)?it.taxonomicHierarchy:''
                               it['artificialGroup'] = f.isValid(it.artificialgroup)?it.artificialgroup:''
                               it['categoriaEspecial'] = f.isValid(it.descripcion)?it.descripcion:''
                             })
                             const newSet = [ ...sectionValues[field]]
                             items.forEach(it => {
                               const idx = newSet.findIndex(ns => ns.taxonid === it.taxonid)
                               if(idx < 0) {
                                 newSet.push(it)
                               }
                             })
                             setSectionValues({...sectionValues, [field]:newSet})
                           }}
                           setRecursoSelected={setRecursoSelected}
                           canDeleteRow={canEdit} />
        </Grid>
        {
          f.isValid(recursoSelected.scientificname)?
            <>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Muestras y submuestras'} />
              </Grid>
              <Grid item xs={12} >
                {recursoSelected.scientificname}
              </Grid>
              <Grid item xs={12} >
                <MyTableMuestras id={'muestras'}
                                 selected={recursoSelected}
                                 formValues={sectionValues}
                                 setFormValues={setSectionValues}
                                 canEdit={canEdit}
                                 muestras={muestras}
                                 addItem={() => {
                                   let key = 1
                                   if(sectionValues['muestras'].length > 0) {
                                     const keys = sectionValues['muestras'].map(it => Number(it.id.split('-')[1]))
                                     key = keys.reduce((a, b) => Math.max(a, b), -Infinity)+1
                                   }
                                   const newMuestra = {
                                     id:`${recursoSelected.taxonid}-${key}`,
                                     taxonid:recursoSelected.taxonid,
                                     tipo:'',
                                     submuestra: '',
                                     loteotro: '',
                                     descripcion: '',
                                     cantidadSolicitada: 0,
                                     cantidadAutorizada: 0,
                                     saldoDRM:0,
                                     saldorATM: 0,
                                   }
                                   setSectionValues({...sectionValues,['muestras']:[...sectionValues['muestras'],newMuestra]})
                                 }} />
              </Grid>
            </>:null
        }

        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Origen de los recursos'} />
        </Grid>
        <Grid item xs={6}>
          <MyMultipleSelect id={'provincias'}
                            label={'Provincias *'}
                            data={provincias}
                            setFormValues={setSectionValues}
                            formValues={sectionValues}
                            canEdit={canEdit}
                            handleChange={handleChange}/>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <MyMultipleSelect id={'areasProtegidas'}
                              label={'Áreas protegidas'}
                              data={areasProtegidasData}
                              setFormValues={setSectionValues}
                              formValues={sectionValues}
                              canEdit={canEdit}
                              handleChange={handleChange}/>
          </Grid>
          <Grid item xs={6}>
            <MyMultipleSelect id={'bosquesProtectores'}
                              label={'Bosques Protectores'}
                              data={bosquesProtectoresData}
                              setFormValues={setSectionValues}
                              formValues={sectionValues}
                              canEdit={canEdit}
                              handleChange={handleChange}/>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Destino de los recursos'} />
        </Grid>
        <Grid item xs={12} >
          <MyMultipleSelect id={'laboratorios'}
                            label={'Institución en la que se desarrollará la fase de laboratorio *'}
                            data={[...laboratorios].sort()}
                            setFormValues={setSectionValues}
                            formValues={sectionValues}
                            canEdit={canEdit}
                            handleChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          {
            (sectionErrors !== '') ? <Typography sx={{color:'red'}}>{sectionErrors}</Typography> : false
          }
        </Grid>
      </Grid>
    </Box>
  )
}
