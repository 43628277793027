import { configureStore } from '@reduxjs/toolkit'

import {sliceApp} from './sliceApp'
import {sliceP1} from 'src/features/P01Solicitud/sliceP1'
import {sliceChat} from 'src/features/chat/sliceChat'
import {sliceUsuarios} from 'src/features/usuarios/sliceUsuarios'
import { sliceAdministrador } from '../Administracion/sliceAdministrador'
import { slicePreferencias } from '../Preferencias/slicePreferencias'

const store = configureStore(
  {
    reducer: {
      app: sliceApp.reducer,
      wf01: sliceP1.reducer,
      chat: sliceChat.reducer,
      usuarios: sliceUsuarios.reducer,
      administracion: sliceAdministrador.reducer,
      preferencias: slicePreferencias.reducer
    }
  }
)

export default store
