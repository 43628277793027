import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {MyTextField} from "src/components/MyTextField";
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {MySwitch} from "../../components/MySwitch";
import {MySubtitle} from "../../components/MySubtitle";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {WF03SolicitudRO} from "./P03SolicitudRO";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MyDatePicker} from "../../components/MyDatePicker";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import GLOBALS from "../App/globals";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {WF03Expediente} from "./subcomponents/P03Expediente";

// eslint-disable-next-line import/no-anonymous-default-export
export const P03DictamenTecnicoMAATE = ({instanciaTarea, instanciaProceso, solicitud, perfilUsuario}) => {
  function getOnSave(setCounter, fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:{...formValues}
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    }
  }

  const inputRef = useRef()
  const section = 'P03DictamenTecnicoMaate'
  const dispatch = useDispatch()
  const bandeja = useSelector(state => state.app.bandeja)
  let payload = !!solicitud.payload?JSON.parse(solicitud.payload):{[section]:{}}
  payload.solicitudId = solicitud.id
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha: today,
    identificador: solicitud.numeroSolicitud,
    resolucionDeInicio : '',
    docx: '',
    pdf: '',
    docxLded: '',
    pdfLded: '',
    pdfSigned: '',
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada' && instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => { setCounter(counter + 1) }, [formValues])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }

  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion

  const subfolder = `dictamen-tecnico-${nombreOrganizacion}`
  const filename = `dictamen-tecnico-${formValues.identificador}-${nombreOrganizacion}`
  const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  const filenamePDFuploaded = `${filename}-upld.pdf`
  const filenamePDFsigned = `${filename}-firmado.pdf`

  const funcionarios = useSelector(state => state.app.funcionarios)
  const esContrato = false
  const informeTecnico = !esContrato?'56015008-1':'56015008-2'
  const autorizador = funcionarios.filter(it => it.idPerfil === 1143)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === 1144)[0]

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Dictamen técnico MAATE'}/>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
            <Tab label="Expediente" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Informe Técnico --1'} />
            </Grid>
            <Grid item xs={12} sx={{p:'0 2rem 0 2rem'}}>
              <Stack direction={'row'} justifyContent="space-between" justifyContent="flex-end" spacing={2}>
                <MyButtonBacan3 label={'INFORME TÉCNICO'}
                                icon={FileDownloadIcon}
                                onClick={() => API.fetchDownloadPDF({
                                  solicitudId: instanciaProceso?.solicitud?.id,
                                  subfolder:`informe-tecnico-${nombreOrganizacion}`,
                                  filename: `informe-tecnico-${formValues.identificador}-${nombreOrganizacion}-firmado.pdf`
                                })}
                                toolTip={'Descargar informe técnico en formato pdf'}
                                width={'12rem'} />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Dictamen Técnico'} />
            </Grid>
            <Grid item xs={12} style={{padding:'0'}}>
              <Stack direction={'row'} justifyContent="space-between" justifyContent="flex-end" spacing={2}>
                <MyReadOnlyTextField id={'fecha'} label={'Fecha'} icon={<CalendarMonthIcon  sx={dialog.readOnlyIcon} />} value={formValues.fecha} />
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = (!esContrato)?'ITMAT':'ITMATCPC'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave(setCounter,{...formValues, serial})()
                                            setFormValues({...formValues, serial:serial})
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== ''  &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',

                                          // firma -- \\
                                          pdfSigned: '',
                                          firmando: false,
                                          firmado: false,
                                          // firma -- //
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar dictamen'}
                                      width={'11rem'}
                                      onClick={() => {
                                        API.fetchPlantila(informeTecnico).then(taggeDoc => {
                                          const doc = MyTagged2Docx(plantilla, nombreOrganizacion, P0102API.mapping({
                                            solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                            payload: {...payload, [section]: {...formValues}},
                                            autorizador,
                                            coordinador,
                                            perfilUsuario,
                                            nombreOrganizacion,
                                            section: payload[section]
                                          }))
                                          API.genDocxAnPdf({
                                            solicitudId: instanciaProceso?.solicitud?.id,
                                            document: doc,
                                            formValues,
                                            setFormValues,
                                            subfolder,
                                            filename: filenameDOCX,
                                          })
                                        })
                                      }}
                                      toolTip={'Generar dictamen técnico'}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => {
                                        API.fetchDownloadDOCX({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder,
                                          filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                        })
                                      }}
                                      toolTip={'Descargar informe técnico en formato docx'}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })}
                                      toolTip={'Descargar informe técnico en formato pdf'}
                                      width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={(fn) => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                                if(f.isValid(fn)) fn()
                                              } else {
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                              }
                                            })
                                          }}
                                          toolTip={'Subir informe técnico modificado en formato docx'}
                                          width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <>
                      <MyFileUploadButton label={'PDF (firmado)'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenamePDFsigned}
                        // afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                          afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                          toolTip={'Subir informe técnico firmado en formato pdf'}
                                          width={'9rem'}
                                          disabled={formValues.firmando || formValues.firmado} />
                      <MyButtonBacan3 label={'FIRMAR'}
                                      icon={FileDownloadIcon}
                                      onClick={()=> {
                                        API.firmarPdf(instanciaTarea.id, subfolder, filenamePDF).then((res)=> {
                                          if(res?.length > 0) {
                                            const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                            const firmaTareaId = res[0].firmaTareaId
                                            setFormValues({...formValues, firmando:true, firmado:false, pdfSigned, firmaTareaId})
                                          }
                                        })
                                      }}
                                      disabled={formValues.firmado}
                                      toolTip={'firmar electronicamente el documento'}
                                      width={'9rem'} />
                      {
                        formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                       icon={FileDownloadIcon}
                                                                       onClick={() => API.fetchDownloadPDF({
                                                                         solicitudId: instanciaProceso?.solicitud?.id,
                                                                         subfolder,
                                                                         filename: formValues.pdfSigned,
                                                                       })}
                                                                       toolTip={'Descargar informe técnico firmado en formato pdf'}
                                                                       width={'3rem'} />
                      }
                    </>
                  }
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <WF03SolicitudRO payload={payload}/>
        </TabPanel>
        <TabPanel value="3">
          <WF03Expediente payload={payload} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={() => {
                                const target = 'MAATE'
                                let docs = {...payload.INFO.docs}
                                let IDXloc = docs.d05DictamenTecnico?.findIndex(x => x.idx === target)
                                if(IDXloc >= 0) {
                                  payload.INFO.docs.d05DictamenTecnico?.splice(IDXloc, 1)
                                  docs = {
                                    ...payload.INFO.docs,
                                    'd05DictamenTecnico': payload.INFO.docs.d05DictamenTecnico
                                  }
                                } else {
                                  if(!f.isValid(payload.INFO.docs.d05DictamenTecnico))
                                    docs = {
                                      ...payload.INFO.docs,
                                      'd05DictamenTecnico': []
                                    }
                                }
                                docs = {
                                  ...payload.INFO.docs,
                                  'd05DictamenTecnico': [
                                    ...docs.d05DictamenTecnico,
                                    {
                                      'idx': target,
                                      'archivo': (formValues.pdfSigned !== '') ? formValues.pdfSigned : formValues.pdf,
                                      'solicitudId': payload.INFO.solicitudId,
                                      'desc': 'Dictamen técnico MAATE',
                                      'subFolder': subfolder
                                    }
                                  ]
                                }
                                payload.INFO.docs = docs
                                payload[section]=formValues
                                dispatch(handleSaveSolicitud(instanciaProceso?.id, payload, () => setCounter(0) ))
                              }}
                              disabled={counter <= 0} />
                <MySendButton onSend={() => {
                                const metadata = JSON.stringify({
                                    "solicitudId": `${instanciaProceso?.solicitud?.id}`
                                  }
                                )
                                dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'ENVIAR'}
                              myTip={''}
                              disabled={counter > 0 || formValues.pdfSigned === ''}/>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

const plantilla = ''
  + '${TITULO1}INFORME TÉCNICO'
  + '${SALTO}${CENTRAR}No. <<serial>>'
  + '${SALTO}${VAR}Fecha${:}<<sistema.fecha>>'
  + '${SALTO}'
  + '${SALTO}${TITULO2}1.  ANTECEDENTES'
  + '${SALTO}${TITULO3}1.1  Datos Generales'
  + '${SALTO}${VAR}Solicitud:${:}<<solicitud.identificador>>'
  + '${SALTO}${VAR}Solicitante: ${:}<<solicitante.nombre>>'
  + '${SALTO}${VAR}Proyecto:${:}<<solicitud.nombreProyecto>>'
  + '${SALTO}'
  + '${SALTO}${TITULO3}Código Orgánico del Ambiente:'
  + '${SALTO}${PARRAFO}Art. 74. - Limitaciones en el acceso a los recursos genéticos y sus derivados. La Autoridad Ambiental Nacional podrá limitar el acceso a los recursos genéticos, sus componentes y derivados, de manera total o parcial en los siguientes casos: '
  + '${SALTO}${PARRAFO}1. Cuando exista endemismo, rareza, amenaza de extinción de las especies, subespecies, variedades o razas'
  + '${SALTO}${PARRAFO}2. Cuando existan condiciones de vulnerabilidad o fragilidad en la estructura o función de los ecosistemas que pudieran agravarse por actividades de acceso;'
  + '${SALTO}${PARRAFO}3. Cuando el acceso a dichos recursos cause efectos adversos sobre la salud humana o sobre elementos esenciales de la identidad cultural de las comunas, comunidades, pueblos y nacionalidades;'
  + '${SALTO}${PARRAFO}4. Cuando existan impactos ambientales difícilmente controlables de las actividades de acceso sobre las especies y los ecosistemas;'
  + '${SALTO}${PARRAFO}5. Cuando exista peligro de erosión genética ocasionado por actividades de acceso;'
  + '${SALTO}${PARRAFO}6. Cuando existan regulaciones sobre bioseguridad y biotecnología que así lo determinen;'
  + '${SALTO}${PARRAFO}7. Cuando existan recursos genéticos, sus productos derivados y sintetizados, que haya sido priorizada; y,'
  + '${SALTO}${PARRAFO}8. Otras limitaciones a criterio de la Autoridad Ambiental Nacional'
  + '${SALTO}'
  + '${SALTO}${TITULO3}Reglamento al Código Orgánico del Ambiente:'
  + '${SALTO}${PARRAFO}Art. 7. Biodiversidad como recurso estratégico-..La Autoridad Ambiental Nacional ejercerá la rectoría y gestión del sector estratégico de la biodiversidad, desarrollando el modelo de gestión intersectorial conforme las competencias, facultades y atribuciones establecidas en la normativa vigente.'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Art. 215. Autoridad Ambiental Nacional.- La Autoridad Ambiental Nacional, en materia de recursos genéticos regulará y normará los procedimientos, plazos y requisitos para la conservación y uso sostenible de la biodiversidad y el patrimonio genético del país.'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Art. 216. Lineamientos para la conservación y uso sostenible de los recursos genéticos. La Autoridad Ambiental Nacional, en el marco de sus competencias, emitirá los lineamientos técnicos para la conservación y uso sostenible de los recursos genéticos y biológicos, mediante norma técnica.'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Art. 220. Criterios técnicos y protocolos para la conservación de la biodiversidad.- La Autoridad Ambiental Nacional, emitirá los criterios técnicos y protocolos para la conservación y uso sostenible de la biodiversidad, a ser considerados previo al acceso a recursos genéticos mediante norma secundaria; dicho instrumento regulará las condiciones, alcance y formatos a aplicarse, en concordancia con el Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación.'
  + '${SALTO}${PARRAFO}La autoridad competente para conceder el acceso al recurso genético, solicitará el pronunciamiento de la Autoridad Ambiental Nacional sobre la pertinencia del acceso solicitado. Dicho pronunciamiento deberá considerar al menos los siguientes lineamientos: '
  + '${SALTO}'
  + '${SALTO}${PARRAFO}a) El estado y formas de conservación in situ y ex situ de las especies, subespecies, morphos, variedades o razas respectivamente;'
  + '${SALTO}${PARRAFO}b) Número de especies (endémicas, amenazadas o en peligro de extinción) y patrones de distribución;'
  + '${SALTO}${PARRAFO}c) Condiciones de vulnerabilidad o fragilidad en la estructura o función de los ecosistemas;'
  + '${SALTO}${PARRAFO}d) Diversidad genética de las especies y áreas geográficas calificadas como estratégicas – vulnerables;'
  + '${SALTO}${PARRAFO}e) Reducción/erosión irreversible de la diversidad genética, causada por actividades de acceso;'
  + '${SALTO}${PARRAFO}f) Acciones que alteren la capacidad de los ecosistemas para proporcionar bienes y servicios ambientales;'
  + '${SALTO}${PARRAFO}g) Análisis de riesgo ambiental para la liberación de organismos genéticamente modificados a medios naturales con fines de investigación o comercio; y,'
  + '${SALTO}${PARRAFO}h) En los casos de investigaciones a realizarse en áreas del Sistema Nacional de Áreas Protegidas, se evaluará la pertinencia de la investigación propuesta en función de la zonificación y actividades previstas en el plan de manejo del área protegida'
  + '${SALTO}${PARRAFO}Para la aplicación de lo establecido en el artículo 74 del Código Orgánico del Ambiente, la Autoridad Ambiental Nacional emitirá su pronunciamiento por escrito, evaluando caso por caso, mediante informe técnico de evaluación, que tendrá carácter vinculante.'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Art. 221. Acceso a recursos genéticos o biológicos con fines de investigación dentro de áreas protegidas.- Cuando el acceso a recursos genéticos en general, o el acceso a recursos biológicos con fines de investigación, implique actividades que deban realizarse dentro de zonas que forman parte del Sistema Nacional de Áreas Protegidas, el responsable de área informará al investigador o a la persona que actúa en representación de quien solicitó el acceso, sobre los procedimientos a seguirse, de forma previa el ingreso al área'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Art. 224. Datos técnicos y científicos.- Para la formulación de política pública y toma de decisiones, la Autoridad Ambiental Nacional recopilará y compilará datos técnicos y científicos sobre la biodiversidad y su patrimonio genético. Para el efecto, establecerá lineamientos, estándares y protocolos que permitan la interrelación e intercambio dinámico de datos biológicos, entre los generadores de información biológica'
  + '${SALTO}'
  + '${SALTO}${TITULO2}2.  DESARROLLO'
  + '${SALTO}'
  + '${SALTO}${TITULO3}2.1  Objetivo'
  + '${SALTO}${PARRAFO}Emitir el criterio técnico para la pertinencia del acceso y/o uso de la biodiversidad y/o recursos genéticos para el proyecto/propuesta: <<solicitud.nombreProyecto>>.'
  + '${SALTO}${TITULO3}2.2  Análisis'
  + '${SALTO}${PARRAFO}Se realizó la revisión técnica a la propuesta de investigación en biodiversidad, de acuerdo al siguiente detalle: '
  + '${SALTO}${PARRAFO}Información de la evaluación.'
  // + '${SALTO}${BAREMO}<<baremo>>'
  + '${SALTO}${PARRAFO.VACIO}'
  + '${SALTO}${TITULO3}2.3  Resultado:'
  + '${SALTO}'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>[POSITIVO]'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>Caso aprobado: '
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>La propuesta no se contrapone a los lineamientos y criterios de evaluación del acceso a la biodiversidad y sus recursos genéticos.'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>[NEGATIVO]'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>Caso observado: '
  + '${SALTO}${LISTA.SIMPLE.NUMERADA}<<informeTecnicoMaate.observaciones>>'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Muestras a ser obtenidas de colecta directa en campo: Localidades de recolección:'
  + '${SALTO}'
  + "${SALTO}${PARRAFO}IN SITU"
  + "${SALTO}${RECURSOS.INSITU2}<<recursos.tablaRecursosInSitu>>"
  + "${SALTO}${PARRAFO.VACIO}"
  + "${SALTO}${PARRAFO}EX SITU"
  + "${SALTO}${RECURSOS.EXSITU2}<<recursos.tablaRecursosExSitu>>"
  + "${SALTO}" +
  + '${SALTO}${TITULO2}3.  CONCLUSIONES Y RECOMENDACIONES'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>[POSITIVO]'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>Del análisis realizado se evidenció que la solicitud No. <<solicitud.identificador>> presentada por <<solicitante.nombre>> para desarrollar el proyecto de investigación titulado “<<solicitud.nombreProyecto>>”, es pertinente, viable y factible conforme el criterio de este Ministerio y, que la misma promueve la conservación y uso sostenible de la biodiversidad. Asimismo, se evidenció que la presente solicitud no prevé el uso del recurso con fin comercial en su propuesta y desarrollo.'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>Por lo expuesto, se recomienda la APROBACIÓN de la autorización de investigación solicitada del proyecto <<solicitud.nombreProyecto>> por el plazo de <<solicitud.plazoProyecto>>, siempre y cuando no estén en contradicción con la normativa legal vigente.'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>[NEGATIVO]'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>Del análisis realizado se evidenció que la solicitud No. <<solicitud.identificador>> presentada por <<solicitante.nombre>> para desarrollar el proyecto <<solicitud.nombreProyecto>> por el plazo de <<solicitud.plazoProyecto>> meses, no cumple con los criterios de conservación y uso sostenible de la biodiversidad.'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>Por lo expuesto, se recomienda, NEGAR la autorización de investigación solicitada y solventar la información entregada en una nueva solicitud para el desarrollo del proyecto <<solicitud.nombreProyecto>> en cumplimiento de la normativa legal vigente.'
  + '${SALTO2}${VAR}Fecha:${:} <<sistema.fecha>>'
  + '${SALTO3}'
  + '${SALTO}${CENTRARBOLD}Revisado y aprobado por: '
  + '${SALTO}${CENTRAR}<<sistema.nombreAutorizadorSectorialMaate>>'
  + '${SALTO}${CENTRAR}<<sistema.rolAutorizadorSectorialMaate>>'
  + '${SALTO}${CENTRAR}<<usuario.cargoAutorizadorSectorialMaate>>'
  + '${SALTO3}'
  + '${SALTO}${CENTRARBOLD}Elaborado por: '
  + '${SALTO}${CENTRAR}<<usuario.nombre>>'
  + '${SALTO}${CENTRAR}<<usuario.rol>>'
  + '${SALTO}${CENTRAR}<<usuario.cargo>>'
