
import { withFormik } from "formik";
import React from 'react';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save';
import { validacionBosques } from "./validacionBosques";
import { cerrarModalAreaInvestigacion } from "./sliceAdministrador";
import { guardarAreaInvestigacion } from "./API";

const form = props => {

  const theme = createTheme();

  const botonAzul = {
    mt: 2, ml:2,  maxWidth: 200,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
      backgroundColor: 'rgba(54, 138, 184, 1)',
    }
  }

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  return (
    <div >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0 }}>
              <Grid container spacing={1}>

                <Grid item xs={12}>
                  <TextField
                    id="codigo"
                    name="codigo"
                    label="Código"
                    value={values.codigo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.codigo ? errors.codigo : ""}
                    error={touched.codigo && Boolean(errors.codigo)}
                    autoComplete="off"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                    disabled={values.accion === 1 ? true : false}
                  />
                </Grid>

                <Grid item xs={12}>
                  < TextField
                    id="nombreArea"
                    name="nombre"
                    label="Nombre"
                    value={values.nombre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.nombre ? errors.nombre : ""}
                    error={touched.nombre && Boolean(errors.nombre)}
                    autoComplete="off"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid container spacing={1} justifyContent='right'>
                  <Button type="submit" color='success' variant="contained"
                    sx={botonAzul}
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </Button>
                  <Button color='success' variant="contained"
                    sx={botonAzul}
                    startIcon={<HighlightOffIcon />}
                    onClick={props.cerrarDialogo}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div >
  );
};

const AreaInvestigacionForm = withFormik({

  enableReinitialize: true,

  mapPropsToValues: (props) => ({
    accion: props.area ? 1 : 0,
    id: props.area ? props.area[0]?.id : null,
    nombre: props.area ? props.area[0]?.nombreArea : '',
    codigo: props.area ? props.area[0]?.id : '',
  }),

  validationSchema: validacionBosques,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.registroAreaInvestigacion(values)
    setSubmitting(false);
  }
})(form);

const mapStateToProps = (
  state
) => ({
  area: state.administracion.seleccionadaAreaInvestigacion,
})

const mapDispatchToProps = (dispatch) => ({
  registroAreaInvestigacion: (values) => {
    dispatch(guardarAreaInvestigacion(values))
  },
  cerrarDialogo: (values) => {
    dispatch(cerrarModalAreaInvestigacion());
  },
})

export const FormAreaInvestigacion = connect(mapStateToProps, mapDispatchToProps)(AreaInvestigacionForm)