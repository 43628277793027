import React, {useEffect, useState} from 'react'
import { useSelector} from 'react-redux'
import {
  Box,
  Grid, Stack,
} from '@mui/material'
import {dialog} from 'src/styles/styles'
import {rulesFor} from 'src/features/P01Solicitud'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyMultipleSelect} from 'src/components/MyMultipleSelect'
import {MyCentroDocumentacion} from "../../../components/MyCentroDocumentacion";
import AddIcon from "@mui/icons-material/Add";
import {MyButtonBacan2} from "../../../components/MyButtonBacan2";

const Recursos = ({payload,mainFormValues,incrementCounter, idTask}) => {
  const section = 'RecursosExSitu'
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  if(!!!payload[section]) {payload[section] = {}}
  const laboratorios = useSelector(state => state.app.laboratorios)

  const bandeja = useSelector(state => state.app.bandeja)
  const canEdit = bandeja === 'entrada'
  const RULES = rulesFor(section)
  const emptyPayload = RULES.emptyPayload()
  const handlesChange= RULES.handlesChange()
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  useEffect(() => {
    mainFormValues[section] = formValues
    incrementCounter()
  },[formValues])

  const origLabs = payload.solicitudOriginal?.payloadSolicitud?.RecursosExSitu?.laboratorios ?? []
  useEffect(() => {
    if(origLabs.length > 0) {
      const labs = [...formValues.laboratorios]
      origLabs.forEach(it => {
        if(!labs.includes(it)) {
          labs.push(it)
          setFormValues({...formValues, laboratorios: labs})
        }
      })
    }
  }, [formValues.laboratorios])

  useEffect(() => {
    if (idTask === '55000016_Activity_ElaborarSolicitudWF0405') {
      console.log(':: fv :: ', payload?.solicitudOriginal?.payloadSolicitud)
    }
  }, [])

  return(
    <Box >
      <Grid container spacing={1}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Centros de documentación'} />
        </Grid>
        {
          formValues.centrosDocumentacion?.map(cd => {
            const oldCD = payload.solicitudOriginal?.payloadSolicitud?.RecursosExSitu?.centrosDocumentacion?.filter(it => it.id === cd.id)?.at(0)
            return (
              <Grid key={cd.id} item xs={12}>
                <MyCentroDocumentacion id={cd.id}
                                       key={cd.id}
                                       canEdit={canEdit}
                                       formValues={formValues}
                                       numeroSolicitud={instanciaProceso?.solicitud?.id}
                                       oldCD={oldCD}
                                       setFormValues={(id, centro) => {
                                         const idx = formValues.centrosDocumentacion.findIndex(it => it.id === id)
                                         formValues.centrosDocumentacion[idx] = {...centro}
                                         setFormValues({...formValues })
                                       }}
                                       mode={'solicitud'}
                                       handleDelete={(id)=> {
                                         setFormValues({...formValues, centrosDocumentacion: formValues.centrosDocumentacion.filter(it => it.id !== id)})
                                       }} />
              </Grid>
            )
          })
        }
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent='flex-end' alignItems='center' spacing={2} sx={{p:'0 1rem 0 0 '}}>
            <MyButtonBacan2 onClick={() => {
                              const noCentros = formValues.centrosDocumentacion.length === 0
                              const myMax = noCentros?1:Math.max(...formValues.centrosDocumentacion.map(it => it.id))
                              const cds = [...formValues.centrosDocumentacion, {id:myMax+1}]
                              setFormValues({...formValues, centrosDocumentacion: cds})
                            }}
                            icon={AddIcon}
                            label={'Agregar Centro de Documentación'} />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Destino de los recursos'} />
        </Grid>
        <Grid item xs={12} >
          <MyMultipleSelect id={'laboratorios'}
                            label={'Institución en la que se desarrollará la fase de laboratorio *'}
                            data={[...laboratorios].sort()}
                            setFormValues={setFormValues}
                            formValues={formValues}
                            canEdit={canEdit}
                            handleChange={handlesChange['areasProtegidas']} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Recursos
