import React, { useRef, useState } from 'react'
import { Button, Chip, Grid, Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch, useSelector } from 'react-redux';
import { abrirModalContratos } from './sliceAdministrador';
import { guardarArchivoContrato } from './API';
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';

const botonAzul = {
    mt: 1, ml: 2, maxWidth: 200,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
        backgroundColor: 'rgba(54, 138, 184, 1)',
    }
}

export const FormContratos = () => {

    const formData = new FormData()
    const inputRef = useRef()
    const dispatch = useDispatch()
    const [nombreImagen, setNombreImagen] = useState('')

    const handleSubmit = () => {
        formData.append('archivo', inputRef.current.files[0])
        dispatch(guardarArchivoContrato(formData))
    }

    const handleCerrar = () => {
        dispatch(abrirModalContratos(false));
    }

    return (
        <div>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: 250,
                    }}
                >
                    <Box component="form" sx={{ mt: 0, width: 300 }}>
                        <Grid container spacing={1}>

                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Typography variant="body2" gutterBottom color="text.secondary">
                                    Prepare el archivo CSV en el formato y estructura
                                    requeridos para proceder.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Typography variant="body1" gutterBottom >
                                    ¿Desea actualizar el catálogo de contratos y registros
                                    de conocimiento libre previo e informado (CLPI)?
                                </Typography>
                            </Grid>

                            <Stack direction="row" justifyContent="center">
                                <Typography variant="body2">
                                    <br />
                                    {nombreImagen !== '' && <Chip label={"Archivo a ser cargado: " + nombreImagen} />}
                                    <br />
                                </Typography>
                            </Stack>

                            <Stack direction="row" justifyContent='center' sx={{ mt: 2 }}>

                                <Button color='success' variant="contained"
                                    sx={botonAzul}
                                    startIcon={<HighlightOffIcon />}
                                    onClick={handleCerrar}
                                >
                                    {'Cancelar'}
                                </Button>

                                <Button variant="contained" sx={botonAzul} startIcon={<SearchIcon />} component='label'>
                                    Archivo
                                    <input
                                        ref={inputRef}
                                        accept={".csv"}
                                        onChange={() => {
                                            setNombreImagen(inputRef.current.files[0].name);
                                        }}
                                        hidden
                                        type='file' />
                                </Button>

                                <Button onClick={handleSubmit} color='success' disabled={nombreImagen !== '' ? false : true} variant="contained"
                                    sx={botonAzul}
                                    startIcon={<UploadIcon />}
                                >
                                    {'Subir'}
                                </Button>



                            </Stack>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}