import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import P0102API from './API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {
  handCompletarTareaCoordinador,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {MyGobackButton, MySaveButton} from 'src/components/MyCommonButtons'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyReadOnlyTextField} from 'src/components/MyReadOnlyTextField'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {P0102PayloadRO} from 'src/features/P01Solicitud/P0102PayloadRO'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import {PageOrientation} from "docx";
import GLOBALS, {ENV} from 'src/features/App/globals'
import {MyButtonAlert} from "../../components/MyButtonAlert"
import {P0102Expediente} from "./P0102Expediente";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({id}) => {
  // console.log(':: id :: ', id)
  const dispatch = useDispatch()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const section = `DictamenTecnico${perfilUsuario?.perfil?.organizacion?.nombreOrganizacion}`
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const esContrato = instanciaTarea?.tipoInstanciaProceso === 'Contrato'
  const solicitud = {...instanciaProceso.solicitud}
  let payload = JSON.parse(!!instanciaProceso?.solicitud?.payload?instanciaProceso?.solicitud?.payload:'{}')
  payload.solicitudId = solicitud.id
  const funcionarios = useSelector(state => state.app.funcionarios)
  const labelTab2 = ['55000002_Activity_VerificarCumplimientoRequisitos'].includes(id)?'Solicitud':'Solicitud Modificatoria'
  const labelTab3 = ['55000002_Activity_VerificarCumplimientoRequisitos'].includes(id)?'Expediente':'Expediente Modificatoria'
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:         today,
    identificador: solicitud.numeroSolicitud,
    docxGenerado:  false,
    serial:        '',
    docx:          '',
    pdf:           '',
    docxLded:      '',
    pdfLded:       '',
  }
  let myPayload = {
    "55000023_Activity_ElaborarInformeSenadiWF15": payload?.Solicitud?.target?.payload,
  }[instanciaTarea?.tareaCodigoTarea]
  if(!f.isValid(myPayload)) {
    myPayload = payload
  } else {
    myPayload = JSON.parse(myPayload)
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const informeTecnico = !esContrato?'56015020-1':'56015020-2'
  const codigoAutorizador = 1148
  const codigoCoordinador = 1148
  const autorizador = funcionarios.filter(it => it.idPerfil === codigoAutorizador)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === codigoCoordinador)[0]

  function getOnSave(setCounter, fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    };
  }

  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    const toBPM = {
      '55000015_Activity_ElaborarInformeTecnicoSenadiWF0102': {
        "criterioTecnicoSenadi": false,
        "informeCompletoSenadi": true,
        "ampliacionInformacionSenadi": false
      },
      '55000017_Activity_ElaborarInformeTecnicoSenadiWF0405': {
        "criterioTecnicoSenadi": false,
        "informeCompletoSenadi": true,
        "ampliacionInformacionSenadi": false,
        "legitimoPoseedorSenadi": false,
      }
    }[instanciaTarea?.tareaCodigoTarea]
    const [counter, setCounter] = useState(-1)
    useEffect(() => { setCounter(counter + 1) }, [formValues])
    const [myTab, setMytab] = useState('1');
    const handleChangeTab = (event, newTab) => { setMytab(newTab) }
    const subfolder = 'dictamen-tecnico-Senadi'
    const filename = `dictamen-tecnico-${formValues.identificador}-Senadi`
    const filenameDOCX = `${filename}.docx`
    const filenameDOCXuploaded = `${filename}-upld.docx`
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Elaboración de dictamen técnico'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label={labelTab2} value="2" />
              <Tab label={labelTab3} value="3" />
              {
                f.isValid(payload?.solicitudOriginal) ?
                  <Tab label={`Solicitud original`} value="4"/> : false
              }
              {
                f.isValid(payload?.solicitudOriginal) ?
                  <Tab label={`Expediente original`} value="5"/> : false
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={accordeonBox.container2}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Solicitud solicitud={solicitud}/>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Informe'} />
              </Grid>
              {f.isValid(payload.T55000015_Activity_AsignarCasoSenadiWF0102?.observaciones) && payload.T55000015_Activity_AsignarCasoSenadiWF0102?.observaciones !== '' &&
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}
                         style={{padding: '1rem 24px 1rem 2rem'}}>
                    <MyButtonAlert label={'Directriz del caso'}
                                   color0={'#888888'}
                                   color1={'#888888'}
                                   messageTitle='Directriz del caso'
                                   messageContent={payload.T55000015_Activity_AsignarCasoSenadiWF0102?.observaciones}/>
                  </Stack>
                </Grid>
              }
              <Grid item xs={4} sx={{pl:'4rem'}}>
                <Box sx={{pl:'1.8rem'}}>
                  <MyReadOnlyTextField id={'fecha'}
                                       label={'Fecha'}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />}
                                       value={formValues.fecha} />
                </Box>
              </Grid>
              <Grid item xs={8} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = 'DTSEN'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave(setCounter,{...formValues, serial:serial})()
                                            setFormValues({...formValues, serial:serial})
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' && ENV === 'PRUEBAS' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar Informe'}
                                      width={'11rem'}
                                      onClick={() => {
                                        API.fetchPlantila(informeTecnico).then(taggeDoc => {
                                          const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, nombreOrganizacion, P0102API.mapping({
                                            solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                            payload,
                                            autorizador,
                                            coordinador,
                                            perfilUsuario,
                                            nombreOrganizacion,
                                            section: payload[section]
                                          }))
                                          API.genDocxAnPdf({
                                            solicitudId: instanciaProceso?.solicitud?.id,
                                            document: doc,
                                            formValues,
                                            setFormValues,
                                            subfolder,
                                            filename: filenameDOCX,
                                            nombreOrganizacion,
                                          })
                                        })
                                      }}
                                      toolTip={'Generar informe técnico'}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                      })}
                                      toolTip={'Descargar informe técnico en formato docx'}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })}
                                      toolTip={'Descargar informe técnico en formato pdf'}
                                      width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={() => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                              } else {
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                              }
                                            })
                                          }}
                                          toolTip={'Subir informe técnico modificado en formato docx'}
                                          width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <>
                      {/*<MyFileUploadButton label={'PDF FIRMADO'}*/}
                      {/*                    solicitudId={instanciaProceso?.solicitud?.id}*/}
                      {/*                    subfolder={subfolder}*/}
                      {/*                    fileName={filenamePDFsigned}*/}
                      {/*                    afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}*/}
                      {/*                    width={'9rem'} />*/}
                      {/*<MyFileUploadButton label={'FIRMAR'}*/}
                      {/*                    solicitudId={instanciaProceso?.solicitud?.id}*/}
                      {/*                    subfolder={subfolder}*/}
                      {/*                    fileName={filenamePDFsigned}*/}
                      {/*                    disabled={true}*/}
                      {/*                    afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}*/}
                      {/*                    width={'9rem'} />*/}
                      {/*{*/}
                      {/*  formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}*/}
                      {/*                                                 icon={FileDownloadIcon}*/}
                      {/*                                                 onClick={() => API.fetchDownloadPDF({*/}
                      {/*                                                   solicitudId: instanciaProceso?.solicitud?.id,*/}
                      {/*                                                   subfolder,*/}
                      {/*                                                   filename: filenamePDFsigned,*/}
                      {/*                                                 })}*/}
                      {/*                                                 width={'3rem'} />*/}
                      {/*}*/}
                    </>
                  }
                </Stack>
              </Grid>

            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={myPayload} />
          </TabPanel>
          <TabPanel value="3">
            <P0102Expediente payload={payload}/>
          </TabPanel>
          {(f.isValid(payload?.solicitudOriginal)) ?
            <TabPanel value="4">
              <P0102PayloadRO payload={payload?.solicitudOriginal?.payloadSolicitud}/>
            </TabPanel> : false
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="5">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : false
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction={'row'}
                       spacing={1}
                       justifyContent="space-between"
                       alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyGobackButton onGoback={() => dispatch(handleClear())} />
                  <MySaveButton onSave={getOnSave(setCounter)}
                                disabled={counter <= 0 } />
                  <MySendButton onSend={() => {
                                  const metadata = JSON.stringify({
                                    "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                    ...toBPM
                                  })
                                  dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                label={'enviar'}
                                myTip={'Enviar Informe Técnico y continuar con proceso'}
                                disabled={counter > 0 || formValues.pdf === ''} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  } else {
    return null
  }
}
