import { withFormik } from "formik";
import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save';
import { validacionBosques } from "./validacionBosques";
import { SelectProvincia } from "./SelectProvincia";
import { guardarArea, guardarAreaNueva } from "./API";
import { cerrarModalBosques } from "./sliceAdministrador";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const form = props => {

  const theme = createTheme();

  const botonAzul = {
    mt: 1, ml: 2, maxWidth: 200,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
      backgroundColor: 'rgba(54, 138, 184, 1)',
    }
  }

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  return (
    <div >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0 }}>
              <Grid container spacing={1}>

                <Grid item xs={12}>
                  <SelectProvincia name='provincia' value={values.provincia} accion={values.accion} />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="codigo"
                    name="codigo"
                    label="Código"
                    value={values.codigo}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.codigo ? errors.codigo : ""}
                    error={touched.codigo && Boolean(errors.codigo)}
                    autoComplete="off"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                    disabled={values.accion === 1 ? true : false}
                  />
                </Grid>


                <Grid item xs={12}>
                  <TextField
                    id="nombre"
                    name="nombre"
                    label="Nombre"
                    value={values.nombre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.nombre ? errors.nombre : ""}
                    error={touched.nombre && Boolean(errors.nombre)}
                    autoComplete="off"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid container spacing={1} justifyContent='right'>
                  <Button type="submit" color='success' variant="contained"
                    sx={botonAzul}
                    startIcon={<SaveIcon />}
                  >
                    {'Guardar'}
                  </Button>
                  <Button color='success' variant="contained"
                    sx={botonAzul}
                    startIcon={<HighlightOffIcon />}
                    onClick={props.cerrarDialogoBosque}
                  >
                    {'Cerrar'}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div >
  );
};

const AreasForm = withFormik({

  enableReinitialize: true,

  mapPropsToValues: (props) => ({
    accion: props.editar ? 1 : 0,
    id: props.editar ? (props.area ? props.area[0].id : '') : '',
    nombre: props.editar ? (props.area ? props.area[0].nombreAreaProtegida : '') : '',
    provincia: props.editar ? (props.area ? props.area[0].provincia.id : 350101) : 350101,
    nombreProvincia: props.editar ? (props.area ? props.area[0].provincia.nombreProvincia : '') : '',
    codigo: props.editar ? (props.area ? props.area[0].id : '') : '',
    tipo: 2
  }),

  validationSchema: validacionBosques,

  handleSubmit: (values, { props, setSubmitting }) => {
    props.registroArea(values)
    setSubmitting(false);
  }
})(form);

const mapStateToProps = (
  state
) => ({
  area: state.administracion.seleccionadoArea,
  editar: state.administracion.editando
})

const mapDispatchToProps = (dispatch) => ({
  registroArea: (values) => {
    if (values.accion === 1) {
      dispatch(guardarArea(values))
    } else {
      dispatch(guardarAreaNueva(values))
    }
  },
  cerrarDialogoBosque: () => {
    dispatch(cerrarModalBosques());
  }
})

export const FormAreas = connect(mapStateToProps, mapDispatchToProps)(AreasForm)