import React, {useEffect, useRef, useState} from 'react'
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useDispatch, useSelector} from 'react-redux'
import {
  AppBar,
  Box, ButtonGroup,
  CssBaseline,
  Grid,
  Stack, Tab,
  Typography,
} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, common, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import API from 'src/features/App/API'
import Toolbar from '@mui/material/Toolbar'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Autorizacion from "src/features/P01Solicitud/subcomponents/Autorizacion";
import {MyTextField} from "../../components/MyTextField";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import {MySwitch} from "../../components/MySwitch";
import {MyUpload} from "../../components/MyUpload";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {MyReadOnlyAreaTextField} from "../../components/MyReadOnlyAreaTextField";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import {P10ATMRO} from "../P10ATM/P10ATMRO";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import GLOBALS from "../App/globals";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import {PageOrientation} from "docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";

export default ({
                  instanciaTarea,
                  perfilUsuario,
                  instanciaProceso,
                  solicitud,
                  solicitudesAprobadas,
                  canEdit=true,
                  // atms=[],
                }) => {
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const ATM = slct.payload.Solicitud.solicitudAprobada.father.payloadSolicitud
  const mp = slct.payload.Solicitud.solicitudAprobada
  const dispatch = useDispatch()
  const section = 'Validar'
  const payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:            today,
    cumpleRequisitos: false,
    certificado: 'Se certifica que el documento que respalda el permiso de importación se encuentra registrado en la base de datos de la Ventanilla Única Virtual para la Investigación de la Biodiversidad',
    // seAutoriza:       false,
    serial:      '',
    docx:        '',
    pdf:         '',
    pdfSigned:   '',
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  useEffect(() => {
    if(formValues.cumpleRequisitos) {
      setFormValues({
        ...formValues,
        asunto:  'Certificado de importación AUTORIZADO',
        detalle: `Estimado/a ${mp.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la verificación de ` +
          `requisitos de importación con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} HA SIDO AUTORIZADA.\n\n` // +
          // 'Saludos cordiales,\n\n' +
          // `${perfilUsuario.usuario.nombreUsuario}`
      })
    } else {
      setFormValues({
        ...formValues,
        asunto:  'Certificado de importación NO AUTORIZADO',
        detalle: `Estimado/a ${mp.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la verificación de ` +
          `requisitos de importación con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} NO HA SIDO AUTORIZADA.\n\n` // +
          // 'Saludos cordiales,\n\n' +
          // `${perfilUsuario.usuario.nombreUsuario}`
      })
    }
  }, [formValues.cumpleRequisitos])
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  const [recursoSelected, setRecursoSelected] = useState({})
  const handleChange = (e) => API.handleChange2(e, canEdit, setFormValues, formValues)
  const [myTab, setMytab] = useState('1')
  const abuelo = JSON.parse(JSON.parse(mp.father.aprobada.payloadSolicitud).Solicitud.solicitudAprobada)

  const inputRef = useRef()
  const subfolder = 'aprobacion'
  const filename = `aprobacion-${payload.solicitudId}`
  const filenameDOCX = `${filename}.docx`
  const filenamePDFsigned = `${filename}-firmado.pdf`
  const funcionarios = useSelector(state => state.app.funcionarios)
  const aprueba = funcionarios.filter(it => it.idPerfil === 1141)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === 164)[0]

  // 55000008_Activity_AprobarImportacion
  if(f.isValid(solicitudesAprobadas)) {
    return (
      <Box sx={accordeonBox.container}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin:0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Emitir certificado de importación'} />
        </AppBar>
        <TabContext value={myTab} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
            <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="ATM" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Identificador'}
                                     icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={solicitud.numeroSolicitud} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Fecha'}
                                     icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={slct.payload.Solicitud['fecha']} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Permiso'} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent='space-between' alignItems='center' >
                  <MyReadOnlyTextField id={'numeroSolicitud'}
                                       label={'Número de permiso'}
                                       value={slct.payload.Solicitud.documentoIdentificador}
                                       icon={<LocalShippingIcon  sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
                  <MyReadOnlyTextField id={'fecha'}
                                       label={'Fecha'}
                                       value={slct.payload.Solicitud.documentoFecha}
                                       icon={<CalendarMonthIcon  sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <MyReadOnlyTextField id={'documento'}
                                     label={'Objeto'}
                                     value={slct.payload.Solicitud.documentoObjetivo}
                                     icon={<CalendarMonthIcon  sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
              </Grid>
              <Grid item xs={12}>
                <MyUpload id={'permisoImportacion'}
                          label={'Permiso de importación: *'}
                          dir={instanciaProceso?.solicitud?.id}
                          canEdit={false}
                          formValues={slct.payload.Solicitud} />
              </Grid>
              <Solicitante solicitud={{solicitud: {payload: mp}}} displayContact={false} />
              {/*<Autorizacion solicitud={{numeroSolicitud: abuelo?.InformeTecnicoSenescyt?.identificador, payload: abuelo}} />*/}
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Ubicación'}/>
              </Grid>
              <Grid item xs={6}>
                <MyReadOnlyTextField id={'ubicacionPais'}
                                     label={'País'}
                                     value={slct.payload.Solicitud.origenPais} />
              </Grid>
              <Grid item xs={3}>
                <MyReadOnlyTextField id={'ubicacionInstitucion'}
                                     label={'Institución'}
                                     value={slct.payload.Solicitud.origenInstitucion} />
              </Grid>
              <Grid item xs={3}>
                <MyReadOnlyTextField id={'ubicacionOrigen'}
                                     label={'Autorización'}
                                     value={slct.payload.Solicitud.origenAutorizacion} />
              </Grid>
              <Grid item xs={12}>
                <MyUpload id={'permisoImportacion'}
                          label={'Permiso de importación:'}
                          dir={instanciaProceso?.solicitud?.id}
                          canEdit={false}
                          formValues={slct.payload.Solicitud} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Recursos'}/>
              </Grid>
              <Grid item xs={12}>
                <MyTableRecursos2 id={'recursos'}
                                  canEdit={false}
                                  formValues={slct.payload.Solicitud}
                                  mode={'ATM-ES2'} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Requisitos'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito01'}
                          label={'¿La importación ha sido debidamente autorizada por la entidad competente, conforme la normativa ecuatoriana vigente.?'}
                          formValues={payload.Validar}
                          setFormValues={setFormValues}
                          handleChange={handleChange}
                          canEdit={false}/>
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito02'}
                          label={'¿La importación se enmarca en un permiso de investigación/contrato autorizado previamente por la Autoridad competente.?'}
                          formValues={payload.Validar}
                          handleChange={handleChange}
                          canEdit={false} />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito03'}
                          label={'¿La importación se encuentra respaldada en un Acuerdo de Transferencia de Material previamente validado por la SENESCYT?'}
                          formValues={payload.Validar}
                          handleChange={handleChange}
                          canEdit={false} />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 0.6rem'}}>
                <MySwitch id={'requisito04'}
                          label={'¿La importación prevé el reporte de los resultados alcanzados.?'}
                          formValues={payload.Validar}
                          handleChange={handleChange}
                          canEdit={false} />
              </Grid>
              <Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 2rem'}}>
                <Stack direction={'row'} spacing={1} justifyContent="flex-end" alignItems='center' >
                  <MySwitch id={'cumpleRequisitos'}
                            label={'¿La solicitud cumple con los Requisitos?'}
                            fullWidth={false}
                            formValues={payload.Validar}
                            handleChange={handleChange}
                            canEdit={false} />
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Certificado'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 0 0 0.6rem'}}>
                <MyAreaTextField id={'certificado'}
                                 label={'Contenido *'}
                                 formValues={formValues}
                                 handleChange={handleChange}
                                 canEdit={true} />
              </Grid>
              {/*<Grid item xs={12} style={{padding:'0.5rem 0.6rem 0.5rem 2rem'}}>*/}
              {/*  <Stack direction={'row'} spacing={1} justifyContent="flex-end" alignItems='center' >*/}
              {/*    <MySwitch id={'seAutoriza'}*/}
              {/*              label={'¿Se autoriza?'}*/}
              {/*              fullWidth={false}*/}
              {/*              formValues={formValues}*/}
              {/*              handleChange={handleChange}*/}
              {/*              canEdit={canEdit} />*/}
              {/*  </Stack>*/}
              {/*</Grid>*/}
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                  {formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = 'PIMP'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            setFormValues({...formValues, serial:serial})
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          pdfSigned: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar CERTIFICADO'}
                                      width={'11rem'}
                                      onClick={() => {
                                        const taggeDoc = {contenidoPlantilla:plantilla}
                                        const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, 'Senescyt', mapping(formValues, payload, slct, aprueba, coordinador, perfilUsuario), PageOrientation.LANDSCAPE, instanciaProceso?.solicitud?.nombreProyecto)
                                        API.genDocxAnPdf({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          document: doc,
                                          formValues,
                                          setFormValues,
                                          subfolder,
                                          filename: filenameDOCX,
                                        })
                                      }}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: formValues.docx,
                                      })}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: formValues.pdf
                                      })}
                                      width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' && formValues.serial !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyFileUploadButton inputRef={inputRef}
                                            label={'PDF (firmado)'}
                                            solicitudId={instanciaProceso?.solicitud?.id}
                                            subfolder={subfolder}
                                            fileName={filenamePDFsigned}
                                            afterUpload={() => setFormValues({
                                              ...formValues,
                                              pdfSigned: filenamePDFsigned
                                            })}
                                            toolTip={'Subir pdf firmado'}
                                            width={'9rem'}/>
                        <MyFileUploadButton inputRef={inputRef}
                                            label={'Firmar PDF'}
                                            solicitudId={instanciaProceso?.solicitud?.id}
                                            subfolder={subfolder}
                                            disabled={true}
                                            fileName={filenamePDFsigned}
                                            afterUpload={() => setFormValues({
                                              ...formValues,
                                              pdfSigned: filenamePDFsigned
                                            })}
                                            toolTip={'Firmar el documento electrónicamete'}
                                            width={'9rem'}/>
                        {
                          formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                         icon={FileDownloadIcon}
                                                                         onClick={() => API.fetchDownloadPDF({
                                                                           solicitudId: instanciaProceso?.solicitud?.id,
                                                                           subfolder,
                                                                           filename: filenamePDFsigned,
                                                                         })}
                                                                         toolTip={'Descargar el documento firmado'}
                                                                         width={'3rem'}/>
                        }
                      </ButtonGroup>
                  }
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Notificación'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 0 0 0.6rem'}}>
                <MyTextField id={'asunto'}
                             label={'Asunto'}
                             formValues={payload.Validar}
                             handleChange={handleChange}
                             canEdit={false} />
              </Grid>
              {
                formValues.asunto === ''?
                  <Typography sx={common.warnig}>Asunto es obligatorio</Typography>
                  :null
              }
              <Grid item xs={12} style={{padding:'0 0 0 0.6rem'}}>
                <MyAreaTextField id={'detalle'}
                                 label={'Contenido'}
                                 formValues={payload.Validar}
                                 handleChange={handleChange}
                                 canEdit={false} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P10ATMRO payload={ATM} />
          </TabPanel>
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  {
                    (canEdit)?
                      <>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => {
                                         if(counter <= 2) {
                                           dispatch(handleClear())
                                         } else {
                                           alert('Debe GUARDAR los cambios realizados')
                                         }
                                       }} />
                        <MyButtonBacan label={'Guardar'}
                                       onClick={() => {
                                         const newPayload= {...payload ,[section]: {...formValues}}
                                         dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
                                         setCounter(0)
                                       }}
                                       disabled={ counter <= 0 }
                                       icon={SaveOutlinedIcon} />
                        <MySendButton disabled={ counter > 0 || formValues.asunto === '' || formValues.detalle === '' }
                                      label={'enviar'}
                                      onSend={() => {
                                        const metadata = (!formValues.cumpleRequisitos)?JSON.stringify({
                                          "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                          "importacionAprobada": false,
                                          "asunto": formValues.asunto,
                                          "perfilUsuarioSolicitud": instanciaProceso?.perfilUsuario?.id,
                                          "detalle": formValues.detalle,
                                        }):JSON.stringify({
                                          "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                          "importacionAprobada": true,
                                          "asunto": formValues.asunto,
                                          "detalle": formValues.detalle,
                                        })
                                        dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                      }} />
                      </>
                      :
                      <Grid item xs={4} style={{padding:'0 24px 0 0'}}>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => dispatch(handleClear())} />
                      </Grid>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
  else return null
}

const plantilla = '' +
  '${TITULO1}CERTIFICADO DE PERMISO DE IMPORTACIÓN' +
  '${SALTO}${TITULO1}<<serial>>' +
  '${SALTO}' +
  '${SALTO}${QR}SOLICITANTE:<<solicitante.nombre>>,CEDULA:<<solicitante.identificador>>,SERIAL:<<serial>>,ITEMS:<<items>>' +
  '${SALTO}${PARRAFO}<<certificado>>' +
  '${SALTO}' +
  '${SALTO}{PARRAFO}Se certifica que el documento que respalda el permiso de exportación se encuentra registrado en la base de datos de la Ventanilla Única Virtual para la Investigación de la Biodiversidad.' +
  '${SALTO}${TITULO2}Permiso de exportación' +
  '${SALTO}${VAR}Número de permiso${:}<<permisoExportacion.numero>>' +
  '${SALTO}${VAR}Fecha de emisión${:}<<permisoExportacion.fecha>>' +
  '${SALTO}${VAR}Objetivo${:}<<permisoExportacion.objeto>>' +
  '${SALTO}' +
  '${SALTO}${TITULO2}Solicitante' +
  '${SALTO}${VAR}Identificación${:}<<solicitante.identificador>>' +
  '${SALTO}${VAR}Nombre${:}<<solicitante.nombre>>' +
  '${SALTO}' +
  '${SALTO}${TITULO2}Autorización' +
  '${SALTO}${VAR}Identificador${:}<<autorizacion.identificador>>' +
  '${SALTO}${VAR}Proyecto${:}<<proyecto.nombre>>' +
  '${SALTO}${VAR}Vigencia${:}<<autorizacion.vigencia>>' +
  '${SALTO}${VAR}Institución Nacional de Apoyo${:}<<solicitud.institucionNacionalApoyo>>' +
  '${SALTO3}' +
  '${SALTO}${VAR}Fecha de emisión${:}<<sistema.fecha>>' +
  '${SALTO3}' +
  '${SALTO}${FIRMAN}<<firman>>'

const mapping = (fv, py, slct, aprueba, coordinador, perfilUsuario) => {
  const items = slct.payload.Solicitud.recursos?.map(it => JSON.stringify({nombreCientífico: it.scientificname, cantidadAutorizada: it.cantidadAutorizada}))?.join(',')
  return {
    '<<serial>>' :                             fv.serial,
    '<<items>>' :                              items,
    '<<permisoExportacion.numero>>' :          slct.numeroSolicitud,
    '<<autorizacion.identificador>>' :         py.Solicitud.aprobadaIdentificador,
    '<<permisoExportacion.fecha>>' :           py.Solicitud.fecha,
    '<<permisoExportacion.objeto>>' :          py.Solicitud.documentoObjetivo,
    '<<proyecto.nombre>>' :                    slct.nombreProyecto,
    '<<solicitud.institucionNacionalApoyo>>' : slct.payload.Solicitud.solicitudAprobada.Propuesta.apoyo,
    '<<solicitante.identificador>>' :          slct.payload.Solicitud.solicitudAprobada.Solicitante.cedula,
    '<<solicitante.nombre>>' :                 slct.payload.Solicitud.solicitudAprobada.Solicitante.nombresCompletos,
    '<<autorizacion.vigencia>>' :              `${slct.payload.Solicitud.solicitudAprobada.Propuesta.plazo} meses`,
    '<<sistema.fecha>>' :                      format(new Date(), 'dd-MMMM-yyyy', {locale: es}),
    '<<firman>>': JSON.stringify([
      {accion: 'Aprobado por',nombre:perfilUsuario?.usuario?.nombreUsuario ?? '', cargo:'Director/a de investigación cientídica'},
      {accion: 'Revisado por', nombre:coordinador?.nombreUsuario ?? '', cargo:'Analista de investigación científica/3'},
      {accion: 'Elaborado por', nombre:aprueba?.nombreUsuario  ?? '', cargo:'Analista de investigación científica/2'},
    ]),
    '<<certificado>>' :                        fv.certificado,
  }
}
