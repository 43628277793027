import React, {useEffect, useState} from 'react'
import {Box, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import {
  handleSaveSolicitud,
  handleClear,
  handCompletarTareaCoordinador,
} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MySubtitle} from "../../components/MySubtitle";
import {MyTextField} from "../../components/MyTextField";
import {Email} from "@mui/icons-material";
import API from 'src/features/App/API'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import {MyTable} from "../../components/MyTable";
import {MySwitch} from "../../components/MySwitch";
import {MySendButton} from "../../components/MySendButton";
import GLOBALS from "../App/globals";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {f} from "../../commons";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "../P01Solicitud/P0102PayloadRO";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch()
  const section = 'Novedades'
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:                  today,
    asunto:                 '',
    detalle:                '',
    novedades:              [],
    incumplimientosGraves:  false,
  }
  const canEdit = instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, 'entrada', setFormValues, formValues)
  const [myTab, setMytab] = useState('1')
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  // console.log('::: PYLD : ', payload?.Solicitud.aprobadaIdentificador)
  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Registro de novedades'} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
          <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{p:'0 1rem 0 2rem'}}>
            {(payload.InformeSeguimiento.informe !== '') ?
              <>
                <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
                  <MySubtitle subtitle={'Reporte de cumplimiento'} />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'1rem 1rem 1rem 0'}}>
                    <MyReadOnlyTextField id={'solicitudNumero'}
                                         value={solicitud?.numeroSolicitud}
                                         label={'Identificador'}
                                         icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                    <MyReadOnlyTextField id={'fecha'}
                                         value={payload.InformeSeguimiento?.fecha}
                                         label={'Fecha'}
                                         icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                    {f.isValidNotEmpty(payload.InformeSeguimiento?.informe) ?
                      <MyButtonBacan label={'Reporte'}
                                     onClick={() => {
                                       const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/informe/${payload.InformeSeguimiento.informe}`
                                       fetch(url)
                                         .then((res) => {
                                           return res.blob();
                                         })
                                         .then((data) => {
                                           const dataPdf = new Blob([data], {type: 'application/pdf'})
                                           const a = document.createElement("a")
                                           a.href = window.URL.createObjectURL(dataPdf)
                                           a.target = "_blank"
                                           a.click()
                                         })
                                     }}
                                     icon={FileDownloadOutlinedIcon}/> : null
                    }
                    {
                      (payload.InformeSeguimiento.anexo !== '') ?
                        <MyButtonBacan label={'Anexo'}
                                       onClick={() => {
                                         const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/anexo/${payload.InformeSeguimiento.anexo}`
                                         fetch(url)
                                           .then((res) => {
                                             return res.blob();
                                           })
                                           .then((data) => {
                                             const dataPdf = new Blob([data], {type: 'application/pdf'})
                                             const a = document.createElement("a")
                                             a.href = window.URL.createObjectURL(dataPdf)
                                             a.target = "_blank"
                                             a.click()
                                           })
                                       }}
                                       icon={FileDownloadOutlinedIcon}/> : null
                    }
                  </Stack>
                </Grid>
              </> : null
            }
            <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
              <MySubtitle subtitle={'Novedades'} />
            </Grid>
            <Grid item xs={12}>
              <MyTable id={'novedades'}
                       formValues={formValues}
                       setFormValues={setFormValues}
                       columnName={'Novedades *'}
                       canEdit={canEdit}
                       addItem={() => {
                         if(formValues['novedades'].filter(it => it === '').length === 0) {
                           const field = 'novedades'
                           const newSet = [ ...formValues[field], ...['']]
                           const newFormValues = {...formValues, [field]:newSet}
                           setFormValues(newFormValues)
                         }
                       }} />
            </Grid>
            <Grid item xs={12} style={{padding:'0.5rem 2rem 0.5rem 2rem'}}>
              <MySwitch id={'incumplimientosGraves'}
                        label={'¿Existen incumplimientos graves?'}
                        fullWidth={false}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        handleChange={handleChange}
                        canEdit={true}/>
            </Grid>
            <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
              <MySubtitle subtitle={'Notificación'} />
            </Grid>
            <Grid item xs={12} style={{padding:'0'}}>
              <MyTextField id={'asunto'}
                           label={'Asunto *'}
                           formValues={formValues}
                           handleChange={handleChange}
                           rows={20}
                           canEdit={true}/>
            </Grid>
            <Grid item xs={12} >
              <MyAreaTextField id={'detalle'}
                               label={'Contenido *'}
                               formValues={formValues}
                               setFormValues={setFormValues}
                               handleChange={canEdit?handleChange:null} />
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <P0102PayloadRO payload={payload.Solicitud.solicitudAprobada.payloadSolicitud} />
        </TabPanel>
      </TabContext>

      <CssBaseline />
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'0 4rem 0 4rem'}}>
                <MyButtonBacan label={'Regresar'}
                               myTip={'Regresar a las tareas'}
                               icon={ArrowBackIcon}
                               onClick={() => { dispatch(handleClear()) }} />
                <MyButtonBacan label={'Guardar'}
                               myTip={'Guarda el formulario, y permite continuar editando'}
                               onClick={() => {
                                 payload[section]=formValues
                                 setCounter(0)
                                 dispatch(handleSaveSolicitud(instanciaProceso?.id,payload))
                               }}
                               disabled={!canEdit || counter <= 0}
                               icon={SaveOutlinedIcon} />
                <MySendButton onSend={() => {
                  const metadata = JSON.stringify({
                                  "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                  "asunto":      formValues.asunto,
                                  "detalle":     formValues.detalle,
                                  "incumplimientosGraves": formValues.incumplimientosGraves,
                                })
                                // console.log('Pyld :: ', payload['Solicitud']['solicitudAprobada']['idSolicitud'], payload['Solicitud'])
                                API.fetchSuspenderSolicitud(payload['Solicitud']['solicitudAprobada']['idSolicitud'], true).then(() => {
                                  dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                })
                              }}
                              label={'Enviar'}
                              disabled={!canEdit || counter > 0 || formValues.asunto === '' || formValues.detalle === ''} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}
