import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {  retornaAreas } from './API';
import { quitarAreaInvestigacion, seleccionarAreaInvestigacion, limpiarLineas } from './sliceAdministrador';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import { Button, ButtonGroup } from '@mui/material';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useNavigate } from 'react-router';
import { ToolBarAreas } from './ToolBarAreas';
import { ModalAreaInvestigacion } from './ModalAreaInvestigacion';

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    esES,
);

const columns = [
    {
        field: 'id',
        headerName: 'Código',
        width: 150,
        editable: false,
    },
    {
        field: 'nombreArea',
        headerName: 'Nombre',
        width: 400,
        editable: false,
    },
];

const textoEsp = {
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Seleccionar columnas',
    columnsPanelTextFieldLabel: 'Buscar columna',
    columnsPanelTextFieldPlaceholder: 'Columna...',
    columnsPanelDragIconLabel: 'Reordenar',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',
    toolbarFilters: 'Filtros',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelOperators: 'Operador',
    filterPanelInputPlaceholder: 'Buscar...',
    toolbarDensity: 'Tamaño',
    toolbarDensityLabel: 'Tamaño',
    toolbarDensityCompact: 'Pequeño',
    toolbarDensityStandard: 'Mediano',
    toolbarDensityComfortable: 'Largo',
    toolbarExport: 'Imprimir',
    toolbarExportLabel: 'Imprimir',
    toolbarExportCSV: 'Descargar como CSV',
    toolbarExportPrint: 'Imprimir como PDF',
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'igual',
    filterOperatorStartsWith: 'empieza con',
    filterOperatorEndsWith: 'termina con',
    filterOperatorIs: 'es',
    filterOperatorNot: 'no es',
    filterOperatorIsEmpty: 'ninguno',
    filterOperatorIsNotEmpty: 'todos',
    filterOperatorIsAnyOf: 'cualquiera de '
}

const botonAzul = {
    borderRadius: '0.2rem',
    textTransform: 'none',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
        backgroundColor: 'rgba(54, 138, 184, 1)',
    }
}



function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

export const TablaAreas = () => {

    const dispatch = useDispatch();
    const [pageSize, setPageSize] = React.useState(5);
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(retornaAreas());
        dispatch(quitarAreaInvestigacion());
        dispatch(limpiarLineas());
    }, [dispatch])

    const { areas, seleccionadaAreaInvestigacion } = useSelector(state => state.administracion)
    const [selectionModel, setSelectionModel] = React.useState([]);

    React.useEffect(() => {
        if (!seleccionadaAreaInvestigacion) {
            setSelectionModel([]);
        }
    }, [seleccionadaAreaInvestigacion])

    const handleArea = (seleccionados) => {
        if (seleccionados.length === 1) {
            dispatch(seleccionarAreaInvestigacion(seleccionados[0]))
        } else {
            dispatch(quitarAreaInvestigacion());
        }
    }

    const handleRegresar = () => {
        navigate('/administracion');
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Box sx={{
                    height: 406, width: '100%',
                }}>

                    <ToolBarAreas numSelected={0} />

                    <DataGrid
                        rows={areas}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10]}
                        checkboxSelection
                        onSelectionModelChange={(newSelectionModel) => {
                            if (newSelectionModel.length > 1) {
                                const selectionSet = new Set(selectionModel);
                                const result = newSelectionModel.filter((s) => !selectionSet.has(s));
                                setSelectionModel(result);
                                handleArea(result);
                            } else {
                                setSelectionModel(newSelectionModel);
                                handleArea(newSelectionModel);
                            }
                        }}
                        selectionModel={selectionModel}
                        disableSelectionOnClick
                        disableColumnMenu
                        experimentalFeatures={{ newEditingApi: true }}
                        localeText={textoEsp}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                </Box>
            </ThemeProvider>
            <ModalAreaInvestigacion />

            <ButtonGroup variant="contained" sx={{ ml: 5, mt: 12 }} aria-label="outlined primary button group">
                <Button variant="contained" onClick={handleRegresar} startIcon={<ArrowCircleLeftOutlinedIcon />}
                    sx={botonAzul}
                >
                    Regresar
                </Button>
            </ButtonGroup>
        </>
    );
}