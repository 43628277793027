import React, {useEffect, useState} from 'react'
import {Box} from '@mui/system'
import {f} from 'src/commons'
import API from 'src/features/App/API'
import {Grid} from '@mui/material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
// import {MySwitch} from 'src/components/MySwitch'
// import {MySelect} from 'src/components/MySelect'
// import {paises} from 'src/features/P01Solicitud/CONF'
import {Email, LocationOn, PhoneInTalk, Smartphone} from '@mui/icons-material'
import {MyTextField} from 'src/components/MyTextField'
import {MyButtonBacan3} from 'src/components/MyButtonBacan3'
import SearchIcon from '@mui/icons-material/Search'
import {MyMaskedTextField} from 'src/components/MyMaskedTextField'
import {MyUpload} from "../../../components/MyUpload";
import Typography from "@mui/material/Typography";

export const DeApoyo = ({section, formValues, setFormValues, canEdit, formErrors}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})
  const sectionErrors = (sectionValues.deRuc === '') ? 'RUC NO VÁLIDO;' : '' ||
    (sectionValues.deRazonSocial === '') ? 'RAZON SOCIAL NO VÁLIDO;' : '' ||
    (sectionValues.deDomicilioLegal === '') ? 'DOMICILIO LEGAL NO VÁLIDO;' : '' ||
    (sectionValues.deRepresentanteLegal === '') ? 'REPRESENTANTE LEGAL NO VÁLIDO' : '' ||
    (sectionValues.deTelefonoTrabajo === '') ? 'TELEFONO NO VÁLIDO' : '' ||
    (sectionValues.deCelular === '') ? 'CELULAR NO VÁLIDO' : '' ||
    (sectionValues.deCorreoElectronico=== '') ? 'CORREO ELECTRÓNICO NO VÁLIDO' : '' ||
    (sectionValues.deApoyoLegal=== '') ? 'APOYO LEGAL NO VÁLIDO' : ''
  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  const handleChangePhone = (e) => {
    if(e.target.value === '' || f.isPhone(e.target.value))
      API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  }
  useEffect(() => {
    if(canEdit && f.isValid(setFormValues)) {
      setFormValues({
          ...formValues,
          [section]: {...sectionValues},
        },
      )}
  }, [sectionValues])
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Institución Nacional de Apoyo'} />
      </Grid>
      <Grid item xs={3}>
        <MyTextField id={'deRuc'}
                     label={'RUC *'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color: 'silver'}}/>}
                     error={f.isValid(sectionErrors) && sectionErrors['deRuc']}
                     canEdit={canEdit}
                     handleChange={handleChange}/>
      </Grid>
      <Grid item xs={2} >
        <Box sx={{p:'1.9rem 0 0 0', width:'100%'}} display={'flex'} justifyContent={'center'}>
          <MyButtonBacan3 label={'BUSCAR RUC'}
                          onClick={() => {
                            API.buscarRuc(sectionValues.deRuc).then((res) =>{
                              if(f.isValid(res.id)) {
                                setSectionValues({
                                  ...sectionValues,
                                  deRuc:                    res.id,
                                  deRazonSocial:            res.razonSocial ?? '',
                                  deDomicilioLegal:         res.direccionRuc ?? '',
                                  deRepresentanteLegal:     res.representanteLegal ?? '',
                                  deTelefonoTrabajo:        res.telefonoRuc ?? '',
                                  deCelular:                res.celularRepresentante ?? '',
                                  deCorreoElectronico:      res.correoRuc ?? '',
                                  rucInfo:res,
                                })
                              }
                            })
                          }}
                          icon={SearchIcon} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'deRazonSocial'}
                     label={'Razón Social'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={f.isValid(sectionErrors) && sectionErrors['deRazonSocial']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'deDomicilioLegal'}
                     label={'Domicilio legal'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={f.isValid(sectionErrors) && sectionErrors['deDomicilioLegal']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'deRepresentanteLegal'}
                     label={'Representante legal'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={f.isValid(sectionErrors) && sectionErrors['deRepresentanteLegal']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'deTelefonoTrabajo'}
                           label={'Teléfono del trabajo'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>}
                           error={f.isValid(sectionErrors) && sectionErrors['deTelefonoTrabajo']}
                           canEdit={canEdit}
                           type={'CellPhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'deCelular'}
                           label={'Celular'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>}
                           error={f.isValid(sectionErrors) && sectionErrors['deCelular']}
                           canEdit={canEdit}
                           type={'HomePhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyTextField id={'deCorreoElectronico'}
                     label={'Correo Electrónico'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>}
                     error={f.isValid(sectionErrors) && sectionErrors['deCorreoElectronico']}
                     canEdit={canEdit}
                     handleChange={handleChange}/>
      </Grid>
      <Grid item xs={12}>
        <MyUpload id={'deApoyoLegal'}
                  label={'Nombramiento Representante Legal'}
                  dir={formValues.INFO.solicitudId}
                  setFormValues={setSectionValues}
                  formValues={sectionValues}
                  canEdit={canEdit} />
      </Grid>
      <Grid item xs={12}>
        {
          (sectionErrors !== '') ? <Typography sx={{color:'red'}}>{sectionErrors}</Typography> : false
        }
      </Grid>
    </Grid>
  )
}
