import React, {useState} from 'react'
import IconButton from '@mui/material/Button'
import RobotoCondensedLight from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Light.ttf'
import Typography from '@mui/material/Typography'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack} from '@mui/material'
import Box from '@mui/material/Box'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'

export const MyYesNoAlert = ({
                                label='no label ...',
                                labelPos='right',
                                icon=NotificationsOutlinedIcon,
                                messageTitle='title ...',
                                messageContent='message here ...',
                                messageYesButton='OK',
                                messageNoButton='CANCEL',
                                bgColor0='transparent',
                                onYes=()=> alert('YES'),
                                color0='rgba(0, 0, 120, 1)',
                                color1='rgba(0, 0, 0, 0.8)',
                                disabled=false
                              }) => {
  const height= '1.8rem'
  const MyIcon=icon
  const [showAlert, setShowAlert] = useState(false)

  const ThisLabel = () => <Box sx={{pt:'0.4rem'}}>
                            <Typography sx={{
                              fontFamily: RobotoCondensedLight,
                              fontSize: '0.9rem',
                              fontWeight: 'bolder',
                              color: color1,
                            }}>{label}</Typography>
                          </Box>

  const ThisButton = () => <IconButton variant="contained"
                                       disabled={disabled}
                                       size={'small'}
                                       sx={{
                                         borderRadius: '1rem',
                                         p:0,
                                         minWidth: '1rem',
                                         backgroundColor: bgColor0,
                                         width: '2rem',
                                         height,
                                         "&:hover":{
                                           backgroundColor: bgColor0,
                                         },
                                         "&:disabled":{
                                           backgroundColor: '#AAAAAA',
                                           color: '#444444'
                                         },
                                       }}
                                       onClick={() => setShowAlert(true)} >
                             <MyIcon sx={{height:'1rem', fill: color0, borderRadius:'6px',}}/>
                           </IconButton>
  return (
    <Stack spacing={1} direction={'row'} sx={{widtg:'8rem', p:0}}>
      {labelPos === 'right' ?
        <>
          <ThisButton />
          <ThisLabel />
        </>:
        <>
          <ThisLabel />
          <ThisButton />
        </>
      }

      <Dialog open={showAlert}
              onClose={() => setShowAlert(false)}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'>
        <DialogTitle id="alert-dialog-title">
          {messageTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {messageContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
                    onYes()
                    setShowAlert(false)
                  }}>{messageYesButton}
          </Button>
          <Button onClick={() => setShowAlert(false)}>
            {messageNoButton}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}
