import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Select, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
// import {MyTextField} from "src/components/MyTextField";
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {MySwitch} from "../../components/MySwitch";
import {MySubtitle} from "../../components/MySubtitle";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {WF03SolicitudRO} from "./P03SolicitudRO";
import {format} from "date-fns";
import {es} from "date-fns/locale";
// import {MyDatePicker} from "../../components/MyDatePicker";
// import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MenuItem from "@mui/material/MenuItem";
import {MyTableRecursos} from "../../components/MyTableRecursos";
import {MyTableMuestras} from "../../components/MyTableMuestras";
import {muestras} from "../P01Solicitud/CONF";
import {MyCentroDocumentacion} from "../../components/MyCentroDocumentacion";
import {MyTable} from "../../components/MyTable";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GLOBALS from "../App/globals";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {WF03Expediente} from "./subcomponents/P03Expediente";

const range = (start, end) => {
  let ans = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
}
const range20 = range(1,20).map(it => <MenuItem key={it} value={it}>{it}</MenuItem>)
const range5 = range(1,5).map(it => <MenuItem key={it} value={it}>{it}</MenuItem>)

// eslint-disable-next-line import/no-anonymous-default-export
export const P03InformeTecnicoMAATE = ({id, instanciaTarea, instanciaProceso, solicitud, perfilUsuario}) => {
  function getOnSave(setCounter, fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:{...formValues}
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    };
  }
  const inputRef = useRef()
  const section = 'P03InformeTecnicoMAATE'
  const dispatch = useDispatch()
  const bandeja = useSelector(state => state.app.bandeja)
  const [resultado, setResultado] = useState(0)
  const [ponderacion, setPonderacion] = useState(0)
  let payload = !!solicitud.payload?JSON.parse(solicitud.payload):{[section]:{}}
  payload.solicitudId = solicitud.id
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    identificador:        solicitud.numeroSolicitud,
    fecha:                today,
    resolucionDeInicio :  '',
    criterioTecnicoMaate: false,
    informeCompletoMaate: true,
    informacionTerritorioMaate: false,
    amplicacionInformacionMaate: false,
    // informeCompleto: false,
    congruencia:     {ponderacion:1, calificacion:1},
    experiencia:     {ponderacion:1, calificacion:1},
    informacion:     {ponderacion:1, calificacion:1},
    resultados:      {ponderacion:1, calificacion:1},
    campo:           {ponderacion:1, calificacion:1},
    laboratorio:     {ponderacion:1, calificacion:1},
    recursosInSitu:  payload?.RecursosInSitu?.recursos,
    muestras:              payload?.RecursosInSitu?.muestras,
    centrosDocumentacion:  payload?.RecursosExSitu?.centrosDocumentacion,
    observaciones:   [],
    seAutoriza:      false,
    serial:          '',
    docx:            '',
    pdf:             '',
    docxLded:        '',
    pdfLded:         '',
    pdfSigned:       '',
  }

  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada' && instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [counter, setCounter] = useState(-1)  // updating counter
  const funcionarios = useSelector(state => state.app.funcionarios)

  useEffect(() => { setCounter(counter + 1) }, [formValues])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }

  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `informe-tecnico-${nombreOrganizacion}`
  const filename = `informe-tecnico-${formValues.identificador}-${nombreOrganizacion}`
  const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  const filenamePDFuploaded = `${filename}-upld.pdf`
  const filenamePDFsigned = `${filename}-firmado.pdf`

  useEffect(() => {
    setCounter(counter + 1)
    const mResultado = formValues.laboratorio.ponderacion * formValues.laboratorio.calificacion / 5 +
      formValues.campo.ponderacion * formValues.campo.calificacion / 5 +
      formValues.resultados.ponderacion * formValues.resultados.calificacion / 5 +
      formValues.informacion.ponderacion * formValues.informacion.calificacion / 5 +
      formValues.experiencia.ponderacion * formValues.experiencia.calificacion / 5 +
      formValues.congruencia.ponderacion * formValues.congruencia.calificacion / 5
    setResultado(mResultado.toFixed(1))
    const mPonderacion = formValues.laboratorio.ponderacion +
      formValues.campo.ponderacion +
      formValues.resultados.ponderacion +
      formValues.informacion.ponderacion +
      formValues.experiencia.ponderacion +
      formValues.congruencia.ponderacion
    setPonderacion(mPonderacion)
  }, [formValues])
  useEffect(() => {
    setFormValues({...formValues, seAutoriza: resultado >= 80 && resultado <= 100})
  }, [resultado])
  const [recursoSelected, setRecursoSelected] = useState({})
  useEffect(() => {
    setFormValues({...formValues, seAutoriza: resultado >= 80 && resultado <= 100})
  }, [resultado])
  const esContrato = false
  const informeTecnico = !esContrato?'56015008-1':'56015008-2'
  const autorizador = funcionarios.filter(it => it.idPerfil === 1143)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === 1144)[0]

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Informe técnico MAATE'}/>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
            <Tab label="Expediente" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Calificación de la propuesta'} />
            </Grid>
            <Grid item xs={12} >
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={'55%'}>Evaluación Técnica</TableCell>
                      <TableCell align="right" width={'15%'}>Ponderación</TableCell>
                      <TableCell align="right" width={'15%'}>Calificación</TableCell>
                      <TableCell align="right" width={'15%'}>Valor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={'1'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Congruencia de la información presentada (título, antecedentes, justificación, objetivos)'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="congruencia.ponderacion"
                                value={formValues.congruencia.ponderacion}
                                onChange={(e) => {
                                  formValues.congruencia.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="congruencia.calificacion"
                                value={formValues.congruencia.calificacion}
                                onChange={(e) => {
                                  formValues.congruencia.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.congruencia.ponderacion*formValues.congruencia.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'2'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Experiencia relacionada del equipo técnico'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="experiencia.ponderacion"
                                value={formValues.experiencia.ponderacion}
                                onChange={(e) => {
                                  formValues.experiencia.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="experiencia.calificacion"
                                value={formValues.experiencia.calificacion}
                                onChange={(e) => {
                                  formValues.experiencia.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.experiencia.ponderacion*formValues.experiencia.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'3'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Información detallada de los especímenes a ser investigados'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="informacion.ponderacion"
                                value={formValues.informacion.ponderacion}
                                onChange={(e) => {
                                  formValues.informacion.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="informacion.calificacion"
                                value={formValues.informacion.calificacion}
                                onChange={(e) => {
                                  formValues.informacion.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.informacion.ponderacion*formValues.informacion.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'4'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Resultados esperados concuerdan con los objetivos propuestos'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="resultados.ponderacion"
                                value={formValues.resultados.ponderacion}
                                onChange={(e) => {
                                  formValues.resultados.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="resultados.calificacion"
                                value={formValues.resultados?.calificacion}
                                onChange={(e) => {
                                  formValues.resultados.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.resultados.ponderacion*formValues.resultados.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'5'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Metodología detallada para recolección y preservación de especímenes en campo'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="campo.ponderacion"
                                value={formValues.campo?.ponderacion}
                                onChange={(e) => {
                                  formValues.campo.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="campo.calificacion"
                                value={formValues.campo?.calificacion}
                                onChange={(e) => {
                                  formValues.campo.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.campo.ponderacion*formValues.campo.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'6'} sx={{height:'3rem'}}>
                      <TableCell component="th" scope="row">
                        {'Metodología detallada para trabajo en laboratorio'}
                      </TableCell>
                      <TableCell align="right">
                        <Select id="laboratorio.ponderacion"
                                value={formValues.laboratorio.ponderacion}
                                onChange={(e) => {
                                  formValues.laboratorio.ponderacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range20}</Select>
                      </TableCell>
                      <TableCell align="right">
                        <Select id="laboratorio.calificacion"
                                value={formValues.laboratorio.calificacion}
                                onChange={(e) => {
                                  formValues.laboratorio.calificacion = e.target.value
                                  setFormValues({...formValues})
                                }}>{range5}</Select>
                      </TableCell>
                      <TableCell align="right">
                        {(formValues.laboratorio.ponderacion*formValues.laboratorio.calificacion/5).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow key={'7'} sx={{height:'3rem'}}>
                      <TableCell />
                      <TableCell />
                      <TableCell >Resultado</TableCell>
                      <TableCell align="right" >{resultado}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {
              (resultado > 100)?
              <Grid item xs={12}>
                <Typography>{`Error: El resultado ${resultado} es ser superior a 100 `}</Typography>
              </Grid>:false
            }
            {
              (ponderacion !== 100)?
              <Grid item xs={12}>
                <Typography>La suma de ponderaciones debe ser igual a 100</Typography>
              </Grid>:false
            }
            {f.isValid(payload?.RecursosInSitu?.recursos?.length) ?
              <>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Cantidades Autorizadas - Recursos IN-SITU'} />
                </Grid>
                <Grid item xs={12}>
                  <MyTableRecursos id={'recursosInSitu'}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   muestras={formValues.muestras}
                                   columnName={'Recursos'}
                                   canEdit={false}
                                   setRecursoSelected={setRecursoSelected}
                                   canDeleteRow={false}
                                   mode={'in-situ'}/>
                </Grid>
                {
                  f.isValid(recursoSelected.scientificname) &&
                  <>
                    <Grid item xs={12} sx={dialog.titleContainer}>
                      <MySubtitle subtitle={'Muestras y submuestras'} />
                    </Grid>
                    <Grid item xs={12} >
                      {recursoSelected.scientificname}
                    </Grid>
                    <Grid item xs={12} >
                      <MyTableMuestras id={'muestras'}
                                       selected={recursoSelected}
                                       formValues={formValues}
                                       setFormValues={setFormValues}
                                       canEdit={true}
                                       mode={'cantidades'}
                                       muestras={muestras} />
                    </Grid>
                  </>
                }
              </>:false
            }
            {f.isValid(payload?.RecursosExSitu?.centrosDocumentacion?.length)?
              <>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Cantidades Autorizadas - Recursos EX-SITU'} />
                </Grid>
                {
                  formValues.centrosDocumentacion.map(cd => {
                    return (
                      <Grid key={cd.id} item xs={12}>
                        <MyCentroDocumentacion id={cd.id}
                                               key={cd.id}
                                               canEdit={false}
                                               mode={'autorizar'}
                                               numeroSolicitud={solicitud?.id}
                                               formValues={formValues}
                                               setFormValues={(id, centro) => {
                                                 const idx = formValues.centrosDocumentacion.findIndex(it => it.id === id)
                                                 formValues.centrosDocumentacion[idx] = {...centro}
                                                 setFormValues({...formValues })
                                               }}
                                               verificarQTYAutorizada={true} />
                      </Grid>
                    )
                  })
                }
              </>: false
            }

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Observaciones'}/>
            </Grid>
            <Grid item xs={12}>
              <MyTable id={'observaciones'}
                       formValues={formValues}
                       setFormValues={setFormValues}
                       columnName={'observacion'}
                       canEdit={true}
                       addItem={() => {
                         if (formValues['observaciones'].filter(it => it === '').length === 0) {
                           const field = 'observaciones'
                           const newSet = [...formValues[field], ...['']]
                           const newFormValues = {...formValues, [field]: newSet}
                           setFormValues(newFormValues)
                         }
                       }} />
            </Grid>
            {(resultado < 80) && (formValues.observaciones?.filter(it => f.isValidNotEmpty(it))?.length === 0) ?
              <Typography sx={{pl:'1rem'}}>Debe registrar al menos una observación.</Typography>:null
            }
            <Grid item xs={12}>
              <Box sx={{p:'2rem 0 2rem 2rem'}}>
                <MySwitch id={'seAutoriza'}
                          canEdit={false}
                          label={'¿Se aprueba el Informe Técnico y/o el uso de los recursos solicitados para las cantidades autorizadas?'}
                          formValues={formValues}
                          fullWidth={false}
                          handleChange={(e) => {
                            setFormValues({...formValues, seAutoriza: e.target.checked})
                          }} />
              </Box>
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Informe'} />
            </Grid>
            <Grid item xs={6}>
              <MyReadOnlyTextField id={'fecha'} label={'Fecha'} icon={<CalendarMonthIcon  sx={dialog.readOnlyIcon} />} value={formValues.fecha} />
            </Grid>
            <Grid container xs={6}>
              {/*<Grid item xs={12}>*/}
              {/*  <MySwitch id={'criterioTecnicoMaate'}*/}
              {/*            label={'¿Criterio técnico?'}*/}
              {/*            formValues={formValues}*/}
              {/*            setFormValues={setFormValues}*/}
              {/*            fullWidth={false}*/}
              {/*            canEdit={canEdit}*/}
              {/*            handleChange={handleChange} />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <MySwitch id={'informacionTerritorioMaate'}*/}
              {/*            label={'¿Información territorio?'}*/}
              {/*            formValues={formValues}*/}
              {/*            setFormValues={setFormValues}*/}
              {/*            fullWidth={false}*/}
              {/*            canEdit={canEdit}*/}
              {/*            handleChange={handleChange} />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12}>*/}
              {/*  <MySwitch id={'amplicacionInformacionMaate'}*/}
              {/*            label={'¿Ampliación información?'}*/}
              {/*            formValues={formValues}*/}
              {/*            setFormValues={setFormValues}*/}
              {/*            fullWidth={false}*/}
              {/*            canEdit={canEdit}*/}
              {/*            handleChange={handleChange} />*/}
              {/*</Grid>*/}
              <Grid item xs={12}>
                <MySwitch id={'informeCompletoMaate'}
                          label={'¿Informe completo?'}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          fullWidth={false}
                          canEdit={canEdit}
                          handleChange={handleChange} />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{padding:'0'}}>
              <Stack direction={'row'} justifyContent="space-between" justifyContent="flex-end" spacing={2}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = (!esContrato)?'ITMAT':'ITMATCPC'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave(setCounter,{...formValues, serial})()
                                            setFormValues({...formValues, serial:serial})
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== ''  &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',

                                          // firma -- \\
                                          pdfSigned: '',
                                          firmando: false,
                                          firmado: false,
                                          // firma -- //
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar Informe'}
                                      width={'11rem'}
                                      onClick={() => {
                                        API.fetchPlantila(informeTecnico).then(taggeDoc => {
                                          const doc = MyTagged2Docx(plantilla, nombreOrganizacion, P0102API.mapping({
                                            solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                            payload: {...payload, [section]: {...formValues}},
                                            autorizador,
                                            coordinador,
                                            perfilUsuario,
                                            nombreOrganizacion,
                                            section: payload[section]
                                          }))
                                          API.genDocxAnPdf({
                                            solicitudId: instanciaProceso?.solicitud?.id,
                                            document: doc,
                                            formValues,
                                            setFormValues,
                                            subfolder,
                                            filename: filenameDOCX,
                                          })
                                        })
                                      }}
                                      toolTip={'Generar informe técnico'}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => {
                                        API.fetchDownloadDOCX({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder,
                                          filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                        })
                                      }}
                                      toolTip={'Descargar informe técnico en formato docx'}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })}
                                      toolTip={'Descargar informe técnico en formato pdf'}
                                      width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={(fn) => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                                if(f.isValid(fn)) fn()
                                              } else {
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                              }
                                            })
                                          }}
                                          toolTip={'Subir informe técnico modificado en formato docx'}
                                          width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <>
                      <MyFileUploadButton label={'PDF (firmado)'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenamePDFsigned}
                        // afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                          afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                          toolTip={'Subir informe técnico firmado en formato pdf'}
                                          width={'9rem'}
                                          disabled={formValues.firmando || formValues.firmado} />
                      <MyButtonBacan3 label={'FIRMAR'}
                                      icon={FileDownloadIcon}
                                      onClick={()=> {
                                        API.firmarPdf(instanciaTarea.id, subfolder, filenamePDF).then((res)=> {
                                          if(res?.length > 0) {
                                            const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                            const firmaTareaId = res[0].firmaTareaId
                                            setFormValues({...formValues, firmando:true, firmado:false, pdfSigned, firmaTareaId})
                                          }
                                        })
                                      }}
                                      disabled={formValues.firmado}
                                      toolTip={'firmar electronicamente el documento'}
                                      width={'9rem'} />
                      {
                        formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                       icon={FileDownloadIcon}
                                                                       onClick={() => API.fetchDownloadPDF({
                                                                         solicitudId: instanciaProceso?.solicitud?.id,
                                                                         subfolder,
                                                                         filename: formValues.pdfSigned,
                                                                       })}
                                                                       toolTip={'Descargar informe técnico firmado en formato pdf'}
                                                                       width={'3rem'} />
                      }
                    </>
                  }
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <WF03SolicitudRO payload={payload}/>
        </TabPanel>
        <TabPanel value="3">
          <WF03Expediente payload={payload} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={() => {
                                  const target = 'MAATE'
                                  let docs = {...payload.INFO.docs}
                                  let IDXloc = docs.d04InformeTecnico?.findIndex(x => x.idx === target)
                                  if(IDXloc >= 0) {
                                    payload.INFO.docs.d04InformeTecnico?.splice(IDXloc, 1)
                                    docs = {
                                      ...payload.INFO.docs,
                                      'd04InformeTecnico': payload.INFO.docs.d04InformeTecnico
                                    }
                                  } else {
                                    if(!f.isValid(payload.INFO.docs.d04InformeTecnico))
                                      docs = {
                                        ...payload.INFO.docs,
                                        'd04InformeTecnico': []
                                      }
                                  }
                                  docs = {
                                    ...payload.INFO.docs,
                                    'd04InformeTecnico': [
                                      ...docs.d04InformeTecnico,
                                      {
                                        'idx': target,
                                        'archivo': (formValues.pdfSigned !== '') ? formValues.pdfSigned : formValues.pdf,
                                        'solicitudId': payload.INFO.solicitudId,
                                        'desc': 'Informe técnico MAATE',
                                        'subFolder': subfolder
                                      }
                                    ]
                                  }
                                  payload.INFO.docs = docs
                                  payload[section]=formValues
                                  dispatch(handleSaveSolicitud(instanciaProceso?.id, payload, () => setCounter(0) ))
                              }}
                              disabled={counter <= 0} />
                <MySendButton onSend={() => {
                                const metadata = JSON.stringify({
                                    "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    "criterioTecnicoMaate": false,
                                    "informeCompletoMaate": formValues.informeCompletoMaate,
                                    "ampliacionInformacionMaate": false,
                                  }
                                )
                                dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'ENVIAR'}
                              myTip={''}
                              disabled={counter > 0 || formValues.pdfSigned === ''}/>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}


const plantilla = ''
  + '${TITULO1}INFORME TÉCNICO'
  + '${SALTO}${CENTRAR}No. <<serial>>'
  + '${SALTO}${VAR}Fecha${:}<<sistema.fecha>>'
  + '${SALTO}'
  + '${SALTO}${TITULO2}1.  ANTECEDENTES'
  + '${SALTO}${TITULO3}1.1  Datos Generales'
  + '${SALTO}${VAR}Solicitud:${:}<<solicitud.identificador>>'
  + '${SALTO}${VAR}Solicitante: ${:}<<solicitante.nombre>>'
  + '${SALTO}${VAR}Proyecto:${:}<<solicitud.nombreProyecto>>'
  + '${SALTO}'
  + '${SALTO}${TITULO3}Código Orgánico del Ambiente:'
  + '${SALTO}${PARRAFO}Art. 74. - Limitaciones en el acceso a los recursos genéticos y sus derivados. La Autoridad Ambiental Nacional podrá limitar el acceso a los recursos genéticos, sus componentes y derivados, de manera total o parcial en los siguientes casos: '
  + '${SALTO}${PARRAFO}1. Cuando exista endemismo, rareza, amenaza de extinción de las especies, subespecies, variedades o razas'
  + '${SALTO}${PARRAFO}2. Cuando existan condiciones de vulnerabilidad o fragilidad en la estructura o función de los ecosistemas que pudieran agravarse por actividades de acceso;'
  + '${SALTO}${PARRAFO}3. Cuando el acceso a dichos recursos cause efectos adversos sobre la salud humana o sobre elementos esenciales de la identidad cultural de las comunas, comunidades, pueblos y nacionalidades;'
  + '${SALTO}${PARRAFO}4. Cuando existan impactos ambientales difícilmente controlables de las actividades de acceso sobre las especies y los ecosistemas;'
  + '${SALTO}${PARRAFO}5. Cuando exista peligro de erosión genética ocasionado por actividades de acceso;'
  + '${SALTO}${PARRAFO}6. Cuando existan regulaciones sobre bioseguridad y biotecnología que así lo determinen;'
  + '${SALTO}${PARRAFO}7. Cuando existan recursos genéticos, sus productos derivados y sintetizados, que haya sido priorizada; y,'
  + '${SALTO}${PARRAFO}8. Otras limitaciones a criterio de la Autoridad Ambiental Nacional'
  + '${SALTO}'
  + '${SALTO}${TITULO3}Reglamento al Código Orgánico del Ambiente:'
  + '${SALTO}${PARRAFO}Art. 7. Biodiversidad como recurso estratégico-..La Autoridad Ambiental Nacional ejercerá la rectoría y gestión del sector estratégico de la biodiversidad, desarrollando el modelo de gestión intersectorial conforme las competencias, facultades y atribuciones establecidas en la normativa vigente.'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Art. 215. Autoridad Ambiental Nacional.- La Autoridad Ambiental Nacional, en materia de recursos genéticos regulará y normará los procedimientos, plazos y requisitos para la conservación y uso sostenible de la biodiversidad y el patrimonio genético del país.'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Art. 216. Lineamientos para la conservación y uso sostenible de los recursos genéticos. La Autoridad Ambiental Nacional, en el marco de sus competencias, emitirá los lineamientos técnicos para la conservación y uso sostenible de los recursos genéticos y biológicos, mediante norma técnica.'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Art. 220. Criterios técnicos y protocolos para la conservación de la biodiversidad.- La Autoridad Ambiental Nacional, emitirá los criterios técnicos y protocolos para la conservación y uso sostenible de la biodiversidad, a ser considerados previo al acceso a recursos genéticos mediante norma secundaria; dicho instrumento regulará las condiciones, alcance y formatos a aplicarse, en concordancia con el Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación.'
  + '${SALTO}${PARRAFO}La autoridad competente para conceder el acceso al recurso genético, solicitará el pronunciamiento de la Autoridad Ambiental Nacional sobre la pertinencia del acceso solicitado. Dicho pronunciamiento deberá considerar al menos los siguientes lineamientos: '
  + '${SALTO}'
  + '${SALTO}${PARRAFO}a) El estado y formas de conservación in situ y ex situ de las especies, subespecies, morphos, variedades o razas respectivamente;'
  + '${SALTO}${PARRAFO}b) Número de especies (endémicas, amenazadas o en peligro de extinción) y patrones de distribución;'
  + '${SALTO}${PARRAFO}c) Condiciones de vulnerabilidad o fragilidad en la estructura o función de los ecosistemas;'
  + '${SALTO}${PARRAFO}d) Diversidad genética de las especies y áreas geográficas calificadas como estratégicas – vulnerables;'
  + '${SALTO}${PARRAFO}e) Reducción/erosión irreversible de la diversidad genética, causada por actividades de acceso;'
  + '${SALTO}${PARRAFO}f) Acciones que alteren la capacidad de los ecosistemas para proporcionar bienes y servicios ambientales;'
  + '${SALTO}${PARRAFO}g) Análisis de riesgo ambiental para la liberación de organismos genéticamente modificados a medios naturales con fines de investigación o comercio; y,'
  + '${SALTO}${PARRAFO}h) En los casos de investigaciones a realizarse en áreas del Sistema Nacional de Áreas Protegidas, se evaluará la pertinencia de la investigación propuesta en función de la zonificación y actividades previstas en el plan de manejo del área protegida'
  + '${SALTO}${PARRAFO}Para la aplicación de lo establecido en el artículo 74 del Código Orgánico del Ambiente, la Autoridad Ambiental Nacional emitirá su pronunciamiento por escrito, evaluando caso por caso, mediante informe técnico de evaluación, que tendrá carácter vinculante.'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Art. 221. Acceso a recursos genéticos o biológicos con fines de investigación dentro de áreas protegidas.- Cuando el acceso a recursos genéticos en general, o el acceso a recursos biológicos con fines de investigación, implique actividades que deban realizarse dentro de zonas que forman parte del Sistema Nacional de Áreas Protegidas, el responsable de área informará al investigador o a la persona que actúa en representación de quien solicitó el acceso, sobre los procedimientos a seguirse, de forma previa el ingreso al área'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Art. 224. Datos técnicos y científicos.- Para la formulación de política pública y toma de decisiones, la Autoridad Ambiental Nacional recopilará y compilará datos técnicos y científicos sobre la biodiversidad y su patrimonio genético. Para el efecto, establecerá lineamientos, estándares y protocolos que permitan la interrelación e intercambio dinámico de datos biológicos, entre los generadores de información biológica'
  + '${SALTO}'
  + '${SALTO}${TITULO2}2.  DESARROLLO'
  + '${SALTO}'
  + '${SALTO}${TITULO3}2.1  Objetivo'
  + '${SALTO}${PARRAFO}Emitir el criterio técnico para la pertinencia del acceso y/o uso de la biodiversidad y/o recursos genéticos para el proyecto/propuesta: <<solicitud.nombreProyecto>>.'
  + '${SALTO}${TITULO3}2.2  Análisis'
  + '${SALTO}${PARRAFO}Se realizó la revisión técnica a la propuesta de investigación en biodiversidad, de acuerdo al siguiente detalle: '
  + '${SALTO}${PARRAFO}Información de la evaluación.'
  // + '${SALTO}${BAREMO}<<baremo>>'
  + '${SALTO}${PARRAFO.VACIO}'
  + '${SALTO}${TITULO3}2.3  Resultado:'
  + '${SALTO}'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>[POSITIVO]'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>Caso aprobado: '
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>La propuesta no se contrapone a los lineamientos y criterios de evaluación del acceso a la biodiversidad y sus recursos genéticos.'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>[NEGATIVO]'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>Caso observado: '
  + '${SALTO}${LISTA.SIMPLE.NUMERADA}<<informeTecnicoMaate.observaciones>>'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Muestras a ser obtenidas de colecta directa en campo: Localidades de recolección:'
  + '${SALTO}'
  + "${SALTO}${PARRAFO}IN SITU"
  + "${SALTO}${RECURSOS.INSITU2}<<recursos.tablaRecursosInSitu>>"
  + "${SALTO}${PARRAFO.VACIO}"
  + "${SALTO}${PARRAFO}EX SITU"
  + "${SALTO}${RECURSOS.EXSITU2}<<recursos.tablaRecursosExSitu>>"
  + "${SALTO}" +
  + '${SALTO}${TITULO2}3.  CONCLUSIONES Y RECOMENDACIONES'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>[POSITIVO]'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>Del análisis realizado se evidenció que la solicitud No. <<solicitud.identificador>> presentada por <<solicitante.nombre>> para desarrollar el proyecto de investigación titulado “<<solicitud.nombreProyecto>>”, es pertinente, viable y factible conforme el criterio de este Ministerio y, que la misma promueve la conservación y uso sostenible de la biodiversidad. Asimismo, se evidenció que la presente solicitud no prevé el uso del recurso con fin comercial en su propuesta y desarrollo.'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>Por lo expuesto, se recomienda la APROBACIÓN de la autorización de investigación solicitada del proyecto <<solicitud.nombreProyecto>> por el plazo de <<solicitud.plazoProyecto>>, siempre y cuando no estén en contradicción con la normativa legal vigente.'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>[NEGATIVO]'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>Del análisis realizado se evidenció que la solicitud No. <<solicitud.identificador>> presentada por <<solicitante.nombre>> para desarrollar el proyecto <<solicitud.nombreProyecto>> por el plazo de <<solicitud.plazoProyecto>> meses, no cumple con los criterios de conservación y uso sostenible de la biodiversidad.'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>Por lo expuesto, se recomienda, NEGAR la autorización de investigación solicitada y solventar la información entregada en una nueva solicitud para el desarrollo del proyecto <<solicitud.nombreProyecto>> en cumplimiento de la normativa legal vigente.'
  + '${SALTO2}${VAR}Fecha:${:} <<sistema.fecha>>'
  + '${SALTO3}'
  + '${SALTO}${CENTRARBOLD}Revisado y aprobado por: '
  + '${SALTO}${CENTRAR}<<sistema.nombreAutorizadorSectorialMaate>>'
  + '${SALTO}${CENTRAR}<<sistema.rolAutorizadorSectorialMaate>>'
  + '${SALTO}${CENTRAR}<<usuario.cargoAutorizadorSectorialMaate>>'
  + '${SALTO3}'
  + '${SALTO}${CENTRARBOLD}Elaborado por: '
  + '${SALTO}${CENTRAR}<<usuario.nombre>>'
  + '${SALTO}${CENTRAR}<<usuario.rol>>'
  + '${SALTO}${CENTRAR}<<usuario.cargo>>'
