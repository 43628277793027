import React from 'react'
import ButtonGroup from '@mui/material/ButtonGroup';
import { useNavigate } from 'react-router';
import { Link, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ModalContratos } from './ModalContratos';
import { abrirModalContratos } from './sliceAdministrador';
import { useDispatch } from 'react-redux';

export const Administracion = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleFuncionarios = () => {
        navigate('/funcionarios')
    }

    const handleInvestigadores = () => {
        navigate('/investigadores')
    }

    const handleAreas = () => {
        navigate('/bosques')
    }

    const handleInstitutos = () => {
        navigate('/institutos')
    }

    const handleFirmas = () => {
        navigate('/firmas')
    }

    const handleTaxonomia = () => {

    }

    const handlePlantillas = () => {
        navigate('/plantillas')
    }

    const handleContratos = () => {
        dispatch(abrirModalContratos(true));
    }

    const handleListas = () => {
          navigate('/areas')  
    }

    const handleProceso = () => {
        navigate('/seguimientoProcesos')
    }

    const handleAutorizacion = () => {
        navigate('/autorizaciones')
    }

    return (
        <div>
            <Box sx={{ height: "100%", width: '100%', textAlign: 'center', mt: 5 }}>
                <ButtonGroup variant="outlined" aria-label="primary button group">
                    <Paper sx={{ width: 400, height: 180, borderRadius: 2, mr: 2 }} elevation={3}>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sx={{ mt: 2 }}>
                                <img src="/usuarios.jpg" alt="usuarios" width={120} height={121} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom sx={{ color: '#7e4a67', mb: 2, mt: 2 }}>
                                    Usuarios y
                                    Permisos
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    Administrar usuarios
                                    <br></br>
                                    y permisos de acceso
                                    <br></br>
                                    y uso
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handleFuncionarios}
                                >
                                    Usuarios funcionarios
                                </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handleInvestigadores}
                                >
                                    Usuarios Investigadores
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper sx={{ width: 400, height: 180, borderRadius: 2, }} elevation={3}>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sx={{ mt: 2 }}>
                                <img src="/firma.png" alt="firmas" width={120} height={121} />
                            </Grid>
                            <Grid item xs={6}>

                                <Typography variant="subtitle2" gutterBottom sx={{ color: '#7e4a67', mb: 2, mt: 2 }}>
                                    Firmas Autorizadas
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    Administrar catálogo
                                    <br></br>
                                    de tareas, documentos
                                    <br></br>
                                    y firmas electrónicas
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handleFirmas}
                                    sx={{ fontSize: 18 }}
                                >
                                    Editar
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </ButtonGroup>
            </Box>

            <Box sx={{ height: "100%", width: '100%', textAlign: 'center', mt: 2 }}>
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                    <Paper sx={{ width: 400, height: 180, borderRadius: 2, mr: 2 }} elevation={3}>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sx={{ mt: 1 }}>
                                <img src="/bosque.png" alt="bosques" width={128} height={125} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom sx={{ color: '#7e4a67', mb: 2, mt: 2 }}>
                                    Bosques y Áreas
                                    <br></br>
                                    protegidas
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    Administrar catálogo
                                    <br></br>
                                    de áreas protegidas
                                    <br></br>
                                    y bosques protectores
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handleAreas}
                                    sx={{ fontSize: 18 }}
                                >
                                    Editar
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper sx={{ width: 400, height: 180, borderRadius: 2, }} elevation={3}>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sx={{ mt: 2 }}>
                                <img src="/taxonomia.png" alt="taxonomia" width={140} height={120} />
                            </Grid>
                            <Grid item xs={6}>

                                <Typography variant="subtitle2" gutterBottom sx={{ color: '#7e4a67', mb: 2, mt: 2 }}>
                                    Taxonomía
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    Actualizar catálogo
                                    <br></br>
                                    taxonómico
                                    <br></br>
                                    especies
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handleTaxonomia}
                                    sx={{ fontSize: 18 }}
                                >
                                    Editar
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </ButtonGroup>
            </Box>

            <Box sx={{ height: "100%", width: '100%', textAlign: 'center', mt: 2 }}>
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                    <Paper sx={{ width: 400, height: 180, borderRadius: 2, mr: 2 }} elevation={3}>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sx={{ mt: 2 }}>
                                <img src="/edificio.ico" alt='institutos' width={130} height={120} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom sx={{ color: '#7e4a67', mb: 2, mt: 2 }}>
                                    Institutos
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    Administrar catálogo
                                    <br></br>
                                    de institutos
                                    <br></br>
                                    acreditados
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handleInstitutos}
                                    sx={{ fontSize: 18 }}
                                >
                                    Editar
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{ width: 400, height: 180, borderRadius: 2, }} elevation={3}>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sx={{ mt: 1 }}>
                                <img src="/biodiversity-1.png" alt="areas" width={140} height={120} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom sx={{ color: '#7e4a67', mb: 2, mt: 2 }}>
                                    Áreas y Listas
                                    <br></br>
                                    de Investigación
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    Actualizar catálogo
                                    <br></br>
                                    de áreas y listas
                                    <br></br>
                                    de investigación
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handleListas}
                                    sx={{ fontSize: 18 }}
                                >
                                    Editar
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </ButtonGroup>
            </Box>


            <Box sx={{ height: "100%", width: '100%', textAlign: 'center', mt: 2 }}>
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                    <Paper sx={{ width: 400, height: 180, borderRadius: 2, mr: 2 }} elevation={3}>

                        <Grid container spacing={2} >
                            <Grid item xs={6} sx={{ mt: 1 }}>
                                <img src="/Contract.jpeg" alt="contratos" width={115} height={125} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom sx={{ color: '#7e4a67', mb: 2, mt: 2 }}>
                                    Contratos y registros CLPI
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    Administrar contratos CLPI
                                    <br></br>
                                    y registros de acceso
                                    <br></br>
                                    a conocimiento general
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handleContratos}
                                    sx={{ fontSize: 18 }}
                                >
                                    Editar
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{ width: 400, height: 180, borderRadius: 2 }} elevation={3}>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sx={{ mt: 1 }}>
                                <img src="/template.png" alt="plantillas" width={115} height={120} />
                            </Grid>
                            <Grid item xs={6}>

                                <Typography variant="subtitle2" gutterBottom sx={{ color: '#7e4a67', mb: 2, mt: 2 }}>
                                    Plantillas
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    Administrar contenido
                                    <br></br>
                                    de texto de plantillas
                                    <br></br>
                                    para documentos administrativos,
                                    <br></br>
                                    legales y contractuales
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handlePlantillas}
                                    sx={{ fontSize: 18, mt: -4 }}
                                >
                                    Editar
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </ButtonGroup>
            </Box>
            <Box sx={{ height: "100%", width: '100%', textAlign: 'center', mt: 2 }}>
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                    <Paper sx={{ width: 400, height: 180, borderRadius: 2, mr: 2, mb: 5 }} elevation={3}>

                        <Grid container spacing={2} >
                            <Grid item xs={6} sx={{ mt: 1 }}>
                                <img src="/seg_autorizacion.png" alt="contratos" width={115} height={125} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2" gutterBottom sx={{ color: '#7e4a67', mb: 2, mt: 2 }}>
                                    Seguimiento de Autorizaciones
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    Realizar el seguimiento de 
                                    <br></br>
                                    las autorizaciones otorgadas
                                    <br></br>
                                    que se encuentren vigentes
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handleAutorizacion}
                                    sx={{ fontSize: 18 }}
                                >
                                    Editar
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{ width: 400, height: 180, borderRadius: 2 }} elevation={3}>
                        <Grid container spacing={2} >
                            <Grid item xs={6} sx={{ mt: 1 }}>
                                <img src="/seg_procesos.png" alt="plantillas" width={115} height={120} />
                            </Grid>
                            <Grid item xs={6}>

                                <Typography variant="subtitle2" gutterBottom sx={{ color: '#7e4a67', mb: 2, mt: 2 }}>
                                    Seguimiento de procesos
                                </Typography>

                                <Typography variant="body2" gutterBottom>
                                    Realizar el seguimiento
                                    <br></br>
                                    de diferentes instancias de
                                    <br></br>
                                    los flujos de procesos
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>
                            <Grid item xs={4} sx={{mt: 1}}>
                                <Link
                                    component="button"
                                    variant="body2"
                                    onClick={handleProceso}
                                    sx={{ fontSize: 18}}
                                >
                                    Editar
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </ButtonGroup>
            </Box>
            <ModalContratos /> 
        </div>
    )
}



