import { createSlice } from '@reduxjs/toolkit'
import { setUsuarioPrueba } from './API'

// import API from './API'

const state0 = {
  msgTest: null,
  usuario: null,
  modalPreferenciasOpen: false,
  modalPasswordOpen: false,
  modalImagenOpen: false
}

export const slicePreferencias = createSlice({
  name: "preferencias",
  initialState: { ...state0 },
  reducers: {
    setMsgTest: (state, action) => {
      state.msgTest = action.payload
    },
    setUsuario: (state, action) => {
      state.usuario = action.payload
    },
    modalPreferencias: (state, action) => {
      state.modalPreferenciasOpen = true
    },
    closeModalPreferencias: (state, action) => {
      state.modalPreferenciasOpen = false
    },
    modalPassword: (state, action) => {
      state.modalPasswordOpen = true
    },
    closeModalPassword: (state, action) => {
      state.modalPasswordOpen = false
    },
    openModalImagen: (state, action) => {
      state.modalImagenOpen = true
    },
    closeModalImagen: (state, action) => {
      state.modalImagenOpen = false
    }
  }
})

export const {
  setMsgTest,
  setUsuario,
  modalPreferencias,
  closeModalPreferencias,
  modalPassword,
  closeModalPassword,
  openModalImagen,
  closeModalImagen
} = slicePreferencias.actions

// THUNKS !!

export const cargarUsuario = (usuario) => {
  return (dispatch) => {
    dispatch(setUsuario(usuario));
  }
}

export const nuevaPreferencia = () => {
  return (dispatch) => {
    dispatch(modalPreferencias());
  }
}

export const cerrarModalPreferencias = () => {
  return (dispatch) => {
    dispatch(closeModalPreferencias());
  }
}

export const nuevoPassword = () => {
  return (dispatch) => {
    dispatch(modalPassword());
  }
}

export const cerrarModalPassword = () => {
  return (dispatch) => {
    dispatch(closeModalPassword());
  }
}
export const abrirModalImagen = () => {
  return (dispatch) => {
    dispatch(openModalImagen());
  }
}

export const cerrarModalImagen = () => {
  return (dispatch) => {
    dispatch(closeModalImagen());
  }
}
