import React, {useEffect, useRef, useState} from 'react'
import {Box, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch} from 'react-redux'
import {
  handleSaveSolicitud,
  handleClear,
  // handCompletarTareaCoordinador
} from 'src/features/App/sliceApp'
import {MySendButton} from 'src/components/MySendButton'
import {SectionTitle} from 'src/components/SectionTitle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import {f} from 'src/commons/f'
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MySubtitle} from "../../components/MySubtitle";
// import {Email} from "@mui/icons-material";
import API from 'src/features/App/API'
import {Solicitante} from "../P01Solicitud/subcomponents/Solicitante";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Autorizacion from "../P01Solicitud/subcomponents/Autorizacion";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import {MyReadOnlyAreaTextField} from "../../components/MyReadOnlyAreaTextField";
import GLOBALS from "../App/globals";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {handCompletarTareaCoordinador} from 'src/features/App/sliceApp'
import {MyUpload} from "../../components/MyUpload";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P16SolicitudRO} from "./P16SolicitudRO";
import {P0102PayloadRO} from "../P01Solicitud/P0102PayloadRO";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import DoneAllIcon from "@mui/icons-material/DoneAll";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({instanciaTarea, instanciaProceso, solicitud, perfilUsuario, solicitudesAprobadas}) => {
  const dispatch = useDispatch()
  const section = 'ElaborarActoAdmistrativo'
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const mp = slct.payload.Solicitud.solicitudAprobada
  const payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:                      today,
    pdfSigned:                  '',
  }

  const canEdit = instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, 'entrada', setFormValues, formValues)
  const [counter, setCounter] = useState(-1)  // updating counter

  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])

  const [myTab, setMytab] = useState('1');

  const handleChangeTab = (event, newTab) => { setMytab(newTab) }
  const inputRef = useRef()
  const filename = `informe-tecnico-${solicitud.numeroSolicitud}-Senescyt`
  const filenamePDFsigned = `${filename}-firmado.pdf`

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Emitir resolución'} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud de cierre" value="2" />
            <Tab label="Solicitud" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Declaración'} />
            </Grid>
            <Grid item xs={6} >
              <MyReadOnlyTextField label={'Identificador'}
                                   icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                   value={solicitud.numeroSolicitud} />
            </Grid>
            <Grid item xs={6} >
              <MyReadOnlyTextField label={'Fecha'}
                                   icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                   value={formValues['fecha']} />
            </Grid>
            <Solicitante solicitud={{solicitud: {payload: mp}}} displayContact={false}/>
            {/*<Autorizacion solicitud={{numeroSolicitud: formValues.aprobadaIdentificador, payload: mp}} />*/}
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Informes y dictamenes técnicos'} />
            </Grid>
            <Grid item xs={12} style={{padding:'0 0 0 24px'}}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={2}>
                <MyButtonBacan label={'Maate'}
                               onClick={() => {
                                 const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/informe-tecnico-Maate/${payload?.EmitirInformeTecnicoMaate?.pdfSigned}`
                                 fetch(url)
                                   .then((res) => { return res.blob(); })
                                   .then((data) => {
                                     const dataPdf = new Blob([data], { type: 'application/pdf' })
                                     const a = document.createElement("a")
                                     a.href = window.URL.createObjectURL(dataPdf)
                                     a.target = "_blank"
                                     a.click()
                                   })
                               }}
                               icon={FileDownloadOutlinedIcon} />
                <MyButtonBacan label={'Senadi'}
                               onClick={() => {
                                 const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/informe-tecnico-Senadi/${payload?.EmitirInformeTecnicoSenadi?.pdfSigned}`
                                 fetch(url)
                                   .then((res) => { return res.blob(); })
                                   .then((data) => {
                                     const dataPdf = new Blob([data], { type: 'application/pdf' })
                                     const a = document.createElement("a")
                                     a.href = window.URL.createObjectURL(dataPdf)
                                     a.target = "_blank"
                                     a.click()
                                   })
                               }}
                               icon={FileDownloadOutlinedIcon} />
                <MyButtonBacan label={'Senescyt'}
                               onClick={() => {
                                 const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/informe-tecnico-Senescyt/${payload?.Verificar?.pdfSigned}`
                                 fetch(url)
                                   .then((res) => { return res.blob(); })
                                   .then((data) => {
                                     const dataPdf = new Blob([data], { type: 'application/pdf' })
                                     const a = document.createElement("a")
                                     a.href = window.URL.createObjectURL(dataPdf)
                                     a.target = "_blank"
                                     a.click()
                                   })
                               }}
                               icon={FileDownloadOutlinedIcon} />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Resolución'} />
            </Grid>
            <Grid item xs={12} >
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 0 0 8rem'}}>
                <MyButtonBacan3 label={'PDF'}
                                icon={FileDownloadIcon}
                                onClick={() => API.fetchDownloadPDF({
                                  solicitudId: instanciaProceso?.solicitud?.id,
                                  subfolder:'informe-tecnico-Senescyt',
                                  filename: (payload?.ElaborarResolucion.pdfLded !== '') ? payload?.ElaborarResolucion.pdfLded : payload?.ElaborarResolucion.pdf
                                })}
                                toolTip={'Descargar documento en formato pdf'}
                                width={'5rem'} />
                <MyFileUploadButton inputRef={inputRef}
                                    label={'PDF (firmado)'}
                                    solicitudId={instanciaProceso?.solicitud?.id}
                                    subfolder={'informe-tecnico-Senescyt'}
                                    fileName={filenamePDFsigned}
                                    afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                    toolTip={'Subir documento firmado o para firmar en formato pdf'}
                                    width={'11rem'} />
                <MyFileUploadButton inputRef={inputRef}
                                    label={'firmar pdf'}
                                    solicitudId={instanciaProceso?.solicitud?.id}
                                    subfolder={'informe-tecnico-Senescyt'}
                                    fileName={filenamePDFsigned}
                                    afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                    disabled={true}
                                    icon={() => <DoneAllIcon height={'1rem'} fill={'rgba(255, 255, 255, 0.6)'} />}
                                    toolTip={'Firmar documento electrónicamente'}
                                    width={'11rem'} />
                {
                  formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                 icon={FileDownloadIcon}
                                                                 onClick={() => API.fetchDownloadPDF({
                                                                   solicitudId: instanciaProceso?.solicitud?.id,
                                                                   subfolder:'informe-tecnico-Senescyt',
                                                                   filename: filenamePDFsigned,
                                                                 })}
                                                                 toolTip={'Descargar dictamen tecnico'}
                                                                 width={'2rem'}/>
                }
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <P16SolicitudRO payload={payload} />
        </TabPanel>
        <TabPanel value="3">
          <P0102PayloadRO payload={JSON.parse(payload?.Solicitud?.solicitudAprobada)} />
        </TabPanel>
      </TabContext>

      <CssBaseline />
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={1} style={{padding:'0 0 0 1rem'}}>
                <MyButtonBacan label={'Regresar'}
                               myTip={'Regresar a las tareas'}
                               icon={ArrowBackIcon}
                               onClick={() => { dispatch(handleClear()) }} />
                <MyButtonBacan label={'Guardar'}
                               myTip={'Guarda el formulario, y permite continuar editando'}
                               onClick={() => {
                                 payload[section]=formValues
                                 setCounter(0)
                                 dispatch(handleSaveSolicitud(instanciaProceso?.id,payload))
                               }}
                               disabled={!canEdit || counter <= 0}
                               icon={SaveOutlinedIcon} />
                <MySendButton onSend={ () => {
                                const metadata = JSON.stringify({
                                  "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                })
                                dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'Enviar'}
                              disabled={ counter > 0 || formValues.pdfSigned === ''} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}
