import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, Chip, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { cambiarEstado } from './API';

const botonAzul = {
    borderRadius: '0.2rem',
    textTransform: 'none',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
        backgroundColor: 'rgba(54, 138, 184, 1)',
    }
}

export const ToolBarInvestigadores = (props) => {

    const { numSelected } = props;
    const dispatch = useDispatch();
    const { investigador } = useSelector(state => state.administracion)

    const handleBorrar = (funcio) => {
        Swal.fire({
            title: 'Desea cambiar el estado del investigador: ' + '</br>' + funcio?.nombreUsuario + " a " + (funcio?.estadoUsuario === 'Activo' ? 'Inactivo' : 'Activo'),
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cerrar',
            confirmButtonColor: 'rgba(54, 160, 184, 1)',
            cancelButtonColor: 'rgba(54, 160, 184, 1)',
            icon: 'question'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(cambiarEstado(funcio.id, 2))
            }
        })
    }

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            {investigador ? (
                <Typography
                    sx={{ flex: '1 1 80%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    <Chip
                        sx={{
                            color: 'rgba(255, 255, 255, 0.9)',
                            backgroundColor: 'rgba(54, 160, 184, 1)',

                        }}
                        size="medium" label={investigador[0]?.nombreUsuario + ' seleccionado'} />

                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h5"
                    id="tableTitle"
                    component="div"
                    align='center'
                >
                    Lista de Investigadores
                </Typography>
            )}
            {investigador &&
                (
                    <Grid item xs={1} sm={2}>
                        <Button variant="contained" onClick={() => handleBorrar(investigador[0])}
                            sx={botonAzul}>
                            {investigador[0].estadoUsuario === "Activo" ? 'Desactivar' : 'Activar'}
                        </Button>
                    </Grid>
                )
            }
        </Toolbar>
    );
};