import * as React from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { dialogoInformativo, retornaProcesos, retornaTareas, retornaFirmas, retornaRoles, guardarFirma } from './API';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import { Alert, AlertTitle, Button, ButtonGroup, InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import "../chat/swalestilo.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import { useNavigate } from 'react-router';
import { cargarOrden, limpiarFirmas, quitarFirmaSeleccionada, quitarSeleccion, seleccionarFirma, seleccionarOrden } from './sliceAdministrador';
import { ToolBarFirmas } from './ToolBarFirmas';
import { DataGrid } from '@mui/x-data-grid';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  esES,
);

const columns = [
  {
    field: 'nombreTarea',
    headerName: 'Tarea',
    width: 300,
    editable: false,
  },
  {
    field: 'descripcionPerfil',
    headerName: 'Rol',
    width: 300,
    editable: false,
  },
  {
    field: 'secuenciaFirma',
    headerName: 'Orden',
    width: 100,
    type: 'number',
    editable: false,
  },
];

const botonAzul = {
  mr: 2, borderRadius: '0.2rem',
  textTransform: 'none',
  color: 'rgba(255, 255, 255, 0.9)',
  backgroundColor: 'rgba(54, 160, 184, 1)',
  "&:hover": {
    backgroundColor: 'rgba(54, 138, 184, 1)',
  }
}

const botonAzul2 = {
  borderRadius: '0.2rem',
  textTransform: 'none',
  color: 'rgba(255, 255, 255, 0.9)',
  backgroundColor: 'rgba(54, 160, 184, 1)',
  "&:hover": {
    backgroundColor: 'rgba(54, 138, 184, 1)',
  }
}

const botonVerde = {
  ml: 2, mr: 2,
  textTransform: 'none',
  borderRadius: '0.2rem',
  color: 'rgba(255, 255, 255, 0.9)',
  backgroundColor: 'rgba(14, 181, 14, 1)',
  "&:hover": {
    backgroundColor: 'rgba(18, 219, 18, 1)',
  }
}

export const TablaFirmas = () => {

  const dispatch = useDispatch();

  const { procesos, tareas, firmas, roles, orden, ordenSeleccionado, seleccionadoFirma } = useSelector(state => state.administracion)

  const [pro, setPro] = React.useState('');
  const [tar, setTar] = React.useState('');
  const [rolon, setRolon] = React.useState('')
  const [cc, setCc] = React.useState(false)
  const [ord, setOrd] = React.useState('')
  const [pageSize, setPageSize] = React.useState(5);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(limpiarFirmas());
    dispatch(quitarSeleccion());
    dispatch(quitarFirmaSeleccionada());
    dispatch(retornaProcesos());
    dispatch(retornaRoles());
  }, [])

  React.useEffect(() => {
    if (!seleccionadoFirma) {
      setSelectionModel([]);
    }
  }, [seleccionadoFirma])

  React.useEffect(() => {

    // firmas.length !== 0 && 
    firmas.map(firma => (dispatch(cargarOrden(firma.secuenciaFirma))))
    setOrd('');
    dispatch(seleccionarOrden(false));

  }, [firmas])

  const handleRegresar = () => {
    navigate('/administracion');
  }

  const handleChangeProceso = (event) => {
    setPro(event.target.value);
    dispatch(retornaTareas(event.target.value));
    setCc(false);
    setTar('');
    dispatch(limpiarFirmas());
    dispatch(quitarFirmaSeleccionada());
    setOrd('');
  }

  const handleChangeTarea = (event) => {
    setTar(event.target.value)
    setCc(false);
    dispatch(limpiarFirmas());
    dispatch(quitarFirmaSeleccionada());
    setOrd('');
  }

  const handleChangeRol = (event) => {
    setRolon(event.target.value)
  }

  const handleBuscar = () => {
    pro !== '' ?
      (tar !== '' ?
        funciones()
        :
        dispatch(dialogoInformativo("Seleccione una tarea!"))
      )
      :
      dispatch(dialogoInformativo("Seleccione un proceso!"));
  }

  const handleChangeOrden = (event) => {
    setOrd(event.target.value);
    dispatch(seleccionarOrden(true));
  }

  const handleAgregar = () => {
    pro !== '' ?
      (tar !== '' ?
        (
          rolon !== '' ?
            (
              ord !== '' ?
                dispatch(guardarFirma(tar, rolon, ord))
                :
                dispatch(dialogoInformativo("Seleccione un orden!"))
            )
            :
            dispatch(dialogoInformativo("Seleccione un rol!"))
        )
        :
        dispatch(dialogoInformativo("Seleccione una tarea!"))
      )
      :
      dispatch(dialogoInformativo("Seleccione un proceso!"));
  }

  const funciones = () => {
    setCc(true)
    dispatch(retornaFirmas(tar))
  }

  const handleFirma = (firma) => {
    if (firma.length === 1) {
      dispatch(seleccionarFirma(firma[0]))
    } else {
      dispatch(quitarFirmaSeleccionada());
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{
          height: 600, width: '100%'
        }}>

          <FormControl sx={{ minWidth: 300, mt: 3, ml: 7 }}>
            <InputLabel id="demo-simple-select-helper-label">Procesos</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={pro}
              label="Procesos"
              onChange={handleChangeProceso}
              displayEmpty
            >
              {
                procesos.map((proceso) =>
                  (<MenuItem key={proceso.id} value={proceso.id}>{proceso.nombreProceso}</MenuItem>)
                )
              }

            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 300, mt: 3, ml: 7 }}>
            <InputLabel id="demo-simple-select-helper-label">Tareas</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={tar}
              label="Tareas"
              onChange={handleChangeTarea}
              displayEmpty
            >
              {
                tareas.map((tarea) =>
                  (<MenuItem key={tarea.id} value={tarea.id} >{tarea.nombreTarea}</MenuItem>)
                )
              }

            </Select>
          </FormControl>

          <ButtonGroup variant="outlined" sx={{ ml: 5, mt: 5 }} aria-label="outlined primary button group">
            <Button onClick={handleBuscar}
              sx={botonAzul}
              startIcon={<SearchIcon />}>Buscar
            </Button>
          </ButtonGroup>

          <Box sx={{ width: '100%', mb: 3 }}>
            <FormControl sx={{ minWidth: 300, mt: 3, ml: 7 }}>
              <InputLabel id="demo-simple-select-helper-label">Roles</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={rolon}
                label="Roles"
                onChange={handleChangeRol}
                displayEmpty
                disabled={cc ? false : true}
              >
                {
                  roles.map((rol) =>
                    (<MenuItem key={rol.id} value={rol.id} >{rol.descripcionPerfil}</MenuItem>)
                  )
                }

              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 100, mt: 3, ml: 7 }}>
              <InputLabel id="demo-simple-select-helper-label">Orden</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={ord}
                label="Orden"
                onChange={handleChangeOrden}
                displayEmpty
                disabled={cc ? false : true}
              >
                {
                  orden.map((numero) =>
                    (<MenuItem key={numero} value={numero} >{numero}</MenuItem>)
                  )
                }

              </Select>
            </FormControl>

            <ButtonGroup variant="outlined" sx={{ ml: 3, mt: 4 }} aria-label="outlined primary button group">
              <Button onClick={handleAgregar}
                sx={botonVerde}
                disabled={(cc && tar && ord && rolon && ordenSeleccionado) ? false : true}
                startIcon={<AddCircleOutlineIcon />}>Agregar
              </Button>
            </ButtonGroup>
          </Box>

          <Box sx={{ height: 371, width: '100%' }}>

            {
              cc &&

              (firmas.length !== 0 ?

                <React.Fragment>

                  <ToolBarFirmas />

                  <DataGrid
                    rows={firmas}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10]}
                    checkboxSelection
                    onSelectionModelChange={(newSelectionModel) => {
                      if (newSelectionModel.length > 1) {
                        const selectionSet = new Set(selectionModel);
                        const result = newSelectionModel.filter((s) => !selectionSet.has(s));

                        setSelectionModel(result);
                        handleFirma(result);
                      } else {
                        setSelectionModel(newSelectionModel);
                        handleFirma(newSelectionModel);
                      }
                    }}
                    selectionModel={selectionModel}
                    disableSelectionOnClick
                    disableColumnMenu
                    experimentalFeatures={{ newEditingApi: true }}
                  />

                </React.Fragment>

                :
                <Alert severity="info" sx={{ textAlign: 'center', color: 'rgba(255, 255, 255, 0.9)', backgroundColor: 'rgba(54, 160, 184, 1)' }} >
                  <AlertTitle >Aviso</AlertTitle>
                  Ninguna firma asignada a la tarea
                </Alert>

              )
            }

          </Box>
        </Box>
      </ThemeProvider>
      <ButtonGroup variant="contained" sx={{ ml: 6, mt: 4 }} aria-label="outlined primary button group">
        <Button variant="contained" onClick={handleRegresar} startIcon={<ArrowCircleLeftOutlinedIcon />}
          sx={botonAzul2}
        >
          Regresar
        </Button>
      </ButtonGroup>
    </>
  );
}