import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import {useSelector} from 'react-redux'
import {accordeonBox} from 'src/styles/styles'
import {MyTablePersonal} from 'src/components/MyTablePersonal'
import {MyTableExperiencia} from 'src/components/MyTableExperiencia'
import Typography from '@mui/material/Typography'
import API from "../../App/API";
import {f} from "../../../commons";
import {Grid} from "@mui/material";

const emptyItem = {
  cedula:         '',
  nombre:         '',
  pasaporte:      '',
  gradoAcademigo: '',
  institucion:    '',
  celular:        '',
  correo:         '',
  notificar:      false
}

const emptySubItem = {
  proyecto:       '',
  funcion:        '',
  inicio:         '',
  fin:            '',
  experiencia:    '',
}

export const Personal = ({section, formValues, setFormValues, canEdit, formErrors}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})
  const sectionErrors = (sectionValues.personal?.length === 0)?'NO HAY LISTA DE PERSONAL':'' ||
    (sectionValues.experiencia?.length === 0)?'NO HAY EXPERIENCIA':''
  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  useEffect(() => {
    if(canEdit && f.isValid(setFormValues)) {
      setFormValues({...formValues,[section]: {...sectionValues}})
    }
  }, [sectionValues])

  useEffect(() => {
    const personal = sectionValues.personal
    if(Array.isArray(personal)) {
      if(personal.filter(it => it.cedula === formValues.Solicitante?.cedula).length === 0) {
        const row = {
          cedula:         formValues?.Solicitante?.cedula,
          celular:        formValues?.Solicitante?.celular,
          correo:         formValues?.Solicitante?.email,
          nombre:         formValues?.Solicitante?.nombresCompletos,
          pais:           '',
          notificar:      true,
          gradoAcademigo: '',
          cargoProyecto:  '',
          pasaporte:      '',
          institucion:    '',
        }
        setSectionValues({...sectionValues, personal: [...sectionValues.personal, row]})
      }
    }
  },[])
  const [selected, setSelected] = useState({})
  return(
    <>
      <Box sx={accordeonBox.container}>
        <MyTablePersonal id={'personal'}
                         canEdit={canEdit}
                         formValues={sectionValues}
                         setFormValues={setSectionValues}
                         addItem={() => {
                           setSectionValues({...sectionValues,personal: [...sectionValues.personal, {...emptyItem}]})
                         }}
                         canDeleteRow={canEdit}
                         selected={selected}
                         idSolicitud={formValues.INFO.solicitudId}
                         setSelected={setSelected} />
      </Box>
      {
        (selected.id !== undefined && selected.cedula !== '') ?
          <Box sx={accordeonBox.container}>
            <Typography>{`Experiencia de ${selected.nombre}, relevante al proyecto que se está solicitando`}</Typography>
            <MyTableExperiencia id={'experiencia'}
                                canEdit={canEdit}
                                formValues={sectionValues}
                                setFormValues={setSectionValues}
                                addItem={() => {
                                  setSectionValues({...sectionValues,experiencia: [...sectionValues.experiencia, {...emptySubItem, cedula: selected.cedula}]})
                                }}
                                canDeleteRow={canEdit}
                                selected={selected}/>
          </Box>:null
      }
      {
        (sectionErrors !== '') ? <Typography sx={{color:'red'}}>{sectionErrors}</Typography> : false
      }
    </>
  )
}
