import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {MyTextField} from "src/components/MyTextField";
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {MySwitch} from "../../components/MySwitch";
import {MySubtitle} from "../../components/MySubtitle";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {WF03SolicitudRO} from "./P03SolicitudRO";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MyDatePicker} from "../../components/MyDatePicker";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {WF03Expediente} from "./subcomponents/P03Expediente";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import GLOBALS from "../App/globals";
import {MyTable as MyTableObservaciones} from "../../components/MyTable";

// eslint-disable-next-line import/no-anonymous-default-export
export const P03RevisarContratoInvestigador = ({instanciaTarea, instanciaProceso, solicitud, perfilUsuario, id}) => {
  // console.log(':::: sid : ', id)
  const inputRef = useRef()
  const section = 'P03P03RevisarContrato'
  const dispatch = useDispatch()
  const bandeja = useSelector(state => state.app.bandeja)
  let payload = !!solicitud.payload?JSON.parse(solicitud.payload):{[section]:{}}
  payload.solicitudId = solicitud.id
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    identificador: solicitud.numeroSolicitud,
    fecha:                today,
    observacionesReporte: payload.P03P03ElaborarContrato.observacionesReporte,
    resolucionDeInicio :  payload.P03P03ElaborarContrato.resolucionDeInicio,
    serial:               payload.P03P03ElaborarContrato.serial,
    docx:                 payload.P03P03ElaborarContrato.docx,
    pdf:                  payload.P03P03ElaborarContrato.pdf,
    docxLded:             payload.P03P03ElaborarContrato.docxLded,
    pdfLded:              payload.P03P03ElaborarContrato.pdfLded,
    pdfSigned:            '',
    cambiosContrato:      false,
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada' && instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => { setCounter(counter + 1) }, [formValues])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }
  // const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `contrato-Inabio`
  const filename = `contrato-${solicitud.numeroSolicitud}-Inabio`
  const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  const filenamePDFuploaded = `${filename}-upld.pdf`
  const filenamePDFsigned = `${filename}-firmado.pdf`

  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    }
  }

  const inabio09 = payload?.INFO?.docs?.d09Negociacion.findIndex(x => x.idx === 'RESFINAL')
  const senescyt08 = payload?.INFO?.docs?.d08Mejoras.findIndex(x => x.idx === 'SENESCYT')
  const maate08 = payload?.INFO?.docs?.d08Mejoras.findIndex(x => x.idx === 'MAATE')
  const senadi08 = payload?.INFO?.docs?.d08Mejoras.findIndex(x => x.idx === 'SENADI')

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Revisar contrato - Investigador'}/>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
            <Tab label="Expediente" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>


            <Grid item xs={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'INABIO'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: payload?.INFO?.docs?.d09Negociacion[inabio09]?.solicitudId,
                        subfolder: payload?.INFO?.docs?.d09Negociacion[inabio09]?.subFolder,
                        filename: payload.INFO.docs?.d09Negociacion[inabio09]?.archivo,
                      })}
                      label={'Res. FINAL'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'SENESCYT'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: payload?.INFO?.docs?.d08Mejoras[senescyt08]?.solicitudId,
                        subfolder: payload?.INFO?.docs?.d08Mejoras[senescyt08]?.subFolder,
                        filename: payload.INFO.docs?.d08Mejoras[senescyt08]?.archivo,
                      })}
                      label={'Mejoras'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'MAATE'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: payload?.INFO?.docs?.d08Mejoras[maate08]?.solicitudId,
                        subfolder: payload?.INFO?.docs?.d08Mejoras[maate08]?.subFolder,
                        filename: payload.INFO.docs?.d08Mejoras[maate08]?.archivo,
                      })}
                      label={'Mejoras'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'SENADI'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: payload?.INFO?.docs?.d08Mejoras[senadi08]?.solicitudId,
                        subfolder: payload?.INFO?.docs?.d08Mejoras[senadi08]?.subFolder,
                        filename: payload.INFO.docs?.d08Mejoras[senadi08]?.archivo,
                      })}
                      label={'Mejoras'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Observaciones'} />
            </Grid>
            <Grid item xs={12}>
              <MyTableObservaciones id={'observacionesReporte'}
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    columnName={'Observaciones *'}
                                    canEdit={canEdit}
                                    addItem={() => {
                                      if(formValues['observacionesReporte'].filter(it => it === '').length === 0) {
                                        const field = 'observacionesReporte'
                                        const newSet = [ ...formValues[field], '']
                                        const newFormValues = {...formValues, [field]:newSet}
                                        setFormValues(newFormValues)
                                      }
                                    }} />
            </Grid>

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Propuesta'} />
            </Grid>
            {/*cambiosContrato*/}
            <Grid item xs={6} >
              <MySwitch id={'cambiosContrato'}
                        label={'¿Cámbios en contrato?'}
                        fullWidth={false}
                        formValues={formValues}
                        canEdit={true}
                        handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12} >
              <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                {/*{*/}
                {/*  formValues.serial === '' &&*/}
                {/*  <ButtonGroup variant={'contained'}>*/}
                {/*    <MyButtonBacan3 label={'GENERAR SERIAL'}*/}
                {/*                    width={'11rem'}*/}
                {/*                    bgColor1={'rgb(148 193 32)'}*/}
                {/*                    onClick={() => {*/}
                {/*                      API.secuenciaSet('AUTIC').then((ser) => {*/}
                {/*                        if(f.isValidNotEmpty(ser.codigoSecuencia)) {*/}
                {/*                          const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`*/}
                {/*                          getOnSave({...formValues, serial:serial})()*/}
                {/*                          setFormValues({...formValues, serial:serial})*/}
                {/*                        }*/}
                {/*                      })*/}
                {/*                    }}*/}
                {/*                    icon={RestartAltIcon}/>*/}
                {/*  </ButtonGroup>*/}
                {/*}*/}
                {/*{*/}
                {/*  formValues.docx !== '' && formValues.serial !== '' &&*/}
                {/*  <ButtonGroup variant={'contained'}>*/}
                {/*    <MyButtonBacan3 label={''}*/}
                {/*                    width={'3rem'}*/}
                {/*                    onClick={() => {*/}
                {/*                      setFormValues({*/}
                {/*                        ...formValues,*/}
                {/*                        docx: '',*/}
                {/*                        pdf: '',*/}
                {/*                        docxLded: '',*/}
                {/*                        pdfLded: '',*/}

                {/*                        // firma -- \\*/}
                {/*                        firmaTareaId: 0,*/}
                {/*                        pdfSigned:    '',*/}
                {/*                        firmando:     false,*/}
                {/*                        firmado:      false,*/}
                {/*                        // firma -- //*/}
                {/*                      })*/}
                {/*                    }}*/}
                {/*                    icon={RestartAltIcon}/>*/}
                {/*  </ButtonGroup>*/}
                {/*}*/}
                {/*{*/}
                {/*  <ButtonGroup variant={'contained'}>*/}
                {/*    <MyButtonBacan3 label={'Generar Propuesta'}*/}
                {/*                    width={'11rem'}*/}
                {/*                    onClick={() => {*/}
                {/*                      const doc = MyTagged2Docx(plantilla, nombreOrganizacion, mapping({*/}
                {/*                        solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,*/}
                {/*                        payload,*/}
                {/*                        autorizador: {},*/}
                {/*                        coordinador: {},*/}
                {/*                        perfilUsuario,*/}
                {/*                        nombreOrganizacion,*/}
                {/*                        action: payload[section]*/}
                {/*                      }, payload))*/}
                {/*                      API.genDocxAnPdf({*/}
                {/*                        solicitudId: instanciaProceso?.solicitud?.id,*/}
                {/*                        document: doc,*/}
                {/*                        formValues,*/}
                {/*                        setFormValues,*/}
                {/*                        subfolder,*/}
                {/*                        filename: filenameDOCX,*/}
                {/*                        nombreOrganizacion,*/}
                {/*                        payload,*/}
                {/*                      })*/}
                {/*                    }}*/}
                {/*                    icon={MiscellaneousServicesIcon} />*/}
                {/*  </ButtonGroup>*/}
                {/*}*/}
                {
                  <>
                    <MyButtonBacan3 label={'DOCX'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadDOCX({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                    })}
                                    toolTip={'Descagar documento en formato docx'}
                                    width={'5rem'} />
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                    })}
                                    toolTip={'Descagar documento en formato pdf'}
                                    width={'5rem'} />
                  </>
                }
                {/*{*/}
                {/*  <ButtonGroup variant={'contained'}>*/}
                {/*    <MyFileUploadButton inputRef={inputRef}*/}
                {/*                        label={'DOCX'}*/}
                {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                {/*                        subfolder={subfolder}*/}
                {/*                        fileName={filenameDOCXuploaded}*/}
                {/*                        afterUpload={() => {*/}
                {/*                          API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {*/}
                {/*                            if(f.isValid(result2.rutaDocumento)) {*/}
                {/*                              const arr2 = result2.rutaDocumento.split('/')*/}
                {/*                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})*/}
                {/*                            } else {*/}
                {/*                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})*/}
                {/*                            }*/}
                {/*                          })*/}
                {/*                        }}*/}
                {/*                        toolTip={'Subir documento modificado en formato docx'}*/}
                {/*                        width={'5rem'} />*/}
                {/*  </ButtonGroup>*/}
                {/*}*/}
                {/*{*/}
                {/*  <ButtonGroup variant={'contained'}>*/}
                {/*    <MyFileUploadButton inputRef={inputRef}*/}
                {/*                        label={'PDF (firmado)'}*/}
                {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                {/*                        subfolder={subfolder}*/}
                {/*                        fileName={filenamePDFsigned}*/}
                {/*                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}*/}
                {/*                        toolTip={'Subir documento firmado electrónicamente'}*/}
                {/*                        width={'9rem'} />*/}
                {/*    <MyFileUploadButton inputRef={inputRef}*/}
                {/*                        label={'Firmar'}*/}
                {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                {/*                        subfolder={subfolder}*/}
                {/*                        fileName={filenamePDFsigned}*/}
                {/*                        toolTip={'Firmar electrónicamente'}*/}
                {/*                        disabled={true}*/}
                {/*                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}*/}
                {/*                        width={'9rem'} />*/}
                {/*    {*/}
                {/*      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}*/}
                {/*                                                     icon={FileDownloadIcon}*/}
                {/*                                                     onClick={() => API.fetchDownloadPDF({*/}
                {/*                                                       solicitudId: instanciaProceso?.solicitud?.id,*/}
                {/*                                                       subfolder,*/}
                {/*                                                       filename: filenamePDFsigned,*/}
                {/*                                                     })}*/}
                {/*                                                     toolTip={'Descargar documento firmado'}*/}
                {/*                                                     width={'3rem'}/>*/}
                {/*    }*/}
                {/*  </ButtonGroup>*/}
                {/*}*/}
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <WF03SolicitudRO payload={payload}/>
        </TabPanel>
        <TabPanel value="3">
          <WF03Expediente payload={payload} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={() => {
                                // const target = 'REVCONTRATO'
                                // let docs = {...payload.INFO.docs}
                                // let IDXloc = docs.d09Negociacion?.findIndex(x => x.idx === target)
                                // if(IDXloc >= 0) {
                                //   payload.INFO.docs.d09Negociacion?.splice(IDXloc, 1)
                                //   docs = {
                                //     ...payload.INFO.docs,
                                //     'd09Negociacion': payload.INFO.docs.d09Negociacion
                                //   }
                                // } else {
                                //   if(!f.isValid(payload.INFO.docs.d09Negociacion))
                                //     docs = {
                                //       ...payload.INFO.docs,
                                //       'd09Negociacion': []
                                //     }
                                // }
                                // docs = {
                                //   ...payload.INFO.docs,
                                //   'd09Negociacion': [
                                //     ...docs.d09Negociacion ,
                                //     {
                                //       'idx': target,
                                //       'archivo': (formValues.pdfSigned !== '') ? formValues.pdfSigned : formValues.pdf,
                                //       'solicitudId': payload.INFO.solicitudId,
                                //       'desc': 'Revisar contrato',
                                //       'subFolder': subfolder
                                //     }
                                //   ]
                                // }
                                // payload.INFO.docs = docs
                                getOnSave()()
                              }}
                              disabled={counter <= 0} />
                <MySendButton onSend={() => {
                                const metadata = JSON.stringify({
                                  "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                  "solicitaCambios": formValues.cambiosContrato,
                                })
                                dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'ENVIAR'}
                              myTip={''}
                              disabled={counter > 0}/>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

const plantilla = '' +
  + '${SALTO}'
  + '${SALTO}${CENTRAR}CONTRATO'
  + '${SALTO}${CENTRAR}No. <<serial>>'
  + '${SALTO}${VAR}Fecha${:}<<sistema.fecha>>'
  + '${SALTO}'
  + '${SALTO}${TITULO2}1.  ANTECEDENTES'
  + '${SALTO}${TITULO3}1.1  Datos Generales'
  + '${SALTO}${VAR}Solicitud:${:}<<solicitud.identificador>>'
  + '${SALTO}${VAR}Solicitante: ${:}<<solicitante.nombre>>'
  + '${SALTO}${VAR}Proyecto:${:}<<solicitud.nombreProyecto>>'
  + '${SALTO}'
  + '${SALTO}${TITULO3}PROPUESTA DE NEGOCIACIÓN:'
  + '${SALTO}${LISTA.SIMPLE}<<observaciones>>'
  + '${SALTO3}'
  + '${SALTO}${CENTRARBOLD}Revisado y aprobado por: '
  + '${SALTO}${CENTRAR}<<sistema.nombreAutorizadorSectorialMaate>>'
  + '${SALTO}${CENTRAR}<<sistema.rolAutorizadorSectorialMaate>>'
  + '${SALTO}${CENTRAR}<<usuario.cargoAutorizadorSectorialMaate>>'
  + '${SALTO3}'
  + '${SALTO}${TITULO3}2.  PROPUESTA:'
  + '${SALTO}${CENTRARBOLD}Elaborado por: '
  + '${SALTO}${CENTRAR}<<usuario.nombre>>'
  + '${SALTO}${CENTRAR}<<usuario.rol>>'
  + '${SALTO}${CENTRAR}<<usuario.cargo>>'


// + '${SALTO}${CENTRAR}<<usuario.cargo>>'


const mapping = (vars) => {
  const observacionesReporte = vars.payload?.InformeTecnicoMaate?.observacionesReporte?.join('{:}')
  const { solicitudId, payload, autorizador, coordinador, perfilUsuario, section, aprueba } = vars
  const autoridadSenescyt = vars.autoridadSenescyt
  // const arr = [...doc.matchAll(/\<\<[\w\.]*\>\>/g)].map(it => ({match:it[0], index: it.index})))
  // console.log(arr?.map(it => ({match:it[0], index: it.index})))
  const recInsitu = payload?.RecursosInSitu?.recursos?.map(it => it.scientificname)?.join(', ') + ', ' + payload?.RecursosExSitu?.centrosDocumentacion?.recursos?.map(it => it.scientificname)?.join(', ')
  const recExsitu = payload?.RecursosExSitu?.centrosDocumentacion?.map(it => it.recursos?.map(it2 => it2.scientificname)?.join((', ')))?.join(', ')
  let recs = []
  if(f.isValid(recInsitu)) recs.push(recInsitu)
  if(f.isValid(recExsitu)) recs.push(recExsitu)
  let acc = []
  if(payload?.AccesoConocimiento?.aConocimientoTradicional) acc.push('Acceso a conocimiento tradicional.')
  if(payload?.AccesoConocimiento?.clpi !== "") acc.push('CLPI: ' + payload?.AccesoConocimiento?.clpi)
  if(payload?.AccesoConocimiento?.contratoAccesoConocimiento !== "") acc.push('Contrato de acceso a conocimiento: '+ payload?.AccesoConocimiento?.contratoAccesoConocimiento)
  if(payload?.AccesoConocimiento?.ambitoComunitario) acc.push('Ambito comunitario.')
  if(payload?.AccesoConocimiento?.derivadosOSimilares) acc.push('Derivados o similares.')
  if(payload?.AccesoConocimiento?.etnozoologicos) acc.push('Etnozoologicos.')
  if(payload?.AccesoConocimiento?.medicinaTradicional) acc.push('Medicina tradeicional.')
  if(payload?.AccesoConocimiento?.practicasAncestrales) acc.push('Prácticas ancestrales.')
  if(payload?.AccesoConocimiento?.recursosBiologico) acc.push('Recursos biológicos.')
  if(payload?.AccesoConocimiento?.valoresReligiososCultutrales) acc.push('Valores religiosos culturales.')

  const result =  {
    '<<sistema.identificadorInformeTecnicoMaate>>':solicitudId,
    '<<autorizacion.vigencia>>': payload?.Propuesta?.plazo,
    '<<solicitante.identificador>>': solicitudId,
    '<<solicitante.idAcreditacion>>': payload?.Solicitante?.registro,
    '<<institucionApoyo.razonSocial>>': payload?.Propuesta?.apoyo,
    '<<solicitud.areaInvestigacion>>': payload?.Propuesta?.areaInvestigacion,
    '<<recursos.listaRecursos>>': recs.join(', '),
    '<<ubicacion.listaProvincias>>': payload?.RecursosInSitu?.provincias?.join(', '),
    '<<ubicacion.listaAreasProtegidas>>': payload?.RecursosInSitu?.areasProtegidas?.join(', '),
    '<<ubicacion.listaBosquesProtectores>>': payload?.RecursosInSitu?.bosquesProtectores?.join(', '),
    '<<solicitud.accesoConocimientoTradicional>>': acc?.join(' '),
    '<<plazoProyecto>>': payload?.Propuesta?.plazo + ' meses',
    '<<sistema.identificadorDictamenTecnicoMaate>>':solicitudId,
    '<<sistema.identificadorIDictamenTecnicoSenadi>>':solicitudId,
    '<<sistema.identificadorInformeTecnicoSenescyt>>':solicitudId,
    '<<sistema.identificadorResolucionSenescyt>>':solicitudId,
    '<<sistema.fechaTexto>>':format(new Date(), 'dd MMMM yyyy', {locale: es}),
    '<<sistema.fecha>>':format(new Date(), 'dd MMMM yyyy', {locale: es}),
    '<<solicitud.identificador>>':solicitudId,
    '<<solicitante.nombre>>':payload?.Solicitante?.nombresCompletos,
    '<<solicitud.nombreProyecto>>':payload?.Propuesta?.nombre,
    '<<solicitud.plazoProyecto>>':payload?.Propuesta?.plazo,
    '<<patrocinador>>':payload?.Propuesta?.apoyo,
    '<<sistema.nombreAutorizadorPrincipalSenescyt>>': aprueba?.nombreUsuario ?? '',
    '<<sistema.rolAutorizadorPrincipalSenescyt>>': aprueba?.nombrePerfil ?? '',
    '<<sistema.cargoAutorizadorPrincipalSenescyt>>': aprueba?.usuarioCargoUsuario?.toUpperCase() ?? '',
    '<<sistema.nombreAutorizadorSectorialMaate>>':coordinador?.nombreUsuario ?? '',
    '<<sistema.rolAutorizadorSectorialMaate>>':coordinador?.nombrePerfil ?? '',
    '<<usuario.cargoAutorizadorSectorialMaate>>':coordinador?.descripcionPerfil ?? '',
    '<<sistema.nombreCoordinadorCasosSenescyt>>':coordinador?.nombreUsuario ?? '',
    '<<sistema.rolCoordinadorCasosSenescyt>>':coordinador?.nombrePerfil ?? '',
    '<<sistema.cargoCoordinadorCasosSenescyt>>':coordinador?.usuarioCargoUsuario?.toUpperCase()  ?? '',
    '<<sistema.telefono>>':coordinador?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<sistema.correoElectronico>>':coordinador?.correoUsuario ?? '',
    '<<firman>>': JSON.stringify([
      {accion: 'Elaborado por',nombre:perfilUsuario?.usuario?.nombreUsuario ?? '', cargo:perfilUsuario?.perfil?.nombrePerfil ?? ''},
      {accion: 'Revisado por',nombre:coordinador?.nombreUsuario ?? '', cargo:coordinador?.usuarioCargoUsuario ?? ''},
      {accion: 'Aprobado por',nombre:aprueba?.nombreUsuario ?? '', cargo:aprueba?.usuarioCargoUsuario?.toUpperCase()},
    ]),
    '<<usuario.nombre>>':perfilUsuario?.usuario?.nombreUsuario ?? '',
    '<<usuario.rol>>':perfilUsuario?.perfil?.nombrePerfil ?? '',
    // '<<usuario.cargo>>':perfilUsuario?.perfil?.descripcionPerfil ?? '',
    '<<usuario.telefono>>':perfilUsuario?.usuario?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<usuario.correoElectronico>>':perfilUsuario?.usuario?.correoUsuario ?? '',
    '<<sistema.nombreAutoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '',
    '<<sistema.rolAutoridadSenescyt>>': autoridadSenescyt?.nombrePerfil ?? '',
    '<<sistema.cargoAutoridadSenescyt>>': autoridadSenescyt?.descripcionPerfil ?? '',
    '<<sistema.autoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '',
    '<<solicitud.objetivoProyecto>>': f.isValid(payload?.Propuesta?.objetivo)?payload?.Propuesta?.objetivo:'**',
    '<<serial>>': vars.payload.P03PropuestaNegociacionInabio.serial,
    '<<observaciones>>': vars.payload.P03PropuestaNegociacionInabio.observacionesReporte?.join('{:}')
  }

  return result
}
