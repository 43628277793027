import { createSlice } from '@reduxjs/toolkit'

const state0 = {
  msgTest: null,
  funcionarios: [],
  investigadores: [],
  funcionario: null,
  investigador: null,
  modalPerfiles: false,
  perfilesOrganizacion: [],
  perfiles: [],
  provincias: [],
  bosques: [],
  areasProtegidas: [],
  seleccionadoBosque: null,
  seleccionadoArea: null,
  modalEditarBosques: false,
  editando: false,
  tipoInstituto: null,
  ies: [],
  ipi: [],
  its: [],
  seleccionadoInstituto: null,
  modalInstitutos: false,
  procesos: [],
  tareas: [],
  firmas: [],
  roles: [],
  orden: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  ordenSeleccionado: false,
  seleccionadoFirma: null,
  solicitudes: [],
  seguimientoProcesos: [],
  plantillas: [],
  seleccionadoPlantilla: null,
  modalPlantilla: false,
  areas: [],
  seleccionadaAreaInvestigacion: null,
  modalAreaInvestigacion: false,
  lineas: [],
  seleccionadaLinea: null,
  modalLineas: false,
  modalContratos: false
}

export const sliceAdministrador = createSlice({
  name: "administrador",
  initialState: { ...state0 },
  reducers: {
    setMsgTest: (state, action) => {
      state.clpis = action.payload
    },
    setFuncionarios: (state, action) => {
      state.funcionarios = [...action.payload]
    },
    setSeleccionarFuncionario: (state, action) => {
      state.funcionario = state.funcionarios.filter(e => (e.id === action.payload))
    },
    noSeleccionado: (state, action) => {
      state.funcionario = null
      state.perfiles = []
      state.perfilesOrganizacion = []
      state.investigador = null
      state.seleccionadoBosque = null
      state.seleccionadoArea = null
      state.seleccionadoInstituto = null
      state.tareas = []
      state.orden = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    abrirModal: (state, action) => {
      state.modalPerfiles = true
    },
    cerrarModal: (state, action) => {
      state.modalPerfiles = false
      state.perfiles = []
    },
    setInvestigadores: (state, action) => {
      state.investigadores = [...action.payload]
    },
    setPerfiles: (state, action) => {
      state.perfiles = [...action.payload]
    },
    setPerfilesOrganizacion: (state, action) => {
      state.perfilesOrganizacion = [...action.payload]
    },
    setSeleccionarInvestigador: (state, action) => {
      state.investigador = state.investigadores.filter(e => (e.id === action.payload))
    },
    setProvincias: (state, action) => {
      state.provincias = [...action.payload]
    },
    setListaBosques: (state, action) => {
      state.bosques = [...action.payload]
    },
    setListaAreasProtegidas: (state, action) => {
      state.areasProtegidas = [...action.payload]
    },
    setSeleccionadoBosque: (state, action) => {
      state.seleccionadoBosque = state.bosques.filter(e => (e.id === action.payload))
    },
    setSeleccionadoArea: (state, action) => {
      state.seleccionadoArea = state.areasProtegidas.filter(e => (e.id === action.payload))
    },
    openModalEditarBosques: (state, action) => {
      state.modalEditarBosques = true
    },
    closeModalBosques: (state, action) => {
      state.modalEditarBosques = false
      state.editando = false
    },
    setEditando: (state, action) => {
      state.editando = true
    },
    setIes: (state, action) => {
      state.ies = action.payload
    },
    setIpi: (state, action) => {
      state.ipi = action.payload
    },
    setIts: (state, action) => {
      state.its = action.payload
    },
    setTipoInstituto: (state, action) => {
      state.tipoInstituto = action.payload
    },
    setSeleccionadoIes: (state, action) => {
      state.seleccionadoInstituto = state.ies.filter(e => (e.id === action.payload))
    },
    setSeleccionadoIpi: (state, action) => {
      state.seleccionadoInstituto = state.ipi.filter(e => (e.id === action.payload))
    },
    setSeleccionadoIts: (state, action) => {
      state.seleccionadoInstituto = state.its.filter(e => (e.id === action.payload))
    },
    openModalInstitutos: (state, action) => {
      state.modalInstitutos = true
    },
    closeModalInstitutos: (state, action) => {
      state.modalInstitutos = false
      state.editando = false
    },
    setProcesos: (state, action) => {
      state.procesos = action.payload
    },
    setTareas: (state, action) => {
      state.tareas = action.payload
    },
    setFirmas: (state, action) => {
      state.firmas = action.payload
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    },
    setOrden: (state, action) => {
      state.orden = state.orden.filter(e => (e !== action.payload))
    },
    setOrdenSeleccionado: (state, action) => {
      state.ordenSeleccionado = action.payload
    },
    setFirmaSeleccionada: (state, action) => {
      state.seleccionadoFirma = state.firmas.filter(e => (e.id === action.payload))
    },
    noFirmaSeleccionada: (state, action) => {
      state.seleccionadoFirma = null
    },
    cleanFirmas: (state, action) => {
      state.firmas = []
      state.orden = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    restoreOrden: (state, action) => {
      state.orden = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    setSolicitudes: (state, action) => {
      state.solicitudes = action.payload
    },
    setSeguimientoProcesos: (state, action) => {
      state.seguimientoProcesos = action.payload
    },
    setPlantillas: (state, action) => {
      state.plantillas = action.payload
    },
    setPlantillaSeleccionada: (state, action) => {
      state.seleccionadoPlantilla = action.payload
    },
    noPlantillaSeleccionada: (state, action) => {
      state.seleccionadoPlantilla = null
    },
    openModalPlantilla: (state, action) => {
      state.modalPlantilla = true
    },
    closeModalPlantilla: (state, action) => {
      state.modalPlantilla = false
    },
    setAreas: (state, action) => {
      state.areas = [...action.payload]
    },
    setAreaInvestigacionSeleccionada: (state, action) => {
      state.seleccionadaAreaInvestigacion = state.areas.filter(e => (e.id === action.payload))
    },
    openModalAreaInvestigacion: (state, action) => {
      state.modalAreaInvestigacion = true
    },
    closeModalAreaInvestigacion: (state, action) => {
      state.modalAreaInvestigacion = false
    },
    noAreaInvestigacionSeleccionada: (state, action) => {
      state.seleccionadaAreaInvestigacion = null
    },
    setLineas: (state, action) => {
      state.lineas = [...action.payload]
    },
    setLineaSeleccionada: (state, action) => {
      state.seleccionadaLinea = state.lineas.filter(e => (e.id === action.payload))
    },
    noLineaSeleccionada: (state, action) => {
      state.seleccionadaLinea = null
    },
    openModalLineas: (state, action) => {
      state.modalLineas = true
    },
    closeModalLineas: (state, action) => {
      state.modalLineas = false
    },
    cleanLineas: (state, action) => {
      state.lineas = []
    },
    openModalContratos: (state, action) => {
      state.modalContratos = action.payload
    },
  }
})

export const {
  setMsgTest,
  setFuncionarios,
  setSeleccionarFuncionario,
  noSeleccionado,
  abrirModal,
  cerrarModal,
  setInvestigadores,
  setPerfiles,
  setPerfilesOrganizacion,
  setSeleccionarInvestigador,
  setProvincias,
  setListaBosques,
  setListaAreasProtegidas,
  setSeleccionadoBosque,
  setSeleccionadoArea,
  openModalEditarBosques,
  closeModalBosques,
  setEditando,
  setIes,
  setIpi,
  setIts,
  setTipoInstituto,
  setSeleccionadoIes,
  setSeleccionadoIpi,
  setSeleccionadoIts,
  openModalInstitutos,
  closeModalInstitutos,
  setProcesos,
  setTareas,
  setFirmas,
  setRoles,
  setOrden,
  setOrdenSeleccionado,
  setFirmaSeleccionada,
  noFirmaSeleccionada,
  cleanFirmas,
  restoreOrden,
  setSolicitudes,
  setSeguimientoProcesos,
  setPlantillas,
  setPlantillaSeleccionada,
  noPlantillaSeleccionada,
  openModalPlantilla,
  closeModalPlantilla,
  setAreas,
  setAreaInvestigacionSeleccionada,
  openModalAreaInvestigacion,
  closeModalAreaInvestigacion,
  noAreaInvestigacionSeleccionada,
  setLineas,
  setLineaSeleccionada,
  noLineaSeleccionada,
  openModalLineas,
  closeModalLineas,
  cleanLineas,
  openModalContratos
} = sliceAdministrador.actions

// THUNKS !!

export const cargarFuncionarios = (funcionarios) => {
  return (dispatch) => {
    dispatch(setFuncionarios(funcionarios))
  }
}

export const seleccionarFuncionario = (funcionario) => {
  return (dispatch) => {
    dispatch(setSeleccionarFuncionario(funcionario));
  }
}

export const quitarSeleccion = () => {
  return (dispatch) => {
    dispatch(noSeleccionado());
  }
}

export const abrirModalPerfiles = () => {
  return (dispatch) => {
    dispatch(abrirModal());
  }
}

export const cerrarModalPerfiles = () => {
  return (dispatch) => {
    dispatch(cerrarModal());
  }
}

export const cargarInvestigadores = (investigadores) => {
  return (dispatch) => {
    dispatch(setInvestigadores(investigadores));
  }
}

export const cargarPerfiles = (perfiles) => {
  return (dispatch) => {
    dispatch(setPerfiles(perfiles));
  }
}

export const cargarPerfilesOrganizacion = (perfiles) => {
  return (dispatch) => {
    dispatch(setPerfilesOrganizacion(perfiles))
  }
}

export const seleccionarInvestigador = (investigador) => {
  return (dispatch) => {
    dispatch(setSeleccionarInvestigador(investigador));
  }
}

export const cargarProvincias = (provincias) => {
  return (dispatch) => {
    dispatch(setProvincias(provincias))
  }
}

export const cargarListaAreasProtegidas = (area) => {
  return (dispatch) => {
    dispatch(setListaAreasProtegidas(area))
  }
}

export const cargarListaBosques = (bosque) => {
  return (dispatch) => {
    dispatch(setListaBosques(bosque))
  }
}

export const seleccionarBosque = (bosque) => {
  return (dispatch) => {
    dispatch(setSeleccionadoBosque(bosque))
  }
}
export const seleccionarArea = (area) => {
  return (dispatch) => {
    dispatch(setSeleccionadoArea(area))
  }
}

export const abrirModalBosques = () => {
  return (dispatch) => {
    dispatch(openModalEditarBosques())
  }
}

export const cerrarModalBosques = () => {
  return (dispatch) => {
    dispatch(closeModalBosques())
  }
}

export const editar = () => {
  return (dispatch) => {
    dispatch(setEditando());
  }
}

export const cargarIes = (ies) => {
  return (dispatch) => {
    dispatch(setIes(ies))
  }
}

export const cargarIpi = (ipi) => {
  return (dispatch) => {
    dispatch(setIpi(ipi))
  }
}

export const cargarIts = (its) => {
  return (dispatch) => {
    dispatch(setIts(its))
  }
}

export const seleccionarInstituto = (instituto, tipo) => {
  return (dispatch) => {
    if (tipo === 1) {
      dispatch(setSeleccionadoIes(instituto))
    }
    if (tipo === 2) {
      dispatch(setSeleccionadoIpi(instituto))
    }
    if (tipo === 3) {
      dispatch(setSeleccionadoIts(instituto))
    }
  }
}

export const abrirModalInstitutos = () => {
  return (dispatch) => {
    dispatch(openModalInstitutos());
  }
}

export const cerrarModalInstitutos = () => {
  return (dispatch) => {
    dispatch(closeModalInstitutos());
  }
}

export const seleccionarTipoInstituto = (tipo) => {
  return (dispatch) => {
    dispatch(setTipoInstituto(tipo))
  }
}

export const cargarProcesos = (procesos) => {
  return (dispatch) => {
    dispatch(setProcesos(procesos));
  }
}

export const cargarTareas = (tareas) => {
  return (dispatch) => {
    dispatch(setTareas(tareas));
  }
}

export const cargarFirmas = (firmas) => {
  return (dispatch) => {
    dispatch(setFirmas(firmas));
  }
}

export const cargarRoles = (roles) => {
  return (dispatch) => {
    dispatch(setRoles(roles));
  }
}

export const cargarOrden = (orden) => {
  return (dispatch) => {
    dispatch(setOrden(orden));
  }
}

export const seleccionarOrden = (estado) => {
  return (dispatch) => {
    dispatch(setOrdenSeleccionado(estado))
  }
}

export const seleccionarFirma = (firma) => {
  return (dispatch) => {
    dispatch(setFirmaSeleccionada(firma))
  }
}

export const quitarFirmaSeleccionada = () => {
  return (dispatch) => {
    dispatch(noFirmaSeleccionada())
  }
}
export const limpiarFirmas = () => {
  return (dispatch) => {
    dispatch(cleanFirmas())
  }
}

export const restaurarOrden = () => {
  return (dispatch) => {
    dispatch(restoreOrden())
  }
}

export const cargarSolicitudes = (procesos) => {
  return (dispatch) => {
    dispatch(setSolicitudes(procesos))
  }
}

export const cargarSeguimientoProcesos = (procesos) => {
  return (dispatch) => {
    dispatch(setSeguimientoProcesos(procesos))
  }
}

export const cargarPlantillas = (plantillas) => {
  return (dispatch) => {
    dispatch(setPlantillas(plantillas))
  }
}

export const seleccionarPlantilla = (plantilla) => {
  return (dispatch) => {
    dispatch(setPlantillaSeleccionada(plantilla))
  }
}

export const quitarPlantillaSeleccionada = () => {
  return (dispatch) => {
    dispatch(noPlantillaSeleccionada())
  }
}

export const abrirModalPlantilla = () => {
  return (dispatch) => {
    dispatch(openModalPlantilla())
  }
}

export const cerrarModalPlantilla = () => {
  return (dispatch) => {
    dispatch(closeModalPlantilla())
  }
}

export const cargarAreas = (areas) => {
  return (dispatch) => {
    dispatch(setAreas(areas))
  }
}

export const seleccionarAreaInvestigacion = (areas) => {
  return (dispatch) => {
    dispatch(setAreaInvestigacionSeleccionada(areas))
  }
}

export const abrirModalAreaInvestigacion = () => {
  return (dispatch) => {
    dispatch(openModalAreaInvestigacion())
  }
}

export const cerrarModalAreaInvestigacion = () => {
  return (dispatch) => {
    dispatch(closeModalAreaInvestigacion())
  }
}

export const quitarAreaInvestigacion = () => {
  return (dispatch) => {
    dispatch(noAreaInvestigacionSeleccionada())
  }
}

export const cargarLineas = (lineas) => {
  return (dispatch) => {
    dispatch(setLineas(lineas))
  }
}

export const seleccionarLinea = (linea) => {
  return (dispatch) => {
    dispatch(setLineaSeleccionada(linea))
  }
}

export const quitarLineaSeleccionada = () => {
  return (dispatch) => {
    dispatch(noLineaSeleccionada())
  }
}

export const abrirModalLineas = () => {
  return (dispatch) => {
    dispatch(openModalLineas())
  }
}

export const cerrarModalLineas = () => {
  return (dispatch) => {
    dispatch(closeModalLineas())
  }
}

export const limpiarLineas = () => {
  return (dispatch) => {
    dispatch(cleanLineas())
  }
}

export const abrirModalContratos = (action) => {
  return (dispatch) => {
    dispatch(openModalContratos(action))
  }
}