import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Button, ButtonGroup, InputLabel, Select } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { abrirModalInstitutos, cargarIes, cargarIpi, cargarIts, quitarSeleccion, seleccionarInstituto, seleccionarTipoInstituto } from './sliceAdministrador';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import Swal from 'sweetalert2';
import { dialogoInformativo, retornaIesIpiIts } from './API';
import { ToolBarInstitutos } from './ToolBarInstitutos';
import { ModalInstitutos } from './ModalInstitutos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useNavigate } from 'react-router';

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    esES,
);

const columns = [
    {
        field: 'id',
        headerName: 'Código',
        width: 120,
        editable: false,
    },
    {
        field: 'nombreIes',
        headerName: 'Nombre',
        width: 600,
        editable: false,
    },
];

const columnsIpi = [
    {
        field: 'id',
        headerName: 'Código',
        width: 120,
        editable: false,
    },
    {
        field: 'nombreIpi',
        headerName: 'Nombre',
        width: 600,
        editable: false,
    },
];

const columnsIts = [
    {
        field: 'id',
        headerName: 'Código',
        width: 120,
        editable: false,
    },
    {
        field: 'nombreIts',
        headerName: 'Nombre',
        width: 600,
        editable: false,
    },
    {
        field: 'tipo',
        headerName: 'Tipo',
        width: 200,
        editable: false,
    },
];

const textoEsp = {
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Seleccionar columnas',
    columnsPanelTextFieldLabel: 'Buscar columna',
    columnsPanelTextFieldPlaceholder: 'Columna...',
    columnsPanelDragIconLabel: 'Reordenar',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',
    toolbarFilters: 'Filtros',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelOperators: 'Operador',
    filterPanelInputPlaceholder: 'Buscar...',
    toolbarDensity: 'Tamaño',
    toolbarDensityLabel: 'Tamaño',
    toolbarDensityCompact: 'Pequeño',
    toolbarDensityStandard: 'Mediano',
    toolbarDensityComfortable: 'Largo',
    toolbarExport: 'Imprimir',
    toolbarExportLabel: 'Imprimir',
    toolbarExportCSV: 'Descargar como CSV',
    toolbarExportPrint: 'Imprimir como PDF',
    toolbarExportExcel: 'Download as Excel',
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'igual',
    filterOperatorStartsWith: 'empieza con',
    filterOperatorEndsWith: 'termina con',
    filterOperatorIs: 'es',
    filterOperatorNot: 'no es',
    filterOperatorIsEmpty: 'ninguno',
    filterOperatorIsNotEmpty: 'todos',
    filterOperatorIsAnyOf: 'cualquiera de '
}

const botonAzul = {
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
        backgroundColor: 'rgba(54, 138, 184, 1)',
    }
}

const botonVerde = {
    ml: 2,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(14, 181, 14, 1)',
    "&:hover": {
        backgroundColor: 'rgba(18, 219, 18, 1)',
    }
}

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton />
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </GridToolbarContainer>
    );
}

export const TablaInstitutos = () => {

    const dispatch = useDispatch();
    const [pageSize, setPageSize] = React.useState(5);
    const [tip, setTip] = React.useState('');
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [cabecera, setCabecera] = React.useState(columns)
    const { ies, ipi, its, seleccionadoInstituto } = useSelector(state => state.administracion)
    const navigate = useNavigate();
    let timerInterval

    React.useEffect(() => {
        dispatch(cargarIes([]));
        dispatch(cargarIpi([]));
        dispatch(cargarIts([]));
        dispatch(seleccionarTipoInstituto(null));
        dispatch(quitarSeleccion());
    }, [dispatch])

    React.useEffect(() => {
        if (!seleccionadoInstituto) {
            setSelectionModel([]);
        }
    }, [seleccionadoInstituto])

    const handleInstituto = (seleccionados) => {
        if (seleccionados.length === 1) {
            dispatch(seleccionarInstituto(seleccionados[0], tip))
        } else {
            dispatch(quitarSeleccion());
        }
    }

    const handleBuscar = () => {
        tip !== '' ?
            (
                Swal.fire({
                    title: 'Cargando...',
                    html: 'Espere porfavor',
                    timer: 1000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                    dispatch(retornaIesIpiIts(tip))
                }))
            :
            dispatch(dialogoInformativo("Seleccione un tipo!"))
    }

    const handleChangeTipo = (event) => {
        dispatch(quitarSeleccion());
        dispatch(cargarIes([]))
        dispatch(cargarIpi([]))
        dispatch(cargarIts([]))
        dispatch(seleccionarTipoInstituto(event.target.value))
        setTip(event.target.value);

        if (event.target.value === 1) {
            setCabecera(columns)
        }
        if (event.target.value === 2) {
            setCabecera(columnsIpi)
        }
        if (event.target.value === 3) {
            setCabecera(columnsIts)
        }
    };

    const handleNuevoIes = () => {
        dispatch(abrirModalInstitutos());
    }

    const handleNuevoIpi = () => {
        dispatch(abrirModalInstitutos());
    }

    const handleNuevoIts = () => {
        dispatch(abrirModalInstitutos());
    }

    const handleRegresar = () => {
        navigate('/administracion');
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Box sx={{
                    height: 406, width: '100%'
                }}>
                    <FormControl sx={{ minWidth: 200, ml: 7, mt: 5 }}>
                        <InputLabel id="demo-simple-select-helper-label">Tipo</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={tip}
                            label="Tipo"
                            onChange={handleChangeTipo}
                            displayEmpty
                        >
                            <MenuItem key={1} value={1}>{'IES'}</MenuItem>
                            <MenuItem key={2} value={2}>{'IPI'}</MenuItem>
                            <MenuItem key={3} value={3}>{'ITS'}</MenuItem>

                        </Select>
                    </FormControl>

                    <ButtonGroup variant="contained" sx={{ ml: 5, mt: 6 }} aria-label="primary button group">
                        <Button variant="contained" onClick={handleBuscar} startIcon={<SearchIcon />}
                            sx={botonAzul}
                        >
                            Buscar
                        </Button>
                        {tip === 1 && <Button color='success' onClick={handleNuevoIes} startIcon={<AddCircleOutlineIcon />}
                            sx={botonVerde}
                        >
                            Nuevo IES
                        </Button>}
                        {tip === 2 && <Button color='success' onClick={handleNuevoIpi} startIcon={<AddCircleOutlineIcon />}
                            sx={botonVerde}
                        >
                            Nuevo IPI
                        </Button>}
                        {tip === 3 && <Button color='success' onClick={handleNuevoIts} startIcon={<AddCircleOutlineIcon />}
                            sx={botonVerde}
                        >
                            Nuevo ITS
                        </Button>}
                    </ButtonGroup>

                    <ToolBarInstitutos tipo={tip} />

                    {((tip === 1 || tip === '') &&

                        <DataGrid
                            rows={ies}
                            columns={cabecera}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10]}
                            checkboxSelection
                            onSelectionModelChange={(newSelectionModel) => {
                                if (newSelectionModel.length > 1) {
                                    const selectionSet = new Set(selectionModel);
                                    const result = newSelectionModel.filter((s) => !selectionSet.has(s));
                                    setSelectionModel(result);
                                    handleInstituto(result);
                                } else {
                                    setSelectionModel(newSelectionModel);
                                    handleInstituto(newSelectionModel);
                                }
                            }}
                            selectionModel={selectionModel}
                            disableSelectionOnClick
                            disableColumnMenu
                            experimentalFeatures={{ newEditingApi: true }}
                            localeText={textoEsp}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    )
                        ||
                        (
                            tip === 2 &&
                            <DataGrid
                                rows={ipi}
                                columns={cabecera}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowsPerPageOptions={[5, 10]}
                                checkboxSelection
                                onSelectionModelChange={(newSelectionModel) => {
                                    if (newSelectionModel.length > 1) {
                                        const selectionSet = new Set(selectionModel);
                                        const result = newSelectionModel.filter((s) => !selectionSet.has(s));
                                        setSelectionModel(result);
                                        handleInstituto(result);
                                    } else {
                                        setSelectionModel(newSelectionModel);
                                        handleInstituto(newSelectionModel);
                                    }
                                }}
                                selectionModel={selectionModel}
                                disableSelectionOnClick
                                disableColumnMenu
                                experimentalFeatures={{ newEditingApi: true }}
                                localeText={textoEsp}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                        )
                        ||
                        (
                            tip === 3 &&
                            <DataGrid
                                rows={its}
                                columns={cabecera}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowsPerPageOptions={[5, 10]}
                                checkboxSelection
                                onSelectionModelChange={(newSelectionModel) => {
                                    if (newSelectionModel.length > 1) {
                                        const selectionSet = new Set(selectionModel);
                                        const result = newSelectionModel.filter((s) => !selectionSet.has(s));
                                        setSelectionModel(result);
                                        handleInstituto(result);
                                    } else {
                                        setSelectionModel(newSelectionModel);
                                        handleInstituto(newSelectionModel);
                                    }
                                }}
                                selectionModel={selectionModel}
                                disableSelectionOnClick
                                disableColumnMenu
                                experimentalFeatures={{ newEditingApi: true }}
                                localeText={textoEsp}
                                components={{
                                    Toolbar: CustomToolbar,
                                }}
                            />
                        )
                    }

                </Box>
            </ThemeProvider>
            <ModalInstitutos tipo={tip} />

            <ButtonGroup variant="contained" sx={{ ml: 5, mt: 25 }} aria-label="outlined primary button group">
                <Button variant="contained" onClick={handleRegresar} startIcon={<ArrowCircleLeftOutlinedIcon />}
                    sx={botonAzul}
                >
                    Regresar
                </Button>
            </ButtonGroup>
        </>
    );
}
