import {format, parse, add} from 'date-fns'
import {es} from 'date-fns/locale'

export const f = {}

f.isArray = (anyThing) => Object.prototype.toString.call(anyThing) === '[object Array]'

f.isString = (anyThing) => typeof anyThing === 'string'

f.isValid = (anyThing) => (anyThing !== undefined) && (anyThing !== null)

f.isValidNotEmpty = (anyThing) => (anyThing !== undefined) && (anyThing !== null) && (anyThing !== '')

f.isInvalid = (anyThing) => (anyThing === undefined) && (anyThing === null)

f.isObject = (anyThing) => Object.prototype.toString.call(anyThing) === '[object Object]'

f.whatIs = (anyThing) => Object.prototype.toString.call(anyThing).split(/\W/)[2]

f.numberInRange = (anyThing, rangeMin, rangeMax) => {
  const isNumber = typeof anyThing === 'number' && isFinite(anyThing)
  if(isNumber) {
    if(anyThing > rangeMax) return rangeMax
    else if(anyThing < rangeMin) return rangeMin
    else return anyThing
  } else {
    return rangeMin
  }
}

f.isPhone = (anything) => {
  const regex = new RegExp(/^[\+]?[(]?[0-9]{0,3}[)]?[-\s\.]?[0-9]{0,3}[-\s\.]?[0-9]{0,6}$/im)
  // const regex = new RegExp(/^\d+$/im)
  return regex.test(anything)
}

f.isMail = (anything) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(anything)
}

f.numberToString = (anything) => (typeof anything === 'number')?`${anything}`:anything

f.StringOrEmpty = (anything) => (typeof anything === 'string')?anything:(typeof anything === 'number')?`${anything}`:''

f.string2json = (anything, alt) => {
  if((typeof anything) !== undefined) {
    try {
      return JSON.parse(anything)
    } catch (e) {
      return JSON.parse(alt)
    }
  } else
    return JSON.parse(alt)
}

f.delay = (time=1000) => {
  return new Promise(resolve => setTimeout(resolve, time));
}

f.parseDate = (strDate, format='dd-MMMM-yyyy') => {
  return parse(strDate, format, new Date(), {locale: es})
}

f.newId = (arr = []) => {
  const arrIds = arr.map(it => it.id)
  if(arrIds.length === 0) {
    return 1
  } else {
    return Math.max(...arrIds) + 1
  }
}

f.parseDateAddMonths = (strDate, plazo, format='dd-MMMM-yyyy') => {
  const myDate = parse(strDate, format, new Date(), {locale: es})
  const meses = Number(plazo.split(' ')[0])

  console.log()
}
