import React, {useEffect, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {add, format, parse} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import API from "src/features/App/API";
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from "src/features/App/sliceApp";
import {MySendButton} from 'src/components/MySendButton'
import {MySubtitle} from 'src/components/MySubtitle'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import {SectionTitle} from "../../components/SectionTitle";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {AccountCircle} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MyButtonBacan} from "../../components/MyButtonBacan";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Toolbar from "@mui/material/Toolbar";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {f} from "../../commons";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "./P0102PayloadRO";
import P0102API from './API'
import GLOBALS, {ENV} from 'src/features/App/globals'
import {P0102Expediente} from "./P0102Expediente";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const section = 'ElaborarPermiso'
  const dispatch = useDispatch()
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const tareas =  useSelector(state => state.wf01.tareas)
  // const nombre_tarea = tareas.filter(it => it.codigo_tarea === instanciaTarea.tareaCodigoTarea)[0].nombre_tarea
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  const plantillaId = '56015023-1'
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:           today,
    serial:          '',
    identificador:   solicitud.numeroSolicitud,
    tiempoVigencia:  '' + payload.Propuesta.plazo + ' meses',
    docx:            '',
    pdf:             '',
    docxLded:        '',
    pdfLded:         '',
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const dResolucion = parse(slct.payload.Resolucion.fecha,'dd-MMMM-yyyy', new Date(), {locale: es})
  const plazo = Math.abs(Number(slct.payload.Propuesta.plazo))
  const tpartes = Math.trunc(plazo/12.0)
  let fechas=[`Fecha inicial: ${format(dResolucion, 'dd-MMM-yyyy', {locale: es})}`]
  for(let i=1; i<= tpartes; i++){
    fechas.push(`Reporte ${i}: ${format(add(dResolucion, {months: i * 12}), 'dd-MMM-yyyy', {locale: es})}` )
  }
  const dPlazo = add(dResolucion, {months: plazo})
  fechas.push(`Reporte final: ${format(dPlazo, 'dd-MMM-yyyy', {locale: es})}`)
  payload.fechas = fechas.join('{|}')
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {setCounter(counter + 1)}, [formValues])
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `permiso-${nombreOrganizacion}`
  const filename = `permiso-${formValues.identificador}-${nombreOrganizacion}`
  // const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  // const filenamePDFuploaded = `${filename}-upld.pdf`
  // const filenamePDFsigned = `${filename}-firmado.pdf`
  const funcionarios = useSelector(state => state.app.funcionarios)
  const autorizador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const autoridadSenescyt = funcionarios.filter(it => it.idPerfil === 1189)[0]

  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    };
  }
  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Elaborar permiso de investigación'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="Solicitud modificatoria" value="2" />
              <Tab label="Expediente modificatorio" value="4" />
              {(f.isValid(payload?.solicitudOriginal))?<Tab label="Solicitud original" value="3" />:false}
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Expediente original`} value="6"/> : null
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={accordeonBox.container2}>
              <Solicitante solicitud={{solicitud: {payload}}} displayContact={false} />
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Resolución'} />
              </Grid>
              <Grid container spacing={1} sx={{margin: '0 2rem 0 0'}}>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                    <MyReadOnlyTextField id='identificador'
                                         label={'Identificador'}
                                         value={solicitud?.numeroSolicitud}
                                         icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                    <MyReadOnlyTextField id='fecha'
                                         label={'Fecha'}
                                         value={slct?.payload?.Resolucion?.fecha}
                                         icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                    <MyButtonBacan label={'VER PDF'}
                                   onClick={() => API.fetchDownloadPDF({
                                     solicitudId:instanciaProceso?.solicitud?.id,
                                     subfolder:`resolucion-${nombreOrganizacion}`,
                                     filename:`resolucion-${solicitud.numeroSolicitud}-${nombreOrganizacion}-firmado.pdf`
                                   })}
                                   myTip={'Ver resolución'}
                                   icon={FileDownloadOutlinedIcon} />
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Permiso'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 24px 0 0.5rem'}}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                  <MyReadOnlyTextField id='identificador'
                                       label={'Identificador'}
                                       value={solicitud?.numeroSolicitud}
                                       icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                  <MyReadOnlyTextField id='fecha'
                                       label={'Fecha de emisión'}
                                       value={format(dResolucion, 'dd-MMM-yyyy', {locale: es})}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                  <MyReadOnlyTextField id='plazo'
                                       label={'Vigencia'}
                                       value={`${plazo} meses`}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                  <MyReadOnlyTextField id='fechaCaducidad'
                                       label={'Fecha de caducidad'}
                                       value={format(dPlazo, 'dd-MMM-yyyy', {locale: es})}
                                       icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                </Stack>
              </Grid>
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 0 0 8rem'}}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = 'AUTIC'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave({...formValues, serial:serial})()
                                            setFormValues({...formValues, serial:serial})
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== ''  &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',
                                          // serial: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar Informe'}
                                      width={'11rem'}
                                      onClick={() => {
                                        API.fetchPlantila(plantillaId).then(taggeDoc => {
                                          const doc = MyTagged2Docx(
                                            // taggeDoc?.contenidoPlantilla,
                                            plantilla,
                                            nombreOrganizacion,
                                            P0102API.mapping({
                                              solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                              payload,
                                              autorizador,
                                              coordinador,
                                              perfilUsuario,
                                              nombreOrganizacion,
                                              autoridadSenescyt,
                                              section: payload[section],
                                            }))
                                          API.genDocxAnPdf({
                                            solicitudId: instanciaProceso?.solicitud?.id,
                                            document: doc,
                                            formValues,
                                            setFormValues,
                                            subfolder,
                                            filename: filenameDOCX,
                                            nombreOrganizacion,
                                          })
                                        })
                                      }}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                      })}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })}
                                      width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={() => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                              } else {
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                              }
                                            })
                                          }}
                                          width={'5rem'} />
                    </ButtonGroup>
                  }
                </Stack>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={payload}/>
          </TabPanel>
          <TabPanel value="4">
            <P0102Expediente payload={payload}/>
          </TabPanel>
          {(f.isValid(payload?.solicitudOriginal)) ?
            <TabPanel value="3">
              <P0102PayloadRO payload={payload?.solicitudOriginal?.payloadSolicitud}/>
            </TabPanel> : false
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="6">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyGobackButton onGoback={() => dispatch(handleClear())} />
                  <MySaveButton onSave={getOnSave()}
                                disabled={counter <= 0 } />
                  <MySendButton onSend={() => {
                                  const metadata = JSON.stringify({
                                    "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    // "perfilUsuarioSolicitud": Number(slct?.perfilUsuarioId),
                                  })
                                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                disabled={counter > 0 || formValues.docx === '' || formValues.pdf === ''}
                                label={'enviar'} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}

const plantilla =
  "${TITULO1}PERMISO DE INVESTIGACIÓN CIENTÍFICA DE LA BIODIVERSIDAD" +
  "${SALTO}${CENTRAR}<<serial>>" +
  "${SALTO}${VAR}Fecha de emisión del permiso de investigación científica de la biodiversidad${:}<<sistema.fecha>>" +
  "${SALTO}${VAR}Identificador del permiso de investigación${:}<<solicitante.identificador>>" +
  "${SALTO}${VAR}Tiempo de vigencia del permiso${:}<<autorizacion.vigencia>> meses" +
  "${SALTO}${TITULO2}Solicitante" +
  "${SALTO}${VAR}Identificación${:}<<solicitante.cedula>>" +
  "${SALTO}${VAR}Nombre${:}<<solicitante.nombre>>" +
  "${SALTO}${TITULO2}Institución nacional de apoyo" +
  "${SALTO}${PARRAFO}<<institucionApoyo.razonSocial>>" +
  "${SALTO}${TITULO2}Proyecto" +
  "${SALTO}${PARRAFO}<<solicitud.nombreProyecto>>" +
  "${SALTO}${TITULO2}Objetivo General " +
  "${SALTO}${PARRAFO}<<solicitud.objetivoProyecto>>" +
  "${SALTO}${TITULO2}Objetivos específicos" +
  "${SALTO}${LISTA.SIMPLE.NUMERADA}<<objetivos>>" +
  "${SALTO}${TITULO2}Metodología de Campo" +
  "${SALTO}${PARRAFO}<<solicitud.metodologiaCampo>>" +
  "${SALTO}${TITULO2}Metodología de Laboratorio" +
  "${SALTO}${PARRAFO}<<solicitud.metodologiaLaboratorio>>" +
  "${SALTO}${TITULO2}Descripción del recurso biológico, genético o sus derivados cuya investigación científica se autoriza" +
  "${SALTO}${PARRAFO}IN SITU" +
  "${SALTO}${RECURSOS.INSITU}<<recursos.tablaRecursosInSitu>>" +
  "${SALTO}${PARRAFO.VACIO}" +
  "${SALTO}${PARRAFO}EX SITU" +
  "${SALTO}${RECURSOS.EXSITU}<<recursos.tablaRecursosExSitu>>" +
  "${SALTO}" +
  "${SALTO}${TITULO2}Área de estudio" +
  "${SALTO}${PARRAFO}<<solicitud.areaInvestigacion>>" +
  "${SALTO}${TITULO2}Resultados Esperados" +
  "${SALTO}${LISTA.SIMPLE.NUMERADA}<<solicitud.resultadosEsperados>>" +
  "${SALTO}${TITULO2}Reportes de Resultados" +
  "${SALTO}${PARRAFO}El solicitante deberá reportar informes parciales a través de la ventanilla única virtual para la investigación sobre biodiversidad (VUVib) en las siguientes fechas:" +
  "${SALTO}${DOTTED.ITEMS}<<solicitud.listaReporteResultados>> " +
  "${SALTO}${PARRAFO}Asimismo, deberá presentar a través de la VUVIB un informe final hasta la fecha de vigencia del permiso." +
  "${SALTO}${TITULO2}Origen de Recursos" +
  "${SALTO}${VAR}Provincia${:}<<ubicacion.listaProvincias>>" +
  "${SALTO}${VAR}Área Protegida${:}<<ubicacion.listaAreasProtegidas>>" +
  "${SALTO}${VAR}Bosque Protector${:}<<ubicacion.listaBosquesProtectores>>" +
  "${SALTO}${TITULO2}Acceso a conocimientos tradicionales asociados a los recursos de la biodiversidad" +
  "${SALTO}${ACCESO.CONOCIMIENTO}<<solicitud.accesoConocimientoTradicional>>" +
  "${SALTO}${TITULO2}Movilización" +
  "${SALTO}${PARRAFO}Para la movilización de los recursos autorizados en el presente permiso, el solicitante deberá realizar la declaración de movilidad respectiva a través de la VUVib, en cada una de las movilizaciones correspondientes." +
  "${SALTO}${TITULO2}Importación y Exportación" +
  "${SALTO}${PARRAFO}En caso de requerir importar recursos y exportar los recursos autorizados en la presente autorización, se deberá gestionar las autorizaciones con la/as entidades  pertinentes." +
  "${SALTO}${TITULO2}Equipo Técnico" +
  "${SALTO}${PERSONAL}<<solicitud.tablaEquipoTecnico>>" +
  "${SALTO}${TITULO2}Actividades autorizadas para el permiso" +
  "${SALTO}${PUNTO.PARRAFO}Se autoriza la manipulación, recolección y acceso a los recursos genéticos de los especímenes y/o muestras en “Ubicación geográfica” del presente permiso." +
  "${SALTO}${PUNTO.PARRAFO}Se autoriza la manipulación, recolección y acceso a los recursos genéticos de los especímenes y/o muestras en la “Descripción del recurso biológico, genético o sus derivados cuya investigación científica se autoriza” del presente permiso." +
  "${SALTO}${TITULO2}Obligaciones del solicitante" +
  "${SALTO}${PUNTO.PARRAFO}Cumplir con la normativa ecuatoriana e internacional vigente" +
  "${SALTO}${PUNTO.PARRAFO}Planificar y asumir todos los costos, gastos y el tiempo requerido para la realización del proyecto de investigación científica de conformidad con lo estipulado en la propuesta técnica presentada (Formulario de solicitud de permiso de investigación)." +
  "${SALTO}${PUNTO.PARRAFO}Solicitar los permisos, autorizaciones, licencias y demás requerimientos necesarios, incluyendo la autorización del o los propietarios de los predios privados en donde se encuentre el recurso genético y/o biológico para para la realización del proyecto de investigación científica." +
  "${SALTO}${PUNTO.PARRAFO}Entregar a la Senescyt los resultados generados de esta investigación científica a través de la Ventanilla única virtual para la investigación sobre biodiversidad (VUVib)." +
  "${SALTO}${PUNTO.PARRAFO}Depositar las muestras biológicas y/o genéticas en centros autorizados catalogados como medios de conservación y manejo ex situ." +
  "${SALTO}${PUNTO.PARRAFO}Informar a la Senescyt el lugar de depósito de las muestras biológicas y/o genéticas a través de la Ventanilla única virtual para la investigación sobre biodiversidad (VUVib)." +
  "${SALTO}${PUNTO.PARRAFO}Depositar los holotipos y muestras únicas de material biológico y/o genético en centros autorizados catalogados como medios de conservación y manejo ex situ del Ecuador." +
  "${SALTO}${PUNTO.PARRAFO}Notificar a la Senescyt y al MAATE en caso de encontrarse nuevas especies, adjuntando la ubicación, descripción y la respectiva publicación en los informes parciales o informe final. " +
  "${SALTO}${PUNTO.PARRAFO}Citar en las publicaciones científicas, tesis, informes técnico-científicos el número de contrato otorgado por la Senescyt con el que se lleva a cabo la investigación científica." +
  "${SALTO}${PUNTO.PARRAFO}Enviar e informar a la Senescyt las publicaciones científicas arbitradas e indexadas que se deriven del objeto de la investigación. " +
  "${SALTO}${PUNTO.PARRAFO}Responder administrativa, penal y civilmente por el uso indebido y no autorizado de los recursos genéticos y/o sus productos derivados, así como también por los daños causados a la naturaleza; restaurar y/o remediar los impactos negativos o daños ambientales a consecuencia del desarrollo de la investigación." +
  "${SALTO}${PUNTO.PARRAFO}Asegurar que sus investigadores, funcionarios, personal, estudiantes, y demás personas a su cargo o bajo su responsabilidad, conozcan y cumplan con los términos del presente permiso." +
  "${SALTO}${PUNTO.PARRAFO}Solicitar a la Senescyt a través de la Ventanilla única virtual para la investigación sobre biodiversidad (VUVib), la incorporación, sustitución o salida de algún miembro del equipo técnico autorizado, con su respectiva justificación." +
  "${SALTO}${PUNTO.PARRAFO}Solicitar a la Senescyt a través de la Ventanilla única virtual para la investigación sobre biodiversidad (VUVib)”, la exclusión o ampliación de grupos taxonómicos, número o tipo de muestras y/o lotes, y sitios de colecta." +
  "${SALTO}${PUNTO.PARRAFO}Para el cumplimiento de las actividades en las áreas naturales protegidas autorizadas, los investigadores deberán informar las fechas programadas para su ingreso, coordinar las actividades a realizar con los administradores de las áreas protegidas correspondientes y apegarse a las normas establecidas en los planes de manejo de estas áreas de conservación y su zonificación." +
  "${SALTO}${PUNTO.PARRAFO}En caso de que en el desarrollo de la investigación se identificará el acceso a un conocimiento tradicional asociado a la biodiversidad, el investigador deberá solicitar el debido consentimiento libre, previo e informado a los legítimos poseedores, y suscribir el respectivo contrato de acceso al conocimiento tradicional; ambos documentos deberán registrarse ante la autoridad nacional competente en materia de Derechos Intelectuales. Para el efecto, el investigador seguirá el correspondiente procedimiento de modificación del permiso de investigación." +
  "${SALTO}${PUNTO.PARRAFO}Celebrar con terceros contratos, convenios y acuerdos que sean necesarios para el desarrollo de la investigación amparado por este permiso de investigación." +
  "${SALTO}${PUNTO.PARRAFO}Si el solicitante necesita transferir material biológico y genético con fines de investigación científica, deberá estar acorde al objetivo de la investigación y será indispensable que se genere mediante un Acuerdo de Transferencia de Material (ATM), mismo que deberá ser solicitado a la Senescyt a través de la Ventanilla única virtual para la investigación sobre biodiversidad (VUVib). " +
  "${SALTO}${PUNTO.PARRAFO}Los demás previstos en la normativa legal y vigente" +
  "${SALTO}${TITULO2} Prohibiciones" +
  "${SALTO}${PUNTO.PARRAFO}No podrá destinar los recursos genéticos accedidos por efecto de emisión del presente permiso de investigación a otros fines distintos a los establecidos en el mismo y con base en la solicitud presentada." +
  "${SALTO}${PUNTO.PARRAFO}No podrá ceder ni transferir a terceros los derechos y obligaciones adquiridas por el presente permiso de investigación." +
  "${SALTO}${PUNTO.PARRAFO}El permiso de investigación no habilita la exportación de flora, fauna, microorganismos y hongos, sin la correspondiente autorización de la entidad competente." +
  "${SALTO}${PUNTO.PARRAFO}Los especímenes o muestras recolectadas no podrán ser utilizadas en actividades de bioprospección o acceso con potencial uso comercial, en caso de que fuera necesario, deberá suscribir un contrato para este fin realizando la solicitud correspondiente a través de la VUVib." +
  "${SALTO}${PUNTO.PARRAFO}Los resultados que se desprendan de la investigación, no podrán ser utilizados para estudios posteriores de acceso a recursos genéticos sin la previa autorización de la entidad competente" +
  "${SALTO}${PUNTO.PARRAFO}No tiene derecho a manipular y/o colectar recursos biológicos y/o genéticos distintos a los que son autorizados en este permiso de investigación, aunque esos recursos hubieren sido descubiertos por el solicitante; excepto en los casos en que obtenga la autorización de la entidad competente " +
  "${SALTO}${PUNTO.PARRAFO}Este permiso de investigación no ampara el permiso de exportación de muestras, por lo que deberán ser requeridos a la entidad competente previa revisión del acuerdo de transferencia de material (ATM)" +
  "${SALTO}${PUNTO.PARRAFO}Este permiso de investigación no faculta al equipo investigador para ingresar a predios privados, el solicitante deberá obtener la autorización correspondiente del propietario del predio en el que se encuentren las muestras" +
  "${SALTO}${PARRAFO}Del incumplimiento de las obligaciones dispuestas se responsabiliza a <<solicitante.nombre>> " +
  "${SALTO}${PARRAFO}Dado en la ciudad de San Francisco de Quito, D.M. a los <<sistema.fechaTexto>>. " +
  "${SALTO}${PARRAFO.VACIO}" +
  "${SALTO}${CENTRARBOLD}NOMBRE Y FIRMA DE LA AUTORIDAD" +
  "${SALTO}${PARRAFO.VACIO}" +
  "${SALTO}${PARRAFO.VACIO}" +
  "${SALTO}${PARRAFO.VACIO}" +
  "${SALTO}${PARRAFO.VACIO}" +
  "${SALTO}${CENTRAR}<<sistema.nombreAutorizadorPrincipalSenescyt>>" +
  "${SALTO}${CENTRAR}<<sistema.rolAutorizadorPrincipalSenescyt>>" +
  "${SALTO}${PARRAFO.VACIO}" +
  "${SALTO}${PARRAFO.VACIO}" +
  // "${SALTO}${CENTRAR}<<sistema.cargoAutorizadorPrincipalSenescyt>>" +
  "${SALTO}${ELABORAN.PERMISO}<<elaboranPermiso>>"
// "${SALTO}${CENTRARBOLD}Elaborado por: " +
// "${SALTO}${CENTRAR}<<usuario.nombre>>" +
// "${SALTO}${CENTRAR}<<usuario.rol>>" +
// "${SALTO}${CENTRAR}<<usuario.cargo>>" +
// "${SALTO2}" +
// "${SALTO}${CENTRARBOLD}Revisado por: " +
// "${SALTO}${CENTRAR}<<sistema.nombreCoordinadorCasosSenescyt>>" +
// "${SALTO}${CENTRAR}<<sistema.rolCoordinadorCasosSenescyt>>" +
// "${SALTO}${CENTRAR}<<sistema.cargoCoordinadorCasosSenescyt>>"
