
import * as Yup from 'yup';

export const validacionPassword = Yup.object().shape({
    pass1: Yup.string()
    .min(5, 'El password debe tener al menos 5 caracteres')
    .required('El nuevo password es obligatorio'),
    pass2: Yup.string()
    .min(5, 'El password debe tener al menos 5 caracteres')
    .required('La confirmación del password es obligatorio')
    .oneOf([Yup.ref('pass1'), null], 'El password debe coincidir'),
});