import React, {useEffect, useState} from 'react'
import {Box} from '@mui/system'
import {f} from 'src/commons'
import API from 'src/features/App/API'
import {Grid, Stack} from '@mui/material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MySwitch} from "../../../components/MySwitch";
import {Email, LocationOn, PhoneInTalk, Smartphone} from "@mui/icons-material";
import {MyTextField} from "../../../components/MyTextField";
import {MyMaskedTextField} from "../../../components/MyMaskedTextField";
import Typography from "@mui/material/Typography";
export const ResponsableSolicitud = ({section, formValues, setFormValues, canEdit, formErrors}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})

  const sectionErrors =  (sectionValues.rlNombresApellidos === '')?'NOMBRE VACÍO;':'' ||
                                  (sectionValues.rlDireccionDomicilio === '')?'DIRECCIÓN VACÍA;':'' ||
                                  (sectionValues.rlTelefonoFijo === '')?'TELEFONO VACIO;':'' ||
                                  (sectionValues.rlCelular === '')?'CELULAR VARCIO':'' ||
                                  (sectionValues.rlCorreoElectronico === '')?'CORREO ELECTRÓNICO VACÍO':''

  useEffect(() => {
    if (canEdit && f.isValid(setFormValues)) {
      setFormValues({
          ...formValues,
          [section]: {...sectionValues},
        },
      )
    }
  }, [sectionValues])

  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  const handleChangePhone = (e) => {
    if(e.target.value === '' || f.isPhone(e.target.value))
      API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  }

  // useEffect(() => {
  //   if(canEdit) {
  //     if(sectionValues.rlEsRepresentanteLegal) {
  //       setSectionValues({
  //         ...sectionValues,
  //         rlNombresApellidos:   f.StringOrEmpty(formValues.EmpresaSolicitante?.rucInfo?.representanteLegal),
  //         rlDireccionDomicilio: f.StringOrEmpty(formValues.EmpresaSolicitante?.rucInfo?.direccionRepresentante),
  //         // rlTelefonoFijo:       f.StringOrEmpty(formValues.EmpresaSolicitante?.rucInfo?.telefonoRepresentante),
  //         // rlCelular:            f.StringOrEmpty(formValues.EmpresaSolicitante?.rucInfo?.celularRepresentante),
  //         // rlCorreoElectronico:  f.StringOrEmpty(formValues.EmpresaSolicitante?.rucInfo?.correoRepresentante),
  //       })
  //     } else {
  //       setSectionValues({
  //         ...sectionValues,
  //         rlNombresApellidos:   '',
  //         rlDireccionDomicilio: '',
  //         rlTelefonoFijo:       '',
  //         rlCelular:            '',
  //         rlCorreoElectronico:  '',
  //       })
  //     }
  //   }}, [sectionValues.rlEsRepresentanteLegal])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Responsable solicitud'} />
      </Grid>
      <Grid item xs={12}>
        <Stack direction={'row'} justifyContent={'flex-start'}>
          <MySwitch id={'rlEsRepresentanteLegal'}
                    canEdit={canEdit}
                    label={'¿Es el representante legal?'}
                    formValues={sectionValues}
                    setFormValues={setSectionValues}
                    handleChange={handleChange}
                    fullWidth={false} />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'rlNombresApellidos'}
                     label={'Nombres y apellidos *'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={f.isValid(sectionErrors) && sectionErrors['rlNombresApellidos']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'rlDireccionDomicilio'}
                     label={'Dirección de domicilio *'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={f.isValid(sectionErrors) && sectionErrors['rlDireccionDomicilio']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'rlTelefonoFijo'}
                           label={'Teléfono fijo'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>}
                           error={f.isValid(sectionErrors) && sectionErrors['rlTelefonoFijo']}
                           canEdit={canEdit}
                           type={'HomePhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'rlCelular'}
                           label={'Celular'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>}
                           error={f.isValid(sectionErrors) && sectionErrors['rlCelular']}
                           canEdit={canEdit}
                           type={'CellPhoneNumber'}
                           handleChange={handleChangePhone}/>
      </Grid>
      <Grid item xs={4}>
        <MyTextField id={'rlCorreoElectronico'}
                     label={'Correo Electrónico'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>}
                     error={f.isValid(sectionErrors) && sectionErrors['rlCorreoElectronico']}
                     canEdit={canEdit}
                     handleChange={handleChange}/>
      </Grid>
      <Grid item xs={12}>
        {
          (sectionErrors !== '') ? <Typography sx={{color:'red'}}>{sectionErrors}</Typography>:false
        }
      </Grid>
    </Grid>
  )
}
