import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFormikContext } from "formik";

export const SelectIts = ({ ...props }) => {

    const [age, setAge] = React.useState("publico");
    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
        setAge(props.value);
    }, [])

    const handleChange = (event) => {
        setAge(event.target.value);
        setFieldValue("tipo", event.target.value)
    };

    return (
        <div>
            <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="tipo-label">Tipo</InputLabel>
                <Select
                    labelId="tipo-label"
                    id="tipo"
                    value={age}
                    onChange={handleChange}
                    autoWidth
                    required
                    label="Tipo"
                >
                    <MenuItem key={1} value={"publico"}>{"Público"}</MenuItem>
                    <MenuItem key={2} value={"privado"}>{"Privado"}</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}
