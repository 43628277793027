import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {MyTextField} from "src/components/MyTextField";
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {MySwitch} from "../../components/MySwitch";
import {MySubtitle} from "../../components/MySubtitle";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {WF03SolicitudRO} from "./P03SolicitudRO";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MyDatePicker} from "../../components/MyDatePicker";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {WF03Expediente} from "./subcomponents/P03Expediente";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import GLOBALS from "../App/globals";
import {MyTable as MyTableObservaciones} from "../../components/MyTable";

// eslint-disable-next-line import/no-anonymous-default-export
export const P03RegistrarContrato = ({instanciaTarea, instanciaProceso, solicitud, perfilUsuario, id}) => {
  // console.log(':::: sid : ', id)
  const section = 'P03RegistrarContrato'
  const dispatch = useDispatch()
  const bandeja = useSelector(state => state.app.bandeja)
  let payload = !!solicitud.payload?JSON.parse(solicitud.payload):{[section]:{}}
  payload.solicitudId = solicitud.id
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    identificador: solicitud.numeroSolicitud,
    fecha:                today,
    cuentaConIRCC:   false,
    idRegistro:      '',
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada' && instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => { setCounter(counter + 1) }, [formValues])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }
  // const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion

  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    }
  }

  const contrato = payload?.INFO?.docs?.d09Negociacion.findIndex(x => x.idx === 'REVCONTRATO')

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Registrar Contrato'}/>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
            <Tab label="Expediente" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>

            <Grid item xs={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'CONTRATO'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: payload?.INFO?.docs?.d09Negociacion[contrato]?.solicitudId,
                        subfolder: payload?.INFO?.docs?.d09Negociacion[contrato]?.subFolder,
                        filename: payload.INFO.docs?.d09Negociacion[contrato]?.archivo,
                      })}
                      label={'CONTRATO'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'REGISTRO'} />
            </Grid>
            <Grid item xs={4} style={{padding:'0 24px 0 0.5rem'}} >
              <MyTextField id={'idRegistro'}
                           label={'Número de contrato *'}
                           formValues={formValues}
                           setFormValues={setFormValues}
                           handleChange={handleChange}
                           canEdit={true} />
            </Grid>

            <Grid item xs={4} style={{padding:'1rem 24px 0 0.5rem'}} >
              <MyDatePicker id={'fecha'}
                            canEdit={true}
                            label={'Fecha de firma del contrato'}
                            formValues={formValues}
                            setFormValues={setFormValues} />
            </Grid>

            <Grid item xs={4} style={{padding:'0.4rem 0px 0 0.5rem'}} >
              <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1} style={{padding:'0.4rem 0 0 0rem'}}>
                <MySwitch id={'cuentaConIRCC'}
                          canEdit={true}
                          label={'¿Este contrato cuenta con IRCC? *'}
                          formValues={formValues}
                          fullWidth={false}
                          handleChange={handleChange} />
              </Stack>
            </Grid>

          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <WF03SolicitudRO payload={payload}/>
        </TabPanel>
        <TabPanel value="3">
          <WF03Expediente payload={payload} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={() => {
                                getOnSave()()
                              }}
                              disabled={counter <= 0} />
                <MySendButton onSend={() => {
                                const metadata = JSON.stringify({
                                  "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                  "icrr": formValues.cuentaConIRCC
                                })
                                dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'ENVIAR'}
                              myTip={''}
                              disabled={counter > 0}/>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}
