import Solicitante from 'src/features/P01Solicitud/subcomponents/P01Solicitud01Solicitante'
import {SolicitanteRO} from 'src/features/P01Solicitud/subcomponents/P0102Solicitud01SolicitanteRO'
import {PropuestaRO} from 'src/features/P01Solicitud/subcomponents/P0102Solicitud01PropuestaRO'
import {RecursosInSituRO} from 'src/features/P01Solicitud/subcomponents/P0102Solicitud01RecursosInSituRO'
import {RecursosExSituRO} from 'src/features/P01Solicitud/subcomponents/P0102Solicitud01RecursosExSituRO'
import {PersonalRO} from 'src/features/P01Solicitud/subcomponents/P0102Solicitud01PersonalRO'
import {DeclaracionRO} from 'src/features/P01Solicitud/subcomponents/P0102Solicitud01DeclaracionRO'
import Propuesta from 'src/features/P01Solicitud/subcomponents/P01Solicitud02Propuesta'
import RecursosInSitu from 'src/features/P01Solicitud/subcomponents/P01Solicitud03RecursosINSITU'
import RecursosExSitu from 'src/features/P01Solicitud/subcomponents/P01Solicitud03RecursosEXSITU'
import AccesoConocimiento from 'src/features/P01Solicitud/subcomponents/P01Solicitud03AccesoConocimiento'
import {AccesoConocimientoRO} from 'src/features/P01Solicitud/subcomponents/P01Solicitud03AccesoConocimientoRO'
import Personal from 'src/features/P01Solicitud/subcomponents/P01Solicitud04Personal'
import Declaracion from 'src/features/P01Solicitud/subcomponents/P01Solicitud06Declaracion'
import GLOBALS from 'src/features/App/globals'
import {f} from "../../commons";
import {add, format, parse} from "date-fns";
import {es} from "date-fns/locale";

const API = {
  sliceName: 'p1',
  processTitle: 'Solicitud de Permiso de Investigación',
  sections: [
    {code:'SOLCTD', sectionTitle: 'Solicitante', f: (payload,mainFormValues,incrementCounter, idTask) => (<Solicitante payload={payload} mainFormValues={mainFormValues} incrementCounter={incrementCounter} idTask={idTask}/>), jsonSection:'Solicitante',},
    {code:'PROPUE', sectionTitle: 'Contexto y Alcance de la Investigación', f: (payload,mainFormValues,incrementCounter, idTask) => <Propuesta payload={payload} mainFormValues={mainFormValues} incrementCounter={incrementCounter} idTask={idTask} />, jsonSection:'Propuesta',},
    {code:'INSITU', sectionTitle: 'Recursos de la biodiversidad a ser investigados IN SITU', f: (payload,mainFormValues,incrementCounter, idTask) => <RecursosInSitu payload={payload} mainFormValues={mainFormValues} incrementCounter={incrementCounter} idTask={idTask}/>, jsonSection:'Recursos',},
    {code:'EXSITU', sectionTitle: 'Recursos de la biodiversidad a ser investigados EX SITU', f: (payload,mainFormValues,incrementCounter, idTask) => <RecursosExSitu payload={payload} mainFormValues={mainFormValues} incrementCounter={incrementCounter} idTask={idTask} />, jsonSection:'Recursos',},
    {code:'ACCESO', sectionTitle: 'Acceso a conocimiento tradicional', f: (payload,mainFormValues,incrementCounter, idTask) => <AccesoConocimiento payload={payload} mainFormValues={mainFormValues} incrementCounter={incrementCounter} idTask={idTask} />, jsonSection:'Recursos',},
    {code:'PERSON', sectionTitle: 'Personal Científico Técnico', f: (payload,mainFormValues,incrementCounter, idTask) => <Personal payload={payload} mainFormValues={mainFormValues} incrementCounter={incrementCounter} idTask={idTask} />, jsonSection:'Personal',},
    {code:'DECLAR', sectionTitle: 'Declaración de veracidad de la información', f: (payload,mainFormValues,incrementCounter, idTask) => <Declaracion payload={payload} mainFormValues={mainFormValues} incrementCounter={incrementCounter} idTask={idTask} />, jsonSection:'Documentos',},
  ],
  sectionsRO: [
    {code:'SOLCTD', sectionTitle: 'Solicitante', f: (payload) => <SolicitanteRO payload={payload}/>, jsonSection:'Solicitante',},
    {code:'PROPUE', sectionTitle: 'Contexto y Alcance de la Investigación', f: (payload) => <PropuestaRO payload={payload} />, jsonSection:'Propuesta',},
    {code:'INSITU', sectionTitle: 'Recursos de la biodiversidad a ser investigados IN SITU', f: (payload,mainFormValues,incrementCounter) => <RecursosInSituRO payload={payload} mainFormValues={mainFormValues} incrementCounter={incrementCounter} />, jsonSection:'Recursos',},
    {code:'EXSITU', sectionTitle: 'Recursos de la biodiversidad a ser investigados EX SITU', f: (payload,mainFormValues,incrementCounter) => <RecursosExSituRO payload={payload} mainFormValues={mainFormValues} incrementCounter={incrementCounter} />, jsonSection:'Recursos',},
    {code:'ACCESO', sectionTitle: 'Acceso a conocimiento tradicional', f: (payload,mainFormValues) => <AccesoConocimientoRO payload={payload} mainFormValues={mainFormValues} />, jsonSection:'AccesoConocimiento', },
    {code:'PERSON', sectionTitle: 'Personal Científico Técnico', f: (payload,mainFormValues,incrementCounter) => <PersonalRO payload={payload} mainFormValues={mainFormValues} incrementCounter={incrementCounter} />, jsonSection:'Personal',},
    {code:'DECLAR', sectionTitle: 'Declaración de veracidad de la información', f: (payload,mainFormValues,incrementCounter) => <DeclaracionRO payload={payload} mainFormValues={mainFormValues} incrementCounter={incrementCounter} />, jsonSection:'Documentos',},
  ],
  searchEspecie: async(value, taxonRank, ranks) => {
    const field = ranks[taxonRank].field
    const option = ranks[taxonRank].option
    const body = {
      terminoBusqueda: `${value}`,
      taxonRankBusqueda: `${taxonRank}`,
      [field]: `${option}`
    }
    if(taxonRank === 'Kingdom') {
      body.kingdom = ranks['Kingdom'].option
    }

    const url    = `${GLOBALS.mainUrl}/v1/api/${ranks[taxonRank].method}`
    const res    = await fetch(url,{
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body:JSON.stringify(body)
    })
    return res.json()
  }
}

API.mapping = (vars) => {
  const { solicitudId, payload, autorizador, coordinador, perfilUsuario, tipo, section } = vars
  const tipoDetalle = (!f.isValid(tipo))?'Permiso de Investigación Científica':(tipo==='permiso')?'Permiso de Investigación Científica':'Contrato de Acceso con Potencial Uso Comercial'
  const autoridadSenescyt = vars.autoridadSenescyt
  const recInsitu = payload?.RecursosInSitu?.recursos?.map(it => it.scientificname)?.join(', ') + ', ' + payload?.RecursosExSitu?.centrosDocumentacion?.recursos?.map(it => it.scientificname)?.join(', ')
  const recExsitu = payload?.RecursosExSitu?.centrosDocumentacion?.map(it => it.recursos?.map(it2 => it2.scientificname)?.join((', ')))?.join(', ')
  let recs = []
  if(f.isValid(recInsitu)) recs.push(recInsitu)
  if(f.isValid(recExsitu)) recs.push(recExsitu)
  let acc = []
  if(payload?.AccesoConocimiento?.aConocimientoTradicional) acc.push('Acceso a conocimiento tradicional.')
  if(payload?.AccesoConocimiento?.clpi !== "") acc.push('CLPI: ' + payload?.AccesoConocimiento?.clpi)
  if(payload?.AccesoConocimiento?.contratoAccesoConocimiento !== "") acc.push('Contrato de acceso a conocimiento: '+ payload?.AccesoConocimiento?.contratoAccesoConocimiento)
  if(payload?.AccesoConocimiento?.ambitoComunitario) acc.push('Ambito comunitario.')
  if(payload?.AccesoConocimiento?.derivadosOSimilares) acc.push('Derivados o similares.')
  if(payload?.AccesoConocimiento?.etnozoologicos) acc.push('Etnozoologicos.')
  if(payload?.AccesoConocimiento?.medicinaTradicional) acc.push('Medicina tradeicional.')
  if(payload?.AccesoConocimiento?.practicasAncestrales) acc.push('Prácticas ancestrales.')
  if(payload?.AccesoConocimiento?.recursosBiologico) acc.push('Recursos biológicos.')
  if(payload?.AccesoConocimiento?.valoresReligiososCultutrales) acc.push('Valores religiosos culturales.')
  let RecursosInSitu = {...payload.RecursosInSitu}
  RecursosInSitu.muestras = payload?.InformeTecnicoMaate?.muestras
  let RecursosExSitu = {...payload.RecursosExSitu}
  RecursosExSitu.centrosDocumentacion = payload?.InformeTecnicoMaate?.centrosDocumentacion
  const dFecha = format(new Date(), 'd MMMM yyyy', {locale: es})
  const dResolucion = f.isValid(payload?.Resolucion?.fecha)?
    parse(payload?.Resolucion?.fecha,'d-MMMM-yyyy', new Date(), {locale: es}):
    new Date()
  const plazo = Number(payload.Propuesta?.plazo)
  const dPlazo = add(dResolucion, {months: plazo})
  return {
    '<<sistema.identificadorInformeTecnicoMaate>>': payload?.InformeTecnicoMaate?.serial ?? '',
    '<<autorizacion.vigencia>>': payload?.Propuesta?.plazo,
    '<<solicitante.identificador>>': solicitudId,
    '<<solicitante.idAcreditacion>>': payload?.Solicitante?.registro,
    '<<institucionApoyo.razonSocial>>': payload?.Propuesta?.apoyo,
    '<<solicitud.areaInvestigacion>>': payload?.Propuesta?.areaInvestigacion,
    '<<recursos.listaRecursos>>': recs.join(', '),
    '<<ubicacion.listaProvincias>>': payload?.RecursosInSitu?.provincias?.join(', '),
    '<<ubicacion.listaAreasProtegidas>>': payload?.RecursosInSitu?.areasProtegidas?.join(', '),
    '<<ubicacion.listaBosquesProtectores>>': payload?.RecursosInSitu?.bosquesProtectores?.join(', '),
    '<<plazoProyecto>>': payload?.Propuesta?.plazo + ' meses',
    '<<plazoProyectoAnio>>': Math.trunc(payload?.Propuesta?.plazo/12) + ' años ' + (payload?.Propuesta?.plazo%12) + ' meses',
    '<<sistema.identificadorDictamenTecnicoMaate>>': solicitudId,
    '<<sistema.identificadorIDictamenTecnicoSenadi>>': solicitudId,
    '<<sistema.identificadorInformeTecnicoSenescyt>>': solicitudId,
    '<<sistema.identificadorResolucionSenescyt>>': solicitudId,
    '<<sistema.fechaTexto>>':format(new Date(), 'd MMMM yyyy', {locale: es}),
    '<<sistema.fecha>>':format(new Date(), 'd MMMM yyyy', {locale: es}),
    '<<solicitud.identificador>>': solicitudId,
    '<<solicitante.nombre>>': payload?.Solicitante?.nombresCompletos,
    '<<solicitud.nombreProyecto>>': `"${payload?.Propuesta?.nombre}"`,
    '<<solicitud.plazoProyecto>>': payload?.Propuesta?.plazo,
    '<<patrocinador>>': payload?.Propuesta?.patrocinador,
    '<<sistema.nombreAutorizadorPrincipalSenescyt>>': autorizador?.nombreUsuario ?? '*',
    '<<sistema.rolAutorizadorPrincipalSenescyt>>': autorizador?.nombrePerfil ?? '*',
    '<<sistema.cargoAutorizadorPrincipalSenescyt>>': autorizador?.descripcionPerfil ?? '*',
    '<<sistema.telefonoAutorizadorPrincipalSenescyt>>': autorizador?.telefonoUsuario ?? '*',
    '<<sistema.coreoElectronicoAutorizadorPrincipalSenescyt>>': autorizador?.correoUsuario ?? '*',
    '<<sistema.nombreAutorizadorSectorialMaate>>': autorizador?.nombreUsuario ?? '*',
    '<<sistema.rolAutorizadorSectorialMaate>>': autorizador?.nombrePerfil ?? '*',
    '<<usuario.cargoAutorizadorSectorialMaate>>': autorizador?.descripcionPerfil ?? '*',
    '<<sistema.nombreCoordinadorCasosSenescyt>>': coordinador?.nombreUsuario ?? '*',
    '<<sistema.rolCoordinadorCasosSenescyt>>': coordinador?.nombrePerfil ?? '*',
    '<<sistema.cargoCoordinadorCasosSenescyt>>': coordinador?.descripcionPerfil  ?? '*',
    '<<sistema.telefono>>': coordinador?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<sistema.correoElectronico>>':coordinador?.correoUsuario ?? '*',
    '<<usuario.nombre>>':perfilUsuario?.usuario?.nombreUsuario ?? '*',
    '<<usuario.rol>>':perfilUsuario?.perfil?.nombrePerfil ?? '*',
    '<<usuario.cargo>>':perfilUsuario?.perfil?.descripcionPerfil ?? '*',
    '<<usuario.telefono>>':perfilUsuario?.usuario?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<usuario.correoElectronico>>':perfilUsuario?.usuario?.correoUsuario ?? '*',
    '<<sistema.nombreAutoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '*' ,
    '<<sistema.rolAutoridadSenescyt>>': autoridadSenescyt?.nombrePerfil ?? '*',
    '<<sistema.cargoAutoridadSenescyt>>': autoridadSenescyt?.descripcionPerfil ?? '*',
    '<<sistema.autoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '*',
    '<<solicitud.objetivoProyecto>>':payload?.Propuesta?.objetivo,
    '<<solicitante.cedula>>':payload?.Solicitante?.cedula,
    '<<objetivos>>':payload?.Propuesta?.objetivos?.join('{:}'),
    '<<solicitud.metodologiaCampo>>': payload?.Propuesta?.metodologia,
    '<<solicitud.metodologiaLaboratorio>>': payload?.Propuesta?.metodologiaLaboratorio,
    '<<recursos.tablaRecursosInSitu>>': JSON.stringify(RecursosInSitu),
    '<<recursos.tablaRecursosExSitu>>': JSON.stringify(RecursosExSitu),
    '<<solicitud.resultadosEsperados>>': payload?.Propuesta?.resultadosEsperados?.join('{:}'),
    '<<solicitud.tablaEquipoTecnico>>': JSON.stringify(payload?.Personal?.personal),
    '<<solicitud.accesoConocimientoTradicional>>': JSON.stringify(payload?.AccesoConocimiento),
    '<<solicitud.listaReporteResultados>>': payload.fechas,
    '<<solicitud.institucionPatrocinadora>>': payload?.Propuesta?.patrocinador,
    '<<solicitud.representanteLegalPatrocinadora>>': payload?.Propuesta?.nombreRepLegalPatrocidador,
    '<<solicitud.cargoRepresentanteLegalPatrocinadora>>': payload?.Propuesta?.cargoRepLegalPatrocidador,
    '<<solicitud.fecha>>': payload?.Resolucion?.fecha,
    '<<solicitud.institucionNacionalApoyo>>': payload?.Propuesta?.apoyo,
    '<<solicitud.legitimosPoseedores>>': (f.isValid(payload?.AccesoConocimiento?.legitimosPoseedores) && (payload?.AccesoConocimiento?.legitimosPoseedores !== ''))?payload?.AccesoConocimiento?.legitimosPoseedores:'',
    '<<autorizacion.fechaHasta>>':format(dPlazo, 'dd MMMM yyyy',{locale: es}),
    '<<positivo>>':(payload?.InformeTecnicoMaate?.seAutoriza)?'+':'-',
    '<<negativo>>':(!payload?.InformeTecnicoMaate?.seAutoriza)?'+':'-',
    '<<baremo>>':(f.isValid(payload.InformeTecnicoMaate)?JSON.stringify(payload.InformeTecnicoMaate):'{}'),
    '<<informeTecnicoMaate.observaciones>>':(f.isValid(payload.InformeTecnicoMaate?.observaciones))?payload.InformeTecnicoMaate?.observaciones?.join('{:}'):'',
    '<<solicitud.tipoDePermiso>>': tipoDetalle.toUpperCase(),
    '<<serial>>':(f.isValid(section) && section.serial)?section.serial:'serial: ????',
    '<<elaboranPermiso>>': JSON.stringify({elabora: (perfilUsuario?.usuario?.nombreUsuario ?? ''), revisa:(coordinador?.nombreUsuario ?? ''), fecha:format(new Date(), 'd MMMM yyyy', {locale: es})}),
    '<<fecha>>': dFecha
  }
}

export default API
