import React, {useEffect} from 'react'
import {Box} from "@mui/material";
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {f} from 'src/commons/f'
import {WF03Solicitud} from 'src/features/P03'
import P01SolicitudAsignacionCaso from "../P01Solicitud/P01SolicitudAsignacionCaso";
import {P03VerificarCumplimiento} from "./P03VerificarCumplimiento";
import {P03RegistrarResolucion} from "./P03RegistrarResolucion";
import {P03RevisarFactivilidad} from "./P03RevisarFactibilidad";
import {P03InformeTecnicoSENESCYT} from "./P03InformeTecnicoSENESCYT";
import {P03DictamenTecnicoSENESCYT} from "./P03DictamenTecnicoSENESCYT";
import {WF03SolicitudRO} from "./P03SolicitudRO";
import {P03InformeTecnicoMAATE} from "./P03InformeTecnicoMAATE";
import {P03DictamenTecnicoMAATE} from "./P03DictamenTecnicoMAATE";
import {P03InformeTecnicoSENADI} from "./P03InformeTecnicoSENADI";
import {P03DictamenTecnicoSENADI} from "./P03DictamenTecnicoSENADI";
import {P03ElaborarResolucion} from "./P03ElaborarResolucion";
import {P03EmitirResolucion} from "./P03EmitirResolucion";
import {P03ElaborarPropuestaInicialNegociacion} from "./P03ElaborarPropuestaInicialNegociacion";
import {P03ElaborarInformeMejorasSenescyt} from "./P03ElaborarInformeMejoraSenescyt";
import {P03SugerirMejorasSenescyt} from "./P03SugerirMejorasSenescyt";
import {P03PropuestaNegociacionInabio} from "./P03PropuestaNegociacionInabio";
import {P03EmitirResolucionFinalInabio} from "./P03EmitirResolucionFinal";
import {P03ElaborarContrato} from "./P03ElaborarContrato";
import {P03RevisarContrato} from "./P03RevisarContrato";
import {P03ElaborarInformeMejorasMaate} from "./P03ElaborarInformeMejorasMaate";
import {P03ElaborarInformeMejorasSenadi} from "./P03ElaborarInformeMejorasSenadi";
import {P03SugerirMejorasMaate} from "./P03SugerirReformasMaate";
import {P03SugerirMejorasSenadi} from "./P03SugerirReformasSenadi";
import {P03RevisarContratoInvestigador} from "./P03RevisarContratoInvestigador";
import {P03RegistrarContrato} from "./P03RegistrarContrato";
import P03RegistroInternacional from "./P03RegistroInternacional";
// import P10ATMElaborarSolicitud from 'src/features/P10ATM/P10ATMElaborarSolicitud'

/*
-- 55000030_Activity_ElaborarSolicitud_WF03
55000030_Activity_CompletarInformacionRequeridaInabio_WF03
55000030_Activity_CompletarInformacionRequeridaMaate_WF03
55000030_Activity_RevisarContratoInvestigador_WF03
55000030_Activity_CompletarInformacionRequeridaSenadi_WF03
55000030_Activity_CompletarInformacionRequeridaSenescyt_WF03
55000031_Activity_AsignarCaso_WF03
55000031_Activity_RegistrarResolucion_WF03
55000031_Activity_RevisarFactibilidadSolicitud_WF03
55000031_Activity_RealizarSolicitudInformacionInabio_WF03
55000031_Activity_RealizarInterconsultasInabio_WF03
55000031_Activity_ResolverInterconsultasInabio_WF03
55000031_Activity_AsignarCasoMaate_WF03
55000031_Activity_ElaborarInformeTecnicoMaate_WF03
55000031_Activity_SolicitarInformacionMaate_WF03
55000031_Activity_RealizarInterconsultasMaate_WF03
55000031_Activity_RealizarConsultasTerritorioMaate_WF03
55000031_Activity_ResolverInterconsultasMaate_WF03
55000031_Activity_ResolverConsultasEspecificasMaate_WF03
55000031_Activity_EmitirDictamenTecnicoMaate
55000031_Activity_AsignarCasoSenescyt_WF03
55000031_Activity_ElaborarInformeTecnicoSenescyt_WF03
55000031_Activity_RealizarInterconsultasSenescyt_WF03
55000031_Activity_SolicitarInformacionSenescyt_WF03
55000031_Activity_ResolverInterconsultasSenescyt_WF03
55000031_Activity_EmitirDictamenTecnicoSenescyt_WF03
55000031_Activity_AsignarCasoSenadi_WF03
55000031_Activity_ElaborarInformeTecnicoSenadi_WF03
55000031_Activity_RealizarInterconsultasSenadi_WF03
55000031_Activity_ResolverInterconsultasSenadi_WF03
55000031_Activity_RealizarSolicitudInfomacionSenadi_WF03
55000031_Activity_1rpu1qx
55000031_Activity_ElaborarInformeMejorasMaate_WF03
55000031_Activity_SugerirMejorasMaate_WF03
55000031_Activity_ElaborarInformeMejorasSenescyt_WF03
55000031_Activity_SugerirMejorasSenescyt_WF03
55000031_Activity_ElaborarInformeMejorasSenadi_WF03
55000031_Activity_SugerirMejorasSenadi_WF03
55000031_Activity_ElaborarResolucion_WF03
55000031_Activity_EmitirResolucion_WF03
55000031_Activity_EmitirResolucionFinal_WF03
55000031_Activity_RealizarInterconsultaInabio_WF03
55000031_Activity_ResolverInterconsultaInabio_WF03
55000031_Activity_ElaborarPropuestaNegociacionFinal_WF03
55000031_Activity_ElaborarPropuestaNegociacionInicial_WF03
55000031_Activity_ElaborarContrato_WF03
Activity_RevisarContratoNegociador_WF03
Activity_ElaborarActoAdministrativo_WF03
Activity_ArchivarSolicitud_WF03
Activity_RegistrarContrato_WF03
Activity_RegistrarCertificadoInternacional_WF03
55000031_Activity_VerificarCumplimientoRequisitos_WF03
 */

export const Widget = () => {
  const navigate = useNavigate()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = instanciaProceso?.solicitud
  useEffect(()=> {
    if(!f.isValid(instanciaTarea.id)) {navigate('/')}
  }, [instanciaTarea, navigate])

  useEffect(() => {window.scrollTo(0, 0)}, [])

  if(f.isValid(solicitud) && f.isValid(instanciaTarea.id) && f.isValid(instanciaProceso.id) ) {
    const formulario = {
      '55000030_Activity_ElaborarSolicitud_WF03': () => f.isValid(solicitud)?<WF03Solicitud instanciaTarea={instanciaTarea}
                                                                       instanciaProceso={instanciaProceso}
                                                                       solicitud={solicitud}
                                                                       perfilUsuario={perfilUsuario} />:false,
      '55000031_Activity_AsignarCaso_WF03': () => <P01SolicitudAsignacionCaso id="55000031_Activity_AsignarCaso_WF03"/>,
      '55000031_Activity_VerificarCumplimientoRequisitos_WF03': () => <P03VerificarCumplimiento id="55000031_Activity_AsignarCaso_WF03"
                                                                                                instanciaTarea={instanciaTarea}
                                                                                                instanciaProceso={instanciaProceso}
                                                                                                solicitud={solicitud}
                                                                                                perfilUsuario={perfilUsuario} />,
      '55000031_Activity_RegistrarResolucion_WF03': () => <P03RegistrarResolucion id="55000031_Activity_RegistrarResolucion_WF03"
                                                                                    instanciaTarea={instanciaTarea}
                                                                                    instanciaProceso={instanciaProceso}
                                                                                    solicitud={solicitud}
                                                                                    perfilUsuario={perfilUsuario} />,
      '55000031_Activity_RevisarFactibilidadSolicitud_WF03': () => <P03RevisarFactivilidad id="55000031_Activity_RevisarFactibilidadSolicitud_WF03"
                                                                                           instanciaTarea={instanciaTarea}
                                                                                           instanciaProceso={instanciaProceso}
                                                                                           solicitud={solicitud}
                                                                                           perfilUsuario={perfilUsuario} />,
      '55000031_Activity_AsignarCasoMaate_WF03': () => <P01SolicitudAsignacionCaso id="55000031_Activity_AsignarCasoMaate_WF03" />,
      '55000031_Activity_AsignarCasoSenadi_WF03': () => <P01SolicitudAsignacionCaso id="55000031_Activity_AsignarCasoSenadi_WF03" />,
      '55000031_Activity_AsignarCasoSenescyt_WF03': () => <P01SolicitudAsignacionCaso id="55000031_Activity_AsignarCasoSenescyt_WF03" />,
      '55000031_Activity_ElaborarInformeTecnicoSenescyt_WF03': () => <P03InformeTecnicoSENESCYT id="55000031_Activity_ElaborarInformeTecnicoSenescyt_WF03"
                                                                                                instanciaTarea={instanciaTarea}
                                                                                                instanciaProceso={instanciaProceso}
                                                                                                solicitud={solicitud}
                                                                                                perfilUsuario={perfilUsuario} />,
      '55000031_Activity_ElaborarInformeTecnicoMaate_WF03': () => <P03InformeTecnicoMAATE id="55000031_Activity_ElaborarInformeTecnicoMaate_WF03"
                                                                                          instanciaTarea={instanciaTarea}
                                                                                          instanciaProceso={instanciaProceso}
                                                                                          solicitud={solicitud}
                                                                                          perfilUsuario={perfilUsuario}/>,
      '55000031_Activity_ElaborarInformeTecnicoSenadi_WF03': () => <P03InformeTecnicoSENADI id={'55000031_Activity_ElaborarInformeTecnicoSenadi_WF03'}
                                                                                            instanciaTarea={instanciaTarea}
                                                                                            instanciaProceso={instanciaProceso}
                                                                                            solicitud={solicitud}
                                                                                            perfilUsuario={perfilUsuario} />,
      '55000031_Activity_EmitirDictamenTecnicoMaate': () => <P03DictamenTecnicoMAATE id={'55000031_Activity_EmitirDictamenTecnicoMaate'}
                                                                                     instanciaTarea={instanciaTarea}
                                                                                     instanciaProceso={instanciaProceso}
                                                                                     solicitud={solicitud}
                                                                                     perfilUsuario={perfilUsuario} />,
      '55000031_Activity_EmitirDictamenTecnicoSenescyt_WF03': () => <P03DictamenTecnicoSENESCYT id="55000031_Activity_ElaborarInformeTecnicoSenescyt_WF03"
                                                                                                instanciaTarea={instanciaTarea}
                                                                                                instanciaProceso={instanciaProceso}
                                                                                                solicitud={solicitud}
                                                                                                perfilUsuario={perfilUsuario} />,
      '55000031_Activity_EmitirDictamenTecnicoSenadi_WF03': () => <P03DictamenTecnicoSENADI id={'55000031_Activity_EmitirDictamenTecnicoSenadi_WF03'}
                                                                                            instanciaTarea={instanciaTarea}
                                                                                            instanciaProceso={instanciaProceso}
                                                                                            solicitud={solicitud}
                                                                                            perfilUsuario={perfilUsuario} />,
      '55000031_Activity_ElaborarResolucion_WF03' : () => <P03ElaborarResolucion id={'55000031_Activity_ElaborarResolucion_WF03'}
                                                                                 instanciaTarea={instanciaTarea}
                                                                                 instanciaProceso={instanciaProceso}
                                                                                 solicitud={solicitud}
                                                                                 perfilUsuario={perfilUsuario} />,
      '55000031_Activity_EmitirResolucion_WF03': () => <P03EmitirResolucion id={'55000031_Activity_EmitirResolucion_WF03'}
                                                                              instanciaTarea={instanciaTarea}
                                                                              instanciaProceso={instanciaProceso}
                                                                              solicitud={solicitud}
                                                                              perfilUsuario={perfilUsuario} />,
      '55000031_Activity_ElaborarPropuestaNegociacionInicial_WF03': () => <P03ElaborarPropuestaInicialNegociacion id={'55000031_Activity_ElaborarPropuestaNegociacionInicial_WF03'}
                                                                                             instanciaTarea={instanciaTarea}
                                                                                             instanciaProceso={instanciaProceso}
                                                                                             solicitud={solicitud}
                                                                                             perfilUsuario={perfilUsuario} />,
      '55000031_Activity_ElaborarInformeMejorasMaate_WF03': () => <P03ElaborarInformeMejorasMaate id={'55000031_Activity_ElaborarInformeMejorasMaate_WF03'}
                                                                                                  instanciaTarea={instanciaTarea}
                                                                                                  instanciaProceso={instanciaProceso}
                                                                                                  solicitud={solicitud}
                                                                                                  perfilUsuario={perfilUsuario} />,
      '55000031_Activity_ElaborarInformeMejorasSenescyt_WF03': () => <P03ElaborarInformeMejorasSenescyt id={'55000031_Activity_ElaborarInformeMejorasSenescyt_WF03'}
                                                                                                        instanciaTarea={instanciaTarea}
                                                                                                        instanciaProceso={instanciaProceso}
                                                                                                        solicitud={solicitud}
                                                                                                        perfilUsuario={perfilUsuario} />,
      '55000031_Activity_ElaborarInformeMejorasSenadi_WF03': () => <P03ElaborarInformeMejorasSenadi id={'55000031_Activity_ElaborarInformeMejorasSenadi_WF03'}
                                                                                                    instanciaTarea={instanciaTarea}
                                                                                                    instanciaProceso={instanciaProceso}
                                                                                                    solicitud={solicitud}
                                                                                                    perfilUsuario={perfilUsuario} />,

      '55000031_Activity_SugerirMejorasSenescyt_WF03': () => <P03SugerirMejorasSenescyt id={'55000031_Activity_SugerirMejorasSenescyt_WF03'}
                                                                                        instanciaTarea={instanciaTarea}
                                                                                        instanciaProceso={instanciaProceso}
                                                                                        solicitud={solicitud}
                                                                                        perfilUsuario={perfilUsuario} />,
      '55000031_Activity_SugerirMejorasMaate_WF03': () => <P03SugerirMejorasMaate id={'55000031_Activity_SugerirMejorasMaate_WF03'}
                                                                                  instanciaTarea={instanciaTarea}
                                                                                  instanciaProceso={instanciaProceso}
                                                                                  solicitud={solicitud}
                                                                                  perfilUsuario={perfilUsuario} />,
      '55000031_Activity_SugerirMejorasSenadi_WF03': () => <P03SugerirMejorasSenadi id={'55000031_Activity_SugerirMejorasSenadi_WF03'}
                                                                                    instanciaTarea={instanciaTarea}
                                                                                    instanciaProceso={instanciaProceso}
                                                                                    solicitud={solicitud}
                                                                                    perfilUsuario={perfilUsuario} />,
      '55000031_Activity_ElaborarPropuestaNegociacionFinal_WF03': () => <P03PropuestaNegociacionInabio id={'55000031_Activity_ElaborarPropuestaNegociacionFinal_WF03'}
                                                                                                       instanciaTarea={instanciaTarea}
                                                                                                       instanciaProceso={instanciaProceso}
                                                                                                       solicitud={solicitud}
                                                                                                       perfilUsuario={perfilUsuario} />,
      '55000031_Activity_EmitirResolucionFinal_WF03': () => <P03EmitirResolucionFinalInabio id={'55000031_Activity_EmitirResolucionFinal_WF03'}
                                                                                            instanciaTarea={instanciaTarea}
                                                                                            instanciaProceso={instanciaProceso}
                                                                                            solicitud={solicitud}
                                                                                            perfilUsuario={perfilUsuario} />,
      '55000031_Activity_ElaborarContrato_WF03': () => <P03ElaborarContrato id={'55000031_Activity_ElaborarContrato_WF03'}
                                                                            instanciaTarea={instanciaTarea}
                                                                            instanciaProceso={instanciaProceso}
                                                                            solicitud={solicitud}
                                                                            perfilUsuario={perfilUsuario} />,
      '55000031_Activity_RevisarContratoNegociador_WF03': () => <P03RevisarContrato id={'55000031_Activity_RevisarContratoNegociador_WF03'}
                                                                                     instanciaTarea={instanciaTarea}
                                                                                     instanciaProceso={instanciaProceso}
                                                                                     solicitud={solicitud}
                                                                                     perfilUsuario={perfilUsuario} />,

      '55000030_Activity_RevisarContratoInvestigador_WF03': () => <P03RevisarContratoInvestigador id={'55000031_Activity_RevisarContratoNegociador_WF03'}
                                                                                                       instanciaTarea={instanciaTarea}
                                                                                                       instanciaProceso={instanciaProceso}
                                                                                                       solicitud={solicitud}
                                                                                                       perfilUsuario={perfilUsuario} />,
      '55000031_Activity_RegistrarContrato_WF03': () => <P03RegistrarContrato id={'55000031_Activity_RegistrarContrato_WF03'}
                                                                              instanciaTarea={instanciaTarea}
                                                                              instanciaProceso={instanciaProceso}
                                                                              solicitud={solicitud}
                                                                              perfilUsuario={perfilUsuario} />,
      '55000031_Activity_RegistrarCertificadoInternacional_WF03': () => <P03RegistroInternacional id={'55000031_Activity_RegistrarCertificadoInternacional_WF03'}
                                                                                                  instanciaTarea={instanciaTarea}
                                                                                                  instanciaProceso={instanciaProceso}
                                                                                                  solicitud={solicitud}
                                                                                                  perfilUsuario={perfilUsuario} />,
    }[instanciaTarea?.tareaCodigoTarea]
    if(f.isValid(formulario)) {
      return formulario()
    } else {
      return <Box sx={{m:'80px 0 0 0'}}>{`ERROR, EN FORMULARIO PARA TAREA ${instanciaTarea?.nombreTarea} .- (${instanciaTarea?.tareaCodigoTarea})`}</Box>
    }
  }
  else return (
    <Box sx={{m:'80px 0 0 0', height:'80px'}}>
      {'loading'}
    </Box>
  )
}
