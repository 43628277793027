import React, {Fragment} from 'react'
import {Box} from "@mui/system";
import Typography from "@mui/material/Typography";
import {MyButtonBacan} from "../../../components/MyButtonBacan";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import GLOBALS from "../../App/globals";
import {dialog} from "../../../styles/styles";
import {MySubtitle} from "../../../components/MySubtitle";
import {Grid} from "@mui/material";

export const WF03Expediente = ({payload}) => {
  const docs = Object.keys(payload.INFO?.docs)?.sort((d1, d2) => (d1 >= d2)?1:-1)
  const titulos = {
    'd01Solicitud':        '1. Solicitud',
    'd02Resolucion':       '2. Resolución',
    'd03Factivilidad':     '3. Factibilidad',
    // 'd04InformeTecnico':   '4. Informe técnico',
    'd05DictamenTecnico':  '4. Dictamen técnico',
    'd06Resolucion':       '5. Resolución',
    'd07Negociacion':      '6. Negociación',
    'd08Mejoras':          '7. Sugerir mejoras',
    'd09Negociacion':      '8. Resolución final',
  }

  let idx3 = 0
  return (
    <Box>
      <Grid container spacing={1}>
        {
          docs?.map((doctema, idx) => {
            return (
              <Fragment key={idx}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={titulos[doctema]} />
                </Grid>
                {
                  payload.INFO?.docs[doctema]?.map((doc, idx2) => {
                    // console.log(`: doc : ${doctema}`, doc)
                    return (
                      <Fragment key={++idx3}>
                        <Grid item xs={6}>
                          {doc.desc}
                        </Grid>
                        <Grid key={idx} item xs={6}>
                          <MyButtonBacan icon={PictureAsPdfOutlinedIcon}
                                         label={'Ver documento'}
                                         onClick={ () => {
                                           const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${doc.solicitudId}/${doc.subFolder}/${doc.archivo}`
                                           fetch(url)
                                             .then((res) => { return res.blob(); })
                                             .then((data) => {
                                               const datas = new Blob([data], { type: 'application/pdf' })
                                               const a = document.createElement("a")
                                               a.href = window.URL.createObjectURL(datas)
                                               a.target="_blank"
                                               a.click()
                                             })
                                         }} />
                        </Grid>
                      </Fragment>
                    )
                  } )
                }
              </Fragment>
            )
          })
        }
        </Grid>
    </Box>
  )
}
