import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import SaveIcon from '@mui/icons-material/Save';
import { guardarPerfilesxUsuario } from './API';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { cerrarModalPerfiles } from './sliceAdministrador';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'descripcion',
        numeric: true,
        disablePadding: false,
        label: 'Descripción',
    },
];

const botonAzul = {
    mt: 1, ml: 2, maxWidth: 200,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
        backgroundColor: 'rgba(54, 138, 184, 1)',
    }
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead >
            <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={'normal'}
                        sx={{ fontWeight: 'bold' }}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

//funcion principal
export const PerfilesFuncionario2 = () => {

    const dispatch = useDispatch();

    const { perfilesOrganizacion, perfiles, funcionario } = useSelector(state => state.administracion)

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [estado, setEstado] = React.useState([])
    const [ids, setIds] = React.useState([])

    React.useEffect(() => {
        let resultado = []
        let arregloIds = []
        perfilesOrganizacion.forEach((row) => {
            resultado.push(perfiles.some(e => (e.idPerfil === row.id)))
            perfiles.some(e => (e.idPerfil === row.id)) && arregloIds.push(row.id)
        });
        setEstado(resultado);
        setIds(arregloIds);
    }, [perfilesOrganizacion])


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleCerrarModalPerfiles = () => {
        dispatch(cerrarModalPerfiles());
    }

    const handleChange = (position, id) => {

        const updatedCheckedState = estado.map((item, index) =>
            index === position ? !item : item
        );

        setEstado(updatedCheckedState);

        ids.some(e => (e === id)) ? quitarItem(id) : agregarItem(id)
    }

    const quitarItem = (id) => {
        let listaRe = [...ids]
        const l2 = listaRe.filter((item) => item !== id)
        setIds(l2)
    }

    const agregarItem = (id) => {
        let lista = [...ids]
        lista.push(id)
        setIds(lista)
    }

    console.log("estado", estado)
    console.log("ids", ids)

    const handleGuardar = () => {
        let arregloStrings = ''

        ids.map((item) => {
            return (
                arregloStrings = (arregloStrings !== '' ? (arregloStrings + ",") : '') + `${item}`
            )
        })

        dispatch(guardarPerfilesxUsuario(arregloStrings, funcionario[0].id));
    }

    return (
        <>
            <div>
                <Box sx={{ width: '100%' }}>
                    <Paper sx={{ width: '100%', mb: 2 }}>
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 400 }}
                                aria-labelledby="tableTitle"
                                size={'small'}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={perfilesOrganizacion.length}
                                />
                                <TableBody>

                                    {stableSort(perfilesOrganizacion, getComparator(order, orderBy))
                                        .map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={index}
                                                    key={row.id}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            id={`${row.id}`}
                                                            onChange={() => handleChange(index, row.id)}
                                                            checked={estado[index] || false}
                                                            color="primary"
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        align='left'
                                                    >
                                                        {row.descripcionPerfil}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>

                    <Stack direction="row" justifyContent="end">
                        <Button type="submit" onClick={handleGuardar} variant="contained"
                            sx={botonAzul}
                            startIcon={<SaveIcon />}
                        >
                            {'Guardar'}
                        </Button>
                        <Button color='success' variant="contained"
                            sx={botonAzul}
                            startIcon={<HighlightOffIcon />}
                            onClick={handleCerrarModalPerfiles}
                        >
                            {'Cerrar'}
                        </Button>
                    </Stack>
                </Box>
            </div >
        </>
    )
}