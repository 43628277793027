import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Swal from 'sweetalert2';
import "./swalestilo.css"
import { guardarRooms, retornaListaUsuariosChat } from './API';
import { AvatarResponde } from './AvatarResponde';

export const UsuarioList = () => {

    const dispatch = useDispatch();

    const { perfilUsuario, instanciaTarea, instanciaProceso } = useSelector(state => state.app);
    const { solicitud } = useSelector(state => state.app.instanciaProceso);
    
    React.useEffect(() => {
        dispatch(retornaListaUsuariosChat(instanciaProceso.solicitud.id))
    }, [])
        
    const {usuarios} = useSelector(state => state.chat)

    let listaFuncionarios = []

    listaFuncionarios = usuarios.slice();
    // listaFuncionarios = funcionarios.slice();
    // listaFuncionarios.push(instanciaProceso.perfilUsuario.usuario)

    const handlePersona = (event, id, name) => {

        Swal.fire({
            title: 'Nuevo chat-room',
            text: `Está seguro de iniciar un chat con el funcionario: ${name}`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'rgba(54, 160, 184, 1)',
            cancelButtonColor: 'rgba(54, 160, 184, 1)',
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            customClass: {
                container: 'my-swal'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(guardarRooms(solicitud.id, instanciaTarea.id, perfilUsuario.id, id));
            }
        })
    }

    return (
        <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {listaFuncionarios.map((usuario) => {
                const labelId = `checkbox-list-secondary-label-${usuario.id}`;
                return (
                    <div>
                        <ListItem
                            key={usuario.id}
                            secondaryAction={
                                <Button type="submit" color='success' fullWidth variant="contained"
                                    startIcon={<CheckCircleIcon />} onClick={(event) => handlePersona(event, usuario.id, usuario.nombreUsuario)}
                                >
                                </Button>
                            }
                            disablePadding
                        >
                            <ListItemButton >
                                <AvatarResponde id={usuario.idUsuario} nombre={usuario.nombreUsuario}/>
                                <ListItemText
                                    sx={{ maxWidth: 250, ml: 2 }}
                                    id={labelId}
                                    primary={`${usuario.nombreUsuario}`}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                <strong>{`${usuario.descripcionPerfil || 'N/A'}`}</strong>
                                            </Typography>
                                        </React.Fragment>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    </div>
                );
            })}
        </List>
    );
}
