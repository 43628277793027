import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarModalInstitutos } from './sliceAdministrador';
import { FormIesIpi } from './FormIesIpi';
import { FormIts } from './FormIts';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export const ModalInstitutos = (tipo) => {

    const dispatch = useDispatch()

    const { modalInstitutos, editando } = useSelector(state => state.administracion)

    const handleClose = () => {
        dispatch(cerrarModalInstitutos());
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalInstitutos}
                maxWidth={'xs'}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {editando ? `Editar ${tipo.tipo === 1 ? ' IES' : (tipo.tipo === 2 ? ' IPI' : ' ITS')}` : `Nuevo ${tipo.tipo === 1 ? ' IES' : (tipo.tipo === 2 ? ' IPI' : ' ITS')}`}
                </BootstrapDialogTitle>
                <DialogContent dividers >
                    {(tipo.tipo === 1 && <FormIesIpi />) || (tipo.tipo === 2 && <FormIesIpi />) || (tipo.tipo === 3 && <FormIts />)}
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
