import React, {useState} from 'react'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import RobotoCondensedLight from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Light.ttf'
import {Tooltip} from "@mui/material";
import {LoadingButton} from "@mui/lab";

export const MyButtonBacan3 = ({
                                label='',
                                onClick=() => alert('on click ... not implemented'),
                                icon=ArrowBackIcon,
                                bgColor0='rgba(54, 138, 184, 1)',
                                color0='rgba(255, 255, 255, 0.6)',
                                bgColor1='rgba(54, 160, 184, 1)',
                                color1='rgba(255, 255, 255, 0.9)',
                                disabled=false,
                                width='9rem',
                                toolTip=''
                              }) => {
  const height= '36px'
  const MyIcon=icon
  if(toolTip !== '') {
    return (
      <>
        <Tooltip title={toolTip}>
          <LoadingButton variant='contained'
                         disabled={disabled}
                         loading={false}
                         loadingPosition={'start'}
                         sx={{
                           borderRadius: '0.2rem',
                           p:0,
                           height,
                           width,
                           fontFamily: RobotoCondensedLight,
                           fontSize: '0.8rem',
                           fontWeight: 'normal',
                           color: color1,
                           backgroundColor: bgColor1,
                           "&:hover":{
                             backgroundColor: bgColor0,
                           },
                           "&:disabled":{
                             backgroundColor: '#888888',
                             color: '#444444'
                           },
                         }}
                         startIcon={<MyIcon sx={{height:'1rem', fill: color0, }} />}
                         size={'small'}
                         onClick={() => {
                           onClick()
                         }} >
            {label}
          </LoadingButton>
        </Tooltip>
      </>
  )
  } else {
    return (
      <>
        <LoadingButton variant='contained'
                       disabled={disabled}
                       sx={{
                         borderRadius: '0.2rem',
                         p:0,
                         height,
                         width,
                         fontFamily: RobotoCondensedLight,
                         fontSize: '0.8rem',
                         fontWeight: 'normal',
                         color: color1,
                         backgroundColor: bgColor1,
                         "&:hover":{
                           backgroundColor: bgColor0,
                         },
                         "&:disabled":{
                           backgroundColor: '#888888',
                           color: '#444444'
                         },
                       }}
                       startIcon={<MyIcon sx={{height:'1rem', fill: color0, }} />}
                       size={'small'}
                       onClick={onClick} >
          {label}
        </LoadingButton>
      </>
    )
  }
}
