import React, {useEffect, useState} from 'react'
import { useSelector} from 'react-redux'
import {
  Box,
  Grid,
} from '@mui/material'
import {format, parseISO} from 'date-fns'
import {es} from 'date-fns/locale'
import {dialog} from 'src/styles/styles'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import {MySwitch} from 'src/components/MySwitch'
import API from 'src/features/App/API'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyTextField} from 'src/components/MyTextField'
import {Stack} from "@mui/system";
import Table from "@mui/material/Table"
import TableRow from "@mui/material/TableRow"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody";
import {MyButtonBacan2} from 'src/components/MyButtonBacan2'
import SearchIcon from '@mui/icons-material/Search';

export const AccesoConocimiento = ({section, formValues, setFormValues, canEdit, formErrors}) => {
  const [sectionValues, setSectionValues] = useState({...formValues[section]})
  const sectionErrors = (f.isValid(formErrors) && f.isValid(formErrors[section])) ? formErrors[section] : null
  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  useEffect(() => {
    if(canEdit && f.isValid(setFormValues)) {
      setFormValues({...formValues,[section]: {...sectionValues}})
    }
  }, [sectionValues])
  const {clpis, clpiContratos} = useSelector(state => state.app)
  const hstyle = {width:'20%', fontWeight:'bold', p:'0.5rem'}
  const dstyle = {width:'20%', p:'0.5rem'}
  return(
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Conocimiento tradicional'} />
        </Grid>
        <Grid item xs={12}>
          <MySwitch id='accesoConocimiento'
                    label={'¿Existe acceso, uso o aprovechamiento de conocimientos tradicionales asociados a la biodiversidad?'}
                    formValues={sectionValues}
                    canEdit={canEdit}
                    handleChange={handleChange}/>
        </Grid>
        {sectionValues['accesoConocimiento'] &&
          <>
            <Grid item xs={4}>
              <Stack direction={'row'}>
                <Box sx={{width:'70%'}}>
                  <MyTextField id={'clpi'}
                               label={'Número de registro CLPI'}
                               canEdit={canEdit}
                               setFormValues={setSectionValues}
                               formValues={sectionValues}
                               formErrors={formErrors}
                               error={formErrors['clpi']}
                               placeholder={'Número de registro CLPI'}
                               handleChange={handleChange}
                               icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
                </Box>
                <Box sx={{width:'30%'}}>
                  <MyButtonBacan2 label={'buscar'}
                                  icon={SearchIcon}
                                  onClick={() => {
                                    API.buscarCLPI(sectionValues.clpi).then((res) => {
                                      setSectionValues({
                                        ...sectionValues,
                                        numTramite: f.StringOrEmpty(res.numTramite),
                                        fechaPresentacion: f.StringOrEmpty(res.fechaPresentacion),
                                        fechaOtorgamiento: f.StringOrEmpty(res.fechaOtorgamiento),
                                        fechaFinVigencia: f.StringOrEmpty(res.fechaFinVigencia),
                                        fechaRegistro: f.StringOrEmpty(res.fechaRegistro),
                                        titulo: f.StringOrEmpty(res.titulo),
                                        legitimosPoseedores: f.StringOrEmpty(res.legitimosPoseedores),
                                        nacionalidad: f.StringOrEmpty(res.nacionalidad),
                                        solicitante: f.StringOrEmpty(res.nombreSolicitante),
                                        paisOrigen: f.StringOrEmpty(res.paisOrigenSol),
                                        usuariosAutorizados: f.string2json(res.usuariosAutorizados, '[]'),
                                        tipo: f.StringOrEmpty(res.tipo),
                                      })
                                    })
                                  }}
                                  padding={'1.8rem 0 0 0'} />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack direction={'row'}>
                <Box sx={{width:'80%'}}>
                  <MyTextField id={'contratoAccesoConocimiento'}
                               label={'Número de registro de contrato de acceso, uso o aprovechamiento del conocimiento tradicional'}
                               canEdit={canEdit}
                               setFormValues={setSectionValues}
                               formValues={sectionValues}
                               formErrors={sectionErrors}
                               placeholder={'SENADI-C-###-####'}
                               // error={formErrors['contratoAccesoConocimiento']}
                               handleChange={handleChange}
                               icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
                </Box>
                <Box sx={{width:'20%'}}>
                  <MyButtonBacan2 label={'buscar'}
                                  icon={SearchIcon}
                                  onClick={() => {
                                    API.buscarCONTRATO(sectionValues.contratoAccesoConocimiento).then((res) => {
                                      setSectionValues({
                                        ...sectionValues,
                                        numTramite2: f.StringOrEmpty(res.numTramite),
                                        fechaPresentacion2: f.StringOrEmpty(res.fechaPresentacion),
                                        fechaOtorgamiento2: f.StringOrEmpty(res.fechaOtorgamiento),
                                        fechaFinVigencia2: f.StringOrEmpty(res.fechaFinVigencia),
                                        fechaRegistro2: f.StringOrEmpty(res.fechaRegistro),
                                        titulo2: f.StringOrEmpty(res.titulo),
                                        legitimosPoseedores2: f.StringOrEmpty(res.legitimosPoseedores),
                                        nacionalidad2: f.StringOrEmpty(res.nacionalidad),
                                        solicitante2: f.StringOrEmpty(res.nombreSolicitante),
                                        paisOrigen2: f.StringOrEmpty(res.paisOrigenSol),
                                        usuariosAutorizados2: f.string2json(res.usuariosAutorizados, '[]'),
                                        tipo2: f.StringOrEmpty(res.tipo),
                                      })
                                    })
                                  }}
                                  padding={'1.8rem 0 0 0'} />
                </Box>
              </Stack>
            </Grid>
            <Grid container spacing={4} >
              <Grid item xs={6}>
                <Box sx={{width:'100%', border: '1px dotted black', borderRadius:'0.5rem'}}>
                  <Table sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
                    <TableBody>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Trámite Nro.</TableCell><TableCell>{sectionValues.numTramite}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Presentación</TableCell><TableCell>{f.isValidNotEmpty(sectionValues.fechaPresentacion) && format(parseISO(sectionValues.fechaPresentacion), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de otorgamiento del CLPI</TableCell><TableCell>{f.isValidNotEmpty(sectionValues.fechaOtorgamiento) && format(parseISO(sectionValues.fechaOtorgamiento), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Vigencia</TableCell><TableCell>{f.isValidNotEmpty(sectionValues.fechaFinVigencia) && format(parseISO(sectionValues.fechaFinVigencia), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Registro</TableCell><TableCell>{f.isValidNotEmpty(sectionValues.fechaRegistro) && format(parseISO(sectionValues.fechaRegistro), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Título del Plan de acceso, uso o aprovechamiento del conocimiento tradicional</TableCell><TableCell>{sectionValues.titulo}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Legítimos Poseedores</TableCell><TableCell>{sectionValues.legitimosPoseedores}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Nacionalidad / Pueblo / Comunidad</TableCell><TableCell>{sectionValues.nacionalidad}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Solicitante</TableCell><TableCell>{sectionValues.solicitante}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>País Origen Solicitante</TableCell><TableCell>{sectionValues.paisOrigen}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Usuarios de CC.TT. Autorizados</TableCell><TableCell>{f.isValidNotEmpty(sectionValues.usuariosAutorizados) && sectionValues.usuariosAutorizados?.map(it => `${it.usuario} (${it.pais})`)?.join(', ')}</TableCell></TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{width:'100%', border: '1px dotted black', borderRadius:'0.5rem'}}>
                  <Table sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
                    <TableBody>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Trámite Nro.</TableCell><TableCell>{sectionValues.numTramite2}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Presentación</TableCell><TableCell>{f.isValidNotEmpty(sectionValues.fechaPresentacion2) && format(parseISO(sectionValues.fechaPresentacion2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de suscripción del contrato</TableCell><TableCell>{f.isValidNotEmpty(sectionValues.fechaOtorgamiento2) && format(parseISO(sectionValues.fechaOtorgamiento2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Vigencia</TableCell><TableCell>{f.isValidNotEmpty(sectionValues.fechaFinVigencia2) && format(parseISO(sectionValues.fechaFinVigencia2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Registro</TableCell><TableCell>{f.isValidNotEmpty(sectionValues.fechaRegistro2) && format(parseISO(sectionValues.fechaRegistro2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Título del Plan de acceso, uso o aprovechamiento del conocimiento tradicional</TableCell><TableCell>{sectionValues.titulo2}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Legítimos poseedores</TableCell><TableCell>{sectionValues.legitimosPoseedores2}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Nacionalidad / Pueblo / Comunidad</TableCell><TableCell>{sectionValues.nacionalidad2}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Solicitante</TableCell><TableCell>{sectionValues.solicitante2}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>País Origen Solicitante</TableCell><TableCell>{sectionValues.paisOrigen2}</TableCell></TableRow>
                      <TableRow><TableCell sx={hstyle} align={'left'}>Usuarios de CC.TT. Autorizados</TableCell><TableCell>{f.isValidNotEmpty(sectionValues.usuariosAutorizados2) && sectionValues.usuariosAutorizados2?.map(it => `${it.usuario} (${it.pais})`)?.join(', ')}</TableCell></TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Grid>
            </Grid>
          </>
        }
        {!sectionValues['accesoConocimiento'] ?
          <>
            <Grid item xs={12}>
              <MySwitch id='ambitoComunitario'
                        label={'¿La investigación se desarrolla en un ámbito comunitario ancestral?'}
                        formValues={sectionValues}
                        canEdit={canEdit}
                        handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='valoresReligiososCultutrales'
                        label={'¿La investigación hace referencia a temas de cosmovisión valores religiosos o culturales?'}
                        formValues={sectionValues}
                        canEdit={canEdit}
                        handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='recursosBiologico'
                        label={'¿La investigación se relaciona con un ritual curativo donde se utilice los recursos biológicos?'}
                        formValues={sectionValues}
                        canEdit={canEdit}
                        handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='practicasAncestrales'
                        label={'¿La investigación versan sobre mecanismos y prácticas de siembra cosecha, mantenimiento y recolección de semillas entre otras prácticas agropecuarias ancestrales?'}
                        formValues={sectionValues}
                        canEdit={canEdit}
                        handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='etnozoologicos'
                        label={'¿La investigación se basa en estudio de carácter etnobotánico o etnozoologico?'}
                        formValues={sectionValues}
                        canEdit={canEdit}
                        handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='aConocimientoTradicional'
                        label={'¿La investigación hace referencia sobre el uso de un recurso biológico planta o animal asociado a un conocimiento tradicional?'}
                        formValues={sectionValues}
                        canEdit={canEdit}
                        handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='derivadosOSimilares'
                        label={'¿La investigación hace referencia a compuestos biológicos naturales para la elaboración de productos alimenticios dieteticos colorantes cosméticos y derevados o similares?'}
                        formValues={sectionValues}
                        canEdit={canEdit}
                        handleChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <MySwitch id='medicinaTradicional'
                      label={'¿La investigacion se basa en combinaciones de extractos biológicos naturales para la preparación de medicina tradicional?'}
                      formValues={sectionValues}
                      canEdit={canEdit}
                      handleChange={handleChange}/>
          </>:null
        }
      </Grid>
    </Box>
  )
}
