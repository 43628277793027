import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch } from 'react-redux';
import { seleccionarTipo } from './sliceUsuarios';
import { FcBusinessman, FcBiomass } from "react-icons/fc";
import { IconContext } from "react-icons";
import { Grid } from '@mui/material';

export const TipoUsuario = () => {
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValue(event.target.value);
    dispatch(seleccionarTipo(event.target.value));
  };

  return (
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">Tipo de cuenta</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {/* <IconContext.Provider value={{ size: 110 }}>
          <div>
          <FcBiomass />
          </div>
        </IconContext.Provider> */}

        <Grid item xs={6} sx={{ mt: 2 }}>
          <img src="/investigador.jpeg" alt="investigadores" width={120} height={121} />
        </Grid>

        <FormControlLabel value="INVESTIGADOR" control={<Radio color='success' />} label="Investigador" />
        <IconContext.Provider value={{ size: 140 }}>
          <div>
            <FcBusinessman />
          </div>
        </IconContext.Provider>

        <FormControlLabel value="FUNCIONARIO" control={<Radio />} label="Funcionario" />
      </RadioGroup>
    </FormControl>
  );
}
