import React, {useEffect, useRef, useState} from 'react'
import {Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import {
  handleSaveSolicitud,
  handleClear,
  handCompletarTareaCoordinador
} from 'src/features/App/sliceApp'
import {MySendButton} from 'src/components/MySendButton'
import {SectionTitle} from 'src/components/SectionTitle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import {f} from 'src/commons/f'
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MySubtitle} from "../../components/MySubtitle";
import {Email} from "@mui/icons-material";
import API from 'src/features/App/API'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import GLOBALS from "../App/globals";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "../P01Solicitud/P0102PayloadRO";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import DoneAllIcon from "@mui/icons-material/DoneAll";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch()
  const section = 'DictamenTecnicoSenadi'
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:         today,
    identificador: solicitud.numeroSolicitud,
    serial:        '',
    docx:          '',
    pdf:           '',
  }
  const canEdit = instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, 'entrada', setFormValues, formValues)
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  const toBPM = {
    seguimientoSenadi:    formValues.pronunciamientoSenadi,
    seguimientoMaate:     formValues.pronunciamientoMaate,
    obligacionesVencidas: false,
    monitoreoCompleto:    false,
    masConsultas:         formValues.pronunciamientoSenadi || formValues.pronunciamientoMaate,
    subsanacionSenescyt:  formValues.subsanacion,
  }
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => {setMytab(newTab)}
  const inputRef = useRef()
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder0 = `informe-tecnico-Senadi`
  const subfolder = `dictamen-tecnico-Senadi`
  const filename = `dictamen-tecnico-${formValues.identificador}-Senadi`
  const filenamePDF = `${filename}.pdf`
  const codigoAutorizador = {
    'Maate':    1143,
    'Senadi':   1148,
    'Senescyt': 1139,
  }[nombreOrganizacion]
  const codigoCoordinador = {
    'Maate':    1144,
    'Senadi':   1148,
    'Senescyt': 164,
  }[nombreOrganizacion]
  const funcionarios = useSelector(state => state.app.funcionarios)
  const autorizador = funcionarios.filter(it => it.idPerfil === codigoAutorizador)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === codigoCoordinador)[0]
  // const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
          instanciaProceso?.id,
          payload,
          () => setCounter(0)
        )
      )
    }
  }
  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Dictamen Técnico Senadi'} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
          <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{p:'0 2rem 0 2rem'}}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Reporte de cumplimiento'} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'1rem 1rem 1rem 0'}}>
                <MyReadOnlyTextField id={'solicitudNumero'}
                                     value={solicitud?.numeroSolicitud}
                                     label={'Identificador'}
                                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                <MyReadOnlyTextField id={'fecha'}
                                     value={payload.InformeSeguimiento?.fecha}
                                     label={'Fecha'}
                                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                <MyButtonBacan3 label={'Informe'}
                                icon={FileDownloadIcon}
                                onClick={() => API.fetchDownloadPDF({
                                  solicitudId: instanciaProceso?.solicitud?.id,
                                  subfolder:'informe-cumplimiento-investigador',
                                  filename: payload.InformeSeguimiento.pdf
                                })}
                                toolTip={'Descargar documento en formato pdf'}
                                width={'7rem'} />
                {(payload.InformeSeguimiento?.anexo !== '') ?
                  <MyButtonBacan label={'Anexo'}
                                 onClick={() => {
                                   const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/anexo/${payload.InformeSeguimiento?.anexo}`
                                   fetch(url)
                                     .then((res) => {
                                       return res.blob();
                                     })
                                     .then((data) => {
                                       const dataPdf = new Blob([data], {type: 'application/pdf'})
                                       const a = document.createElement("a")
                                       a.href = window.URL.createObjectURL(dataPdf)
                                       a.target = "_blank"
                                       a.click()
                                     })
                                 }}
                                 icon={FileDownloadOutlinedIcon}/> : null
                }
              </Stack>
            </Grid>
            <Grid item xs={8} >
              <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1} style={{padding:'0 0 0 1rem'}}>
                <MyButtonBacan3 label={'DOCX'}
                                icon={FileDownloadIcon}
                                onClick={() => API.fetchDownloadDOCX({
                                  solicitudId: instanciaProceso?.solicitud?.id,
                                  subfolder: subfolder0,
                                  filename: payload.InformeTecnicoSenadi.docx,
                                })}
                                toolTip={'Descargar documento en formato docx'}
                                width={'5rem'} />
                <MyButtonBacan3 label={'PDF'}
                                icon={FileDownloadIcon}
                                onClick={() => API.fetchDownloadPDF({
                                  solicitudId: instanciaProceso?.solicitud?.id,
                                  subfolder: subfolder0,
                                  filename: payload.InformeTecnicoSenadi.pdf,
                                })}
                                toolTip={'Descargar documento en formato pdf'}
                                width={'5rem'} />
                <MyFileUploadButton inputRef={inputRef}
                                    label={'PDF (firmado)'}
                                    solicitudId={instanciaProceso?.solicitud?.id}
                                    subfolder={subfolder}
                                    fileName={filenamePDF}
                                    afterUpload={() => setFormValues({...formValues, pdf: filenamePDF})}
                                    toolTip={'Subir documento firmado o para firmar en formato pdf'}
                                    width={'11rem'} />
                <MyFileUploadButton inputRef={inputRef}
                                    label={'firmar pdf'}
                                    solicitudId={instanciaProceso?.solicitud?.id}
                                    subfolder={subfolder}
                                    fileName={filenamePDF}
                                    afterUpload={() => setFormValues({...formValues, pdf: filenamePDF})}
                                    disabled={true}
                                    icon={() => <DoneAllIcon height={'1rem'} fill={'rgba(255, 255, 255, 0.6)'} />}
                                    toolTip={'Firmar documento electrónicamente'}
                                    width={'11rem'} />
                {
                  formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                 icon={FileDownloadIcon}
                                                                 onClick={() => API.fetchDownloadPDF({
                                                                   solicitudId: instanciaProceso?.solicitud?.id,
                                                                   subfolder,
                                                                   filename: filenamePDF,
                                                                 })}
                                                                 toolTip={'Descargar dictamen tecnico'}
                                                                 width={'2rem'}/>
                }
              </Stack>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <P0102PayloadRO payload={payload.Solicitud.solicitudAprobada.payloadSolicitud} />
        </TabPanel>
      </TabContext>

      <CssBaseline />
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'0 4rem 0 4rem'}}>
                <MyButtonBacan label={'Regresar'}
                               myTip={'Regresar a las tareas'}
                               icon={ArrowBackIcon}
                               onClick={() => { dispatch(handleClear()) }} />
                <MyButtonBacan label={'Guardar'}
                               myTip={'Guarda el formulario, y permite continuar editando'}
                               onClick={() => {
                                 payload[section]=formValues
                                 setCounter(0)
                                 dispatch(handleSaveSolicitud(instanciaProceso?.id,payload))
                               }}
                               disabled={!canEdit || counter <= 0}
                               icon={SaveOutlinedIcon} />
                <MySendButton onSend={ () => {
                                const metadata = JSON.stringify({
                                  "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                  "seguimientoMaate": false,
                                  "seguimientoSenadi": false,
                                  "subsanacionSenescyt": false
                                })
                                dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'Enviar'}
                              disabled={!canEdit || counter > 0 || formValues.pdf === ''} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

const plantilla = ""
  + '${TITULO1}PRONUNCIAMIENTO Nro.'
  + '${SALTO}${CENTRAR}<<serial>>'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}De mi consideración.'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}En referencia a la solicitud de permiso de investigación Nro. <<solicitud.identificador>> a través del cual solicita autorización para desarrollar el proyecto denominado <<solicitud.nombreProyecto>>.'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>[POSITIVO]'
  + '${SALTO}${PARRAFO.CONDICION}<<positivo>>Me permito indicar que esta cartera de estado recomienda CONTINUAR CON EL TRÁMITE PARA OBTENCIÓN DE UN <<solicitud.tipoDePermiso>> de acuerdo al informe técnico Nro. <<sistema.identificadorInformeTecnicoMaate>> adjunto al presente pronunciamiento.'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>[NEGATIVO]'
  + '${SALTO}${PARRAFO.CONDICION}<<negativo>>Me permito indicar que esta cartera de estado recomienda rechazar la propuesta debido a las observaciones realizadas en el informe técnico de evaluación Nro. <<sistema.identificadorInformeTecnicoMaate>> adjunto al presente pronunciamiento.'
  + '${SALTO}${PARRAFO}Con sentimientos de distinguida consideración.'
  + '${SALTO}'
  + '${SALTO}${VAR}Fecha:${:}<<sistema.fecha>>'
  + '${SALTO3}'
  + '${SALTO}${CENTRAR}<<sistema.nombreAutorizadorSectorialMaate>>'
  + '${SALTO}${CENTRAR}<<sistema.rolAutorizadorSectorialMaate>>'
  + '${SALTO}${CENTRAR}<<usuario.cargoAutorizadorSectorialMaate>>'
