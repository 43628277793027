
import { withFormik } from "formik";
import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux'
import { validacionForm } from "./validacionForm";
import { verificarInvestigadorRegistro } from "./API";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const form = props => {

    const theme = createTheme();

    const botonVerde = {
        ml: 0, mt: 1,
        textTransform: 'none',
        borderRadius: '0.2rem',
        color: 'rgba(255, 255, 255, 0.9)',
        backgroundColor: 'rgba(14, 181, 14, 1)',
        "&:hover": {
            backgroundColor: 'rgba(18, 219, 18, 1)',
        }
    }

    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        tipo
    } = props;

    return (
        <div >
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="sm">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0 }}>
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <TextField
                                        id="identificador"
                                        label="Registro"
                                        value={values.identificador}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.identificador ? errors.identificador : ""}
                                        error={touched.identificador && Boolean(errors.identificador)}
                                        autoComplete="off"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>

                            </Grid>

                            <Button type="submit" fullWidth variant="contained"
                                sx={botonVerde} startIcon={<AddCircleOutlineIcon />}
                            >
                                {'Buscar'}
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </div >
    );
};

const Registro = withFormik({

    enableReinitialize: true,

    mapPropsToValues: (props) => ({
        identificador: '',
    }),

    validationSchema: validacionForm,

    handleSubmit: (values, { props, setSubmitting }) => {
        props.verificacionDeInvestigador(values.identificador);
        setSubmitting(false);
    }
})(form);

const mapStateToProps = (
    state
) => ({

})

const mapDispatchToProps = (dispatch) => ({
    verificacionDeInvestigador: (values) => {
        dispatch(verificarInvestigadorRegistro(values));
    }
})

export const FormRegistro= connect(mapStateToProps, mapDispatchToProps)(Registro)