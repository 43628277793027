
import { withFormik } from "formik";
import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux'
import { InstitucionesSelect } from "./InstitucionesSelect";
import { RolSelect } from "./RolSelect";
import { guardarCuenta } from "./API";
import SendIcon from '@mui/icons-material/Send';

const form = props => {

  const theme = createTheme();

  const botonVerde = {
    ml: 2, mt: 3,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(14, 181, 14, 1)',
    "&:hover": {
      backgroundColor: 'rgba(18, 219, 18, 1)',
    }
  }

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue
  } = props;

  return (
    <div >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 0 }}>
              <Grid container spacing={1}>

                <Grid item xs={12}>
                  <TextField
                    id="nombre"
                    name="nombre"
                    label="Nombres"
                    value={values.nombre}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.nombre ? errors.nombre : ""}
                    error={touched.nombre && Boolean(errors.nombre)}
                    inputProps={{ style: { textTransform: 'capitalize' } }}
                    autoComplete="off"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="mail"
                    name="mail"
                    label="Correo Electrónico"
                    value={values.mail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={touched.mail ? errors.mail : ""}
                    error={touched.mail && Boolean(errors.mail)}
                    inputProps={{ style: { textTransform: 'lowercase' } }}
                    autoComplete="off"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InstitucionesSelect name='institucion' tipo={1} />
                </Grid>

                <Grid item xs={12}>
                  <RolSelect name='rolId' />
                </Grid>

                <Grid container spacing={1} justifyContent='right'>
                  <Button type="submit" color='success' variant="contained"
                    sx={botonVerde}
                    startIcon={<SendIcon />}
                  >
                    {'Enviar'}
                  </Button>
                </Grid>

              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </div >
  );
};

const InvestigadorForm = withFormik({

  enableReinitialize: true,

  mapPropsToValues: (props) => ({
    // mail: props.initialValues ? props.initialValues?.correoUsuario : "",
    mail: props.initialValues ? props.initialValues?.email : "",
    nombre: props.initialValues ? props.initialValues?.nombresCompletos : "",
    // nombre: props.initialValues ? props.initialValues?.nombreUsuario : "",
    codigo: props.initialValues ? props.initialValues?.codigoUsuario : "",
    cedula: props.initialValues ? props.initialValues?.cedulaUsuario : "",
    pasaporte: props.initialValues ? props.initialValues?.pasaporteUsuario : "",
    username: props.initialValues ? props.initialValues?.usernameUsuario : "",
    institucion: 510001,
    tipo: props.tipo ? props.tipo : "",
    nombreOrganizacion: props.organizacion ? props.organizacion.nombreOrganizacion : "",
    codigoOrganizacion: props.organizacion ? props.organizacion.codigoOrganizacion : "",
    descripcionOrganizacion: props.organizacion ? props.organizacion.descripcionOrganizacion : "",
  }),

  // validationSchema: validacionFinca,

  handleSubmit: (values, { props, setSubmitting }) => {
    // console.log("values-> ", values)
    props.registroDeCuenta(values);
    setSubmitting(false);
  }
})(form);

const mapStateToProps = (
  state
) => ({
  initialValues: state.usuarios.investigador,
  organizacion: state.usuarios.institucion,
  tipo: state.usuarios.tipoSeleccionado
})

//función para usar un dispatch mediante props
const mapDispatchToProps = (dispatch) => ({
  registroDeCuenta: (values) => {
    dispatch(guardarCuenta(values))
  }
})

export const FormDatos = connect(mapStateToProps, mapDispatchToProps)(InvestigadorForm)