import GLOBALS from 'src/features/App/globals'
import Swal from 'sweetalert2'
import "../chat/swalestilo.css"
import { cargarRoles, cargarFirmas, cargarFuncionarios, cargarIes, cargarInvestigadores, cargarIpi, cargarIts, cargarListaAreasProtegidas, cargarListaBosques, cargarPerfiles, cargarPerfilesOrganizacion, cargarProcesos, cargarProvincias, cargarTareas, cerrarModalBosques, cerrarModalInstitutos, cerrarModalPerfiles, quitarSeleccion, seleccionarOrden, restaurarOrden, cargarSolicitudes, cargarSeguimientoProcesos, cargarPlantillas, seleccionarPlantilla, cerrarModalPlantilla, cargarAreas, cerrarModalAreaInvestigacion, quitarAreaInvestigacion, cargarLineas, cerrarModalLineas, quitarLineaSeleccionada, abrirModalContratos } from './sliceAdministrador'
import { quitarPlantillaSeleccionada } from './sliceAdministrador';

export const retornaFuncionarios = () => {
    return async (dispatch) => {

        const resp = await fetchFuncionarios()
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            dispatch(cargarFuncionarios(body))
        } else {
            dispatch(dialogoError("Error cargar los funcionarios"));
        }
    }
}

export const retornaInvestigadores = (texto) => {
    return async (dispatch) => {

        const resp = await fetchInvestigadores(texto)
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            dispatch(cargarInvestigadores(body))
        } else {
            dispatch(cargarInvestigadores([]))
            dispatch(dialogoInformativo("Su búsqueda no retorno ningún resultado"));
        }
    }
}

export const retornaPerfiles = (funcionario) => {
    return async (dispatch) => {

        const resp = await fetchPerfiles(funcionario)
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            dispatch(cargarPerfiles(body));
        } else {
            console.log("funcionario sin perfiles")
        }
    }
}

export const retornaRolesOrganizacion = (organizacion) => {
    return async (dispatch) => {

        const resp = await fetchRoles(organizacion);
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            dispatch(cargarPerfilesOrganizacion(body));
        } else {
            dispatch(cargarPerfilesOrganizacion([]));
            console.log("no existen perfiles")
        }
    }
}

export const cambiarEstado = (usuario, tipo) => {
    return async (dispatch) => {
        const resp = await changeEstado(usuario);

        if (resp.status === 200) {
            dispatch(dialogoConfirmacion("Estado del usuario cambiado correctamente"));
            if (tipo === 1) {
                dispatch(retornaFuncionarios());
            } else {
                dispatch(retornaInvestigadores(''));
            }
            dispatch(quitarSeleccion());
        } else {
            console.log("error al cambiar el estado del usuario")
        }
    }
}

export const guardarPerfilesxUsuario = (valores, usuario) => {
    return async (dispatch) => {
        try {

            const resp = await savePerfiles(usuario, valores);
            const body = await resp.json();

            if (Object.keys(body).length !== 0) {
                dispatch(dialogoConfirmacion("Perfiles guardados correctamente"));
                dispatch(cerrarModalPerfiles());
            } else {
                dispatch(dialogoError("Error al guardar los perfiles del usuario"));
            }

        } catch (error) {
            console.log("error al guardar los perfiles del usuario", error)
        }
    }
}

export const retornaProvincias = () => {
    return async (dispatch) => {
        const resp = await fetchProvincias();
        const body = await resp.json();

        if (Object.keys(body).length !== 0) {
            dispatch(cargarProvincias(body));
        } else {
            dispatch(cargarProvincias([]));
            console.log("no existen provincias")
        }
    }
}

export const retornaBosquesoAreas = (tipo, provincia) => {
    return async (dispatch) => {
        if (tipo === 1) {
            const resp = await fetchBosques(provincia);
            const body = await resp.json();

            dispatch(cargarListaAreasProtegidas([]));
            dispatch(quitarSeleccion());

            if (Object.keys(body).length !== 0) {
                dispatch(cargarListaBosques(body));
            } else {
                dispatch(dialogoInformativo("Su búsqueda no retorno ningún resultado"));
                dispatch(cargarListaBosques([]));
                console.log("no existen bosques")
            }
        } else {
            const resp = await fetchAreasProtegidas(provincia);
            const body = await resp.json();

            dispatch(cargarListaBosques([]));
            dispatch(quitarSeleccion());

            if (Object.keys(body).length !== 0) {
                dispatch(cargarListaAreasProtegidas(body));
            } else {
                dispatch(dialogoInformativo("Su búsqueda no retorno ningún resultado"));
                dispatch(cargarListaAreasProtegidas([]));
                console.log("no existen areas protegidas")
            }
        }
    }
}

export const guardarBosque = (valores) => {
    return async (dispatch) => {

        const resp = await saveEditarBosque(valores);

        if (resp.status === 200) {
            dispatch(dialogoConfirmacion("Bosque guardado correctamente"));
            dispatch(retornaBosquesoAreas(1, valores.provincia));
            dispatch(quitarSeleccion([]));
            dispatch(cerrarModalBosques());

        } else {
            dispatch(dialogoError("Error al guardar el bosque"));
        }
    }
}

export const guardarNuevoBosque = (valores) => {
    return async (dispatch) => {

        const resp = await saveNuevoBosque(valores);

        if (resp.status === 200) {
            dispatch(dialogoConfirmacion("Bosque guardado correctamente"));
            dispatch(retornaBosquesoAreas(1, valores.provincia));
            dispatch(quitarSeleccion([]));
            dispatch(cerrarModalBosques());

        } else {
            dispatch(dialogoError("Error al guardar el bosque"));
        }
    }
}

export const guardarArea = (valores) => {
    return async (dispatch) => {

        const resp = await saveEditarArea(valores);

        if (resp.status === 200) {
            dispatch(dialogoConfirmacion("Área guardada correctamente"));
            dispatch(retornaBosquesoAreas(2, valores.provincia));
            dispatch(quitarSeleccion([]));
            dispatch(cerrarModalBosques());

        } else {
            dispatch(dialogoError("Error al guardar el área"));
        }
    }
}

export const guardarAreaNueva = (valores) => {
    return async (dispatch) => {

        const resp = await saveNuevaArea(valores);

        if (resp.status === 200) {
            dispatch(dialogoConfirmacion("Área guardada correctamente"));
            dispatch(retornaBosquesoAreas(2, valores.provincia));
            dispatch(quitarSeleccion([]));
            dispatch(cerrarModalBosques());

        } else {
            dispatch(dialogoError("Error al guardar el área"));
        }
    }
}

export const borrarBosque = (id, provincia) => {
    return async (dispatch) => {

        const resp = await deleteBosque(id);

        if (resp.status === 200) {
            dispatch(dialogoConfirmacion("Bosque borrado correctamente"));
            dispatch(retornaBosquesoAreas(1, provincia));
            dispatch(quitarSeleccion([]));

        } else {
            dispatch(dialogoError("Error al borrar el bosque"));
        }
    }
}

export const borrarArea = (id, provincia) => {
    return async (dispatch) => {

        const resp = await deleteArea(id);

        if (resp.status === 200) {
            dispatch(dialogoConfirmacion("Área borrada correctamente"));
            dispatch(retornaBosquesoAreas(2, provincia));
            dispatch(quitarSeleccion([]));

        } else {
            dispatch(dialogoError("Error al borrar el área"));
        }
    }
}

export const retornaIesIpiIts = (tipo) => {
    return async (dispatch) => {

        if (tipo === 1) {
            const resp = await fetchIes();

            if (resp.status === 200) {

                const body = await resp.json()
                dispatch(cargarIes(body));
                dispatch(cargarIpi([]));
                dispatch(cargarIts([]));
            } else {
                dispatch(dialogoInformativo("Su búsqueda no retorno ningún resultado"));
                console.log("no existen ies")
            }
        }

        if (tipo === 2) {
            const resp = await fetchIpi();

            if (resp.status === 200) {

                const body = await resp.json()
                dispatch(cargarIes([]));
                dispatch(cargarIpi(body));
                dispatch(cargarIts([]));
            } else {
                dispatch(dialogoInformativo("Su búsqueda no retorno ningún resultado"));
                console.log("no existen ipi")
            }
        }

        if (tipo === 3) {
            const resp = await fetchIts();

            if (resp.status === 200) {

                const body = await resp.json()
                dispatch(cargarIes([]));
                dispatch(cargarIpi([]));
                dispatch(cargarIts(body));
            } else {
                dispatch(dialogoInformativo("Su búsqueda no retorno ningún resultado"));
                console.log("no existen its")
            }
        }
    }
}

export const guardarIesIpi = (values) => {
    return async (dispatch) => {

        //IES
        if (values.tipo === 1) {

            if (values.accion === 0) {
                const resp = await saveNuevoIes(values)
                if (resp.status === 200) {
                    dispatch(dialogoConfirmacion("Instituto guardado correctamente"));
                    dispatch(retornaIesIpiIts(1));
                    dispatch(cerrarModalInstitutos());
                } else {
                    dispatch(dialogoError("Error al guardar el instituto"));
                }

            } else {
                const resp = await saveEditarIes(values)
                if (resp.status === 200) {
                    dispatch(dialogoConfirmacion("Instituto actualizado correctamente"));
                    dispatch(retornaIesIpiIts(1));
                    dispatch(quitarSeleccion([]));
                    dispatch(cerrarModalInstitutos());
                } else {
                    dispatch(dialogoError("Error al guardar el instituto"));
                }
            }
        }
        //IPI
        if (values.tipo === 2) {

            if (values.accion === 0) {
                const resp = await saveNuevoIpi(values)
                if (resp.status === 200) {
                    dispatch(dialogoConfirmacion("Instituto guardado correctamente"));
                    dispatch(retornaIesIpiIts(2));
                    dispatch(cerrarModalInstitutos());
                } else {
                    dispatch(dialogoError("Error al guardar el instituto"));
                }
            } else {
                const resp = await saveEditarIpi(values)
                if (resp.status === 200) {
                    dispatch(dialogoConfirmacion("Instituto actualizado correctamente"));
                    dispatch(retornaIesIpiIts(2));
                    dispatch(quitarSeleccion([]));
                    dispatch(cerrarModalInstitutos());
                } else {
                    dispatch(dialogoError("Error al guardar el instituto"));
                }
            }
        }
    }
}

export const guardarIts = (values) => {
    return async (dispatch) => {
        if (values.accion === 0) {
            const resp = await saveNuevoIts(values)
            if (resp.status === 200) {
                dispatch(dialogoConfirmacion("Instituto guardado correctamente"));
                dispatch(retornaIesIpiIts(3));
                dispatch(cerrarModalInstitutos());
            } else {
                dispatch(dialogoError("Error al guardar el instituto"));
            }
        } else {
            const resp = await saveEditarIts(values)
            if (resp.status === 200) {
                dispatch(dialogoConfirmacion("Instituto actualizado correctamente"));
                dispatch(retornaIesIpiIts(3));
                dispatch(quitarSeleccion([]));
                dispatch(cerrarModalInstitutos());
            } else {
                dispatch(dialogoError("Error al guardar el instituto"));
            }
        }
    }
}

export const borrarIes = (id) => {
    return async (dispatch) => {
        const resp = await deleteIes(id);
        if (resp.status === 200) {
            dispatch(dialogoConfirmacion("Instituto borrado correctamente"));
            dispatch(retornaIesIpiIts(1));
            dispatch(quitarSeleccion([]));
        } else {
            dispatch(dialogoError("Error al borrar el instituto"));
        }
    }
}

export const borrarIpi = (id) => {
    return async (dispatch) => {
        const resp = await deleteIpi(id);
        if (resp.status === 200) {
            dispatch(dialogoConfirmacion("Instituto borrado correctamente"));
            dispatch(retornaIesIpiIts(2));
            dispatch(quitarSeleccion([]));
        } else {
            dispatch(dialogoError("Error al borrar el instituto"));
        }
    }
}

export const borrarIts = (id) => {
    return async (dispatch) => {
        const resp = await deleteIts(id);
        if (resp.status === 200) {
            dispatch(dialogoConfirmacion("Instituto borrado correctamente"));
            dispatch(retornaIesIpiIts(3));
            dispatch(quitarSeleccion([]));
        } else {
            dispatch(dialogoError("Error al borrar el instituto"));
        }
    }
}

export const retornaProcesos = () => {
    return async (dispatch) => {
        const resp = await fetchProcesos();

        if (resp.status === 200) {
            const body = await resp.json();

            if (Object.keys(body).length !== 0) {
                dispatch(cargarProcesos(body));
            } else {
                console.log("no existen procesos")
            }
        } else {
            console.log("error al retornar los procesos")
        }
    }
}

export const retornaTareas = (proceso) => {
    return async (dispatch) => {
        const resp = await fetchTareas(proceso);
        const body = await resp.json();
        if (Object.keys(body).length !== 0) {
            dispatch(cargarTareas(body));
        } else {
            console.log("no existen procesos")
        }
    }
}

export const retornaFirmas = (tarea) => {
    return async (dispatch) => {
        const resp = await fetchFirmas(tarea);

        if (resp.status === 200) {
            const body = await resp.json();

            if (Object.keys(body).length !== 0) {
                dispatch(cargarFirmas(body));
            } else {
                console.log("no existen firmas")
            }
        } else {
            console.log("error al retornar las firmas")
        }
    }
}

export const retornaRoles = () => {
    return async (dispatch) => {
        const resp = await fetchRolesFirmas();

        if (resp.status === 200) {
            const body = await resp.json();

            if (Object.keys(body).length !== 0) {
                dispatch(cargarRoles(body));
            } else {
                console.log("no existen roles")
            }
        } else {
            console.log("error al retornar los roles")
        }
    }
}

export const guardarFirma = (tarea, rol, orden) => {
    return async (dispatch) => {
        const resp = await saveFirmas(tarea, rol, orden)

        if (resp.status === 200) {
            const body = await resp.json();

            if (Object.keys(body).length !== 0) {
                dispatch(dialogoConfirmacion("Firmas guardadas correctamente"));
                dispatch(retornaFirmas(tarea));
                dispatch(seleccionarOrden(false));
            } else {
                dispatch(dialogoError("Error al guardar las firmas"));
            }
        } else {
            console.log("error al guardar las firmas")
        }
    }
}

export const borrarFirma = (firma, tarea) => {
    return async (dispatch) => {
        const resp = await deleteFirmas(firma);

        if (resp.status === 200) {
            const body = await resp.json()
            if (body.resultado) {
                dispatch(dialogoConfirmacion("Firma borrada correctamente"));
                dispatch(retornaFirmas(tarea));
                dispatch(restaurarOrden());
                dispatch(seleccionarOrden(false));
            } else {
                dispatch(dialogoError("Error al borrar la firma"));
            }
        } else {
            dispatch(dialogoError("Error al borrar la firma"));
        }
    }
}

export const retornaSolicitudes = () => {
    return async (dispatch) => {
        const resp = await fetchSolicitudes();
        if (resp.status === 200) {
            const body = await resp.json()
            if (Object.keys(body).length !== 0) {
                dispatch(cargarSolicitudes(body));
            } else {
                console.log("no existen solictudes")
            }
        } else {
            console.log("error al retornar las solicitudes")
        }
    }
}

export const retornaProcesosSeguimiento = () => {
    return async (dispatch) => {
        const resp = await fetchSeguimientoProcesos();
        if (resp.status === 200) {
            const body = await resp.json()
            if (Object.keys(body).length !== 0) {
                dispatch(cargarSeguimientoProcesos(body));
            } else {
                console.log("no existen procesos")
            }
        } else {
            console.log("error al retornar los procesos")
        }
    }
}

export const retornaPlantillas = () => {
    return async (dispatch) => {
        const resp = await fetchPlantillas();
        if (resp.status === 200) {
            const body = await resp.json()
            if (Object.keys(body).length !== 0) {
                dispatch(cargarPlantillas(body));
            } else {
                console.log("no existen plantillas")
            }
        } else {
            console.log("error al retornar las plantillas")
        }
    }
}

export const retornaPlantillaSeleccionada = (plantilla) => {
    return async (dispatch) => {
        const resp = await fetchPlantillaSeleccionada(plantilla);
        if (resp.status === 200) {
            const body = await resp.json()
            if (Object.keys(body).length !== 0) {
                dispatch(seleccionarPlantilla(body));
            } else {
                console.log("no existen plantilla")
            }
        } else {
            console.log("error al retornar la plantilla")
        }
    }
}

export const guardarPlantilla = (codigo, contenido) => {
    return async (dispatch) => {
        const resp = await savePlantilla(codigo, contenido)

        if (resp.status === 200) {
            dispatch(dialogoConfirmacion("Plantilla guardada correctamente"))
            dispatch(cerrarModalPlantilla());
            dispatch(retornaPlantillas());
            dispatch(quitarPlantillaSeleccionada());
        } else {
            console.log("error")
            dispatch(dialogoError("Error al guardar la plantilla"));
        }
    }
}

export const retornaAreas = () => {
    return async (dispatch) => {
        const resp = await fetchAreas();
        if (resp.status === 200) {
            const body = await resp.json()
            if (Object.keys(body).length !== 0) {
                dispatch(cargarAreas(body));
            } else {
                console.log("no existen areas")
            }
        } else {
            console.log("error al retornar las areas")
        }
    }
}

export const guardarAreaInvestigacion = (values) => {
    return async (dispatch) => {
        if (values.accion === 0) {
            const resp = await saveNuevaAreaInvestigacion(values)
            if (resp.status === 200) {
                dispatch(dialogoConfirmacion("Área de investigación guardada correctamente"));
                dispatch(retornaAreas());
                dispatch(cerrarModalAreaInvestigacion());
            } else {
                dispatch(dialogoError("Error al guardar el área de investigación"));
            }
        } else {
            const resp = await saveEditarAreaInvestigacion(values)
            if (resp.status === 200) {
                dispatch(dialogoConfirmacion("Área de investigación actualizada correctamente"));
                dispatch(retornaAreas());
                dispatch(quitarAreaInvestigacion());
                dispatch(cerrarModalAreaInvestigacion());
            } else {
                dispatch(dialogoError("Error al guardar el área de investigación"));
            }
        }
    }
}

export const borrarAreaInvestigacion = (area) => {
    return async (dispatch) => {
        const resp = await deleteAreaInvestigacion(area);

        if (resp.status === 200) {
            const body = await resp.json()
            if (body.resultado) {
                dispatch(dialogoConfirmacion("Área de investigación borrada correctamente"));
                dispatch(retornaAreas());
                dispatch(quitarAreaInvestigacion());
            } else {
                dispatch(dialogoError("Error al borrar el área de investigación"));
            }
        } else {
            dispatch(dialogoError("Error al borrar el área de investigación"));
        }
    }
}

export const retornaLineas = (area) => {
    return async (dispatch) => {
        const resp = await fetchLineas(area);
        if (resp.status === 200) {
            const body = await resp.json()
            if (Object.keys(body).length !== 0) {
                dispatch(cargarLineas(body));
            } else {
                console.log("no existen lineas")
            }
        } else {
            console.log("error al retornar las lineas")
        }
    }
}

export const guardarLinea = (values) => {
    return async (dispatch) => {
        if (values.accion === 0) {
            const resp = await saveNuevaLinea(values)
            if (resp.status === 200) {
                dispatch(dialogoConfirmacion("Línea de investigación guardada correctamente"));
                dispatch(retornaLineas(values.area));
                dispatch(cerrarModalLineas());
            } else {
                dispatch(dialogoError("Error al guardar la línea de investigación"));
            }
        } else {
            const resp = await saveEditarLinea(values)
            if (resp.status === 200) {
                dispatch(dialogoConfirmacion("Línea de investigación actualizada correctamente"));
                dispatch(retornaLineas(values.area));
                dispatch(quitarLineaSeleccionada());
                dispatch(cerrarModalLineas());
            } else {
                dispatch(dialogoError("Error al guardar la línea de investigación"));
            }
        }
    }
}

export const borrarLinea = (linea, area) => {
    return async (dispatch) => {
        const resp = await deleteLinea(linea);

        if (resp.status === 200) {
            const body = await resp.json()
            if (body.resultado) {
                dispatch(dialogoConfirmacion("Línea de investigación borrada correctamente"));
                dispatch(retornaLineas(area));
                dispatch(quitarLineaSeleccionada());
            } else {
                dispatch(dialogoError("Error al borrar la línea de investigación"));
            }
        } else {
            dispatch(dialogoError("Error al borrar la línea de investigación"));
        }
    }
}

export const guardarArchivoContrato = (data) => {
    return async (dispatch) => {
        const resp = await saveArchivoContrato(data);
        const estado = resp.status
        if (estado === 200) {
            dispatch(dialogoConfirmacion("Archivo subido correctamente"));
            dispatch(abrirModalContratos(false))
        } else {
            dispatch(dialogoError("Error al subir el archivo"));
        }
    }
}

const dialogoConfirmacion = (texto) => {
    return () => {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: texto || '',
            showConfirmButton: false,
            timer: 2000,
            customClass: {
                container: 'my-swal'
            }
        })
    }
}

const dialogoError = (texto) => {
    return () => {
        Swal.fire({
            position: "center",
            icon: 'error',
            title: "Error",
            html: texto || '',
            showConfirmButton: true,
            confirmButtonColor: 'rgba(54, 160, 184, 1)',
            customClass: {
                container: 'my-swal'
            }
        })
    }
}

export const dialogoInformativo = (texto) => {
    return () => {
        Swal.fire({
            position: "center",
            icon: 'info',
            title: "Aviso",
            html: texto,
            showConfirmButton: true,
            confirmButtonColor: 'rgba(54, 160, 184, 1)',
            customClass: {
                container: 'my-swal'
            }
        })
    }
}

const fetchRoles = (organizacion) => {
    const url = `${GLOBALS.mainUrl}/v1/api/perfil/get?idorg=${organizacion}`
    return fetch(url)
}

const fetchFuncionarios = (method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/funcionarios`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchInvestigadores = (texto, method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/investigadores?parametro=${texto}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchPerfiles = (funcionario, method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/v1/api/perfilusuario/userprofiles?idusuario=${funcionario}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const changeEstado = (usuario, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/usuarios/activar?idUsuario=${usuario}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const savePerfiles = (usuario, valores, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/v1/api/perfilusuario/update?idUsuario=${usuario}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            "ids": `${valores}`
        })
    })
}

const fetchProvincias = (method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/provincia/all`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchBosques = (provincia, method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/bosqueprotector/idprovincia?idProvincia=${provincia}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchAreasProtegidas = (provincia, method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/areaprotegida/idprovincia?idProvincia=${provincia}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveEditarBosque = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/bosqueprotector/update?idBosqueProtector=${values.id}&nombreBosqueProtector=${values.nombre}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveNuevoBosque = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/bosqueprotector/set?idBosqueProtector=${values.codigo}&nombreBosqueProtector=${values.nombre}&idProvincia=${values.provincia}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveEditarArea = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/areaprotegida/update?idAreaProtegida=${values.id}&nombreAreaProtegida=${values.nombre}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveNuevaArea = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/areaprotegida/set?idAreaProtegida=${values.codigo}&nombreAreaProtegida=${values.nombre}&idProvincia=${values.provincia}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const deleteBosque = (id, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/bosqueprotector/delete?idBosqueProtector=${id}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const deleteArea = (id, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/areaprotegida/delete?idAreaProtegida=${id}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchIes = (method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/ies/all`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchIpi = (method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/ipi/all`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchIts = (method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/its/all`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveEditarIes = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/ies/update?idIes=${values.id}&nombreIes=${values.nombre}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveNuevoIes = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/ies/set?idIes=${values.codigo}&nombreIes=${values.nombre}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveEditarIpi = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/ipi/update?idIpi=${values.id}&nombreIpi=${values.nombre}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveNuevoIpi = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/ipi/set?idIpi=${values.codigo}&nombreIpi=${values.nombre}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveEditarIts = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/its/update?idIts=${values.id}&nombreIts=${values.nombre}&tipo=${values.tipo}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveNuevoIts = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/its/set?idIts=${values.codigo}&nombreIts=${values.nombre}&tipo=${values.tipo}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const deleteIes = (id, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/ies/delete?idIes=${id}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const deleteIpi = (id, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/ipi/delete?idIpi=${id}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const deleteIts = (id, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/its/delete?idIts=${id}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchProcesos = (method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/procesos/list`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchTareas = (proceso, method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/procesos/tasklist?idProceso=${proceso}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchFirmas = (tarea, method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/firma/tarea?idTarea=${tarea}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchRolesFirmas = (method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/v1/api/perfil/list`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveFirmas = (tarea, rol, orden, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/firma/set?idTarea=${tarea}&idPerfilFirmante=${rol}&secuenciaFirma=${orden}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const deleteFirmas = (firma, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/firma/delete?idFirma=${firma}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchSolicitudes = (method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/v1/api/solicitud/all`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchSeguimientoProcesos = (method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/procesos/seguimiento`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchPlantillas = (method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/plantilla/lista`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchPlantillaSeleccionada = (plantilla, method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/plantilla/get/${plantilla}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const savePlantilla = (codigo, contenido, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/plantilla/actualizar?codigoPlantilla=${codigo}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        },
        body: `${contenido}`
    })
}

const fetchAreas = (method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/areasinv/all`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveNuevaAreaInvestigacion = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/areasinv/set?idAreaInvestigacion=${values.codigo}&nombreAreaInvestigacion=${values.nombre}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveEditarAreaInvestigacion = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/areasinv/update?idAreaInvestigacion=${values.codigo}&nombreAreaInvestigacion=${values.nombre}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const deleteAreaInvestigacion = (area, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/areasinv/delete?idAreaInvestigacion=${area}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const fetchLineas = (area, method = 'GET') => {
    const url = `${GLOBALS.mainUrl}/api/v1/lineasinv/${area}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveNuevaLinea = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/lineasinv/set?idLineaInvestigacion=${values.codigo}&nombreLineaInvestigacion=${values.nombre}&idAreaInvestigacion=${values.area}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveEditarLinea = (values, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/lineasinv/update?idLineaInvestigacion=${values.codigo}&nombreLineaInvestigacion=${values.nombre}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const deleteLinea = (linea, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/api/v1/lineasinv/delete?idLineaInvestigacion=${linea}`
    return fetch(url, {
        method,
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const saveArchivoContrato = (data, method = 'POST') => {
    const url = `${GLOBALS.mainUrl}/v1/api/registro/cargar`
    return fetch(url, {
        method,
        body: data
    })
}

