import { Button, Chip, Grid, Stack } from '@mui/material';
import React, { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch, useSelector } from 'react-redux';
import { guardarImagen, fetchImagen } from './API';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Typography from '@mui/material/Typography';
import { cerrarModalImagen } from './slicePreferencias';

const botonAzul = {
  ml: 2,
  textTransform: 'none',
  borderRadius: '0.2rem',
  color: 'rgba(255, 255, 255, 0.9)',
  backgroundColor: 'rgba(54, 160, 184, 1)',
  "&:hover": {
    backgroundColor: 'rgba(54, 138, 184, 1)',
  }
}

export const FormImagen = () => {

  const dispatch = useDispatch();
  const inputRef = useRef()
  const { usuario } = useSelector(state => state.preferencias)
  const [nombreImagen, setNombreImagen] = useState('')
  const [imagenDisplay, setImagenDisplay] = useState()
  const formData = new FormData()
  const [errorIma, setErrorIma] = useState('')

  formData.append('idUsuario', usuario.id)

  const handleGuardarImagen = () => {
    formData.append('archivo', inputRef.current.files[0])
    dispatch(guardarImagen(formData))
  }

  const handleDescargarImagen = () => {
    download(usuario.id)
  }

  async function download(id, name = "imagen_usuario", type = "jpg") {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = await fetchImagen(id);
    a.download = name + "." + type;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async function mostrarImagen(id) {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = await fetchImagen(id).catch(error => setErrorIma(error));
    setImagenDisplay(a.href)
  }

  React.useEffect(() => {
    mostrarImagen(usuario.id)
  }, [])

  const handleCerrarModalImagen = () => {
    dispatch(cerrarModalImagen());
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={3}>
          <Button variant="contained" sx={botonAzul} startIcon={<SearchIcon />} component='label'>
            Buscar Imagen
            <input
              ref={inputRef}
              accept="image/jpg"
              onChange={() => {
                setNombreImagen(inputRef.current.files[0].name);
                setImagenDisplay(URL.createObjectURL(inputRef.current.files[0]))
                setErrorIma('')
              }}
              hidden
              type='file' />
          </Button>

          <Stack direction="row" justifyContent="center">
            <Typography variant="body2">
              <br />
              {nombreImagen !== '' && <Chip label={nombreImagen} />}
              <br />
              {errorIma === '' && <img alt='imagenUsuario' src={imagenDisplay} width={200} height={200} />}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent="end">
            <Button type="submit" color='success' variant="contained" disabled={nombreImagen !== '' ? false : true}
              sx={botonAzul} startIcon={<UploadIcon />} onClick={handleGuardarImagen}
            >
              {'Enviar'}
            </Button>
            <Button type="submit" color='info' variant="contained"
              sx={botonAzul} startIcon={<DownloadIcon />} onClick={handleDescargarImagen}
            >
              {'Descargar'}
            </Button>
            <Button color='success' variant="contained"
              sx={botonAzul}
              startIcon={<HighlightOffIcon />}
              onClick={handleCerrarModalImagen}
            >
              {'Cerrar'}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}