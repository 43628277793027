import * as React from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { retornaBosquesoAreas, retornaProvincias, dialogoInformativo } from './API';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import { Button, ButtonGroup, InputLabel, Select } from '@mui/material';
import Swal from 'sweetalert2'
import { darken, lighten } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import "../chat/swalestilo.css"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { ToolBarBosques } from './ToolBarBosques';
import { abrirModalBosques, cargarListaAreasProtegidas, cargarListaBosques, quitarSeleccion, seleccionarArea, seleccionarBosque } from './sliceAdministrador';
import { ModalBosques } from './ModalBosques';
import SearchIcon from '@mui/icons-material/Search';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import { useNavigate } from 'react-router';

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    esES,
);

function stableSort(array) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'provincia',
        numeric: false,
        disablePadding: true,
        label: 'Provincia',
    },
    {
        id: 'codigo',
        numeric: false,
        disablePadding: true,
        label: 'Código',
    },
    {
        id: 'nombre',
        numeric: false,
        disablePadding: false,
        label: 'Nombre',
    },
];

const botonAzul = {
    mr:2, borderRadius: '0.2rem',
    textTransform: 'none',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
        backgroundColor: 'rgba(54, 138, 184, 1)',
    }
}

const botonAzul2 = {
    borderRadius: '0.2rem',
    textTransform: 'none',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
        backgroundColor: 'rgba(54, 138, 184, 1)',
    }
}

const botonVerde = {
    ml: 2, mr: 2,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(14, 181, 14, 1)',
    "&:hover": {
        backgroundColor: 'rgba(18, 219, 18, 1)',
    }
}

function EnhancedTableHead(props) {
    return (
        <TableHead >
            <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export const TablaBosques = () => {

    const dispatch = useDispatch();

    const { bosques, areasProtegidas, provincias, editando, seleccionadoArea, seleccionadoBosque } = useSelector(state => state.administracion)

    const [pro, setPro] = React.useState('');
    const [tip, setTip] = React.useState('');
    const [tipoEvento, setTipoEvento] = React.useState(1);

    let timerInterval

    const [selected, setSelected] = React.useState([]);
    const [selectedID, setSelectedID] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(retornaProvincias());
        dispatch(cargarListaAreasProtegidas([]));
        dispatch(cargarListaBosques([]));
        dispatch(quitarSeleccion());
        selected.length !== 0 && setSelected([])
        selectedID.length !== 0 && setSelectedID([])
        dispatch(cargarListaAreasProtegidas([]));
    }, [])


    const handleRegresar = () => {
        navigate('/administracion');
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event, name, id) => {

        const selectedIndex = selected.indexOf(name);

        let newSelected = '';
        let ids = '';

        if (selectedIndex === -1) {
            newSelected = name;
            ids = id;
        } else {
            newSelected = '';
            ids = '';
        }

        if (selectedIndex === -1) {
            if (tip === 1) {
                dispatch(seleccionarBosque(ids));
            } else {
                console.log("1")
                dispatch(seleccionarArea(ids));
            }
        }

        if (selectedIndex === 0) {
            console.log("2")
            dispatch(quitarSeleccion());
        }

        setSelected(newSelected)
        setSelectedID(ids)

        console.log("se", selectedID)
    };

    const isSelected = (id) => ((seleccionadoArea || seleccionadoBosque) && id === selectedID)
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (tip === 1 ? bosques.length : areasProtegidas.length)) : 0;

    const handleBuscar = () => {
        tip !== '' ?

            (pro !== '' ?

                (Swal.fire({
                    title: 'Cargando...',
                    html: 'Espere porfavor',
                    timer: 1000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                }).then((result) => {
                    setPage(0);
                    dispatch(retornaBosquesoAreas(tip, pro))
                }))

                :

                dispatch(dialogoInformativo("Seleccione una provincia!"))
            )

            :

            dispatch(dialogoInformativo("Seleccione un tipo!"))
    }

    const getBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


    const handleChangeProvincia = (event) => {
        dispatch(cargarListaAreasProtegidas([]))
        dispatch(cargarListaBosques([]))
        dispatch(quitarSeleccion())
        setPage(0)
        setPro(event.target.value);
    };

    const handleChangeTipo = (event) => {
        dispatch(quitarSeleccion());
        dispatch(cargarListaAreasProtegidas([]))
        dispatch(cargarListaBosques([]))
        setPage(0)
        setTip(event.target.value);
    };

    const handleNuevoBosque = () => {
        setTipoEvento(1);
        dispatch(abrirModalBosques());
    }

    const handleNuevaArea = () => {
        setTipoEvento(2)
        dispatch(abrirModalBosques());
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Box sx={{
                    height: 500, width: '100%',
                    '& .super-app-theme--Inactivo': {
                        bgcolor: (theme) =>
                            getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
                        '&:hover': {
                            bgcolor: (theme) =>
                                getHoverBackgroundColor(
                                    theme.palette.warning.main,
                                    theme.palette.mode,
                                ),
                        },
                    },
                }}>

                    <FormControl sx={{ minWidth: 200, ml: 7, mt: 5 }}>
                        <InputLabel id="demo-simple-select-helper-label">Tipo</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={tip}
                            label="Tipo"
                            onChange={handleChangeTipo}
                            displayEmpty
                        >
                            <MenuItem key={2} value={2}>{'Áreas protegidas'}</MenuItem>
                            <MenuItem key={1} value={1}>{'Bosques'}</MenuItem>

                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 200, mt: 5 }}>
                        <InputLabel id="demo-simple-select-helper-label">Provincia</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={pro}
                            label="Provincia"
                            onChange={handleChangeProvincia}
                            displayEmpty
                        >
                            {
                                provincias.map((provincia) =>
                                    (<MenuItem key={provincia.id} value={provincia.id}>{provincia.nombreProvincia}</MenuItem>)
                                )
                            }

                        </Select>
                    </FormControl>

                    <ButtonGroup variant="outlined" sx={{ ml: 5, mt: 6 }} aria-label="outlined primary button group">
                        <Button onClick={handleBuscar}
                            sx={botonAzul}
                            startIcon={<SearchIcon />}>Buscar
                        </Button>
                        <Button sx={botonVerde} onClick={handleNuevoBosque}
                            startIcon={<AddCircleOutlineIcon />} >Nuevo Bosque
                        </Button>
                        <Button sx={botonVerde} onClick={handleNuevaArea}
                            startIcon={<AddCircleOutlineIcon />} >Nueva Área Protegida
                        </Button>
                    </ButtonGroup>

                    <Box sx={{ width: '100%' }}>
                        <Paper sx={{ width: '100%', mb: 2 }}>

                            <ToolBarBosques tipo={tip} provincia={pro} />

                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 750 }}
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                >
                                    <EnhancedTableHead
                                        rowCount={tip === 1 ? bosques.length : areasProtegidas.length}
                                    />
                                    <TableBody>
                                        {stableSort(tip === 1 ? bosques : areasProtegidas)
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <TableRow
                                                        hover
                                                        onClick={(event) => handleClick(event, (tip === 1 ? row.nombreBosqueProtector : row.nombreAreaProtegida), row.id)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                id={"check_" + row.id}
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {row.provincia.nombreProvincia}
                                                        </TableCell>
                                                        <TableCell align="left">{row.id}</TableCell>
                                                        <TableCell align="left">{tip === 1 ? row.nombreBosqueProtector : row.nombreAreaProtegida}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10]}
                                component="div"
                                count={tip === 1 ? bosques.length : areasProtegidas.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>

                </Box>
            </ThemeProvider>
            <ModalBosques tipo={editando ? tip : tipoEvento} />
            <ButtonGroup variant="contained" sx={{ ml: 5, mt: tip ? 10 : 0 }} aria-label="outlined primary button group">
                <Button variant="contained" onClick={handleRegresar} startIcon={<ArrowCircleLeftOutlinedIcon />}
                    sx={botonAzul2}
                >
                    Regresar
                </Button>
            </ButtonGroup>
        </>
    );
}