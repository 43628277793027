import React, {useState, useEffect} from 'react'
import {Grid} from '@mui/material'
import {SolicitanteTitle} from 'src/features/App/consts'
import {
  Badge,
  SelfImprovement,
  Public,
  CoPresent,
  Smartphone,
  PhoneInTalk,
  Email,
  LocationOn,
} from '@mui/icons-material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyTextField} from 'src/components/MyTextField'
import API from 'src/features/App/API'
import {MyReadOnlyTextField} from 'src/components/MyReadOnlyTextField'
import {ReactComponent as Png21Icon } from 'src/styles/MySvgIcons/png-21-min.svg'
import {f} from 'src/commons/f'
import {MyMaskedTextField} from 'src/components/MyMaskedTextField'
import Typography from "@mui/material/Typography";

export const Solicitante = ({section, formValues, setFormValues, canEdit, formErrors, payload}) => {
  const [errors, setErrors] = useState('')
  const [sectionValues, setSectionValues] = useState({...payload[section]})
  useEffect(() => {
    setSectionValues({...payload[section]})
  }, [])
  // useEffect(() => {
  //   let msgs = ''
  //   if(!f.isValidNotEmpty(sectionValues.telefono)) msgs = msgs + ';' + 'Teléfono de domicilio no válido'
  //   setErrors(msgs)
  // }, [sectionValues])

  const sectionErrors = (f.isValid(formErrors) && f.isValid(formErrors[section])) ? formErrors[section] : null
  const handleChange = (e) => API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  const handleChangePhone = (e) => {
    if(e.target.value === '' || f.isPhone(e.target.value))
      API.handleChange2(e, canEdit, setSectionValues, sectionValues)
  }

  useEffect(() => {
    setSectionValues({...payload[section]})
  }, [])
  // console.log(`====> ${section}.... payload: `, payload[section])

  return(
    <Grid container spacing={1}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Identificación'} />
      </Grid>
      <Grid item xs={6}  >
        <MyReadOnlyTextField value={formValues[section]?.nombresCompletos}
                             label={'Nombres Completos'}
                             icon={<Png21Icon style={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={formValues[section]?.cedula}
                             label={SolicitanteTitle.cedula}
                             icon={<Badge sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={formValues[section]?.genero}
                             label={SolicitanteTitle.genero}
                             icon={<Badge sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={formValues[section]?.etnia}
                             label={'Etnia'}
                             icon={<SelfImprovement sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={formValues[section]?.nacionalidad}
                             label={SolicitanteTitle.nacionalidad}
                             icon={<Public sx={dialog.readOnlyIcon}/>} />
      </Grid>
      <Grid item xs={6}>
        <MyReadOnlyTextField value={formValues[section]?.registro}
                             label={'Número de Registro / Acreditación'}
                             icon={<CoPresent sx={dialog.readOnlyIcon}/>} />
      </Grid>

      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Contacto'} />
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'celular'}
                           label={'Celular'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<Smartphone sx={{fontSize: '14px', color:'silver'}}/>}
                           error={f.isValid(sectionErrors) && sectionErrors['celular']}
                           canEdit={canEdit}
                           type={'CellPhoneNumber'}
                           handleChange={handleChangePhone}  />
      </Grid>
      <Grid item xs={4}>
        <MyMaskedTextField id={'telefono'}
                           label={'Teléfono del Domicilio'}
                           formValues={sectionValues}
                           setFormValues={setSectionValues}
                           icon={<PhoneInTalk sx={{fontSize: '14px', color:'silver'}}/>}
                           error={f.isValid(sectionErrors) && sectionErrors['telefono']}
                           canEdit={canEdit}
                           type={'HomePhoneNumber'}
                           handleChange={handleChangePhone} />
      </Grid>
      <Grid item xs={4}>
        <MyTextField id={'email'}
                     label={'Correo Electrónico'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>}
                     error={f.isValid(sectionErrors) && sectionErrors['email']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      <Grid item xs={12}>
        <MyTextField id={'direccion'}
                     label={'Dirección del Domicilio'}
                     formValues={sectionValues}
                     setFormValues={setSectionValues}
                     icon={<LocationOn sx={{fontSize: '14px', color:'silver'}} />}
                     error={f.isValid(sectionErrors) && sectionErrors['direccion']}
                     canEdit={canEdit}
                     handleChange={handleChange} />
      </Grid>
      {
        !f.isValidNotEmpty(errors) ?
          <Grid item xs={12}>
            <Typography>{errors}</Typography>
          </Grid> : false
      }
    </Grid>
  )
}

export default Solicitante
