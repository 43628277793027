import * as Yup from 'yup';

export const validacionBosques = Yup.object().shape({
    nombre: Yup.string()
        .min(3, 'El nombre debe tener al menos 3 caracteres')
        .required('El nombre es obligatorio'),
    codigo: Yup.number()
        .typeError("Ingrese solo números")
        .required('El código es obligatorio')
        .positive('El código debe ser un número positivo')
        .integer('El código debe ser un número entero'),
});