import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from "formik";
import { cargarInvestigador, seleccionarIdentificador } from './sliceUsuarios';
import { FormCedula } from './FormCedula';
import { FormRegistro } from './FormRegistro';
import { FormCorreo } from './FormCorreo';

export const SelectMetodo = ({ ...props }) => {

    const { indentificador } = useSelector(state => state.usuarios)
    const [age, setAge] = React.useState('');
    const dispatch = useDispatch();

    // const { setFieldValue } = useFormikContext();

    // React.useEffect(() => {
    //     setTimeout(() => {
    //         roles.length !== 0 && setAge(roles[0].id);
    //         roles.length !== 0 && setFieldValue("rolId", roles[0].id);
    //     }, 500);
    // }, [roles])

    const handleChange = (event) => {
        setAge(event.target.value);
        dispatch(seleccionarIdentificador(event.target.value));
        dispatch(cargarInvestigador(null));
    };

    const opciones = [
        { value: 'registro', label: 'Registro' },
        { value: 'cedula', label: 'Cédula' },
        { value: 'correo', label: 'Email' }
      ]

    return (
        <div>
            <FormControl sx={{width: 230}}>
                <InputLabel id="metodo-label">Identificador</InputLabel>
                <Select
                    labelId="metodo-label"
                    id="metodo"
                    value={age}
                    onChange={handleChange}
                    autoWidth
                    required
                    label="Identificador"
                >
                    {
                        opciones.length === 0 ?
                            <MenuItem >{''}</MenuItem>
                            :
                            opciones.map((rol) => (
                                <MenuItem key={rol.value} value={rol.value}>{rol.label}</MenuItem>
                            ))
                    }

                </Select>

                
               { indentificador === 'registro' &&
               (
                <>
                <Box
                  component="main"
                  sx={{ width: { sm: 230 }, flexShrink: { sm: 0 } }}
                >
                  <FormRegistro />
                </Box>
                </>
               )
               } 

               { indentificador === 'cedula' &&
               (
                <>
                <Box
                  component="main"
                  sx={{ width: { sm: 230 }, flexShrink: { sm: 0 } }}
                >
                  <FormCedula />
                </Box>
                </>
               )
               }   

                   { indentificador === 'correo' &&
               (
                <>
                <Box
                  component="main"
                  sx={{ width: { sm: 230 }, flexShrink: { sm: 0 } }}
                >
                  <FormCorreo />
                </Box>
                </>
               )
               }     



            </FormControl>
        </div>
    );
}
