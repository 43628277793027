import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {add, format, parse} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import API from "src/features/App/API"
import WF0102API from "src/features/P01Solicitud/API"
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from "src/features/App/sliceApp";
import {MySendButton} from 'src/components/MySendButton'
import {MySubtitle} from 'src/components/MySubtitle'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import {f} from 'src/commons/f'
import GLOBALS, {ENV} from 'src/features/App/globals'
import {SectionTitle} from "../../components/SectionTitle";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {AccountCircle} from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MyButtonBacan} from "../../components/MyButtonBacan";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Toolbar from "@mui/material/Toolbar";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import P0102API from "./API";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "./P0102PayloadRO";
import {P0102Expediente} from "./P0102Expediente";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import {MyReadOnlyAreaTextField} from "../../components/MyReadOnlyAreaTextField";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const section = 'ElaborarContrato'
  const dispatch = useDispatch()
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:            today,
    serial:           '',
    identificador:    solicitud.numeroSolicitud,
    tiempoVigencia:   '' + payload.Propuesta.plazo + ' meses',
    docx:             '',
    pdf:              '',
    docxLded:         '',
    pdfLded:          '',
    pdfSigned:        '',
  }

  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const dResolucion = parse(slct.payload.Resolucion.fecha,'dd-MMMM-yyyy', new Date(), {locale: es})
  const plazo = Number(slct.payload.Propuesta.plazo)
  const dPlazo = add(dResolucion, {months: plazo})
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {setCounter(counter + 1)}, [formValues])
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `contrato-${nombreOrganizacion}`
  const filename = `contrato-${formValues.identificador}-${nombreOrganizacion}`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  const filenamePDFsigned = `${filename}-firmado.pdf`
  const inputRef = useRef()
  const funcionarios = useSelector(state => state.app.funcionarios)
  const autorizador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const autoridadSenescyt = funcionarios.filter(it => it.idPerfil === 1189)[0]
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }

  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    };
  }
  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={instanciaTarea.tareaCodigoTarea === '55000017_Activity_ElaborarContratoWF0405'?
            'Elaboración de contrato modificatorio de acceso con potencial uso comercial':
            'Elaboración de contrato de acceso con potencial uso comercial'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="Solicitud" value="2" />
              <Tab label="Expediente" value="3" />
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Solicitud ${payload?.solicitudOriginal?.solicitudNumeroSolicitud}`} value="4"/> : null
              }
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Expediente ${payload?.solicitudOriginal?.solicitudNumeroSolicitud}`} value="5"/> : null
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <>
              <Grid container spacing={1} sx={accordeonBox.container2}>
                <Solicitante solicitud={{solicitud: {payload}}} displayContact={false} />
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Resolución'} />
                </Grid>
                <Grid container spacing={1} sx={{margin: '0 2rem 0 0'}}>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                      <MyReadOnlyTextField id='identificador'
                                           label={'Identificador'}
                                           value={solicitud?.numeroSolicitud}
                                           icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                      <MyReadOnlyTextField id='fecha'
                                           label={'Fecha'}
                                           value={slct?.payload?.Resolucion?.fecha}
                                           icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                      <MyButtonBacan label={'VER PDF'}
                                     onClick={() => {
                                       const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${solicitud?.id}/resolucion-Senescyt/${slct.payload?.Resolucion?.pdfSigned}`
                                       fetch(url)
                                         .then((res) => { return res.blob(); })
                                         .then((data) => {
                                           const dataPdf = new Blob([data], { type: 'application/pdf' })
                                           const a = document.createElement("a")
                                           a.href = window.URL.createObjectURL(dataPdf)
                                           a.target="_blank"
                                           a.click()
                                         })
                                     }}
                                     myTip={'Ver resolución'}
                                     icon={FileDownloadOutlinedIcon} />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'Contrato'} />
                </Grid>
                {f.isValidNotEmpty(payload.RevisarContrato?.observaciones) ?
                  <Grid item xs={12} style={{padding: '0 24px 0 0.5rem'}}>
                    <MyReadOnlyAreaTextField id={'observaciones'}
                                     label={'Observaciones hechas por el investigador'}
                                     value={payload.RevisarContrato?.observaciones}
                                     rows={10}/>
                  </Grid> : null
                }
                <Grid item xs={12} style={{padding:'0 24px 0 0.5rem'}}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} >
                    <MyReadOnlyTextField id='identificador'
                                         label={'Identificador'}
                                         value={solicitud?.numeroSolicitud}
                                         icon={<AccountCircle sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                    <MyReadOnlyTextField id='fecha'
                                         label={'Fecha de emisión'}
                                         value={format(dResolucion, 'dd-MMM-yyyy', {locale: es})}
                                         icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                    <MyReadOnlyTextField id='plazo'
                                         label={'Vigencia'}
                                         value={`${plazo} meses`}
                                         icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                    <MyReadOnlyTextField id='fechaCaducidad'
                                         label={'Fecha de caducidad'}
                                         value={format(dPlazo, 'dd-MMM-yyyy', {locale: es})}
                                         icon={<CalendarMonthIcon sx={{fontSize: '14px', color: 'silver', mr: '0.8rem'}}/>}/>
                  </Stack>
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 0 0 8rem'}}>
                    {
                      formValues.serial === '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={'GENERAR SERIAL'}
                                        width={'11rem'}
                                        bgColor1={'rgb(148 193 32)'}
                                        onClick={() => {
                                          const tipo = 'AUTCPC'
                                          API.secuenciaSet(tipo).then((ser) => {
                                            if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                              const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                              getOnSave({...formValues, serial:serial})()
                                              setFormValues({...formValues, serial:serial})
                                            }
                                          })
                                        }}
                                        icon={RestartAltIcon}/>
                      </ButtonGroup>
                    }
                    {
                      formValues.docx !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={''}
                                        width={'3rem'}
                                        onClick={() => {
                                          setFormValues({
                                            ...formValues,
                                            docx: '',
                                            pdf: '',
                                            docxLded: '',
                                            pdfLded: '',
                                            pdfSigned: '',
                                          })
                                        }}
                                        icon={RestartAltIcon}/>
                      </ButtonGroup>
                    }
                    {
                      formValues.docx === '' && formValues.serial !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={'Generar Informe'}
                                        width={'11rem'}
                                        onClick={() => {
                                          API.fetchPlantila('56015025-1').then(taggeDoc => {
                                            const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, nombreOrganizacion, WF0102API.mapping({
                                              solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                              payload,
                                              autorizador,
                                              coordinador,
                                              perfilUsuario,
                                              nombreOrganizacion,
                                              autoridadSenescyt,
                                              section: payload[section],
                                            }))
                                            API.genDocxAnPdf({
                                              solicitudId: instanciaProceso?.solicitud?.id,
                                              document: doc,
                                              formValues,
                                              setFormValues,
                                              subfolder,
                                              filename: filenameDOCX,
                                              nombreOrganizacion,
                                            })
                                          })
                                        }}
                                        icon={MiscellaneousServicesIcon} />
                      </ButtonGroup>
                    }
                    {
                      formValues.docx !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={'DOCX'}
                                        icon={FileDownloadIcon}
                                        onClick={() => API.fetchDownloadDOCX({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder,
                                          filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                        })}
                                        width={'5rem'} />
                        <MyButtonBacan3 label={'PDF'}
                                        icon={FileDownloadIcon}
                                        onClick={() => API.fetchDownloadPDF({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder,
                                          filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                        })}
                                        width={'5rem'} />
                      </ButtonGroup>
                    }
                    {
                      formValues.docx !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyFileUploadButton inputRef={inputRef}
                                            label={'DOCX'}
                                            solicitudId={instanciaProceso?.solicitud?.id}
                                            subfolder={subfolder}
                                            fileName={filenameDOCXuploaded}
                                            afterUpload={() => {
                                              API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                                if(f.isValid(result2.rutaDocumento)) {
                                                  const arr2 = result2.rutaDocumento.split('/')
                                                  setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                                } else {
                                                  setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                                }
                                              })
                                            }}
                                            width={'5rem'} />
                      </ButtonGroup>
                    }
                    {/*{*/}
                    {/*  formValues.docx !== '' &&*/}
                    {/*  <ButtonGroup variant={'contained'}>*/}
                    {/*    <MyFileUploadButton inputRef={inputRef}*/}
                    {/*                        label={'PDF firmado'}*/}
                    {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                    {/*                        subfolder={subfolder}*/}
                    {/*                        fileName={filenamePDFsigned}*/}
                    {/*                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}*/}
                    {/*                        width={'9rem'} />*/}
                    {/*    {*/}
                    {/*      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}*/}
                    {/*                                                     icon={FileDownloadIcon}*/}
                    {/*                                                     onClick={() => API.fetchDownloadPDF({*/}
                    {/*                                                       solicitudId: instanciaProceso?.solicitud?.id,*/}
                    {/*                                                       subfolder,*/}
                    {/*                                                       filename: filenamePDFsigned,*/}
                    {/*                                                     })}*/}
                    {/*                                                     width={'3rem'}/>*/}
                    {/*    }*/}
                    {/*  </ButtonGroup>*/}
                    {/*}*/}
                  </Stack>
                </Grid>
              </Grid>
            </>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={payload}/>
          </TabPanel>
          <TabPanel value="3">
            <P0102Expediente payload={payload}/>
          </TabPanel>
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="4">
                <P0102PayloadRO payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="5">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyGobackButton onGoback={() => dispatch(handleClear())} />
                  <MySaveButton onSave={getOnSave()}
                                disabled={counter <= 0 } />
                  <MySendButton onSend={() => {
                    const metadata = JSON.stringify({
                                    "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    "perfilUsuarioSolicitud": Number(slct?.perfilUsuarioId),
                                  })
                                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                disabled={counter > 0 || formValues.docx === ''}
                                label={'enviar'} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}

// const plantilla = '${TITULO1}CONTRATO DE ACCESO A LOS RECURSOS GENÉTICOS O SUS PRODUCTOS DERIVADOS CON POTENCIAL USO COMERCIAL'
//   + '${SALTO}${TITULO1}<<solicitud.identificador>>'
//   + "${SALTO}${CENTRAR}<<serial>>"
//   + '${SALTO}${TITULO2}COMPARECIENTES '
//   + '${SALTO}${PARRAFO}Comparecen a la celebración del presente Contrato de Acceso a los Recursos Genéticos o sus productos derivados con potencial uso comercial, por una parte, la SECRETARÍA DE EDUCACIÓN SUPERIOR, CIENCIA, TECNOLOGÍA E INNOVACIÓN, representada por <<sistema.nombreAutorizadorPrincipalSenescyt>>, en su calidad de Subsecretaria/o de Investigación, Innovación y Transferencia de Tecnología, Innovación y Transferencia de Tecnología, delegada del Secretario de Educación Superior, Ciencia, Tecnología e Innovación, conforme se desprende de la acción de personal Nro. 010-DTH-2022 de 06 de enero 2022 y de conformidad con el Acuerdo Nro. SENESCYT-2019-112 de 05 de septiembre de 2019, a quien en adelante se le denominará “LA SECRETARÍA” o “LA SENESCYT”; por otra parte, la/el <<solicitud.institucionPatrocinadora>>, representada por <<solicitud.representanteLegalPatrocinadora>>, en su calidad de <<solicitud.cargoRepresentanteLegalPatrocinadora>>, a quien en adelante se le denominará “COLOCAR ACRÓNIMO” o “EL/LA SOLICITANTE”, a quienes podrá llamarse también de forma conjunta: “las partes”. '
//   + '${SALTO}${PARRAFO}Las partes son hábiles para contratar y obligarse libre y voluntariamente, y en las calidades en las que interviene, convienen en suscribir el presente Contrato al tenor de las siguientes cláusulas'
//   + '${SALTO}${TITULO2}CLÁUSULA PRIMERA. BASE LEGAL.'
//   + '${SALTO}${TITULO3}1.1 La Constitución de la República del Ecuador.'
//   + '${SALTO}${PARRAFO}El artículo 74 señala que: “Las personas, comunidades, pueblos y nacionalidades tendrán derecho a beneficiarse del ambiente y de las riquezas naturales que les permitan el buen vivir. Los servicios ambientales no serán susceptibles de apropiación; su producción, prestación, uso y aprovechamiento serán regulados por el Estado”.'
//   + '${SALTO}${PARRAFO}El artículo 313 establece que el patrimonio genético es un sector estratégico, por lo cual, “El Estado se reserva el derecho de administrar, regular, controlar y gestionar los sectores estratégicos, de conformidad con los principios de sostenibilidad ambiental, precaución, prevención y eficiencia”.'
//   + '${SALTO}${PARRAFO}El artículo 402 determina que: “Se prohíbe el otorgamiento de derechos, incluidos los de propiedad intelectual, sobre productos derivados o sintetizados, obtenidos a partir del conocimiento colectivo asociado a la biodiversidad nacional”.'
//   + '${SALTO}${TITULO3}1.2 El Convenio sobre la Diversidad Biológica, publicado en el Registro Oficial Nro. 647 de 06 de marzo de 1995.'
//   + '${SALTO}${PARRAFO}Reconoce el derecho soberano que ejercen los Estados sobre su biodiversidad, estableciendo como objetivos primordiales la conservación de la diversidad biológica, la utilización sostenible de sus componentes y la participación justa y equitativa en los beneficios que se deriven de la utilización de los recursos genéticos.'
//   + '${SALTO}${TITULO3}1.3 La Decisión de la Comunidad Andina Nro. 391, publicado en el Registro Oficial Suplemento Nro. 05 de 16 de agosto de 1996, promulgó el: “Régimen Común sobre Acceso a los Recursos Genéticos.”.'
//   + '${SALTO}${PARRAFO}Los artículos 5 y 6 determinan que los recursos genéticos y sus productos derivados son inalienables, imprescriptibles e inembargables, por lo tanto, son por soberanía, bienes y patrimonio de los países de origen que los contienen y en consecuencia determinan las condiciones de su acceso de acuerdo con los principios y disposiciones contenidos en el Convenio sobre la Diversidad Biológica y la mencionada Decisión de conformidad con lo establecido en sus respectivas legislaciones internas.'
//   + '${SALTO}${TITULO3}1.4 El Código Orgánico Integral Penal, publicado en el Registro Oficial Suplemento Nro. 180 de 10 de febrero de 2014.'
//   + '${SALTO}${PARRAFO}El artículo 248 numeral 1 establece que el: “Acceso no autorizado: la persona que incumpliendo la normativa nacional acceda a recursos genéticos del patrimonio nacional que incluya o no componente intangible asociado, será sancionada con pena privativa de libertad de tres a cinco años de prisión. La pena será agravada en un tercio si se demuestra que el acceso ha tenido finalidad comercial”.'
//   + '${SALTO}${TITULO3}1.5 El Código Orgánico de la Economía Social de los Conocimientos Creatividad e Innovación, publicado en el Registro Oficial Suplemento Nro. 889 de 09 de diciembre de 2016.'
//   + '${SALTO}${PARRAFO}El artículo 68 establece que para el desarrollo de investigaciones científicas sobre los recursos biológicos, genéticos y sus productos derivados en territorio ecuatoriano, las personas naturales, jurídicas u otras formas asociativas, tanto nacionales como extranjeras, deberán obtener la correspondiente autorización para el acceso a recursos biológicos, genéticos y sus productos derivados con fines de investigación, emitido la Secretaría de Educación Superior, Ciencia, Tecnología e Innovación.'
//   + '${SALTO}${PARRAFO}El artículo 73 establece como se deberá aplicar los beneficios del aprovechamiento de la biodiversidad.'
//   + '${SALTO}${TITULO3}1.6 El Código Civil.'
//   + '${SALTO}${PARRAFO}El artículo 1453 señala que: “Las obligaciones nacen, ya del concurso real de las voluntades de dos o más personas, como en los contratos o convenciones (…)”.'
//   + '${SALTO}${PARRAFO}El artículo 1561 determina que: “Todo contrato legalmente celebrado es una ley para los contratantes, y no puede ser invalidado sino por su consentimiento mutuo o por causas legales”.'
//   + '${SALTO}${TITULO3}1.7 El Reglamento General al Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, publicado en el Registro Oficial Suplemento Nro. 9 de 07 de junio de 2017.'
//   + '${SALTO}${PARRAFO}El artículo 25 establece que: “Las autorización de acceso a recursos genéticos y sus derivados para fines de investigación o comerciales, así como los permisos de importación de organismos vivos, especímenes de colecciones científicas que tengan como fin el desarrollo de procesos investigativos se tramitarán a través de una ventanilla única para la investigación para la biodiversidad en la que interoperarán la SENESCYT, el Instituto Público de Investigación Científica sobre la Biodiversidad, la Autoridad Nacional Ambiental, la autoridad aduanera, así como las demás pertinentes”.'
//   + '${SALTO}${TITULO3}1.8 El Decreto Ejecutivo Nro. 34 de 24 de mayo de 2021'
//   + '${SALTO}${PARRAFO}El Presidente Constitucional de la República del Ecuador, Guillermo Lasso Mendoza, designó a Alejandro Ribadeneira Espinosa como Secretario de Educación Superior, Ciencia, Tecnología e Innovación.'
//   + '${SALTO}${TITULO3}1.9 El Acuerdo Nro. SENESCYT-2019-112 de 05 de septiembre de 2019.'
//   + '${SALTO}${PARRAFO}El numeral 6 del artículo 3 delegó a los Subsecretarios Técnicos, dentro del ámbito de sus competencias; entre otras atribuciones las siguientes: “6) Autorizar y suscribir en el ámbito de sus atribuciones, y todo tipo de convenio interinstitucional con instituciones nacionales y/o internacionales, así como también la suscripción de aquellos instrumentos requeridos para la ejecución y terminación de dichos convenios; incluyendo actas de finiquito y cualquier otro instrumento necesario para la culminación de los mismos”.'
//   + '${SALTO}${TITULO3}1.10 El Acuerdo Nro. SENESCYT-2019-112 de 05 de septiembre de 2019.'
//   + '${SALTO}${PARRAFO}El numeral 6 del artículo 3 delegó a los Subsecretarios Técnicos, dentro del ámbito de sus competencias; entre otras atribuciones las siguientes: “6) Autorizar y suscribir en el ámbito de sus atribuciones, y todo tipo de convenio interinstitucional con instituciones nacionales y/o internacionales, así como también la suscripción de aquellos instrumentos requeridos para la ejecución y terminación de dichos convenios; incluyendo actas de finiquito y cualquier otro instrumento necesario para la culminación de los mismos”.'
//   + '${SALTO}${TITULO2}CLÁUSULA SEGUNDA.- ANTECEDENTES:'
//   + '${SALTO}${PARRAFO}(colocar los antecedentes que parten desde la solicitud y su procedimiento para llegar a este instrumento, por ejemplo:)'
//   + '${SALTO}${PARRAFO}La /El “SOLICITANTE”, con fecha <<solicitud.fecha>> solicitó ante la ventanilla única para la investigación para la biodiversidad – VUVIB,  la suscripción de un Contrato para el acceso a los Recursos Genéticos o sus productos derivados con potencial uso comercial, que le permita realizar actividades de desarrollo con potencial uso comerciales, a través del proyecto/plan denominado: <<solicitud.nombreProyecto>>.'
//   + '${SALTO}${TITULO2}CLÁUSULA TERCERA.- DE LAS PARTES:'
//   + '${SALTO}${PARRAFO}1. La /El “SOLICITANTE”'
//   + '${SALTO}${PARRAFO}La /El “SOLICITANTE”, es una persona natural/jurídica (especificar, según sea el caso), dedicada a la (investigación científica/actividad comercial de) (completar); tiene la intención de llevar adelante la utilización de recursos genéticos, derivados (y/o componente intangible asociados) de conformidad con la legislación y los procedimientos aplicables en el Estado ecuatoriano para el acceso a recursos genéticos y participación justa y equitativa de beneficios.'
//   + '${SALTO}${PARRAFO}La /El “SOLICITANTE”, ha suscrito un CLPI y a registrado el contrato de uso de los conocimientos tradicionales de la comunidad <<solicitud.comunidad>>, el cual está debidamente registrado en el SENADI y se anexa al presente Contrato. (Opcional)'
//   + '${SALTO}${PARRAFO}La /El “SOLICITANTE”, ha suscrito un Contrato accesorio con (nombre), en su carácter de proveedor de los recursos biológicos que contienen los recursos genéticos para su transferencia de material para los fines exclusivos detallados en la investigación/ desarrollo y enmarcado en el presente contrato.'
//   + '${SALTO}${PARRAFO}La /El “SOLICITANTE”, ha suscrito un Contrato accesorio con <<solicitud.institucionNacionalApoyo>>),  en su carácter de Institución Nacional de Apoyo.'
//   + '${SALTO}${PARRAFO}El presente CONTRATO se enmarca en el proyecto de investigación científica denominado, <<solicitud.nombreProyecto>>, del cual la /el “SOLICITANTE” es responsable; cuya finalidad es <<solicitud.objetivoProyecto>>, el mismo que producto de la evaluación efectuada por la SENECYT, MAATE y SENADI denota que las actividades a realizar en el citado proyecto no revisten carácter comercial.'
//   + '${SALTO}${PARRAFO}El equipo de investigadores nacionales y extranjeros, se encuentra dirigido por el (la) señor(a) <<solicitante.nombre>>, del ______________________________, en calidad de responsable del mencionado proyecto de investigación científica, identificado(a) con _______________; teniendo como responsables y colaboradores a los siguientes profesionales:'
//   + '${SALTO}${PERSONAL}<<solicitud.tablaEquipoTecnico>>'
//   + '${SALTO}${TITULO2}CLÁUSULA CUARTA.- DOCUMENTOS DEL CONTRATO:'
//   + '${SALTO}${PARRAFO}Forman parte integrante del Contrato los siguientes documentos:'
//   + '${SALTO}${PARRAFO}Documentos que acreditan la facultad para celebrar el Contrato;'
//   + '${SALTO}${PARRAFO}(Numerar y colocar los documentos habilitantes)'
//   + '${SALTO}${TITULO2}CLÁUSULA QUINTA.- INTERPRETACIÓN DEL CONTRATO, DEFINICIÓN DE TÉRMINOS E IDIOMA:'
//   + '${SALTO}${PARRAFO}Los términos del presente Contrato deben interpretarse en un sentido literal, en el contexto del mismo y cuyo objeto revele claramente la intención de las partes.'
//   + '${SALTO}${PARRAFO}En todo caso su interpretación sigue las siguientes normas: UNO) Cuando los términos se hallan definidos en las Leyes ecuatorianas, se estará a tal definición. DOS) Si no están definidos en las Leyes ecuatorianas se sujetará a lo dispuesto en el presente Contrato en su sentido literal y obvio, de conformidad con el objeto contractual y la intención de las partes. TRES) En su falta o insuficiencia se aplicarán las normas contenidas en el Código Civil Ecuatoriano.'
//   + '${SALTO}${PARRAFO}De existir contradicciones entre el Contrato y sus documentos, prevalecerán las normas del Contrato, De persistir las contradicciones o discrepancias será el “SENESCYT” el que determine la prevalencia de un texto, de conformidad con el objeto contractual.'
//   + '${SALTO}${PARRAFO}Definiciones.- Los términos o expresiones utilizados en el presente contrato deberán ser entendidos de acuerdo con las definiciones contenidas en el Convenio sobre la Diversidad Biológica, publicado en el Registro Oficial Nro. 647 de 06 de marzo de 1995, en el Código Orgánico del Ambiente, publicada en el Registro Oficial Suplemento 983 de 12 de abril de 2017; en el “Régimen Común sobre Acceso a los Recursos Genéticos” aprobado por la Decisión del Acuerdo de Cartagena 391 de la Comunidad Andina, publicado en el Registro Oficial Suplemento Nro. 05 de 16 de agosto de 1996, y en las demás leyes, normas y regulaciones aplicables y conexas al presente instrumento.'
//   + '${SALTO}${PARRAFO}Los términos o expresiones no definidos en estas leyes, normas y regulaciones, tendrán el significado que se deriva de su sentido técnico de acuerdo con el uso general de tal término o expresión comúnmente utilizado para la celebración y ejecución de este contrato debiendo entenderse de acuerdo con su significado natural y obvio; y,'
//   + '${SALTO}${PARRAFO}Los términos o expresiones que denoten singular también incluyen el plural y viceversa, siempre y cuando el contexto así lo requiera; y,'
//   + '${SALTO}${PARRAFO}Idioma.- Este contrato es redactado y suscrito por las partes en idioma castellano. Será considerada para todos sus efectos como la única válida.'
//   + '${SALTO}${PARRAFO}Las comunicaciones entre las partes, así como, la información requerida por la legislación aplicable serán redactadas en idioma castellano, excepto aquellos que por su índole deban ser presentados en otro idioma, en cuyo caso, de considerarse indispensable por el Estado ecuatoriano, deberán ser acompañados con una traducción al castellano preparada de conformidad con la ley.'
//   + '${SALTO}${TITULO2}CLÁUSULA SEXTA.- DE LAS PARTES, DECLARACIONES Y OBLIGACIONES DE LAS PARTES:'
//   + '${SALTO}${TITULO2}Declaraciones de la /el “SOLICITANTE”.-'
//   + '${SALTO}${PARRAFO}Autorización: la /el “SOLICITANTE” declara y garantiza que está debidamente autorizado y capaz para celebrar este Contrato de Acceso a los Recursos Genéticos o sus productos derivados con potencial uso comercial;'
//   + '${SALTO}${PARRAFO}Conocimiento de la legislación ecuatoriana e internacional: la /el “SOLICITANTE” declara expresamente que a la fecha de vigencia de este contrato tiene pleno conocimiento de la legislación ecuatoriana e internacional aplicable en cuanto al acceso a los recursos genéticos;'
//   + '${SALTO}${PARRAFO}Calificación y conformidad con requerimientos y prácticas: la /el “SOLICITANTE” declara y garantiza que, por sí misma, posee toda la experiencia adecuada y necesaria para ejecutar sus obligaciones según este contrato, así también garantiza y se obliga a mantenerse permanentemente calificada y en capacidad de ejecutar el objeto contractual, de acuerdo con los términos y condiciones mutuamente acordadas;'
//   + '${SALTO}${PARRAFO}Conocimiento de este contrato: la /el “SOLICITANTE” declara y garantiza que conoce la base en la cual se generó este contrato, requisitos y procedimientos, que ha examinado a cabalidad este instrumento legal y que conoce bien sus términos y disposiciones y que por tanto, renuncia a reclamos alegando desconocimiento o falta de comprensión de los mismos;'
//   + '${SALTO}${PARRAFO}Declaración de solvencia: la /el “SOLICITANTE” declara y garantiza que está financieramente solvente, en capacidad de ejecutar según su plazo de vencimiento y que posee suficiente capital de trabajo para cumplir las obligaciones previstas en este contrato;'
//   + '${SALTO}${PARRAFO}Declaración sobre capacidades técnicas profesionales y acreditación: la /el “SOLICITANTE” declara y asegura cuenta con todas las capacidades técnicas profesionales y acreditaciones requeridas por la Ley Aplicable para la consecución de este contrato; y,'
//   + '${SALTO}${PARRAFO}Cumplimiento de ley: la /el “SOLICITANTE” expresamente declara que la celebración y ejecución de este contrato no resulta ni resultará en una violación o incumplimiento a las disposiciones ambientales, sociales o culturales contenidas en cualquier ley, reglamento o sentencia a la que estuviesen sujetas, incluyendo la Ley Aplicable.'
//   + '${SALTO}${PARRAFO}Obligaciones de la /el “SOLICITANTE”.-'
//   + '${SALTO}${PARRAFO}Cumplir con la normativa ecuatoriana e internacional vigente, con especial énfasis en la la Ley Aplicable;'
//   + '${SALTO}${PARRAFO}(Colocar las obligaciones conforme el MAT)'
//   + '${SALTO}${TITULO2}CLÁUSULA SÉPTIMA.- DE LOS PROVEEDORES:'
//   + '${SALTO}${PARRAFO}(Datos de Identidad)'
//   + '${SALTO}${PARRAFO}1. Del Proveedor Del Recurso Biológico Fuente Del Recurso Genético'
//   + '${SALTO}${PARRAFO}2. Del Proveedor De Recursos Genéticos En Condiciones Ex Situ'
//   + '${SALTO}${PARRAFO}3. Del Proveedor De Conocimiento Individual'
//   + '${SALTO}${PARRAFO}4. Del Proveedor De Conocimiento Colectivo'
//   + '${SALTO}${TITULO2}CLÁUSULA OCTAVA.- SOBRE EL COMPONENTE INTANGIBLE:'
//   + '${SALTO}${PARRAFO}La /El “SOLICITANTE”, utilizará el componente intangible asociado a los recursos genéticos o sus productos derivados que recibe en virtud de este contrato de acuerdo con los términos del mismo, con sujeción a lo previsto en la legislación nacional e internacional.'
//   + '${SALTO}${TITULO2}CLÁUSULA NOVENA.- SOBRE LA INSTITUCIÓN NACIONAL DE APOYO:'
//   + '${SALTO}${PARRAFO}La /El “SOLICITANTE” ha establecido como Institución Nacional de Apoyo, <<solicitud.institucionNacionalApoyo>>, mediante el contrato accesorio (código número fecha).'
//   + '${SALTO}${TITULO2}CLÁUSULA DÉCIMA.- DE LA TRANSFERENCIA DE MATERIAL Y CONOCIMIENTO:'
//   + '${SALTO}${PARRAFO}(definir conforme el MAT)'
//   + '${SALTO}${TITULO2}CLÁUSULA DÉCIMA PRIMERA.- INTRANSFERIBILIDAD DEL CONTRATO, SOBERANÍA SOBRE LOS RECURSOS GENÉTICOS Y PROPIEDAD INTELECTUAL:'
//   + '${SALTO}${PARRAFO}Los derechos que se otorgan y las obligaciones que se generan para la /el “SOLICITANTE”, así como el material genético; en virtud del presente CONTRATO, no pueden ser cedidos ni transferidos a terceras personas o instituciones, con excepción de transferencias necesarias para realizar actividades listadas bajo el presente instrumento'
//   + '${SALTO}${PARRAFO}Se notificará a la SENESCYT las transferencias realizadas y qué medidas se han tomado para que tal transferencia no conlleve a usos no autorizados bajo este contrato.'
//   + '${SALTO}${PARRAFO}La soberanía sobre los recursos genéticos, las Partes, declaran expresamente reconocer al Estado ecuatoriano como titular de los recursos genéticos, productos derivados y de las muestras objeto del presente CONTRATO, obtenidas a través de las muestras colectadas, independientemente que sean conservados en condiciones ex situ, dentro o fuera del territorio nacional.'
//   + '${SALTO}${PARRAFO}La /El “SOLICITANTE”  reconoce el origen ecuatoriano de los recursos genéticos autorizados por el presente CONTRATO.'
//   + '${SALTO}${PARRAFO}Asimismo, el presente Contrato no otorga expresa ni implícitamente a la /el “SOLICITANTE”  ninguna licencia ni otros derechos en virtud de una patente, ni solicitud de patente, ni secretos comerciales ni otros derechos de propiedad intelectual.'
//   + '${SALTO}${TITULO2}CLÁUSULA DÉCIMO SEGUNDA.- PROHIBICIONES:'
//   + '${SALTO}${PARRAFO}Cualquier actividad que involucre los recursos genéticos (o conocimiento tradicional asociado) que no esté expresamente permitido por este Contrato, se considerará prohibida, y su realización constituirá un incumplimiento y motivo de este Contrato.'
//   + '${SALTO}${PARRAFO}Sin limitar la generalidad de lo anterior, se acuerda que la /el “SOLICITANTE”:'
//   + '${SALTO}${PARRAFO}a. No solicitará o adquirirá, por sí o por terceros, derechos de propiedad intelectual sobre los recursos genéticos, sus partes y componentes o información genética, ni sobre el conocimiento tradicional autorizado por el pueblo indígena o comunidad local en virtud de este Contrato, incluyendo, pero sin limitación, patentes y derechos de obtentor.'
//   + '${SALTO}${PARRAFO}La /El “SOLICITANTE” podrá, sin embargo, solicitar y obtener tales derechos en relación, previa autorización por escrito a la SECRETARIA. Tal autorización se considerará aprobada si la SECRETARIA no le denegara.'
//   + '${SALTO}${PARRAFO}b. No cederá el presente contrato a título gratuito u oneroso.'
//   + '${SALTO}${PARRAFO}c. No transferirá los recursos genéticos o el componente intangible recibidos ni licenciará su uso, a título gratuito u oneroso, a un tercero sin la autorización por escrito de y bajo las condiciones determinadas por la SECRETARIA. Tal autorización se considerará denegada si la SECRETARIA no la concediera.'
//   + '${SALTO}${TITULO2}CLÁUSULA DÉCIMO TERCERA.- OBJETO, OBJETIVOS Y EQUIPO TÉCNICO:'
//   + '${SALTO}${PERSONAL}<<solicitud.tablaEquipoTecnico>>'
//   + '${SALTO}${TITULO2}CLÁUSULA DÉCIMO CUARTA.- PLAZO Y VIGENCIA:'
//   + '${SALTO}${PARRAFO}El presente contrato tendrá una duración de <<plazoProyectoAnio>> contados a partir de la fecha de suscripción del presente instrumento, no obstante las partes acuerdan que se podrá renovar o ampliar la vigencia contractual de mutuo acuerdo por las partes de forma expresa y por escrito.'
//   + '${SALTO}${PARRAFO}A la terminación de la vigencia contractual o previa la renovación o ampliación se evaluará el cumplimiento del objeto del presente contrato.'
//   + '${SALTO}${TITULO2}CLÁUSULA DÉCIMO QUINTA.- NO EXCLUSIVIDAD:'
//   + '${SALTO}${PARRAFO}La SECRETARÍA, en representación del Estado ecuatoriano como propietario del recurso biológico y/o genético, podrá celebrar o ejecutar acuerdos y convenios que autoricen el acceso al recurso biológico y/o genético materia del presente contrato con entidades públicas o privadas, sean nacionales o extranjeras, sin perjuicio de lo estipulado en el presente instrumento.'
//   + '${SALTO}${TITULO2}CLÁUSULA DÉCIMA SEXTA.- DE LAS MUESTRAS:'
//   + '${SALTO}${PARRAFO}<<solicitud.metodologiaLaboratorio>>'
//   + '${SALTO}${PARRAFO}Recursos IN SITU'
//   + '${SALTO}${RECURSOS.INSITU}<<recursos.tablaRecursosInSitu>>'
//   + '${SALTO}${PARRAFO.VACIO}'
//   + '${SALTO}${PARRAFO}Recursos EX SITU'
//   + '${SALTO}${RECURSOS.EXSITU}<<recursos.tablaRecursosExSitu>>'
//   + '${SALTO}${PARRAFO}Si durante el análisis y desarrollo para la ejecución de este contrato llegare a ser necesario colectar muestras adicionales o extender los lugares de muestreo, la /el “SOLICITANTE” solicitará autorización a la SECRETARIA mediante un informe técnico, en el cual se justifique el incremento de la colección, en tal razón, la SECRETARIA analizará técnicamente si es procedente conceder dicha solicitud.'
//   + '${SALTO}${PARRAFO}Si la /el “SOLICITANTE” necesita transferir material, en el marco de este contrato, deberá estar acorde al objeto y objetivo de este contrato y será indispensable que se genere mediante un Acuerdo de Transferencia de Material (ATM), mismo que será un instrumento legal remitido para revisión de la SECRETARIA y regulará la transferencia, el uso del material y el retorno o disposición final de las muestras; las muestras únicas serán exportadas con propósitos de investigación científica por un plazo no mayor a doce (12) meses.'
//   + '${SALTO}${PARRAFO}Este contrato no ampara el permiso de exportación de muestras, por lo que deberán ser requeridos a autoridad competente acompañado con el aprobado del ATM.'
//   + '${SALTO}'
//   + '${SALTO}${TITULO2}CLÁUSULA DÉCIMA SÉPTIMA.- DEPÓSITO DE MUESTRAS:'
//   + '${SALTO}${PARRAFO}La /El “SOLICITANTE” depositará una cantidad representativa y viable de muestras del material en la colección del Instituto Nacional de Biodiversidad - INABIO.'
//   + '${SALTO}${PARRAFO}En un plazo de tres meses de la fecha de la entrega a dicha institución pública, la /el “SOLICITANTE” deberá notificar a la SECRETARIA, la fecha de la entrega y la lista de las muestras depositadas indicando que fueron aceptadas por el INABIO con el respectivo certificado de depósito de muestras.'
//   + '${SALTO}${PARRAFO}a. El depósito al INABIO deberá ser acompañado de la información obtenida por la /el “SOLICITANTE” sobre los recursos genéticos objeto de este Contrato, en particular, la resultante de:'
//   + '${SALTO}${PARRAFO}1. Ensayos de elaboración y viabilidad del material, su progenie o sus derivados;'
//   + '${SALTO}${PARRAFO}2. La identificación taxonómica del material, su progenie o derivados.'
//   + '${SALTO}${TITULO2}CLÁUSULA DÉCIMO OCTAVA.- NUEVOS USOS:'
//   + '${SALTO}${PARRAFO}Si como resultado de los ensayos y análisis realizados, la /el “SOLICITANTE” manifiesta su interés en utilizar el material o componente intangible recibido con fines distintos a los previstos en el presente Contrato, la /el “SOLICITANTE” acuerda entablar negociaciones con la SECRETARIA a fin de establecer los términos y condiciones para los nuevos usos.'
//   + '${SALTO}${PARRAFO}A tal fin podrá realizarse una modificación del presente Contrato que exprese el acuerdo por escrito de las partes.'
//   + '${SALTO}${TITULO2}CLÁUSULA DÉCIMO NOVENA.- SOBRE LOS BENEFICIOS:'
//   + '${SALTO}${PARRAFO}(Establecer los beneficios monetarios y no monetarios por cada etapa de ser el caso considerar los establecidos en el MAT)'
//   + '${SALTO}${PARRAFO}En el caso de que, en la cadena de valor, la /el “SOLICITANTE” efectúe pagos de beneficios monetarios a otras partes entre los que están pero sin limitarse los proveedores, la INA o pueblos indígenas y comunidades locales, estos deberán deducirse de lo previsto en este Contrato a efectos de evitar una duplicación de pagos por el acceso y utilización de recursos genéticos.'
//   + '${SALTO}${PARRAFO}Las partes convienen que, en el presente Contrato por su naturaleza se podrá aplicar el reajuste de beneficios monetarios o no monetarios en consideración a las condiciones que cada una de las partes presente a la otra mediante el justificativo motivado y bajo aprobación por escrito de la contraparte.'
//   + '${SALTO}${TITULO2}CLÁUSULA VIGÉSIMA.- FORMA DE EMISIÓN DE BENEFICIOS:'
//   + '${SALTO}${PARRAFO}Los beneficios cualquiera sea su naturaleza se lo hará de forma (establecer temporalidad).'
//   + '${SALTO}${PARRAFO}(Establecer de ser el caso el cronograma)'
//   + '${SALTO}${TITULO2}CLÁUSULA VIGÉSIMA PRIMERA.- MULTAS:'
//   + '${SALTO}${PARRAFO}Tiene por finalidad corregir las faltas que la /el “SOLICITANTE” incurra durante el desarrollo de este contrato desde su inicio hasta la finalización del Contrato, si como evitar el incumplimiento en la ejecución del mismo, por lo tanto la SECRETARIA sancionará a la /el “SOLICITANTE” con las multas siguientes:'
//   + '${SALTO}${PARRAFO}Faltas Leves:'
//   + '${SALTO}${PARRAFO}(Establecer las faltas leves y su sanción)'
//   + '${SALTO}${PARRAFO}10.1. Faltas Graves:'
//   + '${SALTO}${PARRAFO}(Establecer las faltas graves y su sanción)'
//   + '${SALTO}${TITULO2}CLÁUSULA VIGÉSIMA SEGUNDA.- DE LA ADMINISTRACIÓN DEL CONTRATO:'
//   + '${SALTO}${PARRAFO}La SECRETARIA designa en calidad de Administrador del Contrato (establecer nombre de administrador de SENESCYT) en su condición de (establecer cargo), quien deberá atenerse a las condiciones generales y particulares de este contrato.'
//   + '${SALTO}${PARRAFO}La /El “SOLICITANTE” designa en calidad de Administrador del Contrato (establecer nombre de administrador de la contraparte) en su condición de (establecer cargo), quien deberá atenerse a las condiciones generales y particulares de este contrato.'
//   + '${SALTO}${PARRAFO}Los Administradores de este Contrato, queda autorizado para realizar las gestiones inherentes a su ejecución.'
//   + '${SALTO}${PARRAFO}Los Administradores serán los encargados de velar por el cumplimiento de las normas legales y compromisos contractuales de las partes, durante todo el periodo de vigencia del Contrato.'
//   + '${SALTO}${PARRAFO}Respecto de su gestión reportará a sus superiores, debiendo comunicar todos los aspectos operativos, técnicos, económicos y de cualquier naturaleza que pudieren afectar al cumplimiento del objeto del Contrato.'
//   + '${SALTO}${PARRAFO}Las partes podrán cambiar de Administrador de Contrato, para lo cual bastará cursar la respectiva comunicación, sin que sea necesario la modificación del texto contractual.'
//   + '${SALTO}${TITULO2}CLÁUSULA VIGÉSIMA TERCERA.- TERMINACIÓN DEL CONTRATO:'
//   + '${SALTO}${PARRAFO}1. 	Por incumplimiento del objeto o compromisos de las partes en el presente contrato.'
//   + '${SALTO}${PARRAFO}2. 	Por cumplimiento del objeto.'
//   + '${SALTO}${PARRAFO}3. 	Por cumplimiento de la vigencia o plazos.'
//   + '${SALTO}${PARRAFO}4. 	Por fuerza mayor o caso fortuito, conforme a lo dispuesto en el Art. 30 del Código Civil ecuatoriano, debiendo los hechos ser debidamente justificados por parte de la parte que lo alegare dentro de los treinta (30) días de ocurrido el hecho, siempre y cuando las circunstancias hubieren hecho imposible la ejecución de este contrato.'
//   + '${SALTO}${PARRAFO}5. 	Por mutuo acuerdo de las partes.'
//   + '${SALTO}${PARRAFO}6. 	Por terminación unilateral y anticipada a causa del incumplimiento de alguna de las obligaciones o condiciones previstas en este instrumento, previa notificación a la contraparte a fin de que en un plazo máximo de quince (15) días subsane dichas observaciones, caso contrario se iniciarán las acciones penales, civiles o administrativas que correspondan, en los siguientes casos:'
//   + '${SALTO}${PARRAFO}Por explotación o acceso al recurso biológico o genético no autorizado;'
//   + '${SALTO}${PARRAFO}Por presentación de información falsa o por haber alterado la misma;'
//   + '${SALTO}${PARRAFO}Por declaratoria de daño ambiental por parte del Ministerio del Ambiente, Agua y Transición Ecológica;'
//   + '${SALTO}${PARRAFO}Por declaratoria o informe de uso indebido o apropiación de algún conocimiento tradicional manifestado por SENADI;'
//   + '${SALTO}${PARRAFO}En caso de terminación anticipada, el presente instrumento permanecerá plenamente vigente respecto de las obligaciones y responsabilidades de la/el SOLICITANTE, salvo acuerdo expreso en contrario entre las partes.'
//   + '${SALTO}${TITULO2}CLÁUSULA VIGÉSIMA CUARTA: LIQUIDACIÓN DEL CONTRATO.-'
//   + '${SALTO}${PARRAFO}Al momento de darse la terminación del presente Contrato por cualquiera de las causales estipuladas en las cláusulas precedentes, se deberá realizar por parte de la/el SOLICITANTE el informe técnico de gestión y finalización, el cual será aprobado por la SECRETARÍA y dará lugar a la elaboración de una Acta de Finiquito, que deberá ser suscrita por las partes. En esta Acta deberá detallarse los productos o actividades desarrolladas, con indicación de cumplimiento de plazos y cronogramas, prórrogas de plazo que se hubieren efectuado, y la correspondiente repartición de beneficios, además de la inclusión de cláusulas y otras condiciones según el procedimiento interno de cada parte y debidamente firmado por ambas partes.'
//   + '${SALTO}${TITULO2}CLÁUSULA VIGÉSIMA QUINTA: SOLUCIÓN DE DIVERGENCIAS O CONTROVERSIAS.-'
//   + '${SALTO}${PARRAFO}Negociaciones Directas.- En conflictos que por su naturaleza puedan ser transigibles y que tengan relación con la aplicación, interpretación, ejecución, incumplimiento, resolución de actos técnicos y operativos, así como por la terminación anticipada o cualquier otra circunstancia relacionada con las operaciones realizadas durante la vigencia del contrato, las partes buscarán negociar un arreglo directo entre ellas.'
//   + '${SALTO}${PARRAFO}Mediación.- Si se suscitaren divergencias o controversias que las partes no llegaren a un acuerdo mediante negociación directa, podrán utilizar la mediación para la solución de sus controversias, para lo cual, las partes estipulan acudir al Centro de Mediación de la Procuraduría General del Estado.'
//   + '${SALTO}${PARRAFO}Si se llegara a firmar un acta de acuerdo total, o parcial la misma tendrá efecto de sentencia ejecutoriada y cosa juzgada, su ejecución será del mismo modo que la sentencia de última instancia, conforme lo dispuesto en el artículo 47 de la Ley de Arbitraje y Mediación.'
//   + '${SALTO}${PARRAFO}Contencioso Administrativo.- En el caso de no existir acuerdo, las partes suscribirán la respectiva acta de imposibilidad de acuerdo, y la controversia se ventilará ante el Tribunal de lo Contencioso Administrativo del domicilio de la “SECRETARIA”.'
//   + '${SALTO}${PARRAFO}En consecuencia, el/la “SOLICITANTE” renuncia a utilizar la vía diplomática para todo reclamo relacionado con este contrato.'
//   + '${SALTO}${TITULO2}CLÁUSULA VIGÉSIMA SEXTA: SUPERVISIÓN ADMINISTRADORES Y COMUNICACIONES.-'
//   + '${SALTO}${PARRAFO}(Completar)'
//   + '${SALTO}${TITULO2}CLÁUSULA VIGÉSIMA SÉPTIMA: PROPIEDAD INTELECTUAL.-'
//   + '${SALTO}${PARRAFO}(Completar)'
//   + '${SALTO}${TITULO2}CLÁUSULA VIGÉSIMA OCTAVA: CONFIDENCIALIDAD.-'
//   + '${SALTO}${PARRAFO}(Completar)'
//   + '${SALTO}${TITULO2}CLÁUSULA VIGÉSIMA NOVENA: RENUNCIA A DERECHOS O ACCIONES.-'
//   + '${SALTO}${PARRAFO}La falta de ejercicio total o parcial de los derechos o acciones de cualquiera de las partes, bajo los términos del presente contrato, no constituirá bajo ningún concepto una renuncia a dichos derechos o acciones, por lo tanto, subsisten hasta la terminación de este contrato o en su defecto hasta la resolución que emita la SECRETARÍA.'
//   + '${SALTO}${TITULO2}CLÁUSULA TRIGÉSIMA.- GARANTÍAS:'
//   + '${SALTO}${PARRAFO}(Definir en el caso de existir o solicitar garantías las mismas partirán del MAT de ser el caso)'
//   + '${SALTO}${TITULO2}CLÁUSULA TRIGÉSIMA PRIMERA.- ACEPTACIÓN DE LAS PARTES:'
//   + '${SALTO}${PARRAFO}Las partes libre, voluntaria y expresamente declaran que conocen y aceptan el texto íntegro del presente Contrato.'
//   + '${SALTO}${PARRAFO}Para constancia y conformidad con lo antes expuesto y contratado, firman en unidad de acto con sus respectivas firmas electrónicas, no obstante, si la firma se produce en fechas distintas, entrará en vigor en la fecha de la última firma.'
//   + '${SALTO}${PARRAFO}Dado en la ciudad de San Francisco de Quito..'
//   + '${SALTO}'
//   + '${SALTO3}'
//   + '${SALTO}${CENTRAR}<<sistema.nombreAutoridadSenescyt>>'
//   + '${SALTO}${CENTRAR}<<sistema.rolAutoridadSenescyt>>'
//   + '${SALTO}${CENTRAR}<<sistema.cargoAutoridadSenescyt>>'
//   + '${SALTO3}'
//   + '${SALTO}${CENTRAR}<<solicitud.representanteLegalPatrocinadora>>'
//   + '${SALTO}${CENTRAR}<<solicitud.cargoRepresentanteLegalPatrocinadora>>'
//   + '${SALTO}${CENTRAR}<<solicitud.institucionPatrocinadora>>'
