import * as React from 'react';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ChatIcon from '@mui/icons-material/Chat';
import ThreePIcon from '@mui/icons-material/ThreeP';
import { useSelector, useDispatch } from 'react-redux';
import { limpiarChats, limpiarRoom, nuevoChat, seleccionaRoom } from './sliceChat';
import { retornaChatRoomsxSolicitud, retornaChatsxRoom } from './API';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { AvatarResponde } from './AvatarResponde';

const FireNav = styled(List)({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

export const Rooms = () => {

  const dispatch = useDispatch();

  const { chatRooms } = useSelector(state => state.chat)
  const { instanciaTarea, perfilUsuario, usuario, instanciaProceso } = useSelector(state => state.app)
  const [open, setOpen] = React.useState(true);

  const handleCrearChat = () => {
    dispatch(nuevoChat());
  }

  const handleClick = (event, id) => {
    dispatch(limpiarRoom());
    dispatch(seleccionaRoom(id));
    dispatch(limpiarChats());
    dispatch(retornaChatsxRoom(id));
  }

  const handleClickAbrir = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (perfilUsuario?.id) {
      if (usuario.tipo === 'FUNCIONARIO') {
        if (instanciaProceso?.solicitud?.id) {
          dispatch(retornaChatRoomsxSolicitud(instanciaProceso?.solicitud?.id))
        }
      }
    }
  }, [instanciaProceso])

  return (
    <Box sx={{ display: 'flex' }}>
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: 'dark',
            primary: { main: 'rgb(102, 157, 246)' },
            background: { paper: 'rgb(5, 30, 52)' },
          },
        })}
      >
        <Paper elevation={0} sx={{ maxWidth: 250, mt: 5, ml: 3 }}>
          <FireNav component="nav" disablePadding>
            <ListItemButton component="a" href="#customized-list">
              <ListItemIcon sx={{ fontSize: 20 }}><ChatIcon /></ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary="Chats"
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: 'medium',
                  letterSpacing: 0,
                }}
              />
            </ListItemButton>
            <Divider />

            <ListItem component="div" disablePadding>
              {

                (usuario?.tipo === 'FUNCIONARIO' &&

                  (
                    parseInt(instanciaTarea?.perfilUsuarioId || 0) === perfilUsuario.id ?

                      <ListItemButton sx={{ height: 56 }} onClick={handleCrearChat}>

                        <ListItemIcon>
                          <PersonAddIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Crear chat"
                          primaryTypographyProps={{
                            color: 'primary',
                            fontWeight: 'medium',
                            variant: 'body2',
                          }}
                        />
                      </ListItemButton>

                      :

                      <ListItemButton sx={{ height: 56 }}>
                        <ListItemIcon>
                          <BookmarkAddIcon color="warning" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Para crear un chat primero seleccione una tarea"
                          primaryTypographyProps={{
                            color: '#eba229',
                            fontWeight: 'medium',
                            variant: 'body2',
                          }}
                        />
                      </ListItemButton>
                  )
                )
              }
            </ListItem>

            <Divider />

            <Box
              sx={{
                bgcolor: open ? 'rgba(71, 98, 130, 0.2)' : null,
                pb: open ? 2 : 0,
              }}
            >

              <ListItemButton onClick={handleClickAbrir}>
                <ListItemIcon>
                  <ThreePIcon />
                </ListItemIcon>
                <ListItemText primary="Lista de chats" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>

                  {open &&
                    chatRooms.length === 0 ?
                    <ListItemText
                      primary={"No existe ningún chat"}
                      primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium', ml: 4, color: 'error', }}
                    /> :
                    chatRooms.map((item) => (
                      <ListItemButton
                        onClick={(event) => handleClick(event, item.id, item.roomName)}
                        key={item.id}
                        sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}
                      >

                        <AvatarResponde id={item.identificadorPersona} nombre={item.nombrePersona}/>

                        <ListItemText
                          primary={item.roomName}
                          primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium', ml: 2 }}
                          secondary={item.nombrePersona || 'N/A'}
                          secondaryTypographyProps={{ fontSize: 14, fontWeight: 'bold', color: 'primary', ml: 2 }}
                        />
                        </ListItemButton>
                    ))}

                </List>
              </Collapse>
            </Box>
          </FireNav>
        </Paper>
      </ThemeProvider>
    </Box>
  );
}
