import React, {useEffect, useState} from 'react'
import {
  Box,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Stack, Tab,
} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {SectionTitle} from 'src/components/SectionTitle'
import AppBar from '@mui/material/AppBar'
import {MySubtitle} from "../../components/MySubtitle";
import {MyTextField} from "../../components/MyTextField";
import {Solicitante} from "../P01Solicitud/subcomponents/Solicitante";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import {MyUpload} from "../../components/MyUpload";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import FormControl from "@mui/material/FormControl";

// eslint-disable-next-line import/no-anonymous-default-export
export const P16SolicitudRO = ({payload}) => {
  const canEdit = false

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Cierre de la Autorización'} />
      </AppBar>
      <Grid container spacing={1} sx={accordeonBox.container2}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Solicitud'} />
        </Grid>
        <Grid item xs={6} >
          <MyReadOnlyTextField label={'Identificador'}
                               icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                               value={payload.Solicitud.aprobadaIdentificador} />
        </Grid>
        <Grid item xs={6} >
          <MyReadOnlyTextField label={'Fecha'}
                               icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                               value={payload.Solicitud.fecha} />
        </Grid>
        <Solicitante solicitud={{solicitud: {payload: JSON.parse(payload.Solicitud.solicitudAprobada)}}} displayContact={false}/>
        {/*<Autorizacion solicitud={{numeroSolicitud: payload.Solicitud.aprobadaIdentificador, payload: payload.Solicitud.solicitudAprobada}} />*/}
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Solicitud de cierre'} />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p: '0 0 0 0'}}>
            <FormControl>
              <FormLabel id="tipo-cierre">Tipo de cierre</FormLabel>
              <RadioGroup row
                          aria-labelledby="tipo-cierre"
                          name="tipo-cierre"
                          value={payload.Solicitud.tipoCierre}>
                <FormControlLabel value="final" control={<Radio />} label="Cierre final por cumplimiento de objetivos" />
                <FormControlLabel value="anticipado" control={<Radio />} label=" Cierre anticipado" />
              </RadioGroup>
            </FormControl>
            <Link href="#" sx={{pt:'2rem'}}>Descargar formato del Informe</Link>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <MyTextField id='tema'
                       label={'Tema *'}
                       formValues={payload.Solicitud}
                       icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>}
                       canEdit={canEdit} />
        </Grid>
        <Grid item xs={12}>
          <MyAreaTextField id='justificacion'
                           label={'Justificación *'}
                           formValues={payload.Solicitud}
                           icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
        </Grid>
        {payload.Solicitud.tipoCierre === 'final' &&
          <>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent="flex-end" alignItems="center" sx={{p: '0 0 0 0'}}>
                <MyUpload id={'pdf'}
                          label={'Informe de cierre *'}
                          dir={payload.Solicitud.solicitudId}
                          formValues={payload.Solicitud}
                          canEdit={canEdit}
                          info={'info...'}/>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent="flex-end" alignItems="center" sx={{p: '0 0 0 0'}}>
                <MyUpload id={'zip'}
                          label={'adjunto *'}
                          dir={payload.Solicitud.solicitudId}
                          formValues={payload.Solicitud}
                          canEdit={canEdit}
                          mode={'zip'}
                          info={'info...'}/>
              </Stack>
            </Grid>
          </>
        }
      </Grid>
      <CssBaseline />
    </Box>
  </>
}
