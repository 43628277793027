import React from 'react'
import Box from '@mui/material/Box'
import {Grid,} from '@mui/material'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyReadOnlyTextField} from "../../../components/MyReadOnlyTextField";
import {MySwitch} from "../../../components/MySwitch";
import TableBody from "@mui/material/TableBody";
import {f} from "../../../commons";
import TableRow from "@mui/material/TableRow";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {format, parseISO} from "date-fns";
import {es} from "date-fns/locale";
import Table from "@mui/material/Table";
import {Stack} from "@mui/system";

export const AccesoConocimientoRO = ({payload}) => {
  const section = 'AccesoConocimiento'
  const acceso = payload[section]
  const canEdit = false
  const hstyle = {width:'20%', fontWeight:'bold'}
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={dialog.titleContainer}>
          <MySubtitle subtitle={'Conocimiento tradicional'} />
        </Grid>
        <Grid item xs={12}>
          <MySwitch id='accesoConocimiento'
                    label={'¿Existe acceso, uso o aprovechamiento de conocimientos tradicionales asociados a la biodiversidad?'}
                    formValues={acceso}
                    canEdit={canEdit} />
        </Grid>
        { acceso.accesoConocimiento &&
          <>
            <Grid item xs={5}>
              <Stack direction={'column'}>
                <MyReadOnlyTextField id={'clpi'}
                                     label={'Número de registro CLPI'}
                                     canEdit={canEdit}
                                     value={acceso.clpi}
                                     icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <MyReadOnlyTextField id={'contratoAccesoConocimiento'}
                                   label={'Número de registro de contrato de acceso, uso o aprovechamiento del conocimiento tradicional'}
                                   canEdit={canEdit}
                                   value={acceso.contratoAccesoConocimiento}
                                   icon={<ArticleOutlinedIcon sx={dialog.textFieldIcon}/>} />
            </Grid>
                <Grid container spacing={4}>
                  {(f.isValid(acceso.numTramite) && acceso.numTramite !== '') ?
                    <Grid item xs={6}>
                      <Box sx={{width:'100%', border: '1px dotted black', borderRadius:'0.5rem'}}>
                        <Table sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
                          <TableBody>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Trámite Nro.</TableCell><TableCell>{acceso.numTramite}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Presentación</TableCell><TableCell>{f.isValidNotEmpty(acceso.fechaPresentacion) && format(parseISO(acceso.fechaPresentacion), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de otorgamiento del CLPI</TableCell><TableCell>{f.isValidNotEmpty(acceso.fechaOtorgamiento) && format(parseISO(acceso.fechaOtorgamiento), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Vigencia</TableCell><TableCell>{f.isValidNotEmpty(acceso.fechaFinVigencia) && format(parseISO(acceso.fechaFinVigencia), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Registro</TableCell><TableCell>{f.isValidNotEmpty(acceso.fechaRegistro) && format(parseISO(acceso.fechaRegistro), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Título del Plan de acceso, uso o aprovechamiento del conocimiento tradicional</TableCell><TableCell>{acceso.titulo}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Legítimos Poseedores</TableCell><TableCell>{acceso.legitimosPoseedores}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Nacionalidad / Pueblo / Comunidad</TableCell><TableCell>{acceso.nacionalidad}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Solicitante</TableCell><TableCell>{acceso.solicitante}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>País Origen Solicitante</TableCell><TableCell>{acceso.paisOrigen}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Usuarios de CC.TT. Autorizados</TableCell><TableCell>{f.isValidNotEmpty(acceso.usuariosAutorizados) && acceso.usuariosAutorizados?.map(it => `${it.usuario} (${it.pais})`)?.join(', ')}</TableCell></TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Grid> : null
                  }
                  {(f.isValid(acceso.numTramite2) && acceso.numTramite2 !== '') ?
                    <Grid item xs={6}>
                      <Box sx={{width:'100%', border: '1px dotted black', borderRadius:'0.5rem'}}>
                        <Table sx={{[`& .${tableCellClasses.root}`]: {borderBottom: "none"}}}>
                          <TableBody>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Trámite Nro.</TableCell><TableCell>{acceso.numTramite2}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Presentación</TableCell><TableCell>{f.isValidNotEmpty(acceso.fechaPresentacion2) && format(parseISO(acceso.fechaPresentacion2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de suscripción del contrato</TableCell><TableCell>{f.isValidNotEmpty(acceso.fechaOtorgamiento2) && format(parseISO(acceso.fechaOtorgamiento2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Vigencia</TableCell><TableCell>{f.isValidNotEmpty(acceso.fechaFinVigencia2) && format(parseISO(acceso.fechaFinVigencia2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Fecha de Registro</TableCell><TableCell>{f.isValidNotEmpty(acceso.fechaRegistro2) && format(parseISO(acceso.fechaRegistro2), 'dd MMMM yyyy', {locale: es})}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Título del Plan de acceso, uso o aprovechamiento del conocimiento tradicional</TableCell><TableCell>{acceso.titulo2}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Legítimos poseedores</TableCell><TableCell>{acceso.legitimosPoseedores2}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Nacionalidad / Pueblo / Comunidad</TableCell><TableCell>{acceso.nacionalidad2}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Solicitante</TableCell><TableCell>{acceso.solicitante2}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>País Origen Solicitante</TableCell><TableCell>{acceso.paisOrigen2}</TableCell></TableRow>
                            <TableRow><TableCell sx={hstyle} align={'left'}>Usuarios de CC.TT. Autorizados</TableCell><TableCell>{f.isValidNotEmpty(acceso.usuariosAutorizados2) && acceso.usuariosAutorizados2?.map(it => `${it.usuario} (${it.pais})`)?.join(', ')}</TableCell></TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Grid> : null
                  }
                </Grid>
          </>
        }
        { !acceso.accesoConocimiento &&
          <>
            <Grid item xs={12}>
              <MySwitch id='ambitoComunitario'
                        label={'¿La investigación se desarrolla en un ámbito comunitario ancestral?'}
                        formValues={acceso}
                        canEdit={canEdit} />
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='valoresReligiososCultutrales'
                        label={'¿La investigación hace referencia a temas de cosmovisión valores religiosos o culturales?'}
                        formValues={acceso}
                        canEdit={canEdit} />
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='recursosBiologico'
                        label={'¿La investigación se relaciona con un ritual curativo donde se utilice los recursos biológicos?'}
                        formValues={acceso}
                        canEdit={canEdit} />
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='practicasAncestrales'
                        label={'¿La investigación versan sobre mecanismos y prácticas de siembra cosecha, mantenimiento y recolección de semillas entre otras prácticas agropecuarias ancestrales?'}
                        formValues={acceso}
                        canEdit={canEdit} />
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='etnozoologicos'
                        label={'¿La investigación se basa en estudio de carácter etnobotánico o etnozoologico?'}
                        formValues={acceso}
                        canEdit={canEdit} />
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='aConocimientoTradicional'
                        label={'¿La investigación hace referencia sobre el uso de un recurso biológico planta o animal asociado a un onocimiento tradicional?'}
                        formValues={acceso}
                        canEdit={canEdit} />
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='derivadosOSimilares'
                        label={'¿La investigación hace referencia a compuestos biológicos naturales para la elaboración de productos alimenticios dieteticos colorantes cosméticos y derevados o similares?'}
                        formValues={acceso}
                        canEdit={canEdit} />
            </Grid>
            <Grid item xs={12}>
              <MySwitch id='medicinaTradicional'
                        label={'¿La investigacion se basa en combinaciones de extractos biológicos naturales para la preparación de medicina tradicional?'}
                        formValues={acceso}
                        canEdit={canEdit} />
            </Grid>
          </>
        }
      </Grid>
    </Box>
  )
}
