import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from "formik";

export const SelectProvincia = ({ ...props }) => {

    const { provincias } = useSelector(state => state.administracion)
    const [age, setAge] = React.useState(350101);
    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
        if (props.accion === 1) {
            setAge(props.value);
        }
    }, [])

    const handleChange = (event) => {
        setAge(event.target.value);
        setFieldValue("provincia", event.target.value)
    };

    return (
        <div>
            <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="provincia-label">Provincia</InputLabel>
                <Select
                    labelId="provincia-label"
                    id="provincia"
                    value={age}
                    onChange={handleChange}
                    autoWidth
                    required
                    label="Provincia"
                >
                    {

                        props.accion === 1 ?

                            provincias.map((provincia) => (
                                provincia.id === props.value &&
                                <MenuItem key={provincia.id} value={provincia.id}>{provincia.nombreProvincia}</MenuItem>
                            ))
                            :

                            provincias.map((provincia) => (
                                <MenuItem key={provincia.id} value={provincia.id}>{provincia.nombreProvincia}</MenuItem>
                            ))
                    }

                </Select>
            </FormControl>
        </div>
    );
}
