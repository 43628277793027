import { createSlice } from '@reduxjs/toolkit'

const state0 = {
  msgTest: null,
  chatRooms: [],
  room: null,
  chats: [],
  modalChatOpen: false,
  usuarios: []
}

// REDUCERS !!

export const sliceChat = createSlice({
  name: "chat",
  initialState: { ...state0 },
  reducers: {
    setMsgTest: (state, action) => {
      state.msgTest = action.payload
    },
    setChatRooms: (state, action) => {
      state.chatRooms = [...action.payload]
    },
    setRoom: (state, action) => {
      state.room = state.chatRooms.filter(
        e => (e.id === action.payload)
      )
    },
    cleanRoom: (state, action) => {
      state.room = null
    },
    setChats: (state, action) => {
      state.chats = [...action.payload]
    },
    modalChat: (state, action) => {
      state.modalChatOpen = true
    },
    closeChat: (state, action) => {
      state.modalChatOpen = false
      state.chats = []
      state.room = null
    },
    cleanChats: (state, action) => {
      state.chats = []
    },
    cleanChatRooms: (state, action) => {
      state.chatRooms = []
    },
    setUsuarios: (state, action) => {
      state.usuarios = [...action.payload]
    }
  }
})

export const {
  setMsgTest,
  setChatRooms,
  setRoom,
  cleanRoom,
  setChats,
  modalChat,
  closeChat,
  cleanChats,
  cleanChatRooms,
  setUsuarios
} = sliceChat.actions

// THUNKS !!

export const handleSetMesssageTest = (msg) => {
  return (dispatch) => {
    dispatch(setMsgTest(msg))
  }
}

export const cargarChatRooms = (rooms) => {
  return (dispatch) => {
    dispatch(setChatRooms(rooms))
  }
}

export const seleccionaRoom = (room) => {
  return (dispatch) => {
    dispatch(setRoom(room))
  }
}

export const limpiarRoom = () => {
  return (dispatch) => {
    dispatch(cleanRoom())
  }
}

export const cargarChats = (chats) => {
  return (dispatch) => {
    dispatch(setChats(chats))
  }
}

export const nuevoChat = () => {
  return (dispatch) => {
    dispatch(modalChat());
  }
}

export const cerrarModalChat = () => {
  return (dispatch) => {
    dispatch(closeChat());
  }
}

export const limpiarChats = () => {
  return (dispatch) => {
    dispatch(cleanChats());
  }
}

export const limpiarChatRooms = () => {
  return (dispatch) => {
    dispatch(cleanChatRooms());
  }
}

export const cargarListaUsuariosChat = (usuarios) => {
  return (dispatch) => {
    dispatch(setUsuarios(usuarios));
  }
}