import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button, Chip, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { abrirModalAreaInvestigacion } from './sliceAdministrador';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router';
import { borrarAreaInvestigacion } from './API';

const botonAzul = {
    ml: 2,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
        backgroundColor: 'rgba(54, 138, 184, 1)',
    }
}

const botonVerde = {
    ml: 2, mr: 2,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(14, 181, 14, 1)',
    "&:hover": {
        backgroundColor: 'rgba(18, 219, 18, 1)',
    }
}

export const ToolBarAreas = (props) => {

    const { numSelected } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { seleccionadaAreaInvestigacion } = useSelector(state => state.administracion)

    const handleBorrar = () => {
        Swal.fire({
            title: `${'Desea eliminar: ' + `</br>` + seleccionadaAreaInvestigacion[0]?.nombreArea}`,
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cerrar',
            confirmButtonColor: 'rgba(54, 160, 184, 1)',
            cancelButtonColor: 'rgba(54, 160, 184, 1)',
            icon: 'question'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(borrarAreaInvestigacion(seleccionadaAreaInvestigacion[0]?.id))
            }
        })
    }

    const handleEditar = () => {
        dispatch(abrirModalAreaInvestigacion());
    }

    const handleNuevaArea = () => {
        dispatch(abrirModalAreaInvestigacion());
    }

    const handleLineas = () => {
        navigate('/lineas')
    }

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >

            {
                (seleccionadaAreaInvestigacion) ? (
                    <Typography
                        sx={{ flex: '1 1 80%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        <Chip
                            sx={{
                                color: 'rgba(255, 255, 255, 0.9)',
                                backgroundColor: 'rgba(54, 160, 184, 1)',

                            }}
                            size="medium"
                            label={seleccionadaAreaInvestigacion[0]?.nombreArea + " seleccionada"}
                        />

                    </Typography>
                ) : (
                    <Typography
                        sx={{ flex: '1 1 550%' }}
                        variant="h5"
                        id="tableTitle"
                        component="div"
                        align='center'
                    >
                        Lista de áreas
                    </Typography>
                )}

            {
                (seleccionadaAreaInvestigacion) ?
                    (
                        <Grid container spacing={1} justifyContent='right'>
                            <Button variant="contained" onClick={handleLineas}
                                sx={botonAzul}
                            >
                                Líneas
                            </Button>
                            <Button variant="contained" onClick={handleEditar}
                                sx={botonAzul}
                            >
                                Editar
                            </Button>
                            <Button variant="contained" onClick={handleBorrar}
                                sx={botonAzul}>
                                Eliminar
                            </Button>
                        </Grid>
                    ) : (

                        <Grid container spacing={1} justifyContent='right'>
                            <Button sx={botonVerde} onClick={handleNuevaArea}
                                startIcon={<AddCircleOutlineIcon />} >Nueva área
                            </Button>
                        </Grid>
                    )
            }
        </Toolbar>
    );
};