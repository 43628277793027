import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {
  handCompletarTareaCoordinador,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {MyGobackButton, MySaveButton} from 'src/components/MyCommonButtons'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import GLOBALS from "../App/globals";
import {f} from "../../commons";
import {P0102PayloadRO} from 'src/features/P01Solicitud/P0102PayloadRO'
import {MySwitch} from "../../components/MySwitch";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "./API";
import {PageOrientation} from "docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {P0102Expediente} from "./P0102Expediente";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const dispatch = useDispatch()
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const section = `InformeTecnico${perfilUsuario?.perfil?.organizacion?.nombreOrganizacion}`
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const esContrato = instanciaTarea?.tipoInstanciaProceso === 'Contrato'
  const solicitud = {...instanciaProceso.solicitud}
  let payload = JSON.parse(!!instanciaProceso?.solicitud?.payload?instanciaProceso?.solicitud?.payload:'{}')
  payload.solicitudId = solicitud.id
  const funcionarios = useSelector(state => state.app.funcionarios)
  const inputRef = useRef()
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha: today,
    identificador: solicitud.numeroSolicitud,
    serial: '',
    docx: '',
    pdf: '',
    docxLded: '',
    pdfLded: '',
    pdfSigned: '',
    pdfGenerado: false,
    pronunciamientoMaate:true,
    pronunciamientoSenadi:payload?.AccesoConocimiento?.accesoConocimiento,
    informeCompleto: false,
  }
  const [formValues, setFormValues] = useState({
    ...emptyPayload,
    ...payload[section],
  })
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const informeTecnico = !esContrato?'56015003-1':'56015003-2'
  const codigoAutorizador = 1139
  const codigoCoordinador = 164
  const autorizador = funcionarios.filter(it => it.idPerfil === codigoAutorizador)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === codigoCoordinador)[0]
  const [counter, setCounter] = useState(-1)
  useEffect(() => { setCounter(counter + 1) },[formValues])
  const toBPM = {
    "pronunciamientoMaate": formValues.pronunciamientoMaate,
    "pronunciamientoSenadi": formValues.pronunciamientoSenadi,
    "criterioTecnicoSenescyt": false,
    "informeCompletoSenescyt": formValues.informeCompleto,
    "ampliacionInformacionSenescyt": false
  }
  if(!!instanciaProceso.id && !!instanciaTarea.id && f.isValid(formValues)) {
    const [counter, setCounter] = useState(-1)  // updating counter
    useEffect(() => {
      setCounter(counter + 1)
    }, [formValues])
    const [myTab, setMytab] = useState('1');
    const handleChangeTab = (event, newTab) => {
      setMytab(newTab);
    };
    const getOnSave = (setCounter, fv=null) => {
      return () => {
        payload[section] = f.isValid(fv)?fv:formValues
        dispatch(handleSaveSolicitud(instanciaProceso?.id, payload, () => setCounter(0)))
      };
    }
    const subfolder = `informe-tecnico-${nombreOrganizacion}`
    const filename = `informe-tecnico-${formValues.identificador}-${nombreOrganizacion}`
    const filenameDOCX = `${filename}.docx`
    const filenameDOCXuploaded = `${filename}-upld.docx`
    const filenamePDFsigned = `${filename}-firmado.pdf`
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={`Informe Técnico---3`} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="Solicitud modificatoria" value="2" />
              <Tab label="Expediente modificatorio" value="3" />
              {(f.isValid(payload?.solicitudOriginal))?<Tab label="Solicitud original" value="4" />:false}
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Expediente original`} value="6"/> : null
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} >
              <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Solicitud solicitud={solicitud}/>
              <Grid item xs={6} sx={{pr:'1rem'}}>
                <Stack direction={'column'}>
                  <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
                  <MySubtitle subtitle={'Pronunciamiento MAATE'} />
                  </Grid>
                  <MySwitch id='pronunciamientoMaate'
                            label={'¿Se requiere pronunciamiento?'}
                            formValues={formValues}
                            canEdit={false}
                            handleChange={handleChange}/>
                  {f.isValidNotEmpty(payload?.DictamenTecnicoMaate?.pdf) ?
                    <Stack direction={'row'} justifyContent="space-between" alignItems='center' sx={{p:'0 1.4rem 0 0'}}>
                      <MyButtonBacan label={'INFORME'}
                                     onClick={() => {
                                       const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/informe-tecnico-Maate/${payload?.InformeTecnicoMaate?.pdfSigned}`
                                       fetch(url)
                                         .then((res) => { return res.blob(); })
                                         .then((data) => {
                                           const dataPdf = new Blob([data], { type: 'application/pdf' })
                                           const a = document.createElement("a")
                                           a.href = window.URL.createObjectURL(dataPdf)
                                           a.target="_blank"
                                           a.click()
                                         })
                                     }}
                                     icon={FileDownloadOutlinedIcon} />
                      <MyButtonBacan label={'DICTAMEN'}
                                     onClick={() => {
                                       const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/dictamen-tecnico-Maate/${payload?.DictamenTecnicoMaate?.pdf}`
                                       fetch(url)
                                         .then((res) => { return res.blob(); })
                                         .then((data) => {
                                           const dataPdf = new Blob([data], { type: 'application/pdf' })
                                           const a = document.createElement("a")
                                           a.href = window.URL.createObjectURL(dataPdf)
                                           a.target="_blank"
                                           a.click()
                                         })
                                     }}
                                     icon={FileDownloadOutlinedIcon} />
                    </Stack>:false
                  }
                </Stack>
              </Grid>

              <Grid item xs={6}  sx={{pl:'1rem'}}>
                <Stack direction={'column'}>
                  <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
                  <MySubtitle subtitle={'Pronunciamiento SENADI'} />
                  </Grid>
                  <MySwitch id='pronunciamientoSenadi'
                            label={'¿Se requiere pronunciamiento?'}
                            formValues={formValues}
                            canEdit={false}
                            handleChange={handleChange}/>
                  {f.isValidNotEmpty(payload?.DictamenTecnicoSenadi?.pdf) ?
                    <MyButtonBacan label={'DICTAMEN'}
                                   onClick={() => {
                                     const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/dictamen-tecnico-Senadi/${payload?.DictamenTecnicoSenadi?.pdf}`
                                     fetch(url)
                                       .then((res) => { return res.blob(); })
                                       .then((data) => {
                                         const dataPdf = new Blob([data], { type: 'application/pdf' })
                                         const a = document.createElement("a")
                                         a.href = window.URL.createObjectURL(dataPdf)
                                         a.target="_blank"
                                         a.click()
                                       })
                                   }}
                                   icon={FileDownloadOutlinedIcon} />:false
                  }
                </Stack>
              </Grid>
              {
                (formValues.pronunciamientoMaate || formValues.pronunciamientoSenadi) ?
                  <Grid item xs={12} sx={{p: '0 1rem 0 1rem'}}>
                    <Stack direction={'row'} justifyContent="flex-end" alignItems="center" spacing={2}>
                      <MySendButton onSend={() => {
                                      const metadata = JSON.stringify({
                                        "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                        ...toBPM
                                      })
                                      dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                    }}
                                    label={'Solicitar'}
                                    myTip={'Solicitar pronunciuamiento a MAATE y/o SENADI'}
                                    disabled={!((formValues.pronunciamientoMaate && !f.isValidNotEmpty(payload?.DictamenTecnicoMaate?.pdf)) ||
                                      (formValues.pronunciamientoSenadi && !f.isValidNotEmpty(payload?.DictamenTecnicoSenadi?.pdf)))} />
                    </Stack>
                  </Grid> : null
              }
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Informe'} />
              </Grid>
              <Grid item xs={4} >
              <MySwitch id='informeCompleto'
                        label={'¿Informe completo?'}
                        formValues={formValues}
                        canEdit={true}
                        handleChange={handleChange}/>
              </Grid>
              <Grid item xs={8} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 1rem 0 8rem'}}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = 'ITSYT'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave(setCounter, {...formValues, serial})()
                                            setFormValues({...formValues, serial})
                                            setCounter(0)
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',
                                          pdfSigned: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    formValues.docx === '' && formValues.serial !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar Informe'}
                                      width={'11rem'}
                                      onClick={() => {
                                        API.fetchPlantila(informeTecnico).then(taggeDoc => {
                                          const doc = MyTagged2Docx(taggeDoc?.contenidoPlantilla, nombreOrganizacion, P0102API.mapping({
                                            solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                            payload,
                                            autorizador,
                                            coordinador,
                                            perfilUsuario,
                                            nombreOrganizacion,
                                            section: payload[section]
                                          }), PageOrientation.PORTRAIT, instanciaProceso?.solicitud?.nombreProyecto)
                                          API.genDocxAnPdf({
                                            solicitudId: instanciaProceso?.solicitud?.id,
                                            document: doc,
                                            formValues,
                                            setFormValues,
                                            subfolder,
                                            filename: filenameDOCX,
                                            nombreOrganizacion,
                                          })
                                        })
                                      }}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => {
                                        API.fetchDownloadDOCX({
                                          solicitudId: instanciaProceso?.solicitud?.id,
                                          subfolder,
                                          filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                        })
                                      }}
                                      toolTip={'Descargar informe técnico en formato docx'}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })}
                                      toolTip={'Descargar informe técnico en formato pdf'}
                                      width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={() => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                              } else {
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                              }
                                            })
                                          }}
                                          toolTip={'Subir docx actualizado del informe técnico '}
                                          width={'5rem'} />
                    </ButtonGroup>
                  }
                  {
                    formValues.docx !== '' &&
                    <>
                      <MyFileUploadButton label={'PDF (FIRMADO)'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenamePDFsigned}
                                          afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                          toolTip={'Subir documento firmado en formato pdf'}
                                          width={'9rem'} />
                      <MyButtonBacan3 label={'FIRMAR...'}
                                      icon={FileDownloadIcon}
                                      onClick={()=> {
                                        console.log('::::: > ')
                                        // solicitudId: instanciaProceso?.solicitud?.id,
                                        // subfolder,
                                        // filename: filenamePDFsigned,
                                      }}
                                      toolTip={'firmar electronicamente el documento'}
                                      width={'9rem'} />
                      {
                        formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                       icon={FileDownloadIcon}
                                                                       onClick={() => API.fetchDownloadPDF({
                                                                         solicitudId: instanciaProceso?.solicitud?.id,
                                                                         subfolder,
                                                                         filename: filenamePDFsigned,
                                                                       })}
                                                                       toolTip={'Descarga informe técnico firmado'}
                                                                       width={'3rem'} />
                      }
                    </>
                  }
                </Stack>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={payload}/>
          </TabPanel>
          <TabPanel value="3">
            <P0102Expediente payload={payload}/>
          </TabPanel>
          {(f.isValid(payload?.solicitudOriginal)) ?
            <TabPanel value="4">
              <P0102PayloadRO payload={payload?.solicitudOriginal?.payloadSolicitud}/>
            </TabPanel> : false
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="6">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction={'row'}
                       spacing={1}
                       justifyContent="space-between"
                       alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyGobackButton onGoback={() => dispatch(handleClear())} />
                  <MySaveButton onSave={getOnSave(setCounter)
                                  // () => {
                                  //               payload[section]=formValues
                                  //               dispatch(handleSaveSolicitud(
                                  //                 instanciaProceso?.id,
                                  //                 payload,
                                  //                 () => setCounter(0)
                                  //               ))
                                  //             }
                                }
                                disabled={counter <= 0 } />
                  <MySendButton onSend={ () => {
                                  const metadata = JSON.stringify({
                                    "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                    ...toBPM
                                  })
                                  dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                label={'enviar'}
                                myTip={'Enviar Informe Técnico y continuar con proceso'}
                                disabled={counter > 0 || formValues.pdfSigned === ''} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}
