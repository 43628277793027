import React, {useRef, useState} from 'react'
import { DataGrid, gridClasses} from '@mui/x-data-grid'
import {Box, Divider, Stack} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import {MyButtonBacan2} from 'src/components/MyButtonBacan2'
import RobotoCondensedRegular from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf'
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";

const columns = (canEdit) => [
  {
    field: 'id',
    headerName: 'id',
    type: 'number',
    editable: false,
    hide: true
  },
  {
    field: 'nombreMedio',
    headerName: 'Medio de conservación y manejo ex situ ',
    width: 280,
    editable: canEdit,
  },
  {
    field: 'acronimo',
    headerName: 'Acrónimo',
    width: 180,
    editable: canEdit,
  },
  {
    field: 'nombreCurador',
    headerName: 'Curador/administrador',
    width: 240,
    editable: canEdit,
  },
  {
    field: 'telefono',
    headerName: 'Teléfono',
    width: 180,
    editable: canEdit,
    sortable: false,
  },
  {
    field: 'eMail',
    headerName: 'Correo electrónico',
    width: 240,
    editable: canEdit,
  },
  {
    field: 'repositorio',
    headerName: 'Repositorio de documentos ',
    width: 240,
    editable: canEdit,
  },
]

export const MyTableDepositoMuestrasWF15 = ({id, formValues, setFormValues, canEdit=false, addItem=null})  => {
  const inputRef = useRef()
  const rows = formValues[id]
  const patrocinadores = useSelector(state => state.app.instituciones)
  const [selectionModel, setSelectionModel] = useState([])
  const processRowUpdate = (newRow) => {
    const nId = newRow.id
    const nRow = {...newRow}
    const nRows = [...rows]
    const index = nRows.findIndex(it => it.id === nId)
    if(index >= 0) {
      nRows[index] = {...nRows[index], ...nRow}
      setFormValues({...formValues, [id]:[...nRows]})
    }
    return newRow
  }

  return (
    <Stack direction="column" spacing={1} sx={{mt:'1rem'}}>
      <Stack direction="row"
             spacing={1}
             justifyContent='space-between'
             alignItems='center'>
        <Typography sx={{mt:'1rem'}}>Depósito de las muestras</Typography>
        {canEdit ?
          <Stack direction="row" spacing={3}>
            <MyButtonBacan2 onClick={addItem} icon={AddIcon} label={'Agregar'} />
            {
              selectionModel.length > 0 ?
                <MyButtonBacan2 onClick={() => {
                                  const selectedIDs = new Set(selectionModel)
                                  const nRows = [...rows].filter(it => !selectedIDs.has(it.id))
                                  setFormValues({...formValues, [id]:[...nRows]})
                                }}
                                color0={'darkred'}
                                icon={DeleteIcon}
                                label={'Eliminar'} /> : null
            }
          </Stack> : null
        }
      </Stack>
      <Box sx={{pb: 0, width: '100%', }}>
        <DataGrid experimentalFeatures={{ newEditingApi: true }}
                  rows={rows}
                  getRowHeight={() => 'auto'}
                  columns={columns(canEdit, inputRef, patrocinadores)}
                  processRowUpdate={processRowUpdate}
                  autoHeight={true}
                  rowHeight={32}
                  pageSize={8}
                  rowsPerPageOptions={[8]}
                  selectionModel={selectionModel}
                  onSelectionModelChange={(selection) => {
                    const selectionSet = new Set(selectionModel);
                    const result = selection.filter((s) => !selectionSet.has(s))
                    setSelectionModel(result)
                  }}
                  headerHeight={40}
                  sx={{
                    borderRadius: 0,
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: "rgba(244,244,244,0.4)",
                      color: "#aeaeae",
                      fontSize: '0.8rem',
                      fontfamily: RobotoCondensedRegular,
                      fontweight: 'lighter',
                    },
                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                      '& .MuiDataGrid-row': {
                        backgroundColor: 'rgba(255, 255, 255, .7)',
                        fontSize: '0.8rem',
                        fontfamily: RobotoCondensedRegular,
                        fontweight: 'lighter',
                        color: "#888888",
                      },
                      '& .Mui-selected': {
                        color: "#000000",
                      },
                      [`& .${gridClasses.cell}`]: {
                        py: 1,
                      },
                    },
                  }} />
      </Box>
    </Stack>
  )
}
