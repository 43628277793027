import React from 'react'
import { Grid, Typography, } from '@mui/material'
import {dialog} from 'src/styles/styles'
import {MySubtitle} from 'src/components/MySubtitle'
import {MyCentroDocumentacion} from "../../../components/MyCentroDocumentacion";
import {f} from "../../../commons";
import RobotoCondensedRegular from "../../../styles/MyFonts/Roboto_Condensed/RobotoCondensed-Regular.ttf";

export const RecursosExSituRO = ({payload}) => {
  const section = 'RecursosExSitu'
  const recursos = payload[section]
  const canEdit = false
  const recursosMAATE = f.isValid(payload['InformeTecnicoMaate'])?payload['InformeTecnicoMaate']:recursos

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Centros de documentación'} />
      </Grid>
      {
        recursos.centrosDocumentacion?.map(cd => {
          return (
            <Grid key={cd.id} item xs={12}>
              <MyCentroDocumentacion id={cd.id}
                                     key={cd.id}
                                     canEdit={canEdit}
                                     formValues={recursosMAATE}
                                     mode={'autorizar-ver'}
                                     numeroSolicitud={payload.solicitudId} />
            </Grid>
          )
        })
      }
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Destino de los recursos'} />
      </Grid>
      <Grid item xs={12} >
        <Typography sx={{fontSize:'0.9rem', fontFamily:RobotoCondensedRegular}}>
          Instituciones en la que se desarrollará la fase de laboratorio
        </Typography>
        {recursos?.laboratorios?.map((it, idx) => <Typography key={idx} sx={{fontSize:'12px', m:'0.4rem'}}>{it}</Typography>)}
      </Grid>
    </Grid>
  )
}
