import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Tab, Typography} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import API from "src/features/App/API";
import {MyTextField} from "src/components/MyTextField";
import {MyAreaTextField} from "src/components/MyAreaTextField";
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from "src/features/App/sliceApp";
import {MySendButton} from 'src/components/MySendButton'
import {SectionTitle} from 'src/components/SectionTitle'
import {MyButtonBacan} from "../../components/MyButtonBacan";
import {MySubtitle} from "../../components/MySubtitle";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {P0102PayloadRO} from 'src/features/P01Solicitud/P0102PayloadRO'
import {MySwitch} from "../../components/MySwitch";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import {Stack} from "@mui/system";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {P0102Expediente} from "./P0102Expediente";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({id}) => {
  const section = 'Resolucion'
  const dispatch = useDispatch()
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const esContrato = instanciaTarea?.tipoInstanciaProceso === 'Contrato'
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const bandeja = useSelector(state => state.app.bandeja)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  payload.solicitudId = solicitud.id
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `resolucion-${nombreOrganizacion}`
  const filename = `resolucion-${solicitud.numeroSolicitud}-${nombreOrganizacion}`
  const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  const filenamePDFuploaded = `${filename}-upld.pdf`
  const filenamePDFsigned = `${filename}-firmado.pdf`
  const emptyPayload = {
    identificador:    solicitud.numeroSolicitud,
    fecha:            today,
    seAprueba:        false,
    serial:           payload['ProyectoResolucion']?.serial,
    asunto:           '',
    contenido2:       '',
    docx:             filenameDOCX,
    pdf:              filenamePDF,
    docxLded:         payload?.ProyectoResolucion?.docxLded,
    pdfLded:          payload?.ProyectoResolucion?.pdfLded,

    // firma electronica ---\\
    pdfSigned:       '',
    firmando:        false,
    firmaTareaId:    0,
    firmado:         false,
    // firma electronica ---//

  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada' && instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }
  const inputRef = useRef()
  const funcionarios = useSelector(state => state.app.funcionarios)
  const autorizador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const autoridadSenescyt = funcionarios.filter(it => it.idPerfil === 1189)[0]
  useEffect(() => {
    if(!formValues.seAprueba) {
      setFormValues({
        ...formValues,
        asunto: 'RESOLUCIÓN DE PROYECTO DE INVESTIGACIÓN NEGADA',
        contenido2: `Estimado ${payload.Solicitante.nombresCompletos},\n\n`
          + `Su solicitud Nro. ${solicitud.numeroSolicitud} para el desarrollo del proyecto de investigación titulado "${payload.Propuesta.nombre}", ha concluido su proceso de revisión, negándose la autorización.\n\n`
          + 'RESOLUCIÓN ACERCA DEL PROYECTO DE INVESTIGACIÓN APROBADA\n\n'
          // + 'Saludos cordiales,\n'
          + 'Sistema VUVIB.\n'
      })
    } else {
      setFormValues({
        ...formValues,
        asunto: 'RESOLUCIÓN DE PROYECTO DE INVESTIGACIÓN APROBADA',
        contenido2: `Estimado/a ${payload.Solicitante.nombresCompletos},\n\n`
          + `Su solictud Nro. ${solicitud.numeroSolicitud} para el desarrollo del proyecto de investigación titulado "${payload.Propuesta.nombre}", ha concluido su proceso de revisión de manera favorable.\n\n`
          // + 'Saludos cordiales,\n'
          + 'Sistema VUVIB.\n'
      })
    }
  }, [formValues['seAprueba']])

  const labelTab4 = ['55000002_Activity_EmitirResolucion'].includes(id)?'Solicitud':'Solicitud Modificatoria'
  const labelTab2 = ['55000002_Activity_EmitirResolucion'].includes(id)?'Expediente':'Expediente Modificatoria'

  const getOnSave = (setCounter, fv=null) => {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(instanciaProceso?.id, payload, () => setCounter(0)))
    };
  }

  // firma electronica ---\\
  useEffect(() => {
    if(formValues.firmando && !formValues.firmado) {
      API.testFirmarPdf(instanciaTarea.id).then((res) => {
        if(res?.resultado) {
          setFormValues({...formValues, firmando: false, firmado: true})
        }
      })
    }
  },[])
  useEffect(() => {
    if(formValues.firmando) {
      getOnSave(setCounter)()
    }
  },[formValues.firmando])
  // firma electronica ---//

  // console.log(':: firma :: ', JSON.stringify({id:instanciaTarea.id, firmaTareaId:formValues.firmaTareaId, pdfSigned: formValues.pdfSigned, firmando: formValues.firmando, firmado: formValues.firmado}))

  if(!!instanciaProceso.id && !!instanciaTarea.id) {
    return <>
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Emitir Resolución'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label={labelTab4} value="2" />
              <Tab label={labelTab2} value="3" />
              {(f.isValid(payload?.solicitudOriginal))?<Tab label="Solicitud original" value="4" />:false}
              {
                f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
                  <Tab label={`Expediente original`} value="5"/> : null
              }
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={accordeonBox.container2}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Solicitud solicitud={solicitud}/>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Resolución'} />
              </Grid>
              <Grid item xs={6} style={{padding:'0 24px 0 32px'}}>
                <MyReadOnlyTextField id={'identificador'}
                                     label={'Identificador'}
                                     value={formValues['identificador']}
                                     icon={<AssignmentIndOutlinedIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
              </Grid>
              <Grid item xs={6} style={{padding:'0 24px 0 32px'}}>
                <MyReadOnlyTextField id={'fecha'}
                                     label={'Fecha'}
                                     value={formValues['fecha']}
                                     icon={<CalendarMonthIcon sx={{fontSize: '14px', color:'silver', mr:'0.8rem'}} />} />
              </Grid>
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 0 0 8rem'}}>
                  <ButtonGroup variant={'contained'}>
                    {
                      formValues.pdfSifned !== '' &&
                      <ButtonGroup variant={'contained'}>
                        <MyButtonBacan3 label={''}
                                        width={'3rem'}
                                        onClick={() => {
                                          setFormValues({
                                            ...formValues,
                                            // firma -- \\
                                            firmaTareaId: 0,
                                            pdfSigned:    '',
                                            firmando:     false,
                                            firmado:      false,
                                            // firma -- //
                                          })
                                        }}
                                        icon={RestartAltIcon}/>
                      </ButtonGroup>
                    }
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                    })}
                                    width={'5rem'} />
                  </ButtonGroup>
                  <ButtonGroup variant={'contained'}>
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'PDF (firmado)'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenamePDFsigned}
                                        // afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}
                                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned, firmado: true, firmando: false})}
                                        toolTip={'Subir documento firmado en formato pdf'}
                                        width={'9rem'}
                                        disabled={formValues.firmando || formValues.firmado}/>
                    <MyButtonBacan3 label={'FIRMAR'}
                                    icon={FileDownloadIcon}
                                    onClick={()=> {
                                      API.firmarPdf(instanciaTarea.id, subfolder, filenamePDF).then((res)=> {
                                        if(res?.length > 0) {
                                          const pdfSigned = res[0].rutaDocumento?.split('/')?.at(3)
                                          const firmaTareaId = res[0].firmaTareaId
                                          setFormValues({...formValues, firmando:true, firmado:false, pdfSigned, firmaTareaId})
                                        }
                                      })
                                    }}
                                    disabled={formValues.firmado}
                                    toolTip={'firmar electronicamente el documento'}
                                    width={'9rem'} />
                    {
                      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}
                                                                     icon={FileDownloadIcon}
                                                                     onClick={() => API.fetchDownloadPDF({
                                                                       solicitudId: instanciaProceso?.solicitud?.id,
                                                                       subfolder,
                                                                       filename: filenamePDFsigned,
                                                                     })}
                                                                     toolTip={'Descargar el documento firmado'}
                                                                     width={'3rem'}/>
                    }
                  </ButtonGroup>
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Notificacion'} />
              </Grid>
              <Grid item xs={12}>
                <MySwitch id='seAprueba'
                          label={'¿Solicitud Aprobada?'}
                          formValues={formValues}
                          canEdit={canEdit}
                          fullWidth={false}
                          handleChange={handleChange} />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P0102PayloadRO payload={payload}/>
          </TabPanel>
          <TabPanel value="3">
            <P0102Expediente payload={payload}/>
          </TabPanel>
          {(f.isValid(payload?.solicitudOriginal)) ?
            <TabPanel value="4">
              <P0102PayloadRO payload={payload?.solicitudOriginal?.payloadSolicitud}/>
            </TabPanel> : false
          }
          {
            f.isValid(payload?.solicitudOriginal?.payloadSolicitud) ?
              <TabPanel value="5">
                <P0102Expediente payload={payload.solicitudOriginal.payloadSolicitud}/>
              </TabPanel> : null
          }
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  <MyButtonBacan label={'Regresar'}
                                 icon={ArrowBackIcon}
                                 onClick={() => dispatch(handleClear())} />
                  <MyButtonBacan label={'Guardar'}
                                 icon={SaveOutlinedIcon}
                                 onClick={() => {
                                   payload[section]=formValues
                                   dispatch(handleSaveSolicitud(
                                     instanciaProceso?.id,
                                     payload,
                                     () => setCounter(0)
                                   ))
                                 }}
                                 disabled={counter === 0 || formValues.firmando} />
                  <MySendButton onSend={ () => {
                                  const metadata = JSON.stringify({
                                    "resolucionProcedente": formValues.seAprueba,
                                    "permiso": instanciaProceso.tipoInstanciaProceso !== "Contrato",
                                    "contrato": instanciaProceso.tipoInstanciaProceso === "Contrato",
                                    "adjunto": formValues.pdfSigned,
                                    "carpeta": subfolder,
                                    "asunto": formValues.asunto,
                                    "detalle": formValues.contenido2,
                                  })
                                  dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                label='Enviar'
                                disabled={counter > 0 || formValues.pdfSigned === '' || formValues.pdfSigned === '' || !formValues.firmado} />
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  }
  else {
    return null
  }
}

const mapping = (vars) => {
  const { solicitudId, payload, autorizador, coordinador, perfilUsuario } = vars
  const autoridadSenescyt = vars.autoridadSenescyt
  const recInsitu = payload?.RecursosInSitu?.recursos?.map(it => it.scientificname)?.join(', ') + ', ' + payload?.RecursosExSitu?.centrosDocumentacion?.recursos?.map(it => it.scientificname)?.join(', ')
  const recExsitu = payload?.RecursosExSitu?.centrosDocumentacion?.map(it => it.recursos?.map(it2 => it2.scientificname)?.join((', ')))?.join(', ')
  let recs = []
  if(f.isValid(recInsitu)) recs.push(recInsitu)
  if(f.isValid(recExsitu)) recs.push(recExsitu)
  let acc = []
  if(payload?.AccesoConocimiento?.aConocimientoTradicional) acc.push('Acceso a conocimiento tradicional.')
  if(payload?.AccesoConocimiento?.clpi !== "") acc.push('CLPI: ' + payload?.AccesoConocimiento?.clpi)
  if(payload?.AccesoConocimiento?.contratoAccesoConocimiento !== "") acc.push('Contrato de acceso a conocimiento: '+ payload?.AccesoConocimiento?.contratoAccesoConocimiento)
  if(payload?.AccesoConocimiento?.ambitoComunitario) acc.push('Ambito comunitario.')
  if(payload?.AccesoConocimiento?.derivadosOSimilares) acc.push('Derivados o similares.')
  if(payload?.AccesoConocimiento?.etnozoologicos) acc.push('Etnozoologicos.')
  if(payload?.AccesoConocimiento?.medicinaTradicional) acc.push('Medicina tradeicional.')
  if(payload?.AccesoConocimiento?.practicasAncestrales) acc.push('Prácticas ancestrales.')
  if(payload?.AccesoConocimiento?.recursosBiologico) acc.push('Recursos biológicos.')
  if(payload?.AccesoConocimiento?.valoresReligiososCultutrales) acc.push('Valores religiosos culturales.')
  const result =  {
    '<<sistema.identificadorInformeTecnicoMaate>>':solicitudId,
    '<<autorizacion.vigencia>>': payload?.Propuesta?.plazo,
    '<<solicitante.identificador>>': solicitudId,
    '<<solicitante.idAcreditacion>>': payload?.Solicitante?.registro,
    '<<institucionApoyo.razonSocial>>': payload?.Propuesta?.apoyo,
    '<<solicitud.areaInvestigacion>>': payload?.Propuesta?.areaInvestigacion,
    '<<recursos.listaRecursos>>': recs.join(', '),
    '<<ubicacion.listaProvincias>>': payload?.RecursosInSitu?.provincias?.join(', '),
    '<<ubicacion.listaAreasProtegidas>>': payload?.RecursosInSitu?.areasProtegidas?.join(', '),
    '<<ubicacion.listaBosquesProtectores>>': payload?.RecursosInSitu?.bosquesProtectores?.join(', '),
    '<<solicitud.accesoConocimientoTradicional>>': acc?.join(' '),
    '<<plazoProyecto>>': payload?.Propuesta?.plazo + ' meses',
    '<<sistema.identificadorDictamenTecnicoMaate>>':solicitudId,
    '<<sistema.identificadorIDictamenTecnicoSenadi>>':solicitudId,
    '<<sistema.identificadorInformeTecnicoSenescyt>>':solicitudId,
    '<<sistema.identificadorResolucionSenescyt>>':solicitudId,
    '<<sistema.fechaTexto>>':format(new Date(), 'dd MMMM yyyy', {locale: es}),
    '<<sistema.fecha>>':format(new Date(), 'dd MMMM yyyy', {locale: es}),
    '<<solicitud.identificador>>':solicitudId,
    '<<solicitante.nombre>>':payload?.Solicitante?.nombresCompletos,
    '<<solicitud.nombreProyecto>>':payload?.Propuesta?.nombre,
    '<<solicitud.plazoProyecto>>':payload?.Propuesta?.plazo,
    '<<patrocinador>>':payload?.Propuesta?.apoyo,
    '<<sistema.nombreAutorizadorPrincipalSenescyt>>':autorizador?.nombreUsuario ?? '',
    '<<sistema.rolAutorizadorPrincipalSenescyt>>':autorizador?.nombrePerfil ?? '',
    '<<sistema.cargoAutorizadorPrincipalSenescyt>>':autorizador?.descripcionPerfil ?? '',
    '<<sistema.nombreAutorizadorSectorialMaate>>':coordinador?.nombreUsuario ?? '',
    '<<sistema.rolAutorizadorSectorialMaate>>':coordinador?.nombrePerfil ?? '',
    '<<usuario.cargoAutorizadorSectorialMaate>>':coordinador?.descripcionPerfil ?? '',
    '<<sistema.nombreCoordinadorCasosSenescyt>>':coordinador?.nombreUsuario ?? '',
    '<<sistema.rolCoordinadorCasosSenescyt>>':coordinador?.nombrePerfil ?? '',
    '<<sistema.cargoCoordinadorCasosSenescyt>>':coordinador?.descripcionPerfil  ?? '',
    '<<sistema.telefono>>':coordinador?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<sistema.correoElectronico>>':coordinador?.correoUsuario ?? '',
    '<<usuario.nombre>>':perfilUsuario?.usuario?.nombreUsuario ?? '',
    '<<usuario.rol>>':perfilUsuario?.perfil?.nombrePerfil ?? '',
    '<<usuario.cargo>>':perfilUsuario?.perfil?.descripcionPerfil ?? '',
    '<<usuario.telefono>>':perfilUsuario?.usuario?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<usuario.correoElectronico>>':perfilUsuario?.usuario?.correoUsuario ?? '',
    '<<sistema.nombreAutoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '' ?? '',
    '<<sistema.rolAutoridadSenescyt>>': autoridadSenescyt?.nombrePerfil ?? '',
    '<<sistema.cargoAutoridadSenescyt>>': autoridadSenescyt?.descripcionPerfil ?? '',
    '<<sistema.autoridadSenescyt>>':autoridadSenescyt?.nombreUsuari ?? '',
  }
  return result
}
