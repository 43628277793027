import GLOBALS from './globals'
import {f} from "../../commons"
import {Packer} from "docx"
const API = { sliceName: 'app' }
const api = API

API.perfilInvestigadorId = 163

API.esInvestigador = (usuario) => !!usuario && !!usuario.id && usuario.idOrganizacion === 510001

API.fetchOrgs = async() => {
  const url    = `${GLOBALS.mainUrl}/api/v1/usuarios/getorgs`
  const res    = await fetch(url)
  return res.json()
}

API.fetchProvincias = async() => {
  const url    = `${GLOBALS.mainUrl}/api/v1/provincia/all`
  const res    = await fetch(url)
  return res.json()
}

API.fetchFuncionarios = async() => {
  const url    = `${GLOBALS.mainUrl}/v1/api/perfilusuario/funcionarios`
  const res    = await fetch(url)
  return res.json()
}

API.fetchBosques = async(provincias) => {
  const provs = provincias.join(',').split(' ').join('+')
  const url    = `${GLOBALS.mainUrl}/api/v1/bosqueprotector/provincias?provincias=${provs}`
  const res    = await fetch(url,{method:'GET'})
  return res.json()
}

API.fetchCentrosDocumentacion = async() => {
  const url    = `${GLOBALS.mainUrl}/api/v1/catalogo/centros_documentacion`
  const res    = await fetch(url,{method:'GET'})
  return res.json()
}

API.fetchAreasProtegidas = async(provincias) => {
  const provs = provincias.join(',').split(' ').join('+')
  const url    = `${GLOBALS.mainUrl}/api/v1/areaprotegida/provincias?provincias=${provs}`
  const res    = await fetch(url)
  return res.json()
}

API.fetchUsers = async(idOrg) => {
  const url    = `${GLOBALS.mainUrl}/api/v1/usuarios/getemps?idOrganizacion=${idOrg}`
  const res    = await fetch(url)
  return res.json()
}

API.testUsuario = async(cedulaOcorreo) => {
  const url    = `${GLOBALS.mainUrl}/api/v1/usuarios/getemp?cedulaOcorreo=${cedulaOcorreo}`
  const res    = await fetch(url)
  return res.json()
}

API.testPass = async(empId, passwd) => {
  const url    = `${GLOBALS.mainUrl}/api/v1/usuarios/tstpsw?empId=${empId}&passwd=${passwd}`
  const res    = await fetch(url)
  return res.json()
}

API.perfilesUsuario = async(empId) => {
  const url    = `${GLOBALS.mainUrl}/v1/api/perfilusuario/usuarioperfiles?idusuario=${empId}`
  const res    = await fetch(url)
  return res.json()
}

API.createPerfilUsuario = async(usuarioId, idPerfil) => {
  const url    = `${GLOBALS.mainUrl}/v1/api/perfilusuario/set?idusuario=${usuarioId}&idperfil=${idPerfil}`
  const res    = await fetch(url, {method: 'POST'})
  return res.json()
}

API.fetchUsuarioSenescyt = async(usuarioCorreo) => {
  const url    = `${GLOBALS.investigadoresWS}/email/${usuarioCorreo}`
  const res    = await fetch(url)
  return res.json()
}

API.fetchUsuarioSenescytXREGISTRO = async(registro) => {
  // http://68.183.19.195:8585/api/v1/vuv/investigador/registro/REG-INV-15-00095
  const url    = `${GLOBALS.investigadoresWS}/registro/${registro}`
  const res    = await fetch(url)
  return res.json()
}

API.crearInstanciaProceso = async(idProceso, idPerfilUsuario, codigoPerfilUsuario, tipoInstanciaProceso=null, numeroSolicitudPadre=null, solicitudId=null) => {
  let url    = f.isValid(tipoInstanciaProceso)?
    `${GLOBALS.mainUrl}/api/procesos/iniciar?idProceso=${idProceso}&idPerfilUsuario=${idPerfilUsuario}&tipoInstanciaProceso=${tipoInstanciaProceso}`:
    `${GLOBALS.mainUrl}/api/procesos/iniciar?idProceso=${idProceso}&idPerfilUsuario=${idPerfilUsuario}&tipoInstanciaProceso`
  url = f.isValid(solicitudId)?`${url}&idSolicitud=${solicitudId}`:`${url}&idSolicitud=0`
  if(f.isValid(numeroSolicitudPadre)) {
    url = `${url}&numeroSolicitudPadre=${numeroSolicitudPadre}`
  } else {
    url = `${url}&numeroSolicitudPadre`
  }
  const res    = await fetch(url,{method: 'POST'})
  return res.json()
}

API.buscarRuc = async(ruc) => {
  const url    = `${GLOBALS.mainUrl}/api/v1/ruc/${ruc}`
  console.log('url: ', url)
  const res    = await fetch(url)
  return res.json()
}

API.buscarCLPI = async(clpi) => {
  const url    = `${GLOBALS.mainUrl}/v1/api/registro/tipo?codigoRegistro=${clpi}&tipo=CLPI`
  const res    = await fetch(url)
  return res.json()
}

API.buscarCONTRATO = async(clpi) => {
  const url    = `${GLOBALS.mainUrl}/v1/api/registro/tipo?codigoRegistro=${clpi}&tipo=C`
  const res    = await fetch(url)
  return res.json()
}

API.fetchInstanciasProceso = async(idPerfilUsuario) => {
  const url    = `${GLOBALS.mainUrl}/api/procesos/instancias?idPerfilUsuario=${idPerfilUsuario}`
  const res    = await fetch(url,{method: 'GET'})
  return res.json()
}

API.fetchInstanciaProceso = async(idProceso) => {
  const url    = `${GLOBALS.mainUrl}/api/procesos/instancia/${idProceso}`
  const res    = await fetch(url,{method: 'GET'})
  return res.json()
}

API.saveSolicitud = async(idInstanciaProceso, payload) => { // TODO:
  const url    = `${GLOBALS.mainUrl}/v1/api/solicitud/set?idinsproceso=${idInstanciaProceso}`
  const res    = await fetch(url,{method: 'POST', body: payload})
  return res.json()
}

API.fetchTareasPerfilUsuario = async(idPerfilUsuario) => {
  const url    = `${GLOBALS.mainUrl}/api/procesos/tareas?idPerfilUsuario=${idPerfilUsuario}`
  const res    = await fetch(url,{method: 'GET'})
  return res.json()
}

API.fetchTareasPerfilAdministrador = async(idPerfilUsuario) => {
  const url    = `${GLOBALS.mainUrl}/api/procesos/bandeja?idPerfilUsuario=${idPerfilUsuario}`
  const res    = await fetch(url,{method: 'GET'})
  return res.json()
}

API.fetchInstanciaTarea = async(idInstanciaTarea) => {
  const url    = `${GLOBALS.mainUrl}/api/procesos/tarea/${idInstanciaTarea}`
  const res    = await fetch(url,{method: 'GET'})
  return res.json()
}

API.asignarTareaPerfilUsuario = async(idInstanciaTarea,idPerfilUsuario) => {
  const url    = `${GLOBALS.mainUrl}/api/procesos/asignar?idInstanciaTarea=${idInstanciaTarea}&idPerfilUsuario=${idPerfilUsuario}`
  const res    = await fetch(url,{method: 'POST'})
  return res.json()
}

API.completarTarea = async(idInstanciaProceso, idTarea, idPerfilUsuario, metadataTarea) => {
  const url    = `${GLOBALS.mainUrl}/api/procesos/completar?idInstanciaProceso=${idInstanciaProceso}&idTarea=${idTarea}&idPerfilUsuario=${idPerfilUsuario}`
  const res    = await fetch(url,{method: 'POST', body: metadataTarea})
  return res.json()
}

API.secuenciaGet = async(tipo) => {
  const url    = `${GLOBALS.mainUrl}/secuencia/get/${tipo}`
  const res    = await fetch(url,{method: 'GET'})
  return res.json()
}

API.secuenciaSet = async(tipo) => {
  const url    = `${GLOBALS.mainUrl}/secuencia/set/${tipo}`
  const res    = await fetch(url,{method: 'PUT'})
  return res.json()
}

API.fetchPerfilUsuarioXPerfil = async(idPerfil) => {
  const url    = `${GLOBALS.mainUrl}/v1/api/perfilusuario/byperfil?idPerfil=${idPerfil}`
  const res    = await fetch(url,{method: 'GET'})
  return res.json()
}

API.fetchPermisoRelacionado = async(numeroPermiso) => {
  const url    = `${GLOBALS.mainUrl}/v1/api/solicitud/getpermiso/${numeroPermiso}`
  const res    = await fetch(url,{method: 'GET'})
  return res.json()
}

API.getRoute = (tarea) => {
  const route = {
    'Process_WF0102_Usuario' : '/solicitud',
    'Process_WF0102_VUV'     : '/solicitud',
    'Process_WF0405_Usuario' : '/solicitud',
    'Process_WF0405_VUV'     : '/solicitud',
    'Process_WF07'           : '/pedidoMovilizacion',
    'Process_WF10_Usuario'   : '/validarATM',
    'Process_WF10_VUV'       : '/validarATM',
    'Process_WF08_Usuario'   : '/permisoImportacion',
    'Process_WF08_VUV'       : '/permisoImportacion',
    'Process_WF11_Usuario'   : '/permisoExportacion',
    'Process_WF11_VUV'       : '/permisoExportacion',
    'Process_WF13_VUV'       : '/dictamen',
    'Process_WF15_Usuario'   : '/seguimiento',
    'Process_WF15_VUV'       : '/seguimiento',
    'Process_WF09_Usuario'   : '/holotipos',
    'Process_WF09_VUV'       : '/holotipos',
    'Process_WF16_Usuario'   : '/cerrarsolicitud',
    'Process_WF16_VUV'       : '/cerrarsolicitud',
    'Process_WF17_VUV'       : '/evaluar',
    'Process_WF03_Usuario'   : '/finesComerciales',
    'Process_WF03_VUV'       : '/finesComerciales',
    'Process_WF12'           : '/retornoHolotipos',
    'Process_WF14_Usuario'   : '/resultadoExportaciones',
    'Process_WF14_VUV'       : '/resultadoExportaciones',
  }[tarea?.instanciaProcesoProcesoCodigoProceso]
  console.log('Route: ',tarea?.instanciaProcesoProcesoCodigoProceso, route)
  return route
}

API.handleChange = (e, bandeja, setValues, values) => {
  if(bandeja === 'entrada') {
    const name = e.target.id
    const value = (e.target.type === 'checkbox') ? e.target.checked : e.target.value
    setValues({...values, [name]: value})
  }
}

API.handleChange2 = (e, canEdit, setValues, values) => {
  if(canEdit) {
    const name = e.target.id
    const value = (e.target.type === 'checkbox') ? e.target.checked : e.target.value
    setValues({...values, [name]: value})
  }
}

API.handleChange2 = (e, canEdit, setFormValues, formValues) => {
  if(canEdit) {
    const name = e.target.id
    const value = (e.target.type === 'checkbox') ? e.target.checked : e.target.value
    setFormValues({...formValues, [name]: value})
  }
}

API.handleChange2numeric = (e, canEdit, setFormValues, formValues) => {
  if(canEdit && !isNaN(e.target.value)) {
    const name = e.target.id
    const value = e.target.value
    setFormValues({...formValues, [name]: value})
  }
}

API.fetchPerfilesUsuariosByPerfil = async(perfil_id) => {
  const url    = `${GLOBALS.mainUrl}/v1/api/perfilusuario/byperfil?idPerfil=${perfil_id}`
  const res    = await fetch(url,{method: 'GET'})
  return res.json()
}

API.fetchLaboratoriosAcreaditados = async() => {
  const url    = `${GLOBALS.mainUrl}/api/v1/catalogo/laboratorio_acreditado`
  const res    = await fetch(url)
  return res.json()
}

API.fetchBosquesProtectores = async() => {
  const url    = `${GLOBALS.mainUrl}/api/v1/catalogo/bosque_protector`
  const res    = await fetch(url,{method: 'GET'})
  return res.json()
}

API.fetchTaxonomia = async(scientificname='gallina', kingdom='Animal', taxonRankBusqueda='Clase') => {
  const payload = JSON.stringify({scientificname,kingdom,taxonRankBusqueda})
  const url     = `${GLOBALS.mainUrl}/v1/api/taxonomia/get`
  const res     = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body:payload
    })
  return res.json()
}

API.fetchEspecies = async(props) => {
  const {scientificname, kingdom, taxonRankBusqueda} = props
  const payload = JSON.stringify({scientificname,kingdom,taxonRankBusqueda})
  const url     = `${GLOBALS.mainUrl}/v1/api/taxonomia/getespecies`
  const res     = await fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body:payload
  })
  return res.json()
}

API.fetchPlantila = async(plantilla) => {
  const url     = `${GLOBALS.mainUrl}/plantilla/${plantilla}`
  const res     = await fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8'
    },
  })
  return res.json()
}

API.fetchGenerarPDF = async(idSolicitud, subfolder,nombreArchivo,contenido) => {
  const url     = `${GLOBALS.mainUrl}/v1/api/solicitud/pdf?idSolicitud=${idSolicitud}&subfolder=${subfolder}&nombreArchivo=${nombreArchivo}`
  const res     = await fetch(url, {
    method: 'POST',
    body: contenido
  })
  return res.json()
}

API.fetchGenerarDocx = async(idSolicitud, subfolder,nombreArchivo,blob) => {
  const url     = `${GLOBALS.mainUrl}/v1/api/solicitud/docx?idSolicitud=${idSolicitud}&subfolder=${subfolder}&nombreArchivo=${nombreArchivo}`
  const res     = await fetch(url, {
    method: 'POST',
    body: blob
  })
  return res.json()
}

API.firmarPdf = async(idInstanciaTarea, subfolder,pdfFile) => {
  const url     = `${GLOBALS.mainUrl}/v1/detallefirma/set?idInstanciaTarea=${Number(idInstanciaTarea)}&carpeta=${subfolder}&archivo=${pdfFile}`
  const res     = await fetch(url, {method: 'GET',})
  return res.json()
}

// https://testvuv.tech/ws/v1/detallefirma/completo?idInstanciaTarea=56015008

API.testFirmarPdf = async(idInstanciaTarea) => {
  const url     = `${GLOBALS.mainUrl}/v1/detallefirma/completo?idInstanciaTarea=${Number(idInstanciaTarea)}`
  const res     = await fetch(url, {method: 'GET',})
  return res.json()
}

API.fetchDocx2PDF = async(idSolicitud, subfolder,nombreArchivo) => {
  const url     = `${GLOBALS.mainUrl}/v1/api/solicitud/docx2pdf?idSolicitud=${idSolicitud}&subfolder=${subfolder}&nombreArchivo=${nombreArchivo}`
  console.log(`${GLOBALS.mainUrl}/v1/api/solicitud/docx2pdf?idSolicitud=${idSolicitud}&subfolder=${subfolder}&nombreArchivo=${nombreArchivo}`)
  const res     = await fetch(url, {method: 'GET',})
  return res.json()
}

API.genDocxAnPdf = ({solicitudId, document, formValues, setFormValues, subfolder, filename}) => {
  Packer.toBlob(document).then(blob => {
      setFormValues({...formValues, docx: '', pdf: '',})
      const reader = new FileReader();
      let base64data;
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        base64data = reader.result
        api.fetchGenerarDocx(solicitudId, subfolder, filename, base64data).then(result => {
          if(f.isValid(result.rutaDocumento)) {
            const arr = result.rutaDocumento.split('/')
            setFormValues({...formValues, docxGenerado: true, docx: arr[arr.length-1]})
            api.fetchDocx2PDF(solicitudId, subfolder, filename).then(result2 => {
              if(f.isValid(result2.rutaDocumento)) {
                const arr2 = result2.rutaDocumento.split('/')
                setFormValues({...formValues, pdf: arr2[arr2.length-1], docx: arr[arr.length-1]})
              } else {
                setFormValues({...formValues, pdf: ''})
              }
            })
          } else {
            setFormValues({...formValues, docxGenerado: false, docx: ''})
          }
        })
      }
      // saveAs(blob, "informe-tecnico.docx");
    }
  )
}

API.fetchDownloadPDF = ({solicitudId, subfolder, filename}) => {
  if(filename !== '') {
    const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${solicitudId}/${subfolder}/${filename}`
    fetch(url)
      .then((res) => { return res.blob(); })
      .then((data) => {
        const dataPdf = new Blob([data], { type: 'application/pdf' })
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(dataPdf)
        a.download = filename
        a.target="_blank"
        a.click()
      })
  } else {
    alert('no se ha generado el PDF')
  }
}

API.fetchDownloadDOCX = ({solicitudId, subfolder, filename}) => {
  if(filename !== '') {
    const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${solicitudId}/${subfolder}/${filename}`
    fetch(url)
      .then((res) => { return res.blob(); })
      .then((data) => {
        const dataDOCX = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(dataDOCX)
        a.download = filename
        a.target="_blank"
        a.click()
      })
  } else {
    alert('no se ha generado el PDF')
  }
}

API.fetchPdf = async(idSolicitud, subfolder,nombreArchivo) => {
  const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${idSolicitud}/${subfolder}/${nombreArchivo}`
  const res = await fetch(url, {method: 'GET',})
  return res.json()
}

// API.fetchSolicitudesCompletadas = async(idPerfilusuario) => {
//   const url = `${GLOBALS.mainUrl}/api/procesos/solicitudes?estadoSolicitud=Completado&idPerfilusuario=${idPerfilusuario}`
//   const res = await fetch(url, {method: 'GET',})
//   return res.json()
// }

API.fetchSolicitudesAprobadas = async(idPerfilusuario) => {
  const url = `${GLOBALS.mainUrl}/api/procesos/solicitudes?estadoSolicitud=Aprobada&idPerfilusuario=${idPerfilusuario}`
  const res = await fetch(url, {method: 'GET',})
  return res.json()
}

API.fetchSolicitudesFinalizadas = async(idPerfilusuario) => {
  const url = `${GLOBALS.mainUrl}/api/procesos/solicitudes?estadoSolicitud=Finalizada&idPerfilusuario=${idPerfilusuario}`
  const res = await fetch(url, {method: 'GET',})
  return res.json()
}

API.fetchExpediente = async(solicitudId) => {
  const url = `${GLOBALS.mainUrl}/documentos/listado?idSolicitud=${solicitudId}`
  const res = await fetch(url, {method: 'GET',})
  return res.json()
}
// https://testvuv.tech/ws/documentos/listado?idSolicitud=30585

API.fetchSolicitudesCerradas = async(idPerfilusuario) => {
  const url = `${GLOBALS.mainUrl}/api/procesos/solicitudes?estadoSolicitud=Cerrada&idPerfilusuario=${idPerfilusuario}`
  const res = await fetch(url, {method: 'GET',})
  return res.json()
}

API.fetchSolicitudesCerradas = async(idPerfilusuario) => {
  const url = `${GLOBALS.mainUrl}/api/procesos/solicitudes?estadoSolicitud=Completado&idPerfilusuario=${idPerfilusuario}`
  const res = await fetch(url, {method: 'GET',})
  return res.json()
}

// API.fetchSolicitudesCompletado = async(idPerfilusuario) => {
//   const url = `${GLOBALS.mainUrl}/api/procesos/solicitudes?estadoSolicitud=Completado&idPerfilusuario=${idPerfilusuario}`
//   const res = await fetch(url, {method: 'GET',})
//   return res.json()
// }
// https://testvuv.tech/ws/api/procesos/solicitudes?estadoSolicitud=Rechazada&idPerfilusuario=4423

API.fetchSolicitudesDenegadas = async(idPerfilusuario) => {
  const url = `${GLOBALS.mainUrl}/api/procesos/solicitudes?estadoSolicitud=Denegada&idPerfilusuario=${idPerfilusuario}`
  const res = await fetch(url, {method: 'GET',})
  return res.json()
}

API.fetchSolicitudesRechazadas = async(idPerfilusuario) => {
  const url = `${GLOBALS.mainUrl}/api/procesos/solicitudes?estadoSolicitud=Rechazada&idPerfilusuario=${idPerfilusuario}`
  const res = await fetch(url, {method: 'GET',})
  return res.json()
}

API.fetchSolicitudesImprocedente = async(idPerfilusuario) => {
  const url = `${GLOBALS.mainUrl}/api/procesos/solicitudes?estadoSolicitud=Improcedente&idPerfilusuario=${idPerfilusuario}`
  const res = await fetch(url, {method: 'GET',})
  return res.json()
}

API.fetchSolicitudByNumero = async(numero) => {
  const url = `${GLOBALS.mainUrl}/v1/api/solicitud/pornumero?numeroSolicitud=${numero}`
  const res = await fetch(url, {method: 'GET',})
  return res.json()
}

API.setPayload = async(idProceso, body) => {
  const url = `${GLOBALS.mainUrl}/v1/api/solicitud/set?idinsproceso=${idProceso}`
  const res = await fetch(url, {method: 'POST',body:body})
  return res.json()
}

API.fetchRecursos = async(idPerfilusuario, idProceso) => {
  const url = `${GLOBALS.mainUrl}/api/procesos/recursos?estadoSolicitud=Aprobada&idPerfilusuario=${idPerfilusuario}&idProceso=${idProceso}`
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}

API.fetchPatrocinadoresAll = async() => {
  const url = `${GLOBALS.mainUrl}/api/v1/patrocinador/all`
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}

API.fetchTaxonomiaR1 = async(scientificname, taxonrank) => {
  const url = `${GLOBALS.mainUrl}/api/taxonomy/get?scientificname=${scientificname}&taxonrank=${taxonrank}`
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}

API.fetchTaxonomiaR2 = async(scientificname, order) => {
  const url = `${GLOBALS.mainUrl}/api/taxonomy/orden?nombre=${scientificname}&orden=${order}`
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}


API.fetchIes = async() => {
  const url = `${GLOBALS.mainUrl}/api/v1/ies/all`
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}

API.fetchIpi = async() => {
  const url = `${GLOBALS.mainUrl}/api/v1/ipi/all`
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}

API.fetchIts = async() => {
  const url = `${GLOBALS.mainUrl}/api/v1/its/all`
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}

API.fetchCCLPI = async(tipo) => {
  const url = `${GLOBALS.mainUrl}/v1/api/registro/list?tipo=${tipo}`
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}

API.fetchSolitudById = async (solicitudId) => {
  const url = `${GLOBALS.mainUrl}/v1/api/solicitud/get?idSolicitud=${solicitudId}`
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}

API.fetchSetPayload = async(solicitudId, payload) => {
  const url = `${GLOBALS.mainUrl}/v1/api/solicitud/actualiza?idSolicitud=${solicitudId}`
  const res    = await fetch(url,{method: 'POST', body: (typeof payload === 'string')?payload:JSON.stringify(payload)})
  return res.json()
}

API.fetchSolicitudByNumero = async(numero) => {
  const url = `${GLOBALS.mainUrl}/v1/api/solicitud/pornumero?numeroSolicitud=${numero}`
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}

API.fetchSuspenderSolicitud = async(idsolicitud, bvalue) => {
  const url = `${GLOBALS.mainUrl}/v1/api/solicitud/suspendida?idSolicitud=${idsolicitud}&valor=${bvalue}`
  console.log(url)
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}

API.allResources = (sa, mode='IN-EX-SITU') => {
  let allExSituRes = []
  if(['IN-EX-SITU','EX-SITU'].includes(mode)) {
    sa.InformeTecnicoMaate?.centrosDocumentacion?.forEach(it => {
      const data0 = {
        id: 'EXSITU-' + it.id,
        centroDocumentacion: 'EXSITU' + it.centroDocumentacion,
      }
      it.recursos.forEach(it2 => {
        const data = {
          mid:                 JSON.stringify({cdocid: data0.id, recursoid: it2.id,}),
          origen:              'EX-SITU',
          scientificname:      it2.scientificname,
          holotipo:            false,
          cites:               it2.cites,
          tipo:                '',
          metodo:              '',
          tratamiento:         '',
          cantidadAutorizada:  it2.cantidadAutorizada,
          cantidadSolicitada:   0,
          saldoDRM:            f.isValid(it2.saldoDRM)?it2.saldoDRM:it2.cantidadAutorizada,
          saldorATM:           f.isValid(it2.saldorATM)?it2.saldorATM:it2.cantidadAutorizada,
          taxonrank:           it2.taxonrank,
          clasificacion:       it2.clasificacion,
          listaRoja:           '',
          submuestra:          '',
          otraSubmuestra:      '',
          descripcion:         '',
          centroDocumentacion: it.centroDocumentacion,
        }
        allExSituRes.push(data)
      })
    })
  }



  let allInSituRes = []
  if(['IN-EX-SITU','IN-SITU'].includes(mode)) {
    sa.InformeTecnicoMaate?.recursosInSitu?.forEach(it => {
      const data0 = {
        mid: 'INSITU-' + it.id,
        scientificname: it.scientificname,
      }
      const muestras = sa.InformeTecnicoMaate?.muestras?.filter(it2 => it2.taxonid === it.id)
      muestras.forEach(it2 => {
        const data = {
          mid:                JSON.stringify({recursoid:data0.mid, muestraid:it2.id}),
          origen:             'IN-SITU',
          scientificname:     data0.scientificname,
          holotipo:           false,
          cites:              it2.cites,
          tipo:               it2.tipo,
          metodo:             '',
          tratamiento:        '',
          cantidadAutorizada: it2.cantidadAutorizada,
          cantidadSolicitada: 0,
          cantidadDeclarada:  it2.cantidadDeclarada,
          saldoDRM:           it2.saldoDRM,
          saldorATM:          it2.saldorATM,
          taxonrank:          it.taxonrank,
          clasificacion:      it.clasificacion,
          listaRoja:          it2.redList,
          submuestra:         it2.submuestra,
          otraSubmuestra:     it2.loteotro,
          descripcion:        it2.descripcion,
          centroDocumentacion: '',
        }
        allInSituRes.push(data)
      })
    })
  }
  let res = [...allInSituRes, ...allExSituRes]
  return res.map((it,idx) => ({...it, id: idx}))
}

API.allCentros = (sa) => {
  const centrosDocumentacion = sa.InformeTecnicoMaate?.centrosDocumentacion?.map(it => it.centroDocumentacion)
  return f.isValid(centrosDocumentacion)?centrosDocumentacion:[]
}

API.gradosAcademicos = ['Estudiante', 'Cuarto nivel', 'Pasante', 'Tercer nivel', 'Tesista',]

API.copiarDirServer = async(idOrigen, dirOrigen, idDestino) => {
  const url = `${GLOBALS.mainUrl}/documentos/copiar?idSolicitudOrigen=${idOrigen}&origen=${dirOrigen}&idSolicitudCopia=${idDestino}&destino=${dirOrigen}`
  const res = await fetch(url, {method: 'GET'})
  return res.json()
}

API.dpa = [
  {tipo: 'provincia', codigo: '01', nombre:'AZUAY', sup: ''},
  {tipo: 'provincia', codigo: '02', nombre:'BOLÍVAR', sup: ''},
  {tipo: 'provincia', codigo: '03', nombre:'CAÑAR', sup: ''},
  {tipo: 'provincia', codigo: '04', nombre:'CARCHI', sup: ''},
  {tipo: 'provincia', codigo: '05', nombre:'COTOPAXI', sup: ''},
  {tipo: 'provincia', codigo: '06', nombre:'CHIMBORAZO', sup: ''},
  {tipo: 'provincia', codigo: '07', nombre:'EL ORO', sup: ''},
  {tipo: 'provincia', codigo: '08', nombre:'ESMERALDAS', sup: ''},
  {tipo: 'provincia', codigo: '09', nombre:'GUAYAS', sup: ''},
  {tipo: 'provincia', codigo: '10', nombre:'IMBABURA', sup: ''},
  {tipo: 'provincia', codigo: '11', nombre:'LOJA', sup: ''},
  {tipo: 'provincia', codigo: '12', nombre:'LOS RÍOS', sup: ''},
  {tipo: 'provincia', codigo: '13', nombre:'MANABÍ', sup: ''},
  {tipo: 'provincia', codigo: '14', nombre:'MORONA SANTIAGO', sup: ''},
  {tipo: 'provincia', codigo: '15', nombre:'NAPO', sup: ''},
  {tipo: 'provincia', codigo: '16', nombre:'PASTAZA', sup: ''},
  {tipo: 'provincia', codigo: '17', nombre:'PICHINCHA', sup: ''},
  {tipo: 'provincia', codigo: '18', nombre:'TUNGURAHUA', sup: ''},
  {tipo: 'provincia', codigo: '19', nombre:'ZAMORA CHINCHIPE', sup: ''},
  {tipo: 'provincia', codigo: '20', nombre:'GALÁPAGOS', sup: ''},
  {tipo: 'provincia', codigo: '21', nombre:'SUCUMBÍOS', sup: ''},
  {tipo: 'provincia', codigo: '22', nombre:'ORELLANA', sup: ''},
  {tipo: 'provincia', codigo: '23', nombre:'SANTO DOMINGO DE LOS TSÁCHILAS', sup: ''},
  {tipo: 'provincia', codigo: '24', nombre:'SANTA ELENA', sup: ''},
  {tipo: 'canton', codigo: '0101', nombre:'CUENCA', sup: '01'},
  {tipo: 'canton', codigo: '0102', nombre:'GIRÓN', sup: '01'},
  {tipo: 'canton', codigo: '0103', nombre:'GUALACEO', sup: '01'},
  {tipo: 'canton', codigo: '0104', nombre:'NABÓN', sup: '01'},
  {tipo: 'canton', codigo: '0105', nombre:'PAUTE', sup: '01'},
  {tipo: 'canton', codigo: '0106', nombre:'PUCARÁ', sup: '01'},
  {tipo: 'canton', codigo: '0107', nombre:'SAN FERNANDO', sup: '01'},
  {tipo: 'canton', codigo: '0108', nombre:'SANTA ISABEL', sup: '01'},
  {tipo: 'canton', codigo: '0109', nombre:'SÍGSIG', sup: '01'},
  {tipo: 'canton', codigo: '0110', nombre:'OÑA', sup: '01'},
  {tipo: 'canton', codigo: '0111', nombre:'CHORDELEG', sup: '01'},
  {tipo: 'canton', codigo: '0112', nombre:'EL PAN', sup: '01'},
  {tipo: 'canton', codigo: '0113', nombre:'SEVILLA DE ORO', sup: '01'},
  {tipo: 'canton', codigo: '0114', nombre:'GUACHAPALA', sup: '01'},
  {tipo: 'canton', codigo: '0115', nombre:'CAMILO PONCE ENRÍQUEZ', sup: '01'},
  {tipo: 'canton', codigo: '0201', nombre:'GUARANDA', sup: '02'},
  {tipo: 'canton', codigo: '0202', nombre:'CHILLANES', sup: '02'},
  {tipo: 'canton', codigo: '0203', nombre:'CHIMBO', sup: '02'},
  {tipo: 'canton', codigo: '0204', nombre:'ECHEANDÍA', sup: '02'},
  {tipo: 'canton', codigo: '0205', nombre:'SAN MIGUEL', sup: '02'},
  {tipo: 'canton', codigo: '0206', nombre:'CALUMA', sup: '02'},
  {tipo: 'canton', codigo: '0207', nombre:'LAS NAVES', sup: '02'},
  {tipo: 'canton', codigo: '0301', nombre:'AZOGUES', sup: '03'},
  {tipo: 'canton', codigo: '0302', nombre:'BIBLIÁN', sup: '03'},
  {tipo: 'canton', codigo: '0303', nombre:'CAÑAR', sup: '03'},
  {tipo: 'canton', codigo: '0304', nombre:'LA TRONCAL', sup: '03'},
  {tipo: 'canton', codigo: '0305', nombre:'EL TAMBO', sup: '03'},
  {tipo: 'canton', codigo: '0306', nombre:'DÉLEG', sup: '03'},
  {tipo: 'canton', codigo: '0307', nombre:'SUSCAL', sup: '03'},
  {tipo: 'canton', codigo: '0401', nombre:'TULCÁN', sup: '04'},
  {tipo: 'canton', codigo: '0402', nombre:'BOLÍVAR', sup: '04'},
  {tipo: 'canton', codigo: '0403', nombre:'ESPEJO', sup: '04'},
  {tipo: 'canton', codigo: '0404', nombre:'MIRA', sup: '04'},
  {tipo: 'canton', codigo: '0405', nombre:'MONTÚFAR', sup: '04'},
  {tipo: 'canton', codigo: '0406', nombre:'SAN PEDRO DE HUACA', sup: '04'},
  {tipo: 'canton', codigo: '0501', nombre:'LATACUNGA', sup: '05'},
  {tipo: 'canton', codigo: '0502', nombre:'LA MANÁ', sup: '05'},
  {tipo: 'canton', codigo: '0503', nombre:'PANGUA', sup: '05'},
  {tipo: 'canton', codigo: '0504', nombre:'PUJILÍ', sup: '05'},
  {tipo: 'canton', codigo: '0505', nombre:'SALCEDO', sup: '05'},
  {tipo: 'canton', codigo: '0506', nombre:'SAQUISILÍ', sup: '05'},
  {tipo: 'canton', codigo: '0507', nombre:'SIGCHOS', sup: '05'},
  {tipo: 'canton', codigo: '0601', nombre:'RIOBAMBA', sup: '06'},
  {tipo: 'canton', codigo: '0602', nombre:'ALAUSÍ', sup: '06'},
  {tipo: 'canton', codigo: '0603', nombre:'COLTA', sup: '06'},
  {tipo: 'canton', codigo: '0604', nombre:'CHAMBO', sup: '06'},
  {tipo: 'canton', codigo: '0605', nombre:'CHUNCHI', sup: '06'},
  {tipo: 'canton', codigo: '0606', nombre:'GUAMOTE', sup: '06'},
  {tipo: 'canton', codigo: '0607', nombre:'GUANO', sup: '06'},
  {tipo: 'canton', codigo: '0608', nombre:'PALLATANGA', sup: '06'},
  {tipo: 'canton', codigo: '0609', nombre:'PENIPE', sup: '06'},
  {tipo: 'canton', codigo: '0610', nombre:'CUMANDÁ', sup: '06'},
  {tipo: 'canton', codigo: '0701', nombre:'MACHALA', sup: '07'},
  {tipo: 'canton', codigo: '0702', nombre:'ARENILLAS', sup: '07'},
  {tipo: 'canton', codigo: '0703', nombre:'ATAHUALPA', sup: '07'},
  {tipo: 'canton', codigo: '0704', nombre:'BALSAS', sup: '07'},
  {tipo: 'canton', codigo: '0705', nombre:'CHILLA', sup: '07'},
  {tipo: 'canton', codigo: '0706', nombre:'EL GUABO', sup: '07'},
  {tipo: 'canton', codigo: '0707', nombre:'HUAQUILLAS', sup: '07'},
  {tipo: 'canton', codigo: '0708', nombre:'MARCABELÍ', sup: '07'},
  {tipo: 'canton', codigo: '0709', nombre:'PASAJE', sup: '07'},
  {tipo: 'canton', codigo: '0710', nombre:'PIÑAS', sup: '07'},
  {tipo: 'canton', codigo: '0711', nombre:'PORTOVELO', sup: '07'},
  {tipo: 'canton', codigo: '0712', nombre:'SANTA ROSA', sup: '07'},
  {tipo: 'canton', codigo: '0713', nombre:'ZARUMA', sup: '07'},
  {tipo: 'canton', codigo: '0714', nombre:'LAS LAJAS', sup: '07'},
  {tipo: 'canton', codigo: '0801', nombre:'ESMERALDAS', sup: '08'},
  {tipo: 'canton', codigo: '0802', nombre:'ELOY ALFARO', sup: '08'},
  {tipo: 'canton', codigo: '0803', nombre:'MUISNE', sup: '08'},
  {tipo: 'canton', codigo: '0804', nombre:'QUININDÉ', sup: '08'},
  {tipo: 'canton', codigo: '0805', nombre:'SAN LORENZO', sup: '08'},
  {tipo: 'canton', codigo: '0806', nombre:'ATACAMES', sup: '08'},
  {tipo: 'canton', codigo: '0807', nombre:'RIOVERDE', sup: '08'},
  {tipo: 'canton', codigo: '0901', nombre:'GUAYAQUIL', sup: '09'},
  {tipo: 'canton', codigo: '0902', nombre:'ALFREDO BAQUERIZO MORENO (JUJÁN)', sup: '09'},
  {tipo: 'canton', codigo: '0903', nombre:'BALAO', sup: '09'},
  {tipo: 'canton', codigo: '0904', nombre:'BALZAR', sup: '09'},
  {tipo: 'canton', codigo: '0905', nombre:'COLIMES', sup: '09'},
  {tipo: 'canton', codigo: '0906', nombre:'DAULE', sup: '09'},
  {tipo: 'canton', codigo: '0907', nombre:'DURÁN', sup: '09'},
  {tipo: 'canton', codigo: '0908', nombre:'EL EMPALME', sup: '09'},
  {tipo: 'canton', codigo: '0909', nombre:'EL TRIUNFO', sup: '09'},
  {tipo: 'canton', codigo: '0910', nombre:'MILAGRO', sup: '09'},
  {tipo: 'canton', codigo: '0911', nombre:'NARANJAL', sup: '09'},
  {tipo: 'canton', codigo: '0912', nombre:'NARANJITO', sup: '09'},
  {tipo: 'canton', codigo: '0913', nombre:'PALESTINA', sup: '09'},
  {tipo: 'canton', codigo: '0914', nombre:'PEDRO CARBO', sup: '09'},
  {tipo: 'canton', codigo: '0916', nombre:'SAMBORONDÓN', sup: '09'},
  {tipo: 'canton', codigo: '0918', nombre:'SANTA LUCÍA', sup: '09'},
  {tipo: 'canton', codigo: '0919', nombre:'SALITRE', sup: '09'},
  {tipo: 'canton', codigo: '0920', nombre:'SAN JACINTO DE YAGUACHI', sup: '09'},
  {tipo: 'canton', codigo: '0921', nombre:'PLAYAS', sup: '09'},
  {tipo: 'canton', codigo: '0922', nombre:'SIMÓN BOLÍVAR', sup: '09'},
  {tipo: 'canton', codigo: '0923', nombre:'CORONEL MARCELINO MARIDUEÑA', sup: '09'},
  {tipo: 'canton', codigo: '0924', nombre:'LOMAS DE SARGENTILLO', sup: '09'},
  {tipo: 'canton', codigo: '0925', nombre:'NOBOL', sup: '09'},
  {tipo: 'canton', codigo: '0927', nombre:'GENERAL ANTONIO ELIZALDE', sup: '09'},
  {tipo: 'canton', codigo: '0928', nombre:'ISIDRO AYORA', sup: '09'},
  {tipo: 'canton', codigo: '1001', nombre:'IBARRA', sup: '10'},
  {tipo: 'canton', codigo: '1002', nombre:'ANTONIO ANTE', sup: '10'},
  {tipo: 'canton', codigo: '1003', nombre:'COTACACHI', sup: '10'},
  {tipo: 'canton', codigo: '1004', nombre:'OTAVALO', sup: '10'},
  {tipo: 'canton', codigo: '1005', nombre:'PIMAMPIRO', sup: '10'},
  {tipo: 'canton', codigo: '1006', nombre:'SAN MIGUEL DE URCUQUÍ', sup: '10'},
  {tipo: 'canton', codigo: '1101', nombre:'LOJA', sup: '11'},
  {tipo: 'canton', codigo: '1102', nombre:'CALVAS', sup: '11'},
  {tipo: 'canton', codigo: '1103', nombre:'CATAMAYO', sup: '11'},
  {tipo: 'canton', codigo: '1104', nombre:'CELICA', sup: '11'},
  {tipo: 'canton', codigo: '1105', nombre:'CHAGUARPAMBA', sup: '11'},
  {tipo: 'canton', codigo: '1106', nombre:'ESPÍNDOLA', sup: '11'},
  {tipo: 'canton', codigo: '1107', nombre:'GONZANAMÁ', sup: '11'},
  {tipo: 'canton', codigo: '1108', nombre:'MACARÁ', sup: '11'},
  {tipo: 'canton', codigo: '1109', nombre:'PALTAS', sup: '11'},
  {tipo: 'canton', codigo: '1110', nombre:'PUYANGO', sup: '11'},
  {tipo: 'canton', codigo: '1111', nombre:'SARAGURO', sup: '11'},
  {tipo: 'canton', codigo: '1112', nombre:'SOZORANGA', sup: '11'},
  {tipo: 'canton', codigo: '1113', nombre:'ZAPOTILLO', sup: '11'},
  {tipo: 'canton', codigo: '1114', nombre:'PINDAL', sup: '11'},
  {tipo: 'canton', codigo: '1115', nombre:'QUILANGA', sup: '11'},
  {tipo: 'canton', codigo: '1116', nombre:'OLMEDO', sup: '11'},
  {tipo: 'canton', codigo: '1201', nombre:'BABAHOYO', sup: '12'},
  {tipo: 'canton', codigo: '1202', nombre:'BABA', sup: '12'},
  {tipo: 'canton', codigo: '1203', nombre:'MONTALVO', sup: '12'},
  {tipo: 'canton', codigo: '1204', nombre:'PUEBLOVIEJO', sup: '12'},
  {tipo: 'canton', codigo: '1205', nombre:'QUEVEDO', sup: '12'},
  {tipo: 'canton', codigo: '1206', nombre:'URDANETA', sup: '12'},
  {tipo: 'canton', codigo: '1207', nombre:'VENTANAS', sup: '12'},
  {tipo: 'canton', codigo: '1208', nombre:'VINCES', sup: '12'},
  {tipo: 'canton', codigo: '1209', nombre:'PALENQUE', sup: '12'},
  {tipo: 'canton', codigo: '1210', nombre:'BUENA FE', sup: '12'},
  {tipo: 'canton', codigo: '1211', nombre:'VALENCIA', sup: '12'},
  {tipo: 'canton', codigo: '1212', nombre:'MOCACHE', sup: '12'},
  {tipo: 'canton', codigo: '1213', nombre:'QUINSALOMA', sup: '12'},
  {tipo: 'canton', codigo: '1301', nombre:'PORTOVIEJO', sup: '13'},
  {tipo: 'canton', codigo: '1302', nombre:'BOLÍVAR', sup: '13'},
  {tipo: 'canton', codigo: '1303', nombre:'CHONE', sup: '13'},
  {tipo: 'canton', codigo: '1304', nombre:'EL CARMEN', sup: '13'},
  {tipo: 'canton', codigo: '1305', nombre:'FLAVIO ALFARO', sup: '13'},
  {tipo: 'canton', codigo: '1306', nombre:'JIPIJAPA', sup: '13'},
  {tipo: 'canton', codigo: '1307', nombre:'JUNÍN', sup: '13'},
  {tipo: 'canton', codigo: '1308', nombre:'MANTA', sup: '13'},
  {tipo: 'canton', codigo: '1309', nombre:'MONTECRISTI', sup: '13'},
  {tipo: 'canton', codigo: '1310', nombre:'PAJÁN', sup: '13'},
  {tipo: 'canton', codigo: '1311', nombre:'PICHINCHA', sup: '13'},
  {tipo: 'canton', codigo: '1312', nombre:'ROCAFUERTE', sup: '13'},
  {tipo: 'canton', codigo: '1313', nombre:'SANTA ANA', sup: '13'},
  {tipo: 'canton', codigo: '1314', nombre:'SUCRE', sup: '13'},
  {tipo: 'canton', codigo: '1315', nombre:'TOSAGUA', sup: '13'},
  {tipo: 'canton', codigo: '1316', nombre:'24 DE MAYO', sup: '13'},
  {tipo: 'canton', codigo: '1317', nombre:'PEDERNALES', sup: '13'},
  {tipo: 'canton', codigo: '1318', nombre:'OLMEDO', sup: '13'},
  {tipo: 'canton', codigo: '1319', nombre:'PUERTO LÓPEZ', sup: '13'},
  {tipo: 'canton', codigo: '1320', nombre:'JAMA', sup: '13'},
  {tipo: 'canton', codigo: '1321', nombre:'JARAMIJÓ', sup: '13'},
  {tipo: 'canton', codigo: '1322', nombre:'SAN VICENTE', sup: '13'},
  {tipo: 'canton', codigo: '1401', nombre:'MORONA', sup: '14'},
  {tipo: 'canton', codigo: '1402', nombre:'GUALAQUIZA', sup: '14'},
  {tipo: 'canton', codigo: '1403', nombre:'LIMÓN INDANZA', sup: '14'},
  {tipo: 'canton', codigo: '1404', nombre:'PALORA', sup: '14'},
  {tipo: 'canton', codigo: '1405', nombre:'SANTIAGO', sup: '14'},
  {tipo: 'canton', codigo: '1406', nombre:'SUCÚA', sup: '14'},
  {tipo: 'canton', codigo: '1407', nombre:'HUAMBOYA', sup: '14'},
  {tipo: 'canton', codigo: '1408', nombre:'SAN JUAN BOSCO', sup: '14'},
  {tipo: 'canton', codigo: '1409', nombre:'TAISHA', sup: '14'},
  {tipo: 'canton', codigo: '1410', nombre:'LOGROÑO', sup: '14'},
  {tipo: 'canton', codigo: '1411', nombre:'PABLO SEXTO', sup: '14'},
  {tipo: 'canton', codigo: '1412', nombre:'TIWINTZA', sup: '14'},
  {tipo: 'canton', codigo: '1501', nombre:'TENA', sup: '15'},
  {tipo: 'canton', codigo: '1503', nombre:'ARCHIDONA', sup: '15'},
  {tipo: 'canton', codigo: '1504', nombre:'EL CHACO', sup: '15'},
  {tipo: 'canton', codigo: '1507', nombre:'QUIJOS', sup: '15'},
  {tipo: 'canton', codigo: '1509', nombre:'CARLOS JULIO AROSEMENA TOLA', sup: '15'},
  {tipo: 'canton', codigo: '1601', nombre:'PASTAZA', sup: '16'},
  {tipo: 'canton', codigo: '1602', nombre:'MERA', sup: '16'},
  {tipo: 'canton', codigo: '1603', nombre:'SANTA CLARA', sup: '16'},
  {tipo: 'canton', codigo: '1604', nombre:'ARAJUNO', sup: '16'},
  {tipo: 'canton', codigo: '1701', nombre:'DISTRITO METROPOLITANO DE QUITO', sup: '17'},
  {tipo: 'canton', codigo: '1702', nombre:'CAYAMBE', sup: '17'},
  {tipo: 'canton', codigo: '1703', nombre:'MEJÍA', sup: '17'},
  {tipo: 'canton', codigo: '1704', nombre:'PEDRO MONCAYO', sup: '17'},
  {tipo: 'canton', codigo: '1705', nombre:'RUMIÑAHUI', sup: '17'},
  {tipo: 'canton', codigo: '1707', nombre:'SAN MIGUEL DE LOS BANCOS', sup: '17'},
  {tipo: 'canton', codigo: '1708', nombre:'PEDRO VICENTE MALDONADO', sup: '17'},
  {tipo: 'canton', codigo: '1709', nombre:'PUERTO QUITO', sup: '17'},
  {tipo: 'canton', codigo: '1801', nombre:'AMBATO', sup: '18'},
  {tipo: 'canton', codigo: '1802', nombre:'BAÑOS DE AGUA SANTA', sup: '18'},
  {tipo: 'canton', codigo: '1803', nombre:'CEVALLOS', sup: '18'},
  {tipo: 'canton', codigo: '1804', nombre:'MOCHA', sup: '18'},
  {tipo: 'canton', codigo: '1805', nombre:'PATATE', sup: '18'},
  {tipo: 'canton', codigo: '1806', nombre:'QUERO', sup: '18'},
  {tipo: 'canton', codigo: '1807', nombre:'SAN PEDRO DE PELILEO', sup: '18'},
  {tipo: 'canton', codigo: '1808', nombre:'SANTIAGO DE PÍLLARO', sup: '18'},
  {tipo: 'canton', codigo: '1809', nombre:'TISALEO', sup: '18'},
  {tipo: 'canton', codigo: '1901', nombre:'ZAMORA', sup: '19'},
  {tipo: 'canton', codigo: '1902', nombre:'CHINCHIPE', sup: '19'},
  {tipo: 'canton', codigo: '1903', nombre:'NANGARITZA', sup: '19'},
  {tipo: 'canton', codigo: '1904', nombre:'YACUAMBI', sup: '19'},
  {tipo: 'canton', codigo: '1905', nombre:'YANTZAZA', sup: '19'},
  {tipo: 'canton', codigo: '1906', nombre:'EL PANGUI', sup: '19'},
  {tipo: 'canton', codigo: '1907', nombre:'CENTINELA DEL CÓNDOR', sup: '19'},
  {tipo: 'canton', codigo: '1908', nombre:'PALANDA', sup: '19'},
  {tipo: 'canton', codigo: '1909', nombre:'PAQUISHA', sup: '19'},
  {tipo: 'canton', codigo: '2001', nombre:'SAN CRISTÓBAL', sup: '20'},
  {tipo: 'canton', codigo: '2002', nombre:'ISABELA', sup: '20'},
  {tipo: 'canton', codigo: '2003', nombre:'SANTA CRUZ', sup: '20'},
  {tipo: 'canton', codigo: '2101', nombre:'LAGO AGRIO', sup: '21'},
  {tipo: 'canton', codigo: '2102', nombre:'GONZALO PIZARRO', sup: '21'},
  {tipo: 'canton', codigo: '2103', nombre:'PUTUMAYO', sup: '21'},
  {tipo: 'canton', codigo: '2104', nombre:'SHUSHUFINDI', sup: '21'},
  {tipo: 'canton', codigo: '2105', nombre:'SUCUMBÍOS', sup: '21'},
  {tipo: 'canton', codigo: '2106', nombre:'CASCALES', sup: '21'},
  {tipo: 'canton', codigo: '2107', nombre:'CUYABENO', sup: '21'},
  {tipo: 'canton', codigo: '2201', nombre:'FRANCISCO DE ORELLANA', sup: '22'},
  {tipo: 'canton', codigo: '2202', nombre:'AGUARICO', sup: '22'},
  {tipo: 'canton', codigo: '2203', nombre:'LA JOYA DE LOS SACHAS', sup: '22'},
  {tipo: 'canton', codigo: '2204', nombre:'LORETO', sup: '22'},
  {tipo: 'canton', codigo: '2301', nombre:'SANTO DOMINGO', sup: '23'},
  {tipo: 'canton', codigo: '2302', nombre:'LA CONCORDIA', sup: '23'},
  {tipo: 'canton', codigo: '2401', nombre:'SANTA ELENA', sup: '24'},
  {tipo: 'canton', codigo: '2402', nombre:'LA LIBERTAD', sup: '24'},
  {tipo: 'canton', codigo: '2403', nombre:'SALINAS', sup: '24'},
  {tipo: 'parroquia', codigo: '010101', nombre:'BELLAVISTA', sup: '0101'},
  {tipo: 'parroquia', codigo: '010102', nombre:'CAÑARIBAMBA', sup: '0101'},
  {tipo: 'parroquia', codigo: '010103', nombre:'EL BATÁN', sup: '0101'},
  {tipo: 'parroquia', codigo: '010104', nombre:'EL SAGRARIO', sup: '0101'},
  {tipo: 'parroquia', codigo: '010105', nombre:'EL VECINO', sup: '0101'},
  {tipo: 'parroquia', codigo: '010106', nombre:'GIL RAMÍREZ DÁVALOS', sup: '0101'},
  {tipo: 'parroquia', codigo: '010107', nombre:'HUAYNACÁPAC', sup: '0101'},
  {tipo: 'parroquia', codigo: '010108', nombre:'MACHÁNGARA', sup: '0101'},
  {tipo: 'parroquia', codigo: '010109', nombre:'MONAY', sup: '0101'},
  {tipo: 'parroquia', codigo: '010110', nombre:'SAN BLAS', sup: '0101'},
  {tipo: 'parroquia', codigo: '010111', nombre:'SAN SEBASTIÁN', sup: '0101'},
  {tipo: 'parroquia', codigo: '010112', nombre:'SUCRE', sup: '0101'},
  {tipo: 'parroquia', codigo: '010113', nombre:'TOTORACOCHA ', sup: '0101'},
  {tipo: 'parroquia', codigo: '010114', nombre:'YANUNCAY', sup: '0101'},
  {tipo: 'parroquia', codigo: '010115', nombre:'HERMANO MIGUEL', sup: '0101'},
  {tipo: 'parroquia', codigo: '010151', nombre:'BAÑOS', sup: '0101'},
  {tipo: 'parroquia', codigo: '010152', nombre:'CUMBE', sup: '0101'},
  {tipo: 'parroquia', codigo: '010153', nombre:'CHAUCHA', sup: '0101'},
  {tipo: 'parroquia', codigo: '010154', nombre:'CHECA', sup: '0101'},
  {tipo: 'parroquia', codigo: '010155', nombre:'CHIQUINTAD', sup: '0101'},
  {tipo: 'parroquia', codigo: '010156', nombre:'LLACAO', sup: '0101'},
  {tipo: 'parroquia', codigo: '010157', nombre:'MOLLETURO', sup: '0101'},
  {tipo: 'parroquia', codigo: '010158', nombre:'NULTI', sup: '0101'},
  {tipo: 'parroquia', codigo: '010159', nombre:'OCTAVIO CORDERO PALACIOS', sup: '0101'},
  {tipo: 'parroquia', codigo: '010160', nombre:'PACCHA', sup: '0101'},
  {tipo: 'parroquia', codigo: '010161', nombre:'QUINGEO', sup: '0101'},
  {tipo: 'parroquia', codigo: '010162', nombre:'RICAURTE', sup: '0101'},
  {tipo: 'parroquia', codigo: '010163', nombre:'SAN JOAQUÍN', sup: '0101'},
  {tipo: 'parroquia', codigo: '010164', nombre:'SANTA ANA', sup: '0101'},
  {tipo: 'parroquia', codigo: '010165', nombre:'SAYAUSÍ', sup: '0101'},
  {tipo: 'parroquia', codigo: '010166', nombre:'SIDCAY', sup: '0101'},
  {tipo: 'parroquia', codigo: '010167', nombre:'SININCAY', sup: '0101'},
  {tipo: 'parroquia', codigo: '010168', nombre:'TARQUI', sup: '0101'},
  {tipo: 'parroquia', codigo: '010169', nombre:'TURI', sup: '0101'},
  {tipo: 'parroquia', codigo: '010170', nombre:'VALLE', sup: '0101'},
  {tipo: 'parroquia', codigo: '010171', nombre:'VICTORIA DEL PORTETE', sup: '0101'},
  {tipo: 'parroquia', codigo: '010250', nombre:'GIRÓN', sup: '0102'},
  {tipo: 'parroquia', codigo: '010251', nombre:'LA ASUNCIÓN', sup: '0102'},
  {tipo: 'parroquia', codigo: '010252', nombre:'SAN GERARDO', sup: '0102'},
  {tipo: 'parroquia', codigo: '010350', nombre:'GUALACEO', sup: '0103'},
  {tipo: 'parroquia', codigo: '010352', nombre:'DANIEL CÓRDOVA TORAL', sup: '0103'},
  {tipo: 'parroquia', codigo: '010353', nombre:'JADÁN', sup: '0103'},
  {tipo: 'parroquia', codigo: '010354', nombre:'MARIANO MORENO', sup: '0103'},
  {tipo: 'parroquia', codigo: '010356', nombre:'REMIGIO CRESPO TORAL', sup: '0103'},
  {tipo: 'parroquia', codigo: '010357', nombre:'SAN JUAN', sup: '0103'},
  {tipo: 'parroquia', codigo: '010358', nombre:'ZHIDMAD', sup: '0103'},
  {tipo: 'parroquia', codigo: '010359', nombre:'LUIS CORDERO VEGA', sup: '0103'},
  {tipo: 'parroquia', codigo: '010360', nombre:'SIMÓN BOLÍVAR', sup: '0103'},
  {tipo: 'parroquia', codigo: '010450', nombre:'NABÓN', sup: '0104'},
  {tipo: 'parroquia', codigo: '010451', nombre:'COCHAPATA', sup: '0104'},
  {tipo: 'parroquia', codigo: '010452', nombre:'EL PROGRESO', sup: '0104'},
  {tipo: 'parroquia', codigo: '010453', nombre:'LAS NIEVES', sup: '0104'},
  {tipo: 'parroquia', codigo: '010550', nombre:'PAUTE', sup: '0105'},
  {tipo: 'parroquia', codigo: '010552', nombre:'BULÁN', sup: '0105'},
  {tipo: 'parroquia', codigo: '010553', nombre:'CHICÁN', sup: '0105'},
  {tipo: 'parroquia', codigo: '010554', nombre:'EL CABO', sup: '0105'},
  {tipo: 'parroquia', codigo: '010556', nombre:'GUARAINAG', sup: '0105'},
  {tipo: 'parroquia', codigo: '010559', nombre:'SAN CRISTÓBAL', sup: '0105'},
  {tipo: 'parroquia', codigo: '010561', nombre:'TOMEBAMBA', sup: '0105'},
  {tipo: 'parroquia', codigo: '010562', nombre:'DUG DUG', sup: '0105'},
  {tipo: 'parroquia', codigo: '010650', nombre:'PUCARÁ', sup: '0106'},
  {tipo: 'parroquia', codigo: '010652', nombre:'SAN RAFAEL DE SHARUG', sup: '0106'},
  {tipo: 'parroquia', codigo: '010750', nombre:'SAN FERNANDO', sup: '0107'},
  {tipo: 'parroquia', codigo: '010751', nombre:'CHUMBLÍN', sup: '0107'},
  {tipo: 'parroquia', codigo: '010850', nombre:'SANTA ISABEL', sup: '0108'},
  {tipo: 'parroquia', codigo: '010851', nombre:'ABDÓN CALDERÓN', sup: '0108'},
  {tipo: 'parroquia', codigo: '010852', nombre:'EL CARMEN DE PIJILÍ', sup: '0108'},
  {tipo: 'parroquia', codigo: '010853', nombre:'SHAGLLI', sup: '0108'},
  {tipo: 'parroquia', codigo: '010854', nombre:'SAN SALVADOR DE CAÑARIBAMBA', sup: '0108'},
  {tipo: 'parroquia', codigo: '010950', nombre:'SÍGSIG', sup: '0109'},
  {tipo: 'parroquia', codigo: '010951', nombre:'CUCHIL', sup: '0109'},
  {tipo: 'parroquia', codigo: '010952', nombre:'JIMA', sup: '0109'},
  {tipo: 'parroquia', codigo: '010953', nombre:'GÜEL', sup: '0109'},
  {tipo: 'parroquia', codigo: '010954', nombre:'LUDO', sup: '0109'},
  {tipo: 'parroquia', codigo: '010955', nombre:'SAN BARTOLOMÉ', sup: '0109'},
  {tipo: 'parroquia', codigo: '010956', nombre:'SAN JOSÉ DE RARANGA', sup: '0109'},
  {tipo: 'parroquia', codigo: '011050', nombre:'SAN FELIPE DE OÑA', sup: '0110'},
  {tipo: 'parroquia', codigo: '011051', nombre:'SUSUDEL', sup: '0110'},
  {tipo: 'parroquia', codigo: '011150', nombre:'CHORDELEG', sup: '0111'},
  {tipo: 'parroquia', codigo: '011151', nombre:'PRINCIPAL', sup: '0111'},
  {tipo: 'parroquia', codigo: '011152', nombre:'LA UNIÓN', sup: '0111'},
  {tipo: 'parroquia', codigo: '011153', nombre:'LUIS GALARZA ORELLANA', sup: '0111'},
  {tipo: 'parroquia', codigo: '011154', nombre:'SAN MARTÍN DE PUZHIO', sup: '0111'},
  {tipo: 'parroquia', codigo: '011250', nombre:'EL PAN', sup: '0112'},
  {tipo: 'parroquia', codigo: '011253', nombre:'SAN VICENTE', sup: '0112'},
  {tipo: 'parroquia', codigo: '011350', nombre:'SEVILLA DE ORO', sup: '0113'},
  {tipo: 'parroquia', codigo: '011351', nombre:'AMALUZA', sup: '0113'},
  {tipo: 'parroquia', codigo: '011352', nombre:'PALMAS', sup: '0113'},
  {tipo: 'parroquia', codigo: '011450', nombre:'GUACHAPALA', sup: '0114'},
  {tipo: 'parroquia', codigo: '011550', nombre:'CAMILO PONCE ENRÍQUEZ', sup: '0115'},
  {tipo: 'parroquia', codigo: '020101', nombre:'ÁNGEL POLIBIO CHÁVES', sup: '0201'},
  {tipo: 'parroquia', codigo: '020102', nombre:'GABRIEL IGNACIO VEINTIMILLA', sup: '0201'},
  {tipo: 'parroquia', codigo: '020103', nombre:'GUANUJO', sup: '0201'},
  {tipo: 'parroquia', codigo: '020151', nombre:'FACUNDO VELA', sup: '0201'},
  {tipo: 'parroquia', codigo: '020153', nombre:'JULIO E. MORENO', sup: '0201'},
  {tipo: 'parroquia', codigo: '020155', nombre:'SALINAS', sup: '0201'},
  {tipo: 'parroquia', codigo: '020156', nombre:'SAN LORENZO', sup: '0201'},
  {tipo: 'parroquia', codigo: '020157', nombre:'SAN SIMÓN', sup: '0201'},
  {tipo: 'parroquia', codigo: '020158', nombre:'SANTA FE', sup: '0201'},
  {tipo: 'parroquia', codigo: '020159', nombre:'SIMIÁTUG', sup: '0201'},
  {tipo: 'parroquia', codigo: '020160', nombre:'SAN LUIS DE PAMBIL', sup: '0201'},
  {tipo: 'parroquia', codigo: '020250', nombre:'CHILLANES', sup: '0202'},
  {tipo: 'parroquia', codigo: '020251', nombre:'SAN JOSÉ DEL TAMBO', sup: '0202'},
  {tipo: 'parroquia', codigo: '020350', nombre:'SAN JOSÉ DE CHIMBO', sup: '0203'},
  {tipo: 'parroquia', codigo: '020351', nombre:'ASUNCIÓN', sup: '0203'},
  {tipo: 'parroquia', codigo: '020353', nombre:'LA MAGDALENA', sup: '0203'},
  {tipo: 'parroquia', codigo: '020354', nombre:'SAN SEBASTIÁN', sup: '0203'},
  {tipo: 'parroquia', codigo: '020355', nombre:'TELIMBELA', sup: '0203'},
  {tipo: 'parroquia', codigo: '020450', nombre:'ECHEANDÍA', sup: '0204'},
  {tipo: 'parroquia', codigo: '020550', nombre:'SAN MIGUEL', sup: '0205'},
  {tipo: 'parroquia', codigo: '020551', nombre:'BALSAPAMBA', sup: '0205'},
  {tipo: 'parroquia', codigo: '020552', nombre:'BILOVÁN', sup: '0205'},
  {tipo: 'parroquia', codigo: '020553', nombre:'RÉGULO DE MORA', sup: '0205'},
  {tipo: 'parroquia', codigo: '020554', nombre:'SAN PABLO', sup: '0205'},
  {tipo: 'parroquia', codigo: '020555', nombre:'SANTIAGO', sup: '0205'},
  {tipo: 'parroquia', codigo: '020556', nombre:'SAN VICENTE', sup: '0205'},
  {tipo: 'parroquia', codigo: '020650', nombre:'CALUMA', sup: '0206'},
  {tipo: 'parroquia', codigo: '020701', nombre:'LAS MERCEDES', sup: '0207'},
  {tipo: 'parroquia', codigo: '020702', nombre:'LAS NAVES', sup: '0207'},
  {tipo: 'parroquia', codigo: '030101', nombre:'AURELIO BAYAS MARTÍNEZ', sup: '0301'},
  {tipo: 'parroquia', codigo: '030102', nombre:'AZOGUES', sup: '0301'},
  {tipo: 'parroquia', codigo: '030103', nombre:'BORRERO', sup: '0301'},
  {tipo: 'parroquia', codigo: '030104', nombre:'SAN FRANCISCO', sup: '0301'},
  {tipo: 'parroquia', codigo: '030151', nombre:'COJITAMBO', sup: '0301'},
  {tipo: 'parroquia', codigo: '030153', nombre:'GUAPÁN', sup: '0301'},
  {tipo: 'parroquia', codigo: '030154', nombre:'JAVIER LOYOLA', sup: '0301'},
  {tipo: 'parroquia', codigo: '030155', nombre:'LUIS CORDERO', sup: '0301'},
  {tipo: 'parroquia', codigo: '030156', nombre:'PINDILIG', sup: '0301'},
  {tipo: 'parroquia', codigo: '030157', nombre:'RIVERA', sup: '0301'},
  {tipo: 'parroquia', codigo: '030158', nombre:'SAN MIGUEL', sup: '0301'},
  {tipo: 'parroquia', codigo: '030160', nombre:'TADAY', sup: '0301'},
  {tipo: 'parroquia', codigo: '030250', nombre:'BIBLIÁN', sup: '0302'},
  {tipo: 'parroquia', codigo: '030251', nombre:'NAZÓN', sup: '0302'},
  {tipo: 'parroquia', codigo: '030252', nombre:'SAN FRANCISCO DE SAGEO', sup: '0302'},
  {tipo: 'parroquia', codigo: '030253', nombre:'TURUPAMBA', sup: '0302'},
  {tipo: 'parroquia', codigo: '030254', nombre:'JERUSALÉN', sup: '0302'},
  {tipo: 'parroquia', codigo: '030350', nombre:'CAÑAR', sup: '0303'},
  {tipo: 'parroquia', codigo: '030351', nombre:'CHONTAMARCA', sup: '0303'},
  {tipo: 'parroquia', codigo: '030352', nombre:'CHOROCOPTE', sup: '0303'},
  {tipo: 'parroquia', codigo: '030353', nombre:'GENERAL MORALES', sup: '0303'},
  {tipo: 'parroquia', codigo: '030354', nombre:'GUALLETURO', sup: '0303'},
  {tipo: 'parroquia', codigo: '030355', nombre:'HONORATO VÁSQUEZ', sup: '0303'},
  {tipo: 'parroquia', codigo: '030356', nombre:'INGAPIRCA', sup: '0303'},
  {tipo: 'parroquia', codigo: '030357', nombre:'JUNCAL', sup: '0303'},
  {tipo: 'parroquia', codigo: '030358', nombre:'SAN ANTONIO', sup: '0303'},
  {tipo: 'parroquia', codigo: '030361', nombre:'ZHUD', sup: '0303'},
  {tipo: 'parroquia', codigo: '030362', nombre:'VENTURA', sup: '0303'},
  {tipo: 'parroquia', codigo: '030363', nombre:'DUCUR', sup: '0303'},
  {tipo: 'parroquia', codigo: '030450', nombre:'LA TRONCAL', sup: '0304'},
  {tipo: 'parroquia', codigo: '030451', nombre:'MANUEL J. CALLE', sup: '0304'},
  {tipo: 'parroquia', codigo: '030452', nombre:'PANCHO NEGRO', sup: '0304'},
  {tipo: 'parroquia', codigo: '030550', nombre:'EL TAMBO', sup: '0305'},
  {tipo: 'parroquia', codigo: '030650', nombre:'DÉLEG', sup: '0306'},
  {tipo: 'parroquia', codigo: '030651', nombre:'SOLANO', sup: '0306'},
  {tipo: 'parroquia', codigo: '030750', nombre:'SUSCAL', sup: '0307'},
  {tipo: 'parroquia', codigo: '040101', nombre:'GONZÁLEZ SUÁREZ', sup: '0401'},
  {tipo: 'parroquia', codigo: '040102', nombre:'TULCÁN', sup: '0401'},
  {tipo: 'parroquia', codigo: '040151', nombre:'EL CARMELO', sup: '0401'},
  {tipo: 'parroquia', codigo: '040153', nombre:'JULIO ANDRADE', sup: '0401'},
  {tipo: 'parroquia', codigo: '040154', nombre:'MALDONADO', sup: '0401'},
  {tipo: 'parroquia', codigo: '040155', nombre:'PIOTER', sup: '0401'},
  {tipo: 'parroquia', codigo: '040156', nombre:'TOBAR DONOSO', sup: '0401'},
  {tipo: 'parroquia', codigo: '040157', nombre:'TUFIÑO', sup: '0401'},
  {tipo: 'parroquia', codigo: '040158', nombre:'URBINA', sup: '0401'},
  {tipo: 'parroquia', codigo: '040159', nombre:'EL CHICAL', sup: '0401'},
  {tipo: 'parroquia', codigo: '040161', nombre:'SANTA MARTHA DE CUBA', sup: '0401'},
  {tipo: 'parroquia', codigo: '040250', nombre:'BOLÍVAR', sup: '0402'},
  {tipo: 'parroquia', codigo: '040251', nombre:'GARCÍA MORENO', sup: '0402'},
  {tipo: 'parroquia', codigo: '040252', nombre:'LOS ANDES', sup: '0402'},
  {tipo: 'parroquia', codigo: '040253', nombre:'MONTE OLIVO', sup: '0402'},
  {tipo: 'parroquia', codigo: '040254', nombre:'SAN VICENTE DE PUSIR', sup: '0402'},
  {tipo: 'parroquia', codigo: '040255', nombre:'SAN RAFAEL', sup: '0402'},
  {tipo: 'parroquia', codigo: '040301', nombre:'EL ÁNGEL', sup: '0403'},
  {tipo: 'parroquia', codigo: '040302', nombre:'27 DE SEPTIEMBRE', sup: '0403'},
  {tipo: 'parroquia', codigo: '040351', nombre:'EL GOALTAL', sup: '0403'},
  {tipo: 'parroquia', codigo: '040352', nombre:'LA LIBERTAD', sup: '0403'},
  {tipo: 'parroquia', codigo: '040353', nombre:'SAN ISIDRO', sup: '0403'},
  {tipo: 'parroquia', codigo: '040450', nombre:'MIRA ', sup: '0404'},
  {tipo: 'parroquia', codigo: '040451', nombre:'CONCEPCIÓN', sup: '0404'},
  {tipo: 'parroquia', codigo: '040452', nombre:'JIJÓN Y CAAMAÑO', sup: '0404'},
  {tipo: 'parroquia', codigo: '040453', nombre:'JUAN MONTALVO', sup: '0404'},
  {tipo: 'parroquia', codigo: '040501', nombre:'GONZÁLEZ SUÁREZ', sup: '0405'},
  {tipo: 'parroquia', codigo: '040502', nombre:'SAN JOSÉ', sup: '0405'},
  {tipo: 'parroquia', codigo: '040551', nombre:'CRISTÓBAL COLÓN', sup: '0405'},
  {tipo: 'parroquia', codigo: '040552', nombre:'CHITÁN DE NAVARRETE', sup: '0405'},
  {tipo: 'parroquia', codigo: '040553', nombre:'FERNÁNDEZ SALVADOR', sup: '0405'},
  {tipo: 'parroquia', codigo: '040554', nombre:'LA PAZ', sup: '0405'},
  {tipo: 'parroquia', codigo: '040555', nombre:'PIARTAL', sup: '0405'},
  {tipo: 'parroquia', codigo: '040650', nombre:'HUACA', sup: '0406'},
  {tipo: 'parroquia', codigo: '040651', nombre:'MARISCAL SUCRE', sup: '0406'},
  {tipo: 'parroquia', codigo: '050101', nombre:'ELOY ALFARO  (SAN FELIPE) ', sup: '0501'},
  {tipo: 'parroquia', codigo: '050102', nombre:'IGNACIO FLORES (PARQUE FLORES) ', sup: '0501'},
  {tipo: 'parroquia', codigo: '050103', nombre:'JUAN MONTALVO (SAN SEBASTIÁN)', sup: '0501'},
  {tipo: 'parroquia', codigo: '050104', nombre:'LA MATRIZ', sup: '0501'},
  {tipo: 'parroquia', codigo: '050105', nombre:'SAN BUENAVENTURA', sup: '0501'},
  {tipo: 'parroquia', codigo: '050151', nombre:'ALÁQUEZ', sup: '0501'},
  {tipo: 'parroquia', codigo: '050152', nombre:'BELISARIO QUEVEDO', sup: '0501'},
  {tipo: 'parroquia', codigo: '050153', nombre:'GUAYTACAMA', sup: '0501'},
  {tipo: 'parroquia', codigo: '050154', nombre:'JOSEGUANGO BAJO', sup: '0501'},
  {tipo: 'parroquia', codigo: '050156', nombre:'MULALÓ', sup: '0501'},
  {tipo: 'parroquia', codigo: '050157', nombre:'ONCE DE NOVIEMBRE', sup: '0501'},
  {tipo: 'parroquia', codigo: '050158', nombre:'POALÓ', sup: '0501'},
  {tipo: 'parroquia', codigo: '050159', nombre:'SAN JUAN DE PASTOCALLE', sup: '0501'},
  {tipo: 'parroquia', codigo: '050161', nombre:'TANICUCHÍ', sup: '0501'},
  {tipo: 'parroquia', codigo: '050162', nombre:'TOACASO', sup: '0501'},
  {tipo: 'parroquia', codigo: '050201', nombre:'EL CARMEN', sup: '0502'},
  {tipo: 'parroquia', codigo: '050202', nombre:'LA MANÁ ', sup: '0502'},
  {tipo: 'parroquia', codigo: '050203', nombre:'EL TRIUNFO', sup: '0502'},
  {tipo: 'parroquia', codigo: '050251', nombre:'GUASAGANDA', sup: '0502'},
  {tipo: 'parroquia', codigo: '050252', nombre:'PUCAYACU', sup: '0502'},
  {tipo: 'parroquia', codigo: '050350', nombre:'EL CORAZÓN', sup: '0503'},
  {tipo: 'parroquia', codigo: '050351', nombre:'MORASPUNGO', sup: '0503'},
  {tipo: 'parroquia', codigo: '050352', nombre:'PINLLOPATA', sup: '0503'},
  {tipo: 'parroquia', codigo: '050353', nombre:'RAMÓN CAMPAÑA', sup: '0503'},
  {tipo: 'parroquia', codigo: '050450', nombre:'PUJILÍ', sup: '0504'},
  {tipo: 'parroquia', codigo: '050451', nombre:'ANGAMARCA', sup: '0504'},
  {tipo: 'parroquia', codigo: '050453', nombre:'GUANGAJE', sup: '0504'},
  {tipo: 'parroquia', codigo: '050455', nombre:'LA VICTORIA', sup: '0504'},
  {tipo: 'parroquia', codigo: '050456', nombre:'PILALÓ', sup: '0504'},
  {tipo: 'parroquia', codigo: '050457', nombre:'TINGO', sup: '0504'},
  {tipo: 'parroquia', codigo: '050458', nombre:'ZUMBAHUA', sup: '0504'},
  {tipo: 'parroquia', codigo: '050550', nombre:'SAN MIGUEL', sup: '0505'},
  {tipo: 'parroquia', codigo: '050551', nombre:'ANTONIO JOSÉ HOLGUÍN', sup: '0505'},
  {tipo: 'parroquia', codigo: '050552', nombre:'CUSUBAMBA', sup: '0505'},
  {tipo: 'parroquia', codigo: '050553', nombre:'MULALILLO', sup: '0505'},
  {tipo: 'parroquia', codigo: '050554', nombre:'MULLIQUINDIL', sup: '0505'},
  {tipo: 'parroquia', codigo: '050555', nombre:'PANSALEO', sup: '0505'},
  {tipo: 'parroquia', codigo: '050650', nombre:'SAQUISILÍ', sup: '0506'},
  {tipo: 'parroquia', codigo: '050651', nombre:'CANCHAGUA', sup: '0506'},
  {tipo: 'parroquia', codigo: '050652', nombre:'CHANTILÍN', sup: '0506'},
  {tipo: 'parroquia', codigo: '050653', nombre:'COCHAPAMBA', sup: '0506'},
  {tipo: 'parroquia', codigo: '050750', nombre:'SIGCHOS', sup: '0507'},
  {tipo: 'parroquia', codigo: '050751', nombre:'CHUGCHILLÁN', sup: '0507'},
  {tipo: 'parroquia', codigo: '050752', nombre:'ISINLIVI', sup: '0507'},
  {tipo: 'parroquia', codigo: '050753', nombre:'LAS PAMPAS', sup: '0507'},
  {tipo: 'parroquia', codigo: '050754', nombre:'PALO QUEMADO', sup: '0507'},
  {tipo: 'parroquia', codigo: '060101', nombre:'LIZARZABURU', sup: '0601'},
  {tipo: 'parroquia', codigo: '060102', nombre:'MALDONADO', sup: '0601'},
  {tipo: 'parroquia', codigo: '060103', nombre:'VELASCO', sup: '0601'},
  {tipo: 'parroquia', codigo: '060104', nombre:'VELOZ', sup: '0601'},
  {tipo: 'parroquia', codigo: '060105', nombre:'YARUQUÍES', sup: '0601'},
  {tipo: 'parroquia', codigo: '060151', nombre:'CACHA', sup: '0601'},
  {tipo: 'parroquia', codigo: '060152', nombre:'CALPI', sup: '0601'},
  {tipo: 'parroquia', codigo: '060153', nombre:'CUBIJÍES', sup: '0601'},
  {tipo: 'parroquia', codigo: '060154', nombre:'FLORES', sup: '0601'},
  {tipo: 'parroquia', codigo: '060155', nombre:'LICÁN', sup: '0601'},
  {tipo: 'parroquia', codigo: '060156', nombre:'LICTO', sup: '0601'},
  {tipo: 'parroquia', codigo: '060157', nombre:'PUNGALÁ', sup: '0601'},
  {tipo: 'parroquia', codigo: '060158', nombre:'PUNÍN', sup: '0601'},
  {tipo: 'parroquia', codigo: '060159', nombre:'QUIMIAG', sup: '0601'},
  {tipo: 'parroquia', codigo: '060160', nombre:'SAN JUAN', sup: '0601'},
  {tipo: 'parroquia', codigo: '060161', nombre:'SAN LUIS', sup: '0601'},
  {tipo: 'parroquia', codigo: '060250', nombre:'ALAUSÍ', sup: '0602'},
  {tipo: 'parroquia', codigo: '060251', nombre:'ACHUPALLAS', sup: '0602'},
  {tipo: 'parroquia', codigo: '060253', nombre:'GUASUNTOS', sup: '0602'},
  {tipo: 'parroquia', codigo: '060254', nombre:'HUIGRA', sup: '0602'},
  {tipo: 'parroquia', codigo: '060255', nombre:'MULTITUD', sup: '0602'},
  {tipo: 'parroquia', codigo: '060256', nombre:'PISTISHI', sup: '0602'},
  {tipo: 'parroquia', codigo: '060257', nombre:'PUMALLACTA', sup: '0602'},
  {tipo: 'parroquia', codigo: '060258', nombre:'SEVILLA', sup: '0602'},
  {tipo: 'parroquia', codigo: '060259', nombre:'SIBAMBE', sup: '0602'},
  {tipo: 'parroquia', codigo: '060260', nombre:'TIXÁN', sup: '0602'},
  {tipo: 'parroquia', codigo: '060301', nombre:'CAJABAMBA', sup: '0603'},
  {tipo: 'parroquia', codigo: '060302', nombre:'SICALPA', sup: '0603'},
  {tipo: 'parroquia', codigo: '060351', nombre:'CAÑI', sup: '0603'},
  {tipo: 'parroquia', codigo: '060352', nombre:'COLUMBE', sup: '0603'},
  {tipo: 'parroquia', codigo: '060353', nombre:'JUAN DE VELASCO', sup: '0603'},
  {tipo: 'parroquia', codigo: '060354', nombre:'SANTIAGO DE QUITO', sup: '0603'},
  {tipo: 'parroquia', codigo: '060450', nombre:'CHAMBO', sup: '0604'},
  {tipo: 'parroquia', codigo: '060550', nombre:'CHUNCHI', sup: '0605'},
  {tipo: 'parroquia', codigo: '060551', nombre:'CAPZOL', sup: '0605'},
  {tipo: 'parroquia', codigo: '060552', nombre:'COMPUD', sup: '0605'},
  {tipo: 'parroquia', codigo: '060553', nombre:'GONZOL', sup: '0605'},
  {tipo: 'parroquia', codigo: '060554', nombre:'LLAGOS', sup: '0605'},
  {tipo: 'parroquia', codigo: '060650', nombre:'GUAMOTE', sup: '0606'},
  {tipo: 'parroquia', codigo: '060651', nombre:'CEBADAS', sup: '0606'},
  {tipo: 'parroquia', codigo: '060652', nombre:'PALMIRA', sup: '0606'},
  {tipo: 'parroquia', codigo: '060701', nombre:'EL ROSARIO', sup: '0607'},
  {tipo: 'parroquia', codigo: '060702', nombre:'LA MATRIZ', sup: '0607'},
  {tipo: 'parroquia', codigo: '060751', nombre:'GUANANDO', sup: '0607'},
  {tipo: 'parroquia', codigo: '060752', nombre:'ILAPO', sup: '0607'},
  {tipo: 'parroquia', codigo: '060753', nombre:'LA PROVIDENCIA', sup: '0607'},
  {tipo: 'parroquia', codigo: '060754', nombre:'SAN ANDRÉS', sup: '0607'},
  {tipo: 'parroquia', codigo: '060755', nombre:'SAN GERARDO', sup: '0607'},
  {tipo: 'parroquia', codigo: '060756', nombre:'SAN ISIDRO DE PATULÚ', sup: '0607'},
  {tipo: 'parroquia', codigo: '060757', nombre:'SAN JOSÉ DEL CHAZO', sup: '0607'},
  {tipo: 'parroquia', codigo: '060758', nombre:'SANTA FÉ DE GALÁN', sup: '0607'},
  {tipo: 'parroquia', codigo: '060759', nombre:'VALPARAISO', sup: '0607'},
  {tipo: 'parroquia', codigo: '060850', nombre:'PALLATANGA', sup: '0608'},
  {tipo: 'parroquia', codigo: '060950', nombre:'PENIPE', sup: '0609'},
  {tipo: 'parroquia', codigo: '060951', nombre:'EL ALTAR', sup: '0609'},
  {tipo: 'parroquia', codigo: '060952', nombre:'MATUS', sup: '0609'},
  {tipo: 'parroquia', codigo: '060953', nombre:'PUELA', sup: '0609'},
  {tipo: 'parroquia', codigo: '060954', nombre:'SAN ANTONIO DE BAYUSHIG', sup: '0609'},
  {tipo: 'parroquia', codigo: '060955', nombre:'LA CANDELARIA', sup: '0609'},
  {tipo: 'parroquia', codigo: '060956', nombre:'BILBAO', sup: '0609'},
  {tipo: 'parroquia', codigo: '061050', nombre:'CUMANDÁ', sup: '0610'},
  {tipo: 'parroquia', codigo: '070101', nombre:'LA PROVIDENCIA', sup: '0701'},
  {tipo: 'parroquia', codigo: '070102', nombre:'MACHALA', sup: '0701'},
  {tipo: 'parroquia', codigo: '070103', nombre:'PUERTO BOLÍVAR', sup: '0701'},
  {tipo: 'parroquia', codigo: '070104', nombre:'NUEVE DE MAYO', sup: '0701'},
  {tipo: 'parroquia', codigo: '070105', nombre:'EL CAMBIO', sup: '0701'},
  {tipo: 'parroquia', codigo: '070152', nombre:'EL RETIRO', sup: '0701'},
  {tipo: 'parroquia', codigo: '070250', nombre:'ARENILLAS', sup: '0702'},
  {tipo: 'parroquia', codigo: '070251', nombre:'CHACRAS', sup: '0702'},
  {tipo: 'parroquia', codigo: '070254', nombre:'PALMALES', sup: '0702'},
  {tipo: 'parroquia', codigo: '070255', nombre:'CARCABÓN', sup: '0702'},
  {tipo: 'parroquia', codigo: '070256', nombre:'LA CUCA', sup: '0702'},
  {tipo: 'parroquia', codigo: '070350', nombre:'PACCHA', sup: '0703'},
  {tipo: 'parroquia', codigo: '070351', nombre:'AYAPAMBA', sup: '0703'},
  {tipo: 'parroquia', codigo: '070352', nombre:'CORDONCILLO', sup: '0703'},
  {tipo: 'parroquia', codigo: '070353', nombre:'MILAGRO', sup: '0703'},
  {tipo: 'parroquia', codigo: '070354', nombre:'SAN JOSÉ', sup: '0703'},
  {tipo: 'parroquia', codigo: '070355', nombre:'SAN JUAN DE CERRO AZUL', sup: '0703'},
  {tipo: 'parroquia', codigo: '070450', nombre:'BALSAS', sup: '0704'},
  {tipo: 'parroquia', codigo: '070451', nombre:'BELLAMARÍA', sup: '0704'},
  {tipo: 'parroquia', codigo: '070550', nombre:'CHILLA', sup: '0705'},
  {tipo: 'parroquia', codigo: '070650', nombre:'EL GUABO', sup: '0706'},
  {tipo: 'parroquia', codigo: '070651', nombre:'BARBONES', sup: '0706'},
  {tipo: 'parroquia', codigo: '070652', nombre:'LA IBERIA', sup: '0706'},
  {tipo: 'parroquia', codigo: '070653', nombre:'TENDALES', sup: '0706'},
  {tipo: 'parroquia', codigo: '070654', nombre:'RÍO BONITO', sup: '0706'},
  {tipo: 'parroquia', codigo: '070701', nombre:'ECUADOR', sup: '0707'},
  {tipo: 'parroquia', codigo: '070702', nombre:'EL PARAÍSO', sup: '0707'},
  {tipo: 'parroquia', codigo: '070703', nombre:'HUALTACO', sup: '0707'},
  {tipo: 'parroquia', codigo: '070704', nombre:'MILTON REYES', sup: '0707'},
  {tipo: 'parroquia', codigo: '070705', nombre:'UNIÓN LOJANA', sup: '0707'},
  {tipo: 'parroquia', codigo: '070850', nombre:'MARCABELÍ', sup: '0708'},
  {tipo: 'parroquia', codigo: '070851', nombre:'EL INGENIO', sup: '0708'},
  {tipo: 'parroquia', codigo: '070901', nombre:'BOLÍVAR', sup: '0709'},
  {tipo: 'parroquia', codigo: '070902', nombre:'LOMA DE FRANCO', sup: '0709'},
  {tipo: 'parroquia', codigo: '070903', nombre:'OCHOA LEÓN (MATRIZ)', sup: '0709'},
  {tipo: 'parroquia', codigo: '070904', nombre:'TRES CERRITOS', sup: '0709'},
  {tipo: 'parroquia', codigo: '070951', nombre:'BUENAVISTA', sup: '0709'},
  {tipo: 'parroquia', codigo: '070952', nombre:'CASACAY', sup: '0709'},
  {tipo: 'parroquia', codigo: '070953', nombre:'LA PEAÑA', sup: '0709'},
  {tipo: 'parroquia', codigo: '070954', nombre:'PROGRESO', sup: '0709'},
  {tipo: 'parroquia', codigo: '070955', nombre:'UZHCURRUMI', sup: '0709'},
  {tipo: 'parroquia', codigo: '070956', nombre:'CAÑAQUEMADA', sup: '0709'},
  {tipo: 'parroquia', codigo: '071001', nombre:'LA MATRIZ', sup: '0710'},
  {tipo: 'parroquia', codigo: '071002', nombre:'LA SUSAYA', sup: '0710'},
  {tipo: 'parroquia', codigo: '071003', nombre:'PIÑAS GRANDE', sup: '0710'},
  {tipo: 'parroquia', codigo: '071051', nombre:'CAPIRO', sup: '0710'},
  {tipo: 'parroquia', codigo: '071052', nombre:'LA BOCANA', sup: '0710'},
  {tipo: 'parroquia', codigo: '071053', nombre:'MOROMORO', sup: '0710'},
  {tipo: 'parroquia', codigo: '071054', nombre:'PIEDRAS', sup: '0710'},
  {tipo: 'parroquia', codigo: '071055', nombre:'SAN ROQUE', sup: '0710'},
  {tipo: 'parroquia', codigo: '071056', nombre:'SARACAY', sup: '0710'},
  {tipo: 'parroquia', codigo: '071150', nombre:'PORTOVELO', sup: '0711'},
  {tipo: 'parroquia', codigo: '071151', nombre:'CURTINCAPA', sup: '0711'},
  {tipo: 'parroquia', codigo: '071152', nombre:'MORALES', sup: '0711'},
  {tipo: 'parroquia', codigo: '071153', nombre:'SALATÍ', sup: '0711'},
  {tipo: 'parroquia', codigo: '071201', nombre:'SANTA ROSA', sup: '0712'},
  {tipo: 'parroquia', codigo: '071202', nombre:'PUERTO JELÍ', sup: '0712'},
  {tipo: 'parroquia', codigo: '071203', nombre:'BALNEARIO JAMBELÍ (SATÉLITE)', sup: '0712'},
  {tipo: 'parroquia', codigo: '071204', nombre:'JUMÓN (SATÉLITE)', sup: '0712'},
  {tipo: 'parroquia', codigo: '071205', nombre:'NUEVO SANTA ROSA', sup: '0712'},
  {tipo: 'parroquia', codigo: '071251', nombre:'BELLAVISTA', sup: '0712'},
  {tipo: 'parroquia', codigo: '071252', nombre:'JAMBELÍ', sup: '0712'},
  {tipo: 'parroquia', codigo: '071253', nombre:'LA AVANZADA', sup: '0712'},
  {tipo: 'parroquia', codigo: '071254', nombre:'SAN ANTONIO', sup: '0712'},
  {tipo: 'parroquia', codigo: '071255', nombre:'TORATA', sup: '0712'},
  {tipo: 'parroquia', codigo: '071256', nombre:'VICTORIA', sup: '0712'},
  {tipo: 'parroquia', codigo: '071257', nombre:'BELLAMARÍA', sup: '0712'},
  {tipo: 'parroquia', codigo: '071350', nombre:'ZARUMA', sup: '0713'},
  {tipo: 'parroquia', codigo: '071351', nombre:'ABAÑÍN', sup: '0713'},
  {tipo: 'parroquia', codigo: '071352', nombre:'ARCAPAMBA', sup: '0713'},
  {tipo: 'parroquia', codigo: '071353', nombre:'GUANAZÁN', sup: '0713'},
  {tipo: 'parroquia', codigo: '071354', nombre:'GUIZHAGUIÑA', sup: '0713'},
  {tipo: 'parroquia', codigo: '071355', nombre:'HUERTAS', sup: '0713'},
  {tipo: 'parroquia', codigo: '071356', nombre:'MALVAS', sup: '0713'},
  {tipo: 'parroquia', codigo: '071357', nombre:'MULUNCAY GRANDE', sup: '0713'},
  {tipo: 'parroquia', codigo: '071358', nombre:'SINSAO', sup: '0713'},
  {tipo: 'parroquia', codigo: '071359', nombre:'SALVIAS', sup: '0713'},
  {tipo: 'parroquia', codigo: '071401', nombre:'LA VICTORIA', sup: '0714'},
  {tipo: 'parroquia', codigo: '071402', nombre:'PLATANILLOS', sup: '0714'},
  {tipo: 'parroquia', codigo: '071403', nombre:'VALLE HERMOSO', sup: '0714'},
  {tipo: 'parroquia', codigo: '071451', nombre:'LA LIBERTAD', sup: '0714'},
  {tipo: 'parroquia', codigo: '071452', nombre:'EL PARAÍSO', sup: '0714'},
  {tipo: 'parroquia', codigo: '071453', nombre:'SAN ISIDRO', sup: '0714'},
  {tipo: 'parroquia', codigo: '080101', nombre:'BARTOLOMÉ RUIZ (CÉSAR FRANCO CARRIÓN) ', sup: '0801'},
  {tipo: 'parroquia', codigo: '080102', nombre:'5 DE AGOSTO', sup: '0801'},
  {tipo: 'parroquia', codigo: '080103', nombre:'ESMERALDAS', sup: '0801'},
  {tipo: 'parroquia', codigo: '080104', nombre:'LUIS TELLO  (LAS PALMAS)', sup: '0801'},
  {tipo: 'parroquia', codigo: '080105', nombre:'SIMÓN PLATA TORRES', sup: '0801'},
  {tipo: 'parroquia', codigo: '080152', nombre:'CAMARONES', sup: '0801'},
  {tipo: 'parroquia', codigo: '080153', nombre:'CORONEL CARLOS CONCHA TORRES', sup: '0801'},
  {tipo: 'parroquia', codigo: '080154', nombre:'CHINCA', sup: '0801'},
  {tipo: 'parroquia', codigo: '080159', nombre:'MAJUA', sup: '0801'},
  {tipo: 'parroquia', codigo: '080163', nombre:'SAN MATEO', sup: '0801'},
  {tipo: 'parroquia', codigo: '080165', nombre:'TABIAZO', sup: '0801'},
  {tipo: 'parroquia', codigo: '080166', nombre:'TACHINA', sup: '0801'},
  {tipo: 'parroquia', codigo: '080168', nombre:'VUELTA LARGA', sup: '0801'},
  {tipo: 'parroquia', codigo: '080250', nombre:'VALDEZ', sup: '0802'},
  {tipo: 'parroquia', codigo: '080251', nombre:'ANCHAYACU', sup: '0802'},
  {tipo: 'parroquia', codigo: '080252', nombre:'ATAHUALPA', sup: '0802'},
  {tipo: 'parroquia', codigo: '080253', nombre:'BORBÓN', sup: '0802'},
  {tipo: 'parroquia', codigo: '080254', nombre:'LA TOLA', sup: '0802'},
  {tipo: 'parroquia', codigo: '080255', nombre:'LUIS VARGAS TORRES', sup: '0802'},
  {tipo: 'parroquia', codigo: '080256', nombre:'MALDONADO', sup: '0802'},
  {tipo: 'parroquia', codigo: '080257', nombre:'PAMPANAL DE BOLÍVAR', sup: '0802'},
  {tipo: 'parroquia', codigo: '080258', nombre:'SAN FRANCISCO DE ONZOLE', sup: '0802'},
  {tipo: 'parroquia', codigo: '080259', nombre:'SANTO DOMINGO DE ONZOLE', sup: '0802'},
  {tipo: 'parroquia', codigo: '080260', nombre:'SELVA ALEGRE', sup: '0802'},
  {tipo: 'parroquia', codigo: '080261', nombre:'TELEMBÍ', sup: '0802'},
  {tipo: 'parroquia', codigo: '080262', nombre:'COLÓN ELOY DEL MARÍA', sup: '0802'},
  {tipo: 'parroquia', codigo: '080263', nombre:'SAN JOSÉ DE CAYAPAS', sup: '0802'},
  {tipo: 'parroquia', codigo: '080264', nombre:'TIMBIRÉ', sup: '0802'},
  {tipo: 'parroquia', codigo: '080265', nombre:'SANTA LUCÍA DE LAS PEÑAS', sup: '0802'},
  {tipo: 'parroquia', codigo: '080350', nombre:'MUISNE', sup: '0803'},
  {tipo: 'parroquia', codigo: '080351', nombre:'BOLÍVAR', sup: '0803'},
  {tipo: 'parroquia', codigo: '080352', nombre:'DAULE', sup: '0803'},
  {tipo: 'parroquia', codigo: '080353', nombre:'GALERA', sup: '0803'},
  {tipo: 'parroquia', codigo: '080354', nombre:'QUINGUE', sup: '0803'},
  {tipo: 'parroquia', codigo: '080355', nombre:'SÁLIMA', sup: '0803'},
  {tipo: 'parroquia', codigo: '080356', nombre:'SAN FRANCISCO', sup: '0803'},
  {tipo: 'parroquia', codigo: '080357', nombre:'SAN GREGORIO', sup: '0803'},
  {tipo: 'parroquia', codigo: '080358', nombre:'SAN JOSÉ DE CHAMANGA', sup: '0803'},
  {tipo: 'parroquia', codigo: '080450', nombre:'ROSA ZÁRATE ', sup: '0804'},
  {tipo: 'parroquia', codigo: '080451', nombre:'CUBE', sup: '0804'},
  {tipo: 'parroquia', codigo: '080452', nombre:'CHURA', sup: '0804'},
  {tipo: 'parroquia', codigo: '080453', nombre:'MALIMPIA', sup: '0804'},
  {tipo: 'parroquia', codigo: '080454', nombre:'VICHE', sup: '0804'},
  {tipo: 'parroquia', codigo: '080455', nombre:'LA UNIÓN', sup: '0804'},
  {tipo: 'parroquia', codigo: '080550', nombre:'SAN LORENZO', sup: '0805'},
  {tipo: 'parroquia', codigo: '080551', nombre:'ALTO TAMBO', sup: '0805'},
  {tipo: 'parroquia', codigo: '080552', nombre:'ANCÓN', sup: '0805'},
  {tipo: 'parroquia', codigo: '080553', nombre:'CALDERÓN', sup: '0805'},
  {tipo: 'parroquia', codigo: '080554', nombre:'CARONDELET', sup: '0805'},
  {tipo: 'parroquia', codigo: '080555', nombre:'5 DE JUNIO', sup: '0805'},
  {tipo: 'parroquia', codigo: '080556', nombre:'CONCEPCIÓN', sup: '0805'},
  {tipo: 'parroquia', codigo: '080557', nombre:'MATAJE', sup: '0805'},
  {tipo: 'parroquia', codigo: '080558', nombre:'SAN JAVIER DE CACHAVÍ', sup: '0805'},
  {tipo: 'parroquia', codigo: '080559', nombre:'SANTA RITA', sup: '0805'},
  {tipo: 'parroquia', codigo: '080560', nombre:'TAMBILLO', sup: '0805'},
  {tipo: 'parroquia', codigo: '080561', nombre:'TULULBÍ', sup: '0805'},
  {tipo: 'parroquia', codigo: '080562', nombre:'URBINA', sup: '0805'},
  {tipo: 'parroquia', codigo: '080650', nombre:'ATACAMES', sup: '0806'},
  {tipo: 'parroquia', codigo: '080651', nombre:'LA UNIÓN', sup: '0806'},
  {tipo: 'parroquia', codigo: '080652', nombre:'SÚA', sup: '0806'},
  {tipo: 'parroquia', codigo: '080653', nombre:'TONCHIGÜE', sup: '0806'},
  {tipo: 'parroquia', codigo: '080654', nombre:'TONSUPA', sup: '0806'},
  {tipo: 'parroquia', codigo: '080750', nombre:'RIOVERDE', sup: '0807'},
  {tipo: 'parroquia', codigo: '080751', nombre:'CHONTADURO', sup: '0807'},
  {tipo: 'parroquia', codigo: '080752', nombre:'CHUMUNDÉ', sup: '0807'},
  {tipo: 'parroquia', codigo: '080753', nombre:'LAGARTO', sup: '0807'},
  {tipo: 'parroquia', codigo: '080754', nombre:'MONTALVO', sup: '0807'},
  {tipo: 'parroquia', codigo: '080755', nombre:'ROCAFUERTE', sup: '0807'},
  {tipo: 'parroquia', codigo: '090101', nombre:'AYACUCHO', sup: '0901'},
  {tipo: 'parroquia', codigo: '090102', nombre:'BOLÍVAR  (SAGRARIO) ', sup: '0901'},
  {tipo: 'parroquia', codigo: '090103', nombre:'CARBO (CONCEPCIÓN) ', sup: '0901'},
  {tipo: 'parroquia', codigo: '090104', nombre:'FEBRES CORDERO', sup: '0901'},
  {tipo: 'parroquia', codigo: '090105', nombre:'GARCÍA MORENO', sup: '0901'},
  {tipo: 'parroquia', codigo: '090106', nombre:'LETAMENDI', sup: '0901'},
  {tipo: 'parroquia', codigo: '090107', nombre:'NUEVE DE OCTUBRE', sup: '0901'},
  {tipo: 'parroquia', codigo: '090108', nombre:'OLMEDO  (SAN ALEJO) ', sup: '0901'},
  {tipo: 'parroquia', codigo: '090109', nombre:'ROCA', sup: '0901'},
  {tipo: 'parroquia', codigo: '090110', nombre:'ROCAFUERTE', sup: '0901'},
  {tipo: 'parroquia', codigo: '090111', nombre:'SUCRE', sup: '0901'},
  {tipo: 'parroquia', codigo: '090112', nombre:'TARQUI', sup: '0901'},
  {tipo: 'parroquia', codigo: '090113', nombre:'URDANETA', sup: '0901'},
  {tipo: 'parroquia', codigo: '090114', nombre:'XIMENA', sup: '0901'},
  {tipo: 'parroquia', codigo: '090115', nombre:'PASCUALES', sup: '0901'},
  {tipo: 'parroquia', codigo: '090152', nombre:'JUAN GÓMEZ RENDÓN', sup: '0901'},
  {tipo: 'parroquia', codigo: '090153', nombre:'MORRO', sup: '0901'},
  {tipo: 'parroquia', codigo: '090156', nombre:'POSORJA', sup: '0901'},
  {tipo: 'parroquia', codigo: '090157', nombre:'PUNÁ', sup: '0901'},
  {tipo: 'parroquia', codigo: '090158', nombre:'TENGUEL', sup: '0901'},
  {tipo: 'parroquia', codigo: '090250', nombre:'ALFREDO BAQUERIZO MORENO (JUJÁN)', sup: '0902'},
  {tipo: 'parroquia', codigo: '090350', nombre:'BALAO', sup: '0903'},
  {tipo: 'parroquia', codigo: '090450', nombre:'BALZAR', sup: '0904'},
  {tipo: 'parroquia', codigo: '090550', nombre:'COLIMES', sup: '0905'},
  {tipo: 'parroquia', codigo: '090551', nombre:'SAN JACINTO', sup: '0905'},
  {tipo: 'parroquia', codigo: '090601', nombre:'DAULE', sup: '0906'},
  {tipo: 'parroquia', codigo: '090602', nombre:'LA AURORA (SATÉLITE)', sup: '0906'},
  {tipo: 'parroquia', codigo: '090603', nombre:'BANIFE', sup: '0906'},
  {tipo: 'parroquia', codigo: '090604', nombre:'EMILIANO CAICEDO MARCOS', sup: '0906'},
  {tipo: 'parroquia', codigo: '090605', nombre:'MAGRO', sup: '0906'},
  {tipo: 'parroquia', codigo: '090606', nombre:'PADRE JUAN BAUTISTA AGUIRRE', sup: '0906'},
  {tipo: 'parroquia', codigo: '090607', nombre:'SANTA CLARA', sup: '0906'},
  {tipo: 'parroquia', codigo: '090608', nombre:'VICENTE PIEDRAHITA', sup: '0906'},
  {tipo: 'parroquia', codigo: '090652', nombre:'JUAN BAUTISTA AGUIRRE', sup: '0906'},
  {tipo: 'parroquia', codigo: '090653', nombre:'LAUREL', sup: '0906'},
  {tipo: 'parroquia', codigo: '090654', nombre:'LIMONAL', sup: '0906'},
  {tipo: 'parroquia', codigo: '090656', nombre:'LOS LOJAS', sup: '0906'},
  {tipo: 'parroquia', codigo: '090701', nombre:'ELOY ALFARO (DURÁN)', sup: '0907'},
  {tipo: 'parroquia', codigo: '090702', nombre:'EL RECREO', sup: '0907'},
  {tipo: 'parroquia', codigo: '090703', nombre:'DIVINO NIÑO', sup: '0907'},
  {tipo: 'parroquia', codigo: '090850', nombre:'VELASCO IBARRA', sup: '0908'},
  {tipo: 'parroquia', codigo: '090851', nombre:'GUAYAS', sup: '0908'},
  {tipo: 'parroquia', codigo: '090852', nombre:'EL ROSARIO', sup: '0908'},
  {tipo: 'parroquia', codigo: '090950', nombre:'EL TRIUNFO', sup: '0909'},
  {tipo: 'parroquia', codigo: '091001', nombre:'CAMILO ANDRADE', sup: '0910'},
  {tipo: 'parroquia', codigo: '091002', nombre:'ELOY ALFARO', sup: '0910'},
  {tipo: 'parroquia', codigo: '091003', nombre:'CHIRIJOS', sup: '0910'},
  {tipo: 'parroquia', codigo: '091004', nombre:'CORONEL ENRIQUE VALDEZ', sup: '0910'},
  {tipo: 'parroquia', codigo: '091005', nombre:'ROSA MARÍA', sup: '0910'},
  {tipo: 'parroquia', codigo: '091006', nombre:'JOSÉ MARÍA VELASCO IBARRA', sup: '0910'},
  {tipo: 'parroquia', codigo: '091007', nombre:'VICENTE ROCAFUERTE', sup: '0910'},
  {tipo: 'parroquia', codigo: '091008', nombre:'ERNESTO SEMINARIO', sup: '0910'},
  {tipo: 'parroquia', codigo: '091009', nombre:'LAS PIÑAS', sup: '0910'},
  {tipo: 'parroquia', codigo: '091051', nombre:'CHOBO', sup: '0910'},
  {tipo: 'parroquia', codigo: '091053', nombre:'MARISCAL SUCRE', sup: '0910'},
  {tipo: 'parroquia', codigo: '091054', nombre:'ROBERTO ASTUDILLO', sup: '0910'},
  {tipo: 'parroquia', codigo: '091150', nombre:'NARANJAL', sup: '0911'},
  {tipo: 'parroquia', codigo: '091151', nombre:'JESÚS MARÍA', sup: '0911'},
  {tipo: 'parroquia', codigo: '091152', nombre:'SAN CARLOS', sup: '0911'},
  {tipo: 'parroquia', codigo: '091153', nombre:'SANTA ROSA DE FLANDES', sup: '0911'},
  {tipo: 'parroquia', codigo: '091154', nombre:'TAURA', sup: '0911'},
  {tipo: 'parroquia', codigo: '091250', nombre:'NARANJITO', sup: '0912'},
  {tipo: 'parroquia', codigo: '091350', nombre:'PALESTINA', sup: '0913'},
  {tipo: 'parroquia', codigo: '091450', nombre:'PEDRO CARBO', sup: '0914'},
  {tipo: 'parroquia', codigo: '091451', nombre:'VALLE DE LA VIRGEN', sup: '0914'},
  {tipo: 'parroquia', codigo: '091452', nombre:'SABANILLA', sup: '0914'},
  {tipo: 'parroquia', codigo: '091601', nombre:'SAMBORONDÓN', sup: '0916'},
  {tipo: 'parroquia', codigo: '091602', nombre:'LA PUNTILLA (SATÉLITE)', sup: '0916'},
  {tipo: 'parroquia', codigo: '091651', nombre:'TARIFA', sup: '0916'},
  {tipo: 'parroquia', codigo: '091850', nombre:'SANTA LUCÍA', sup: '0918'},
  {tipo: 'parroquia', codigo: '091901', nombre:'BOCANA', sup: '0919'},
  {tipo: 'parroquia', codigo: '091902', nombre:'CANDILEJOS', sup: '0919'},
  {tipo: 'parroquia', codigo: '091903', nombre:'CENTRAL', sup: '0919'},
  {tipo: 'parroquia', codigo: '091904', nombre:'PARAÍSO', sup: '0919'},
  {tipo: 'parroquia', codigo: '091905', nombre:'SAN MATEO', sup: '0919'},
  {tipo: 'parroquia', codigo: '091951', nombre:'GENERAL VERNAZA', sup: '0919'},
  {tipo: 'parroquia', codigo: '091952', nombre:'LA VICTORIA', sup: '0919'},
  {tipo: 'parroquia', codigo: '091953', nombre:'JUNQUILLAL', sup: '0919'},
  {tipo: 'parroquia', codigo: '092050', nombre:'SAN JACINTO DE YAGUACHI', sup: '0920'},
  {tipo: 'parroquia', codigo: '092053', nombre:'GENERAL PEDRO J. MONTERO', sup: '0920'},
  {tipo: 'parroquia', codigo: '092055', nombre:'YAGUACHI VIEJO', sup: '0920'},
  {tipo: 'parroquia', codigo: '092056', nombre:'VIRGEN DE FÁTIMA', sup: '0920'},
  {tipo: 'parroquia', codigo: '092150', nombre:'GENERAL VILLAMIL', sup: '0921'},
  {tipo: 'parroquia', codigo: '092250', nombre:'SIMÓN BOLÍVAR', sup: '0922'},
  {tipo: 'parroquia', codigo: '092251', nombre:'CORONEL LORENZO DE GARAYCOA', sup: '0922'},
  {tipo: 'parroquia', codigo: '092350', nombre:'CORONEL MARCELINO MARIDUEÑA', sup: '0923'},
  {tipo: 'parroquia', codigo: '092450', nombre:'LOMAS DE SARGENTILLO', sup: '0924'},
  {tipo: 'parroquia', codigo: '092550', nombre:'NARCISA DE JESÚS', sup: '0925'},
  {tipo: 'parroquia', codigo: '092750', nombre:'GENERAL ANTONIO ELIZALDE', sup: '0927'},
  {tipo: 'parroquia', codigo: '092850', nombre:'ISIDRO AYORA', sup: '0928'},
  {tipo: 'parroquia', codigo: '100101', nombre:'CARANQUI', sup: '1001'},
  {tipo: 'parroquia', codigo: '100102', nombre:'GUAYAQUIL DE ALPACHACA', sup: '1001'},
  {tipo: 'parroquia', codigo: '100103', nombre:'SAGRARIO', sup: '1001'},
  {tipo: 'parroquia', codigo: '100104', nombre:'SAN FRANCISCO', sup: '1001'},
  {tipo: 'parroquia', codigo: '100105', nombre:'LA DOLOROSA DEL PRIORATO', sup: '1001'},
  {tipo: 'parroquia', codigo: '100151', nombre:'AMBUQUÍ', sup: '1001'},
  {tipo: 'parroquia', codigo: '100152', nombre:'ANGOCHAGUA', sup: '1001'},
  {tipo: 'parroquia', codigo: '100153', nombre:'CAROLINA', sup: '1001'},
  {tipo: 'parroquia', codigo: '100154', nombre:'LA ESPERANZA', sup: '1001'},
  {tipo: 'parroquia', codigo: '100155', nombre:'LITA', sup: '1001'},
  {tipo: 'parroquia', codigo: '100156', nombre:'SALINAS', sup: '1001'},
  {tipo: 'parroquia', codigo: '100157', nombre:'SAN ANTONIO', sup: '1001'},
  {tipo: 'parroquia', codigo: '100201', nombre:'ANDRADE MARÍN  (LOURDES) ', sup: '1002'},
  {tipo: 'parroquia', codigo: '100202', nombre:'ATUNTAQUI', sup: '1002'},
  {tipo: 'parroquia', codigo: '100251', nombre:'IMBAYA', sup: '1002'},
  {tipo: 'parroquia', codigo: '100252', nombre:'SAN FRANCISCO DE NATABUELA', sup: '1002'},
  {tipo: 'parroquia', codigo: '100253', nombre:'SAN JOSÉ DE CHALTURA', sup: '1002'},
  {tipo: 'parroquia', codigo: '100254', nombre:'SAN ROQUE', sup: '1002'},
  {tipo: 'parroquia', codigo: '100301', nombre:'SAGRARIO', sup: '1003'},
  {tipo: 'parroquia', codigo: '100302', nombre:'SAN FRANCISCO', sup: '1003'},
  {tipo: 'parroquia', codigo: '100351', nombre:'APUELA', sup: '1003'},
  {tipo: 'parroquia', codigo: '100352', nombre:'GARCÍA MORENO', sup: '1003'},
  {tipo: 'parroquia', codigo: '100353', nombre:'IMANTAG', sup: '1003'},
  {tipo: 'parroquia', codigo: '100354', nombre:'PEÑAHERRERA', sup: '1003'},
  {tipo: 'parroquia', codigo: '100355', nombre:'PLAZA GUTIÉRREZ', sup: '1003'},
  {tipo: 'parroquia', codigo: '100356', nombre:'QUIROGA', sup: '1003'},
  {tipo: 'parroquia', codigo: '100357', nombre:'SEIS DE JULIO DE CUELLAJE', sup: '1003'},
  {tipo: 'parroquia', codigo: '100358', nombre:'VACAS GALINDO', sup: '1003'},
  {tipo: 'parroquia', codigo: '100401', nombre:'JORDÁN', sup: '1004'},
  {tipo: 'parroquia', codigo: '100402', nombre:'SAN LUIS', sup: '1004'},
  {tipo: 'parroquia', codigo: '100451', nombre:'DR. MIGUEL EGAS CABEZAS', sup: '1004'},
  {tipo: 'parroquia', codigo: '100452', nombre:'EUGENIO ESPEJO', sup: '1004'},
  {tipo: 'parroquia', codigo: '100453', nombre:'GONZÁLEZ SUÁREZ', sup: '1004'},
  {tipo: 'parroquia', codigo: '100454', nombre:'PATAQUÍ', sup: '1004'},
  {tipo: 'parroquia', codigo: '100455', nombre:'SAN JOSÉ DE QUICHINCHE', sup: '1004'},
  {tipo: 'parroquia', codigo: '100456', nombre:'SAN JUAN DE ILUMÁN', sup: '1004'},
  {tipo: 'parroquia', codigo: '100457', nombre:'SAN PABLO', sup: '1004'},
  {tipo: 'parroquia', codigo: '100458', nombre:'SAN RAFAEL', sup: '1004'},
  {tipo: 'parroquia', codigo: '100459', nombre:'SELVA ALEGRE', sup: '1004'},
  {tipo: 'parroquia', codigo: '100550', nombre:'PIMAMPIRO', sup: '1005'},
  {tipo: 'parroquia', codigo: '100551', nombre:'CHUGÁ', sup: '1005'},
  {tipo: 'parroquia', codigo: '100552', nombre:'MARIANO ACOSTA', sup: '1005'},
  {tipo: 'parroquia', codigo: '100553', nombre:'SAN FRANCISCO DE SIGSIPAMBA', sup: '1005'},
  {tipo: 'parroquia', codigo: '100650', nombre:'URCUQUÍ', sup: '1006'},
  {tipo: 'parroquia', codigo: '100651', nombre:'CAHUASQUÍ', sup: '1006'},
  {tipo: 'parroquia', codigo: '100652', nombre:'LA MERCED DE BUENOS AIRES', sup: '1006'},
  {tipo: 'parroquia', codigo: '100653', nombre:'PABLO ARENAS', sup: '1006'},
  {tipo: 'parroquia', codigo: '100654', nombre:'SAN BLAS', sup: '1006'},
  {tipo: 'parroquia', codigo: '100655', nombre:'TUMBABIRO', sup: '1006'},
  {tipo: 'parroquia', codigo: '110101', nombre:'EL SAGRARIO', sup: '1101'},
  {tipo: 'parroquia', codigo: '110102', nombre:'SAN SEBASTIÁN', sup: '1101'},
  {tipo: 'parroquia', codigo: '110103', nombre:'SUCRE', sup: '1101'},
  {tipo: 'parroquia', codigo: '110104', nombre:'VALLE', sup: '1101'},
  {tipo: 'parroquia', codigo: '110105', nombre:'CARIGÁN', sup: '1101'},
  {tipo: 'parroquia', codigo: '110106', nombre:'PUNZARA', sup: '1101'},
  {tipo: 'parroquia', codigo: '110151', nombre:'CHANTACO', sup: '1101'},
  {tipo: 'parroquia', codigo: '110152', nombre:'CHUQUIRIBAMBA', sup: '1101'},
  {tipo: 'parroquia', codigo: '110153', nombre:'EL CISNE', sup: '1101'},
  {tipo: 'parroquia', codigo: '110154', nombre:'GUALEL', sup: '1101'},
  {tipo: 'parroquia', codigo: '110155', nombre:'JIMBILLA', sup: '1101'},
  {tipo: 'parroquia', codigo: '110156', nombre:'MALACATOS', sup: '1101'},
  {tipo: 'parroquia', codigo: '110157', nombre:'SAN LUCAS', sup: '1101'},
  {tipo: 'parroquia', codigo: '110158', nombre:'SAN PEDRO DE VILCABAMBA', sup: '1101'},
  {tipo: 'parroquia', codigo: '110159', nombre:'SANTIAGO', sup: '1101'},
  {tipo: 'parroquia', codigo: '110160', nombre:'TAQUIL', sup: '1101'},
  {tipo: 'parroquia', codigo: '110161', nombre:'VILCABAMBA', sup: '1101'},
  {tipo: 'parroquia', codigo: '110162', nombre:'YANGANA', sup: '1101'},
  {tipo: 'parroquia', codigo: '110163', nombre:'QUINARA', sup: '1101'},
  {tipo: 'parroquia', codigo: '110201', nombre:'CARIAMANGA', sup: '1102'},
  {tipo: 'parroquia', codigo: '110202', nombre:'CHILE', sup: '1102'},
  {tipo: 'parroquia', codigo: '110203', nombre:'SAN VICENTE', sup: '1102'},
  {tipo: 'parroquia', codigo: '110251', nombre:'COLAISACA', sup: '1102'},
  {tipo: 'parroquia', codigo: '110252', nombre:'EL LUCERO', sup: '1102'},
  {tipo: 'parroquia', codigo: '110253', nombre:'UTUANA', sup: '1102'},
  {tipo: 'parroquia', codigo: '110254', nombre:'SANGUILLÍN', sup: '1102'},
  {tipo: 'parroquia', codigo: '110301', nombre:'CATAMAYO', sup: '1103'},
  {tipo: 'parroquia', codigo: '110302', nombre:'SAN JOSÉ', sup: '1103'},
  {tipo: 'parroquia', codigo: '110351', nombre:'EL TAMBO', sup: '1103'},
  {tipo: 'parroquia', codigo: '110352', nombre:'GUAYQUICHUMA', sup: '1103'},
  {tipo: 'parroquia', codigo: '110353', nombre:'SAN PEDRO DE LA BENDITA', sup: '1103'},
  {tipo: 'parroquia', codigo: '110354', nombre:'ZAMBI', sup: '1103'},
  {tipo: 'parroquia', codigo: '110450', nombre:'CELICA', sup: '1104'},
  {tipo: 'parroquia', codigo: '110451', nombre:'CRUZPAMBA', sup: '1104'},
  {tipo: 'parroquia', codigo: '110455', nombre:'PÓZUL', sup: '1104'},
  {tipo: 'parroquia', codigo: '110456', nombre:'SABANILLA', sup: '1104'},
  {tipo: 'parroquia', codigo: '110457', nombre:'TENIENTE MAXIMILIANO RODRÍGUEZ LOAIZA', sup: '1104'},
  {tipo: 'parroquia', codigo: '110550', nombre:'CHAGUARPAMBA', sup: '1105'},
  {tipo: 'parroquia', codigo: '110551', nombre:'BUENAVISTA', sup: '1105'},
  {tipo: 'parroquia', codigo: '110552', nombre:'EL ROSARIO', sup: '1105'},
  {tipo: 'parroquia', codigo: '110553', nombre:'SANTA RUFINA', sup: '1105'},
  {tipo: 'parroquia', codigo: '110554', nombre:'AMARILLOS', sup: '1105'},
  {tipo: 'parroquia', codigo: '110650', nombre:'AMALUZA', sup: '1106'},
  {tipo: 'parroquia', codigo: '110651', nombre:'BELLAVISTA', sup: '1106'},
  {tipo: 'parroquia', codigo: '110652', nombre:'JIMBURA', sup: '1106'},
  {tipo: 'parroquia', codigo: '110653', nombre:'SANTA TERESITA', sup: '1106'},
  {tipo: 'parroquia', codigo: '110654', nombre:'27 DE ABRIL', sup: '1106'},
  {tipo: 'parroquia', codigo: '110655', nombre:'EL INGENIO', sup: '1106'},
  {tipo: 'parroquia', codigo: '110656', nombre:'EL AIRO', sup: '1106'},
  {tipo: 'parroquia', codigo: '110750', nombre:'GONZANAMÁ', sup: '1107'},
  {tipo: 'parroquia', codigo: '110751', nombre:'CHANGAIMINA', sup: '1107'},
  {tipo: 'parroquia', codigo: '110753', nombre:'NAMBACOLA', sup: '1107'},
  {tipo: 'parroquia', codigo: '110754', nombre:'PURUNUMA', sup: '1107'},
  {tipo: 'parroquia', codigo: '110756', nombre:'SACAPALCA', sup: '1107'},
  {tipo: 'parroquia', codigo: '110801', nombre:'GENERAL ELOY ALFARO (SAN SEBASTIÁN) ', sup: '1108'},
  {tipo: 'parroquia', codigo: '110802', nombre:'MACARÁ  (MANUEL ENRIQUE RENGEL SUQUILANDA)', sup: '1108'},
  {tipo: 'parroquia', codigo: '110851', nombre:'LARAMA', sup: '1108'},
  {tipo: 'parroquia', codigo: '110852', nombre:'LA VICTORIA', sup: '1108'},
  {tipo: 'parroquia', codigo: '110853', nombre:'SABIANGO', sup: '1108'},
  {tipo: 'parroquia', codigo: '110901', nombre:'CATACOCHA', sup: '1109'},
  {tipo: 'parroquia', codigo: '110902', nombre:'LOURDES', sup: '1109'},
  {tipo: 'parroquia', codigo: '110951', nombre:'CANGONAMÁ', sup: '1109'},
  {tipo: 'parroquia', codigo: '110952', nombre:'GUACHANAMÁ', sup: '1109'},
  {tipo: 'parroquia', codigo: '110954', nombre:'LAURO GUERRERO', sup: '1109'},
  {tipo: 'parroquia', codigo: '110956', nombre:'ORIANGA', sup: '1109'},
  {tipo: 'parroquia', codigo: '110957', nombre:'SAN ANTONIO', sup: '1109'},
  {tipo: 'parroquia', codigo: '110958', nombre:'CASANGA', sup: '1109'},
  {tipo: 'parroquia', codigo: '110959', nombre:'YAMANA', sup: '1109'},
  {tipo: 'parroquia', codigo: '111050', nombre:'ALAMOR', sup: '1110'},
  {tipo: 'parroquia', codigo: '111051', nombre:'CIANO', sup: '1110'},
  {tipo: 'parroquia', codigo: '111052', nombre:'EL ARENAL', sup: '1110'},
  {tipo: 'parroquia', codigo: '111053', nombre:'EL LIMO', sup: '1110'},
  {tipo: 'parroquia', codigo: '111054', nombre:'MERCADILLO', sup: '1110'},
  {tipo: 'parroquia', codigo: '111055', nombre:'VICENTINO', sup: '1110'},
  {tipo: 'parroquia', codigo: '111150', nombre:'SARAGURO', sup: '1111'},
  {tipo: 'parroquia', codigo: '111151', nombre:'EL PARAÍSO DE CELEN', sup: '1111'},
  {tipo: 'parroquia', codigo: '111152', nombre:'EL TABLÓN', sup: '1111'},
  {tipo: 'parroquia', codigo: '111153', nombre:'LLUZHAPA', sup: '1111'},
  {tipo: 'parroquia', codigo: '111154', nombre:'MANÚ', sup: '1111'},
  {tipo: 'parroquia', codigo: '111155', nombre:'SAN ANTONIO DE QUMBE', sup: '1111'},
  {tipo: 'parroquia', codigo: '111156', nombre:'SAN PABLO DE TENTA', sup: '1111'},
  {tipo: 'parroquia', codigo: '111157', nombre:'SAN SEBASTIÁN DE YÚLUC', sup: '1111'},
  {tipo: 'parroquia', codigo: '111158', nombre:'SELVA ALEGRE', sup: '1111'},
  {tipo: 'parroquia', codigo: '111159', nombre:'URDANETA', sup: '1111'},
  {tipo: 'parroquia', codigo: '111160', nombre:'SUMAYPAMBA', sup: '1111'},
  {tipo: 'parroquia', codigo: '111250', nombre:'SOZORANGA', sup: '1112'},
  {tipo: 'parroquia', codigo: '111251', nombre:'NUEVA FÁTIMA', sup: '1112'},
  {tipo: 'parroquia', codigo: '111252', nombre:'TACAMOROS', sup: '1112'},
  {tipo: 'parroquia', codigo: '111350', nombre:'ZAPOTILLO', sup: '1113'},
  {tipo: 'parroquia', codigo: '111351', nombre:'MANGAHURCO', sup: '1113'},
  {tipo: 'parroquia', codigo: '111352', nombre:'GARZAREAL', sup: '1113'},
  {tipo: 'parroquia', codigo: '111353', nombre:'LIMONES', sup: '1113'},
  {tipo: 'parroquia', codigo: '111354', nombre:'PALETILLAS', sup: '1113'},
  {tipo: 'parroquia', codigo: '111355', nombre:'BOLASPAMBA', sup: '1113'},
  {tipo: 'parroquia', codigo: '111356', nombre:'CAZADEROS', sup: '1113'},
  {tipo: 'parroquia', codigo: '111450', nombre:'PINDAL', sup: '1114'},
  {tipo: 'parroquia', codigo: '111451', nombre:'CHAQUINAL', sup: '1114'},
  {tipo: 'parroquia', codigo: '111452', nombre:'12 DE DICIEMBRE', sup: '1114'},
  {tipo: 'parroquia', codigo: '111453', nombre:'MILAGROS', sup: '1114'},
  {tipo: 'parroquia', codigo: '111550', nombre:'QUILANGA', sup: '1115'},
  {tipo: 'parroquia', codigo: '111551', nombre:'FUNDOCHAMBA', sup: '1115'},
  {tipo: 'parroquia', codigo: '111552', nombre:'SAN ANTONIO DE LAS ARADAS', sup: '1115'},
  {tipo: 'parroquia', codigo: '111650', nombre:'OLMEDO', sup: '1116'},
  {tipo: 'parroquia', codigo: '111651', nombre:'LA TINGUE', sup: '1116'},
  {tipo: 'parroquia', codigo: '120101', nombre:'CLEMENTE BAQUERIZO', sup: '1201'},
  {tipo: 'parroquia', codigo: '120102', nombre:'DOCTOR CAMILO PONCE ', sup: '1201'},
  {tipo: 'parroquia', codigo: '120103', nombre:'BARREIRO', sup: '1201'},
  {tipo: 'parroquia', codigo: '120104', nombre:'EL SALTO', sup: '1201'},
  {tipo: 'parroquia', codigo: '120152', nombre:'CARACOL', sup: '1201'},
  {tipo: 'parroquia', codigo: '120153', nombre:'FEBRES CORDERO', sup: '1201'},
  {tipo: 'parroquia', codigo: '120154', nombre:'PIMOCHA', sup: '1201'},
  {tipo: 'parroquia', codigo: '120155', nombre:'LA UNIÓN', sup: '1201'},
  {tipo: 'parroquia', codigo: '120250', nombre:'BABA', sup: '1202'},
  {tipo: 'parroquia', codigo: '120251', nombre:'GUARE', sup: '1202'},
  {tipo: 'parroquia', codigo: '120252', nombre:'ISLA DE BEJUCAL', sup: '1202'},
  {tipo: 'parroquia', codigo: '120350', nombre:'MONTALVO', sup: '1203'},
  {tipo: 'parroquia', codigo: '120351', nombre:'LA ESMERALDA', sup: '1203'},
  {tipo: 'parroquia', codigo: '120450', nombre:'PUEBLOVIEJO', sup: '1204'},
  {tipo: 'parroquia', codigo: '120451', nombre:'PUERTO PECHICHE', sup: '1204'},
  {tipo: 'parroquia', codigo: '120452', nombre:'SAN JUAN', sup: '1204'},
  {tipo: 'parroquia', codigo: '120501', nombre:'QUEVEDO', sup: '1205'},
  {tipo: 'parroquia', codigo: '120502', nombre:'SAN CAMILO', sup: '1205'},
  {tipo: 'parroquia', codigo: '120504', nombre:'GUAYACÁN', sup: '1205'},
  {tipo: 'parroquia', codigo: '120505', nombre:'NICOLÁS INFANTE DÍAZ', sup: '1205'},
  {tipo: 'parroquia', codigo: '120506', nombre:'SAN CRISTÓBAL', sup: '1205'},
  {tipo: 'parroquia', codigo: '120507', nombre:'SIETE DE OCTUBRE', sup: '1205'},
  {tipo: 'parroquia', codigo: '120508', nombre:'24 DE MAYO', sup: '1205'},
  {tipo: 'parroquia', codigo: '120509', nombre:'VENUS DEL RÍO QUEVEDO', sup: '1205'},
  {tipo: 'parroquia', codigo: '120510', nombre:'VIVA ALFARO', sup: '1205'},
  {tipo: 'parroquia', codigo: '120553', nombre:'SAN CARLOS', sup: '1205'},
  {tipo: 'parroquia', codigo: '120555', nombre:'LA ESPERANZA', sup: '1205'},
  {tipo: 'parroquia', codigo: '120650', nombre:'CATARAMA', sup: '1206'},
  {tipo: 'parroquia', codigo: '120651', nombre:'RICAURTE', sup: '1206'},
  {tipo: 'parroquia', codigo: '120701', nombre:'10 DE NOVIEMBRE', sup: '1207'},
  {tipo: 'parroquia', codigo: '120702', nombre:'VENTANAS', sup: '1207'},
  {tipo: 'parroquia', codigo: '120752', nombre:'ZAPOTAL', sup: '1207'},
  {tipo: 'parroquia', codigo: '120753', nombre:'CHACARITA', sup: '1207'},
  {tipo: 'parroquia', codigo: '120754', nombre:'LOS ÁNGELES', sup: '1207'},
  {tipo: 'parroquia', codigo: '120801', nombre:'BALZAR DE VINCES', sup: '1208'},
  {tipo: 'parroquia', codigo: '120802', nombre:'VINCES CENTRAL', sup: '1208'},
  {tipo: 'parroquia', codigo: '120803', nombre:'SAN LORENZO DE VINCES', sup: '1208'},
  {tipo: 'parroquia', codigo: '120851', nombre:'ANTONIO SOTOMAYOR', sup: '1208'},
  {tipo: 'parroquia', codigo: '120950', nombre:'PALENQUE', sup: '1209'},
  {tipo: 'parroquia', codigo: '121001', nombre:'SAN JACINTO DE BUENA FE', sup: '1210'},
  {tipo: 'parroquia', codigo: '121002', nombre:'7 DE AGOSTO', sup: '1210'},
  {tipo: 'parroquia', codigo: '121003', nombre:'11 DE OCTUBRE', sup: '1210'},
  {tipo: 'parroquia', codigo: '121051', nombre:'PATRICIA PILAR', sup: '1210'},
  {tipo: 'parroquia', codigo: '121101', nombre:'VALENCIA', sup: '1211'},
  {tipo: 'parroquia', codigo: '121102', nombre:'LA UNIÓN', sup: '1211'},
  {tipo: 'parroquia', codigo: '121103', nombre:'LA NUEVA UNIÓN', sup: '1211'},
  {tipo: 'parroquia', codigo: '121250', nombre:'MOCACHE', sup: '1212'},
  {tipo: 'parroquia', codigo: '121350', nombre:'QUINSALOMA', sup: '1213'},
  {tipo: 'parroquia', codigo: '130101', nombre:'PORTOVIEJO', sup: '1301'},
  {tipo: 'parroquia', codigo: '130102', nombre:'12 DE MARZO', sup: '1301'},
  {tipo: 'parroquia', codigo: '130103', nombre:'COLÓN', sup: '1301'},
  {tipo: 'parroquia', codigo: '130104', nombre:'PICOAZÁ', sup: '1301'},
  {tipo: 'parroquia', codigo: '130105', nombre:'SAN PABLO', sup: '1301'},
  {tipo: 'parroquia', codigo: '130106', nombre:'ANDRÉS DE VERA', sup: '1301'},
  {tipo: 'parroquia', codigo: '130107', nombre:'FRANCISCO PACHECO', sup: '1301'},
  {tipo: 'parroquia', codigo: '130108', nombre:'18 DE OCTUBRE', sup: '1301'},
  {tipo: 'parroquia', codigo: '130109', nombre:'SIMÓN BOLÍVAR', sup: '1301'},
  {tipo: 'parroquia', codigo: '130151', nombre:'ABDÓN CALDERÓN', sup: '1301'},
  {tipo: 'parroquia', codigo: '130152', nombre:'ALHAJUELA', sup: '1301'},
  {tipo: 'parroquia', codigo: '130153', nombre:'CRUCITA', sup: '1301'},
  {tipo: 'parroquia', codigo: '130154', nombre:'PUEBLO NUEVO', sup: '1301'},
  {tipo: 'parroquia', codigo: '130155', nombre:'RIOCHICO', sup: '1301'},
  {tipo: 'parroquia', codigo: '130156', nombre:'SAN PLÁCIDO', sup: '1301'},
  {tipo: 'parroquia', codigo: '130157', nombre:'CHIRIJOS', sup: '1301'},
  {tipo: 'parroquia', codigo: '130250', nombre:'CALCETA', sup: '1302'},
  {tipo: 'parroquia', codigo: '130251', nombre:'MEMBRILLO', sup: '1302'},
  {tipo: 'parroquia', codigo: '130252', nombre:'QUIROGA', sup: '1302'},
  {tipo: 'parroquia', codigo: '130301', nombre:'CHONE', sup: '1303'},
  {tipo: 'parroquia', codigo: '130302', nombre:'SANTA RITA', sup: '1303'},
  {tipo: 'parroquia', codigo: '130351', nombre:'BOYACÁ', sup: '1303'},
  {tipo: 'parroquia', codigo: '130352', nombre:'CANUTO', sup: '1303'},
  {tipo: 'parroquia', codigo: '130353', nombre:'CONVENTO', sup: '1303'},
  {tipo: 'parroquia', codigo: '130354', nombre:'CHIBUNGA', sup: '1303'},
  {tipo: 'parroquia', codigo: '130355', nombre:'ELOY ALFARO', sup: '1303'},
  {tipo: 'parroquia', codigo: '130356', nombre:'RICAURTE', sup: '1303'},
  {tipo: 'parroquia', codigo: '130357', nombre:'SAN ANTONIO', sup: '1303'},
  {tipo: 'parroquia', codigo: '130401', nombre:'EL CARMEN', sup: '1304'},
  {tipo: 'parroquia', codigo: '130402', nombre:'4 DE DICIEMBRE', sup: '1304'},
  {tipo: 'parroquia', codigo: '130451', nombre:'WILFRIDO LOOR MOREIRA', sup: '1304'},
  {tipo: 'parroquia', codigo: '130452', nombre:'SAN PEDRO DE SUMA', sup: '1304'},
  {tipo: 'parroquia', codigo: '130453', nombre:'SANTA MARÍA', sup: '1304'},
  {tipo: 'parroquia', codigo: '130454', nombre:'EL PARAÍSO LA 14', sup: '1304'},
  {tipo: 'parroquia', codigo: '130550', nombre:'FLAVIO ALFARO', sup: '1305'},
  {tipo: 'parroquia', codigo: '130551', nombre:'SAN FRANCISCO DE NOVILLO', sup: '1305'},
  {tipo: 'parroquia', codigo: '130552', nombre:'ZAPALLO', sup: '1305'},
  {tipo: 'parroquia', codigo: '130601', nombre:'DOCTOR MIGUEL MORÁN LUCIO ', sup: '1306'},
  {tipo: 'parroquia', codigo: '130602', nombre:'MANUEL INOCENCIO PARRALES Y GUALE', sup: '1306'},
  {tipo: 'parroquia', codigo: '130603', nombre:'SAN LORENZO DE JIPIJAPA', sup: '1306'},
  {tipo: 'parroquia', codigo: '130651', nombre:'AMÉRICA', sup: '1306'},
  {tipo: 'parroquia', codigo: '130652', nombre:'EL ANEGADO', sup: '1306'},
  {tipo: 'parroquia', codigo: '130653', nombre:'JULCUY', sup: '1306'},
  {tipo: 'parroquia', codigo: '130654', nombre:'LA UNIÓN', sup: '1306'},
  {tipo: 'parroquia', codigo: '130656', nombre:'MEMBRILLAL', sup: '1306'},
  {tipo: 'parroquia', codigo: '130657', nombre:'PEDRO PABLO GÓMEZ', sup: '1306'},
  {tipo: 'parroquia', codigo: '130658', nombre:'PUERTO CAYO', sup: '1306'},
  {tipo: 'parroquia', codigo: '130750', nombre:'JUNÍN', sup: '1307'},
  {tipo: 'parroquia', codigo: '130801', nombre:'LOS ESTEROS', sup: '1308'},
  {tipo: 'parroquia', codigo: '130802', nombre:'MANTA', sup: '1308'},
  {tipo: 'parroquia', codigo: '130803', nombre:'SAN MATEO', sup: '1308'},
  {tipo: 'parroquia', codigo: '130804', nombre:'TARQUI', sup: '1308'},
  {tipo: 'parroquia', codigo: '130805', nombre:'ELOY ALFARO', sup: '1308'},
  {tipo: 'parroquia', codigo: '130851', nombre:'SAN LORENZO', sup: '1308'},
  {tipo: 'parroquia', codigo: '130852', nombre:'SANTA MARIANITA', sup: '1308'},
  {tipo: 'parroquia', codigo: '130901', nombre:'ANÍBAL SAN ANDRÉS', sup: '1309'},
  {tipo: 'parroquia', codigo: '130902', nombre:'MONTECRISTI', sup: '1309'},
  {tipo: 'parroquia', codigo: '130903', nombre:'EL COLORADO', sup: '1309'},
  {tipo: 'parroquia', codigo: '130904', nombre:'GENERAL ELOY ALFARO', sup: '1309'},
  {tipo: 'parroquia', codigo: '130905', nombre:'LEONIDAS PROAÑO', sup: '1309'},
  {tipo: 'parroquia', codigo: '130952', nombre:'LA PILA', sup: '1309'},
  {tipo: 'parroquia', codigo: '131050', nombre:'PAJÁN', sup: '1310'},
  {tipo: 'parroquia', codigo: '131051', nombre:'CAMPOZANO', sup: '1310'},
  {tipo: 'parroquia', codigo: '131052', nombre:'CASCOL', sup: '1310'},
  {tipo: 'parroquia', codigo: '131053', nombre:'GUALE', sup: '1310'},
  {tipo: 'parroquia', codigo: '131054', nombre:'LASCANO', sup: '1310'},
  {tipo: 'parroquia', codigo: '131150', nombre:'PICHINCHA', sup: '1311'},
  {tipo: 'parroquia', codigo: '131151', nombre:'BARRAGANETE', sup: '1311'},
  {tipo: 'parroquia', codigo: '131152', nombre:'SAN SEBASTIÁN', sup: '1311'},
  {tipo: 'parroquia', codigo: '131250', nombre:'ROCAFUERTE', sup: '1312'},
  {tipo: 'parroquia', codigo: '131301', nombre:'SANTA ANA', sup: '1313'},
  {tipo: 'parroquia', codigo: '131302', nombre:'LODANA', sup: '1313'},
  {tipo: 'parroquia', codigo: '131351', nombre:'AYACUCHO', sup: '1313'},
  {tipo: 'parroquia', codigo: '131352', nombre:'HONORATO VÁSQUEZ', sup: '1313'},
  {tipo: 'parroquia', codigo: '131353', nombre:'LA UNIÓN', sup: '1313'},
  {tipo: 'parroquia', codigo: '131355', nombre:'SAN PABLO', sup: '1313'},
  {tipo: 'parroquia', codigo: '131401', nombre:'BAHÍA DE CARÁQUEZ', sup: '1314'},
  {tipo: 'parroquia', codigo: '131402', nombre:'LEONIDAS PLAZA GUTIÉRREZ', sup: '1314'},
  {tipo: 'parroquia', codigo: '131453', nombre:'CHARAPOTÓ', sup: '1314'},
  {tipo: 'parroquia', codigo: '131457', nombre:'SAN ISIDRO', sup: '1314'},
  {tipo: 'parroquia', codigo: '131550', nombre:'TOSAGUA', sup: '1315'},
  {tipo: 'parroquia', codigo: '131551', nombre:'BACHILLERO', sup: '1315'},
  {tipo: 'parroquia', codigo: '131552', nombre:'ÁNGEL PEDRO GILER', sup: '1315'},
  {tipo: 'parroquia', codigo: '131650', nombre:'SUCRE', sup: '1316'},
  {tipo: 'parroquia', codigo: '131651', nombre:'BELLAVISTA', sup: '1316'},
  {tipo: 'parroquia', codigo: '131652', nombre:'NOBOA', sup: '1316'},
  {tipo: 'parroquia', codigo: '131653', nombre:'ARQUITECTO SIXTO DURÁN BALLÉN', sup: '1316'},
  {tipo: 'parroquia', codigo: '131750', nombre:'PEDERNALES', sup: '1317'},
  {tipo: 'parroquia', codigo: '131751', nombre:'COJIMÍES', sup: '1317'},
  {tipo: 'parroquia', codigo: '131752', nombre:'DIEZ DE AGOSTO', sup: '1317'},
  {tipo: 'parroquia', codigo: '131753', nombre:'ATAHUALPA', sup: '1317'},
  {tipo: 'parroquia', codigo: '131850', nombre:'OLMEDO', sup: '1318'},
  {tipo: 'parroquia', codigo: '131950', nombre:'PUERTO LÓPEZ', sup: '1319'},
  {tipo: 'parroquia', codigo: '131951', nombre:'MACHALILLA', sup: '1319'},
  {tipo: 'parroquia', codigo: '131952', nombre:'SALANGO', sup: '1319'},
  {tipo: 'parroquia', codigo: '132050', nombre:'JAMA', sup: '1320'},
  {tipo: 'parroquia', codigo: '132150', nombre:'JARAMIJÓ', sup: '1321'},
  {tipo: 'parroquia', codigo: '132250', nombre:'SAN VICENTE', sup: '1322'},
  {tipo: 'parroquia', codigo: '132251', nombre:'CANOA', sup: '1322'},
  {tipo: 'parroquia', codigo: '140150', nombre:'MACAS', sup: '1401'},
  {tipo: 'parroquia', codigo: '140151', nombre:'ALSHI', sup: '1401'},
  {tipo: 'parroquia', codigo: '140153', nombre:'GENERAL PROAÑO', sup: '1401'},
  {tipo: 'parroquia', codigo: '140156', nombre:'SAN ISIDRO', sup: '1401'},
  {tipo: 'parroquia', codigo: '140157', nombre:'SEVILLA DON BOSCO', sup: '1401'},
  {tipo: 'parroquia', codigo: '140158', nombre:'SINAÍ', sup: '1401'},
  {tipo: 'parroquia', codigo: '140160', nombre:'ZUÑA', sup: '1401'},
  {tipo: 'parroquia', codigo: '140162', nombre:'CUCHAENTZA', sup: '1401'},
  {tipo: 'parroquia', codigo: '140164', nombre:'RÍO BLANCO', sup: '1401'},
  {tipo: 'parroquia', codigo: '140201', nombre:'GUALAQUIZA', sup: '1402'},
  {tipo: 'parroquia', codigo: '140202', nombre:'MERCEDES MOLINA', sup: '1402'},
  {tipo: 'parroquia', codigo: '140251', nombre:'AMAZONAS', sup: '1402'},
  {tipo: 'parroquia', codigo: '140252', nombre:'BERMEJOS', sup: '1402'},
  {tipo: 'parroquia', codigo: '140253', nombre:'BOMBOÍZA', sup: '1402'},
  {tipo: 'parroquia', codigo: '140254', nombre:'CHIGÜINDA', sup: '1402'},
  {tipo: 'parroquia', codigo: '140255', nombre:'EL ROSARIO', sup: '1402'},
  {tipo: 'parroquia', codigo: '140256', nombre:'NUEVA TARQUI', sup: '1402'},
  {tipo: 'parroquia', codigo: '140257', nombre:'SAN MIGUEL DE CUYES', sup: '1402'},
  {tipo: 'parroquia', codigo: '140258', nombre:'EL IDEAL', sup: '1402'},
  {tipo: 'parroquia', codigo: '140350', nombre:'GENERAL LEONIDAS PLAZA GUTIÉRREZ', sup: '1403'},
  {tipo: 'parroquia', codigo: '140351', nombre:'INDANZA', sup: '1403'},
  {tipo: 'parroquia', codigo: '140353', nombre:'SAN ANTONIO', sup: '1403'},
  {tipo: 'parroquia', codigo: '140356', nombre:'SAN MIGUEL DE CONCHAY', sup: '1403'},
  {tipo: 'parroquia', codigo: '140357', nombre:'SANTA SUSANA DE CHIVIAZA', sup: '1403'},
  {tipo: 'parroquia', codigo: '140358', nombre:'YUNGANZA', sup: '1403'},
  {tipo: 'parroquia', codigo: '140450', nombre:'PALORA', sup: '1404'},
  {tipo: 'parroquia', codigo: '140451', nombre:'ARAPICOS', sup: '1404'},
  {tipo: 'parroquia', codigo: '140452', nombre:'CUMANDÁ', sup: '1404'},
  {tipo: 'parroquia', codigo: '140454', nombre:'SANGAY', sup: '1404'},
  {tipo: 'parroquia', codigo: '140455', nombre:'16 DE AGOSTO', sup: '1404'},
  {tipo: 'parroquia', codigo: '140550', nombre:'SANTIAGO DE MÉNDEZ', sup: '1405'},
  {tipo: 'parroquia', codigo: '140551', nombre:'COPAL', sup: '1405'},
  {tipo: 'parroquia', codigo: '140552', nombre:'CHUPIANZA', sup: '1405'},
  {tipo: 'parroquia', codigo: '140553', nombre:'PATUCA', sup: '1405'},
  {tipo: 'parroquia', codigo: '140554', nombre:'SAN LUIS DE EL ACHO', sup: '1405'},
  {tipo: 'parroquia', codigo: '140556', nombre:'TAYUZA', sup: '1405'},
  {tipo: 'parroquia', codigo: '140557', nombre:'SAN FRANCISCO DE CHINIMBIMI', sup: '1405'},
  {tipo: 'parroquia', codigo: '140650', nombre:'SUCÚA', sup: '1406'},
  {tipo: 'parroquia', codigo: '140651', nombre:'ASUNCIÓN', sup: '1406'},
  {tipo: 'parroquia', codigo: '140652', nombre:'HUAMBI', sup: '1406'},
  {tipo: 'parroquia', codigo: '140655', nombre:'SANTA MARIANITA DE JESÚS', sup: '1406'},
  {tipo: 'parroquia', codigo: '140750', nombre:'HUAMBOYA', sup: '1407'},
  {tipo: 'parroquia', codigo: '140751', nombre:'CHIGUAZA', sup: '1407'},
  {tipo: 'parroquia', codigo: '140850', nombre:'SAN JUAN BOSCO', sup: '1408'},
  {tipo: 'parroquia', codigo: '140851', nombre:'PAN DE AZÚCAR', sup: '1408'},
  {tipo: 'parroquia', codigo: '140852', nombre:'SAN CARLOS DE LIMÓN', sup: '1408'},
  {tipo: 'parroquia', codigo: '140853', nombre:'SAN JACINTO DE WAKAMBEIS', sup: '1408'},
  {tipo: 'parroquia', codigo: '140854', nombre:'SANTIAGO DE PANANZA', sup: '1408'},
  {tipo: 'parroquia', codigo: '140950', nombre:'TAISHA', sup: '1409'},
  {tipo: 'parroquia', codigo: '140951', nombre:'HUASAGA', sup: '1409'},
  {tipo: 'parroquia', codigo: '140952', nombre:'MACUMA', sup: '1409'},
  {tipo: 'parroquia', codigo: '140953', nombre:'TUUTINENTSA', sup: '1409'},
  {tipo: 'parroquia', codigo: '140954', nombre:'PUMPUENTSA', sup: '1409'},
  {tipo: 'parroquia', codigo: '141050', nombre:'LOGROÑO', sup: '1410'},
  {tipo: 'parroquia', codigo: '141051', nombre:'YAUPI', sup: '1410'},
  {tipo: 'parroquia', codigo: '141052', nombre:'SHIMPIS', sup: '1410'},
  {tipo: 'parroquia', codigo: '141150', nombre:'PABLO SEXTO', sup: '1411'},
  {tipo: 'parroquia', codigo: '141250', nombre:'SANTIAGO', sup: '1412'},
  {tipo: 'parroquia', codigo: '141251', nombre:'SAN JOSÉ DE MORONA', sup: '1412'},
  {tipo: 'parroquia', codigo: '150150', nombre:'TENA', sup: '1501'},
  {tipo: 'parroquia', codigo: '150151', nombre:'AHUANO', sup: '1501'},
  {tipo: 'parroquia', codigo: '150153', nombre:'CHONTAPUNTA', sup: '1501'},
  {tipo: 'parroquia', codigo: '150154', nombre:'PANO', sup: '1501'},
  {tipo: 'parroquia', codigo: '150155', nombre:'PUERTO MISAHUALLÍ', sup: '1501'},
  {tipo: 'parroquia', codigo: '150156', nombre:'PUERTO NAPO', sup: '1501'},
  {tipo: 'parroquia', codigo: '150157', nombre:'TÁLAG', sup: '1501'},
  {tipo: 'parroquia', codigo: '150158', nombre:'SAN JUAN DE MUYUNA', sup: '1501'},
  {tipo: 'parroquia', codigo: '150350', nombre:'ARCHIDONA', sup: '1503'},
  {tipo: 'parroquia', codigo: '150352', nombre:'COTUNDO', sup: '1503'},
  {tipo: 'parroquia', codigo: '150354', nombre:'SAN PABLO DE USHPAYACU', sup: '1503'},
  {tipo: 'parroquia', codigo: '150356', nombre:'HATUN SUMAKU', sup: '1503'},
  {tipo: 'parroquia', codigo: '150450', nombre:'EL CHACO', sup: '1504'},
  {tipo: 'parroquia', codigo: '150451', nombre:'GONZALO DÍAZ DE PINEDA', sup: '1504'},
  {tipo: 'parroquia', codigo: '150452', nombre:'LINARES', sup: '1504'},
  {tipo: 'parroquia', codigo: '150453', nombre:'OYACACHI', sup: '1504'},
  {tipo: 'parroquia', codigo: '150454', nombre:'SANTA ROSA', sup: '1504'},
  {tipo: 'parroquia', codigo: '150455', nombre:'SARDINAS', sup: '1504'},
  {tipo: 'parroquia', codigo: '150750', nombre:'BAEZA', sup: '1507'},
  {tipo: 'parroquia', codigo: '150751', nombre:'COSANGA', sup: '1507'},
  {tipo: 'parroquia', codigo: '150752', nombre:'CUYUJA', sup: '1507'},
  {tipo: 'parroquia', codigo: '150753', nombre:'PAPALLACTA', sup: '1507'},
  {tipo: 'parroquia', codigo: '150754', nombre:'SAN FRANCISCO DE BORJA', sup: '1507'},
  {tipo: 'parroquia', codigo: '150756', nombre:'SUMACO', sup: '1507'},
  {tipo: 'parroquia', codigo: '150950', nombre:'CARLOS JULIO AROSEMENA TOLA', sup: '1509'},
  {tipo: 'parroquia', codigo: '160150', nombre:'PUYO', sup: '1601'},
  {tipo: 'parroquia', codigo: '160152', nombre:'CANELOS', sup: '1601'},
  {tipo: 'parroquia', codigo: '160154', nombre:'DIEZ DE AGOSTO', sup: '1601'},
  {tipo: 'parroquia', codigo: '160155', nombre:'FÁTIMA', sup: '1601'},
  {tipo: 'parroquia', codigo: '160156', nombre:'MONTALVO', sup: '1601'},
  {tipo: 'parroquia', codigo: '160157', nombre:'POMONA', sup: '1601'},
  {tipo: 'parroquia', codigo: '160158', nombre:'RÍO CORRIENTES', sup: '1601'},
  {tipo: 'parroquia', codigo: '160159', nombre:'RÍO TIGRE', sup: '1601'},
  {tipo: 'parroquia', codigo: '160161', nombre:'SARAYACU', sup: '1601'},
  {tipo: 'parroquia', codigo: '160162', nombre:'SIMÓN BOLÍVAR', sup: '1601'},
  {tipo: 'parroquia', codigo: '160163', nombre:'TARQUI', sup: '1601'},
  {tipo: 'parroquia', codigo: '160164', nombre:'TENIENTE HUGO ORTIZ', sup: '1601'},
  {tipo: 'parroquia', codigo: '160165', nombre:'VERACRUZ', sup: '1601'},
  {tipo: 'parroquia', codigo: '160166', nombre:'EL TRIUNFO', sup: '1601'},
  {tipo: 'parroquia', codigo: '160250', nombre:'MERA', sup: '1602'},
  {tipo: 'parroquia', codigo: '160251', nombre:'MADRE TIERRA', sup: '1602'},
  {tipo: 'parroquia', codigo: '160252', nombre:'SHELL', sup: '1602'},
  {tipo: 'parroquia', codigo: '160350', nombre:'SANTA CLARA', sup: '1603'},
  {tipo: 'parroquia', codigo: '160351', nombre:'SAN JOSÉ', sup: '1603'},
  {tipo: 'parroquia', codigo: '160450', nombre:'ARAJUNO', sup: '1604'},
  {tipo: 'parroquia', codigo: '160451', nombre:'CURARAY', sup: '1604'},
  {tipo: 'parroquia', codigo: '170101', nombre:'BELISARIO QUEVEDO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170102', nombre:'CARCELÉN', sup: '1701'},
  {tipo: 'parroquia', codigo: '170103', nombre:'CENTRO HISTÓRICO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170104', nombre:'COCHAPAMBA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170105', nombre:'COMITÉ DEL PUEBLO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170106', nombre:'COTOCOLLAO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170107', nombre:'CHILIBULO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170108', nombre:'CHILLOGALLO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170109', nombre:'CHIMBACALLE', sup: '1701'},
  {tipo: 'parroquia', codigo: '170110', nombre:'EL CONDADO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170111', nombre:'GUAMANÍ', sup: '1701'},
  {tipo: 'parroquia', codigo: '170112', nombre:'IÑAQUITO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170113', nombre:'ITCHIMBIA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170114', nombre:'JIPIJAPA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170115', nombre:'KENNEDY', sup: '1701'},
  {tipo: 'parroquia', codigo: '170116', nombre:'LA ARGELIA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170117', nombre:'LA CONCEPCIÓN', sup: '1701'},
  {tipo: 'parroquia', codigo: '170118', nombre:'LA ECUATORIANA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170119', nombre:'LA FERROVIARIA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170120', nombre:'LA LIBERTAD', sup: '1701'},
  {tipo: 'parroquia', codigo: '170121', nombre:'LA MAGDALENA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170122', nombre:'LA MENA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170123', nombre:'MARISCAL SUCRE', sup: '1701'},
  {tipo: 'parroquia', codigo: '170124', nombre:'PONCEANO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170125', nombre:'PUENGASÍ', sup: '1701'},
  {tipo: 'parroquia', codigo: '170126', nombre:'QUITUMBE', sup: '1701'},
  {tipo: 'parroquia', codigo: '170127', nombre:'RUMIPAMBA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170128', nombre:'SAN BARTOLO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170129', nombre:'SAN ISIDRO DEL INCA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170130', nombre:'SAN JUAN', sup: '1701'},
  {tipo: 'parroquia', codigo: '170131', nombre:'SOLANDA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170132', nombre:'TURUBAMBA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170151', nombre:'ALANGASÍ', sup: '1701'},
  {tipo: 'parroquia', codigo: '170152', nombre:'AMAGUAÑA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170153', nombre:'ATAHUALPA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170154', nombre:'CALACALÍ', sup: '1701'},
  {tipo: 'parroquia', codigo: '170155', nombre:'CALDERÓN', sup: '1701'},
  {tipo: 'parroquia', codigo: '170156', nombre:'CONOCOTO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170157', nombre:'CUMBAYÁ', sup: '1701'},
  {tipo: 'parroquia', codigo: '170158', nombre:'CHAVEZPAMBA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170159', nombre:'CHECA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170160', nombre:'EL QUINCHE', sup: '1701'},
  {tipo: 'parroquia', codigo: '170161', nombre:'GUALEA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170162', nombre:'GUANGOPOLO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170163', nombre:'GUAYLLABAMBA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170164', nombre:'LA MERCED', sup: '1701'},
  {tipo: 'parroquia', codigo: '170165', nombre:'LLANO CHICO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170166', nombre:'LLOA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170168', nombre:'NANEGAL', sup: '1701'},
  {tipo: 'parroquia', codigo: '170169', nombre:'NANEGALITO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170170', nombre:'NAYÓN', sup: '1701'},
  {tipo: 'parroquia', codigo: '170171', nombre:'NONO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170172', nombre:'PACTO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170174', nombre:'PERUCHO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170175', nombre:'PIFO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170176', nombre:'PÍNTAG', sup: '1701'},
  {tipo: 'parroquia', codigo: '170177', nombre:'POMASQUI', sup: '1701'},
  {tipo: 'parroquia', codigo: '170178', nombre:'PUÉLLARO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170179', nombre:'PUEMBO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170180', nombre:'SAN ANTONIO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170181', nombre:'SAN JOSÉ DE MINAS', sup: '1701'},
  {tipo: 'parroquia', codigo: '170183', nombre:'TABABELA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170184', nombre:'TUMBACO', sup: '1701'},
  {tipo: 'parroquia', codigo: '170185', nombre:'YARUQUÍ', sup: '1701'},
  {tipo: 'parroquia', codigo: '170186', nombre:'ZÁMBIZA', sup: '1701'},
  {tipo: 'parroquia', codigo: '170202', nombre:'CAYAMBE', sup: '1702'},
  {tipo: 'parroquia', codigo: '170203', nombre:'JUAN MONTALVO', sup: '1702'},
  {tipo: 'parroquia', codigo: '170251', nombre:'ASCÁZUBI', sup: '1702'},
  {tipo: 'parroquia', codigo: '170252', nombre:'CANGAHUA', sup: '1702'},
  {tipo: 'parroquia', codigo: '170253', nombre:'OLMEDO', sup: '1702'},
  {tipo: 'parroquia', codigo: '170254', nombre:'OTÓN', sup: '1702'},
  {tipo: 'parroquia', codigo: '170255', nombre:'SANTA ROSA DE CUZUBAMBA', sup: '1702'},
  {tipo: 'parroquia', codigo: '170256', nombre:'SAN JOSÉ DE AYORA', sup: '1702'},
  {tipo: 'parroquia', codigo: '170350', nombre:'MACHACHI', sup: '1703'},
  {tipo: 'parroquia', codigo: '170351', nombre:'ALOAG', sup: '1703'},
  {tipo: 'parroquia', codigo: '170352', nombre:'ALOASÍ', sup: '1703'},
  {tipo: 'parroquia', codigo: '170353', nombre:'CUTUGLAHUA', sup: '1703'},
  {tipo: 'parroquia', codigo: '170354', nombre:'EL CHAUPI', sup: '1703'},
  {tipo: 'parroquia', codigo: '170355', nombre:'MANUEL CORNEJO ASTORGA', sup: '1703'},
  {tipo: 'parroquia', codigo: '170356', nombre:'TAMBILLO', sup: '1703'},
  {tipo: 'parroquia', codigo: '170357', nombre:'UYUMBICHO', sup: '1703'},
  {tipo: 'parroquia', codigo: '170450', nombre:'TABACUNDO', sup: '1704'},
  {tipo: 'parroquia', codigo: '170451', nombre:'LA ESPERANZA', sup: '1704'},
  {tipo: 'parroquia', codigo: '170452', nombre:'MALCHINGUÍ', sup: '1704'},
  {tipo: 'parroquia', codigo: '170453', nombre:'TOCACHI', sup: '1704'},
  {tipo: 'parroquia', codigo: '170454', nombre:'TUPIGACHI', sup: '1704'},
  {tipo: 'parroquia', codigo: '170501', nombre:'SANGOLQUÍ', sup: '1705'},
  {tipo: 'parroquia', codigo: '170502', nombre:'SAN PEDRO DE TABOADA', sup: '1705'},
  {tipo: 'parroquia', codigo: '170503', nombre:'SAN RAFAEL', sup: '1705'},
  {tipo: 'parroquia', codigo: '170504', nombre:'FAJARDO', sup: '1705'},
  {tipo: 'parroquia', codigo: '170551', nombre:'COTOGCHOA', sup: '1705'},
  {tipo: 'parroquia', codigo: '170552', nombre:'RUMIPAMBA', sup: '1705'},
  {tipo: 'parroquia', codigo: '170750', nombre:'SAN MIGUEL DE LOS BANCOS', sup: '1707'},
  {tipo: 'parroquia', codigo: '170751', nombre:'MINDO', sup: '1707'},
  {tipo: 'parroquia', codigo: '170850', nombre:'PEDRO VICENTE MALDONADO', sup: '1708'},
  {tipo: 'parroquia', codigo: '170950', nombre:'PUERTO QUITO', sup: '1709'},
  {tipo: 'parroquia', codigo: '180101', nombre:'ATOCHA – FICOA', sup: '1801'},
  {tipo: 'parroquia', codigo: '180102', nombre:'CELIANO MONGE', sup: '1801'},
  {tipo: 'parroquia', codigo: '180103', nombre:'HUACHI CHICO', sup: '1801'},
  {tipo: 'parroquia', codigo: '180104', nombre:'HUACHI LORETO', sup: '1801'},
  {tipo: 'parroquia', codigo: '180105', nombre:'LA MERCED', sup: '1801'},
  {tipo: 'parroquia', codigo: '180106', nombre:'LA PENÍNSULA', sup: '1801'},
  {tipo: 'parroquia', codigo: '180107', nombre:'MATRIZ', sup: '1801'},
  {tipo: 'parroquia', codigo: '180108', nombre:'PISHILATA', sup: '1801'},
  {tipo: 'parroquia', codigo: '180109', nombre:'SAN FRANCISCO', sup: '1801'},
  {tipo: 'parroquia', codigo: '180151', nombre:'AMBATILLO', sup: '1801'},
  {tipo: 'parroquia', codigo: '180152', nombre:'ATAHUALPA', sup: '1801'},
  {tipo: 'parroquia', codigo: '180153', nombre:'AUGUSTO N. MARTÍNEZ', sup: '1801'},
  {tipo: 'parroquia', codigo: '180154', nombre:'CONSTANTINO FERNÁNDEZ', sup: '1801'},
  {tipo: 'parroquia', codigo: '180155', nombre:'HUACHI GRANDE', sup: '1801'},
  {tipo: 'parroquia', codigo: '180156', nombre:'IZAMBA', sup: '1801'},
  {tipo: 'parroquia', codigo: '180157', nombre:'JUAN BENIGNO VELA', sup: '1801'},
  {tipo: 'parroquia', codigo: '180158', nombre:'MONTALVO', sup: '1801'},
  {tipo: 'parroquia', codigo: '180159', nombre:'PASA', sup: '1801'},
  {tipo: 'parroquia', codigo: '180160', nombre:'PICAIHUA', sup: '1801'},
  {tipo: 'parroquia', codigo: '180161', nombre:'PILAGÜÍN', sup: '1801'},
  {tipo: 'parroquia', codigo: '180162', nombre:'QUISAPINCHA', sup: '1801'},
  {tipo: 'parroquia', codigo: '180163', nombre:'SAN BARTOLOMÉ DE PINLLO', sup: '1801'},
  {tipo: 'parroquia', codigo: '180164', nombre:'SAN FERNANDO', sup: '1801'},
  {tipo: 'parroquia', codigo: '180165', nombre:'SANTA ROSA', sup: '1801'},
  {tipo: 'parroquia', codigo: '180166', nombre:'TOTORAS', sup: '1801'},
  {tipo: 'parroquia', codigo: '180167', nombre:'CUNCHIBAMBA', sup: '1801'},
  {tipo: 'parroquia', codigo: '180168', nombre:'UNAMUNCHO', sup: '1801'},
  {tipo: 'parroquia', codigo: '180250', nombre:'BAÑOS', sup: '1802'},
  {tipo: 'parroquia', codigo: '180251', nombre:'LLIGUA', sup: '1802'},
  {tipo: 'parroquia', codigo: '180252', nombre:'RÍO NEGRO', sup: '1802'},
  {tipo: 'parroquia', codigo: '180253', nombre:'RÍO VERDE', sup: '1802'},
  {tipo: 'parroquia', codigo: '180254', nombre:'ULBA', sup: '1802'},
  {tipo: 'parroquia', codigo: '180350', nombre:'CEVALLOS', sup: '1803'},
  {tipo: 'parroquia', codigo: '180450', nombre:'MOCHA', sup: '1804'},
  {tipo: 'parroquia', codigo: '180451', nombre:'PINGUILÍ', sup: '1804'},
  {tipo: 'parroquia', codigo: '180550', nombre:'PATATE', sup: '1805'},
  {tipo: 'parroquia', codigo: '180551', nombre:'EL TRIUNFO', sup: '1805'},
  {tipo: 'parroquia', codigo: '180552', nombre:'LOS ANDES', sup: '1805'},
  {tipo: 'parroquia', codigo: '180553', nombre:'SUCRE', sup: '1805'},
  {tipo: 'parroquia', codigo: '180650', nombre:'QUERO', sup: '1806'},
  {tipo: 'parroquia', codigo: '180651', nombre:'RUMIPAMBA', sup: '1806'},
  {tipo: 'parroquia', codigo: '180652', nombre:'YANAYACU-MOCHAPATA', sup: '1806'},
  {tipo: 'parroquia', codigo: '180701', nombre:'PELILEO', sup: '1807'},
  {tipo: 'parroquia', codigo: '180702', nombre:'PELILEO GRANDE', sup: '1807'},
  {tipo: 'parroquia', codigo: '180751', nombre:'BENÍTEZ', sup: '1807'},
  {tipo: 'parroquia', codigo: '180752', nombre:'BOLÍVAR', sup: '1807'},
  {tipo: 'parroquia', codigo: '180753', nombre:'COTALÓ', sup: '1807'},
  {tipo: 'parroquia', codigo: '180754', nombre:'CHIQUICHA', sup: '1807'},
  {tipo: 'parroquia', codigo: '180755', nombre:'EL ROSARIO', sup: '1807'},
  {tipo: 'parroquia', codigo: '180756', nombre:'GARCÍA MORENO', sup: '1807'},
  {tipo: 'parroquia', codigo: '180757', nombre:'GUAMBALÓ', sup: '1807'},
  {tipo: 'parroquia', codigo: '180758', nombre:'SALASACA', sup: '1807'},
  {tipo: 'parroquia', codigo: '180801', nombre:'CIUDAD NUEVA', sup: '1808'},
  {tipo: 'parroquia', codigo: '180802', nombre:'PÍLLARO', sup: '1808'},
  {tipo: 'parroquia', codigo: '180851', nombre:'BAQUERIZO MORENO', sup: '1808'},
  {tipo: 'parroquia', codigo: '180852', nombre:'EMILIO MARÍA TERÁN', sup: '1808'},
  {tipo: 'parroquia', codigo: '180853', nombre:'MARCOS ESPINEL', sup: '1808'},
  {tipo: 'parroquia', codigo: '180854', nombre:'PRESIDENTE URBINA', sup: '1808'},
  {tipo: 'parroquia', codigo: '180855', nombre:'SAN ANDRÉS', sup: '1808'},
  {tipo: 'parroquia', codigo: '180856', nombre:'SAN JOSÉ DE POALÓ', sup: '1808'},
  {tipo: 'parroquia', codigo: '180857', nombre:'SAN MIGUELITO', sup: '1808'},
  {tipo: 'parroquia', codigo: '180950', nombre:'TISALEO', sup: '1809'},
  {tipo: 'parroquia', codigo: '180951', nombre:'QUINCHICOTO', sup: '1809'},
  {tipo: 'parroquia', codigo: '190101', nombre:'EL LIMÓN', sup: '1901'},
  {tipo: 'parroquia', codigo: '190102', nombre:'ZAMORA', sup: '1901'},
  {tipo: 'parroquia', codigo: '190151', nombre:'CUMBARATZA', sup: '1901'},
  {tipo: 'parroquia', codigo: '190152', nombre:'GUADALUPE', sup: '1901'},
  {tipo: 'parroquia', codigo: '190153', nombre:'IMBANA', sup: '1901'},
  {tipo: 'parroquia', codigo: '190155', nombre:'SABANILLA', sup: '1901'},
  {tipo: 'parroquia', codigo: '190156', nombre:'TIMBARA', sup: '1901'},
  {tipo: 'parroquia', codigo: '190158', nombre:'SAN CARLOS DE LAS MINAS', sup: '1901'},
  {tipo: 'parroquia', codigo: '190250', nombre:'ZUMBA', sup: '1902'},
  {tipo: 'parroquia', codigo: '190251', nombre:'CHITO', sup: '1902'},
  {tipo: 'parroquia', codigo: '190252', nombre:'EL CHORRO', sup: '1902'},
  {tipo: 'parroquia', codigo: '190254', nombre:'LA CHONTA', sup: '1902'},
  {tipo: 'parroquia', codigo: '190256', nombre:'PUCAPAMBA', sup: '1902'},
  {tipo: 'parroquia', codigo: '190259', nombre:'SAN ANDRÉS', sup: '1902'},
  {tipo: 'parroquia', codigo: '190350', nombre:'GUAYZIMI', sup: '1903'},
  {tipo: 'parroquia', codigo: '190351', nombre:'ZURMI', sup: '1903'},
  {tipo: 'parroquia', codigo: '190352', nombre:'NUEVO PARAÍSO', sup: '1903'},
  {tipo: 'parroquia', codigo: '190353', nombre:'NANKAIS', sup: '1903'},
  {tipo: 'parroquia', codigo: '190450', nombre:'28 DE MAYO', sup: '1904'},
  {tipo: 'parroquia', codigo: '190451', nombre:'LA PAZ', sup: '1904'},
  {tipo: 'parroquia', codigo: '190452', nombre:'TUTUPALI', sup: '1904'},
  {tipo: 'parroquia', codigo: '190550', nombre:'YANTZAZA', sup: '1905'},
  {tipo: 'parroquia', codigo: '190551', nombre:'CHICAÑA', sup: '1905'},
  {tipo: 'parroquia', codigo: '190553', nombre:'LOS ENCUENTROS', sup: '1905'},
  {tipo: 'parroquia', codigo: '190650', nombre:'EL PANGUI', sup: '1906'},
  {tipo: 'parroquia', codigo: '190651', nombre:'EL GUISME', sup: '1906'},
  {tipo: 'parroquia', codigo: '190652', nombre:'PACHICUTZA', sup: '1906'},
  {tipo: 'parroquia', codigo: '190653', nombre:'TUNDAYME', sup: '1906'},
  {tipo: 'parroquia', codigo: '190750', nombre:'ZUMBI', sup: '1907'},
  {tipo: 'parroquia', codigo: '190752', nombre:'TRIUNFO DORADO', sup: '1907'},
  {tipo: 'parroquia', codigo: '190753', nombre:'PANGUINTZA', sup: '1907'},
  {tipo: 'parroquia', codigo: '190850', nombre:'PALANDA', sup: '1908'},
  {tipo: 'parroquia', codigo: '190851', nombre:'EL PORVENIR DEL CARMEN', sup: '1908'},
  {tipo: 'parroquia', codigo: '190852', nombre:'SAN FRANCISCO DEL VERGEL', sup: '1908'},
  {tipo: 'parroquia', codigo: '190853', nombre:'VALLADOLID', sup: '1908'},
  {tipo: 'parroquia', codigo: '190854', nombre:'LA CANELA', sup: '1908'},
  {tipo: 'parroquia', codigo: '190950', nombre:'PAQUISHA', sup: '1909'},
  {tipo: 'parroquia', codigo: '190951', nombre:'BELLAVISTA', sup: '1909'},
  {tipo: 'parroquia', codigo: '190952', nombre:'NUEVO QUITO', sup: '1909'},
  {tipo: 'parroquia', codigo: '200150', nombre:'PUERTO BAQUERIZO MORENO', sup: '2001'},
  {tipo: 'parroquia', codigo: '200151', nombre:'EL PROGRESO', sup: '2001'},
  {tipo: 'parroquia', codigo: '200152', nombre:'ISLA SANTA MARÍA FLOREANA', sup: '2001'},
  {tipo: 'parroquia', codigo: '200250', nombre:'PUERTO VILLAMIL', sup: '2002'},
  {tipo: 'parroquia', codigo: '200251', nombre:'TOMÁS DE BERLANGA', sup: '2002'},
  {tipo: 'parroquia', codigo: '200350', nombre:'PUERTO AYORA', sup: '2003'},
  {tipo: 'parroquia', codigo: '200351', nombre:'BELLA VISTA', sup: '2003'},
  {tipo: 'parroquia', codigo: '200352', nombre:'SANTA ROSA', sup: '2003'},
  {tipo: 'parroquia', codigo: '210150', nombre:'NUEVA LOJA', sup: '2101'},
  {tipo: 'parroquia', codigo: '210152', nombre:'DURENO', sup: '2101'},
  {tipo: 'parroquia', codigo: '210153', nombre:'GENERAL FARFÁN', sup: '2101'},
  {tipo: 'parroquia', codigo: '210155', nombre:'EL ENO', sup: '2101'},
  {tipo: 'parroquia', codigo: '210156', nombre:'PACAYACU', sup: '2101'},
  {tipo: 'parroquia', codigo: '210157', nombre:'JAMBELÍ', sup: '2101'},
  {tipo: 'parroquia', codigo: '210158', nombre:'SANTA CECILIA', sup: '2101'},
  {tipo: 'parroquia', codigo: '210160', nombre:'10 DE AGOSTO', sup: '2101'},
  {tipo: 'parroquia', codigo: '210250', nombre:'LUMBAQUÍ', sup: '2102'},
  {tipo: 'parroquia', codigo: '210251', nombre:'EL REVENTADOR', sup: '2102'},
  {tipo: 'parroquia', codigo: '210252', nombre:'GONZALO PIZARRO', sup: '2102'},
  {tipo: 'parroquia', codigo: '210254', nombre:'PUERTO LIBRE', sup: '2102'},
  {tipo: 'parroquia', codigo: '210350', nombre:'PUERTO EL CARMEN DE PUTUMAYO', sup: '2103'},
  {tipo: 'parroquia', codigo: '210351', nombre:'PALMA ROJA', sup: '2103'},
  {tipo: 'parroquia', codigo: '210352', nombre:'PUERTO BOLÍVAR', sup: '2103'},
  {tipo: 'parroquia', codigo: '210353', nombre:'PUERTO RODRÍGUEZ', sup: '2103'},
  {tipo: 'parroquia', codigo: '210354', nombre:'SANTA ELENA', sup: '2103'},
  {tipo: 'parroquia', codigo: ' ', nombre:'SANSAHUARI', sup: '2103'},
  {tipo: 'parroquia', codigo: '210450', nombre:'SHUSHUFINDI', sup: '2104'},
  {tipo: 'parroquia', codigo: '210451', nombre:'LIMONCOCHA', sup: '2104'},
  {tipo: 'parroquia', codigo: '210452', nombre:'PAÑACOCHA', sup: '2104'},
  {tipo: 'parroquia', codigo: '210453', nombre:'SAN ROQUE', sup: '2104'},
  {tipo: 'parroquia', codigo: '210454', nombre:'SAN PEDRO DE LOS COFÁNES', sup: '2104'},
  {tipo: 'parroquia', codigo: '210455', nombre:'SIETE DE JULIO', sup: '2104'},
  {tipo: 'parroquia', codigo: '210550', nombre:'LA BONITA', sup: '2105'},
  {tipo: 'parroquia', codigo: '210551', nombre:'EL PLAYÓN DE SAN FRANCISCO', sup: '2105'},
  {tipo: 'parroquia', codigo: '210552', nombre:'LA SOFÍA', sup: '2105'},
  {tipo: 'parroquia', codigo: '210553', nombre:'ROSA FLORIDA', sup: '2105'},
  {tipo: 'parroquia', codigo: '210554', nombre:'SANTA BÁRBARA', sup: '2105'},
  {tipo: 'parroquia', codigo: '210650', nombre:'EL DORADO DE CASCALES', sup: '2106'},
  {tipo: 'parroquia', codigo: '210651', nombre:'SANTA ROSA DE SUCUMBÍOS', sup: '2106'},
  {tipo: 'parroquia', codigo: '210652', nombre:'SEVILLA', sup: '2106'},
  {tipo: 'parroquia', codigo: '210653', nombre:'NUEVA TRONCAL', sup: '2106'},
  {tipo: 'parroquia', codigo: '210750', nombre:'TARAPOA', sup: '2107'},
  {tipo: 'parroquia', codigo: '210751', nombre:'CUYABENO', sup: '2107'},
  {tipo: 'parroquia', codigo: '210752', nombre:'AGUAS NEGRAS', sup: '2107'},
  {tipo: 'parroquia', codigo: '220150', nombre:'EL COCA (PUERTO FRANCISCO DE ORELLANA)', sup: '2201'},
  {tipo: 'parroquia', codigo: '220151', nombre:'DAYUMA', sup: '2201'},
  {tipo: 'parroquia', codigo: '220152', nombre:'TARACOA', sup: '2201'},
  {tipo: 'parroquia', codigo: '220153', nombre:'ALEJANDRO LABAKA', sup: '2201'},
  {tipo: 'parroquia', codigo: '220154', nombre:'EL DORADO', sup: '2201'},
  {tipo: 'parroquia', codigo: '220155', nombre:'EL EDÉN', sup: '2201'},
  {tipo: 'parroquia', codigo: '220156', nombre:'GARCÍA MORENO', sup: '2201'},
  {tipo: 'parroquia', codigo: '220157', nombre:'INÉS ARANGO', sup: '2201'},
  {tipo: 'parroquia', codigo: '220158', nombre:'LA BELLEZA', sup: '2201'},
  {tipo: 'parroquia', codigo: '220159', nombre:'NUEVO PARAÍSO', sup: '2201'},
  {tipo: 'parroquia', codigo: '220160', nombre:'SAN JOSÉ DE GUAYUSA', sup: '2201'},
  {tipo: 'parroquia', codigo: '220161', nombre:'SAN LUIS DE ARMENIA', sup: '2201'},
  {tipo: 'parroquia', codigo: '220201', nombre:'NUEVO ROCAFUERTE', sup: '2202'},
  {tipo: 'parroquia', codigo: '220202', nombre:'TIPUTINI', sup: '2202'},
  {tipo: 'parroquia', codigo: '220251', nombre:'CAPITÁN AUGUSTO RIVADENEYRA', sup: '2202'},
  {tipo: 'parroquia', codigo: '220252', nombre:'CONONACO', sup: '2202'},
  {tipo: 'parroquia', codigo: '220253', nombre:'SANTA MARÍA DE HUIRIRIMA', sup: '2202'},
  {tipo: 'parroquia', codigo: '220255', nombre:'YASUNÍ', sup: '2202'},
  {tipo: 'parroquia', codigo: '220350', nombre:'LA JOYA DE LOS SACHAS', sup: '2203'},
  {tipo: 'parroquia', codigo: '220351', nombre:'ENOKANQUI', sup: '2203'},
  {tipo: 'parroquia', codigo: '220352', nombre:'POMPEYA', sup: '2203'},
  {tipo: 'parroquia', codigo: '220353', nombre:'SAN CARLOS', sup: '2203'},
  {tipo: 'parroquia', codigo: '220354', nombre:'SAN SEBASTIÁN DEL COCA', sup: '2203'},
  {tipo: 'parroquia', codigo: '220355', nombre:'LAGO SAN PEDRO', sup: '2203'},
  {tipo: 'parroquia', codigo: '220356', nombre:'RUMIPAMBA', sup: '2203'},
  {tipo: 'parroquia', codigo: '220357', nombre:'TRES DE NOVIEMBRE', sup: '2203'},
  {tipo: 'parroquia', codigo: '220358', nombre:'UNIÓN MILAGREÑA', sup: '2203'},
  {tipo: 'parroquia', codigo: '220450', nombre:'LORETO', sup: '2204'},
  {tipo: 'parroquia', codigo: '220451', nombre:'ÁVILA', sup: '2204'},
  {tipo: 'parroquia', codigo: '220452', nombre:'PUERTO MURIALDO', sup: '2204'},
  {tipo: 'parroquia', codigo: '220453', nombre:'SAN JOSÉ DE PAYAMINO', sup: '2204'},
  {tipo: 'parroquia', codigo: '220454', nombre:'SAN JOSÉ DE DAHUANO', sup: '2204'},
  {tipo: 'parroquia', codigo: '220455', nombre:'SAN VICENTE DE HUATICOCHA', sup: '2204'},
  {tipo: 'parroquia', codigo: '230101', nombre:'ABRAHAM CALAZACÓN', sup: '2301'},
  {tipo: 'parroquia', codigo: '230102', nombre:'BOMBOLÍ', sup: '2301'},
  {tipo: 'parroquia', codigo: '230103', nombre:'CHIGUILPE', sup: '2301'},
  {tipo: 'parroquia', codigo: '230104', nombre:'RÍO TOACHI', sup: '2301'},
  {tipo: 'parroquia', codigo: '230105', nombre:'RÍO VERDE', sup: '2301'},
  {tipo: 'parroquia', codigo: '230106', nombre:'SANTO DOMINGO DE LOS COLORADOS', sup: '2301'},
  {tipo: 'parroquia', codigo: '230107', nombre:'ZARACAY', sup: '2301'},
  {tipo: 'parroquia', codigo: '230151', nombre:'ALLURIQUÍN', sup: '2301'},
  {tipo: 'parroquia', codigo: '230152', nombre:'PUERTO LIMÓN', sup: '2301'},
  {tipo: 'parroquia', codigo: '230153', nombre:'LUZ DE AMÉRICA', sup: '2301'},
  {tipo: 'parroquia', codigo: '230154', nombre:'SAN JACINTO DEL BÚA', sup: '2301'},
  {tipo: 'parroquia', codigo: '230155', nombre:'VALLE HERMOSO', sup: '2301'},
  {tipo: 'parroquia', codigo: '230156', nombre:'EL ESFUERZO', sup: '2301'},
  {tipo: 'parroquia', codigo: '230157', nombre:'SANTA MARÍA DEL TOACHI', sup: '2301'},
  {tipo: 'parroquia', codigo: '230250', nombre:'LA CONCORDIA', sup: '2302'},
  {tipo: 'parroquia', codigo: '230251', nombre:'MONTERREY', sup: '2302'},
  {tipo: 'parroquia', codigo: '230252', nombre:'LA VILLEGAS', sup: '2302'},
  {tipo: 'parroquia', codigo: '230253', nombre:'PLAN PILOTO', sup: '2302'},
  {tipo: 'parroquia', codigo: '240101', nombre:'BALLENITA', sup: '2401'},
  {tipo: 'parroquia', codigo: '240102', nombre:'SANTA ELENA', sup: '2401'},
  {tipo: 'parroquia', codigo: '240151', nombre:'ATAHUALPA', sup: '2401'},
  {tipo: 'parroquia', codigo: '240152', nombre:'COLONCHE', sup: '2401'},
  {tipo: 'parroquia', codigo: '240153', nombre:'CHANDUY', sup: '2401'},
  {tipo: 'parroquia', codigo: '240154', nombre:'MANGLARALTO', sup: '2401'},
  {tipo: 'parroquia', codigo: '240155', nombre:'SIMÓN BOLÍVAR', sup: '2401'},
  {tipo: 'parroquia', codigo: '240156', nombre:'SAN JOSÉ DE ANCÓN', sup: '2401'},
  {tipo: 'parroquia', codigo: '240250', nombre:'LA LIBERTAD', sup: '2402'},
  {tipo: 'parroquia', codigo: '240301', nombre:'CARLOS ESPINOZA LARREA', sup: '2403'},
  {tipo: 'parroquia', codigo: '240302', nombre:'GENERAL ALBERTO ENRÍQUEZ GALLO', sup: '2403'},
  {tipo: 'parroquia', codigo: '240303', nombre:'VICENTE  ROCAFUERTE', sup: '2403'},
  {tipo: 'parroquia', codigo: '240304', nombre:'SANTA ROSA', sup: '2403'},
  {tipo: 'parroquia', codigo: '240351', nombre:'ANCONCITO', sup: '2403'},
  {tipo: 'parroquia', codigo: '240352', nombre:'JOSÉ LUIS TAMAYO', sup: '2403'},
]

export default API
