import React, {useEffect, useRef, useState} from 'react'
import {AppBar, Box, ButtonGroup, CssBaseline, Grid, Stack, Tab, Typography} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import API from 'src/features/App/API'
import {useDispatch, useSelector} from 'react-redux'
import Solicitud from "src/features/P01Solicitud/subcomponents/Solicitud";
import {MyTextField} from "src/components/MyTextField";
import {
  handCompletarTareaAndSalir,
  handleClear,
  handleSaveSolicitud
} from "src/features/App/sliceApp";
import {SectionTitle} from 'src/components/SectionTitle'
import {MySendButton} from 'src/components/MySendButton'
import {MySwitch} from "../../components/MySwitch";
import {MySubtitle} from "../../components/MySubtitle";
import {MyGobackButton, MySaveButton} from "../../components/MyCommonButtons";
import Toolbar from "@mui/material/Toolbar";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {f} from "../../commons";
import {WF03SolicitudRO} from "./P03SolicitudRO";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MyDatePicker} from "../../components/MyDatePicker";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {WF03Expediente} from "./subcomponents/P03Expediente";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import GLOBALS from "../App/globals";
import {MyMessageIfFalse} from "../../components/MyMessageIfTrue";

// eslint-disable-next-line import/no-anonymous-default-export
export const P03ElaborarResolucion = ({instanciaTarea, instanciaProceso, solicitud, perfilUsuario, id}) => {
  // console.log('::::::: id : ', id)
  const inputRef = useRef()
  const section = 'P03ElaborarResolucion'
  const dispatch = useDispatch()
  const bandeja = useSelector(state => state.app.bandeja)
  let payload = !!solicitud.payload?JSON.parse(solicitud.payload):{[section]:{}}
  payload.solicitudId = solicitud.id
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    identificador: solicitud.numeroSolicitud,
    fecha:               today,
    resolucionDeInicio : '',
    serial:              '',
    docx:                '',
    pdf:                 '',
    docxLded:            '',
    pdfLded:             '',
    pdfSigned:           '',
    resolucionProcedente:true,
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, bandeja, setFormValues, formValues)
  const canEdit = bandeja === 'entrada' && instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => { setCounter(counter + 1) }, [formValues])
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab)
  }
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const subfolder = `resolucion-${nombreOrganizacion}`
  const filename = `resolucion-${solicitud.numeroSolicitud}-${nombreOrganizacion}`
  const filenamePDF = `${filename}.pdf`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  // const filenamePDFuploaded = `${filename}-upld.pdf`
  // const filenamePDFsigned = `${filename}-firmado.pdf`

  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    }
  }

  const senescyt04 = payload?.INFO?.docs?.d04InformeTecnico.findIndex(x => x.idx === 'SENESCYT')
  const maate04 = payload?.INFO?.docs?.d04InformeTecnico.findIndex(x => x.idx === 'MAATE')
  const senadi04 = payload?.INFO?.docs?.d04InformeTecnico.findIndex(x => x.idx === 'SENADI')

  const senescyt05 = payload?.INFO?.docs?.d05DictamenTecnico.findIndex(x => x.idx === 'SENESCYT')
  const maate05 = payload?.INFO?.docs?.d05DictamenTecnico.findIndex(x => x.idx === 'MAATE')
  const senadi05 = payload?.INFO?.docs?.d05DictamenTecnico.findIndex(x => x.idx === 'SENADI')

  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Elaborar resolución'}/>
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
            <Tab label="Expediente" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Grid container spacing={1} sx={accordeonBox.container2}>
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Solicitud'} />
            </Grid>
            <Solicitud solicitud={solicitud}/>

            <Grid item xs={6} >
              <Grid container spacing={1} sx={{paddingTop:'24px'}}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'INABIO'} />
                </Grid>
                  <Grid item xs={12} >
                    <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                      <MyButtonBacan3
                        onClick={() => API.fetchDownloadPDF({
                          solicitudId: instanciaProceso?.solicitud?.id,
                          subfolder:payload?.INFO?.docs?.d03Factivilidad[0]?.subFolder, // 'd03Factivilidad',
                          filename: payload.INFO.docs?.d03Factivilidad[0]?.archivo,
                        })}
                        label={'Factibilidad'}
                        width={'10rem'} />
                    </Stack>
                  </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} >
              <Grid container spacing={1} sx={{paddingTop:'24px'}}>
                <Grid item xs={12} sx={dialog.titleContainer} >
                  <MySubtitle subtitle={'SENESCYT'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    {/*<MyButtonBacan3*/}
                    {/*  onClick={() => API.fetchDownloadPDF({*/}
                    {/*    solicitudId: instanciaProceso?.solicitud?.id,*/}
                    {/*    subfolder: payload?.INFO?.docs?.d04InformeTecnico[senescyt04]?.subFolder,*/}
                    {/*    filename: payload.INFO.docs?.d04InformeTecnico[senescyt04]?.archivo,*/}
                    {/*  })}*/}
                    {/*  label={'Informe'}*/}
                    {/*  width={'10rem'} />*/}
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: instanciaProceso?.solicitud?.id,
                        subfolder: payload?.INFO?.docs?.d05DictamenTecnico[senescyt05]?.subFolder,
                        filename: payload.INFO.docs?.d05DictamenTecnico[senescyt05]?.archivo,
                      })}
                      label={'Dictamen'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'MAATE'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    {/*<MyButtonBacan3*/}
                    {/*  onClick={() => API.fetchDownloadPDF({*/}
                    {/*    solicitudId: payload?.INFO?.docs?.d04InformeTecnico[maate04]?.solicitudId,*/}
                    {/*    subfolder: payload?.INFO?.docs?.d04InformeTecnico[maate04]?.subFolder,*/}
                    {/*    filename: payload.INFO.docs?.d04InformeTecnico[maate04]?.archivo,*/}
                    {/*  })}*/}
                    {/*  label={'Informe'}*/}
                    {/*  width={'10rem'} />*/}
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: payload?.INFO?.docs?.d05DictamenTecnico[maate05]?.solicitudId,
                        subfolder: payload?.INFO?.docs?.d05DictamenTecnico[maate05]?.subFolder,
                        filename: payload.INFO.docs?.d05DictamenTecnico[maate05]?.archivo,
                      })}
                      label={'Dictamen'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} >
              <Grid container spacing={1}>
                <Grid item xs={12} sx={dialog.titleContainer}>
                  <MySubtitle subtitle={'SENADI'} />
                </Grid>
                <Grid item xs={12} >
                  <Stack direction="row" justifyContent="space-around" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                    {/*<MyButtonBacan3*/}
                    {/*  onClick={() => API.fetchDownloadPDF({*/}
                    {/*    solicitudId: payload?.INFO?.docs?.d04InformeTecnico[senadi04]?.solicitudId,*/}
                    {/*    subfolder: payload?.INFO?.docs?.d04InformeTecnico[senadi04]?.subFolder,*/}
                    {/*    filename: payload.INFO.docs?.d04InformeTecnico[senadi04]?.archivo,*/}
                    {/*  })}*/}
                    {/*  label={'Informe'}*/}
                    {/*  width={'10rem'} />*/}
                    <MyButtonBacan3
                      onClick={() => API.fetchDownloadPDF({
                        solicitudId: payload?.INFO?.docs?.d05DictamenTecnico[senadi05]?.solicitudId,
                        subfolder: payload?.INFO?.docs?.d05DictamenTecnico[senadi05]?.subFolder,
                        filename: payload.INFO.docs?.d05DictamenTecnico[senadi05]?.archivo,
                      })}
                      label={'Dictamen'}
                      width={'10rem'} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Resolución'} />
            </Grid>

            <Grid item xs={6} >
              <Box sx={{width:'25%'}} >
                <MyDatePicker id={'fecha'}
                              canEdit={true}
                              label={'Fecha'}
                              formValues={formValues}
                              setFormValues={setFormValues} />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box sx={{p:'0 0 0 2rem'}}>
                <MySwitch id={'resolucionProcedente'}
                          canEdit={true}
                          label={'¿Procede resolución?'}
                          formValues={formValues}
                          fullWidth={false}
                          handleChange={(e) => {
                            setFormValues({...formValues, resolucionProcedente: e.target.checked})
                          }} />
              </Box>
            </Grid>

            <MyMessageIfFalse varBoolean={formValues.resolucionProcedente} message={'SI NO PROCEDE RESOLUCIóN, termina el proceso'} sx={{ml:'12px'}} />

            <Grid item xs={12} >
              <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1} style={{padding:'0 1rem 0 0'}}>
                {
                  formValues.serial === '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'GENERAR SERIAL'}
                                    width={'11rem'}
                                    bgColor1={'rgb(148 193 32)'}
                                    onClick={() => {
                                      API.secuenciaSet('RES').then((ser) => {
                                        if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                          const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                          getOnSave({...formValues, serial:serial})()
                                          setFormValues({...formValues, serial:serial})
                                        }
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' && formValues.serial !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={''}
                                    width={'3rem'}
                                    onClick={() => {
                                      setFormValues({
                                        ...formValues,
                                        docx: '',
                                        pdf: '',
                                        docxLded: '',
                                        pdfLded: '',

                                        // firma -- \\
                                        firmaTareaId: 0,
                                        pdfSigned:    '',
                                        firmando:     false,
                                        firmado:      false,
                                        // firma -- //
                                      })
                                    }}
                                    icon={RestartAltIcon}/>
                  </ButtonGroup>
                }
                {
                  formValues.docx === '' && formValues.serial !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyButtonBacan3 label={'Generar Resolución'}
                                    width={'11rem'}
                                    onClick={() => {
                                      const doc = MyTagged2Docx(plantilla, nombreOrganizacion, mapping({
                                        solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                        payload,
                                        autorizador: {},
                                        coordinador: {},
                                        perfilUsuario,
                                        nombreOrganizacion,
                                        action: payload[section]
                                      }))
                                      API.genDocxAnPdf({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        document: doc,
                                        formValues,
                                        setFormValues,
                                        subfolder,
                                        filename: filenameDOCX,
                                        nombreOrganizacion,
                                      })
                                    }}
                                    icon={MiscellaneousServicesIcon} />
                  </ButtonGroup>
                }
                {
                  formValues.docx !== '' &&
                  <>
                    <MyButtonBacan3 label={'DOCX'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadDOCX({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                    })}
                                    toolTip={'Descagar documento en formato docx'}
                                    width={'5rem'} />
                    <MyButtonBacan3 label={'PDF'}
                                    icon={FileDownloadIcon}
                                    onClick={() => API.fetchDownloadPDF({
                                      solicitudId: instanciaProceso?.solicitud?.id,
                                      subfolder,
                                      filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                    })}
                                    toolTip={'Descagar documento en formato pdf'}
                                    width={'5rem'} />
                  </>
                }
                {
                  formValues.docx !== '' &&
                  <ButtonGroup variant={'contained'}>
                    <MyFileUploadButton inputRef={inputRef}
                                        label={'DOCX'}
                                        solicitudId={instanciaProceso?.solicitud?.id}
                                        subfolder={subfolder}
                                        fileName={filenameDOCXuploaded}
                                        afterUpload={() => {
                                          API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                            if(f.isValid(result2.rutaDocumento)) {
                                              const arr2 = result2.rutaDocumento.split('/')
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                            } else {
                                              setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                            }
                                          })
                                        }}
                                        toolTip={'Subir documento modificado en formato docx'}
                                        width={'5rem'} />
                  </ButtonGroup>
                }
                {/*{*/}
                {/*  formValues.docx !== '' &&*/}
                {/*  <ButtonGroup variant={'contained'}>*/}
                {/*    <MyFileUploadButton inputRef={inputRef}*/}
                {/*                        label={'PDF (firmado)'}*/}
                {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                {/*                        subfolder={subfolder}*/}
                {/*                        fileName={filenamePDFsigned}*/}
                {/*                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}*/}
                {/*                        toolTip={'Subir documento firmado electrónicamente'}*/}
                {/*                        width={'9rem'} />*/}
                {/*    <MyFileUploadButton inputRef={inputRef}*/}
                {/*                        label={'Firmar'}*/}
                {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                {/*                        subfolder={subfolder}*/}
                {/*                        fileName={filenamePDFsigned}*/}
                {/*                        toolTip={'Firmar electrónicamente'}*/}
                {/*                        disabled={true}*/}
                {/*                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}*/}
                {/*                        width={'9rem'} />*/}
                {/*    {*/}
                {/*      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}*/}
                {/*                                                     icon={FileDownloadIcon}*/}
                {/*                                                     onClick={() => API.fetchDownloadPDF({*/}
                {/*                                                       solicitudId: instanciaProceso?.solicitud?.id,*/}
                {/*                                                       subfolder,*/}
                {/*                                                       filename: filenamePDFsigned,*/}
                {/*                                                     })}*/}
                {/*                                                     toolTip={'Descargar documento firmado'}*/}
                {/*                                                     width={'3rem'}/>*/}
                {/*    }*/}
                {/*  </ButtonGroup>*/}
                {/*}*/}
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="2">
          <WF03SolicitudRO payload={payload}/>
        </TabPanel>
        <TabPanel value="3">
          <WF03Expediente payload={payload} />
        </TabPanel>
      </TabContext>
      <CssBaseline/>
      <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                <MyGobackButton onGoback={() => dispatch(handleClear())} />
                <MySaveButton onSave={() => {
                                const target = 'PREPARARRES'
                                let docs = {...payload.INFO.docs}
                                let IDXloc = docs.d06Resolucion?.findIndex(x => x.idx === target)
                                if(IDXloc >= 0) {
                                  payload.INFO.docs.d06Resolucion?.splice(IDXloc, 1)
                                  docs = {
                                    ...payload.INFO.docs,
                                    'd06Resolucion': payload.INFO.docs.d06Resolucion
                                  }
                                } else {
                                  if(!f.isValid(payload.INFO.docs.d06Resolucion))
                                    docs = {
                                      ...payload.INFO.docs,
                                      'd06Resolucion': []
                                    }
                                }
                                docs = {
                                  ...payload.INFO.docs,
                                  'd06Resolucion': [
                                    ...docs.d06Resolucion,
                                    {
                                      'idx': target,
                                      'archivo': (formValues.pdfSigned !== '') ? formValues.pdfSigned : formValues.pdf,
                                      'solicitudId': payload.INFO.solicitudId,
                                      'desc': 'Preparar resolución',
                                      'subFolder': subfolder
                                    }
                                  ]
                                }
                                payload.INFO.docs = docs
                                payload[section]=formValues
                                dispatch(handleSaveSolicitud(
                                  instanciaProceso?.id,
                                  payload,
                                  () => setCounter(0)
                                ))
                              }}
                              disabled={counter <= 0} />
                <MySendButton onSend={() => {
                                const metadata = JSON.stringify({
                                  "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                  "resolucionProcedente": formValues.resolucionProcedente, // BIEN FALTA PÖNER CONTRL DDE LA VARIABLE
                                  // "perfilUsuarioSolicitud": Number(solicitud.perfilUsuarioId), MAL !!!
                                })
                                dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'ENVIAR'}
                              myTip={''}
                              disabled={counter > 0 || formValues.docx === ''}/>
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}

const plantilla = '' +
  + '${TITULO1}RESOLUCIÓN'
  + '${SALTO}${CENTRAR}No. <<serial>>'
  + '${SALTO3}'
  + '${SALTO}${CENTRAR}<<sistema.autoridadSenescyt>>'
  + '${SALTO}${CENTRARBOLD}CONSIDERANDO:'
  + '${SALTO}${PARRAFO}Que el artículo 14 de la Constitución de la República del Ecuador, publicada en el Registro Oficial No. 449 de 20 de octubre de 2008, reconoce el derecho a la población a vivi en un ambiente sano y ecológicamente equilibrado, garantizando sostenibilidad y el buen vivir; además, se declara de interés público la biodiversidad y la integridad del patrimonio genético del país;'
  + '${SALTO}${PARRAFO}Que el numeral 12 del artículo 57 de la Constitución de la República del Ecuador, establece que “Se reconoce y garantizará a las comunas, comunidades, pueblos y nacionalidades indígenas, de conformidad con la Constitución y con los pactos, convenios, declaraciones y demás instrumentos internacionales de derechos humanos, los siguientes derechos colectivos: (…) Mantener, proteger y desarrollar (…) los recursos genéticos que contienen la diversidad biológica y la agrobiodiversidad; (…). Se prohíbe toda forma de apropiación sobre sus conocimientos, innovaciones y prácticas. (…)”;'
  + '${SALTO}${PARRAFO}Que el artículo 74 de la Constitución de la República del Ecuador señala que: “Las personas, comunidades, pueblos y nacionalidades tendrán derecho a beneficiarse del ambiente y de las riquezas naturales que les permitan el buen vivir. Los servicios ambientales no serán susceptibles de apropiación; su producción, prestación, uso y aprovechamiento serán regulados por el Estado.”;'
  + '${SALTO}${PARRAFO}Que el numeral 1 del artículo 154 de la Constitución de la República del Ecuador determina que, a las ministras y ministros de Estado, además de las atribuciones establecidas en la ley, les corresponde: “(…) Ejercer la rectoría de las políticas públicas del área a su cargo y expedir los acuerdos y resoluciones administrativas que requiera su gestión. (…)”;'
  + '${SALTO}${PARRAFO}Que el artículo 226 de la Constitución de la República del Ecuador, manifiesta que: “Las instituciones del Estado, sus organismos, dependencias, las servidoras o servidores públicos y las personas que actúen en virtud de una potestad estatal ejercerán solamente las competencias y facultades que les sean atribuidas en la Constitución y la ley. Tendrán el deber de coordinar acciones para el cumplimiento de sus fines y hacer efectivo el goce y ejercicio de los derechos reconocidos en la Constitución.”; '
  + '${SALTO}${PARRAFO}Que el artículo 227 de la Constitución de la República del Ecuador, prescribe que: “La administración pública constituye un servicio a la colectividad que se rige por los principios de eficacia, eficiencia, calidad, jerarquía, desconcentración, descentralización, coordinación, participación, planificación, transparencia y evaluación.”; '
  + '${SALTO}${PARRAFO}Que el artículo 313 de la Constitución de la República del Ecuador, señala que la biodiversidad y el patrimonio genético forman parte de los sectores estratégicos, sobre los cuales el Estado se reserva el derecho de administración, regulación, control y gestión de conformidad con los principios de sostenibilidad ambiental, precaución, prevención y eficiencia; '
  + '${SALTO}${PARRAFO}Que el artículo 322 de la Constitución de la República del Ecuador prohíbe la apropiación sobre los recursos genéticos que contienen la diversidad biológica y la agro-biodiversidad;'
  + '${SALTO}${PARRAFO}Que el numeral 3 del artículo 387 de la Constitución de la República del Ecuador asegura por parte del Estado la difusión del acceso a los conocimientos científicos y tecnológicos, el usufructo de sus descubrimientos y hallazgos en el marco de lo establecido en la Constitución y la Ley;'
  + '${SALTO}${PARRAFO}Que el artículo 400 de la Constitución de la República del Ecuador prescribe que el Estado ejercerá la soberanía sobre la biodiversidad, cuya administración y gestión se realizará con responsabilidad intergeneracional. Así también, se declara de interés público la conservación de la biodiversidad y todos sus componentes;'
  + '${SALTO}${PARRAFO}Que el artículo 402 de la Constitución de la República del Ecuador prohíbe el otorgamiento de derechos, incluidos los de propiedad intelectual, sobre productos derivados o sintetizados, obtenidos a partir del conocimiento colectivo asociado a la biodiversidad nacional;'
  + '${SALTO}${PARRAFO}Que el artículo 408 de la Constitución de la República del Ecuador determina que son de propiedad inalienable, imprescriptible e inembargable del Estado, entre otros, la biodiversidad y su patrimonio genético; mismo que sólo podrá ser explotado en estricto cumplimiento de los principios ambientales establecidos en la Constitución, siendo el  Estado, quien: “(…) participará en los beneficios del aprovechamiento de estos recursos, en un monto que no será inferior a los de la empresa que los explota. El Estado garantizará que los mecanismos de producción, consumo y uso de los recursos naturales y la energía preserven y recuperen los ciclos naturales y permitan condiciones de vida con dignidad.”;'
  + '${SALTO}${PARRAFO}Que el Convenio sobre la Diversidad Biológica, publicado en el Registro Oficial Nro. 647 de 06 de marzo de 1995, reconoce el derecho soberano que ejercen los Estados sobre su biodiversidad, estableciendo como objetivos primordiales la conservación de la diversidad biológica, la utilización sostenible de sus componentes y la participación justa y equitativa en los beneficios que se deriven de la utilización de los recursos genéticos;'
  + '${SALTO}${PARRAFO}Que los literales b) y c) del artículo 10 del Convenio sobre la Diversidad Biológica, establece que, cada Parte Contratante, en la medida de lo posible y según proceda: “(…) b) adoptará medidas relativas a la utilización de los recursos biológicos para evitar o reducir al mínimo los efectos adversos para la diversidad biológica; c) Protegerá y alentará la utilización consuetudinaria de los recursos biológicos, (…)”;'
  + '${SALTO}${PARRAFO}Que el numeral 1 del artículo 15, del Convenio sobre la Diversidad Biológica, reconoce los derechos soberanos de los Estados sobre sus recursos naturales, así como, se subraya que la facultad de regular el acceso a los recursos genéticos es de incumbencia de los gobiernos nacionales y está sometida a la legislación nacional;'
  + '${SALTO}${PARRAFO}Que el numeral 3 del artículo 16 del Convenio sobre la Diversidad Biológica, dispone que cada parte contratante tomará medidas legislativas, administrativas o de política, según proceda, con el objeto de que se asegure a las Partes Contratantes, en particular las que son países en desarrollo, que aportan recursos genéticos, el acceso a la tecnología que utilice ese material y la transferencia de esa tecnología, en condiciones mutuamente acordadas, incluida la tecnología protegida por patentes y otros derechos de propiedad intelectual;'
  + '${SALTO}${PARRAFO}Que el numeral 2 del artículo 19 del Convenio sobre la Diversidad Biológica, establece que: “(…) Cada parte contratante adoptará todas las medidas practicables para promover e impulsar en condiciones justas y equitativas el acceso prioritario de las Partes Contratantes, en particular los países en desarrollo, a los resultados y beneficios derivados de las biotecnologías basadas en recursos genéticos aportados por esas Partes Contratantes. (…)”;'
  + '${SALTO}${PARRAFO}Que el artículo 1 del Protocolo de Nagoya sobre Acceso a los Recursos Genéticos y Participación Justa y Equitativa en los Beneficios que se Deriven de su Utilización al Convenio sobre Diversidad Biológica, publicado en el Registro Oficial No. 533, de 13 de septiembre de 2011 (ratificado por Ecuador en 20 de septiembre 2017), señala que el mismo tiene como objetivo “(…) la participación justa y equitativa en los beneficios que se deriven de la utilización de los recursos genéticos, incluso por medio del acceso apropiado a los recursos genéticos y por medio de la transferencia apropiada de tecnologías pertinentes, teniendo en cuenta todos los derechos sobre dichos recursos y tecnologías y por medio de la financiación apropiada, contribuyendo por ende a la conservación de la diversidad biológica y la utilización sostenible de sus componentes.”;'
  + '${SALTO}${PARRAFO}Que el artículo 3 del Protocolo de Nagoya señala que se aplica a los recursos genéticos, a los beneficios que se deriven de su utilización, a los conocimientos tradicionales asociados a los recursos genéticos y los beneficios que se deriven de la utilización de dichos conocimientos; '
  + '${SALTO}${PARRAFO}Que el numeral 1 del artículo 5 del Protocolo de Nagoya, establece que los beneficios que se deriven de la utilización de recursos genéticos, así como las aplicaciones y comercializaciones subsiguientes, se compartirán de manera justa y equitativa con la parte que aporta dichos recursos, sea el país de origen de dichos recursos o una Parte que haya adquirido los recursos genéticos, dicha participación se llevará a cabo en condiciones mutuamente acordadas;'
  + '${SALTO}${PARRAFO}Que el numeral 2 del artículo 6 del Protocolo de Nagoya, establece que: “(…) Conforme a las leyes nacionales cada parte adoptará medidas, según proceda, con miras a asegurar que se obtenga el consentimiento fundamentado previo o la aprobación y participación de las comunidades indígenas y locales para el acceso a los recursos genéticos cuando estas tengan el derecho establecido a otorgar acceso a dichos recursos. (…);'
  + '${SALTO}${PARRAFO}Que el artículo 5 de la Decisión Andina 391 de la Comisión del Acuerdo de Cartagena, establece que el Régimen Común sobre Acceso a los Recursos Genéticos, publicada en el Registro Oficial Suplemento Nro. 5, de 16 de agosto de 1996, proclama que los países miembros de la Comunidad Andina de Naciones ejercen soberanía sobre sus recursos genéticos y sus productos derivados y en consecuencia determinan las condiciones de su acceso;'
  + '${SALTO}${PARRAFO}Que el artículo 6 de la Decisión Andina 391, determina que los recursos genéticos y sus productos derivados, de los cuales los países miembros son países de origen, son bienes o patrimonio de la Nación o del Estado de cada país miembro, de conformidad con lo establecido en sus respectivas legislaciones internas. Además, dichos recursos son inalienables, imprescriptibles e inembargables, sin perjuicio de los regímenes de propiedad aplicables sobre los recursos biológicos que los contienen, el predio en que se encuentran, o el componente intangible asociado;'
  + '${SALTO}${PARRAFO}Que el artículo 9 de la Decisión Andina 391, establece que para el logro de los objetivos de la Decisión, los países miembros asegurarán y facilitarán a través de los contratos correspondientes, el acceso a tecnologías y biotecnologías que utilicen recursos genéticos y sus productos derivados adecuadas para la conservación y utilización sostenible de la diversidad biológica, que no causen daño al medio ambiente;'
  + '${SALTO}${PARRAFO}Que el literal e) del artículo 17 de la Decisión Andina 391, determina que las solicitudes y contratos de acceso y, de ser el caso, los contratos accesorios entre sus condiciones incluirán el fortalecimiento y desarrollo de la capacidad institucional nacional o subregional asociada a los recursos genéticos y sus productos derivados;'
  + '${SALTO}${PARRAFO}Que el artículo 34 de la Decisión Andina 391, establece que el contrato de acceso tendrá en cuenta los derechos e intereses de los proveedores de los recursos genéticos y de sus productos derivados, de los recursos biológicos que los contengan y del componente intangible según proceda, en concordancia con los contratos correspondientes;'
  + '${SALTO}${PARRAFO}Que el literal a) del artículo 50 de la Decisión Andina 391, determina que la Autoridad Nacional competente ejercerá las atribuciones conferidas en la Decisión y en la legislación interna de los países miembros. En tal sentido, estará facultada para emitir las disposiciones administrativas internas necesarias para el cumplimiento de la Decisión y, en tanto no se dicten las normas comunitarias que correspondan, disponer la forma de identificación y empaque de los recursos genéticos y sus productos derivados;'
  + '${SALTO}${PARRAFO}Que el numeral 16 del artículo 4 del Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, publicado en el Registro Oficial Suplemento Nro. 899, de 09 de diciembre de 2016, prescribe que la biodiversidad y el patrimonio genético son propiedad inalienable, imprescriptible e inembargable del Estado; no pueden ser privatizados y, su acceso, uso y aprovechamiento se realizará de forma estratégica procurando la generación de los conocimientos endógenos y el desarrollo tecnológico nacional;'
  + '${SALTO}${PARRAFO}Que los numerales 27 y 28 del artículo 8 del Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, determinan que la entidad rectora del Sistema Nacional de Ciencia, Tecnología, Innovación y Saberes Ancestrales, tiene entre sus atribuciones y deberes el otorgar los permisos necesarios para la investigación asociados con la biodiversidad en coordinación con la Autoridad Ambiental Nacional; y, emitir la normativa y la política pública necesaria para la suscripción de los contratos de acceso, uso y explotación de recursos genéticos asociados con la biodiversidad o conocimientos tradicionales, en coordinación con la Autoridad Ambiental Nacional;'
  + '${SALTO}${PARRAFO}Que el artículo 68 del Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, dispone que: “Para el desarrollo de investigaciones científicas sobre los recursos biológicos, genéticos y sus productos derivados en territorio ecuatoriano, las personas naturales, jurídicas u otras formas asociativas, tanto nacionales como extranjeras, deberán obtener la correspondiente autorización para el acceso a recursos biológicos, genéticos y sus productos derivados con fines de investigación. La Secretaría de Educación Superior, Ciencia, Tecnología e Innovación para conceder dichos accesos, deberá acoger los criterios técnicos y protocolos de la autoridad ambiental nacional para la conservación de la biodiversidad. Se prohíbe recolectar, capturar, cazar, pescar, manipular o movilizar el recurso biológico, nacional e internacionalmente, para fines investigativos sin los correspondientes permisos. La infracción de esta norma será penada de acuerdo a lo previsto en el Código Orgánico Integral Penal. El incumplimiento a esta disposición se sancionará de conformidad con las leyes en la materia correspondiente. Esta prohibición no aplicará cuando la movilización del recurso se realice como parte de la práctica de un conocimiento tradicional, por sus legítimos poseedores.”;'
  + '${SALTO}${PARRAFO}Que el artículo 69 del Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, establece que: “Las personas naturales o jurídicas, tanto nacionales como extranjeras, que accedan a los recursos genéticos del país o a sus productos derivados con fines comerciales deberán obtener la autorización respectiva previo a acceder al recurso. El instituto público de investigación científica sobre la biodiversidad, a través de la unidad encargada de la transferencia tecnológica, será el competente para llevar adelante el proceso de negociación de los beneficios monetarios y no monetarios correspondientes, así como autorizar el acceso al recurso genético y sus productos derivados. Todo producto e investigación no contemplada originalmente en la negociación será materia de un nuevo proceso. (…)”;'
  + '${SALTO}${PARRAFO}Que el artículo 70 del Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, determina que la entidad rectora del Sistema Nacional de Ciencia, Tecnología, Innovación y Saberes Ancestrales en coordinación con la autoridad nacional ambiental y la autoridad nacional competente en materia de derechos intelectuales, deberá evaluar periódicamente el estado de protección de la biodiversidad y los conocimientos tradicionales, así como tomar acciones para impedir el aprovechamiento, patentamiento y comercialización de invenciones consistentes en los recursos genéticos endémicos o desarrolladas a partir de estos;'
  + '${SALTO}${PARRAFO}Que el artículo 73 del Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, establece que: “Conforme a la política pública emitida por la Secretaría de Educación Superior, Ciencia, Tecnología e Innovación, el Estado participará al menos en la misma proporción que cualquier persona natural o jurídica que haya obtenido beneficios monetarios o no monetarios derivados de la investigación, uso, transferencia, desarrollo y comercialización del material biológico o genético, así como de la información, productos o procedimientos derivados del mismo. Los beneficios percibidos serán destinados según la política pública determinada por la Secretaría de Educación Superior, Ciencia, Tecnología e Innovación, la cual en todos los casos deberá prever un porcentaje mayoritario para actividades de ciencia, tecnología, innovación, saberes ancestrales. Así mismo, una parte de dichos beneficios serán destinados a la conservación, restauración y reparación de la biodiversidad, lo cual será coordinado con el ente rector del ambiente. En los casos en que los recursos hayan sido obtenidos de los territorios de las comunidades, pueblos y nacionalidades indígenas, el pueblo afroecuatoriano, el pueblo montubio y sus comunas; el porcentaje mayoritario se destinará en esos territorios a las actividades antes detalladas. En el caso de acceso a recursos genéticos con componente intangible asociado, la participación en los beneficios por parte del Estado se dará únicamente respecto de los recursos genéticos. Los beneficios derivados de componentes intangibles les corresponderá a sus legítimos poseedores.”;'
  + '${SALTO}${PARRAFO}Que el tercer inciso del artículo 511 del Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, reconoce y protege derechos colectivos sobre el componente intangible y las expresiones culturales tradicionales serán complementarios a las normas sobre acceso a recursos genéticos;'
  + '${SALTO}${PARRAFO}Que el artículo 529 del Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, establece que para el acceso, uso y aprovechamiento de los conocimientos tradicionales se deberá contar con el consentimiento libre, previo e informado de sus legítimos poseedores, estableciendo una repartición justa y equitativa de los beneficios monetarios y no monetarios. Esto sin perjuicio de los derechos que le corresponden al Estado cuando se trate de recursos genéticos, de acuerdo con lo establecido en la Constitución y la Ley;'
  + '${SALTO}${PARRAFO}Que el artículo 530 del Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, establece que los legítimos poseedores de conformidad con sus normas consuetudinarias, e instituciones de representación legítima y legalmente constituidas, mediante mecanismos participativos, tienen la facultad exclusiva de autorizar a un tercero de forma libre, expresa e informada el acceso, uso o aprovechamiento de sus conocimientos tradicionales, mediante su consentimiento previo, libre e informado. Adicionalmente, dispone que para los casos en que la solicitud del consentimiento previo, libre e informado verse sobre un componente intangible asociado a recursos genéticos, los usuarios interesados presentarán un plan que detalle el acceso, uso y aprovechamiento de dichos componentes, ante la Secretaría de Educación Superior, Ciencia, Tecnología e Innovación, como ente rector en materia de conocimientos tradicionales, de conformidad con lo que disponga el respectivo reglamento.'
  + '${SALTO}${PARRAFO}Que el numeral 9 del artículo 3 del Código Orgánico del Ambiente, publicado en el Registro Oficial Suplemento Nro. 983 del 12 de abril de 2017, establece que la Autoridad Nacional Ambiental deberá “Establecer los mecanismos que promuevan y fomenten la generación de información ambiental, así como la articulación y coordinación de las entidades públicas como privadas de la sociedad civil responsables de realizar actividades de gestión e investigación ambiental, de conformidad con los requerimientos y prioridades estatales”;'
  + '${SALTO}${PARRAFO}Que el artículo 32 del Código Orgánico del Ambiente, dispone que la entidad rectora del Sistema Nacional de Ciencia, Tecnología, Innovación y Saberes Ancestrales promoverá y regulará las investigaciones científicas in situ y ex situ que comprendan actividades de extracción, colección, recolección, importación, movilización, transportación, exportación y disposición temporal o final de especies de vida silvestre, implementando mecanismos de rastreo y monitoreo de la biodiversidad, de acuerdo a los lineamientos de las autoridades competentes. '
  + '${SALTO}${PARRAFO}Que el artículo 72 del Código Orgánico del Ambiente establece que “Los derechos otorgados sobre los recursos biológicos no conceden derecho alguno sobre los recursos genéticos o sus derivados, ni sobre los conocimientos colectivos asociados a estos, de conformidad con la ley y la Constitución. Las autorizaciones administrativas a las actividades de investigación, manejo, comercialización u otras, de especímenes, elementos constitutivos y subproductos de especies de vida silvestre, no autorizan el acceso a sus recursos genéticos o sus derivados. (…)”; '
  + '${SALTO}${PARRAFO}Que el artículo 74 del Código Orgánico del Ambiente establece que la Autoridad Nacional Ambiental podrá limitar el acceso a los recursos genéticos, sus componentes y derivados de manera total o parcial.'
  + '${SALTO}${PARRAFO}Que el artículo 247 del Código Orgánico Integral Penal, publicado en el Registro Oficial Suplemento No. 180 de 10 de febrero de 2014, establece que “(…) La persona que cace, pesque, capture, recolecte, extraiga, tenga, transporte, trafique, se beneficie, permute o comercialice, especímenes o sus partes, sus elementos constitutivos, productos y derivados, de flora o fauna silvestre terrestre, marina o acuática, de especies amenazadas, en peligro de extinción y migratorias, listadas a nivel nacional por la Autoridad Ambiental Nacional así como instrumentos o tratados internacionales ratificados por el Estado, será sancionada con pena privativa de libertad de uno a tres años. (…)”; '
  + '${SALTO}${PARRAFO}Que el artículo 248 del Código Orgánico Integral Penal, define como atentado contra el patrimonio genético ecuatoriano: el acceso no autorizado, la erosión genética y la pérdida genética, estableciéndose  una pena privativa de libertad de tres a cinco años, dicha pena será agravada en un tercio si se demuestra que el acceso ha tenido finalidad comercial;'
  + '${SALTO}${PARRAFO}Que el artículo 25 del Reglamento General al Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación, publicado en el Registro Oficial Suplemento Nro. 9, de 07 de junio de 2017, prescribe que los permisos de acceso con fines de investigación a recursos genéticos y sus derivados para fines de investigación o comerciales, así como los permisos de importación de organismos vivos, especímenes de colecciones científicas que tengan como fin el desarrollo de procesos investigativos se tramitarán a través de una ventanilla única para la investigación para la biodiversidad en la que inter-operarán la entidad rectora del Sistema Nacional de Ciencia, Tecnología, Innovación y Saberes Ancestrales, el instituto público de investigación científica sobre la biodiversidad, la autoridad ambiental nacional, la autoridad aduanera, así como las demás pertinentes. Dicha plataforma será administrada por la entidad rectora del Sistema Nacional de Ciencia, Tecnología, Innovación y Saberes Ancestrales y formará parte del Sistema Nacional de Información de Ciencia, Tecnología, Innovación y Conocimientos Tradicionales, sin perjuicio de que el contenido de la misma sea reproducido en otros sistemas públicos de información o se vincule a éstos;'
  + '${SALTO}${PARRAFO}Que el artículo 215 del Reglamento al Código Orgánico del Ambiente dispone que “La Autoridad Ambiental Nacional, en materia de recursos genéticos regulará y normará los procedimientos, plazos y requisitos para la conservación y uso sostenible de la biodiversidad y el patrimonio genético del país.”;'
  + '${SALTO}${PARRAFO}Que el artículo 216 del Reglamento al Código Orgánico del Ambiente, establece que: “La Autoridad Ambiental Nacional, en el marco de sus competencias, emitirá los lineamientos técnicos para la conservación y usos sostenible de los recursos genéticos y biológicos, mediante norma técnica.”;'
  + '${SALTO}${PARRAFO}Que el artículo 218 del Reglamento al Código Orgánico del Ambiente, indica que la Autoridad Ambiental Nacional, en ejercicio de sus competencias, constituirá un punto de verificación, acorde lo dispuesto en el Protocolo de Nagoya;'
  + '${SALTO}${PARRAFO}Que el Reglamento al Código Orgánico del Ambiente, en el artículo 219, establece que: “La Autoridad Ambiental Nacional emitirá, en coordinación con la Autoridad Nacional de Investigación, Ciencia y Tecnología y a través de norma técnica, los criterios de conservación a utilizarse para la evaluación prevista en el Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación.”;'
  + '${SALTO}${PARRAFO}Que el artículo 220 del Código Orgánico del Ambiente, establece que la Autoridad Ambiental Nacional emitirá los criterios técnicos y protocolos para la conservación y uso sostenible de la biodiversidad, a ser considerados previo al acceso a recursos genéticos. En este sentido, la autoridad competente para conceder el acceso al recurso genético, solicitará el pronunciamiento de la Autoridad Ambiental Nacional sobre la pertinencia del acceso solicitado;'
  + '${SALTO}${PARRAFO}Que el artículo 222 del Código Orgánico del Ambiente, dispone que: “La Autoridad Ambiental Nacional, en coordinación con la autoridad competente, realizará una identificación de posibles conocimientos tradicionales asociados a recursos genéticos localizados dentro de áreas protegidas, o cuyos legítimos poseedores sean comunas, comunidades, pueblos y nacionalidades que habitan en ellas. Con dicha información, la Autoridad Nacional de Conocimientos Tradicionales informará y exhortará a las comunas, comunidades, pueblos y nacionalidades, a fin de que, en caso de considerarlo necesario y pertinente, puedan realizar el depósito voluntario de sus conocimientos tradicionales, acorde con lo dispuesto en el Código Orgánico de la Economía Social de los Conocimientos, Creatividad e Innovación.”; '
  + '${SALTO}${PARRAFO}Que el artículo 225 del Código Orgánico del Ambiente, establece que: “En los casos en que llegue a conocimiento de la Autoridad Ambiental Nacional información que haga presumir accesos ilegales a recursos genéticos, sus componentes derivados y sintetizados, se elaborará un informe técnico que será notificado a las autoridades competentes a fin de que se inicien los procedimientos respectivos. Dicho informe se remitirá de forma obligatoria a la Fiscalía General del Estado.”; '
  + '${SALTO}${PARRAFO}Que el Código Orgánico del Ambiente, en el artículo 226, establece que la Autoridad Ambiental Nacional, a través de norma técnica, expedirá los lineamientos y criterios para la distribución de los beneficios destinados a conservación, protección, restauración y reparación de la biodiversidad;'
  + '${SALTO}${PARRAFO}Que el artículo 228 del Código Orgánico del Ambiente, dispone que: “Cuando se involucren comunidades locales como proveedores del componente intangible asociado al recurso genético, se acordará la participación de estos sectores en los beneficios derivados del acceso al recurso genético en sus respectivos territorios, de acuerdo a los lineamientos que se emitan en el Régimen respectivo.”;'
  + '${SALTO}${PARRAFO}Que la Secretaría de Educación Superior, Ciencia, Tecnología e Innovación, a través de la Dirección de Investigación Científica gestiona y establece estrategias y mecanismos para la emisión de permisos de investigación científica de la biodiversidad y contratos de acceso a los recursos genéticos o sus derivados.'
  + '${SALTO}${PARRAFO}Por delegación del Secretario de Educación Superior, Ciencia, Tecnología e Innovación:'
  + '${SALTO}'
  + '${SALTO}${CENTRARBOLD}RESUELVE:'
  + '${SALTO}'
  + '${SALTO}${PARRAFO}Otorgar a <<solicitante.nombre>> la autorización para desarrollar la investigación <<solicitud.nombreProyecto>> por el plazo de <<solicitud.plazoProyecto>>.'
  + '${SALTO}'
  + '${SALTO}${CENTRARBOLD}DISPOSICIONES FINALES'
  + '${SALTO}${PARRAFO}Primera.- De la ejecución de la presente Resolución, encárguese a la Dirección de Investigación Científica de la Secretaría de Educación Superior, Ciencia, Tecnología e Innovación. '
  + '${SALTO}${PARRAFO}Segunda.- La notificación de la presente Resolución será realizada por la Dirección de Investigación Científica, de conformidad a lo dispuesto en el Acuerdo Nro. SENESCYT-. '
  + '${SALTO}${PARRAFO}Tercera.- La presente Resolución entrará en vigencia a partir de su suscripción, sin perjuicio de su publicación en el Registro Oficial.'
  + '${SALTO}${PARRAFO}Dado en la ciudad de San Francisco de Quito, D.M. a los <<sistema.fechaTexto>>. '
  + '${SALTO}${PARRAFO}Comuníquese y Publíquese.-'
  + '${SALTO3}'
  + '${SALTO}${CENTRAR}<<sistema.nombreAutoridadSenescyt>>'
  + '${SALTO}${CENTRAR}SUBSECRETARIO/A DE INVESTIGACIÓN, INNOVACIÓN Y TRANSFERENCIA DE TECNOLOGÍA'
  + '${SALTO3}'
  + '${SALTO}${FIRMAN}<<firman>>'


// + '${SALTO}${CENTRAR}<<usuario.cargo>>'


const mapping = (vars) => {
  const { solicitudId, payload, autorizador, coordinador, perfilUsuario, section, aprueba } = vars
  const autoridadSenescyt = vars.autoridadSenescyt
  // const arr = [...doc.matchAll(/\<\<[\w\.]*\>\>/g)].map(it => ({match:it[0], index: it.index})))
  // console.log(arr?.map(it => ({match:it[0], index: it.index})))
  const recInsitu = payload?.RecursosInSitu?.recursos?.map(it => it.scientificname)?.join(', ') + ', ' + payload?.RecursosExSitu?.centrosDocumentacion?.recursos?.map(it => it.scientificname)?.join(', ')
  const recExsitu = payload?.RecursosExSitu?.centrosDocumentacion?.map(it => it.recursos?.map(it2 => it2.scientificname)?.join((', ')))?.join(', ')
  let recs = []
  if(f.isValid(recInsitu)) recs.push(recInsitu)
  if(f.isValid(recExsitu)) recs.push(recExsitu)
  let acc = []
  if(payload?.AccesoConocimiento?.aConocimientoTradicional) acc.push('Acceso a conocimiento tradicional.')
  if(payload?.AccesoConocimiento?.clpi !== "") acc.push('CLPI: ' + payload?.AccesoConocimiento?.clpi)
  if(payload?.AccesoConocimiento?.contratoAccesoConocimiento !== "") acc.push('Contrato de acceso a conocimiento: '+ payload?.AccesoConocimiento?.contratoAccesoConocimiento)
  if(payload?.AccesoConocimiento?.ambitoComunitario) acc.push('Ambito comunitario.')
  if(payload?.AccesoConocimiento?.derivadosOSimilares) acc.push('Derivados o similares.')
  if(payload?.AccesoConocimiento?.etnozoologicos) acc.push('Etnozoologicos.')
  if(payload?.AccesoConocimiento?.medicinaTradicional) acc.push('Medicina tradeicional.')
  if(payload?.AccesoConocimiento?.practicasAncestrales) acc.push('Prácticas ancestrales.')
  if(payload?.AccesoConocimiento?.recursosBiologico) acc.push('Recursos biológicos.')
  if(payload?.AccesoConocimiento?.valoresReligiososCultutrales) acc.push('Valores religiosos culturales.')

  const result =  {
    '<<sistema.identificadorInformeTecnicoMaate>>':solicitudId,
    '<<autorizacion.vigencia>>': payload?.Propuesta?.plazo,
    '<<solicitante.identificador>>': solicitudId,
    '<<solicitante.idAcreditacion>>': payload?.Solicitante?.registro,
    '<<institucionApoyo.razonSocial>>': payload?.Propuesta?.apoyo,
    '<<solicitud.areaInvestigacion>>': payload?.Propuesta?.areaInvestigacion,
    '<<recursos.listaRecursos>>': recs.join(', '),
    '<<ubicacion.listaProvincias>>': payload?.RecursosInSitu?.provincias?.join(', '),
    '<<ubicacion.listaAreasProtegidas>>': payload?.RecursosInSitu?.areasProtegidas?.join(', '),
    '<<ubicacion.listaBosquesProtectores>>': payload?.RecursosInSitu?.bosquesProtectores?.join(', '),
    '<<solicitud.accesoConocimientoTradicional>>': acc?.join(' '),
    '<<plazoProyecto>>': payload?.Propuesta?.plazo + ' meses',
    '<<sistema.identificadorDictamenTecnicoMaate>>':solicitudId,
    '<<sistema.identificadorIDictamenTecnicoSenadi>>':solicitudId,
    '<<sistema.identificadorInformeTecnicoSenescyt>>':solicitudId,
    '<<sistema.identificadorResolucionSenescyt>>':solicitudId,
    '<<sistema.fechaTexto>>':format(new Date(), 'dd MMMM yyyy', {locale: es}),
    '<<sistema.fecha>>':format(new Date(), 'dd MMMM yyyy', {locale: es}),
    '<<solicitud.identificador>>':solicitudId,
    '<<solicitante.nombre>>':payload?.Solicitante?.nombresCompletos,
    '<<solicitud.nombreProyecto>>':payload?.Propuesta?.nombre,
    '<<solicitud.plazoProyecto>>':payload?.Propuesta?.plazo,
    '<<patrocinador>>':payload?.Propuesta?.apoyo,
    '<<sistema.nombreAutorizadorPrincipalSenescyt>>': aprueba?.nombreUsuario ?? '',
    '<<sistema.rolAutorizadorPrincipalSenescyt>>': aprueba?.nombrePerfil ?? '',
    '<<sistema.cargoAutorizadorPrincipalSenescyt>>': aprueba?.usuarioCargoUsuario?.toUpperCase() ?? '',
    '<<sistema.nombreAutorizadorSectorialMaate>>':coordinador?.nombreUsuario ?? '',
    '<<sistema.rolAutorizadorSectorialMaate>>':coordinador?.nombrePerfil ?? '',
    '<<usuario.cargoAutorizadorSectorialMaate>>':coordinador?.descripcionPerfil ?? '',
    '<<sistema.nombreCoordinadorCasosSenescyt>>':coordinador?.nombreUsuario ?? '',
    '<<sistema.rolCoordinadorCasosSenescyt>>':coordinador?.nombrePerfil ?? '',
    '<<sistema.cargoCoordinadorCasosSenescyt>>':coordinador?.usuarioCargoUsuario?.toUpperCase()  ?? '',
    '<<sistema.telefono>>':coordinador?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<sistema.correoElectronico>>':coordinador?.correoUsuario ?? '',
    '<<firman>>': JSON.stringify([
      {accion: 'Elaborado por',nombre:perfilUsuario?.usuario?.nombreUsuario ?? '', cargo:perfilUsuario?.perfil?.nombrePerfil ?? ''},
      {accion: 'Revisado por',nombre:coordinador?.nombreUsuario ?? '', cargo:coordinador?.usuarioCargoUsuario ?? ''},
      {accion: 'Aprobado por',nombre:aprueba?.nombreUsuario ?? '', cargo:aprueba?.usuarioCargoUsuario?.toUpperCase()},
    ]),
    '<<usuario.nombre>>':perfilUsuario?.usuario?.nombreUsuario ?? '',
    '<<usuario.rol>>':perfilUsuario?.perfil?.nombrePerfil ?? '',
    // '<<usuario.cargo>>':perfilUsuario?.perfil?.descripcionPerfil ?? '',
    '<<usuario.telefono>>':perfilUsuario?.usuario?.telefonoUsuario ?? '(teléfono no disponible)',
    '<<usuario.correoElectronico>>':perfilUsuario?.usuario?.correoUsuario ?? '',
    '<<sistema.nombreAutoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '',
    '<<sistema.rolAutoridadSenescyt>>': autoridadSenescyt?.nombrePerfil ?? '',
    '<<sistema.cargoAutoridadSenescyt>>': autoridadSenescyt?.descripcionPerfil ?? '',
    '<<sistema.autoridadSenescyt>>':autoridadSenescyt?.nombreUsuario ?? '',
    '<<solicitud.objetivoProyecto>>': f.isValid(payload?.Propuesta?.objetivo)?payload?.Propuesta?.objetivo:'**',
    '<<serial>>': 'xxxxxxxx',
  }

  return result
}

