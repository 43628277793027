import React, {useEffect, useState} from 'react'
import {Box, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {accordeonBox, dialog} from 'src/styles/styles'
import {useDispatch, useSelector} from 'react-redux'
import {
  handleSaveSolicitud,
  handleClear,
  handCompletarTareaCoordinador
} from 'src/features/App/sliceApp'
import {MySendButton} from 'src/components/MySendButton'
import {SectionTitle} from 'src/components/SectionTitle'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import {f} from 'src/commons/f'
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {MySubtitle} from "../../components/MySubtitle";
import {Email} from "@mui/icons-material";
import API from 'src/features/App/API'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {MyUpload} from "../../components/MyUpload";
import {MySwitch} from "../../components/MySwitch";
import GLOBALS from "../App/globals";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P0102PayloadRO} from "../P01Solicitud/P0102PayloadRO";
import {MyTableCheck} from "../../components/MyTableCheck";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import {MyButtonAlert} from "../../components/MyButtonAlert";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({id}) => {
  const dispatch = useDispatch()
  const section = 'Monitoreo'
  const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const instanciaProceso = useSelector(state => state.app.instanciaProceso)
  const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  const solicitud = {...instanciaProceso.solicitud}
  const payload = !!solicitud.payload?JSON.parse(solicitud.payload):{}
  const aConocimientoTradicional = payload.Solicitud.solicitudAprobada.payloadSolicitud?.AccesoConocimiento?.aConocimientoTradicional
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:                  today,
    pronunciamientoMaate:   f.isValid(payload?.DictamenTecnicoMaate?.pdf)?false:true,
    pronunciamientoSenadi:  (aConocimientoTradicional && (f.isValid(payload?.DictamenTecnicoSenadi?.pdf)?false:true)),
    observaciones:          [{subsanada:false, item:'', ritem:''}],
    subsanacion:            false,
    monitoreoCompleto:      false,
    subsanacionn:    0,
  }
  const canEdit = instanciaTarea.estadoInstanciaProceso === "En Progreso" && instanciaTarea.estadoInstanciaTarea === "Iniciada"
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const handleChange = (e) => API.handleChange(e, 'entrada', setFormValues, formValues)
  const [counter, setCounter] = useState(-1)  // updating counter
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  const [myTab, setMytab] = useState('1')
  const handleChangeTab = (event, newTab) => {setMytab(newTab)}
  return <>
    <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%', backgroundImage: 'url(src/style/MySvgIcons)'}}>
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
        <SectionTitle title={'Monitoreo de cumplimiento'} />
      </AppBar>
      <TabContext value={myTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
          <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
            <Tab label="Formulario" value="1" />
            <Tab label="Solicitud" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{p:'0 2rem 0 2rem'}}>
            {f.isValid(payload.T55000023_Activity_AsignarCasoSenescyt1_WF15?.observaciones) && payload.T55000023_Activity_AsignarCasoSenescyt1_WF15?.observaciones !== '' &&
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}
                       style={{padding: '1rem 24px 1rem 0'}}>
                  <MyButtonAlert label={'Directriz del caso'}
                                 color0={'#888888'}
                                 color1={'#888888'}
                                 messageTitle='Directriz del caso'
                                 messageContent={payload.T55000023_Activity_AsignarCasoSenescyt1_WF15?.observaciones}/>
                </Stack>
              </Grid>
            }
            <Grid item xs={12} sx={dialog.titleContainer}>
              <MySubtitle subtitle={'Reporte de cumplimiento'} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'1rem 0 0 0'}}>
                <MyReadOnlyTextField id={'solicitudNumero'}
                                     value={solicitud?.numeroSolicitud}
                                     label={'Identificador'}
                                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                <MyReadOnlyTextField id={'fecha'}
                                     value={payload.InformeSeguimiento?.fecha}
                                     label={'Fecha'}
                                     icon={<Email sx={{fontSize: '14px', color:'silver'}}/>} />
                <MyButtonBacan3 label={'Informe'}
                                icon={FileDownloadIcon}
                                onClick={() => API.fetchDownloadPDF({
                                  solicitudId: instanciaProceso?.solicitud?.id,
                                  subfolder:'informe-cumplimiento-investigador',
                                  filename: payload.InformeSeguimiento.pdf
                                })}
                                toolTip={'Descargar documento en formato pdf'}
                                width={'7rem'} />
                {(payload.InformeSeguimiento.anexo !== '')?
                  < MyUpload id={'anexo'}
                             label={'Anexo'}
                             dir={instanciaProceso?.solicitud?.id}
                             formValues={payload.InformeSeguimiento}
                             canEdit={false} />:null
                }
              </Stack>
            </Grid>
            <Grid container>
              <Grid item xs={6} sx={{pr:'1rem'}}>
                <Stack direction={'column'}>
                  <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
                    <MySubtitle subtitle={'Pronunciamiento MAATE'} />
                  </Grid>
                  <Grid item xs={8}>
                    <MySwitch id='pronunciamientoMaate'
                              label={'¿Se requiere pronunciamiento?'}
                              formValues={formValues}
                              fullWidth={true}
                              canEdit={false}
                              handleChange={handleChange} />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  {f.isValid(payload?.DictamenTecnicoMaate?.pdf) ?
                    <Stack direction={'row'}
                           justifyContent="flex-start"
                           alignItems="flex-start"
                           spacing={2}>
                    <MyButtonBacan label={'Dictamen'}
                                   onClick={() => {
                                     const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/dictamen-tecnico-Maate/${payload['DictamenTecnicoMaate']['pdf']}`
                                     fetch(url)
                                       .then((res) => { return res.blob(); })
                                       .then((data) => {
                                         const dataPdf = new Blob([data], { type: 'application/pdf' })
                                         const a = document.createElement("a")
                                         a.href = window.URL.createObjectURL(dataPdf)
                                         a.target="_blank"
                                         a.click()
                                       })
                                   }}
                                   icon={FileDownloadOutlinedIcon} />
                    </Stack>:false
                  }
                </Stack>
              </Grid>
              <Grid item xs={6} sx={{pr:'1rem'}}>
                <Stack direction={'column'}>
                  <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
                    <MySubtitle subtitle={'Pronunciamiento SENADI'} />
                  </Grid>
                  <Grid item xs={8}>
                    <MySwitch id='pronunciamientoSenadi'
                              label={'¿Se requiere pronunciamiento?'}
                              formValues={formValues}
                              fullWidth={true}
                              canEdit={false}
                              handleChange={handleChange}/>
                  </Grid>
                  <Grid item xs={4}></Grid>
                  {f.isValidNotEmpty(payload?.InformeTecnicoSenadi?.pdf) ?
                    <Stack direction={'row'}
                           justifyContent="flex-start"
                           alignItems="flex-start"
                           spacing={2}>
                    <MyButtonBacan label={'Dictamen'}
                                   onClick={() => {
                                     const url = `${GLOBALS.mainUrl}/documentos/descargar?filename=vuv-${instanciaProceso?.solicitud?.id}/dictamen-tecnico-Senadi/${payload['DictamenTecnicoSenadi']['pdf']}`
                                     fetch(url)
                                       .then((res) => { return res.blob(); })
                                       .then((data) => {
                                         const dataPdf = new Blob([data], { type: 'application/pdf' })
                                         const a = document.createElement("a")
                                         a.href = window.URL.createObjectURL(dataPdf)
                                         a.target="_blank"
                                         a.click()
                                       })
                                   }}
                                   icon={FileDownloadOutlinedIcon} />
                    </Stack>:null
                  }
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={6}  sx={{p:'2rem 1rem 0 1rem'}}>
              <Stack direction={'row'}
                     justifyContent="flex-end"
                     alignItems="center"
                     spacing={2}>
                <MySendButton onSend={ () => {
                                const metadata = JSON.stringify({
                                  "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                  seguimientoSenadi:    formValues.pronunciamientoSenadi,
                                  seguimientoMaate:     formValues.pronunciamientoMaate,
                                  monitoreoCompleto:    false,
                                  subsanacionSenescyt:  false,
                                })
                                dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'Solicitar'}
                              myTip={'Solicitar pronunciuamiento a MAATE y/o SENADI'}
                              disabled={counter > 0 || (!formValues.pronunciamientoSenadi && !formValues.pronunciamientoMaate)} />
              </Stack>
            </Grid>
            <Grid item xs={12} sx={{...dialog.titleContainer, p:'0'}}>
              <MySubtitle subtitle={'Observaciones para subsanación'} />
            </Grid>
            <Grid item xs={12}>
              <MyTableCheck id={'observaciones'}
                            formValues={formValues}
                            setFormValues={setFormValues}
                            columnName={'Observaciones'}
                            canEdit={canEdit}
                            addItem={() => {
                              if(formValues['observaciones'].filter(it => it === '').length === 0) {
                                const field = 'observaciones'
                                const newSet = [ ...formValues[field], {subsanada:false, item:'', ritem:''} ]
                                const newFormValues = {...formValues, [field]:newSet}
                                setFormValues(newFormValues)
                              }
                            }} />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={1} style={{padding:'1rem'}}>
                {(formValues.subsanacionn + 1 <= 3) ?
                  <MySendButton onSend={() => {
                                  payload[section] = {...formValues, subsanacionn: formValues.subsanacionn + 1}
                                  payload['InformeSeguimiento'].docx = ''
                                  payload['InformeSeguimiento'].pdf = ''
                                  setCounter(0)
                                  dispatch(handleSaveSolicitud(instanciaProceso?.id, payload))
                                  const metadata = JSON.stringify({
                                    "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                    "perfilUsuarioSolicitud": Number(payload?.Solicitud?.solicitudAprobada?.idPerfilUsuario),
                                    "monitoreoCompleto": false,
                                    "subsanacionSenescyt": true,
                                    "seguimientoMaate": false,
                                    "seguimientoSenadi": false,
                                  })
                                  dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                }}
                                label={`Subsanar ${formValues.subsanacionn +1}`}
                                disabled={!canEdit || counter > 0 || formValues?.observaciones?.filter(it => it.item !== '')?.length === 0}
                                icon={SaveOutlinedIcon}/> : null
                }
              </Stack>
            </Grid>
            <Grid container>
              <Grid item xs={6} >

              </Grid>
              <Grid item xs={6}>
                <MySwitch id='monitoreoCompleto'
                          label={'¿Monitoreo Completo?'}
                          fullWidth={false}
                          formValues={formValues}
                          canEdit={!(formValues.pronunciamientoSenadi || formValues.pronunciamientoMaate)}
                          handleChange={handleChange} />
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <P0102PayloadRO payload={payload.Solicitud.solicitudAprobada.payloadSolicitud} />
        </TabPanel>
      </TabContext>

      <CssBaseline />
      <AppBar position='fixed'
              color='primary'
              elevation={0}
              sx={accordeonBox.bottomBar} >
        <Toolbar>
          <Grid container sx={{p:0, mt:'-1rem'}}>
            <Grid item xs={12} sx={{p:0, m:0, position: 'sticky',}}>
              <Stack direction={'row'} justifyContent="space-between" alignItems="center" sx={{p:'0 4rem 0 4rem'}}>
                <MyButtonBacan label={'Regresar'}
                               myTip={'Regresar a las tareas'}
                               icon={ArrowBackIcon}
                               onClick={() => { dispatch(handleClear()) }} />
                <MyButtonBacan label={'Guardar'}
                               myTip={'Guarda el formulario, y permite continuar editando'}
                               onClick={() => {
                                 payload[section]=formValues
                                 if(id === '55000023_Activity_RealizarMonitoreoWF15') {
                                   if(f.isValid(payload?.InformeSeguimiento?.obsSenescyt)) {
                                     payload.InformeSeguimiento.obsSenescyt = formValues.observaciones
                                   }
                                 }
                                 setCounter(0)
                                 dispatch(handleSaveSolicitud(instanciaProceso?.id,payload))
                               }}
                               disabled={!canEdit || counter <= 0}
                               icon={SaveOutlinedIcon} />
                <MySendButton onSend={ () => {
                                const metadata = JSON.stringify({
                                  "solicitudId":`${instanciaProceso?.solicitud?.id}`,
                                  "masConsultas":           false,
                                  "seguimientoSenadi":      false,
                                  "seguimientoMaate":       false,
                                  "obligacionesVencidas":   false,
                                  "monitoreoCompleto":      formValues.monitoreoCompleto,
                                  "subsanacionSenescyt":    false
                                })
                                dispatch(handCompletarTareaCoordinador(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                              }}
                              label={'Enviar'}
                              disabled={!canEdit || counter > 0 || formValues.asunto === '' || formValues.detalle === ''
                                || formValues.monitoreoCompleto === false || formValues.pronunciamientoMaate === true
                                || formValues.pronunciamientoSenadi === true} />
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  </>
}
