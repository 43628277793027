import React, {useEffect, useRef, useState} from 'react'
import {TabContext, TabList, TabPanel} from "@mui/lab"
import {useDispatch, useSelector} from 'react-redux'
import {
  AppBar,
  Box, ButtonGroup,
  CssBaseline,
  Grid,
  Stack, Tab, Typography,
} from '@mui/material'
import {add, format, parse} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import Toolbar from '@mui/material/Toolbar'
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField"
import BorderColorIcon from '@mui/icons-material/BorderColor'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import Autorizacion from "src/features/P01Solicitud/subcomponents/Autorizacion"
import {MyReabOnlyTableRecursos} from "../../components/MyReadOnlyTableRecursos"
import {MyTableMuestras} from "../../components/MyTableMuestras"
import {AccountCircle, Badge} from "@mui/icons-material"
import GLOBALS from "../App/globals"
import API from "../App/API"
import {MyButtonBacan3} from "../../components/MyButtonBacan3";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {MyTagged2Docx} from "../../components/MyTagged2Docx";
import P0102API from "../P01Solicitud/API";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {MyFileUploadButton} from "../../components/MyFileUploadButton";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import {P10ATMRO} from "../P10ATM/P10ATMRO";
import {MySwitch} from "../../components/MySwitch";

export default ({
                  instanciaTarea,
                  perfilUsuario,
                  instanciaProceso,
                  solicitud,
                  solicitudesAprobadas,
                  canEdit=true,
                }) => {
  const inputRef = useRef()
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const ATM = slct.payload.Solicitud.solicitudAprobada.father.payloadSolicitud
  const mp = slct.payload.Solicitud.solicitudAprobada
  const dispatch = useDispatch()
  const section = 'Elaboracion'
  const payload = f.isValid(solicitud.payload)?JSON.parse(solicitud.payload):{}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha:         today,
    contenido:     '',
    docxGenerado:  false,
    docx:          '',
    pdf:           '',
    docxLded:      '',
    pdfLded:       '',
    // pdfSigned:     '',
    serial:        '',
  }
  const nombreOrganizacion = perfilUsuario?.perfil?.organizacion?.nombreOrganizacion
  const [formValues, setFormValues] = useState({...emptyPayload,...payload[section]})
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  const [recursoSelected, setRecursoSelected] = useState({})
  const handleChange = (e) => API.handleChange(e, 'entrada', setFormValues, formValues)
  const {funcionarios} = useSelector(state => state.app)
  const autorizador = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const coordinador = funcionarios.filter(it => it.idPerfil === 164)[0]
  const aprueba = funcionarios.filter(it => it.idPerfil === 1139)[0]
  const subfolder = `elaborar-autorizacion-${nombreOrganizacion}`
  const filename = `elaborar-autorizacion-${formValues.identificador}-${nombreOrganizacion}`
  const filenameDOCX = `${filename}.docx`
  const filenameDOCXuploaded = `${filename}-upld.docx`
  // const filenamePDFsigned = `${filename}-firmado.pdf`
  const [myTab, setMytab] = useState('1')
  function getOnSave(fv=null) {
    return () => {
      payload[section] = f.isValid(fv)?fv:formValues
      dispatch(handleSaveSolicitud(
        instanciaProceso?.id,
        payload,
        () => setCounter(0)
      ))
    };
  }
  if(f.isValid(solicitudesAprobadas)) {
    return (
      <Box sx={accordeonBox.container}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          {
            (instanciaTarea.tareaCodigoTarea === "55000010_Activity_ElaborarAprobacionAutorizacionWF09")?
              <SectionTitle title={'Elaboración Autorización de salida de holotipos'} />:
              <SectionTitle title={'Elaboración Negación de salida de holotipos'} />
          }
        </AppBar>
        <TabContext value={myTab} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt:'1rem'}}>
            <TabList onChange={ (event, newTab) => { setMytab(newTab) }} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="ATM" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={{...accordeonBox.container2, m:'2rem 0 4rem 0'}}>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Identificador'}
                                     icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={solicitud.numeroSolicitud} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Fecha'}
                                     icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                                     value={slct.payload.Solicitud['fecha']} />
              </Grid>
              <Solicitante solicitud={{solicitud: {payload: mp}}} displayContact={false}/>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Recursos'}/>
              </Grid>
              <Grid item xs={12}>
                <MyTableRecursos2 id={'recursos'}
                                  canEdit={false}
                                  formValues={slct.payload.Solicitud}
                                  mode={'ATM-ES2'} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                {
                  (instanciaTarea.tareaCodigoTarea === "55000010_Activity_ElaborarAprobacionAutorizacionWF09")?
                    <MySubtitle subtitle={'Autorización'} />:
                    <MySubtitle subtitle={'Negación'} />
                }
              </Grid>
              {/*<Grid item xs={4} style={{padding:'0.5rem 0.6rem 0.5rem 0'}}>*/}
              {/*  <Stack direction={'row'} spacing={1} justifyContent="flex-start" alignItems='center' >*/}
              {/*    <MySwitch id={'cumpleRequisitos'}*/}
              {/*              label={'¿Se aprueba la autorización?'}*/}
              {/*              fullWidth={false}*/}
              {/*              formValues={slct?.payload?.Validar}*/}
              {/*              canEdit={false} />*/}
              {/*  </Stack>*/}
              {/*</Grid>*/}
              <Grid item xs={12} >
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{padding:'0 24px 0 8rem'}}>
                  {
                    formValues.serial === '' &&
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'GENERAR SERIAL'}
                                      width={'11rem'}
                                      bgColor1={'rgb(148 193 32)'}
                                      onClick={() => {
                                        const tipo = 'PHOL'
                                        API.secuenciaSet(tipo).then((ser) => {
                                          if(f.isValidNotEmpty(ser.codigoSecuencia)) {
                                            const serial = `${ser.codigoSecuencia}${GLOBALS.year}-${ser.numeroSecuencia}`
                                            getOnSave({...formValues, serial:serial})()
                                            setFormValues({...formValues, serial:serial})
                                          }
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>
                  }
                  {
                    (formValues.docx !== '') ?
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={''}
                                      width={'3rem'}
                                      onClick={() => {
                                        setFormValues({
                                          ...formValues,
                                          serial: '',
                                          docx: '',
                                          pdf: '',
                                          docxLded: '',
                                          pdfLded: '',
                                          // pdfSigned: '',
                                        })
                                      }}
                                      icon={RestartAltIcon}/>
                    </ButtonGroup>:null
                  }
                  {
                    (formValues.docx === '' && formValues.serial !== '' ) ?
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'Generar Informe'}
                                      width={'11rem'}
                                      onClick={() => {
                                        API.fetchPlantila('56010008-1').then(taggeDoc => {
                                          const doc = MyTagged2Docx(plantilla, nombreOrganizacion, mapping({
                                            solicitudId: instanciaProceso?.solicitud?.numeroSolicitud,
                                            slct,
                                            autorizador,
                                            coordinador,
                                            perfilUsuario,
                                            nombreOrganizacion,
                                            aprueba
                                          }))
                                          API.genDocxAnPdf({
                                            solicitudId: instanciaProceso?.solicitud?.id,
                                            document: doc,
                                            formValues,
                                            setFormValues,
                                            subfolder,
                                            filename: filenameDOCX,
                                            nombreOrganizacion,
                                          })
                                        })
                                      }}
                                      icon={MiscellaneousServicesIcon} />
                    </ButtonGroup> : null
                  }
                  {
                    (formValues.docx !== '') ?
                    <ButtonGroup variant={'contained'}>
                      <MyButtonBacan3 label={'DOCX'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadDOCX({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.docxLded !== '') ? formValues.docxLded : formValues.docx,
                                      })}
                                      width={'5rem'} />
                      <MyButtonBacan3 label={'PDF'}
                                      icon={FileDownloadIcon}
                                      onClick={() => API.fetchDownloadPDF({
                                        solicitudId: instanciaProceso?.solicitud?.id,
                                        subfolder,
                                        filename: (formValues.pdfLded !== '') ? formValues.pdfLded : formValues.pdf
                                      })}
                                      width={'5rem'} />
                    </ButtonGroup> : null
                  }
                  {
                    (formValues.docx !== '') ?
                    <ButtonGroup variant={'contained'}>
                      <MyFileUploadButton label={'DOCX'}
                                          solicitudId={instanciaProceso?.solicitud?.id}
                                          subfolder={subfolder}
                                          fileName={filenameDOCXuploaded}
                                          afterUpload={() => {
                                            API.fetchDocx2PDF(instanciaProceso?.solicitud?.id, subfolder, filenameDOCXuploaded).then(result2 => {
                                              if(f.isValid(result2.rutaDocumento)) {
                                                const arr2 = result2.rutaDocumento.split('/')
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: arr2[arr2.length-1],})
                                              } else {
                                                setFormValues({...formValues, docxLded: filenameDOCXuploaded, pdfLded: '',})
                                              }
                                            })
                                          }}
                                          width={'5rem'} />
                    </ButtonGroup> : null
                  }
                  {/*{*/}
                  {/*  (formValues.docx !== '') ?*/}
                  {/*  <ButtonGroup variant={'contained'}>*/}
                  {/*    <MyFileUploadButton label={'PDF firmado'}*/}
                  {/*                        solicitudId={instanciaProceso?.solicitud?.id}*/}
                  {/*                        subfolder={subfolder}*/}
                  {/*                        fileName={filenamePDFsigned}*/}
                  {/*                        afterUpload={() => setFormValues({...formValues, pdfSigned: filenamePDFsigned})}*/}
                  {/*                        width={'9rem'} />*/}
                  {/*    {*/}
                  {/*      formValues.pdfSigned !== '' && <MyButtonBacan3 label={''}*/}
                  {/*                                                     icon={FileDownloadIcon}*/}
                  {/*                                                     onClick={() => API.fetchDownloadPDF({*/}
                  {/*                                                       solicitudId: instanciaProceso?.solicitud?.id,*/}
                  {/*                                                       subfolder,*/}
                  {/*                                                       filename: filenamePDFsigned,*/}
                  {/*                                                     })}*/}
                  {/*                                                     width={'3rem'} />*/}
                  {/*    }*/}
                  {/*  </ButtonGroup> : null*/}
                  {/*}*/}
                </Stack>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P10ATMRO payload={ATM} />
          </TabPanel>
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed' color='primary' elevation={0} sx={accordeonBox.bottomBar}>
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              <Grid item xs={12} >
                <Stack direction={'row'} spacing={1} justifyContent="space-between" alignItems='center' sx={{p:'0 2rem 0 3rem'}}>
                  {
                    (canEdit)?
                      <>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => {
                                         if(counter <= 2) {
                                           dispatch(handleClear())
                                         } else {
                                           alert('Debe GUARDAR los cambios realizados')
                                         }
                                       }} />
                        <MyButtonBacan label={'Guardar'} onClick={() => {
                                         const newPayload= {...payload ,[section]: {...formValues}}
                                         dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
                                         setCounter(0)
                                       }}
                                       disabled={counter <= 0}
                                       icon={SaveOutlinedIcon} />
                        <MySendButton disabled={counter > 0  || formValues.pdf === ''}
                                      label={(instanciaTarea.tareaCodigoTarea === "55000010_Activity_ElaborarAprobacionAutorizacionWF09")?'aprobar':'negar'}
                                      onSend={ () => {
                                        const metadata = JSON.stringify({
                                          "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                          "informeFavorable": true,
                                        })
                                        dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                      }} />
                      </>
                      :
                      <Grid item xs={4} style={{padding:'0 24px 0 0'}}>
                        <MyButtonBacan label={'Regresar'}
                                       icon={ArrowBackIcon}
                                       onClick={() => dispatch(handleClear())} />
                      </Grid>
                  }
                </Stack>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
  else return null
}

const mapping = ({ solicitudId, slct, autorizador, coordinador, perfilUsuario, tipo, section, aprueba }) => {
  return {
    '<<serial>>': slct.payload?.Elaboracion?.serial ?? '',
    '<<autorizacion.fechaSalida>>': slct.payload?.Solicitud?.fecha ?? '',
    '<<autorizacion.fechaRetorno>>': slct.payload?.Solicitud?.fechaRetorno ?? '',
    '<<autorizacion.pai>>': slct.payload?.Solicitud?.ubicacionPais ?? '',
    '<<autorizacion.institucion>>': slct.payload?.Solicitud?.ubicacionInstitucion ?? '',
    '<<solicitante.identificador>>': slct.payload?.Solicitud?.solicitudAprobada?.Solicitante?.cedula ?? '',
    '<<solicitante.nombre>>': slct.payload?.Solicitud?.solicitudAprobada?.Solicitante?.nombresCompletos ?? '',
    '<<autorizacion.responsable>>': slct.payload?.Solicitud?.responsableEnvio ?? '',
    '<<autorizacion.justificacion>>': slct.payload?.Solicitud?.justificacion ?? '',
    '<<recursos.tablaSalidaHolotipos>>': JSON.stringify(slct.payload?.Solicitud?.recursos),
    '<<firman>>': JSON.stringify([
      {accion: 'Elaborado por',nombre:perfilUsuario?.usuario?.nombreUsuario ?? '', cargo:perfilUsuario?.perfil?.nombrePerfil ?? ''},
      {accion: 'Revisado por',nombre:coordinador?.nombreUsuario ?? '', cargo:coordinador?.usuarioCargoUsuario ?? ''},
      {accion: 'Aprobado por',nombre:aprueba?.nombreUsuario ?? '', cargo:aprueba?.usuarioCargoUsuario?.toUpperCase()},
    ]),
  }
}

const plantilla =  '' +
  '${TITULO1}AUTORIZACIÓN DE SALIDA DE HOLOTIPOS' +
  '${SALTO}' +
  '${SALTO}${VAR}Identificado${:}<<serial>>' +
  '${SALTO}${VAR}Fecha de emisión${:}<<autorizacion.fechaSalida>>' +
  '${SALTO}${VAR}Fecha de retorno${:}<<autorizacion.fechaRetorno>>' +
  '${SALTO}${VAR}País de destino${:}<<autorizacion.pai>>' +
  '${SALTO}${VAR}Institución de destino${:}<<autorizacion.institucion>>' +
  '${SALTO}' +
  '${SALTO}${TITULO2}Solicitante' +
  '${SALTO}${VAR}Identificación${:}<<solicitante.identificador>>' +
  '${SALTO}${VAR}Nombre${:}<<solicitante.nombre>>' +
  '${SALTO}' +
  '${SALTO}${TITULO2}Descripción del recurso biológico, genético o sus derivados cuya salida se autoriza' +
  '${SALTO}${RECURSOSATMES2}<<recursos.tablaSalidaHolotipos>>' +
  '${SALTO}' +
  '${SALTO}${TITULO2}Justificación de la salida de holotipos' +
  '${SALTO}${VAR}Responsable del envío${:}<<autorizacion.responsable>>' +
  '${SALTO}${VAR}Justificación${:}<<autorizacion.justificacion>>' +
  '${SALTO3}' +
  '${SALTO}${FIRMAN}<<firman>>'
