import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { useFormikContext } from "formik";

export const RolSelect = ({ ...props }) => {

    const { roles } = useSelector(state => state.usuarios)
    const [age, setAge] = React.useState('');

    const { setFieldValue } = useFormikContext();

    React.useEffect(() => {
        setTimeout(() => {
            roles.length !== 0 && setAge(roles[0].id);
            roles.length !== 0 && setFieldValue("rolId", roles[0].id);
        }, 500);
    }, [roles])

    const handleChange = (event) => {
        setAge(event.target.value);
        setFieldValue("rolId", event.target.value)
    };

    return (
        <div>
            <FormControl sx={{width: 330}}>
                <InputLabel id="rol-label">Rol</InputLabel>
                <Select
                    labelId="rol-label"
                    id="rol"
                    value={age}
                    onChange={handleChange}
                    autoWidth
                    required
                    label="Rol"
                >
                    {

                        roles.length === 0 ?
                            <MenuItem >{''}</MenuItem>
                            :
                            roles.map((rol) => (
                                <MenuItem key={rol.id} value={rol.id}>{rol.descripcionPerfil}</MenuItem>
                            ))

                    }

                </Select>
            </FormControl>
        </div>
    );
}
