import React, {useState} from 'react'
import {accordeonBox, dialog} from "../../styles/styles";
import {FormControlLabel, Grid, InputAdornment, Link, Stack, TextField, Typography} from "@mui/material";
import {MySubtitle} from "../../components/MySubtitle";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {MySwitch} from "../../components/MySwitch";
import {MyUpload} from "../../components/MyUpload";
import {AccountCircle} from "@mui/icons-material";
import {MyTableRecursos2} from "../../components/MyTableRecursos2";
import BiotechIcon from "@mui/icons-material/Biotech";
import Autorizacion from "../P01Solicitud/subcomponents/Autorizacion";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";


export const P10ATMRO = ({payload}) => {
  const solicitud = payload.Solicitud
  const arr = solicitud?.identificador?.split('-')
  const solicitudId = arr[arr.length-1]
  const pageStyle = {m:'1.2rem 0 0 0 ', fontSize:'0.9rem'}

  return (
    <Grid container spacing={1} sx={{...accordeonBox.container2, m:'1rem 0 4rem 0'}}>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Declaración'} />
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField label={'Identificador'}
                             icon={<BorderColorIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                             value={solicitud.identificador} />
      </Grid>
      <Grid item xs={6} >
        <MyReadOnlyTextField label={'Fecha'}
                             icon={<CalendarMonthIcon fontSize={'1rem'} sx={{color:'silver', m:'0 0.8rem 0 0'}}/>}
                             value={solicitud.fecha} />
      </Grid>
      <Autorizacion solicitud={{numeroSolicitud: payload?.Solicitud?.aprobadaIdentificador, payload: JSON.parse(payload?.Solicitud?.solicitudAprobada)}} />
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Recursos a ser incluidos en el acuerdo de transferencia de material
          </FormLabel>
          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={solicitud.tipo}>
            <FormControlLabel value='IN-SITU' control={<Radio />} label='Recurso a ser recolectado (in situ)?' />
            <FormControlLabel value='EX-SITU' control={<Radio />} label='Recurso depositado previamente (ex situ)' />
            <FormControlLabel value='IN-EX-SITU' control={<Radio />} label='Ambos, in situ y ex situ' />
          </RadioGroup>
        </FormControl>
      </Grid>
      {['IN-EX-SITU', 'IN-SITU'].includes(solicitud.tipo) ?
        <>
          <Grid item xs={12} sx={dialog.titleContainer}>
            <MySubtitle subtitle={'Recursos in-situ'}/>
          </Grid>
          <Grid item xs={12} sx={dialog.titleContainer}>
            <MyTableRecursos2 id={'recursosIS'}
                              canEdit={false}
                              formValues={solicitud}
                              mode={'ATM-IS'} />
          </Grid>
          <Grid item xs={12} >
            <MySubtitle subtitle={'Destino'} />
          </Grid>
          <Grid item xs={10} alignItems="center">
            <MyReadOnlyTextField id={'destinoFinalIS'}
                                 label={'Institución en la que se desarrollará la fase de laboratorio'}
                                 value={solicitud.destinoFinalIS}
                                 icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                                 canEdit={false} />
          </Grid>
        </> : null
      }

      {['IN-EX-SITU', 'EX-SITU'].includes(solicitud.tipo) ?
        <>
          <Grid item xs={12} sx={dialog.titleContainer}>
            <MySubtitle subtitle={'Recursos ex-situ'}/>
          </Grid>
          <Grid item xs={12} sx={dialog.titleContainer}>
            <MyTableRecursos2 id={'recursosES'}
                              canEdit={false}
                              formValues={solicitud}
                              showHolotipo={true}
                              mode={'ATM-ES'} />
          </Grid>
          <Grid item xs={12} >
            <MySubtitle subtitle={'Destino'} />
          </Grid>
          <Grid item xs={10} alignItems="center">
            <MyReadOnlyTextField id={'destinoFinalES'}
                                 label={'Institución en la que se desarrollará la fase de laboratorio'}
                                 value={solicitud.destinoFinalES}
                                 icon={<BiotechIcon sx={{fontSize: '14px', color:'silver'}} />}
                                 canEdit={false} />
          </Grid>
        </> : null
      }
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Acuerdo de transferencia de material suscrito'} />
      </Grid>
      <Grid item xs={12}>
        <Stack direction={'row'} justifyContent="space-between" alignItems="center">
          <MyUpload id={'atm'}
                    dir={solicitudId}
                    label={'Acuerdo de transferencia de material *'}
                    formValues={solicitud}
                    canEdit={false} />
          <MyUpload id={'atmAdicional'}
                    dir={solicitudId}
                    label={'Documento adicional'}
                    formValues={solicitud}
                    canEdit={false} />
        </Stack>
      </Grid>
      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Cláusulas'} />
      </Grid>
      <Grid item xs={10} alignItems="center">
          <MySwitch id={'condicion1'}
                    label={'¿El ATM contempla una cláusula de divulgación obligatoria del país de origen, fuente o proveedor de los recursos?'}
                    formValues={solicitud}
                    canEdit={false} />
      </Grid>
      <Grid item xs={2} >
        {
          solicitud['condicion1'] &&
          <Typography sx={pageStyle}>
            {`Página No. ${solicitud.pagina1}`}
          </Typography>
        }
      </Grid>
      <Grid item xs={10} >
          <MySwitch id={'condicion2'}
                    label={'¿El ATM define una cláusula de sometimiento a la legislación ecuatoriana en materia de propiedad intelectual, incluyendo la prohibición de patentar recursos biológicos, genéticos o sus derivados de origen ecuatoriano?'}
                    formValues={solicitud}
                    canEdit={false} />
      </Grid>
      <Grid item xs={2} >
        {
          solicitud['condicion2'] &&
          <Typography sx={pageStyle}>
            {`Página No. ${solicitud.pagina2}`}
          </Typography>
        }
      </Grid>
      <Grid item xs={10} >
          <MySwitch id={'condicion3'}
                    label={'¿El ATM contine una cláusula que incluya la obligación de repatriar los recursos biológicos, genéticos o sus derivados y la información levantada a partir de estos, en caso de incumplimiento de los términos del Acuerdo.?'}
                    formValues={solicitud}
                    canEdit={false} />
      </Grid>
      <Grid item xs={2} >
        {
          solicitud['condicion3'] &&
          <Typography sx={pageStyle}>
            {`Página No. ${solicitud.pagina3}`}
          </Typography>
        }
      </Grid>
      <Grid item xs={10} >
          <MySwitch id={'condicion4'}
                    label={'¿El ATM define la obligación de reportar los resultados alcanzados?'}
                    formValues={solicitud}
                    canEdit={false} />
      </Grid>
      <Grid item xs={2} >
        {
          solicitud['condicion4'] &&
          <Typography sx={pageStyle}>
            {`Página No. ${solicitud.pagina4}`}
          </Typography>
        }
      </Grid>
      <Grid item xs={10} >
          <MySwitch id={'condicion5'}
                    label={'¿El ATM define la obligación del receptor de no transferir a terceros los recursos recibidos?'}
                    formValues={solicitud}
                    canEdit={false} />
      </Grid>
      <Grid item xs={2} >
        {
          solicitud['condicion5'] &&
          <Typography sx={pageStyle}>
            {`Página No. ${solicitud.pagina5}`}
          </Typography>
        }
      </Grid>
      <Grid item xs={10}>
          <MySwitch id={'condicion6'}
                    label={'¿El ATM define el destino final del recurso transferido una vez alcanzando el objetivo?'}
                    formValues={solicitud}
                    canEdit={false} />
      </Grid>
      <Grid item xs={2} >
        {
          solicitud['condicion6'] &&
          <Typography sx={pageStyle}>
            {`Página No. ${solicitud.pagina6}`}
          </Typography>
        }
      </Grid>

      <Grid item xs={12} sx={dialog.titleContainer}>
        <MySubtitle subtitle={'Declaración de veracidad de la información'} />
      </Grid>
      <Grid item xs={12}>
        <TextField id='texto'
                   multiline
                   rows={10}
                   value={solicitud.texto}
                   fullWidth
                   variant='standard'
                   aria-readonly={true}
                   sx={dialog.textTypography}
                   InputProps={{
                     disableUnderline: true,
                     startAdornment:(
                       <InputAdornment position="start">
                         <AccountCircle sx={{fontSize: '14px', color:'silver'}}/>
                       </InputAdornment>
                     ),
                     sx: {
                       fontSize: '12px',
                       backgroundColor: 'white',
                     }
                   }}
                   InputLabelProps={{
                     sx: {
                       fontSize: '14px',
                     }
                   }}/>
      </Grid>
      <Grid item xs={10}>
        <MySwitch id={'si'}
                  label={'Aceptar y enviar'}
                  formValues={solicitud}
                  fullWidth={false}
                  canEdit={false} />
      </Grid>
    </Grid>
  )
}
