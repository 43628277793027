import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Rooms } from './Rooms';
import { ChatForm } from './ChatForm';
import { ModalChat } from './ModalChat';
import { limpiarChats, limpiarRoom } from './sliceChat';
import { AppBar, Box, CssBaseline, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { P0102PayloadRO } from '../P01Solicitud/P0102PayloadRO';
import { f } from "../../commons";
// import { format } from 'date-fns'
// import { es } from 'date-fns/locale'
import API from "../App/API";

const drawerWidth = 270;

export const TablaChats = (props) => {

  const dispatch = useDispatch();

  // const perfilUsuario = useSelector(state => state.app.perfilUsuario)
  // const section = `DictamenTecnico${perfilUsuario?.perfil?.organizacion?.nombreOrganizacion}`
  // const instanciaTarea = useSelector(state => state.app.instanciaTarea)
  const [myTab, setMytab] = React.useState('1');
  const handleChangeTab = (event, newTab) => {
    setMytab(newTab);
  };

  useEffect(() => {
    dispatch(limpiarChats());
    dispatch(limpiarRoom());
  }, [])

  const { room } = useSelector(state => state.chat)
  const [payload, setPayload] = useState(null)

  useEffect(() => {
    const numeroSolicitud = (room?.length > 0) ? room[0].numeroSolicitud : null
    if (f.isValid(numeroSolicitud)) {
      API.fetchSolitudById(numeroSolicitud).then((solicitud) => {
        setPayload(JSON.parse(solicitud.payload ?? '{}'))
      })
    } else {
      setPayload(null)
    }
  }, [room])

  return (

    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >

        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Rooms />
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 5, mt: -8, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <TabContext value={myTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab} aria-label="tabs-chat">
                <Tab label="Chat" value="1" />
                {room &&
                  <Tab label="Solicitud" value="2" />
                }
              </TabList>
            </Box>
            <TabPanel value="1">
              <ChatForm />
            </TabPanel>
            {room &&
              <TabPanel value="2">
                {room && <P0102PayloadRO payload={payload} />}
              </TabPanel>
            }
          </TabContext>
        </Box>
        <ModalChat />
      </Box>
    </div>

  );
}

