import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {AppBar, Box, CssBaseline, Grid, Stack, Tab} from '@mui/material'
import {format} from 'date-fns'
import {es} from 'date-fns/locale'
import {accordeonBox, dialog} from 'src/styles/styles'
import {handCompletarTareaAndSalir, handleClear, handleSaveSolicitud} from 'src/features/App/sliceApp'
import {SectionTitle} from 'src/components/SectionTitle'
import {MySubtitle} from 'src/components/MySubtitle'
import {f} from 'src/commons/f'
import {MyButtonBacan} from 'src/components/MyButtonBacan'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {MySendButton} from 'src/components/MySendButton'
import {Solicitante} from 'src/features/P01Solicitud/subcomponents/Solicitante'
import {MyTextField} from "../../components/MyTextField";
import {MyReadOnlyTextField} from "../../components/MyReadOnlyTextField";
import {MySwitch} from 'src/components/MySwitch'
import {MyUpload} from 'src/components/MyUpload';
import Toolbar from "@mui/material/Toolbar";
import API from "../App/API";
import {MyAreaTextField} from "../../components/MyAreaTextField";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {P10ATMRO} from 'src/features/P10ATM/P10ATMRO'
import Autorizacion from "../P01Solicitud/subcomponents/Autorizacion";
import {P0102Expediente} from "../P01Solicitud/P0102Expediente";
import {P0102PayloadRO} from "../P01Solicitud/P0102PayloadRO";
import {Expediente} from "../App/Expediente";
import {MyButtonAlert} from "../../components/MyButtonAlert";

export default ({
                  instanciaTarea,
                  perfilUsuario,
                  instanciaProceso,
                  solicitud,
                  solicitudesAprobadas,
                  canEdit=true
                }) => {
  const dispatch = useDispatch()
  const section = 'Validar'
  const slct = {...solicitud, payload : JSON.parse(solicitud.payload)}
  slct.payload.Solicitud.solicitudAprobada = JSON.parse(slct.payload.Solicitud.solicitudAprobada)
  const aprobadaIdentificador = slct.payload.Solicitud.solicitudAprobada?.Resolucion?.identificador?.split('-')
  const aprobadaId = aprobadaIdentificador[aprobadaIdentificador.length-1]
  const mp = slct.payload.Solicitud.solicitudAprobada
  const payload = f.isValid(solicitud.payload) ? JSON.parse(solicitud.payload) : {}
  const today = format(new Date(), 'dd-MMMM-yyyy', {locale: es})
  const emptyPayload = {
    fecha: today,
    plazo: 0,
    destinoFinal: '',
    aprobadaIdentificador: '',
    solicitudAprobada: {},
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
    check6: false,
    validacionCompleta: false,
    atmCumpleRequisitos: false,
    asunto: '',
    detalle: '',
  }
  const [formValues, setFormValues] = useState({...emptyPayload, ...payload[section]})
  const [counter, setCounter] = useState(-1)
  useEffect(() => {
    setCounter(counter + 1)
  }, [formValues])
  // const [recursoSelected, setRecursoSelected] = useState({})
  const defaultHandleChange4Switch = (event, value, canEdit, setFormValues, formValues) => {
    if(canEdit) {
      setFormValues({...formValues, [event.target.id]: value})
    }
  }
  const handleChange = (e) => API.handleChange(e, 'entrada', setFormValues, formValues)
  const solicitudATM = payload.Solicitud
  const [myTab, setMytab] = useState('1');
  const handleChangeTab = (event, newTab) => { setMytab(newTab) }
  const [payloadPC,setPayloadPC] = useState({})
  const [padreId,setPadreId] = useState(0)
  useEffect(() => {
    let solicitudPadreId = instanciaTarea.instanciaProcesoSolicitudPadreId
    if(!f.isValid(solicitudPadreId)) {
      const arr = instanciaTarea.instanciaProcesoNumeroSolicitudPadre?.split('-')
      solicitudPadreId = arr[arr?.length - 1]
      setPadreId(Number(solicitudPadreId))
    }
    API.fetchSolitudById(Number(solicitudPadreId)).then(slct => {
      setPayloadPC(JSON.parse(slct.payload))
    })
  },[instanciaTarea.instanciaProcesoNumeroSolicitudPadre])
  useEffect(() => {
    const atmCumpleRequisitos = formValues.check1 && formValues.check2 && formValues.check3 && formValues.check4 && formValues.check5 && formValues.check6
    if(!atmCumpleRequisitos) {
      setFormValues({
        ...formValues,
        atmCumpleRequisitos,
        asunto:  'Solicitud de validación de Acuerdo de Transferencia de Material NO CUMPLE REQUISITOS',
        detalle: `Estimado/a ${mp.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la validación del ` +
          `Acuerdo de Transferencia de Material registrada con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} no cumple requisitos.\n\n` // +
          // 'Saludos cordiales,\n\n' +
          // `${perfilUsuario.usuario.nombreUsuario}`
      })
    } else {
      setFormValues({
        ...formValues,
        atmCumpleRequisitos,
        asunto:  'Solicitud de validación de Acuerdo de Transferencia de Material CUMPLE REQUISITOS',
        detalle: `Estimado/a ${mp.Solicitante.nombresCompletos},\n\n` +
          `Su solicitud realizada a través de la Ventanilla Única Virtual para la validación del ` +
          `Acuerdo de Transferencia de Material registrada con el Identificador: ${solicitud.numeroSolicitud} y correspondiente al ` +
          `proyecto titulado ${solicitud.nombreProyecto} cumple requisitos.\n\n` //+
          // 'Saludos cordiales,\n\n' +
          // `${perfilUsuario.usuario.nombreUsuario}`
      })
    }
  }, [formValues.check1, formValues.check2, formValues.check3, formValues.check4, formValues.check5, formValues.check6])
  // https://testvuv.tech/ws/v1/api/solicitud/get?idSolicitud=18566
  if(f.isValid(solicitudesAprobadas)) {
    return (
      <Box sx={{...accordeonBox.container, pt:'2rem', height: '100%'}}>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={{top:'64px', bottom:'auto', margin: 0, padding:0, backgroundColor:'rgba(148, 193, 32, 0.8)'}} >
          <SectionTitle title={'Validación del Solicitud de Acuerdo de Transferencia ATM'} />
        </AppBar>
        <TabContext value={myTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="Formulario" value="1" />
              <Tab label="ATM" value="2" />
              <Tab label="Solicitud" value="3" />
              <Tab label="Expediente autorizacion" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1} sx={accordeonBox.container2}>
              {f.isValid(payload.T55000006_Activity_AsignarCaso?.observaciones) && payload.T55000006_Activity_AsignarCaso?.observaciones !== '' &&
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}
                         style={{padding: '1rem 24px 1rem 0'}}>
                    <MyButtonAlert label={'Directriz del caso'}
                                   color0={'#888888'}
                                   color1={'#888888'}
                                   messageTitle='Directriz del caso'
                                   messageContent={payload.T55000006_Activity_AsignarCaso?.observaciones}/>
                  </Stack>
                </Grid>
              }
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Solicitud'} />
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Número de Solicitud'} value={instanciaTarea.instanciaProcesoSolicitudNumeroSolicitud}/>
              </Grid>
              <Grid item xs={6} >
                <MyReadOnlyTextField label={'Solicitud padre'} value={instanciaTarea.instanciaProcesoNumeroSolicitudPadre}/>
              </Grid>
              <Solicitante solicitud={{solicitud: {payload: mp}}} displayContact={false}/>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Acuerdo de Transferencia de Material Suscrito'} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent="space-between" alignItems="center">
                  <MyUpload id={'atm'}
                            dir={instanciaProceso?.solicitud?.id}
                            canEdit={false}
                            label={'Acuerdo de transferencia de material'}
                            formValues={solicitudATM}/>
                  <MyUpload id={'atmAdicional'}
                            dir={instanciaProceso?.solicitud?.id}
                            label={'Documento adicional'}
                            formValues={solicitudATM}
                            canEdit={false} />
                </Stack>
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Cláusulas/Requisitos'} />
              </Grid>
              <Grid item xs={8} alignItems="center">
                <MySwitch id={'condicion1'}
                          label={'¿El ATM contempla una cláusula de divulgación obligatoria del país de origen, fuente o proveedor de los recursos?'}
                          formValues={solicitudATM}
                          canEdit={false} />
              </Grid>
              <Grid item xs={2} >
                <MyTextField id={'pagina1'}
                             label={'Página No.'}
                             canEdit={false}
                             formValues={solicitudATM}/>
              </Grid>
              <Grid item xs={1} alignItems="center">
                <MySwitch id={'check1'}
                          label={''}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                          canEdit={canEdit} />
              </Grid>
              <Grid item xs={8} >
                <MySwitch id={'condicion2'}
                          label={'¿El ATM define una cláusula de sometimiento a la legislación ecuatoriana en materia de propiedad intelectual, incluyendo la prohibición de patentar recursos biológicos, genéticos o sus derivados de origen ecuatoriano?'}
                          formValues={solicitudATM}
                          canEdit={false} />
              </Grid>
              <Grid item xs={2} >
                <MyTextField id={'pagina2'}
                             label={'Página No.'}
                             canEdit={false}
                             formValues={solicitudATM} />
              </Grid>
              <Grid item xs={1} alignItems="center">
                <MySwitch id={'check2'}
                          label={''}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                          canEdit={canEdit} />
              </Grid>
              <Grid item xs={8} >
                <MySwitch id={'condicion3'}
                          label={'¿El ATM contine una cláusula que incluya la obligación de repatriar los recursos biológicos, genéticos o sus derivados y la información levantada a partir de estos, en caso de incumplimiento de los términos del Acuerdo.?'}
                          formValues={solicitudATM}
                          canEdit={false} />
              </Grid>
              <Grid item xs={2} >
                <MyTextField id={'pagina3'}
                             label={'Página No.'}
                             canEdit={false}
                             formValues={solicitudATM} />
              </Grid>
              <Grid item xs={1} alignItems="center">
                <MySwitch id={'check3'}
                          label={''}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                          canEdit={canEdit} />
              </Grid>
              <Grid item xs={8} >
                <MySwitch id={'condicion4'}
                          label={'¿El ATM define la obligación de reportar los resultados alcanzados?'}
                          formValues={solicitudATM}
                          handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                          canEdit={false} />
              </Grid>
              <Grid item xs={2} >
                <MyTextField id={'pagina4'}
                             label={'Página No.'}
                             canEdit={false}
                             formValues={solicitudATM} />
              </Grid>
              <Grid item xs={1} alignItems="center">
                <MySwitch id={'check4'}
                          label={''}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                          canEdit={canEdit} />
              </Grid>
              <Grid item xs={8} >
                <MySwitch id={'condicion5'}
                          label={'¿El ATM define la obligación del receptor de no transferir a terceros los recursos recibidos?'}
                          formValues={solicitudATM}
                          canEdit={false} />
              </Grid>
              <Grid item xs={2} >
                <MyTextField id={'pagina5'}
                             label={'Página No.'}
                             canEdit={false}
                             formValues={solicitudATM}/>
              </Grid>
              <Grid item xs={1} alignItems="center">
                <MySwitch id={'check5'}
                          label={''}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                          canEdit={canEdit} />
              </Grid>
              <Grid item xs={8}>
                <MySwitch id={'condicion6'}
                          label={'¿El ATM define el destino final del recurso transferido una vez alcanzando el objetivo?'}
                          formValues={solicitudATM}
                          canEdit={false} />
              </Grid>
              <Grid item xs={2}>
                <MyTextField id={'pagina6'}
                             label={'Página No.'}
                             canEdit={false}
                             formValues={solicitudATM} />
              </Grid>
              <Grid item xs={1} alignItems="center">
                <MySwitch id={'check6'}
                          label={''}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          handleChange={(e,v) => defaultHandleChange4Switch(e,v,canEdit,setFormValues,formValues)}
                          canEdit={canEdit} />
              </Grid>
              <Grid item xs={12} sx={dialog.titleContainer}>
                <MySubtitle subtitle={'Notificación'} />
              </Grid>
              <Grid item xs={12} style={{padding:'0'}}>
                <MyTextField id={'asunto'}
                             label={'Asunto *'}
                             formValues={formValues}
                             handleChange={handleChange}
                             canEdit />
              </Grid>
              <Grid item xs={12} style={{padding:'0'}}>
                <MyAreaTextField id={'detalle'}
                                 label={'Contenido *'}
                                 formValues={formValues}
                                 handleChange={handleChange}
                                 rows={5} />
              </Grid>
              <Grid item xs={12} style={{padding:'0 0 0 24px'}}>
                <MySwitch id={'validacionCompleta'}
                          label={'¿Validación finalizada?'}
                          formValues={formValues}
                          handleChange={handleChange}
                          fullWidth={false}
                          canEdit />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <P10ATMRO payload={payload} />
          </TabPanel>
          <TabPanel value="3">
            {
              f.isValid(payload.Solicitud?.solicitudAprobada)?
                <P0102PayloadRO payload={JSON.parse(payload.Solicitud?.solicitudAprobada)}/>:
                null
            }
          </TabPanel>
          <TabPanel value="4">
            <P0102Expediente payload={JSON.parse(payload.Solicitud?.solicitudAprobada)} />
          </TabPanel>
          {/*<TabPanel value="4">*/}
          {/*  <Expediente solicitudId={aprobadaId} />*/}
          {/*</TabPanel>*/}
        </TabContext>
        <CssBaseline/>
        <AppBar position='fixed'
                color='primary'
                elevation={0}
                sx={accordeonBox.bottomBar} >
          <Toolbar>
            <Grid container sx={{p:0, mt:'-1rem'}}>
              { (canEdit)?
                <Grid container >
                  <Grid item xs={12} sx={{p:'0 4rem 0 4rem'}}>
                    <Stack direction={'row'} justifyContent="space-between" alignItems="center">
                      <MyButtonBacan label={'Regresar'}
                                     icon={ArrowBackIcon}
                                     onClick={() => {
                                       if(counter <= 2) {
                                         dispatch(handleClear())
                                       } else
                                         alert('Debe GUARDAR los cambios realizados')
                                     }} />
                      <MyButtonBacan label={'Guardar'}
                                     onClick={() => {
                                       const newPayload= {...payload ,[section]: {...formValues}}
                                       dispatch(handleSaveSolicitud(instanciaProceso?.id, newPayload))
                                       setCounter(0)
                                     }}
                                     disabled={counter <= 0}
                                     icon={SaveOutlinedIcon} />
                      <MySendButton disabled={counter > 0|| formValues.asunto === '' || formValues.detalle === '' || !formValues.validacionCompleta}
                                    label={'Enviar'}
                                    onSend={() => {
                                      const metadata = JSON.stringify({
                                        "validacionCompleta": formValues.validacionCompleta,
                                        "atmCumpleRequisitos": formValues.atmCumpleRequisitos,
                                        "solicitudId": `${instanciaProceso?.solicitud?.id}`,
                                        "asunto": formValues.asunto,
                                        "detalle": formValues.detalle,
                                        "adjunto": solicitudATM.atm,
                                        "carpeta": "atm",
                                      })
                                      // const muestras = payloadPC['InformeTecnicoMaate']['centrosDocumentacion']
                                      // console.log(':: ', payload.Solicitud.tipo)
                                      if(formValues.atmCumpleRequisitos) {
                                        if (['IN-EX-SITU', 'IN-SITU'].includes(payload.Solicitud.tipo)) {
                                          payload.Solicitud.recursosIS.forEach(recurso => {
                                            // const cantidadSolicitada = recurso.cantidadSolicitada
                                            const muestraid = JSON.parse(recurso.mid).muestraid
                                            payloadPC['InformeTecnicoMaate']['muestras'].filter(it => it.id === muestraid).forEach(it => {
                                                it.saldorATM = it.saldorATM - recurso.cantidadSolicitada
                                              }
                                            )
                                          })
                                        }
                                        // console.log('>>> ', payloadPC['InformeTecnicoMaate']['muestras'])
                                        if(['IN-EX-SITU','EX-SITU'].includes(payload.Solicitud.tipo)) {
                                          payloadPC?.InformeTecnicoMaate?.centrosDocumentacion.forEach(it => {
                                            let recursosES = payload.Solicitud.recursosES.filter(it2 => it2.centroDocumentacion === it.centroDocumentacion)
                                            recursosES = recursosES.map(it2 => ({...it2, mid:JSON.parse(it2.mid)}))
                                            recursosES.forEach(it2 => {
                                              const dato =  {cantidadSolicitada: it2.cantidadSolicitada, id: it2.mid.recursoid}
                                              const row = it.recursos?.filter(it => it.id === dato.id)[0]
                                              row.saldoATM = row.saldoATM - dato.cantidadSolicitada
                                            })
                                          })
                                        }
                                      }
                                      // console.log(':::: > ', payloadPC['InformeTecnicoMaate']['centrosDocumentacion'])
                                      API.fetchSetPayload(padreId, payloadPC).then(() => {
                                        dispatch(handCompletarTareaAndSalir(instanciaProceso.id, instanciaTarea, perfilUsuario.id, metadata))
                                      })
                                    }} />
                    </Stack>
                  </Grid>
                </Grid>
                :
                <Grid item xs={4} style={{padding:'0 24px 0 0'}}>
                  <MyButtonBacan label={'Regresar'}
                                 icon={ArrowBackIcon}
                                 onClick={() => dispatch(handleClear())} />
                </Grid>
              }
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    )
  }
  else return null
}
