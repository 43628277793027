import React, {useState} from 'react'
import IconButton from '@mui/material/Button'
import RobotoCondensedLight from 'src/styles/MyFonts/Roboto_Condensed/RobotoCondensed-Light.ttf'
import Typography from '@mui/material/Typography'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack} from '@mui/material'
import Box from '@mui/material/Box'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'

export const MyButtonAlert = ({
                                label='no label ...',
                                labelPos='right',
                                messageTitle='title ...',
                                messageContent='message here ...',
                                messageButton='entendido',
                                bgColor0='transparent',
                                color0='rgba(255, 0, 0, 1)',
                                color1='rgba(255, 0, 0, 0.8)',
                                disabled=false,
                               }) => {
  const height= '1.8rem'
  const MyIcon=NotificationsOutlinedIcon
  const [showAlert, setShowAlert] = useState(false)

  const ThisButton = () => <IconButton variant="contained"
                                       disabled={disabled}
                                       size={'small'}
                                       sx={{
                                         borderRadius: '1rem',
                                         p:0,
                                         minWidth: '1rem',
                                         backgroundColor: bgColor0,
                                         width: '2rem',
                                         height,
                                         "&:hover":{
                                           backgroundColor: bgColor0,
                                         },
                                         "&:disabled":{
                                           backgroundColor: '#AAAAAA',
                                           color: '#444444'
                                         },
                                       }}
                                       onClick={() => setShowAlert(true)} >
                             <MyIcon sx={{height:'1rem', fill: color0, borderRadius:'6px',}} />
                           </IconButton>

  const ThisIcon = () => <Box sx={{pt:'0.4rem'}}>
                           <Typography sx={{
                                              fontFamily: RobotoCondensedLight,
                                              fontSize: '0.9rem',
                                              fontWeight: 'bolder',
                                              color: color1,
                                       }}>{label}</Typography>
                         </Box>

  return (
    <Stack spacing={1} direction={'row'} sx={{widtg:'8rem', p:0}}>
      {
        labelPos === 'right' ?
          <>
            <ThisButton />
            <ThisIcon />
          </>
          :
          <>
            <ThisIcon />
            <ThisButton />
          </>
      }

      <Dialog open={showAlert}
              onClose={() => setShowAlert(false)}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'>
        <DialogTitle id="alert-dialog-title">
          {messageTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {messageContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlert(false)}>{messageButton}</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}
