import { TextField, Typography, Stack } from '@mui/material'
import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useSelector, useDispatch } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import BadgeIcon from '@mui/icons-material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import Grid from '@mui/material/Grid';
import PasswordIcon from '@mui/icons-material/Password';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GroupIcon from '@mui/icons-material/Group';
import PhoneIcon from '@mui/icons-material/Phone';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { abrirModalImagen, cerrarModalPreferencias, modalPassword } from './slicePreferencias'
import { ModalPassword } from './ModalPassword';
import { ModalImagen } from './ModalImagen';
import { guardarUsuario, retornaUsuario } from './API';
import EngineeringIcon from '@mui/icons-material/Engineering';

const botonAzul = {
    ml: 2,
    textTransform: 'none',
    borderRadius: '0.2rem',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
        backgroundColor: 'rgba(54, 138, 184, 1)',
    }
}

export const FormPreferencias = () => {

    const { orgs, perfilUsuario, usuario: usuarioApp } = useSelector(state => state.app)

    const dispatch = useDispatch()

    React.useEffect(() => {
        dispatch(retornaUsuario(usuarioApp.correoUsuario))
    }, [])

    const { usuario } = useSelector(state => state.preferencias)

    const [telf, setTelf] = React.useState(usuario.telefonoUsuario || '')
    const [carg, setCarg] = React.useState(usuario.cargoUsuario || '')

    const handlePassword = () => {
        dispatch(modalPassword());
    }

    const handleChange = (event) => {
        setTelf(event.target.value);
    }

    const handleCargo = (event) => {
        setCarg(event.target.value);
    }

    const handleGuardar = () => {
        dispatch(guardarUsuario(usuario, telf, carg));
    }

    const handleImagen = () => {
        dispatch(abrirModalImagen());
    }

    const handleCerrarModalPreferencias = () => {
        dispatch(cerrarModalPreferencias());
    }

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <ListItem >
                            <ListItemAvatar>
                                <BadgeIcon fontSize="small" color="success" />
                            </ListItemAvatar>
                            <ListItemText
                                sx={{ display: 'inline', fontWeight: 'bold', float: 'right' }}
                                primary="Cédula:"
                            />
                        </ListItem>
                    </Grid>
                    <Grid item xs={7}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline', fontWeight: 'bold', float: 'left' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {usuario.cedulaUsuario || 'N/A'}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </Grid>

                    <Grid item xs={5}>
                        <ListItem >
                            <ListItemAvatar>
                                <PersonIcon fontSize="small" color="success" />
                            </ListItemAvatar>
                            <ListItemText
                                sx={{ display: 'inline', fontWeight: 'bold', float: 'right' }}
                                primary="Nombres:"
                            />
                        </ListItem>
                    </Grid>
                    <Grid item xs={7}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline', fontWeight: 'bold', float: 'left' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {usuario.nombreUsuario || 'N/A'}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </Grid>

                    <Grid item xs={5}>
                        <ListItem >
                            <ListItemAvatar>
                                <AlternateEmailIcon fontSize="small" color="success" />
                            </ListItemAvatar>
                            <ListItemText
                                sx={{ display: 'inline', fontWeight: 'bold', float: 'right' }}
                                primary="Correo:"
                            />
                        </ListItem>
                    </Grid>
                    <Grid item xs={7}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline', fontWeight: 'bold', float: 'left' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {usuario.correoUsuario || 'N/A'}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </Grid>

                    <Grid item xs={5}>
                        <ListItem >
                            <ListItemAvatar>
                                <PhoneIcon fontSize="small" color="success" />
                            </ListItemAvatar>
                            <ListItemText
                                sx={{ display: 'inline', fontWeight: 'bold', float: 'right' }}
                                primary="Teléfono:"
                            />
                        </ListItem>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            id="telefono"
                            value={telf}
                            name='telefono'
                            onChange={handleChange}
                            autoComplete="off"
                            margin="dense"
                            variant="outlined"
                            type="text"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <ListItem >
                            <ListItemAvatar>
                                <EngineeringIcon fontSize="small" color="success" />
                            </ListItemAvatar>
                            <ListItemText
                                sx={{ display: 'inline', fontWeight: 'bold', float: 'right' }}
                                primary="Cargo:"
                            />
                        </ListItem>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            id="cargo"
                            value={carg}
                            name='cargo'
                            onChange={handleCargo}
                            autoComplete="off"
                            margin="dense"
                            variant="outlined"
                            type="text"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={5}>
                        <ListItem >
                            <ListItemAvatar>
                                <ImportContactsIcon fontSize="small" color="info" />
                            </ListItemAvatar>
                            <ListItemText
                                sx={{ display: 'inline', fontWeight: 'bold', float: 'right' }}
                                primary="Tipo:"
                            />
                        </ListItem>
                    </Grid>
                    <Grid item xs={7}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline', fontWeight: 'bold', float: 'left' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {usuario.tipo || 'N/A'}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </Grid>

                    <Grid item xs={5}>
                        <ListItem >
                            <ListItemAvatar>
                                <CorporateFareIcon fontSize="small" color="info" />
                            </ListItemAvatar>
                            <ListItemText
                                sx={{ display: 'inline', fontWeight: 'bold', float: 'right' }}
                                primary="Organización:"
                            />
                        </ListItem>
                    </Grid>
                    <Grid item xs={7}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline', fontWeight: 'bold', float: 'left' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {
                                                orgs.filter(e => (e.id === usuario?.idOrganizacion))[0]?.nombre

                                                || 'N/A'
                                            }
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </Grid>

                    <Grid item xs={5}>
                        <ListItem >
                            <ListItemAvatar>
                                <GroupIcon fontSize="small" color="info" />
                            </ListItemAvatar>
                            <ListItemText
                                sx={{ display: 'inline', fontWeight: 'bold', float: 'right' }}
                                primary="Rol:"
                            />
                        </ListItem>
                    </Grid>
                    <Grid item xs={7}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline', fontWeight: 'bold', float: 'left' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            {perfilUsuario.perfil.descripcionPerfil || 'N/A'}
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </Grid>

                </Grid>
            </Box>

            <Box
                sx={{
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Stack direction="row" justifyContent="end">
                    <Button variant="contained"
                        sx={botonAzul} startIcon={<PhotoCameraIcon />} onClick={handleImagen}
                    >
                        {'Imagen'}
                    </Button>
                    <Button variant="contained"
                        sx={botonAzul} startIcon={<PasswordIcon />} onClick={handlePassword}
                    >
                        {'Contraseña'}
                    </Button>
                    <Button type="submit" onClick={handleGuardar} variant="contained"
                        sx={botonAzul}
                        startIcon={<SaveIcon />}
                    >
                        {'Guardar'}
                    </Button>
                    <Button color='success' variant="contained"
                        sx={botonAzul}
                        startIcon={<HighlightOffIcon />}
                        onClick={handleCerrarModalPreferencias}
                    >
                        {'Cerrar'}
                    </Button>
                </Stack>

            </Box>
            <ModalPassword />
            <ModalImagen />
        </div>
    )
}
