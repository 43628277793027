import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { retornaFuncionarios } from './API';
import { quitarSeleccion, seleccionarFuncionario } from './sliceAdministrador';
import { ToolBarFuncionarios } from './ToolBarFuncionarios';
import { ModalPerfiles } from './ModalPerfiles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import { darken, lighten } from '@mui/material/styles';
import { Button, ButtonGroup } from '@mui/material';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useNavigate } from 'react-router';

const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
    },
    esES,
);

const columns = [
    {
        field: 'nombreUsuario',
        headerName: 'Nombre',
        width: 300,
        editable: false,
    },
    {
        field: 'cedulaUsuario',
        headerName: 'Cédula',
        type: 'number',
        width: 120,
        editable: false,
    },
    {
        field: 'correoUsuario',
        headerName: 'Email',
        width: 300,
        editable: false,
    },
    {
        field: 'codigoUsuario',
        headerName: 'Código usuario',
        width: 200,
        editable: false,
    },
    {
        field: 'usernameUsuario',
        headerName: 'Nombre de usuario',
        width: 200,
        editable: false,
    },
    {
        field: 'estadoUsuario',
        headerName: 'Estado',
        width: 100,
        editable: false,
    },

];

const textoEsp = {
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Seleccionar columnas',
    columnsPanelTextFieldLabel: 'Buscar columna',
    columnsPanelTextFieldPlaceholder: 'Columna...',
    columnsPanelDragIconLabel: 'Reordenar',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',
    toolbarFilters: 'Filtros',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelOperators: 'Operador',
    filterPanelInputPlaceholder: 'Buscar...',
    toolbarDensity: 'Tamaño',
    toolbarDensityLabel: 'Tamaño',
    toolbarDensityCompact: 'Pequeño',
    toolbarDensityStandard: 'Mediano',
    toolbarDensityComfortable: 'Largo',
    toolbarExport: 'Imprimir',
    toolbarExportLabel: 'Imprimir',
    toolbarExportCSV: 'Descargar como CSV',
    toolbarExportPrint: 'Imprimir como PDF',
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'igual',
    filterOperatorStartsWith: 'empieza con',
    filterOperatorEndsWith: 'termina con',
    filterOperatorIs: 'es',
    filterOperatorNot: 'no es',
    filterOperatorIsEmpty: 'ninguno',
    filterOperatorIsNotEmpty: 'todos',
    filterOperatorIsAnyOf: 'cualquiera de '
}

const botonAzul = {
    borderRadius: '0.2rem',
    textTransform: 'none',
    color: 'rgba(255, 255, 255, 0.9)',
    backgroundColor: 'rgba(54, 160, 184, 1)',
    "&:hover": {
        backgroundColor: 'rgba(54, 138, 184, 1)',
    }
}

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </GridToolbarContainer>
    );
}

export const TablaFuncionarios = () => {

    const dispatch = useDispatch();
    const [pageSize, setPageSize] = React.useState(5);
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(retornaFuncionarios());
        dispatch(quitarSeleccion());
    }, [dispatch])

    const { funcionarios, funcionario } = useSelector(state => state.administracion)
    const [selectionModel, setSelectionModel] = React.useState([]);

    React.useEffect(() => {
        if (!funcionario) {
            setSelectionModel([]);
        }
    }, [funcionario])

    const handleFuncionario = (seleccionados) => {
        if (seleccionados.length === 1) {
            dispatch(seleccionarFuncionario(seleccionados[0]))
        } else {
            dispatch(quitarSeleccion());
        }
    }

    const getBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getHoverBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    const handleRegresar = () => {
        navigate('/administracion');
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <Box sx={{
                    height: 406, width: '100%',

                    '& .super-app-theme--Inactivo': {
                        bgcolor: (theme) =>
                            getBackgroundColor('rgba(54, 160, 184, 1)'),
                        '&:hover': {
                            bgcolor: (theme) =>
                                getHoverBackgroundColor('rgba(54, 138, 184, 1)'),
                        },
                    },

                }}>

                    <ToolBarFuncionarios numSelected={0} />

                    <DataGrid
                        rows={funcionarios}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10]}
                        checkboxSelection
                        onSelectionModelChange={(newSelectionModel) => {
                            if (newSelectionModel.length > 1) {
                                const selectionSet = new Set(selectionModel);
                                const result = newSelectionModel.filter((s) => !selectionSet.has(s));

                                setSelectionModel(result);
                                handleFuncionario(result);
                            } else {
                                setSelectionModel(newSelectionModel);
                                handleFuncionario(newSelectionModel);
                            }
                        }}
                        selectionModel={selectionModel}
                        disableSelectionOnClick
                        disableColumnMenu
                        experimentalFeatures={{ newEditingApi: true }}
                        localeText={textoEsp}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        getRowClassName={(params) => `super-app-theme--${params.row.estadoUsuario}`}
                    />
                </Box>
            </ThemeProvider>
            <ModalPerfiles />

            <ButtonGroup variant="contained" sx={{ ml: 5, mt: 12 }} aria-label="outlined primary button group">
                <Button variant="contained" onClick={handleRegresar} startIcon={<ArrowCircleLeftOutlinedIcon />}
                    sx={botonAzul}
                >
                    Regresar
                </Button>
            </ButtonGroup>
        </>
    );
}
