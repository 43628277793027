import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarModalBosques } from './sliceAdministrador';
import { FormBosques } from './FormBosques';
import { FormAreas } from './FormAreas';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

export const ModalBosques = (tipo) => {

    const dispatch = useDispatch()

    const { modalEditarBosques, editando } = useSelector(state => state.administracion)

    const handleClose = () => {
        dispatch(cerrarModalBosques());
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modalEditarBosques}
                maxWidth={'xs'}
                fullWidth
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {editando ? `Editar ${tipo.tipo === 1 ? ' Bosque' : ' Área Protegida'}` : `Nuevo ${tipo.tipo === 1 ? ' Bosque' : ' Área Protegida'}`}
                </BootstrapDialogTitle>
                <DialogContent dividers >
                    {tipo.tipo === 1 ? <FormBosques /> : <FormAreas />}
                </DialogContent>             
            </BootstrapDialog>
        </div>
    );
}
